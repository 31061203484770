import { useState, useMemo } from "react";
import { message } from "antd";
import moment from "moment";
import download from "downloadjs";
import { ActivityLogList } from "./components/ActivityLogList";
import { Text } from "@certa/blocks/thanos";
import { useIntl, FormattedMessage } from "react-intl";
import { css } from "emotion";
import {
  useDownloadActivityLog,
  useGetActivityLogs,
  useGetWorkflowDetails
} from "@certa/queries";
import type { GroupedActivityLogs, TActionGroup } from "./types";
import {
  ACTIVITY_ACTION_GROUPS,
  ACTIVITY_TABS,
  TAB_LABEL_MAPPER
} from "./constants";
import { Download } from "@certa/icons";
import { Loader } from "@certa/blocks";
import { activityLogResultsList, groupActivityLogs } from "./utils";
import {
  showToast,
  ToastTypes,
  Button,
  ButtonTypes,
  ButtonSizes,
  Tooltip,
  Stack,
  HorizontalTabs,
  HorizontalTab
} from "@certa/catalyst";
import { NoResultsInActivityLogs } from "./components/NoResultsInActivityLogs";
import { MixPanelActions, MixPanelEvents } from "../../_helpers/mixpanel";

type Props = {
  id: number | undefined;
  defaultActiveTab?: keyof TActionGroup;
};

const renderSomethingWentWrong = () => (
  <Stack direction="vertical" align="center" justify="center">
    <Text variant="h2-regular" color="red">
      <FormattedMessage id="notificationInstances.somethingWentWrong" />
    </Text>
  </Stack>
);

const renderLoader = () => (
  <Stack direction="vertical" align="center" justify="center" padding="12 0">
    <Loader />
  </Stack>
);

export const ActivityLogsV2 = ({
  id,
  defaultActiveTab = "significantEvents"
}: Props) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const { data: workflowDetails, status: workflowQuerytatus } =
    useGetWorkflowDetails(id);

  const { createdAt: startDate } = workflowDetails || {};

  const intl = useIntl();
  const { data, status, fetchNextPage, hasNextPage, isLoading } =
    useGetActivityLogs({
      id,
      actions: ACTIVITY_ACTION_GROUPS[activeTab as keyof TActionGroup]
    });

  const resultsList = useMemo(
    () => activityLogResultsList(data, startDate),
    [data, startDate]
  );

  const groupedActivityLogs: GroupedActivityLogs = useMemo(
    () => groupActivityLogs(resultsList),
    [resultsList]
  );
  const { mutate: initDownload } = useDownloadActivityLog();
  const onTabChange = (key: string) => {
    activityLogsMixPanelEventHandler(key);
    setActiveTab(key as keyof TActionGroup);
  };

  const downloadFile = () => {
    MixPanelActions.track(
      MixPanelEvents.workflowDetailEvents
        .ACTIVITY_LOGS_CLICK_DOWNLOAD_ACTIVITY_LOGS
    );
    const hideLoadingMessage = message.loading({
      content: intl.formatMessage({
        id: "commonTextInstances.preparingDownload",
        defaultMessage: "Preparing for download"
      }),
      duration: 0,
      icon: (
        <Loader
          size="small"
          className={css`
            margin-right: 12px;
          `}
        />
      )
    });
    initDownload(
      { id, startDate: startDate || "" },
      {
        onSuccess: resp => {
          resp.blob().then(function (blob) {
            // Allowing filename and MIME type to be decided by the backend
            // though it's possible to specify here
            download(
              blob,
              getFileName(resp) ||
                "activity_log_" + moment().format("YYYY-MM-DD") + ".xlsx"
            );
          });
        },
        onError: () => {
          showToast({
            type: ToastTypes.ERROR,
            title: intl.formatMessage({
              id: "errorMessageInstances.somethingWentWrong"
            }),
            description: intl.formatMessage({
              id: "errorMessageInstances.downloadFailed"
            })
          });
        },
        onSettled: () => {
          hideLoadingMessage();
        }
      }
    );
    function getFileName(resp: any) {
      const filename = resp.headers.get("Content-Disposition");
      return filename && (filename.match(/filename="(.*)"/) || []).pop();
    }
  };

  const tabOptions = useMemo(
    () =>
      ACTIVITY_TABS.map((key: string) => ({
        label: intl.formatMessage({
          id: "workflowsInstances.activityLog." + key + "Tab",
          defaultMessage: TAB_LABEL_MAPPER[key as keyof typeof TAB_LABEL_MAPPER]
        }),
        value: key
      })),
    [intl]
  );

  const hasNoResults =
    status === "success" && data && data?.pages?.[0]?.total === 0;

  if (workflowQuerytatus === "loading") {
    return renderLoader();
  }
  if (workflowQuerytatus === "error") {
    return renderSomethingWentWrong();
  }

  return (
    <Stack
      direction="vertical"
      dangerouslySetClassName={css({ height: "100%" })}
    >
      <div
        className={css({
          borderTop: "1px solid var(--colors-neutral-400)",
          marginLeft: "var(--space-20)"
        })}
      />
      <Stack
        direction="horizontal"
        justify="space-between"
        align="center"
        padding="8 0"
        dangerouslySetClassName={css({
          margin: "0 var(--space-24)"
        })}
      >
        <Stack direction="horizontal" gap="12" align="center">
          <HorizontalTabs
            aria-label="Activity"
            activeTab={activeTab}
            onChange={onTabChange}
          >
            {tabOptions.map((tab, index) => (
              <HorizontalTab
                value={tab.value}
                key={index}
                aria-label={tab.label}
              >
                {tab.label}
              </HorizontalTab>
            ))}
          </HorizontalTabs>
        </Stack>
        <Tooltip
          content={intl.formatMessage({
            id: "workflowsInstances.activityLog.downloadTab",
            defaultMessage: "Download all"
          })}
          showArrow
        >
          <Button
            size={ButtonSizes.SMALL}
            type={ButtonTypes.ICON}
            rightIcon={<Download />}
            onClick={downloadFile}
            aria-label={intl.formatMessage({
              id: "workflowsInstances.activityLog.downloadTab",
              defaultMessage: "Download all"
            })}
          />
        </Tooltip>
      </Stack>
      {!hasNoResults && (
        <div
          className={css({
            borderTop: "1px solid var(--colors-neutral-400)",
            marginLeft: "var(--space-20)"
          })}
        />
      )}
      {hasNoResults ? (
        <NoResultsInActivityLogs logType={activeTab} />
      ) : status === "error" ? (
        renderSomethingWentWrong()
      ) : status === "loading" ? (
        renderLoader()
      ) : (
        <ActivityLogList
          loadData={() => {
            fetchNextPage(); // to avoid page params to pass
          }}
          hasMore={hasNextPage || false}
          groupedActivityLogs={groupedActivityLogs}
          isLoading={isLoading}
        />
      )}
    </Stack>
  );
};

const activityLogsMixPanelEventHandler = (key: string) => {
  switch (key) {
    case "significantEvents":
      MixPanelActions.track(
        MixPanelEvents.workflowDetailEvents
          .ACTIVITY_LOGS_CLICK_VIEW_SIGNIFICANT_EVENTS
      );
      break;
    case "edits":
      MixPanelActions.track(
        MixPanelEvents.workflowDetailEvents.ACTIVITY_LOGS_CLICK_VIEW_EDITS
      );
      break;
    case "emails":
      MixPanelActions.track(
        MixPanelEvents.workflowDetailEvents.ACTIVITY_LOGS_CLICK_VIEW_EMAILS
      );
      break;
    case "views":
      MixPanelActions.track(
        MixPanelEvents.workflowDetailEvents.ACTIVITY_LOGS_CLICK_VIEW_VIEWS
      );
      break;
    default:
      return null;
  }
};
