import { useMemo } from "react";
import type { FC, PropsWithChildren } from "react";
import { Text, Stack } from "@certa/blocks/thanos";
import type {
  ChartIdentifiers,
  ShowLegend,
  AxisFormatterResult,
  OtherChartConfigColors,
  SingleToneColorType,
  ChartSorting
} from "@certa/common";
import {
  generateRandomColor,
  getColorSource,
  getColorSourceForPieChart
} from "../../utils";
import { scaleLinear } from "d3-scale";
import type { PieData } from "../charts/pieChart/PieChart";
import { css } from "emotion";
import { colord } from "colord";
import { CustomSorter } from "./CustomSorter";

export type LegendData = {
  label: string;
  background: string;
  fillOpacity: number;
}[];

type CustomLegendProps = PropsWithChildren<{
  data: LegendData;
  sorting?: ChartSorting[];
  chartType?: ChartIdentifiers;
  showLegend?: ShowLegend;
  position: Omit<ShowLegend, "none">;
  handleSorterChange?: (sorter: ChartSorting) => void;
}>;

export function CustomLegend({
  data,
  chartType,
  position,
  showLegend
}: CustomLegendProps) {
  const isPieOrSpiderChart = ["PIE_CHART", "SPIDER_CHART"].includes(
    chartType ?? ""
  );
  if (position !== showLegend) return null;
  return (
    <Stack
      gap="s2"
      gutter={isPieOrSpiderChart ? "s0" : "s0 s0 s0 s20"}
      direction="horizontal"
      justify="center"
      className={flexWrap}
    >
      {data.map((entry, index) => (
        <Stack
          gap="s2"
          direction="horizontal"
          align="center"
          key={`item-${index}`}
          gutter="s2 s0"
        >
          <span
            className={squareBoxStyle(entry.background, entry.fillOpacity)}
          />
          <Text variant="p1-regular" color="neutral-70">
            {entry.label}
          </Text>
        </Stack>
      ))}
    </Stack>
  );
}

export const CustomLegendWrapper: FC<Omit<CustomLegendProps, "position">> = ({
  children,
  sorting,
  handleSorterChange,
  ...props
}) => {
  const { chartType } = props;
  return (
    <>
      <CustomLegend position="top" {...props} />
      {children}
      <CustomLegend position="bottom" {...props} />
      <CustomSorter
        sorting={sorting}
        handleSorterChange={handleSorterChange}
        chartType={chartType}
      />
    </>
  );
};

type UseGetLegendDataCommonProps = {
  labels: string[];
  xAxisKey: string;
  colors?: OtherChartConfigColors;
  fillOpacity?: number;
};

export const useGetLegendDataCommon = (
  props: UseGetLegendDataCommonProps
): LegendData => {
  const { labels, xAxisKey, colors, fillOpacity = 1 } = props;
  const legendsLabel = labels.filter(label => label !== xAxisKey);
  const colorSource = getColorSource(legendsLabel, xAxisKey, colors);

  return legendsLabel.reduce<CustomLegendProps["data"]>(
    (newArray, label, index) => {
      return newArray.concat({
        label: label,
        ...getColorForLegend(colorSource, label, index, fillOpacity)
      });
    },
    []
  );
};

type GetColorForLegendProps = (
  colors: SingleToneColorType | undefined,
  label: string,
  index: number,
  opacity: number
) => { background: string; fillOpacity: number };

const getColorForLegend: GetColorForLegendProps = (
  colors,
  label,
  index,
  opacity
) => {
  let background = generateRandomColor(index);
  let fillOpacity = opacity;
  if (colors?.[label]) {
    background = colord(colors[label]).alpha(1).toRgbString();
    fillOpacity = colord(colors[label]).alpha();
  }
  return { background, fillOpacity };
};

type UseGetLegendDataForPieChartProps = {
  pies: PieData[][];
  xAxisLabelFormatter: AxisFormatterResult;
  colors?: OtherChartConfigColors;
  isCycleTimeReport: boolean;
};

export function useGetLegendDataForPieChart({
  isCycleTimeReport,
  colors,
  xAxisLabelFormatter,
  pies
}: UseGetLegendDataForPieChartProps) {
  return useMemo(() => {
    const piesColorMapping: LegendData[] = [];

    const legendData = pies.reduce<CustomLegendProps["data"]>(
      (newPies, pieArray, index) => {
        const scaleOpacity = scaleLinear()
          .domain([0, pieArray.length - 1])
          .range([0.2, 0.8]);
        const yAxisLabel = pieArray?.[0]?.yLabel ?? "";
        const suffixLabel = isCycleTimeReport
          ? getSuffixForCycleTimePieChart(yAxisLabel)
          : "";
        const labels = pieArray.map(
          (pie, index) =>
            xAxisLabelFormatter(
              suffixLabel ? pie.name + suffixLabel : pie.name,
              index
            ) as string
        );
        const colorSource = getColorSourceForPieChart(
          labels,
          colors,
          yAxisLabel,
          scaleOpacity,
          isCycleTimeReport
        );
        const legends = labels.map((label, pieIndex) => ({
          label: label,
          ...getColorForLegend(
            colorSource,
            label,
            index,
            scaleOpacity(pieArray.length - 1 - pieIndex)
          )
        }));
        piesColorMapping.push(legends);
        return newPies.concat(legends);
      },
      []
    );
    return { piesColorMapping, legendData };
  }, [pies, colors, isCycleTimeReport, xAxisLabelFormatter]);
}

const getSuffixForCycleTimePieChart = (label: string) =>
  ({
    "Minimum Cycle Time": " (min)",
    "Maximum Cycle Time": " (max)",
    "Average Cycle Time": " (avg)"
  })[label] ?? "";

const squareBoxStyle = (background: string, opacity: number) => css`
   {
    width: var(--s4);
    height: var(--s4);
    border-radius: 2px;
    background: ${background};
    opacity: ${opacity};
  }
`;
const flexWrap = css`
   {
    flex-wrap: wrap;
  }
`;
