import type { SelectOption } from "@certa/types";
import type { Colors } from "../../types";

export enum TASK_VARIANTS {
  COMPLETED = "completed",
  DELAYED = "delayed",
  TO_BE_DELAYED = "to-be-delayed",
  HIDDEN = "hidden",
  FYI = "FYI",
  LOCKED = "locked",
  MY_TASK = "my-task",
  NEUTRAL = "neutral",
  ASSISTED_BY_AI = "assistedByAI"
}

export type Avatar = {
  letters?: string;
  props?: {
    user: any;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  };
  secondary: boolean;
};

export type TaskProps = {
  /** Variants of tasks */
  type: TASK_VARIANTS;
  /** If the task is selected or not */
  isSelectedTask?: boolean;
  /** Text that will appear on the Task */
  title: string;
  /** Avatar details to be shown*/
  avatars: Avatar[];
  /** Used to make a step more intuitive by changing boreder and Avatar variant. Generally used to deisplay step assigned to current user. */
  stepEnhancer?: boolean;
  /** Wrapper for Avatar */
  avatarWrapper?: React.FC;
  /** Wrapper for Avatar Showing +n */
  extraAvatarWrapper?: React.FC;
  /** Props for Wrapper for Avatar Showing +n */
  extraAvatarWrapperProps: {
    assignedTo: SelectOption[];
  };
  /** Width of the task in pixels */
  taskWidth: number;
  /** OverDue days to show */
  overdueDays?: string;
  /** Loader to show while data is being fetched */
  canShowInlineLoader: boolean;
  /** Conunt for the Alert badge */
  alertCount?: number;
  /** Color of the Alert badge */
  alertColor?: Colors | string;
  /** Wrapper for Alert Badge */
  alertWrapper?: React.FC;
  /** Props to pass to Wrapper for Alert Badge */
  alertWrapperProps?: any;
  /** Action to perform on clicking the View */
  onClick?: () => void;
  /** Source to the integration image to be displayed */
  integrationImageSrc?: string;
  /** It is used in Recall AI to indicate locked step (first one only) with different colours. */
  isLockedStepHighlight?: boolean;
};
