// List of languages supported by the platform.
// Add any newly supported language here.
// It is expected that the files with the same name with json
// extensions would be found in the same directory as this.
const intlCollection = [
  "en",
  "es",
  "es-cl",
  "fr-ca",
  "zh-hans",
  "ja",
  "de",
  "ua",
  "ru",
  "th",
  "vi",
  "ar",
  "tr",
  "pt-br",
  "pt",
  "zh-hant",
  "ko",
  "zh-tw"
];

// IntlLoader holds the functions which acts as asynchronous loaders
// to load any specific supported language at run-time.
const _IntlLoader = {};
intlCollection.forEach(languageCode => {
  /**
   * Loader function which asynchronously loads the required language
   * and returns the JSON. Intentionally didn't made this as a getter because
   * we use to check if the language code exists in the object or not before
   * invoking the loader function.
   * @returns {Promise} Successful promise resolution returns the language JSON
   */
  _IntlLoader[languageCode] = () => {
    if (!import.meta.env.PROD) {
      console.log("Loading language strings for " + languageCode);
    }

    const asyncImport = import(`./language-${languageCode}.json`);
    return asyncImport;
  };
});

export const IntlLoader = _IntlLoader;
