import React, { Component } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { requestOptions } from "../../../services/auth-header-auditlog-service";
import moment from "moment";
import PropTypes from "prop-types";
import download from "downloadjs";
import { serverlessAPIFetch } from "../../../utils/request";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { AuditList } from "./AuditList";

const TabPane = Tabs.TabPane;

class ServerlessAuditListTabsComponent extends Component {
  static ACTIVITY_ACTION_GROUPS = {
    edits: [
      "step_submitted",
      "step_undo",
      "step_approved",
      "response_changed",
      "child_workflow_created",
      "task_assigned",
      "task_unassigned",
      "workflow_status_changed",
      "adjudication_done",
      "integration_status_change",
      "integration_triggered_manually"
    ],
    emails: [
      "sendgrid_email",
      "sendgrid_queued",
      "sendgrid_sent",
      "sendgrid_rejected",
      "sendgrid_failed",
      "sendgrid_bounced",
      "sendgrid_deferred",
      "sendgrid_delivered",
      "sendgrid_autoresponded",
      "sendgrid_opened",
      "sendgrid_clicked",
      "sendgrid_complained",
      "sendgrid_unsubscribed",
      "sendgrid_subscribed",
      "sendgrid_inbound",
      "sendgrid_inbound_failed",
      "sendgrid_unknown"
    ],
    views: ["step_viewed", "workflow_viewed", "field_decrypted"],
    alerts: ["alert_created", "alert_dismissed"]
  };

  constructor(props) {
    super(props);
    this.state = {
      currentTab: "edits"
    };
  }

  onTabChange = key => {
    this.setState({
      currentTab: key
    });
  };

  /**
   * @deprecated fileDownload inside common/utils should be used / updated for any kind of file download
   * */

  downloadFile = () => {
    function getFileName(resp) {
      const filename = resp.headers.get("Content-Disposition");
      return filename && (filename.match(/filename="(.*)"/) || []).pop();
    }
    const { startDate } = this.props;
    const url = `workflows/activity/export?workflows=${this.props.id}&start_date=${startDate}`;
    return serverlessAPIFetch(url, requestOptions(), "AUDIT_LOG").then(
      function (resp) {
        resp.blob().then(function (blob) {
          // Allowing filename and MIME type to be decided by the backend
          // though it's possible to specify here
          download(
            blob,
            getFileName(resp) ||
              "activity_log_" + moment().format("YYYY-MM-DD") + ".xlsx"
          );
        });
      }
    );
  };

  render = () => {
    const { startDate } = this.props;
    return (
      <Tabs defaultActiveKey="edits" onChange={this.onTabChange}>
        {Object.entries(ServerlessAuditListTabs.ACTIVITY_ACTION_GROUPS).map(
          ([key]) => {
            return (
              <TabPane
                tab={
                  <span
                    style={{ textTransform: "capitalize" }}
                    data-testid={"activitylog_" + key}
                  >
                    <FormattedMessage
                      id={"workflowsInstances.activityLog." + key + "Tab"}
                    />
                  </span>
                }
                key={key}
              >
                <AuditList
                  actions={ServerlessAuditListTabs.ACTIVITY_ACTION_GROUPS[key]}
                  isFocused={this.state.currentTab === key}
                  logType={key}
                  startDate={startDate}
                  id={this.props.id}
                />
              </TabPane>
            );
          }
        )}
        <TabPane
          isFocused={this.state.currentTab === "download"}
          key="download"
          tab={
            <span>
              <FormattedMessage id="workflowsInstances.activityLog.downloadTab" />{" "}
              <DownloadOutlined />
            </span>
          }
        >
          <div className="text-center mr-top-lg">
            <br />
            <span
              onClick={this.downloadFile}
              className="text-secondary text-anchor"
            >
              <DownloadOutlined style={{ fontSize: 30 }} />
            </span>
            <br />
            <div className="mr-top t-12 text-light">
              <FormattedMessage id="workflowsInstances.activityLog.downloadText" />
            </div>
          </div>
        </TabPane>
      </Tabs>
    );
  };
}

ServerlessAuditListTabsComponent.propTypes = {
  id: PropTypes.array.isRequired,
  startDate: PropTypes.string
};

const getCurrentWorklowCreatedAt = state =>
  state?.workflowDetailsHeader?.workflowDetailsHeader?.created_at || "";

const mapStateToProps = state => ({
  startDate: getCurrentWorklowCreatedAt(state)
});

export const ServerlessAuditListTabs = connect(mapStateToProps)(
  injectIntl(ServerlessAuditListTabsComponent)
);
