import type {
  UserProfileData,
  UserRecentActivityParams
} from "../types/user.types";

export const createUserProfileDataModel = (response: any) => {
  const userProfileData: UserProfileData = {};
  // TODO: in future this may be an array, but for now it only gets a single field
  if (response.answer) {
    userProfileData.preferredRegion = response.answer;
  }
  return userProfileData;
};

export const getUserRecentActivityModal = (
  response: UserRecentActivityParams
) => {
  return { recentlyViewedWorkflows: response.recently_viewed_workflows };
};
