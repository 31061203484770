import {
  arrayAnswerAsDropdownModelCreator,
  bulkActionFieldModelCreator
} from "../models/appBuilder.model";
import type { FieldResponse } from "../types/generic.types";
import {
  useQuery,
  useMutation,
  type UseQueryOptions,
  type QueryKey
} from "react-query";
import {
  getRelatedProcesses,
  getFieldResponse,
  getEditableTableColumn,
  createPackageVersion,
  getFieldWorkflows,
  duplicateWorkflow,
  getFieldWorkflowWithType,
  createHotpatchPackageVersion,
  getABProcessKinds,
  getAppVersions,
  getLiteFieldWorkflows,
  deployPackageVersion,
  getPlatformSettingsProcessID,
  getDeployedApps,
  saveAppVersionConfig,
  getWorkflowsAsDropdown,
  getPlatformIntegrationConfigs,
  publishAppVersionConfig,
  createFromReusableComponent,
  getStudioTemplateCategories,
  getStudioTemplateSources
} from "../services/appBuilder.services";
import type { GetChildWorkflowServiceParams } from "../..";
import { useKindId } from "../..";
import { readonlyTableServices } from "../services/readonlyTable.services";
import type {
  UseMutationExtraConfigType,
  UseQueryReturnType
} from "@certa/common";
import type { OptionItem } from "@certa/types";

/**
 * Related processes are those top-level workflow-designer/self-service
 * workflows that are related to the current workflow in a parent-child
 * relationship.
 * @param rootId
 */
export const useRelatedProcessesQuery = (rootId: number | null) =>
  useQuery({
    staleTime: 5 * 60 * 1000,
    enabled: Boolean(rootId),
    queryKey: ["relatedProcesses", rootId],
    queryFn: () => getRelatedProcesses(rootId)
  });

/**
 * Returns the "field" workflows that are part of app builder,
 * child of "step" workflows, grandchild of "stepgroup" workflows
 * @param params
 */
export const useFieldWorkflowsQuery = (
  params: { rootId: number | null } & Record<string, unknown>
) =>
  useQuery({
    staleTime: 5 * 60 * 1000,
    retry: false,
    enabled: Boolean(params.rootId),
    queryKey: ["constraintFields", params],
    queryFn: () => getFieldWorkflows(params as { rootId: number })
  });

export const useFieldLiteWorkflowsQuery = (params: { rootId: number | null }) =>
  useQuery({
    staleTime: 5 * 60 * 1000,
    retry: false,
    enabled: Boolean(params.rootId),
    queryKey: ["constraintLiteFields", params],
    queryFn: () => getLiteFieldWorkflows(params as { rootId: number })
  });

export const useStaticUserGroups = (rootId: number | null) => {
  const fieldTag = "related_types";
  return useQuery({
    enabled: !!rootId,
    retry: 0,
    queryKey: ["staticUserGroups", rootId, "visible_to_static_groups"],

    queryFn: () =>
      getFieldResponse<FieldResponse>(rootId as number, fieldTag).then(
        arrayAnswerAsDropdownModelCreator
      )
  });
};

export const useRelatedWorkflow = (rootId: number | null) => {
  const fieldTag = "related_types";
  return useQuery({
    enabled: !!rootId,
    retry: 0,
    queryKey: ["relatedWorkflow", rootId, fieldTag],

    queryFn: () =>
      getFieldResponse<FieldResponse>(rootId as number, fieldTag).then(
        arrayAnswerAsDropdownModelCreator
      )
  });
};

export const useLCDataItemsQuery = (rootId: number | null) => {
  const fieldTag = "extra_fields_json";
  const params = { label: "label", value: "label" };
  return useQuery({
    enabled: !!rootId,
    retry: 0,
    queryKey: ["lcDataItems", rootId, fieldTag, params],
    queryFn: () => getEditableTableColumn(rootId as number, fieldTag, params)
  });
};

export const useABProcessKindsQuery = () =>
  useQuery({
    retry: 0,
    queryKey: ["workflowsId"],
    queryFn: getABProcessKinds
  });

export const useDynamicUserGroups = (rootId: number | null) => {
  const fieldTag = "user_roles_permissions_dynamic";
  const params = { label: "user_role", value: "user_role" };
  return useQuery({
    enabled: !!rootId,
    retry: 0,
    queryKey: ["dynamicUserGroups", rootId, fieldTag, params],
    queryFn: () => getEditableTableColumn(rootId as number, fieldTag, params)
  });
};

export const useStaticUserPermissionGroups = (rootId: number | null) => {
  const fieldTag = "user_roles_permissions_client";
  const params = { label: "user_role", value: "user_role" };
  return useQuery({
    enabled: !!rootId,
    retry: 0,
    queryKey: ["staticUserPermissionGroups", rootId, fieldTag, params],
    queryFn: () => getEditableTableColumn(rootId as number, fieldTag, params)
  });
};

export const usePlatformUserGroups = (
  extraConfig?: UseQueryOptions<OptionItem<string>[]>
) => {
  const { data: workflowId } = usePlatformSettingsProcessID();
  const fieldTag = "user_roles_permissions_client";
  const params = {
    label: "user_role",
    value: "user_role"
  };
  return useQuery({
    enabled: !!workflowId,
    retry: 0,
    ...extraConfig,
    queryKey: ["platformUserGroups", workflowId, fieldTag, params] as QueryKey,
    queryFn: () =>
      getEditableTableColumn(workflowId as number, fieldTag, params)
  });
};

export const usePlatformWorkflowStatuses = (
  extraConfig?: UseQueryOptions<OptionItem<string>[]>
) => {
  const { data: id } = usePlatformSettingsProcessID();
  const fieldTag = "workflow_statuses_table";
  const params = {
    label: "label",
    value: "status_tag"
  };
  return useQuery({
    enabled: !!id,
    retry: 0,
    ...extraConfig,
    queryKey: ["platformWorkflowStatuses", id, fieldTag, params] as QueryKey,
    queryFn: () => {
      if (!id) {
        return Promise.reject("id is required!");
      }
      return getEditableTableColumn(id, fieldTag, params);
    }
  });
};

export const usePlatformNotificationTemplates = (
  extraConfig?: UseQueryOptions<
    UseQueryReturnType<typeof getWorkflowsAsDropdown<string>>
  >
) => {
  const { data: id } = usePlatformSettingsProcessID();

  const params = {
    parent: id || null,
    kind_tag: "email-templates",
    field_tags: ["temp_title", "slug"],
    label_tags: ["label", "value"],
    label: "temp_title",
    value: "slug"
  };

  return useQuery({
    enabled: !!id,
    retry: 0,
    ...extraConfig,
    queryKey: ["platformNotificationTemplates", params] as QueryKey,
    // @ts-expect-error | FIXME: params doesn't have root attr but service has params.root check
    queryFn: () => getWorkflowsAsDropdown(params)
  });
};

export const usePlatformExtraJSONs = (
  extraConfig?: UseQueryOptions<
    UseQueryReturnType<typeof getWorkflowsAsDropdown<string>>
  >
) => {
  const { data: id } = usePlatformSettingsProcessID();

  const params: any = {
    parent: id || null,
    kind_tag: "extra-json",
    field_tags: ["name", "tag"],
    label_tags: ["label", "value"],
    label: "name",
    value: "tag"
  };

  return useQuery({
    enabled: !!id,
    retry: 0,
    ...extraConfig,
    queryKey: ["platformExtraJSONs", params] as QueryKey,
    queryFn: () => getWorkflowsAsDropdown(params)
  });
};

export const usePlatformCommentFlagOptions = (
  extraConfig?: UseQueryOptions<
    UseQueryReturnType<typeof getWorkflowsAsDropdown<string>>
  >
) => {
  const { data: id } = usePlatformSettingsProcessID();

  const params: any = {
    parent: id || null,
    kind_tag: "comment-flag-options",
    field_tags: ["flag_name", "flag_tag"],
    label_tags: ["label", "value"],
    label: "flag_name",
    value: "flag_tag"
  };

  return useQuery({
    enabled: !!id,
    retry: 0,
    ...extraConfig,
    queryKey: ["platformCommentFlagOptions", params] as QueryKey,
    queryFn: () => getWorkflowsAsDropdown(params)
  });
};

export const usePlatformIntegrationConfigs = () =>
  useQuery({
    retry: 0,
    queryKey: ["platformIntegrationConfigs"],
    queryFn: getPlatformIntegrationConfigs
  });

/**
 * Returns the "field" workflows that are part of app builder, similar
 * to useFieldWorkflowsQuery, with only addition that we have Field-Types here
 * as well.
 * @param rootId
 */
export const useFieldWorkflowsQueryWithType = (rootId: number | null) =>
  useQuery({
    staleTime: 5 * 60 * 1000,
    retry: false,
    enabled: Boolean(rootId),
    queryKey: ["constraintFields-withType", rootId],
    queryFn: () => getFieldWorkflowWithType(rootId)
  });

export const useBulkActionFieldQuery = (rootId: number | null) =>
  useQuery({
    retry: 0,
    queryKey: ["bulkActionsField", rootId, "bulk_actions"],

    queryFn: () => {
      if (!rootId) {
        return Promise.reject("rootId is required!");
      }
      return getFieldResponse(rootId, "bulk_actions").then(
        bulkActionFieldModelCreator
      );
    }
  });

/**
 * Creates a duplicate workflow, from an app builder component
 * (step/stepgroup/field) workflow.
 * @param extraConfig
 */
export const useDuplicateWorkflowMutation = (
  extraConfig: UseMutationExtraConfigType
) => useMutation(duplicateWorkflow, extraConfig);

export const useCreatePackageVersionMutation = (
  extraConfig: UseMutationExtraConfigType
) => useMutation(createPackageVersion, extraConfig);

export const useDeployPackageVersionMutation = (
  extraConfig: UseMutationExtraConfigType
) => useMutation(deployPackageVersion, extraConfig);

/**
 * @deprecated
 */
export const useSaveReleasePackageVersionMutation = (
  extraConfig: UseMutationExtraConfigType
) => useMutation(createPackageVersion, extraConfig);

/**
 * @deprecated
 */
export const useCreateHotpatchPackageVersionMutation = (
  extraConfig: UseMutationExtraConfigType
) => useMutation(createHotpatchPackageVersion, extraConfig);

/**
 * Provides a custom hook wrapped around useQuery for retrieving list of
 * versions created for an app.
 * @param appId
 */
export const useAppVersions = (appId?: number) =>
  useQuery({
    retry: false,
    enabled: Boolean(appId),
    queryKey: ["appVersions", appId],

    queryFn: () => {
      if (!appId) {
        return Promise.reject("appId is required!");
      }
      return getAppVersions(appId);
    }
  });

/**
 * Provides a custom hook wrapped around useQuery for retrieving
 * platform settings workflow ID
 */
export const usePlatformSettingsProcessID = () =>
  useQuery({
    staleTime: Infinity,
    retry: false,
    queryKey: ["platformSettingsProcessID"],
    queryFn: getPlatformSettingsProcessID
  });

/*
 * Provides a list of all the versions of all the apps that are deployed
 * on at least 1 environment.
 */
export const useDeployedAppVersions = () =>
  useQuery({
    retry: false,
    queryKey: ["deployedApps"],
    queryFn: getDeployedApps
  });

export const useSaveAppVersionConfigMutation = (
  extraConfig?: UseMutationExtraConfigType
) => useMutation(saveAppVersionConfig, extraConfig);

export const usePublishAppVersionConfigMutation = (
  extraConfig?: UseMutationExtraConfigType
) => useMutation(publishAppVersionConfig, extraConfig);

export const useStudioTemplates = () => {
  const { data: id } = usePlatformSettingsProcessID();
  const kindId = useKindId("reusable-components");
  const params: GetChildWorkflowServiceParams = {
    parents: id || undefined,
    kind: kindId
  };
  return useQuery({
    enabled: !!(id && kindId),
    retry: 0,
    queryKey: ["studio-templates", params],
    queryFn: () => readonlyTableServices.getChildWorkflows(params)
  });
};

export const useStudioTemplateCategories = () => {
  return useQuery({
    enabled: true,
    retry: 0,
    queryKey: ["studio-template-categories"],
    queryFn: getStudioTemplateCategories
  });
};

export const useStudioTemplateSources = () => {
  return useQuery({
    enabled: true,
    retry: 0,
    queryKey: ["studio-template-sources"],
    queryFn: getStudioTemplateSources
  });
};

export const useCreateFromReusableComponent = (
  extraConfig?: UseMutationExtraConfigType
) => useMutation(createFromReusableComponent, extraConfig);
