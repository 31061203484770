import {
  apiBaseURL,
  apiBaseURLActivityLog,
  apiBaseURLV2,
  apiBaseURLV3,
  apiBaseStudio,
  apiBaseURLExt,
  apiBaseURLMimir,
  apiStudioSync,
  apiBaseStudioV2,
  apiBaseTemplatesV2,
  getCommsAPIOrigin
} from "./config";
import { userUtilities } from "main/src/js/utils/user";
import { store } from "main/src/js/_helpers/store";
import { onlyUpdateUserInfo } from "main/src/modules/LoginPage/loginSlice";

import { showNotification } from "@certa/common";
import { intl } from "main/src/modules/common/components/IntlCapture.js";
import { getAcitivityLogsOrigin } from "@certa/network/src/config";
import { isHeyCertaAPI } from "./utils";

const fetchQueue = new Set(); // TODO: used to deferred fetch, which is pending to be added.
const activityLogServiceOrigin = getAcitivityLogsOrigin();

/**
 * Simple wrapper around fetch, that enables it to maintain a fetchQueue,
 * which is used for queuing any deferring fetch requests after the queue is
 * settled
 * @param resource
 * @param init
 */
const fetchWrapper = <T>(resource: string, init?: RequestInit) => {
  const request = new Promise<T>((resolve, reject) => {
    fetch(resource, init)
      .then(async response => {
        if (response.status === 401) {
          if (
            resource.indexOf("users/me") === -1 &&
            resource.indexOf("users/permissions/") === -1
          ) {
            // We don't want to get stuck in redirect loop if auto login
            // fails for any reason.
            userUtilities.postLogoutAction({ addNextURL: true });
          }
        } else if (
          response.status === 403 &&
          !isHeyCertaAPI(resource) &&
          !resource.includes("subscriptions/")
        ) {
          // TODO: Figure out later! We cannot use useIntl since this is not a hook
          // hence using a deprecated method for now
          showNotification({
            type: "warning",
            message: intl.formatMessage({
              id: "permissions.insufficientPermissions",
              defaultMessage: "Insufficient Permissions"
            })
          });
          // In ideal case we would be rejecting the promising
          // and expecting that the called would handle it
          // and each called might handle it in a different way
          // but since that would require a major change (at every place where APIFetch is being used)
          // we are returning a random string instead of resolving/rejecting the promise
          // to keep the promise in a pending state always
          // and since in most of the cases the reference to the promise will be lost
          // it will be automatically garbage collected, avoiding memory leaks
          return "Random string to keep the promise in pending state forever";
        } else if (response.status === 503) {
          throw new Error("Service Unavailable");
        }
        resolve(await handleResponse(response));
      })
      .catch(error => {
        reject(error.message || error);
      });
  }).finally(() => {
    // Remove call from fetch queue when the call is finished
    fetchQueue.delete(request);
  });

  fetchQueue.add(request);

  return request;
};

/**
 * Simple wrapper around fetch for Comms Microservice
 * @param resource
 * @param init
 */
const fetchWrapperComms = <T>(resource: string, init?: RequestInit) => {
  const request = new Promise<T>((resolve, reject) => {
    fetch(resource, init)
      .then(async response => {
        if (response.status === 401) {
          console.error("Unauthorized request to Comms API");
        } else if (response.status === 403) {
          // TODO: Figure out later! We cannot use useIntl since this is not a hook
          // hence using a deprecated method for now
          showNotification({
            type: "warning",
            message: intl.formatMessage({
              id: "permissions.insufficientPermissions",
              defaultMessage: "Insufficient Permissions"
            })
          });
          // In ideal case we would be rejecting the promising
          // and expecting that the caller would handle it
          // and each called might handle it in a different way
          // but since that would require a major change (at every place where APIFetch is being used)
          // we are returning a random string instead of resolving/rejecting the promise
          // to keep the promise in a pending state always
          // and since in most of the cases the reference to the promise will be lost
          // it will be automatically garbage collected, avoiding memory leaks
          return "Random string to keep the promise in pending state forever";
        } else if (response.status === 503) {
          console.error("Comms Service Unavailable");
          return;
        }
        resolve(await handleResponse(response));
      })
      .catch(error => {
        reject(error.message || error);
      });
  });
  return request;
};

const handleResponse = async (response: Response) => {
  /**
   *  When a concurrent user impersonation session already exists
   *  BE should return a status of 434 along with 3 params
   *  { error_tag, impersonator, impersonatee }
   */
  if (response.status === 434) {
    const jsonFromBackend = await response.json();
    const message = intl.formatMessage(
      {
        id: `impersonation.${jsonFromBackend?.error_tag}`,
        defaultMessage: "An error occurred! Please contact the administrator."
      },
      {
        impersonator: jsonFromBackend?.impersonator,
        impersonatee: jsonFromBackend?.impersonatee
      }
    );
    return Promise.reject(message);
  }

  if (!response.ok) {
    return Promise.reject(response);
  }
  // in case the response status is 204, there is no JSON response

  /**
   * - User Impersonation session logic:
   *   if impersonateeId is incoming, see if session storage has one,
   *   if not then it's a fresh session, persist it
   *   but if the session already has one, check for equality
   *   if matched do nothing, else...bummer! session hijacked! reload page!
   */
  const impersonateeId = response.headers.get("X-IMPERSONATOR-ID");
  if (impersonateeId) {
    const sessionImpersonateeId = sessionStorage.getItem("impersonateeId");
    if (!sessionImpersonateeId) {
      sessionStorage.setItem("impersonateeId", impersonateeId);
    } else if (
      sessionImpersonateeId &&
      sessionImpersonateeId !== impersonateeId
    ) {
      sessionStorage.removeItem("impersonateeId");
      window.location.reload();
      Promise.reject(response);
    }
  } else if (!impersonateeId && sessionStorage.getItem("impersonateeId")) {
    // ActivityLog service doesn't return impersonation response headers
    const isActivityLogServiceResponse = response.url.startsWith(
      activityLogServiceOrigin
    );
    if (!isActivityLogServiceResponse) {
      // IMPORTANT: Update user details here, refetch user details and permissions here
      store.dispatch(onlyUpdateUserInfo());
      // defensive cleanup
      sessionStorage.removeItem("impersonateeId");
    }
  }

  /**
   * - This is done to avoid error if the api doesn't returns any response
   *   on successful completion.
   * - A Response is expected on 200 status and without it we receive json syntax error.
   * - So to avoid this error we are cathicinh this error here and resolving
   *   the promise in the catch block
   */
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json
      ? response
          .json()
          .then(json => Promise.resolve(json))
          .catch(() => null)
      : Promise.resolve(response);
  } else {
    return Promise.resolve(response);
  }
};

/**
 * Just in case the URL is given in an unacceptable format, we have to throw
 * an error immediately.
 * @param endpoint
 */
const validateAPIEndpoint = (endpoint: string) => {
  if (endpoint.startsWith("http") || endpoint.startsWith("/")) {
    throw new Error(
      "Invalid URL provided to APIFetch, must be a relative URL not starting with /"
    );
  }
};

/**
 * A very faithful fetch function that appends the API base URL for older
 * v1 API and forwards that to the fetchWrapper
 * @param params
 * @returns {Promise<Response | never>}
 */
const APIFetch = <T = unknown>(resource: string, init?: RequestInit) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${apiBaseURL}${resource}`;
  return fetchWrapper<T>(resourceWithPrefix, init);
};

const APIFetchComms = <T = unknown>(resource: string, init?: RequestInit) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${getCommsAPIOrigin()}/${resource}`;
  return fetchWrapperComms<T>(resourceWithPrefix, init);
};

/**
 * A very faithful fetch function that appends the API base URL for older
 * v1 API and forwards that to the fetchWrapper
 * @param params
 * @returns {Promise<Response | never>}
 */
const APIFetchExt = <T = unknown>(resource: string, init?: RequestInit) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${apiBaseURLExt}${resource}`;
  return fetchWrapper<T>(resourceWithPrefix, init);
};

/**
 * A very faithful fetch function that appends the API base URL for newer
 * v2 API and forwards that to the fetchWrapper
 * @param params
 * @returns {Promise<Response | never>}
 */
const APIFetchV2 = <T = unknown>(resource: string, init?: RequestInit) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${apiBaseURLV2}${resource}`;
  return fetchWrapper<T>(resourceWithPrefix, init);
};

/**
 * A very faithful fetch function that appends the API base URL for newer
 * v3 API and forwards that to the fetchWrapper
 * @param params
 * @returns {Promise<Response | never>}
 */
const APIFetchV3 = <T = unknown>(resource: string, init?: RequestInit) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${apiBaseURLV3}${resource}`;
  return fetchWrapper<T>(resourceWithPrefix, init);
};

const APIFetchStudio = <T = unknown>(resource: string, init?: RequestInit) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${apiBaseStudio}${resource}`;
  return fetchWrapper<T>(resourceWithPrefix, init);
};

const APIFetchStudioV2 = <T = unknown>(
  resource: string,
  init?: RequestInit
) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${apiBaseStudioV2}${resource}`;
  return fetchWrapper<T>(resourceWithPrefix, init);
};

const APIFetchTemplatesV2 = <T = unknown>(
  resource: string,
  init?: RequestInit
) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${apiBaseTemplatesV2}${resource}`;
  return fetchWrapper<T>(resourceWithPrefix, init);
};

const APIFetchStudioSync = <T = unknown>(
  resource: string,
  init?: RequestInit
) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${apiStudioSync}${resource}`;
  return fetchWrapper<T>(resourceWithPrefix, init);
};

export const APIFetchMimir = <T = unknown>(
  resource: string,
  init?: RequestInit
) => {
  validateAPIEndpoint(resource);
  const resourceWithPrefix = `${apiBaseURLMimir}${resource}`;
  return fetchWrapper<T>(resourceWithPrefix, init);
};

/**
 * If in case we need to implement third party API calls or directly pass the url without prefixes
 * @param resource
 * @param init
 */
const genericFetch = <T = unknown>(resource: string, init?: RequestInit) => {
  if (resource.startsWith("/")) {
    throw new Error(
      "Invalid URL provided to genericFetch, must be a relative URL not starting with /"
    );
  }
  return fetchWrapper<T>(resource, init);
};

/**
 * A fetch function that keeps the unauthorized users out for serverless API calls
 * @param params
 * @returns {Promise<Response | never>}
 */

// for "AUDIT_LOG"
const serverlessAPIFetch = <T = unknown>(
  resource: string,
  init?: RequestInit
) => {
  validateAPIEndpoint(resource);
  const baseUrl = `${apiBaseURLActivityLog}${resource}`;
  return fetchWrapper<T>(baseUrl, init);
};

export {
  APIFetch,
  APIFetchComms,
  APIFetchExt,
  APIFetchV2,
  APIFetchV3,
  genericFetch,
  serverlessAPIFetch,
  APIFetchStudio,
  APIFetchStudioSync,
  APIFetchStudioV2,
  APIFetchTemplatesV2
};
