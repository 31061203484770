import type { FC } from "react";
import React from "react";
import { Stack } from "../layout/Stack";
import { Image } from "@certa/blocks";

export const ProcessLogo: FC<{
  logoURL: string;
  name: string | undefined;
  height?: number;
  width?: number;
}> = props => {
  const { logoURL, name, height = 40, width = 40 } = props;
  return (
    <Stack
      align="center"
      justify="center"
      style={{
        height,
        width,
        // Will only be visible if there is border/background color
        borderRadius: "50%",
        padding: "var(--s0)"
      }}
    >
      <Image
        src={logoURL}
        alt={name || ""}
        style={{
          width: "100%",
          borderRadius: "50%"
        }}
      />
    </Stack>
  );
};
