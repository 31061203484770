import React from "react";
import type { ActivityLogModelType } from "@certa/queries";
import type { FC } from "react";
import { Text, Stack } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";

export const ActivityLogBulkAction: FC<{ item: ActivityLogModelType }> = ({
  item
}) => {
  const intl = useIntl();
  return (
    <Stack direction="vertical">
      <span>
        <Text variant="p1-bold" color="neutral-100">
          {item.actorEmail}{" "}
        </Text>
        <Text variant="p1-bold" color="neutral-70">
          {item.actionType.replace(/_/g, " ")}{" "}
        </Text>
        <Text variant="p1-bold" color="neutral-100">
          {item.details?.action_name}{" "}
        </Text>
        <Text variant="p1-bold" color="neutral-70">
          {intl.formatMessage({
            id: "avLogs.of",
            defaultMessage: "of"
          })}{" "}
        </Text>
        <Text variant="p1-bold" color="neutral-100">
          {item.workflowKind}
        </Text>
        <Text variant="p1-bold" color="neutral-70">
          {` - `}
        </Text>
        <Text variant="p1-bold" color="neutral-100">
          {item.workflowName}
        </Text>
      </span>
      {/* TODO add Timestamp if require */}
      {/* <Timestamp timestamp={item.timestamp.$date} fontSize="var(--p3)" /> */}
    </Stack>
  );
};
