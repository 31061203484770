import { Tooltip as AntTooltip, type TooltipProps } from "@certa/blocks/thanos";
import type { TaskDetails } from "@certa/queries";
import { css } from "emotion";
import { ProcessTooltip } from "./ProcessTooltip";

type ProcessLCDataOverlayProps = {
  disabled?: boolean;
  children: React.ReactNode;
  processDetails: TaskDetails;
  trigger?: "hover" | "click";
  placement?: TooltipProps["placement"];
};

const overlayClassName = css`
  max-width: 450px !important;
  .ant-tooltip-content {
    .ant-tooltip-arrow:before {
      background: var(--neutral-0);
    }
    .ant-tooltip-inner {
      background: var(--neutral-0);
      color: var(--neutral-100);
      box-shadow: 0px 2px 16px rgba(0, 22, 78, 0.1);
      border-radius: var(--big-border-radius);
      padding: 0;
    }
  }
`;

// Todo: Use new popover - https://thevetted.atlassian.net/browse/PLAT-14732
const ProcessLCDataOverlay = ({
  disabled,
  children,
  processDetails,
  trigger = "click",
  placement = "topLeft"
}: ProcessLCDataOverlayProps) => {
  const {
    lcData,
    id: processId,
    name: processName,
    logo,
    status
  } = processDetails || {};

  const tooltipLcData = status
    ? [...(lcData || []), { label: "Status", value: status }]
    : lcData;

  const overlay = (
    <ProcessTooltip
      lcData={tooltipLcData ?? []}
      id={processId}
      name={processName}
      logoURL={logo}
    />
  );

  if (disabled) return <div>{children}</div>;

  return (
    <AntTooltip
      overlay={overlay}
      trigger={[trigger]}
      placement={placement}
      overlayClassName={overlayClassName}
    >
      <span>{children}</span>
    </AntTooltip>
  );
};

export { ProcessLCDataOverlay };
