import { ClassNames } from "@emotion/core";
import type { FC, VFC, ReactNode, CSSProperties } from "react";
import React from "react";
import { Drawer as AntDrawer } from "antd";
import type { DrawerProps as AntDrawerProps } from "antd/lib/drawer/index";
import styled from "@emotion/styled";
import { Stack } from "../Stack";
import { Text } from "../Typography/Text";
import { HoverAbleSVG } from "../HoverableSVG";
import { Close, ChevronLeft } from "@certa/icons";
import { useIntl } from "react-intl";
import { Button } from "../Button/Button";
import { Z_INDEX } from "../../constants";
import { getLayoutContainer } from "../../utils";

export type DrawerProps = AntDrawerProps & {
  /** Placement of the drawer */
  placement?: "left" | "right"; // because top and bottom placements are not handled
  /** Whether to use New UI */
  /** @deprecated This prop is no longer in use. */
  isCERTA2Enabled: boolean;
  /** Callback that runs when back button is clicked. Setting this will make back button visible. */
  onBack?: () => void;
  /** Content */
  children?: ReactNode;
  /** Subtitle of drawer */
  subtitle?: string;
  /** Content styles */
  contentStyle?: CSSProperties;
  /** Extra element to insert to the right of the title */
  titleExtension?: ReactNode;
  /** Back button label */
  backButtonLabel?: string;
  removeMask?: boolean;
};

export const CustomDrawer: FC<DrawerProps> = props => {
  const {
    children,
    title,
    closable = true,
    onClose,
    isCERTA2Enabled = false,
    onBack,
    headerStyle,
    bodyStyle,
    contentStyle,
    zIndex,
    width,
    placement = "right",
    subtitle,
    titleExtension,
    getContainer,
    backButtonLabel,
    removeMask,
    ...restProps
  } = props;

  const intl = useIntl();

  const drawerOtherProps = isCERTA2Enabled
    ? { ...restProps, closable: false }
    : { title, closable, headerStyle, bodyStyle, ...restProps };

  const headerVisible = !!title || !!subtitle || !!onBack;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <AntDrawer
          {...drawerOtherProps}
          zIndex={zIndex ?? isCERTA2Enabled ? Z_INDEX["zindex-modal"] : 1100}
          width={width ?? 600}
          placement={placement}
          onClose={onClose}
          // @ts-expect-error: suppressed type error since this code is not actively maintained
          id="drawer-container-new"
          getContainer={getContainer || (() => getLayoutContainer())}
        >
          {isCERTA2Enabled ? (
            <Stack
              direction="vertical"
              className={css`
                height: 100%;
              `}
              data-testid="drawerOverlayContent"
            >
              {/* Drawer Header Starts*/}
              <Stack
                className="drawer-header"
                // refer: https://5dab585317aff30020354d35-lbborijysg.chromatic.com/?path=/story/components-drawer--no-title
                gutter={headerVisible ? "s4 s4 s4 s4" : "s00"}
                style={headerStyle} // style from the props.
              >
                <Stack
                  className="drawer-header"
                  itemWidth="100%"
                  direction="horizontal"
                  justify="space-between"
                  align="center"
                >
                  {/* Stack 1st: BackButton and Title */}
                  {headerVisible && (
                    <Stack>
                      {/* BackButton */}
                      {!!onBack && (
                        <Stack align="center">
                          <Button
                            type="link"
                            icon={<ChevronLeft />}
                            onClick={onBack}
                            size="small"
                            className={css`
                              && {
                                padding: 0;
                              }
                            `}
                          >
                            {backButtonLabel ??
                              intl.formatMessage({
                                id: "comments.backButton",
                                defaultMessage: "Back"
                              })}
                          </Button>
                        </Stack>
                      )}

                      {/* SubTitle and Title Stacked Vertically*/}
                      <Stack
                        direction="vertical"
                        justify="center"
                        className={css`
                          margin-left: var(--s4);
                          flex-grow: 2;
                        `}
                      >
                        {/* Subtitle */}
                        {subtitle && (
                          <Stack gutter="s00 s00 s00 s00">
                            <Text variant="p2-bold-upper" color="neutral-70">
                              {subtitle}
                            </Text>
                          </Stack>
                        )}

                        {/* Title */}
                        {title &&
                          (React.isValidElement(title) ? (
                            title
                          ) : (
                            <Stack align="center" itemWidth="100%">
                              <Text variant="h2-bold" color="neutral-100">
                                {title}
                              </Text>
                            </Stack>
                          ))}
                      </Stack>
                    </Stack>
                  )}

                  {/* Stack 2: Title Extension */}
                  <Stack
                    className={css`
                      align-items: flex-end;
                      flex-grow: 2;
                      justify-content: flex-end;
                    `}
                  >
                    {/* Title Extension */}
                    {headerVisible && titleExtension && (
                      <Stack>{titleExtension}</Stack>
                    )}
                  </Stack>

                  {/* Close Button, should be visible irrespective of headerVisible */}
                  {closable && (
                    <CloseButton
                      onClose={onClose}
                      zIndex={zIndex}
                      positionAbsolute={!headerVisible} // required to align with step-body-content, when header is not visible.
                    />
                  )}
                </Stack>
              </Stack>
              {/* Drawer Header Ends */}

              {title && headerVisible && <Divider placement={placement} />}
              <Stack
                direction="vertical"
                gap="s4"
                className={cx(
                  "drawer-new-content",
                  css`
                    overflow-y: auto;
                    // as per figma we need total visible margin of 24px
                    // margin-top calculation = 24px - 16px (stepbody-top-padding) = 8px
                    margin-top: var(--s2);
                  `
                )}
                gutter={`${subtitle && !title ? "s4" : "s6"} s6 s6 s6`}
                style={contentStyle}
              >
                {children}
              </Stack>
            </Stack>
          ) : (
            children
          )}
        </AntDrawer>
      )}
    </ClassNames>
  );
};

const CloseButton: FC<{
  onClose: DrawerProps["onClose"];
  zIndex?: number;
  positionAbsolute?: boolean;
}> = ({ onClose, zIndex, positionAbsolute = false }) => (
  <ClassNames>
    {({ css, cx }) => (
      <Stack
        align="center"
        className={cx(
          "hide-print",
          css`
            z-index: ${(zIndex ?? Z_INDEX["zindex-modal"]) + 10};
          `,
          positionAbsolute &&
            css`
              position: absolute;
              top: var(--s5);
              right: var(--s6);
            `
        )}
      >
        <HoverAbleSVG
          onClick={e => {
            if (onClose) {
              onClose(
                e as React.MouseEvent<
                  HTMLDivElement | HTMLButtonElement,
                  MouseEvent
                >
              );
            }
          }}
          icon={Close}
        />
      </Stack>
    )}
  </ClassNames>
);

const Divider: VFC<{ placement: DrawerProps["placement"] }> = ({
  placement
}) => (
  <ClassNames>
    {({ css }) => (
      <Stack
        className={css`
          border-bottom: 1px solid var(--neutral-20);
          ${placement === "left"
            ? "transform: translateX(calc(-1 * var(--s6)))"
            : ""};
          ${placement === "right" ? "transform: translateX(var(--s6))" : ""};
        `}
      />
    )}
  </ClassNames>
);

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Drawer } from '@certa/catalyst';
 */
// Note no css variable support here (since drawer render outside design container)
export const Drawer = styled(CustomDrawer)`
  &.ant-drawer {
    ${props => props.removeMask && "pointer-events: none"};

    .ant-drawer-body {
      ${props => props.isCERTA2Enabled && "padding: 0"};
      overflow-x: hidden;
    }
    .ant-drawer-mask {
      ${props =>
        props.isCERTA2Enabled && "background-color: rgba(0, 0, 0, 0.1)"};
      ${props => props.removeMask && "display: none"};
    }
    .ant-drawer-content-wrapper {
      box-shadow: none;
      ${props => props.removeMask && "pointer-events: auto"};
    }
    .drawer-new-content {
      flex: 1;
      min-height: 0;
      position: relative;
    }
    .ant-drawer-content {
      box-shadow: 0px 2px 16px rgba(0, 22, 78, 0.1);
    }
    &.ant-drawer-right {
      .ant-drawer-content {
        border-radius: 8px 0px 0px 8px;
      }
    }
    &.ant-drawer-left {
      .ant-drawer-content {
        border-radius: 0px 8px 8px 0px;
      }
    }
    .small-overlay-title {
      ${props => props.onBack && "padding: 0"};
    }
  }
  --label: CustomDrawer;
`;
