import { css, cx } from "emotion";
import type { FC } from "react";
import styled from "@emotion/styled";
import React from "react";

type ELoaderSize = "large" | "medium" | "small" | "extra-small";

type LoaderProps = {
  color?: string;
  size?: ELoaderSize;
  className?: string;
};

const Loader: FC<
  LoaderProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
> = props => {
  const { color = "var(--brand)", size = "medium", className, ...rest } = props;
  return (
    <div
      role="alert"
      aria-busy="true"
      className={
        cx(
          ldsEllipsisCSS[size],
          css`
            & div {
              background: ${color};
            }
          `
        ) +
        " " +
        className
      }
      data-testid={"loader"}
      {...rest}
    >
      <LoaderChildren />
      <LoaderChildren />
      <LoaderChildren />
      <LoaderChildren />
    </div>
  );
};

export { Loader };

const ldsEllipsisCSS = {
  large: css`
    display: inline-block;
    position: relative;
    width: 5em;
    height: 1em;
    font-size: 7px;
  `,
  medium: css`
    display: inline-block;
    position: relative;
    width: 5em;
    height: 1em;
    font-size: 6px;
  `,
  small: css`
    display: inline-block;
    position: relative;
    width: 5em;
    height: 1em;
    font-size: 5px;
  `,
  "extra-small": css`
    display: inline-block;
    position: relative;
    width: 5em;
    height: 1em;
    font-size: 3px;
  `
};

const LoaderChildren = styled.div`
  position: absolute;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #148cd6;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    animation: lds-ellipsis-scale-up-animation 0.9s infinite;
  }

  &:nth-child(2) {
    animation: lds-ellipsis-translate-animation 0.9s infinite;
  }

  &:nth-child(3) {
    left: 2em;
    animation: lds-ellipsis-translate-animation 0.9s infinite;
  }

  &:nth-child(4) {
    left: 4em;
    animation: lds-ellipsis-scale-down-animation 0.9s infinite;
  }

  @keyframes lds-ellipsis-scale-up-animation {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis-scale-down-animation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis-translate-animation {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(2em, 0);
    }
  }
`;
