import { css } from "emotion";

export const CertaTableUI = css`
  .ant-table-container {
    border: none;
  }
  th {
    background: transparent !important;
    border-right: none;
    font-weight: 600 !important;
    font-size: 13px;
    line-height: 20px;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    overflow-wrap: normal !important;
    min-height: 40px !important;
  }
  td {
    border: none !important;
    color: #757575 !important;
    font-weight: normal !important;
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    transition: background 0s !important;
  }
  tr:nth-child(even) {
    background: #f6f8f9;
  }
  tr:hover {
    td {
      background-color: #e0f6ff !important;
    }
  }
  td:nth-child(1) {
    font-weight: 600 !important;
    color: #0f0f0f !important;
  }
  td:first-child,
  th:first-child {
    padding-left: var(--s8) !important;
  }
`;
