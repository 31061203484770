import {
  useGodaamSyncState,
  dedupeArray,
  useAppSelector,
  Godaam
} from "@certa/common";
import { MAX_SEARCHES_TO_STORE } from "./constants";

export const useRecentSearches = () => {
  const STORAGE_KEY = "recentSearches";
  const [recentSearches, setRecentSearches] = useGodaamSyncState<
    Record<string, string[]> | undefined
  >(STORAGE_KEY);

  const authentication = useAppSelector(state => state?.authentication);

  const userId = authentication.user.id;
  const userRecentSearch = userId ? recentSearches?.[userId] || [] : [];

  /**
   * It does the following things -
   * 1. Stores a max of 15 recent searches. 15 is a random number chosen to not
   *    bloat localStorage with a lot of data.
   * 2. The latest search is always at the top of the list.
   * 3. While saving it also make sure to de-duplicate the searches.
   */
  const setUserRecentSearch = (searches: string[]) => {
    if (userId) {
      const sanitizedSearches = searches.map(search =>
        search.trim().toLowerCase()
      );
      /**
       * The following code make sure to store the updated value
       * in the local storage synchronously.
       * Whereas the `setState`(setRecentSearches) and `useEffect`(useGodaamSyncState)
       * do it asynchronously. This is the default React's behavior.
       * Asynchronous behavior is a problem in this case because we are
       * routing the user to the search page on on the click which
       * unmounts the current component thereby discarding the async tasks.
       */
      const updatedRecentSearches = {
        ...recentSearches,
        [userId]: dedupeArray([
          ...sanitizedSearches,
          ...(recentSearches?.[userId] || [])
        ]).slice(0, MAX_SEARCHES_TO_STORE)
      };
      Godaam[STORAGE_KEY] = JSON.stringify(updatedRecentSearches);
      setRecentSearches(updatedRecentSearches);
    }
  };

  return {
    recentSearches: userRecentSearch,
    setRecentSearches: setUserRecentSearch
  };
};
