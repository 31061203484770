export const DEFAULT_PAGINATION_SIZE = 20;
export const DEFAULT_OFFSET = 0;

export const usePaginationOffset = (currentPage: number) => {
  if (currentPage === 1) return 0;

  if (currentPage > 1) {
    const offset = (currentPage - 1) * DEFAULT_PAGINATION_SIZE;
    return offset;
  }

  return 0;
};
