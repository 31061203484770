/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import { Stack, Text } from "@certa/blocks/thanos";
import type { WorkflowItem } from "@certa/queries";
import { useGetWorkflowList as useGetProcessList } from "@certa/queries";
import { Loader } from "@certa/blocks";
import { useIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";
import { ProcessItem } from "./ProcessItem";

const ChildProcessList: FC<{
  processTypeId: number;
  selectedProcessId: number;
}> = ({ processTypeId, selectedProcessId }) => {
  const intl = useIntl();

  const {
    data: childProcessData,
    status,
    hasNextPage,
    fetchNextPage
  } = useGetProcessList({
    kind_id: processTypeId,
    parent: selectedProcessId
  });

  if (status === "idle" || status === "loading") {
    return (
      <Stack align="center" justify="center" gutter="s3">
        <Loader />
      </Stack>
    );
  }

  if (status === "error") {
    return (
      <Stack align="center" justify="center" gutter="s3">
        <Text variant="h2-regular" color="red">
          {intl.formatMessage({
            id: "notificationInstances.somethingWentWrong",
            defaultMessage: "Something went wrong"
          })}
          .
        </Text>
      </Stack>
    );
  }

  return (
    <Stack
      direction="vertical"
      css={css`
        max-height: 300px;
        overflow-y: auto;
        ::-webkit-scrollbar {
          display: none;
        }
      `}
      gutter="s00 s4 s00 s6"
    >
      <Stack
        css={css`
          background-color: var(--neutral-5);
          border-radius: var(--big-border-radius) var(--big-border-radius) 0px
            0px;
          border: 1px solid var(--neutral-20);
        `}
        gutter="s4"
      >
        <Text color="neutral-70" variant="p2-medium-upper">
          {intl.formatMessage({
            id: "familyContainer.childProcessTable.process",
            defaultMessage: "PROCESS"
          })}
        </Text>
      </Stack>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => fetchNextPage()}
        hasMore={hasNextPage || false}
        useWindow={false}
        loader={
          <Stack
            align="center"
            justify="center"
            css={css`
              margin-top: var(--s5);
            `}
          >
            <Loader />
          </Stack>
        }
        threshold={250}
      >
        {childProcessData?.pages?.map((record, recIndex) =>
          record.results.map((process: WorkflowItem, processIndex: number) => (
            <Stack
              direction="vertical"
              css={css`
                border: 1px solid var(--neutral-20);
                border-top: var(--s00);
                border-radius: ${recIndex ===
                  childProcessData.pages.length - 1 &&
                processIndex === record.results.length - 1 &&
                `0px 0px var(--big-border-radius) var(--big-border-radius)`};
              `}
              gutter="s00 s4"
              key={`${process.id}`}
            >
              <ProcessItem process={process} inTable />
            </Stack>
          ))
        )}
      </InfiniteScroll>
    </Stack>
  );
};

export { ChildProcessList };
