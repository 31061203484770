import * as reducers from "./reducers";
import { combineReducers } from "redux";
import { withFilters as wf } from "./filters";
import { FILTERS_ENUM as FE } from "./constants";

// TODO: Fix the importing system
export const withFilters = wf;
export const FILTERS_ENUM = FE;

export const workflowList = combineReducers({ ...reducers });

export * from "./interfaces";
