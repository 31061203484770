import type { SubscriptionList } from "@certa/types";
export const subscriptionListModelCreator = (
  response: any
): SubscriptionList => ({
  count: response.count,
  next: response.next,
  previous: response.previous,
  results: response.results.map((item: any) => ({
    id: item.id,
    name: item.name,
    type: item.content_object_type,
    channel: item.channel,
    workflow: {
      id: item.workflow.id,
      name: item.workflow.name
    },
    step: item.step
      ? {
          id: item.step.id,
          name: item.step.name
        }
      : null,
    stepGroup: item.step_group
      ? {
          id: item.step_group.id,
          name: item.step_group.name
        }
      : null,
    field: item.field
      ? {
          id: item.field.id,
          name: item.field.body
        }
      : null,
    integrationResult: item.integration_result
      ? {
          uid: item.integration_result.uid
        }
      : null
  }))
});
