import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";

const useHeaderReportLinkText = () => {
  return useSelector(state =>
    lodashGet(state, "config.custom_ui_labels.header_report_link_text")
  );
};

export { useHeaderReportLinkText };
