import { getValueFromCookie } from "../../../js/utils/request";
import { tenant } from "../../../config";

export const RequestHeaders = {
  get common() {
    return {
      "X-DTS-SCHEMA": tenant,
      "Content-Type": "application/json"
    } as HeadersInit;
  },

  get PUT() {
    return {
      ...this.common,
      "X-CSRFToken": getValueFromCookie("csrftoken")
    } as HeadersInit;
  },

  get GET() {
    return this.common;
  },

  get POST() {
    return {
      ...this.common,
      "X-CSRFToken": getValueFromCookie("csrftoken")
    } as HeadersInit;
  }
};
