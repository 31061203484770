import React, { useEffect, useState } from "react";
import { ClassNames } from "@emotion/core";
import { Text, Stack } from "@certa/blocks/thanos";
import { Image } from "@certa/blocks";
import Maintananceimage from "../../../images/MaintananceImage.png";
import { MaintananceSelectLanguage } from "../SelectLanguage/MaintananceSelectLanguage";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";

type MaintenanceProps = {
  eta: string;
  msg: Record<string, string>;
};

const SECONDS_MS = 1000;

const padSingleDigit = (n: number): string => {
  if (n < 10) {
    return String(n).padStart(2, "0");
  }
  return String(n);
};

export const Maintenance = (props: MaintenanceProps) => {
  const { eta, msg } = props;
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);
  const intl = useIntl();

  // TODO: This default value needs to be updated from useGetPreferredLanguage
  //       when BE starts sending translated messages
  const defaultPrefferedLanguage = "en";
  const [message, setMessage] = useState(msg[defaultPrefferedLanguage]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null;

    if (eta) {
      const etaDate: any = new Date(eta);
      const differenceBetweenDates = () => {
        const dateNow: any = new Date();
        const dateDifference = etaDate - dateNow;
        let seconds = Math.floor(dateDifference / 1000);

        if (dateDifference > 0) {
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);

          hours %= 24;
          minutes %= 60;
          seconds %= 60;

          setDays(days);
          setHours(hours);
          setMinutes(minutes);
          setSeconds(seconds);
        } else {
          setDays(0);
          setHours(0);
          setMinutes(0);
          setSeconds(0);
          if (interval)
            //case: eta received is in past.
            clearInterval(interval);
        }
      };
      // on first render.
      differenceBetweenDates();

      // on second render, and onwards
      interval = setInterval(() => {
        differenceBetweenDates();
      }, SECONDS_MS);
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [eta]);

  return (
    <ClassNames>
      {({ cx, css }) => (
        <Stack
          className={css`
            background: var(--neutral-5);
          `}
        >
          <Stack
            direction="vertical"
            align="center"
            className={css`
              position: fixed;
              width: 100%;
              background: var(--neutral-5);
              margin: auto;
              height: 100vh;
            `}
          >
            <Stack
              direction="vertical"
              align="center"
              className={css`
                margin-top: 10%;
              `}
            >
              <Image
                src={Maintananceimage}
                style={{ width: "640px", height: "360px" }}
                alt=""
              />
              <Text
                className={css`
                  margin-top: var(--s6);
                  font-size: 20px;
                  font-weight: bold;
                `}
              >
                {intl.formatMessage({
                  id: "maintanance.heading",
                  defaultMessage: "Under Maintenance"
                })}
              </Text>
              <Text
                className={css`
                  margin-top: var(--s4);
                  max-width: 400px;
                  font-size: var(--p);
                  text-align: center;
                  line-height: var(--s5);
                `}
                color="var(--neutral-70)"
              >
                {message}
              </Text>
              {days || hours || minutes || seconds ? (
                <Stack gutter="s6" direction="horizontal" justify="center">
                  {days ? (
                    <Stack gap="s2" direction="vertical" align="center">
                      <WrapperStack>{padSingleDigit(days)}</WrapperStack>
                      <Text variant="p1-bold" color="neutral-70">
                        DAYS
                      </Text>
                    </Stack>
                  ) : null}
                  <Stack gap="s2" direction="vertical" align="center">
                    <WrapperStack>{padSingleDigit(hours)}</WrapperStack>
                    <Text variant="p1-bold" color="neutral-70">
                      HRS
                    </Text>
                  </Stack>
                  <Stack gap="s2" direction="vertical" align="center">
                    <WrapperStack>{padSingleDigit(minutes)}</WrapperStack>
                    <Text variant="p1-bold" color="neutral-70">
                      MIN
                    </Text>
                  </Stack>
                  <Stack gap="s2" direction="vertical" align="center">
                    <WrapperStack>{padSingleDigit(seconds)}</WrapperStack>
                    <Text variant="p1-bold" color="neutral-70">
                      SEC
                    </Text>
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
            <Stack
              gap="s3"
              className={css`
                position: absolute;
                right: 10px;
                top: 10px;
              `}
            >
              <MaintananceSelectLanguage
                languageData={msg}
                onChangeLanguage={(value: string) => setMessage(value)}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </ClassNames>
  );
};

const WrapperStack = styled(Text)`
  width: 57px;
  height: 38px;
  border-radius: 10px;
  background: var(--neutral-0);
  margin: var(--s1) var(--s1) var(--s00) var(--s1);
  justify-content: center;
  align-items: center;
  color: var(--neutra-100);
  font-size: 18px;
  font-weight: 700;
  display: flex;
`;
