import { css } from "emotion";

export const gapS1 = css({
  gap: "var(--s1)"
});

export const gapS1WithAutoWidth = css({
  gap: "var(--s1)",
  width: "100%"
});

export const createdOnStyles = css({
  maxWidth: "calc(var(--s1) * 39)",
  textAlign: "right"
});

export const workflowKindNameStyles = css({
  flex: 1
});
