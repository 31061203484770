import styled from "@emotion/styled";

export const StyledSidebarSectionHeading = styled.h3`
  color: #138bd4;
  margin: 30px 0px 20px 20px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

export const StyledFixedSidebar = styled.div`
  width: 300px;
  padding-bottom: 100px;
  height: 100%;
  font-family: Cabin, "system-ui";
  min-height: 110vh;
  background: #104775;
  position: fixed;
`;

export const StyledSidebarScrollContainer = styled.div`
  max-height: calc(100vh - 125px);
  padding-bottom: 20px;
  overflow-y: auto;
`;

// eslint-disable-next-line no-unexpected-multiline
export const StylesSidebarItem = styled.div<{
  isSelected: boolean;
  lastItem?: boolean;
}>`
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: ${({ lastItem }) =>
    lastItem ? "1px solid rgba(0, 0, 0, 0.3)" : "none"};
  display: flex;

  /* Since general format is {item, counts} */
  justify-content: space-between;
  padding: 8px 15px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  opacity: 0.8;
  background-color: ${({ isSelected }) =>
    isSelected ? "rgb(20, 137, 210)" : "rgb(16, 71, 117)"};
`;

export const StyledSimpleCountWidget = styled.span`
  font-size: 14px;
  opacity: 0.3;
  color: white;
  margin-right: 16px;
`;

// eslint-disable-next-line no-unexpected-multiline
export const StyledAlertListItem = styled.div<{
  open: boolean;
  highlight: boolean;
}>`
  background-color: ${({ open, highlight }) =>
    highlight ? "#1489D2" : open ? "#093050" : "#104775"};
  transition-duration: 150ms;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding: 10px 15px;
  cursor: pointer;
  color: white;
  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const StyledAlertContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// eslint-disable-next-line no-unexpected-multiline
export const StyledAlertSubCategoryListItem = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  padding: 3px 0px 2px 20px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  line-height: 18px;
  align-items: flex-start;
  background-color: ${({ isSelected }) =>
    isSelected ? "#148CD6" : "transparent"};
  border-radius: ${({ isSelected }) => (isSelected ? "12px" : "0px")};
`;

// eslint-disable-next-line no-unexpected-multiline
export const StyledColoredCountWidget = styled.span<{
  background: string;
}>`
  border-radius: 20px;
  background-color: ${({ background }) => background || "red"};
  color: white;
  margin: 0;
  padding: 0 5px;
  font-size: 14px;
  min-width: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  display: inline-block;
`;

export const StyledToggleListSizeContainer = styled.span`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 6px;
`;
