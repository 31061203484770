import { useGetWorkflowPermissionsType } from "@certa/queries";
import type { WorkflowLevelPermissionType } from "@certa/queries/src/types/permissions.types";
import type { FC } from "react";
import React, { useCallback } from "react";
import type { UseQueryExtraConfigType } from "./types/reactQueryGeneric.types";

type Props = {
  processId: number;
  checkFor: WorkflowLevelPermissionType | WorkflowLevelPermissionType[];
  children: React.ReactNode;
};

export type PermissionStatusType = "loading" | "error" | "denied" | "granted";

export type PermissionMap = Record<
  WorkflowLevelPermissionType,
  { status: PermissionStatusType }
>;
export type CheckLevelReturnType = {
  /* And operated permission status */
  status: PermissionStatusType;
  /* Individual status per permission mapper*/
  permissionMap: PermissionMap;
  refetch: () => void;
};

/**
 * This hook checks permission for workflow level actions of @type {PermissionMap}
 * @returns {CheckLevelReturnType} where status is of @type {PermissionStatusType} tells about resolution of permission
 */
export const useCheckLevelPermission = ({
  processId,
  checkFor,
  config
}: {
  processId: number;
  checkFor: WorkflowLevelPermissionType | WorkflowLevelPermissionType[];
  config?: UseQueryExtraConfigType;
}): CheckLevelReturnType => {
  const {
    data: workflowLevelPermissions,
    isLoading,
    isError,
    refetch
  } = useGetWorkflowPermissionsType(processId, config);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkForList = Array.isArray(checkFor) ? checkFor : [checkFor];

  const getPermissionStatus = useCallback(
    (checkFor: WorkflowLevelPermissionType[]) => {
      if (isLoading) {
        return "loading";
      }
      if (isError) {
        return "error";
      }
      if (
        workflowLevelPermissions &&
        checkFor.every(check => workflowLevelPermissions[check])
      ) {
        return "granted";
      }

      return "denied";
    },
    [isError, isLoading, workflowLevelPermissions]
  );
  const createPermissionMap = useCallback(() => {
    const permissionMap = {} as PermissionMap;
    checkForList.forEach(check => {
      permissionMap[check] = { status: getPermissionStatus([check]) };
    });
    return permissionMap;
  }, [checkForList, getPermissionStatus]);

  return {
    status: getPermissionStatus(checkForList),
    permissionMap: createPermissionMap(),
    refetch
  };
};
/**
 * This component is HOC which checks permission and shows/hides children.
 * processId @type {number} is the workflow id for which level permission is to be checked
 * checkFor is the permission type we are checking for,
 * it is flexible to take either one permission @type {WorkflowLevelPermissionType} or multiple @type {WorkflowLevelPermissionType[]}
 * For single permission it will return status @type {PermissionStatusType}
 * For multiple permission it will return status @type {PermissionStatusType} AND operated and,
 * permissionMap @type {PermissionMap} which will help to extract individual statuses out of it instead of AND operated
 */
export const Chokidar: FC<Props> = props => {
  const { processId, checkFor, children } = props;
  const { status: permissionStatus } = useCheckLevelPermission({
    processId,
    checkFor
  });
  // TODO: handle loading and error
  if (
    permissionStatus === "denied" ||
    permissionStatus === "loading" ||
    permissionStatus === "error"
  ) {
    return null;
  }
  return <>{children}</>;
};
