import styled from "@emotion/styled";
import { css, cx } from "emotion";
import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import { Text } from "../Typography/Text";
import { Stack } from "../Stack";
import { Typography, TypographyVariants } from "@certa/catalyst";
import { Link } from "react-router-dom";
import { Breadcrumb as AntBreadcrumb } from "antd";
import type { BreadcrumbOptions } from "./types";
import BreadcrumbMenu from "./BreadcrumbMenu";
import { LAYOUT_ID } from "../../constants";
import type { DropDownProps } from "antd/lib/dropdown";
import { Caret } from "@certa/icons";
import { Image } from "../../layout/Image";
import { Ellipsis } from "@certa/common";

type TextSize = "small" | "default";

type BreadcrumbItemProps = {
  label: BreadcrumbOptions["label"];
  icon: BreadcrumbOptions["icon"];
  isActive: boolean;
  hasMenu: boolean;
  maxWidth?: number;
  textSize?: TextSize;
  tooltipText?: string;
  isClickable?: boolean;
};

const BreadcrumbItem = ({
  label,
  icon: Icon,
  isActive,
  textSize = "default",
  hasMenu,
  maxWidth,
  isClickable
}: BreadcrumbItemProps) => {
  const textVariant = useMemo(
    () => ({
      small: isActive
        ? TypographyVariants.LABEL_SM_BOLD
        : TypographyVariants.LABEL_SM,
      default: isActive ? TypographyVariants.BODY_BOLD : TypographyVariants.BODY
    }),
    [isActive]
  );

  return (
    <Stack
      className={cx(
        "breadcrumb-item",
        isActive && "breadcrumb-item-active",
        isClickable && "breadcrumb-item-href"
      )}
      align="center"
    >
      {Icon && <Icon className="breadcrumb-icon" />}
      {label && (
        <Typography
          variant={textVariant[textSize]}
          as="span"
          className={css({
            whiteSpace: "nowrap",
            maxWidth: `${maxWidth}px`
          })}
        >
          <Ellipsis>{label}</Ellipsis>
        </Typography>
      )}
      {hasMenu && (
        <Caret
          size={14}
          className="button-disclosure"
          style={{
            marginLeft: "var(--s1)"
          }}
        />
      )}
    </Stack>
  );
};

export type CertaBreadcrumb = {
  /** Highlight the breadcrumb option with matching value */
  activeValue?: BreadcrumbOptions["value"];
  /** Breadcrumb hierarchy list -> descending */
  options: BreadcrumbOptions[];
  /** Breadcrumb dropdown customization */
  dropdownProps?: Omit<DropDownProps, "overlay">;
  /** Number of items to hide into dropdown, will take center items only and total items should be more than 3 */
  overflow?: number;
  /** Max width of item in breadbrumb after which to add ellipsis */
  maxItemWidth?: number;
  /** Minimum number of options to be passed for the breadcrumb to be visible */
  minimumOptions?: number;
  /** Logo url. Logo is placed at the start of breadcrumb. */
  logoUrl?: string;
  /** Logo alt attribute value */
  logoAlt?: string;
  /** Whether to disable link on active breadcrumb item */
  disableActiveLink?: boolean;
  separator?: React.ReactElement;
  className?: string;
  /** To control the size of text inside of breadcrumb */
  textSize?: TextSize;
};

const CustomBreadcrumb: FC<CertaBreadcrumb> = ({
  options,
  activeValue,
  dropdownProps = {},
  overflow,
  maxItemWidth = 180,
  minimumOptions = 2,
  logoUrl,
  logoAlt,
  disableActiveLink = true,
  className,
  separator,
  textSize = "default",
  ...rest
}) => {
  const getMenuProps = useCallback(
    (menuOptions: BreadcrumbOptions["menuOptions"]) =>
      menuOptions
        ? {
            dropdownProps: {
              getPopupContainer: () =>
                document.getElementById(LAYOUT_ID) || document.body,
              trigger: "click",
              ...dropdownProps
            } as DropDownProps,
            overlay: <BreadcrumbMenu options={menuOptions} />
          }
        : {},
    [dropdownProps]
  );

  const breadcrumbOption = useMemo(() => {
    if (
      overflow &&
      typeof overflow === "number" &&
      options.length - overflow >= 2
    ) {
      const deltaStart = Math.floor(options.length) / 2 - overflow / 2;
      const deltaEnd = Math.ceil(options.length) / 2 + overflow / 2;
      return [
        ...options.slice(0, deltaStart),
        {
          label: "...",
          value: undefined,
          href: "",
          isExternalLink: true,
          tooltipText: options
            .slice(deltaStart, deltaEnd)
            .map(item => item.label)
            .join(" / "),
          icon: undefined,
          maxWidth: undefined,
          menuOptions: undefined
        },
        ...options.slice(deltaEnd)
      ];
    }
    return options;
  }, [options, overflow]);

  if (options.length < minimumOptions) return null;

  return (
    <Stack>
      {logoUrl && (
        <Stack
          className={css`
            width: 26px;
            height: 26px;
            overflow: hidden;
            margin-right: var(--s1);
          `}
        >
          <Image
            src={logoUrl}
            alt={logoAlt ?? "Logo"}
            height="100%"
            width="100%"
            className={css`
              border-radius: 50%;
              object-fit: cover;
            `}
          />
        </Stack>
      )}
      <AntBreadcrumb
        className={className}
        separator={
          separator || (
            <Text as="span" variant="p1-medium" color="neutral-50">
              /
            </Text>
          )
        }
        {...rest}
      >
        {breadcrumbOption.map(
          (
            {
              href,
              value,
              label,
              icon: Icon,
              isExternalLink,
              menuOptions,
              maxWidth,
              onClick,
              tooltipText
            },
            index
          ) => {
            const hasDefaultHref = href !== undefined && href !== null;
            const isActive = activeValue
              ? !!activeValue && activeValue === value
              : breadcrumbOption.length - 1 === index;
            const isClickable =
              isActive && disableActiveLink
                ? false
                : hasDefaultHref || !!onClick;

            if (isExternalLink || !hasDefaultHref || !isClickable) {
              return (
                <AntBreadcrumb.Item
                  href={isClickable ? href : undefined}
                  key={href}
                  {...getMenuProps(menuOptions)}
                  onClick={onClick}
                >
                  <BreadcrumbItem
                    textSize={textSize}
                    label={label}
                    icon={Icon}
                    isActive={isActive}
                    hasMenu={!!menuOptions}
                    maxWidth={maxWidth}
                    tooltipText={tooltipText}
                    isClickable={isClickable}
                  />
                </AntBreadcrumb.Item>
              );
            }

            return (
              <AntBreadcrumb.Item
                key={href}
                onClick={onClick}
                {...getMenuProps(menuOptions)}
              >
                {href ? (
                  <Link to={href} tabIndex={isActive ? -1 : 0}>
                    <BreadcrumbItem
                      textSize={textSize}
                      label={label}
                      icon={Icon}
                      isActive={isActive}
                      hasMenu={!!menuOptions}
                      maxWidth={maxWidth}
                      tooltipText={tooltipText}
                      isClickable={isClickable}
                    />
                  </Link>
                ) : (
                  <BreadcrumbItem
                    textSize={textSize}
                    label={label}
                    icon={Icon}
                    isActive={isActive}
                    hasMenu={!!menuOptions}
                    maxWidth={maxWidth}
                    tooltipText={tooltipText}
                    isClickable={isClickable}
                  />
                )}
              </AntBreadcrumb.Item>
            );
          }
        )}
      </AntBreadcrumb>
    </Stack>
  );
};

const Breadcrumb = styled(CustomBreadcrumb)`
  &.ant-breadcrumb {
    color: var(--neutral-70);
    height: 26px;
    line-height: unset;
    display: flex;
    align-items: center;

    & > span {
      font-size: unset;
      line-height: unset;
      display: flex;
      align-items: center;
    }

    .ant-breadcrumb-overlay-link {
      display: flex;
      align-items: center;
    }

    a {
      color: var(--neutral-70);
      font-size: var(--p1-medium-font-size);
      line-height: unset;
      display: inline;
      align-items: center;
    }

    .breadcrumb-item {
      font-size: var(--p1-medium-font-size);
      line-height: var(--p1-medium-line-height);
      display: flex;
      align-items: center;
      padding: var(--s1);
      border-radius: var(--small-border-radius);
      transition: all 0.3s ease-in-out;

      .breadcrumb-text {
        line-height: inherit;
        display: inline;
        max-width: ${props => props.maxItemWidth}px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .button-disclosure {
        color: var(--brand-15);
        transition: all 0.3s ease-in-out;
      }

      &.breadcrumb-item-active {
        color: var(--neutral-100);
      }

      &:hover,
      &:focus {
        color: var(--brand);
        background-color: var(--brand-35);
        .button-disclosure {
          color: var(--brand);
        }
      }
      &:not(.breadcrumb-item-href) {
        cursor: default;
        &:hover,
        &:focus {
          color: unset;
          background-color: unset;
          .button-disclosure {
            color: unset;
          }
        }
        &.breadcrumb-item-active {
          &:hover,
          &:focus {
            color: var(--neutral-100);
          }
        }
      }

      svg {
        flex-shrink: 0;
      }
    }

    .ant-breadcrumb-separator {
      font-size: var(--p1-medium-font-size);
      line-height: var(--p1-medium-line-height);
      margin: 0 var(--s1);
      pointer-events: none;
    }

    .breadcrumb-icon {
      width: 1em;
      height: 1em;
      font-size: inherit;

      & + .breadcrumb-text {
        margin-left: var(--s1);
      }
    }

    a {
      transition: inherit;
    }

    .anticon.anticon-down {
      display: none;
    }
  }
  --label: Breadcrumb;
`;

Breadcrumb.defaultProps = {
  maxItemWidth: 180
};

export { Breadcrumb };
