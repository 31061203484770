export enum SearchResultType {
  RECENT_SEARCH = "RECENT_SEARCH",
  WORKFLOW_RESULT = "WORKFLOW_RESULT",
  FILE_RESULT = "FILE_RESULT",
  FIELD_RESPONSE_RESULT = "FIELD_RESPONSE_RESULT",
  MORE_RESULTS = "MORE_RESULTS",
  LC = "LC"
}

export const LOGO_SIZE = 32;
export const DEBOUNCE_TIME = 600;
export const MIN_CHAR_LENGTH_TO_SEARCH = 0;
export const MAX_RECENT_SEARCHES_TO_SHOW = 10;
export const MAX_SEARCHES_TO_STORE = 15;

export const CATEGORIES = [
  {
    translationKey: "globalSearch.identifier.workflows",
    label: "Workflows",
    value: "workflows"
  },
  {
    translationKey: "globalSearch.identifier.fieldsResponse",
    label: "Fields",
    value: "fields"
  },
  {
    translationKey: "globalSearch.identifier.files",
    label: "Files",
    value: "files"
  }
];
