/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import React from "react";
import { getCSSToken } from "../utils";

type StackProps = {
  space?: string;
  splitAfter?: number;
  align?: string;
  justify?: string;
} & { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>;

const Stack = React.forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  const { space, splitAfter, align, children, justify, ...htmlProps } = props;
  return (
    <div
      {...htmlProps}
      ref={ref}
      css={[
        css`
          --label: stack;
          display: flex;
          flex-direction: column;
          justify-content: ${justify || "flex-start"};
          align-items: ${align};
          & > * {
            margin-top: 0;
            margin-bottom: 0;
          }
          & > * + * {
            margin-top: ${getCSSToken(space || "0")};
          }
          &:only-child {
            height: 100%;
          }
          & > :nth-child(${splitAfter}) {
            margin-bottom: auto;
          }
        `
      ]}
    >
      {children}
    </div>
  );
});

Stack.defaultProps = {
  splitAfter: 0,
  space: "s4"
};

export { Stack };
