import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";

// Instance level config SUBSCRIBE_THREADS
export const useSubscribeThreadToggle = (): boolean => {
  return useSelector((state: ReduxState) =>
    lodashGet(state, "config.feature_toggles.SUBSCRIBE_THREADS", false)
  );
};
