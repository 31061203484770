import React from "react";
import { useIntl } from "react-intl";
import { Stack, Text } from "@certa/blocks/thanos";
import { Info } from "@certa/icons";
import { useScreenResolution, useWhiteLabelled } from "@certa/common";

const CommentCareLine = () => {
  const intl = useIntl();
  const SUPPORT_EMAIL = "care@getcerta.com";
  const whiteLabelled = useWhiteLabelled();
  const { isMobileResolution } = useScreenResolution();

  return !isMobileResolution && !whiteLabelled ? (
    <Stack>
      <Info color="teal" />
      <Text variant="p1-regular" color="neutral-70">
        {intl.formatMessage(
          {
            id: "comments.certaSupportTag",
            defaultMessage: "Mail care@getcerta.com for support"
          },
          {
            supportEmail: SUPPORT_EMAIL
          }
        )}
      </Text>
    </Stack>
  ) : null;
};

export { CommentCareLine };
