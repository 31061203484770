// To remove this from common  package and move in catalyst
// https://thevetted.atlassian.net/browse/PLAT-20227
import React, { forwardRef } from "react";
import type { TabProps, TabsProps } from "./Tabs.types";
import useTabs from "./hooks/useTabs";
import { mergeRefs } from "@certa/catalyst";
import { TabButton } from "./components/TabButton/TabButton";
import styles from "./Tabs.module.css";

const Tabs = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & TabsProps
>((props, ref) => {
  const {
    "aria-label": ariaTabsLabel,
    activeTab,
    children,
    onTabChange,
    tabPanelId,
    ...rest
  } = props;

  const tabChildren = (
    children.length ? children.filter(Boolean) : []
  ) as React.ReactElement<TabProps>[];

  const tabs = React.Children.map(tabChildren, (child, index) => {
    if (child.type !== TabButton) {
      throw new Error("Use only Tab component as children");
    }
    return {
      ...child,
      props: {
        ...child.props,
        id: (child.props.label + child.props.value + index).replace(" ", "-")
      }
    };
  }) as React.ReactElement<TabProps>[];

  const { selectedTab, tabListRef, handleTabChange } = useTabs(
    activeTab,
    tabs,
    onTabChange
  );

  return (
    <div
      {...rest}
      className={styles.tabsContainer}
      role="tablist"
      aria-label={ariaTabsLabel}
      ref={mergeRefs([tabListRef, ref])}
    >
      {tabs.map((tab: React.ReactElement<TabProps>) => (
        <TabButton
          {...tab.props}
          key={tab.props.value}
          onTabChange={handleTabChange}
          selectedTab={selectedTab}
          aria-controls={tabPanelId}
        />
      ))}
    </div>
  );
});

export { Tabs, TabButton as Tab };
