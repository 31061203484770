import { css } from "emotion";

export const RangePickerClass = css`
  border-radius: var(--s2) !important;
  border: none !important;
  height: 40px !important;
  background-color: var(--neutral-10) !important;
  width: 100%;

  & .ant-picker-clear {
    background-color: var(--neutral-10);
  }

  input {
    font-weight: 500 !important;
    font-family: "Inter", sans-serif, "system-ui" !important;
    font-size: 14px !important;
    color: var(--neutral-100) !important;
    line-height: 20px !important;
    &::placeholder {
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      color: var(--neutral-70) !important;
    }
  }
`;
