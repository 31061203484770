import type { ModelledAPIResponse } from "@certa/queries";
import { Stack } from "@certa/blocks/thanos";
import { gapS1, gapS1WithAutoWidth } from "../styles";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst";
import { ResultLayout } from "../ResultLayout/ResultLayout";
import type { ResultComponentProps } from "./types";
import type { HierarchyItem } from "../Breadcrumbs/Breadcrumbs.types";

export const FieldResponseResult = (
  // Omitting the "type" key here since this component is also used for "lc" type
  props: ResultComponentProps<
    | ModelledAPIResponse.FieldResponseSearchResult
    | ModelledAPIResponse.LCDataSearchResult
  >
) => {
  const { result, family } = props;
  const breadcrumbs: HierarchyItem[] = family
    .map((member, index) => ({
      id: member.id,
      name: member.name,
      logo: member.logo,
      hideLogo: false,
      // Except the root and the leaf, all other items can be hidden
      canHide: index !== 0 && index !== family.length - 1,
      // The root and the leaf are always priority 1, the rest are 4 since 2 & 3 are taken by step & field name
      priority: index === 0 || index === family.length - 1 ? 1 : 4
    }))
    .concat([
      {
        id: result.step.id,
        name: result.step.name,
        canHide: false,
        priority: 2,
        logo: null,
        hideLogo: true
      },
      {
        id: result.id,
        name: result.answer,
        canHide: false,
        priority: 3,
        logo: null,
        hideLogo: true
      }
    ]);

  return (
    <ResultLayout
      workflow={result.workflow}
      breadcrumbProps={{
        highlights: result.highlights,
        parentToChildHierarchy: breadcrumbs
      }}
      type={result.type}
      footer={
        <Stack direction="horizontal" className={gapS1} align="center">
          <Stack
            direction="horizontal"
            className={gapS1WithAutoWidth}
            justify="space-between"
            gutter="s0 s0 s0 s0"
          >
            <Stack direction="horizontal">
              <Stack direction="horizontal" gutter="s0 s0 s0 s0">
                <Typography
                  variant={TypographyVariants.LABEL_SM}
                  color={TypographyColors.NEUTRAL_600}
                >
                  {result.body}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      }
    />
  );
};
