import { Stack, Text } from "@certa/blocks/thanos";
import type { SelectOption } from "@certa/types";
import type { FC } from "react";
import React from "react";
import { css } from "emotion";
import { useIntl } from "react-intl";
import { UserGroup } from "@certa/icons";
import type { PopoverPlacements } from "@certa/catalyst";
import { Popover, TypographyVariants, Tag, Typography } from "@certa/catalyst";

const AssignedToUserGroupsTooltip: FC<{
  assignedTo: SelectOption[];
  children: React.ReactElement;
  placement?: PopoverPlacements;
}> = ({ assignedTo, children, placement = "top" }) => {
  const intl = useIntl();

  const assignedToLabel = intl.formatMessage({
    id: "taskDetails.swimlane.tooltip.assignedTo",
    defaultMessage: "Assigned To"
  });

  const assigendLabelList = assignedTo.map(item => item.label).join(", ");

  return (
    <Popover
      placement={placement}
      ariaDescription={`${assignedToLabel}: ${assigendLabelList}`}
      trigger="hover"
      openInPortal
      showArrow
      content={
        <div
          className={css`
            border-radius: var(--big-border-radius);
            color: var(--colors-neutral-700);
            font-size: var(--p3);
            line-height: var(--p3-line-height);
            font-weight: var(--regular);
            padding: var(--s4);
            min-height: 1.75rem;
            min-width: 18.75rem;
            max-width: 25rem;

            align-items: center;
            & > * {
              flex-grow: 1;
            }
          `}
        >
          <Stack direction="vertical" gap="s3" align={"flex-start"}>
            <Text variant="p2-bold-upper">{assignedToLabel}</Text>
            <Stack
              className={css`
                flex-wrap: wrap;
                gap: 0.5rem;
                max-height: 200px;
                overflow-y: auto;
              `}
            >
              {assignedTo?.map(({ label, value }) => (
                <Tag
                  icon={<UserGroup color="neutral-100" autoSize />}
                  key={value}
                  label={label}
                  multiline
                >
                  <Typography variant={TypographyVariants.LABEL_SM_BOLD}>
                    {label}
                  </Typography>
                </Tag>
              ))}
            </Stack>
          </Stack>
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default AssignedToUserGroupsTooltip;
