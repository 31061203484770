import type { IntegrationRow } from "../types/readonly.types";

/**
 * Modal creator to put the Integration row's common data into a
 * proper structure.
 * @param dataSet
 */

export const createIntegrationRowsModel = (dataSet: any[]): IntegrationRow[] =>
  dataSet.map(
    rowSet =>
      ({
        rowJSON: rowSet.row_json,
        fieldId: rowSet.field_id,
        selectedFlag: {
          label: rowSet.selected_flag.label,
          color: rowSet.selected_flag.extra?.color,
          reasonCodes: rowSet.selected_flag.reason_codes,
          tag: rowSet.selected_flag.tag
        },
        uid: rowSet.uid,
        commentCount: rowSet.commentCount,
        ...(rowSet.row_json && rowSet.row_json.krypton_status
          ? {
              defaultStatus: {
                displayName: rowSet.row_json.krypton_status
              }
            }
          : {})
      }) as IntegrationRow
  );
