// Prasaran Event types to add event listeners of different types of events
// Prasaran Event groups are required to make sure multiple events on the same object ID
// do not overlap and can co-exist at the same type as long as the two events belong to
// separate groups.
// Example, a step is submitted, the object id is 1, so two events can be pushed where both of them
// have the object id as 1 but one event is of Integration

import type { PrasaranEventTypeMap } from "./types";

export enum PrasaranEventGroups {
  INTEGRATION = "INTG",
  STATE = "STATE",
  MIRRORMASTER = "MM",
  STEP = "STEP",
  WORKFLOW = "WF",
  CHILD_WF_CREATION = "CWC",
  FIELD = "FIELD",
  SS_CONFIG_VALIDATION = "SS_CONFIG_VALIDATION",
  SS_CONFIG_DEPLOYMENT = "SS_CONFIG_DEPLOYMENT",
  SS_RC_CREATION = "SS_RC_CREATION",
  BULK_ACTIONS = "WBA",
  PACKAGE_VERSIONING = "PACKAGE_VERSIONING",
  PACKAGE_DEPLOYMENT = "PACKAGE_DEPLOYMENT",
  COPY_COMPONENT = "COPY_COMPONENT",
  DEPENDENCY_DEPLOYMENT = "DEPENDENCY_DEPLOYMENT",
  WORK_STARTED_STOPPED_ON_BRANCH = "WORK_STARTED_STOPPED_ON_BRANCH",
  META_JSON_UPDATED = "META_JSON_UPDATED",
  BRANCH_DEPLOYMENT = "BRANCH_DEPLOYMENT_STATUS_CHANGED",
  PROCESS_DEPLOYMENT = "PROCESS_DEPLOYMENT_STATUS_CHANGED",
  RESPONSE_CHANGED = "RESPONSE_CHANGED",
  LINK = "LINK",
  DELINK = "DELINK",
  RELINK = "RELINK"
}

export const PRASARAN_EVENT_TYPES: PrasaranEventTypeMap = {
  STATE_CHANGED: {
    name: "STATE_CHANGED",
    group: PrasaranEventGroups.STATE
  },
  INTEGRATION_STARTED: {
    name: "INTEGRATION_STARTED",
    group: PrasaranEventGroups.INTEGRATION,
    notification: { type: "info" }
  },
  INTEGRATION_FINISHED: {
    name: "INTEGRATION_FINISHED",
    group: PrasaranEventGroups.INTEGRATION,
    notification: { type: "success" }
  },
  INTEGRATION_QUEUED: {
    name: "INTEGRATION_QUEUED",
    group: PrasaranEventGroups.INTEGRATION,
    notification: { type: "warning" }
  },
  MIRRORMASTER_QUEUED: {
    name: "MIRRORMASTER_QUEUED",
    group: PrasaranEventGroups.MIRRORMASTER,
    notification: { type: "warning" }
  },
  MIRRORMASTER_FINISHED: {
    name: "MIRRORMASTER_FINISHED",
    group: PrasaranEventGroups.MIRRORMASTER,
    notification: { type: "success" }
  },
  STEP_UPDATED: {
    name: "STEP_UPDATED",
    group: PrasaranEventGroups.STEP
  },
  WORKFLOW_DATA_UPDATED: {
    name: "WORKFLOW_DATA_UPDATED",
    group: PrasaranEventGroups.WORKFLOW
  },
  CHILD_WF_CREATION_QUEUED: {
    name: "CWC_QUEUED",
    group: PrasaranEventGroups.CHILD_WF_CREATION,
    notification: { type: "warning" }
  },
  CHILD_WF_CREATION_FINISHED: {
    name: "CWC_FINISHED",
    group: PrasaranEventGroups.CHILD_WF_CREATION,
    notification: { type: "success" }
  },
  CHILD_WF_CREATION_STARTED: {
    name: "CWC_STARTED",
    group: PrasaranEventGroups.CHILD_WF_CREATION,
    notification: { type: "info" }
  },
  CHILD_WF_CREATION_FAILED: {
    name: "CWC_FAILED",
    group: PrasaranEventGroups.CHILD_WF_CREATION,
    notification: { type: "error" }
  },
  FIELD_CHANGE_QUEUED: {
    name: "FIELD_CHANGE_QUEUED",
    group: PrasaranEventGroups.FIELD,
    notification: { type: "warning" }
  },
  FIELD_CHANGE_FINISHED: {
    name: "FIELD_CHANGE_FINISHED",
    group: PrasaranEventGroups.FIELD,
    notification: { type: "success" }
  },
  SS_CONFIG_VALIDATION_QUEUED: {
    name: "SS_CONFIG_QUEUED",
    group: PrasaranEventGroups.SS_CONFIG_VALIDATION,
    notification: { type: "warning" }
  },
  SS_CONFIG_VALIDATION_STARTED: {
    name: "SS_CONFIG_VALIDATION_STARTED",
    group: PrasaranEventGroups.SS_CONFIG_VALIDATION,
    notification: { type: "info" }
  },
  SS_CONFIG_VALIDATION_FAILED: {
    name: "SS_CONFIG_VALIDATION_FAILED",
    group: PrasaranEventGroups.SS_CONFIG_VALIDATION,
    notification: { type: "error" }
  },
  SS_CONFIG_VALIDATION_COMPLETED: {
    name: "SS_CONFIG_VALIDATION_COMPLETED",
    group: PrasaranEventGroups.SS_CONFIG_VALIDATION,
    notification: { type: "success" }
  },
  SS_CONFIG_DEPLOYMENT_STARTED: {
    name: "SS_CONFIG_DEPLOYMENT_STARTED",
    group: PrasaranEventGroups.SS_CONFIG_DEPLOYMENT,
    notification: { type: "info" }
  },
  SS_CONFIG_DEPLOYMENT_FAILED: {
    name: "SS_CONFIG_DEPLOYMENT_FAILED",
    group: PrasaranEventGroups.SS_CONFIG_DEPLOYMENT,
    notification: { type: "error" }
  },
  SS_CONFIG_DEPLOYMENT_COMPLETED: {
    name: "SS_CONFIG_DEPLOYMENT_COMPLETED",
    group: PrasaranEventGroups.SS_CONFIG_DEPLOYMENT,
    notification: { type: "success" }
  },
  SS_RC_CREATION_QUEUED: {
    name: "RC_CREATION_QUEUED",
    group: PrasaranEventGroups.SS_RC_CREATION,
    notification: { type: "warning" }
  },
  SS_RC_CREATION_STARTED: {
    name: "RC_CREATION_STARTED",
    group: PrasaranEventGroups.SS_RC_CREATION,
    notification: { type: "info" }
  },
  SS_RC_CREATION_FAILED: {
    name: "RC_CREATION_FAILED",
    group: PrasaranEventGroups.SS_RC_CREATION,
    notification: { type: "error" }
  },
  SS_RC_CREATION_COMPLETED: {
    name: "RC_CREATION_COMPLETED",
    group: PrasaranEventGroups.SS_RC_CREATION,
    notification: { type: "success" }
  },
  BULK_ACTIONS_QUEUED: {
    name: "WBA_QUEUED",
    group: PrasaranEventGroups.BULK_ACTIONS,
    notification: { type: "warning" }
  },
  BULK_ACTIONS_COMPLETED: {
    name: "WBA_COMPLETED",
    group: PrasaranEventGroups.BULK_ACTIONS,
    notification: { type: "success" }
  },
  PACKAGE_VERSIONING_QUEUED: {
    name: "PACKAGE_VERSIONING_QUEUED",
    group: PrasaranEventGroups.PACKAGE_VERSIONING,
    notification: { type: "warning" }
  },
  PACKAGE_VERSIONING_STARTED: {
    name: "PACKAGE_VERSIONING_STARTED",
    group: PrasaranEventGroups.PACKAGE_VERSIONING,
    notification: { type: "info" }
  },
  PACKAGE_VERSIONING_COMPLETED: {
    name: "PACKAGE_VERSIONING_COMPLETED",
    group: PrasaranEventGroups.PACKAGE_VERSIONING,
    notification: { type: "success" }
  },
  PACKAGE_VERSIONING_FAILED: {
    name: "PACKAGE_VERSIONING_FAILED",
    group: PrasaranEventGroups.PACKAGE_VERSIONING,
    notification: { type: "error" }
  },
  PACKAGE_VERSIONING_FAILED_MAXIMUM_MINOR_VERSION_REACHED: {
    name: "PACKAGE_VERSIONING_FAILED_MAXIMUM_MINOR_VERSION_REACHED",
    group: PrasaranEventGroups.PACKAGE_VERSIONING,
    notification: { type: "error" }
  },
  PACKAGE_VERSIONING_FAILED_MINOR_VERSION_CREATION: {
    name: "PACKAGE_VERSIONING_FAILED_MINOR_VERSION_CREATION",
    group: PrasaranEventGroups.PACKAGE_VERSIONING,
    notification: { type: "error" }
  },
  PACKAGE_VERSIONING_FAILED_NOT_FROZEN_VERSION: {
    name: "PACKAGE_VERSIONING_FAILED_NOT_FROZEN_VERSION",
    group: PrasaranEventGroups.PACKAGE_VERSIONING,
    notification: { type: "error" }
  },
  PACKAGE_VERSIONING_FAILED_PARSING_TO_JSON: {
    name: "PACKAGE_VERSIONING_FAILED_PARSING_TO_JSON",
    group: PrasaranEventGroups.PACKAGE_VERSIONING,
    notification: { type: "error" }
  },
  PACKAGE_VERSIONING_FAILED_VERSION_CANT_BE_LOCKED: {
    name: "PACKAGE_VERSIONING_FAILED_VERSION_CANT_BE_LOCKED",
    group: PrasaranEventGroups.PACKAGE_VERSIONING,
    notification: { type: "error" }
  },
  PACKAGE_DEPLOYMENT_QUEUED: {
    name: "PACKAGE_DEPLOYMENT_QUEUED",
    group: PrasaranEventGroups.PACKAGE_DEPLOYMENT,
    notification: { type: "warning" }
  },
  PACKAGE_DEPLOYMENT_STARTED: {
    name: "PACKAGE_DEPLOYMENT_STARTED",
    group: PrasaranEventGroups.PACKAGE_DEPLOYMENT,
    notification: { type: "info" }
  },
  PACKAGE_DEPLOYMENT_COMPLETED: {
    name: "PACKAGE_DEPLOYMENT_COMPLETED",
    group: PrasaranEventGroups.PACKAGE_DEPLOYMENT,
    notification: { type: "success" }
  },
  PACKAGE_DEPLOYMENT_FAILED: {
    name: "PACKAGE_DEPLOYMENT_FAILED",
    group: PrasaranEventGroups.PACKAGE_DEPLOYMENT,
    notification: { type: "error" }
  },
  PACKAGE_FAILED_PARSING_TO_JSON: {
    name: "PACKAGE_FAILED_PARSING_TO_JSON",
    group: PrasaranEventGroups.PACKAGE_DEPLOYMENT,
    notification: { type: "error" }
  },
  PACKAGE_FAILED_VERSION_CANT_BE_LOCKED: {
    name: "PACKAGE_FAILED_VERSION_CANT_BE_LOCKED",
    group: PrasaranEventGroups.PACKAGE_DEPLOYMENT,
    notification: { type: "error" }
  },
  COPY_COMPONENT_QUEUED: {
    name: "COPY_COMPONENT_QUEUED",
    group: PrasaranEventGroups.COPY_COMPONENT,
    notification: { type: "warning" }
  },
  COPY_COMPONENT_STARTED: {
    name: "COPY_COMPONENT_STARTED",
    group: PrasaranEventGroups.COPY_COMPONENT,
    notification: { type: "info" }
  },
  COPY_COMPONENT_COMPLETED: {
    name: "COPY_COMPONENT_COMPLETED",
    group: PrasaranEventGroups.COPY_COMPONENT,
    notification: { type: "success" }
  },
  DEPENDENCY_DEPLOYMENT_QUEUED: {
    name: "DEPENDENCY_DEPLOYMENT_QUEUED",
    group: PrasaranEventGroups.DEPENDENCY_DEPLOYMENT,
    notification: { type: "warning" }
  },
  DEPENDENCY_DEPLOYMENT_STARTED: {
    name: "DEPENDENCY_DEPLOYMENT_STARTED",
    group: PrasaranEventGroups.DEPENDENCY_DEPLOYMENT,
    notification: { type: "info" }
  },
  DEPENDENCY_DEPLOYMENT_COMPLETED: {
    name: "DEPENDENCY_DEPLOYMENT_COMPLETED",
    group: PrasaranEventGroups.DEPENDENCY_DEPLOYMENT,
    notification: { type: "success" }
  },
  DEPENDENCY_DEPLOYMENT_FAILED: {
    name: "DEPENDENCY_DEPLOYMENT_FAILED",
    group: PrasaranEventGroups.DEPENDENCY_DEPLOYMENT,
    notification: { type: "error" }
  },
  WORK_STARTED_STOPPED_ON_BRANCH: {
    name: "WORK_STARTED_STOPPED_ON_BRANCH",
    group: PrasaranEventGroups.WORK_STARTED_STOPPED_ON_BRANCH
  },
  META_JSON_UPDATED: {
    name: "META_JSON_UPDATED",
    group: PrasaranEventGroups.META_JSON_UPDATED
  },
  PROCESS_DEPLOYMENT: {
    name: "PROCESS_DEPLOYMENT_STATUS_CHANGED",
    group: PrasaranEventGroups.PROCESS_DEPLOYMENT
  },
  BRANCH_DEPLOYMENT: {
    name: "BRANCH_DEPLOYMENT_STATUS_CHANGED",
    group: PrasaranEventGroups.BRANCH_DEPLOYMENT
  },
  RESPONSE_CHANGED: {
    name: "RESPONSE_CHANGED",
    group: PrasaranEventGroups.RESPONSE_CHANGED
  },
  WORKFLOW_LINKING_QUEUED: {
    name: "WORKFLOW_LINKING_QUEUED",
    group: PrasaranEventGroups.LINK,
    notification: { type: "warning" }
  },
  WORKFLOW_LINKING_STARTED: {
    name: "WORKFLOW_LINKING_STARTED",
    group: PrasaranEventGroups.LINK,
    notification: { type: "info" }
  },
  WORKFLOW_LINKING_FINISHED: {
    name: "WORKFLOW_LINKING_FINISHED",
    group: PrasaranEventGroups.LINK,
    notification: { type: "success" }
  },
  WORKFLOW_LINKING_FAILED: {
    name: "WORKFLOW_LINKING_FAILED",
    group: PrasaranEventGroups.LINK,
    notification: { type: "error" }
  },
  WORKFLOW_DELINKING_QUEUED: {
    name: "WORKFLOW_DELINKING_QUEUED",
    group: PrasaranEventGroups.DELINK,
    notification: { type: "warning" }
  },
  WORKFLOW_DELINKING_STARTED: {
    name: "WORKFLOW_DELINKING_STARTED",
    group: PrasaranEventGroups.DELINK,
    notification: { type: "info" }
  },
  WORKFLOW_DELINKING_FINISHED: {
    name: "WORKFLOW_DELINKING_FINISHED",
    group: PrasaranEventGroups.DELINK,
    notification: { type: "success" }
  },
  WORKFLOW_DELINKING_FAILED: {
    name: "WORKFLOW_DELINKING_FAILED",
    group: PrasaranEventGroups.DELINK,
    notification: { type: "error" }
  },
  WORKFLOW_RELINKING_QUEUED: {
    name: "WORKFLOW_RELINKING_QUEUED",
    group: PrasaranEventGroups.RELINK,
    notification: { type: "warning" }
  },
  WORKFLOW_RELINKING_STARTED: {
    name: "WORKFLOW_RELINKING_STARTED",
    group: PrasaranEventGroups.RELINK,
    notification: { type: "info" }
  },
  WORKFLOW_RELINKING_FINISHED: {
    name: "WORKFLOW_RELINKING_FINISHED",
    group: PrasaranEventGroups.RELINK,
    notification: { type: "success" }
  },
  WORKFLOW_RELINKING_FAILED: {
    name: "WORKFLOW_RELINKING_FAILED",
    group: PrasaranEventGroups.RELINK,
    notification: { type: "error" }
  }
};

// eslint-disable-next-line no-useless-escape
export const DYNAMIC_LOV_URI_REGEX = `api\/v2\/dynamic_lov\/workflow\/{.*}\/field_tag\/`;

export const INTEGRATION_EVENT_TYPE_NAMES = [
  PRASARAN_EVENT_TYPES.INTEGRATION_STARTED.name,
  PRASARAN_EVENT_TYPES.INTEGRATION_FINISHED.name,
  PRASARAN_EVENT_TYPES.INTEGRATION_QUEUED.name
];
