import {
  Stack,
  ButtonVariants,
  Button,
  TypographyColors,
  TypographyVariants,
  Typography,
  type ButtonProps
} from "@certa/catalyst";
import { Fragment } from "react";
import { wrapperStylesCSS } from "./LoadingAndEmptyStatesWrapper.styles";
import { ListGroupIllustration } from "./illustrations/ListGroupIllustration";

type ActionButtonProps = {
  onClickAction?: () => void;
  actionText?: string;
  actionButtonProps?: Omit<ButtonProps, "onClick" | "children"> | undefined;
} & (
  | {
      onClickAction: () => void;
      actionText: string;
      actionButtonProps?: Omit<ButtonProps, "onClick" | "children">;
    }
  | { onClickAction?: never; actionText?: never; actionButtonProps?: never }
);

type LoadingAndEmptyStatesWrapperProps = {
  primaryMessage: string;
  primaryIcon?: React.ReactElement;
  secondaryMessage?: string;
  secondaryIcon?: React.ReactElement;
} & ActionButtonProps;

/*
 * This component handles :
 * Empty state - display message and an action button
 * Loading state - display loading spinner with a message
 */
const LoadingAndEmptyStatesWrapper = ({
  primaryMessage,
  primaryIcon,
  secondaryMessage,
  secondaryIcon,
  onClickAction,
  actionText,
  actionButtonProps = {
    loading: false,
    disabled: false
  }
}: LoadingAndEmptyStatesWrapperProps) => {
  return (
    <Stack
      direction="vertical"
      justify="center"
      align="center"
      dangerouslySetClassName={wrapperStylesCSS}
    >
      <ListGroupIllustration />
      <Stack direction="vertical" justify="center" align="center" gap="12">
        <Stack direction="horizontal" justify="center" align="center" gap="8">
          {primaryIcon && <Fragment>{primaryIcon}</Fragment>}
          <Typography
            variant={TypographyVariants.TITLE_SM}
            color={TypographyColors.NEUTRAL_800}
          >
            {primaryMessage}
          </Typography>
        </Stack>
        {secondaryMessage && (
          <Stack direction="horizontal" justify="center" align="center" gap="8">
            {secondaryIcon && <div>{secondaryIcon}</div>}
            <Typography
              as="div"
              variant={TypographyVariants.BODY}
              color={TypographyColors.NEUTRAL_600}
            >
              {secondaryMessage}
            </Typography>
          </Stack>
        )}
        {onClickAction && (
          <Button
            variant={ButtonVariants.FILLED}
            onClick={onClickAction}
            {...actionButtonProps}
          >
            {actionText}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export { LoadingAndEmptyStatesWrapper };
