import React, { useState, useCallback, useMemo } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { Loader, ELoaderSize } from "../../common/components/Loader";
import { createFilterParams } from "../../dashboard/utils";
import { exportWorkflow } from "../services";
import { showNotification } from "../../common/notification";
import type { IKind } from "../../dashboard/interfaces";
import { FormattedMessage } from "react-intl";
import { to } from "await-to-js";
import * as Sentry from "@sentry/react";
import { HttpStatus } from "@certa/types";

export const ExportWorkflow = () => {
  const [isLoading, setIsLoading] = useState(false);
  const selectedFilters = useSelector(
    (state: any) => state.workflowList.selectedWorkflowFilters
  );
  const kinds: Array<IKind> = useSelector(
    (state: any) => state.workflowList.kinds.data
  );

  const onClickExport = useCallback(async () => {
    const filters = Object.values(selectedFilters);
    const exportFilter: any = createFilterParams(
      filters.filter((item: any) => item.key !== "q" && item.key !== "page")
    );

    /**
     * In V2 export API we need to only send the kind_id so we are simply adding it in the payload
     * and removing the extra keys kind (used to be kind id) and kind_tag used earlier in v1 API.
     */
    exportFilter.kind_id = exportFilter.kind;

    delete exportFilter.kind;
    delete exportFilter.kind_tag;

    setIsLoading(true);
    const [error, response] = await to(
      exportWorkflow({ filters: exportFilter })
    );
    setIsLoading(false);
    if (error) {
      showNotification({
        type: "error",
        message: "notificationInstances.networkError",
        description: "notificationInstances.networkErrorDescription"
      });
    }

    if (response?.ok) {
      showNotification({
        type: "success",
        message: "notificationInstances.workflowExportSuccess"
      });
    } else {
      if (response.status === HttpStatus.NOT_FOUND) {
        showNotification({
          type: "error",
          message: "errorMessageInstances.noWorkflowsToExport"
        });
      } else if (response.status === HttpStatus.BAD_REQUEST) {
        showNotification({
          type: "error",
          message: "errorMessageInstances.invalidExportParams"
        });
        Sentry.captureException(
          new Error(`Parameter kind_id missing in Workflow Export API`)
        );
      } else {
        showNotification({
          type: "error",
          message: "errorMessageInstances.exportErrorMessage"
        });
      }
    }
  }, [selectedFilters]);

  const exportableKind = useMemo(() => {
    const kindFilter = selectedFilters.KIND_FILTER;
    if (!kindFilter) {
      return null;
    }
    const exportKind = kinds.find(item => item.tag === kindFilter.value);

    return exportKind ? exportKind.name : null;
  }, [selectedFilters, kinds]);

  if (!exportableKind) {
    return null;
  }

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="tooltips.exportDataText"
          values={{
            kind: exportableKind
          }}
        />
      }
      placement="left"
    >
      <span
        className="mr-right-lg"
        style={{
          fontSize: 24,
          color: "#000000",
          opacity: 0.3,
          cursor: "pointer",
          paddingLeft: 8
        }}
      >
        {isLoading ? (
          <Loader size={ELoaderSize.SMALL} />
        ) : (
          <DownloadOutlined onClick={onClickExport} />
        )}
      </span>
    </Tooltip>
  );
};
