import React, { useMemo } from "react";
import { Route, Redirect } from "react-router-dom";
import Godaam from "../utils/storage";
import { withHeader } from "../../modules/header/components/withHeader";
import {
  Header,
  HeaderMode,
  CloseIframe,
  NavigationSidebar,
  MobileHeader,
  useScreenResolution,
  MobileInCompatiblePage
} from "@certa/common";
import { Stack } from "@certa/catalyst";

/**
 * @param props
 * - component is the final UI to be served on the route
 * - fallbackLoader is the loading UI to be served until loadable/lazy component is loaded
 */
export const PrivateRoute = ({
  component: Component,
  fallbackLoader,
  headerMode,
  isMobileInCompatible,
  ...rest
}) => {
  const ComponentWithLegacyHeader = useMemo(
    () => withHeader(Component),
    [Component]
  );
  const isEmbeddedView = Godaam.isEmbeddedView;

  const { isMobileResolution } = useScreenResolution();

  const renderFunction = props => {
    if (!Godaam.user) {
      return (
        <Redirect
          to={{
            // Redirecting the user to login means,
            // he/she needs to go to some specifix url after login
            pathname: `/login`,
            search: rest.location.search
              ? `?next=${encodeURIComponent(
                  rest.location.pathname + rest.location.search
                )}`
              : `?next=${rest.location.pathname}`,
            state: { from: props.location }
          }}
        />
      );
    }
    if (isMobileResolution && isMobileInCompatible) {
      return (
        <Stack direction="vertical" height="100%">
          <MobileHeader />
          <MobileInCompatiblePage />
        </Stack>
      );
    }
    if (headerMode === HeaderMode.LEGACY) {
      return <ComponentWithLegacyHeader {...props} fallback={fallbackLoader} />;
    }

    return (
      <Stack
        direction={isMobileResolution ? "vertical" : "horizontal"}
        height="100%"
      >
        {headerMode !== HeaderMode.NONE &&
          headerMode !== HeaderMode.STUDIO &&
          !isEmbeddedView &&
          !isMobileResolution && <NavigationSidebar />}
        {isMobileResolution && <MobileHeader />}
        <Stack width="100%" height="100%" overflow="auto">
          <div id="catalyst-positioned-drawer-wrapper" />
          {headerMode !== HeaderMode.NONE && !isEmbeddedView && (
            <Header mode={headerMode} />
          )}
          {Godaam.exitIframeButton && <CloseIframe />}
          <Component {...props} fallback={fallbackLoader} />
        </Stack>
      </Stack>
    );
  };

  return <Route {...rest} render={renderFunction} />;
};
