import {
  useQuery,
  useInfiniteQuery,
  type UseQueryOptions,
  type QueryKey
} from "react-query";
import {
  getCompletedProcessCount,
  getDelayedTaskLaneCount,
  getInProgressTaskLaneCount,
  getMyTaskTaskLaneCount,
  getMyGroupsByTaskLane,
  getAllTasksByTaskLane,
  getMyUserGroupProcessList,
  getMyGroupsByStatus,
  getAllTasksByStatus,
  getNonABProcessType,
  getMyTaskByTaskLane,
  getMyTaskByStatus,
  getMyTaskProcessList,
  getAllTaskProcessList,
  getMyGroupTaskProcessCount,
  getMyTaskProcessCount,
  getTaskDetails,
  getMyTasksProcesses,
  getTaskCount,
  getTaskDependencyData,
  getDistinctAttributes
} from "../services/tasks.services";
import type {
  TaskHeaderListParams,
  MyTaskProcessListParams,
  AllTaskProcessListParams
} from "../types/tasks.types";
import type { ProcessFilterSet, StepDetails } from "@certa/types";
import { fetchStatusesListConditionally, queryClient } from "../utils/utils";
import {
  useDebugMode,
  type UseInfiniteQueryExtraConfigType,
  type UseQueryExtraConfigType,
  type UseQueryReturnType
} from "@certa/common";
import { useMemo } from "react";

// TODO: Remove this in future
export const useGetMyTaskTaskLaneCount = () =>
  useQuery({
    retry: 0,
    queryKey: "mytask-stepgroups",
    queryFn: getMyTaskTaskLaneCount
  });

// TODO: Remove this in future
export const useGetMyTaskProcessCount = (
  processTypeId: number | null,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyTaskProcessCount>>
) =>
  useQuery({
    enabled: !!processTypeId,
    ...config,
    queryKey: ["my-task-process-count", processTypeId] as QueryKey,
    queryFn: () => getMyTaskProcessCount(processTypeId as number)
  });

// TODO: Remove this in future
export const useGetMyGroupTaskProcessCount = (
  processTypeId: number | null,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof getMyGroupTaskProcessCount>
  >
) =>
  useQuery({
    enabled: !!processTypeId,
    ...config,
    queryKey: ["my-group-task-process-count", processTypeId] as QueryKey,
    queryFn: () => getMyGroupTaskProcessCount(processTypeId as number)
  });

export const useGetDelayedTaskLaneCount = () =>
  useQuery({
    retry: 0,
    queryKey: "delayed-stepgroups",
    queryFn: getDelayedTaskLaneCount
  });

export const useGetInProgressTaskLaneCount = () =>
  useQuery({
    retry: 0,
    queryKey: "inprogress-stepgroups",
    queryFn: getInProgressTaskLaneCount
  });

export const useGetCompletedProcessCount = () =>
  useQuery({
    retry: 0,
    queryKey: "completed-tasks",
    queryFn: getCompletedProcessCount
  });

export const useGetNonABProcessType = (
  ...params: Parameters<typeof getNonABProcessType>
) =>
  useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["non-ab-kinds", ...params],
    queryFn: () => getNonABProcessType(...params)
  });

// My Groups header(status + taskLane) and list hooks
export const useGetMyGroupsByTaskLane = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyGroupsByTaskLane>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["mygroups-by-tasklane", params] as QueryKey,
    queryFn: () => getMyGroupsByTaskLane(params)
  });

// TODO: Remove this in future
export const useGetMyGroupsByStatus = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyGroupsByStatus>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["mygroups-by-status", params] as QueryKey,
    queryFn: () => getMyGroupsByStatus(params)
  });

export const useGetMyUserGroupProcessList = (
  offset: number,
  params?: ProcessFilterSet,
  config?: UseQueryExtraConfigType
) =>
  useQuery({
    queryKey: ["my-group-tasks-process-table-list-section", params, offset],
    queryFn: async () => {
      await fetchStatusesListConditionally();
      return getMyUserGroupProcessList(params || {}, offset);
    },
    ...config
  });

// All Tasks header(status + taskLane) and list hooks
export const useGetAllTasksByTaskLane = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getAllTasksByTaskLane>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    refetchOnWindowFocus: false,
    queryKey: ["alltasks-by-tasklane", params] as QueryKey,
    queryFn: () => getAllTasksByTaskLane(params)
  });

export const useGetAllTasksByStatus = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getAllTasksByStatus>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["alltasks-by-status", params] as QueryKey,
    queryFn: () => getAllTasksByStatus(params)
  });

export const useGetAllTaskProcessList = (
  params: AllTaskProcessListParams,
  config?: UseInfiniteQueryExtraConfigType
) =>
  useInfiniteQuery(
    ["all-task-process-list", params],
    ({ pageParam = 0 }) => getAllTaskProcessList(params, pageParam),
    {
      getNextPageParam: ({ next }: { next: string | null }) => {
        if (next) {
          const urlParams = new URLSearchParams(next);
          const offset = urlParams.get("offset");
          return offset;
        }
        return false;
      },
      ...config
    }
  );

// All Tasks header(status + taskLane) and list hooks
// TODO: Remove this in future
export const useGetMyTaskByTaskLane = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyTaskByTaskLane>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["myTasks-by-taskLane", params] as QueryKey,
    queryFn: () => getMyTaskByTaskLane(params)
  });

// TODO: Remove this in future
export const useGetMyTaskByStatus = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyTaskByStatus>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["myTasks-by-status", params] as QueryKey,
    queryFn: () => getMyTaskByStatus(params)
  });

// This is the latest my task hook to get my task processes across all and specific kind
export const useGetMyTasksProcesses = (
  offset: number,
  params?: ProcessFilterSet,
  orderings?: string,
  config?: UseQueryExtraConfigType
) =>
  useQuery({
    refetchOnWindowFocus: false,
    ...config,
    queryKey: ["myTasks-process-section", params, offset, orderings],
    queryFn: async () => {
      await fetchStatusesListConditionally();
      return getMyTasksProcesses(params || {}, offset, orderings);
    },
    ...config
  });

//
export const useGetDistinctAttributes = (
  offset: number,
  params: ProcessFilterSet,
  attribute: "kind_id" | "status_id",
  config?: UseQueryOptions<UseQueryReturnType<typeof getDistinctAttributes>>
) =>
  useQuery({
    refetchOnWindowFocus: false,
    ...config,
    queryKey: ["distinct-attributes", params] as QueryKey,
    queryFn: () => getDistinctAttributes(params, offset, attribute)
  });

// TODO: Remove this in future
export const useGetMyTaskProcessList = (
  params: MyTaskProcessListParams,
  config?: UseInfiniteQueryExtraConfigType
) =>
  useInfiniteQuery(
    ["myTask-process-list-column", params],
    async ({ pageParam = 0 }) => {
      await fetchStatusesListConditionally();
      return getMyTaskProcessList(params, pageParam);
    },
    {
      getNextPageParam: ({ next }: { next: string | null }) => {
        if (next) {
          const urlParams = new URLSearchParams(next);
          const offset = urlParams.get("offset");
          return offset;
        }
        return false;
      },
      ...config
    }
  );

export const useGetTaskDetailsWithDebugMode = (taskId: number) => {
  const isDebugModeEnabled = useDebugMode();
  const boundGetTaskDetails = useMemo(() => {
    return getTaskDetails.bind(null, taskId, isDebugModeEnabled);
  }, [taskId, isDebugModeEnabled]);
  return boundGetTaskDetails;
};

export const useGetTaskDetails = (
  taskId: number | null,
  config?: UseQueryOptions<StepDetails>
) => {
  const getTaskDetailsWithDebugMode = useGetTaskDetailsWithDebugMode(
    taskId as number
  );

  return useQuery({
    enabled: taskId !== null,
    ...config,
    queryKey: ["currentStepFields", taskId] as QueryKey,
    queryFn: () => getTaskDetailsWithDebugMode()
  });
};

export const inValidateStepFieldsData = (taskId: number) =>
  queryClient.invalidateQueries(["currentStepFields", taskId]);

export const useGetTaskCount = (
  config?: UseQueryOptions<UseQueryReturnType<typeof getTaskCount>>
) =>
  useQuery({
    queryKey: "taskCount" as QueryKey,
    queryFn: getTaskCount,
    refetchOnWindowFocus: false,
    ...config
  });

export const useGetTaskDependencies = (
  processId: number,
  config?: UseQueryOptions<UseQueryReturnType<typeof getTaskDependencyData>>
) =>
  useQuery({
    ...config,
    queryKey: [processId, "taskDependencies"] as QueryKey,
    queryFn: () => getTaskDependencyData(processId)
  });
