import { FilterTypes } from "@certa/queries";
import type { MessageDescriptor } from "react-intl";
export const PAGE_SIZE = 20;

export const DEFAULT_COLUMNS: MessageDescriptor[] = [
  {
    id: "savedSearch.workflowName",
    defaultMessage: "Name"
  },
  {
    id: "savedSearch.alerts",
    defaultMessage: "Notifications"
  },
  {
    id: "savedSearch.workflowStatus",
    defaultMessage: "Status"
  },
  {
    id: "savedSearch.progress",
    defaultMessage: "Progress"
  },
  {
    id: "savedSearch.actions",
    defaultMessage: "Actions"
  }
];

export const FIXED_COLUMNS: MessageDescriptor[] = [
  {
    id: "savedSearch.workflowName",
    defaultMessage: "Name"
  }
];

//accordion specific constants
export const PROCESS_STATUS = FilterTypes.STATUS; // not important to use FilterTypes (JUST TO KEEP CLEAN CONSTANTS)
export const BUSINESS_UNIT = FilterTypes.BUSINESS_UNIT;
export const REGION = FilterTypes.REGION;
export const DATE_RANGE = `${FilterTypes.START_DATE}~${FilterTypes.END_DATE}`;
export const KIND = FilterTypes.KIND_ID;
export const NESTED_ENTITY_FILTER_PREFIX = "relationship-type__eq__";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
