/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import React from "react";
import { getCSSToken } from "../utils";

type CenterProps = {
  maxWidth: string;
  gutters?: string;
} & { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>;

const Center = React.forwardRef<HTMLDivElement, CenterProps>((props, ref) => {
  const { gutters, maxWidth, children, ...htmlProps } = props;
  return (
    <div
      {...htmlProps}
      ref={ref}
      css={[
        css`
          box-sizing: content-box;
          margin-left: auto;
          margin-right: auto;
          max-width: ${getCSSToken(maxWidth)};
          width: 100%;
          padding-left: ${getCSSToken(gutters || "0")};
          padding-right: ${getCSSToken(gutters || "0")};
          --label: center;
        `
      ]}
    >
      {children}
    </div>
  );
});

Center.defaultProps = {
  gutters: "0px"
};

export { Center };
