import React, { useState, useEffect, useContext, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { get as lodashGet, debounce } from "lodash-es";
import { Text } from "@certa/blocks/thanos";
import { Info } from "@certa/icons";
import COPY_TEXT from "../../util/copyText";
import { showMessage, useNotificationsNewUI } from "@certa/common";
import { ChannelContext, ThreadContext } from "../../comments.context";
import { useRemoveSubscription, useAddSubscription } from "@certa/queries";
import {
  Tooltip,
  Switch,
  Stack,
  Typography,
  TypographyVariants,
  TypographyColors
} from "@certa/catalyst";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

const DEBOUNCE_TIMER_IN_MS = 500;

const initiateUserSubscriptionFlow = (
  cb: Function,
  toggleValue: boolean,
  threadId: number,
  onSuccess: Function,
  onError: Function
) => {
  return cb(
    {
      enable: toggleValue,
      threadId
    },
    {
      onSuccess: () => onSuccess(toggleValue),
      onError
    }
  );
};

const debounceRequest = debounce(
  initiateUserSubscriptionFlow,
  DEBOUNCE_TIMER_IN_MS
);

const SubscribeSwitch = () => {
  const intl = useIntl();
  const { threadId, subscribedBy } = useContext(ThreadContext);
  const { objectId, objectType } = useContext(ChannelContext);
  const userId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );
  const [isSubscribed, setIsSubscribed] = useState(() =>
    // @ts-expect-error - use useAppSelector from common package instead of useSelector
    subscribedBy.includes(userId)
  );
  const { mutate: removeSubscription } = useRemoveSubscription(
    objectId,
    objectType,
    // @ts-expect-error - use useAppSelector from common package instead of useSelector
    userId
  );
  const { mutate: addSubscription } = useAddSubscription(
    objectId,
    objectType,
    // @ts-expect-error - use useAppSelector from common package instead of useSelector
    userId
  );

  useEffect(() => {
    // @ts-expect-error - use useAppSelector from common package instead of useSelector
    setIsSubscribed(subscribedBy.includes(userId));
  }, [threadId, subscribedBy, userId]);

  const handleOnSuccess = useCallback((toggleValue: boolean) => {
    setIsSubscribed(toggleValue);
  }, []);

  const handleOnError = useCallback(() => {
    showMessage(
      "error",
      intl.formatMessage({
        id: "comments.errors.changeSubscriptionFailed",
        defaultMessage: COPY_TEXT.DEFAULT_MESSAGES.UNABLE_TO_SUBSCRIBE
      })
    );
  }, [intl]);

  const debouncedCallback = useCallback(
    (cb: Function, toggleValue: boolean) => {
      return debounceRequest(
        cb,
        toggleValue,
        threadId,
        handleOnSuccess,
        handleOnError
      );
    },
    [handleOnError, handleOnSuccess, threadId]
  );

  const onToggle = (toggleValue: boolean) => {
    MixPanelActions.track(
      toggleValue
        ? MixPanelEvents.workflowDetailEvents
            .COMMENT_DETAIL_PANE_CLICK_SUBSCRIBE_TO_THREAD
        : MixPanelEvents.workflowDetailEvents
            .COMMENT_DETAIL_PANE_CLICK_UNSUBSCRIBE_FROM_THREAD
    );
    const mutatorFn = toggleValue ? addSubscription : removeSubscription;
    debouncedCallback(mutatorFn, toggleValue);
  };

  const isNewCommentUXEnabled = useNotificationsNewUI();

  const subscribedMessage = intl.formatMessage({
    id: "comments.subscribedMessage",
    defaultMessage: "SUBSCRIBED"
  });

  return isNewCommentUXEnabled ? (
    <Stack direction="horizontal" align="center" justify="flex-start" gap="8">
      <Switch
        label={subscribedMessage}
        checked={isSubscribed}
        onChange={onToggle}
      />
      <Typography
        variant={TypographyVariants.OVERLINE_SMALL}
        color={TypographyColors.NEUTRAL_700}
      >
        {subscribedMessage}
      </Typography>
    </Stack>
  ) : (
    <Stack direction="horizontal" align="center" justify="flex-start" gap="8">
      <Text
        variant="p1-medium"
        color="neutral-100"
        style={{ color: "var(--colors-neutral-700)" }}
      >
        {COPY_TEXT.SUBSCRIBE_TEXT}
      </Text>
      <Tooltip
        content={
          isSubscribed ? COPY_TEXT.SWITCH_ON_TEXT : COPY_TEXT.SWITCH_OFF_TEXT
        }
      >
        <Info color="brand-15" />
      </Tooltip>
      <Switch
        label={
          isSubscribed ? COPY_TEXT.SWITCH_ON_TEXT : COPY_TEXT.SWITCH_OFF_TEXT
        }
        checked={isSubscribed}
        onChange={onToggle}
      />
    </Stack>
  );
};

export { SubscribeSwitch };
