import type {
  AttributesPayloadIC,
  ColumnIC,
  FilterConditionPayloadIC,
  FilterIC,
  FilterPayloadIC,
  GroupByIC,
  GroupByPayloadIC,
  OperationArgumentPayloadIC,
  OperationIC,
  OperationPayloadIC,
  OrderByIC,
  OrderByPayloadIC,
  OtherChartConfigurationsED
} from "@certa/types";
import { otherConfigurationsPayloadCreatorED } from "./emailDeliveryReportPayload.model";

type Params = {
  attributes?: ColumnIC[];
  groupBy: GroupByIC[];
  operations: OperationIC[];
  orderBy?: OrderByIC[];
  otherConfigurations?: OtherChartConfigurationsED;
};

type ParamsReportIC = {
  attributes?: ColumnIC[];
  groupBy?: GroupByIC[];
  operations?: OperationIC[];
  orderBy?: OrderByIC[];
  filters?: FilterIC;
};

type PayloadResultIC = {
  attributes?: AttributesPayloadIC[];
  operations?: OperationPayloadIC[];
  group_by?: GroupByPayloadIC[];
  order_by?: OrderByPayloadIC[];
  filters?: FilterPayloadIC;
};

export const chartConfigPayloadCreatorIC = (data: Params) => {
  return {
    attributes: attributesPayloadCreatorIC(data.attributes),
    operations: operationPayloadCreatorIC(data.operations),
    group_by: groupByPayloadCreatorIC(data.groupBy),
    order_by: orderByPayloadCreatorIC(data.orderBy),
    other_configurations: otherConfigurationsPayloadCreatorED(
      data.otherConfigurations
    )
  };
};

const attributesPayloadCreatorIC = (
  columns?: ColumnIC[]
): AttributesPayloadIC[] | undefined => {
  if (!columns) {
    return undefined;
  }
  return columns.map(attribute => ({
    type: attribute.type,
    value: attribute.value,
    label: attribute.label,
    extra_json: {
      field_type: attribute.extraJSON.fieldType,
      is_hidden: attribute.extraJSON.isHidden,
      added_by: attribute.extraJSON.addedBy
    }
  }));
};

const groupByPayloadCreatorIC = (groupBys: GroupByIC[]): GroupByPayloadIC[] => {
  return groupBys.map(groupBy => ({
    type: groupBy.type,
    source: groupBy.source,
    operations: groupBy.operations
      ? operationPayloadCreatorIC(groupBy.operations)
      : undefined,
    extra_json: {
      label: groupBy.extraJSON.label,
      field_type: groupBy.extraJSON.fieldType,
      data_type: groupBy.extraJSON.dataType,
      label_output_type: groupBy.extraJSON.labelOutputType,
      is_for_secondary_x_axis: groupBy.extraJSON.isForSecondaryXAxis,
      operation_type: groupBy.extraJSON.operationType,
      data_sources: groupBy.extraJSON.dataSources
    }
  }));
};

const orderByPayloadCreatorIC = (
  orderBys?: OrderByIC[]
): OrderByPayloadIC[] | undefined => {
  if (!orderBys) {
    return undefined;
  }
  return orderBys.map(orderBy => ({
    type: orderBy.type,
    ordering: orderBy.ordering,
    source: orderBy.source,
    operations: orderBy.operations
      ? operationPayloadCreatorIC(orderBy.operations)
      : undefined
  }));
};

const operationPayloadCreatorIC = (
  operations: OperationIC[]
): OperationPayloadIC[] => {
  return operations.map(operation => ({
    type: operation.type,
    label: operation.label,
    data_type: operation.dataType,
    function: operation.function,
    arguments: operationArgumentPayloadCreatorIC(operation.arguments),
    extra_args: {
      separator: operation.extraArgs.separator,
      compare_value: operation.extraArgs.compareValue
    },
    extra_json: {
      output_data_type: operation.extraJSON.outputDataType,
      axis_name: operation.extraJSON.axisName,
      field_type: operation.extraJSON.fieldType,
      tag: operation.extraJSON.tag
    }
  }));
};

const operationArgumentPayloadCreatorIC = (
  argumentsData: OperationIC["arguments"]
) => {
  return argumentsData.map(argument => {
    if (typeof argument === "string") {
      return argument;
    }
    const newArgument: OperationArgumentPayloadIC = {
      label: argument.label,
      data_type: argument.dataType,
      function: argument.function,
      arguments: operationArgumentPayloadCreatorIC(argument.arguments),
      extra_args: {
        separator: argument.extraArgs.separator,
        compare_value: argument.extraArgs.compareValue
      }
    };
    return newArgument;
  });
};

export const columnsPayloadCreatorIC = (data: ParamsReportIC) => {
  const result: PayloadResultIC = {};
  result.attributes = attributesPayloadCreatorIC(data.attributes);
  if (data.operations) {
    result.operations = operationPayloadCreatorIC(data.operations);
  }
  if (data.groupBy) {
    result.group_by = groupByPayloadCreatorIC(data.groupBy);
  }
  if (data.orderBy) {
    result.order_by = orderByPayloadCreatorIC(data.orderBy);
  }
  if (data.filters) {
    result.filters = filterPayloadCreatorIC(data.filters);
  }
  return result;
};

export const filterPayloadCreatorIC = (filter: FilterIC) => {
  const conditions: (FilterPayloadIC | FilterConditionPayloadIC)[] =
    filter.conditions.map(condition => {
      if ("type" in condition) {
        return filterPayloadCreatorIC(condition);
      }
      return {
        lhs_type: condition.lhsType,
        data_type: condition.dataType,
        lhs_source: condition.lhsSource,
        op: condition.operation,
        rhs_value: condition.rhsValue,
        extra_json: {
          field_type: condition.extraJSON.fieldType,
          field_value: condition.extraJSON.fieldValue
        }
      };
    });
  return {
    type: filter.type,
    conditions
  };
};
