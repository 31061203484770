import { useIntl } from "react-intl";
import {
  Stack,
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst";
import { css } from "emotion";
import { EventLogIllustration } from "../illustrations/EventsLogIllustration";

export const NoResultsInActivityLogs = ({ logType }: { logType: string }) => {
  return (
    <Stack
      justify="center"
      align="center"
      direction="vertical"
      dangerouslySetClassName={noResultsWrapperStyles} // to vertically center
    >
      <Stack align="center" gap="32" direction="vertical">
        <EventLogIllustration />
        <NoResultsText logType={logType} />
      </Stack>
    </Stack>
  );
};

export const NoResultsText = ({ logType }: { logType: string }) => {
  const intl = useIntl();

  const getActivityLogMessage = (logType: string) => {
    switch (logType) {
      case "significantEvents":
        return {
          title: intl.formatMessage({
            id: "activityLog.noSignificantEventsTitle",
            defaultMessage: "No significant Events yet"
          }),
          message: intl.formatMessage({
            id: "activityLog.noSignificantEventsMessage",
            defaultMessage:
              "A list of significant events will appear here in chronological order"
          })
        };
      case "views":
        return {
          title: intl.formatMessage({
            id: "activityLog.noViewsTitle",
            defaultMessage: "No views yet"
          }),
          message: intl.formatMessage({
            id: "activityLog.noViewsMessage",
            defaultMessage:
              "A list of users who have viewed this workflow will appear here in chronological order"
          })
        };
      case "edits":
        return {
          title: intl.formatMessage({
            id: "activityLog.noEditsTitle",
            defaultMessage: "No edits yet"
          }),
          message: intl.formatMessage({
            id: "activityLog.noEditsMessage",
            defaultMessage:
              "A list of edits made by users or the system will appear here in chronological order"
          })
        };
      case "emails":
        return {
          title: intl.formatMessage({
            id: "activityLog.noEmailsTitle",
            defaultMessage: "No emails yet"
          }),
          message: intl.formatMessage({
            id: "activityLog.noEmailsTitleMessage",
            defaultMessage:
              "A list of emails sent to users as part of this workflow will appear here in chronological order"
          })
        };
      case "alerts":
        return {
          title: intl.formatMessage({
            id: "activityLog.noAlertsTitle",
            defaultMessage: "No notifications yet"
          }),
          message: intl.formatMessage({
            id: "activityLog.noAlertsMessage",
            defaultMessage:
              "A list of notifications triggered for this workflow will appear here in chronological order"
          })
        };
      default:
        return {
          title: intl.formatMessage({
            id: "activityLog.noLogsTitle",
            defaultMessage: "No logs yet"
          }),
          message: intl.formatMessage({
            id: "activityLog.noLogsMessage",
            defaultMessage:
              "A list of logs will appear here in chronological order"
          })
        };
    }
  };

  return (
    <Stack justify="center" align="center" gap="8">
      <Typography
        variant={TypographyVariants.TITLE_SM}
        color={TypographyColors.NEUTRAL_800}
      >
        {getActivityLogMessage(logType).title}
      </Typography>
      <Typography
        variant={TypographyVariants.BODY}
        color={TypographyColors.NEUTRAL_700}
        className={activityLogMessageStyles}
      >
        {getActivityLogMessage(logType).message}
      </Typography>
    </Stack>
  );
};

const noResultsWrapperStyles = css({
  backgroundColor: "var(--colors-neutral-200)",
  height: "100%"
});

const activityLogMessageStyles = css({
  width: "30rem",
  textAlign: "center"
});
