import type { FC, ReactNode } from "react";
import { useState, useContext, createContext, useCallback } from "react";

import type { CommentObjectTypes, WorkflowFamily } from "@certa/types";

export type MentionOverlayModes = "mentions" | "comments" | "subscriptions";

export type CommentData = {
  processId: number | null;
  processName?: string;
  objectType?: CommentObjectTypes;
  objectId?: number;
  threadId?: number;
  uid?: string;
  workflowFamily?: WorkflowFamily;
};

export type MentionsContextType = {
  show: boolean;
  toggleShow: (show?: boolean) => void;
  onClose: () => void;
  mode: MentionOverlayModes;
  updateMode: (mode: MentionOverlayModes) => void;
  commentData: CommentData;
  updateCommentData: (comment: CommentData) => void;
};

const MentionsContext = createContext<MentionsContextType | undefined>(
  undefined
);

export const MentionsContextProvider: FC<{
  children: ReactNode;
}> = props => {
  const [mode, setMode] = useState<MentionOverlayModes>("mentions");

  const [commentData, setCommentData] = useState<CommentData>({
    processId: null
  });

  const [shouldShow, setShouldShow] = useState(false);

  const toggleShow = useCallback((show?: boolean) => {
    if (show !== undefined) {
      setShouldShow(show);
    } else {
      setShouldShow(prevState => !prevState);
    }
  }, []);

  const updateCommentData = useCallback((comment: CommentData) => {
    setCommentData(comment);
  }, []);

  const updateMode = useCallback((mode: MentionOverlayModes) => {
    setMode(mode);
  }, []);

  const onClose = useCallback(() => {
    setShouldShow(false);
  }, []);

  return (
    <MentionsContext.Provider
      value={{
        show: shouldShow,
        toggleShow,
        onClose,
        mode,
        updateMode,
        commentData,
        updateCommentData
      }}
    >
      {props.children}
    </MentionsContext.Provider>
  );
};

export const useMentionsContext = () => {
  const contextValue = useContext(MentionsContext);
  if (typeof contextValue === "undefined") {
    throw new Error("No value defined for the mentions context");
  }
  return contextValue;
};
