import React, {
  useEffect,
  useState,
  cloneElement,
  isValidElement
} from "react";
import { ClassNames } from "@emotion/core";
import type { FC, ReactElement } from "react";
import { Dropdown as AntDropdown } from "antd";
import type { DropDownProps as AntDropDownProps } from "antd/lib/dropdown";
import { getLayoutContainer } from "../utils";

// type OptionValue = string;
// type Option = { label: string | React.ReactNode; value: OptionValue };

export type DropdownProps = Omit<
  AntDropDownProps,
  "getPopupContainer" | "children"
> & {
  children: (isOpen: boolean) => ReactElement;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement | null;
  destroyPopupOnHide?: boolean;
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { DropdownMenu } from '@certa/catalyst';
 */
export const Dropdown: FC<DropdownProps> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    overlay,
    onVisibleChange,
    children,
    overlayClassName,
    getPopupContainer,
    ...dropdownProps
  } = props;

  // onVisibleChange is not called when the any menu item is clicked
  // its by design https://github.com/react-component/dropdown/commit/f304a249a17b3596c8252f5f2ec10a2b64804a32#
  // One solution is to hijack the onClick on the overlay root
  // And always mark the the isOpen state false in the click handler
  // NOTE: There are two cases to be handled
  // 1. overlay could be a function or a react element.
  // There is nothing can be done in the case of a function
  // 2. If its a react element, it might or might not have its on onClick
  // That onClick needs to be called with all the args
  const childrenWithOnClick = isValidElement(overlay)
    ? cloneElement(overlay, {
        // @ts-expect-error TS5 upgrade
        onClick: (...args: unknown[]) => {
          // @ts-expect-error TS5 upgrade
          overlay.props?.onClick?.(...args);
          setIsOpen(false);
        }
      })
    : props.overlay;

  useEffect(() => {
    if (dropdownProps.visible !== undefined) {
      setIsOpen(dropdownProps.visible);
    }
  }, [dropdownProps.visible]);
  return (
    <ClassNames>
      {({ css, cx }) => (
        <AntDropdown
          trigger={["click"]}
          {...dropdownProps}
          getPopupContainer={triggerNode =>
            getPopupContainer?.(triggerNode) || getLayoutContainer()
          }
          overlayClassName={cx(
            css`
              &.ant-dropdown::before {
                pointer-events: none;
              }
            `,
            overlayClassName
          )}
          onVisibleChange={visible => {
            setIsOpen(visible);
            onVisibleChange?.(visible);
          }}
          overlay={childrenWithOnClick}
        >
          {children?.(isOpen)}
        </AntDropdown>
      )}
    </ClassNames>
  );
};
