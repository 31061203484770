export const iframePostMessage = (
  message: { text: string; id: string },
  data: Record<string, any>,
  origins: Array<string> | string
) => {
  if (Array.isArray(origins)) {
    origins.forEach(origin => {
      window.parent.postMessage({ message, data }, origin);
    });
  } else {
    window.parent.postMessage({ message, data }, origins);
  }
};
