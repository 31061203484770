import { css } from "emotion";
import type { FC } from "react";
import React, { useMemo } from "react";
import { Text, Button, Stack } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";
import { getVariantStyles } from "@certa/blocks/src/componentsTh/Typography/utils";
import type { StepGroupsData } from "@certa/queries";
import { useGetTaskLaneList } from "@certa/queries";
import { getProcessDetailRoute } from "../utils/routes";
import { Arrow, Circle, Clock, Kanban } from "@certa/icons";
import { Table } from "antd";
import moment from "moment";
import type { Step } from "@certa/types";

const tableStyles = css`
  //--For border and manging fonts of the table.--//
  border: 1px solid var(--neutral-20);
  border-radius: var(--normal-border-radius);
  background-color: var(--neutral-5);

  thead .ant-table-cell {
    ${getVariantStyles("p2-medium")}
    color: var(--neutral-70);
  }
  //-----------------------------------------------//

  //--For managing the border radius of the table and tablecells--//
  .ant-table {
    border-radius: var(--normal-border-radius);
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: var(--normal-border-radius);
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: var(--normal-border-radius);
  }
  //----------------------------------------------------------------//

  //--For managing the background color of different components of table.--//
  .ant-table-row-level-0 {
    & .create-new-button {
      visibility: hidden !important;
    }
    :hover {
      & > td {
        background-color: var(--teal-60) !important;
      }
      & .process-name {
        color: var(--brand) !important;
      }
      & .create-new-button {
        visibility: visible !important;
      }
    }
  }
  //-------------------------------------------------------------------------//
`;

type MyTaskRow = {
  stepTag: string;
  stepGroupName: string;
  stepName: string;
  deadline: string | null;
  overdue: boolean;
  id: number;
};

export const getVisibleAssignedSteps = (
  processDetails: StepGroupsData | undefined
) => {
  return (
    processDetails?.taggedSteps
      .map((stepTag: string) => {
        const stepGroupDetails = processDetails.results.find(
          (stepGroup: { steps: Step[] }) => {
            return stepGroup.steps.find(step => step.definitionTag === stepTag);
          }
        );

        if (!stepGroupDetails) {
          return null;
        }
        const stepDetails = stepGroupDetails.steps.find(
          (step: { definitionTag: string }) => step.definitionTag === stepTag
        );

        if (!stepDetails) {
          return null;
        }
        return {
          stepTag: stepTag,
          stepGroupName: stepGroupDetails.name,
          stepName: stepDetails.name,
          deadline: stepDetails.deadline,
          overdue: stepDetails.overdue,
          id: stepDetails.id
        };
      })
      .filter(data => !!data) || []
  );
};

const MyTasksTable: FC<{
  processId: number;
}> = ({ processId }) => {
  const intl = useIntl();
  const { data: processDetails } = useGetTaskLaneList(processId);

  const tableData = useMemo<(MyTaskRow | null)[]>(
    () => getVisibleAssignedSteps(processDetails),
    [processDetails]
  );

  const columns = useMemo(() => {
    return [
      {
        title: intl.formatMessage({
          id: "myTaskTable.Task",
          defaultMessage: "TASK"
        }),
        width: "70%",
        path: "label",
        render: ({
          stepGroupName,
          stepName
        }: {
          stepGroupName: string;
          stepName: string;
        }) => {
          return (
            <Stack gap="s2">
              <Circle />
              <Stack gap="s1">
                <Kanban color="neutral-70" />
                <Text color="neutral-70">{stepGroupName}</Text>
                <Arrow color="neutral-70" />
                <Text>{stepName}</Text>
              </Stack>
            </Stack>
          );
        }
      },
      {
        title: intl.formatMessage({
          id: "myTaskTable.dueIn",
          defaultMessage: "DUE IN"
        }),
        path: "deadline",
        sorter: (a: MyTaskRow, b: MyTaskRow) => {
          if (!a.deadline || !b.deadline) {
            return 0;
          }
          return new Date(a.deadline).getTime() < new Date(b.deadline).getTime()
            ? 1
            : -1;
        },
        render: ({
          deadline,
          overdue
        }: {
          deadline: string;
          overdue: boolean;
        }) => {
          return deadline ? (
            <Stack align={"center"} gap="s1">
              {overdue && <Clock color="red" />}
              <Text color={overdue ? "red" : "neutral-70"}>
                {moment(deadline).fromNow()}
              </Text>
            </Stack>
          ) : null;
        }
      },
      {
        title: "",
        path: "id",
        width: "10%",
        render: ({ id }: { id: number }) => {
          return (
            <Button
              className="create-new-button"
              size="small"
              type="primary"
              icon={<Arrow />}
              iconAsSuffix
              href={`${getProcessDetailRoute(processId)}/?step=${id}`}
            >
              {intl.formatMessage({
                id: "myTaskTable.goToTask",
                defaultMessage: "Go to task"
              })}
            </Button>
          );
        }
      }
    ];
  }, [intl, processId]);

  return (
    <Table
      columns={columns}
      className={tableStyles}
      rowKey={"id"}
      dataSource={tableData as MyTaskRow[]}
      pagination={false}
    />
  );
};

export default MyTasksTable;
