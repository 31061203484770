import { STUDIO_VERSION, STUDIO_THEME, useLocalStorage } from "@certa/common";
import { useCallback } from "react";
import { STUDIO_SETTINGS_KEY_V2 } from "../constants/studioSettings";

type StudioSettings = {
  theme: STUDIO_THEME;
  version: STUDIO_VERSION;
};

const defaultSettings: StudioSettings = {
  theme: STUDIO_THEME.LIGHT,
  version: STUDIO_VERSION.NEXT
};

/**
 * Sets the default theme to dark if the user's OS is set to dark mode.
 */
if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  defaultSettings.theme = STUDIO_THEME.DARK;
}

// This is not being used in the codebase, keeping it for future reference if we need to use it.
export const useStudioSettings = () => {
  const [settings, setSettings] = useLocalStorage<StudioSettings>(
    STUDIO_SETTINGS_KEY_V2,
    defaultSettings
  );
  const changeSetting = useCallback(
    (newSettings: Partial<StudioSettings>) => {
      setSettings(settings => {
        const updatedSettings = {
          ...settings,
          ...newSettings
        };
        return updatedSettings;
      });
    },
    [setSettings]
  );

  return {
    settings,
    changeSetting
  };
};
