import { useQuery, type QueryKey, type UseQueryOptions } from "react-query";
import {
  getBusinessUnits,
  getRegionsList,
  getSearchFileResults,
  getSearchResults,
  getStatusListForSearch
} from "../services/search.services";
import { fetchStatusesListConditionally } from "../utils/utils";
import type { UseQueryReturnType } from "@certa/common";
import type { SearchFileResults } from "../types/search.types";

export const useGetBusinessUnits = (region?: string | null) =>
  useQuery({
    retry: 0,
    staleTime: Infinity,
    queryKey: region ? ["businessUnits", region] : ["businessUnits"],

    queryFn: () => {
      if (!region) {
        return Promise.reject("region must be a string!");
      }
      return getBusinessUnits(region);
    }
  });

export const useGetRegions = () =>
  useQuery({
    retry: 0,
    staleTime: Infinity,
    queryKey: "regions",
    queryFn: getRegionsList
  });

export const useGetStatusListForSearch = (
  config?: UseQueryOptions<UseQueryReturnType<typeof getStatusListForSearch>>
) =>
  useQuery({
    retry: 0,
    staleTime: Infinity,
    ...config!,
    queryKey: "search-statuses" as QueryKey,
    queryFn: getStatusListForSearch
  });

export const useGetSearchResults = (
  params: {
    urlParams: string;
    page: number;
  },
  config?: UseQueryOptions<UseQueryReturnType<typeof getSearchResults>>
) =>
  useQuery({
    retry: 0,
    // 1 minute
    staleTime: 60 * 1000,
    ...config,
    queryKey: ["search-results", params.urlParams, params.page] as QueryKey,
    queryFn: async ({ signal }) => {
      await fetchStatusesListConditionally();
      return getSearchResults(params.urlParams, params.page, { signal });
    }
  });

export const useGetSearchFileResults = (
  params: {
    searchText: string;
    page: number;
  },
  config?: UseQueryOptions<UseQueryReturnType<typeof getSearchFileResults>>
) => {
  return useQuery<any, any, SearchFileResults>({
    retry: 0,
    // 1 minute
    staleTime: 60 * 1000,
    ...config,
    queryKey: ["attachment-search", params.searchText, params.page] as QueryKey,
    queryFn: async ({ signal }) => {
      return getSearchFileResults(params.searchText, params.page, { signal });
    }
  });
};
