import type { Field, HashMap } from "@certa/types";
import { isValidElement } from "react";
import { LENGTH_ZERO, urlWithDependentFieldsRegEx } from "../constants";
/**
 * Returns a sanitized string that can be used with RegExp constructor.
 * Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 * @param str
 */
export function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

/**
 * Format the apiUrl if it exists in config else return null
 * @param apiUrl
 * @param fieldList
 * @returns
 */
export const optionApiUrlFormatted = (
  fieldList: Field[],
  apiUrl: string,
  record?: HashMap,
  formValues?: HashMap
) => {
  let updatedUrl = apiUrl.replace(/\[([^\]]*?)\]/g, (expr, variable): any => {
    if (record && record?.[variable]) {
      return record[variable];
    }
    if (formValues && formValues?.[variable]) {
      return formValues[variable];
    }

    return "";
  });

  updatedUrl = updatedUrl.replace(
    urlWithDependentFieldsRegEx,
    (expr, variable): any => {
      const field = fieldList.find(
        (field: any) => field.definition.tag === variable
      );

      return field && field.answer ? field.answer.answer : "";
    }
  );

  return updatedUrl;
};

export const toCapital = (string: string) => {
  return string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * Converts ReactNode to a string
 * @param reactNode
 * @returns
 */
export const reactNodeToString = function (reactNode: React.ReactNode): string {
  let string = "";
  if (typeof reactNode === "string") {
    string = reactNode;
  } else if (typeof reactNode === "number") {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach(function (child) {
      string += reactNodeToString(child);
    });
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props.children);
  }
  return string;
};

/**
 * @param url
 * @param  allStepFields
 * @param  defaultAnswer
 */
export const replaceFieldTagsWithAnswers = (
  url: string,
  allStepFields: {
    definition: { tag: string };
    answer: { answer: string } | null;
  }[] = [],
  defaultAnswer: string,
  shouldEncode: boolean = false
): string => {
  const getFieldAnswer = (fieldName: string) => {
    if (!fieldName) {
      return shouldEncode ? encodeURIComponent(defaultAnswer) : defaultAnswer;
    }

    const field = allStepFields.find(
      field => field.definition.tag === fieldName
    );

    if (field && field.answer) {
      return shouldEncode
        ? encodeURIComponent(field.answer.answer)
        : field.answer.answer;
    }
  };

  url = url.replace(
    urlWithDependentFieldsRegEx,
    (expr, variable) => getFieldAnswer(variable) || ""
  );

  return url;
};

export const getDependentFieldTagsFromAPIUrl = (apiUrl?: string): string[] => {
  const matches = apiUrl?.match(urlWithDependentFieldsRegEx);
  return matches ?? [];
};

export const hasOnlySpaces = (value: string | undefined) => {
  if (!value) {
    return false;
  }
  return !!(value.length > LENGTH_ZERO && value.trim().length === LENGTH_ZERO);
};

export const hasSpaceInStartOrEnd = (value: string | undefined) => {
  if (!value) {
    return false;
  }
  return value.startsWith(" ") || value.endsWith(" ");
};

/**
 * Pluralizes a word based on a count, appending 's' if count is not equal to 1.
 * @param {string} word - The word to pluralize.
 * @param {number} count - The count used to determine pluralization.
 * @returns {string} - The pluralized word.
 */
export const pluralizeByAppendingS = (word: string, count: number) => {
  return count === 1 ? word : `${word}s`;
};

/**
 * Truncates a string and adds ellipsis if it exceeds the specified limit.
 * @param {string} str - The string to truncate.
 * @param {number} limit - The character limit.
 * @returns {string} - The truncated string with ellipsis if necessary.
 */
export const truncateString = (str: string, limit: number): string => {
  if (str.length <= limit) {
    return str;
  } else {
    return str.slice(0, limit) + "...";
  }
};
