/** @jsx jsx */
/** @jsxRuntime classic */
import React from "react";
import { Global, css, jsx } from "@emotion/core";
import {
  APP_COLORS,
  TYPOGRAPHY_VARIANTS,
  Z_INDEX
} from "@certa/blocks/src/constants";

/**
 * Global styling would be used for entire application
 */
export const GlobalStyle = () => <Global styles={style} />;

const style = css`
  :root {
    --ratio: 1.5;
    --multiple: 4px;
    --s00: 0px;
    --s0: 1px;
    --s1: 4px;
    --s2: 8px;
    --s3: 12px;
    --s4: 16px;
    --s5: 20px;
    --s6: 24px;
    --s7: 28px;
    --s8: 32px;
    --s9: 36px;
    --s10: 40px;
    --s11: 44px;
    --s12: 48px;
    --s13: 52px;
    --s14: 56px;
    --s15: 60px;
    --s16: 64px;
    --s17: 68px;
    --s18: 72px;
    --s19: 76px;
    --s20: 80px;

    --n-s1: -4px;
    --n-s2: -8px;
    --n-s4: -16px;
    --n-s6: -24px;

    /* Breakpoints */
    --min-width: 900px;

    /* Border radius */
    --small-border-radius: 4px;
    --normal-border-radius: 6px;
    --big-border-radius: 8px;

    /* Elevations */
    --popover-shadow: 0px 2px 16px rgba(0, 22, 78, 0.1);

    /* Font Family */
    --font-family: "Inter", sans-serif, "system-ui";
    ${Object.entries(APP_COLORS)
      .map(([colorName, hexcode]) => `--${colorName}: ${hexcode};`)
      .join("")}

    /* Typography Variants */
  ${Object.entries(TYPOGRAPHY_VARIANTS)
      .map(
        ([fontVariant, value]) => `
        --${fontVariant}-font-size: ${value.fontSize};
        --${fontVariant}-line-height: ${value.lineHeight};
        --${fontVariant}-font-weight: ${value.fontWeight};
        ${
          value.fontStyle
            ? `--${fontVariant}-font-style: ${value.fontStyle};`
            : ""
        }
        ${
          value.textTransform
            ? `--${fontVariant}-text-transform: ${value.textTransform};`
            : ""
        }
      `
      )
      .join("")}

  /* z-index */
  ${Object.entries(Z_INDEX)
      .map(([name, value]) => `--${name}: ${value};`)
      .join("")}

  /**************** Claudiu Design System ***************/
  /*************** DEPRECATED - DO NOT USE **************/

  /** Font Sizes and line height */
  --h1: 48px;
    --h1-line-height: 56px;

    --h2: 32px;
    --h2-line-height: 40px;

    --h3: 24px;
    --h3-line-height: 32px;

    --h4: 20px;
    --h4-line-height: 28px;

    --h5: 18px;
    --h5-line-height: 22px;

    --p1: 16px;
    --p1-line-height: 24px;

    --p2: 14px;
    --p2-line-height: 20px;

    --p3: 12px;
    --p3-line-height: 16px;

    --p4: 10px;
    --p4-line-height: 12px;

    /** Font Weights */
    --regular: 400;
    --medium: 500;
    --semi-bold: 600;
    --bold: 700;
    --bolder: 800;

    --label: CSSVariables;
  }
  /* Select dropdown icon fix */
  .ant-select .ant-select-arrow {
    pointer-events: none !important;
  }
  .hightlight-message {
    background: ${APP_COLORS["orange-40"]};
    border-radius: calc(var(--small-border-radius) / 2);
    padding: 0 calc(var(--s0) * 2);
  }
`;
