import type { UserMention } from "@certa/queries";
import {
  useGetMentionsList,
  useMarkMentionRead,
  useGetUnReadMentionCount
} from "@certa/queries";
import {
  ListWrapper,
  LoadingAndEmptyStatesWrapper,
  SomethingWentWrong,
  getProcessDetailRoute,
  getDashboardsRoute,
  getFullScreenReportRoute
} from "@certa/common";
import { MentionItem } from "./MentionsItem";
import { Stack } from "@certa/catalyst";
import { Spinner3 } from "@certa/icons";
import { useHistory } from "react-router-dom";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import { useMentionsContext, type CommentData } from "../../MentionsContext";
import { useState } from "react";
import { useIntl } from "react-intl";
import {
  MixPanelActions,
  MixPanelEvents
} from "../../../../../main/src/js/_helpers/mixpanel";

type MentionsContainerProps = {
  showUnreadOnly: boolean;
};

const getKey = (mention: UserMention) => mention.mentionId.toString();
const getLabel = (mention: UserMention) => mention.message;

const renderMentionItem = (mention: UserMention) => (
  <MentionItem mention={mention} />
);

const MentionsContainer = ({ showUnreadOnly }: MentionsContainerProps) => {
  const intl = useIntl();

  const allSetMessage = intl.formatMessage({
    id: "notifications.allSetMessage",
    defaultMessage: "You’re all set!"
  });

  const noUnreadMentionsMessage = intl.formatMessage({
    id: "notifications.noUnreadMessage",
    defaultMessage: "You have no unread notifications"
  });

  const noNewMentionsMessge = intl.formatMessage({
    id: "notifications.noNewMentionsMessage",
    defaultMessage: "You have no new notifications"
  });

  const noNotificationsMessage = showUnreadOnly
    ? noUnreadMentionsMessage
    : noNewMentionsMessge;

  const loadingNotificationsMessage = intl.formatMessage({
    id: "notifications.loadingNotificationsMessage",
    defaultMessage: "Loading notifications, please wait..."
  });

  const { status, data, isFetching, hasNextPage, fetchNextPage } =
    useGetMentionsList();

  const [processId, setProcessId] = useState<number | null>(null);

  const { mutate: markAsRead } = useMarkMentionRead({
    onSuccess: () => refetch()
  });
  const { data: processDetails, status: processDetailsStatus } =
    useProcessDetails(processId);

  const { updateMode, updateCommentData } = useMentionsContext();

  const history = useHistory();

  const { refetch } = useGetUnReadMentionCount();

  if (status === "error") return <SomethingWentWrong />;

  const mentions = data?.pages.flatMap(page => page.results);

  const listItems = showUnreadOnly
    ? mentions?.filter(mention => !mention.isRead)
    : mentions;

  const processName = processDetails?.definition.name;

  const isDashboardMention = processName === "report_dashboard";
  const isReportMention = processName === "report_workflow";

  const handleOnMentionClick = (mentionItem: UserMention) => {
    MixPanelActions.track(
      MixPanelEvents.notificationEvents
        .NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_OPEN_COMMENT_NOTIFICATION
    );
    const { mentionId, process, isRead, mentionData } = mentionItem;
    setProcessId(process?.processId);

    !isRead && markAsRead(mentionId);

    if (processDetailsStatus === "loading") return;

    const {
      object_id: objectId,
      type: objectType,
      thread_id: threadId,
      uid
    } = mentionData;

    const commentData: CommentData = {
      objectId,
      objectType,
      threadId,
      uid,
      processId: process.processId,
      processName: process.processName,
      workflowFamily:
        isDashboardMention || isReportMention
          ? processDetails?.workflowFamily
          : []
    };

    const location = history.location;
    const [, type, id] = location?.pathname?.split("/") || [];

    // If the user is already on the same page as that of the mention
    // then we don't need to redirect the user
    if (
      !(
        ["process", "dashboard", "report"].includes(type) &&
        process?.processId &&
        process.processId === Number(id)
      )
    ) {
      if (isDashboardMention) {
        history.push(
          `${getDashboardsRoute(process?.processId)}?redirectFromComments=true`
        );
        return;
      }

      if (isReportMention) {
        history.push(
          `${getFullScreenReportRoute(
            process?.processId
          )}?redirectFromComments=true`
        );
        return;
      }

      const redirectCommentData = encodeURIComponent(
        JSON.stringify(commentData)
      );

      history.push(
        `${getProcessDetailRoute(
          process?.processId
        )}?group=${mentionData?.group}&step=${mentionData?.step}&redirectFromComments=true&redirectCommentData=${redirectCommentData}`
      );
    }

    // changing drawer mode to comments drawer
    updateMode("comments");
    // setting comment data for drawer
    updateCommentData(commentData);
  };

  return (
    <Stack direction="vertical">
      {listItems && listItems.length > 0 ? (
        <ListWrapper
          options={listItems}
          aria-label="mentions list box"
          getKey={getKey}
          getLabel={getLabel}
          onClick={mentionItem => handleOnMentionClick(mentionItem)}
          render={renderMentionItem}
          loadMore={fetchNextPage}
          hasMore={hasNextPage || false}
        />
      ) : (
        <LoadingAndEmptyStatesWrapper
          primaryMessage={!isFetching ? allSetMessage : ""}
          secondaryMessage={
            !isFetching ? noNotificationsMessage : loadingNotificationsMessage
          }
          secondaryIcon={isFetching ? <Spinner3 size={12} /> : undefined}
        />
      )}
    </Stack>
  );
};

export { MentionsContainer };
