import { Menu as AntMenu } from "antd";
import styled from "@emotion/styled";

export const MenuDivider = styled(AntMenu.Divider)`
  background-color: var(--neutral-20) !important;
  height: 1px !important;
  margin: 6px 0 !important;
  overflow: hidden !important;
  line-height: 0 !important;
  --label: CustomMenuDivider;
`;
