import styled from "@emotion/styled";
import { Form as AntForm } from "antd";

export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 var(--s1);
  }
`;

export const FormItem = AntForm.Item;
export const useForm = AntForm.useForm;
