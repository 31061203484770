import React from "react";
import styled from "@emotion/styled";
import type { UserMentionType } from "../interfaces";
import { UserMentionView } from "./UserMentionView";
import { useIntl } from "react-intl";
import { MOBILE_DEVICE } from "../../common/viewPort";
import { Loader } from "../../common/components/Loader";

type UserMentionListPropsInterface = {
  isLoading: boolean;
  userMentions: UserMentionType[];
  onMarkAsRead: (id: number) => void;
};

const UserMentionList = React.memo((props: UserMentionListPropsInterface) => {
  const { isLoading, userMentions, onMarkAsRead } = props;
  const intl = useIntl();
  return (
    <UserMentionsContainer>
      {isLoading ? (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      ) : !!userMentions.length ? (
        <UserMentionViewContainer>
          {userMentions.map(
            (userMentionItem: UserMentionType): JSX.Element => (
              <UserMentionView
                key={userMentionItem.id}
                id={userMentionItem.id}
                workflowName={userMentionItem.workflowName}
                postedBy={userMentionItem.postedBy}
                createdAt={userMentionItem.createdAt}
                threadURL={userMentionItem.threadURL}
                message={userMentionItem.message}
                stepName={userMentionItem.stepName}
                fieldName={userMentionItem.fieldName}
                isRead={userMentionItem.isRead}
                onMarkAsRead={onMarkAsRead}
              />
            )
          )}
        </UserMentionViewContainer>
      ) : (
        <NoDataMessageContainer>
          {intl.formatMessage({
            id: "dashboard.userMentions.noDataMessage",
            defaultMessage: "No Mentions Found"
          })}
        </NoDataMessageContainer>
      )}
    </UserMentionsContainer>
  );
});

export { UserMentionList };

const UserMentionsContainer = styled.div`
  height: 100%;
`;

const UserMentionViewContainer = styled.div`
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  max-width: 100vw;
  overflow: auto;
  margin-bottom: 60px;

  @media (min-width: ${MOBILE_DEVICE}px) {
    max-width: 570px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const NoDataMessageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
