import { Globe } from "@certa/icons";
import type { CommentThreadMeta, CommentThreads } from "@certa/types";
import {
  CatalystColors,
  Ellipsis,
  Stack,
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst";
import {
  groupTagStyles,
  overflowStyles,
  globalIconStyles
} from "./CommentsRedesigned.styles";
import { MembersViewTooltip } from "./MembersViewTooltip";

type ThreadTagProps = {
  thread: CommentThreadMeta;
  threads: CommentThreads;
  isSelectedThread: boolean;
  setActiveThread: (threadId: number) => void;
};

const ThreadTag = ({
  thread,
  threads,
  isSelectedThread,
  setActiveThread
}: ThreadTagProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      setActiveThread(thread.threadId);
    }
  };

  return (
    <MembersViewTooltip
      placement={"bottom-start"}
      threadUserGroups={threads[thread.threadId]?.threadUserGroups}
    >
      <Stack
        role="button"
        aria-label={thread.threadName}
        tabIndex={0}
        direction="horizontal"
        gap="4"
        align="center"
        justify="center"
        padding="4 8"
        onClick={() => setActiveThread(thread.threadId)}
        onKeyDown={handleKeyDown}
        dangerouslySetClassName={groupTagStyles(isSelectedThread)}
      >
        {thread.isPublic ? (
          <Globe
            color={
              isSelectedThread
                ? CatalystColors.NEUTRAL_100
                : CatalystColors.NEUTRAL_700
            }
            className={globalIconStyles}
          />
        ) : undefined}
        <Typography
          width="100%"
          color={
            isSelectedThread
              ? TypographyColors.NEUTRAL_100
              : TypographyColors.NEUTRAL_700
          }
          variant={TypographyVariants.LABEL_SM_BOLD}
          className={overflowStyles}
        >
          <Ellipsis>{thread.threadName}</Ellipsis>
        </Typography>
      </Stack>
    </MembersViewTooltip>
  );
};

export { ThreadTag };
