/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import type { IconProps } from "@certa/blocks/thanos";
import { Stack, Text } from "@certa/blocks/thanos";

export const NoGroupResults: FC<{
  IconComp: React.FC<IconProps>;
  heading: string;
}> = ({ IconComp, heading }) => {
  return (
    <Stack direction="vertical" gutter="s4" style={{ width: "100%" }}>
      <Stack
        direction="vertical"
        gap="s4"
        align="center"
        justify="center"
        css={css`
          border-radius: var(--big-border-radius);
          border: 1px solid var(--neutral-20);
          background-color: var(--neutral-5);
          height: 393px;
          min-height: calc(65vh - 32px);
        `}
      >
        <IconComp size="32" color="neutral-50" />
        <Text variant="p1-regular" color="neutral-70" align="center">
          {heading}
        </Text>
      </Stack>
    </Stack>
  );
};
