import { useRef, useEffect, useCallback } from "react";
import { setParentChildDetail } from "./utils/parentChildHierarchy";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { useQuery } from "react-query";
import type { Field, HashMap } from "@certa/types";
import { optionApiUrlFormatted } from "./utils/string";
import { fetchApiUrl } from "@certa/queries";
import { Godaam, getProcessDetailRoute } from "..";
import { disconnectPrasaran } from "../../prasaran";
import { queryClient } from "@certa/queries/src/utils/utils";
import type { RootState, AppDispatch } from "main/src/js/_helpers/store";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
/**
 * @param {any} value
 * Custom hook for storing and getting previous props
 */
export const usePrevious = <T = unknown>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

/**
 * @param {any} value
 * Custom hook for storing and getting previous props with initialization
 */
export const usePreviousWithInitialization = <T = unknown>(
  value: T
): T | undefined => {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

/**
 * CAUTION: This expects that step body data to be already in the cache
 * Retrieve the options if the apiUrl exits.
 * @param {object} props
 * @param {string} props.apiUrl
 * @param {number} props.stepId
 *
 * @returns {string | null}
 */
export const useApiUrlOptions = (props: {
  apiUrl?: string;
  stepId: number;
  formValues?: HashMap;
  record: HashMap;
}) => {
  const stepData: any = queryClient.getQueryData([
    "currentStepFields",
    props.stepId
  ]);

  const fields: Field[] = stepData?.fields || [];

  const url = props?.apiUrl
    ? optionApiUrlFormatted(
        fields,
        props.apiUrl,
        props.record,
        props?.formValues
      )
    : null;

  const query = useQuery<any>(
    [`api_url`, url],
    () => fetchApiUrl(url as string),
    {
      enabled: !!url,
      retry: 0
    }
  );

  return { query };
};

export const useWorkflowNavigation = () => {
  const history = useHistory();
  const navigateToWorkflowId = (workflowId: number, isNew: boolean = false) => {
    const extra = isNew ? "?new=true" : "";
    // TODO: Backward compatibility to handle post workflow creation navigation
    // to the appropriate route
    setParentChildDetail(workflowId);
    if (window.location && /\/\d+/.test(window.location.pathname)) {
      history.push(
        window.location.pathname.replace(/\/\d+/, "/" + workflowId + extra)
      );
    } else {
      // fallback
      history.push("/workflows/instances/" + workflowId + extra);
    }
  };

  return {
    navigateToWorkflowId
  };
};

/**
 * Gets the query parameters.
 * Taken from: https://reactrouter.com/web/example/query-parameters
 */
export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

/**
 * Retrieved the answer of any given fieldTag, if it exists within the step.
 * Normal use-case would only involve loading field's answer by another sibling field
 * (i.e. that belongs in the same step).
 *
 * Maybe best suited in some form-specific package, which doesn't exist right now.
 * So parking this here.
 * @param {object} props
 * @param {string} props.fieldTag
 * @param {number} props.stepId
 *
 * @returns {string | null}
 */
export const useFieldAnswer = (props: { fieldTag: string; stepId: number }) => {
  const stepData: any = queryClient.getQueryData([
    "currentStepFields",
    props.stepId
  ]);
  const fields: Field[] = stepData.fields;
  return (
    fields.find(field => field.definition.tag === props.fieldTag)?.answer
      ?.answer || null
  );
};

export const useBrowserAuthSession = () => {
  const history = useHistory();

  const clearAuthSession = useCallback(() => {
    const preferredLanguage = Godaam.preferredLanguage;
    const recentlyViewed = Godaam.recentlyViewed;
    // TODO: Should be taken from a constant
    // Not able to import now because of circular dependency
    Godaam.clear(["taskFilters", "recentSearches"]);
    Godaam.recentlyViewed = recentlyViewed;
    Godaam.preferredLanguage = preferredLanguage;
    disconnectPrasaran();
  }, []);

  const redirectToLogin = useCallback(() => {
    const nextURL = window.location.pathname + window.location.search;
    history.replace({
      pathname: "/login",
      search: `?next=${encodeURIComponent(nextURL)}`
    });
  }, [history]);

  return { clearAuthSession, redirectToLogin };
};

// Use throughout these, instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * This hooks checks if the pathname do have some traces
 * of the OLD UI path that starts with the prefix "workflows/instances"
 *
 * then it should be redirecting the user to the new path
 * replacing the prefix with "process"
 *
 * and in case there is not process id, we are redirecting to /
 *
 */
export const useNavigateToNewUI = () => {
  const history = useHistory();
  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    const isOldUiPath = pathname.includes("workflows/instances");
    const redirectTo = isOldUiPath
      ? pathname.replace("workflows/instances", "process")
      : pathname;
    const isProcessDetailRoute = redirectTo.startsWith("/process");
    const processDetailRoute = getProcessDetailRoute();

    const processIdMatch = matchPath(redirectTo, processDetailRoute);

    // if route do not have process id, then redirect to /
    if (isProcessDetailRoute && !processIdMatch) {
      return history.replace("/");
    }

    if (isOldUiPath) {
      return history.replace({
        pathname: redirectTo,
        search,
        hash
      });
    }
  }, [hash, history, pathname, search]);
};
