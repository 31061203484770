import moment from "moment";
import type {
  AggregateModelledResponse,
  MetricDataTypes,
  ChartIdentifiers,
  YAxisOtherChartConfig,
  ChartData
} from "../types";
import { getYAxisProbableDataType } from "../constants/chartConfig";
import { INDEX_ZERO, LENGTH_ZERO, ONE, SortChange } from "../constants/common";

type ChartDataPoints = ChartData[];

// Should never be exported since
// we already have modelled response type
type Labels = [string, ...string[]];
export type AggregateResponse = [
  // This item represents all the labels
  Labels,
  // Each element in CharDataPoint
  // is a value corresponding to the labels
  // at the same index
  ...ChartDataPoints[]
];

export const aggregateModelCreator =
  (
    dataType: MetricDataTypes[],
    isStackedByLegend: boolean,
    chartType: ChartIdentifiers,
    yAxisOtherConfig: YAxisOtherChartConfig = {},
    xAxisLength: number = LENGTH_ZERO
  ) =>
  (data: AggregateResponse): AggregateModelledResponse => {
    const [labels, ...dataPoints] = data;
    const xAxisKey = labels?.[INDEX_ZERO];
    const probableDataType = getYAxisProbableDataType(
      yAxisOtherConfig,
      dataType
    );

    let chartData = [{}];

    if (chartType === "HEAT_MAP_CHART") {
      chartData = dataPoints
        .reduce(
          (validDataPoints, dataPoint) => {
            const newPoint = dataPoint.reduce(
              (chartData, item, index) => {
                const isFormatAndNullConvertAllowed = index < xAxisLength;
                return {
                  ...chartData,
                  [labels[index]]: parseChartData(
                    item,
                    isFormatAndNullConvertAllowed,
                    isFormatAndNullConvertAllowed
                  )
                };
              },
              {} as Record<string, ChartData>
            );
            validDataPoints.push(newPoint);
            return validDataPoints;
          },
          [] as Record<string, ChartData>[]
        )
        .sort(sortNullInEnd);
    } else if (isStackedByLegend) {
      const X_AXIS_KEY_INDEX = 0;
      const VALUE_KEY_INDEX = 1;
      const VALUE_INDEX = 2;
      const transformedChartData = dataPoints.reduce(
        (dataPoint, item, index) => {
          const valueKey = item[VALUE_KEY_INDEX] as string;
          const existingXAxisData = dataPoint.find(
            (data: any) => data[xAxisKey] === item[X_AXIS_KEY_INDEX]
          );
          if (existingXAxisData) {
            Object.assign(existingXAxisData, {
              [xAxisKey]: parseChartData(item[X_AXIS_KEY_INDEX], true, true),
              [valueKey]: parseChartData(item[VALUE_INDEX])
            });
            return dataPoint;
          } else {
            return [
              ...dataPoint,
              {
                [xAxisKey]: parseChartData(item[X_AXIS_KEY_INDEX], true, true),
                [valueKey]: parseChartData(item[VALUE_INDEX])
              }
            ];
          }
        },
        [] as Record<string, ChartData>[]
      );
      chartData = transformedChartData;
    } else if (Array.isArray(dataPoints?.[INDEX_ZERO])) {
      // This condition executes for normal report charts
      const sortFunction = customSort(xAxisLength);
      const sortedDataPoints = xAxisLength
        ? dataPoints.sort(sortFunction)
        : dataPoints;
      chartData = sortedDataPoints.reduce(
        (validDataPoints, dataPoint) => {
          const newPoint = dataPoint.reduce(
            (chartData, item, index) => {
              //convert null to string, for only X-axis data points
              const isFormatAndNullConvertAllowed = index < xAxisLength;
              return {
                ...chartData,
                // Date has to be formatted to (YYYY-MM-DD) only for X-axis (i.e., index 0)
                // to pass correct filter values on clicking report charts.
                // Formatting Y-axis will corrupt library's date interpolation
                // hence keeping it as it is
                [labels[index]]: parseChartData(
                  item,
                  isFormatAndNullConvertAllowed,
                  isFormatAndNullConvertAllowed
                )
              };
            },
            {} as Record<string, ChartData>
          );
          return [...validDataPoints, newPoint];
        },
        [] as Record<string, ChartData>[]
      );
    } else {
      // This condition executes for table column aggregation - Min, Max, Sum, Avg
      chartData = data;
    }

    return {
      xAxisKey,
      probableDataType,
      data: chartData
    };
  };

const DATE_FORMAT = "YYYY-MM-DD";
const parseChartData = (
  data: ChartData,
  format = false,
  convertNull = false
) => {
  if (Number.isNaN(Number(data)) && moment(data, true).isValid()) {
    if (format) {
      if (data?.toString?.length === DATE_FORMAT.length) {
        return moment(data)?.format(DATE_FORMAT);
      }
    } else {
      return +moment(data);
    }
  } else if (data === null && convertNull) return "null";
  return data;
};

const calculateNulls = (data: Record<string, ChartData>) => {
  return Object.values(data).filter(item => item === null).length;
};
const sortNullInEnd = (
  item1: Record<string, ChartData>,
  item2: Record<string, ChartData>
) => {
  const item1Nulls = calculateNulls(item1);
  const item2Nulls = calculateNulls(item2);

  if (item1Nulls === item2Nulls) {
    return SortChange.NO_CHANGE;
  }

  if (item1Nulls > item2Nulls) {
    return SortChange.ASCENDING;
  }

  if (item1Nulls < item2Nulls) {
    return SortChange.DESCENDING;
  }

  return SortChange.NO_CHANGE;
};

const customSort =
  (sortUpto: number) => (row1: ChartDataPoints, row2: ChartDataPoints) => {
    for (let i = sortUpto - ONE; i >= INDEX_ZERO; i--) {
      const value1 = row1[i];
      const value2 = row2[i];
      if (value1 === value2) {
        continue;
      } else if (value1 === null) {
        return SortChange.ASCENDING; // Move null values to the end
      } else if (value2 === null) {
        return SortChange.DESCENDING; // Move null values to the end
      } else {
        return value1 < value2 ? SortChange.DESCENDING : SortChange.ASCENDING;
      }
    }

    return SortChange.NO_CHANGE; // Rows are equal
  };
