import type PptxGenJS from "pptxgenjs";
import type { ImageMetaData } from "./pngToPdf";
import { getScaledWidthAndHeight, pdfTitleConfig } from "./pngToPdf";

const slideWidth = 10; // for pptx.layout = "LAYOUT_16x9", slide width = 10 inches
const slideHeight = 5.625; // for pptx.layout = "LAYOUT_16x9", slide height = 5.625 inches
const titleHeight = 0.4;
const pieChartDeviation = 0.2; // to fix pie chart's slightly oval shape.
const defaultImageX = 0;

const sliderNumberConfig: PptxGenJS.SlideNumberProps = {
  x: "95%",
  y: "95%",
  fontSize: 12
};

const masterSlideConfig: PptxGenJS.SlideMasterProps = {
  title: "PLACEHOLDER_SLIDE",
  background: { color: "FFFFFF" },
  objects: [
    {
      rect: {
        x: 0,
        y: 0,
        w: "100%",
        h: titleHeight,
        fill: { color: "F1F1F1" }
      }
    }
  ],
  slideNumber: sliderNumberConfig
};

/**
 *
 * @param pngData array of Data URL for a png image.
 * @param metaData array of metadata for image - width, height and name
 * @param fileName string file name
 * downloads the generated PPT doc
 */

export const convertAndDownloadPptFromPng = async (
  pngDataUrls: string[],
  metaDataArray: ImageMetaData[] | undefined = [{}],
  fileName?: string
) => {
  // initialize PPT
  const { default: pptxgen } = await import("pptxgenjs");
  const pptx = new pptxgen();
  pptx.layout = "LAYOUT_16x9";

  pptx.defineSlideMaster(masterSlideConfig);

  pngDataUrls.forEach((pngDataUrl, index) => {
    const metaData = metaDataArray?.[index] ?? {};
    // 2. Add a Slide
    const slide = pptx.addSlide({
      masterName: "PLACEHOLDER_SLIDE"
    });
    // 2.1 Add a title
    slide.addText(metaData?.name ?? "", {
      h: titleHeight,
      color: pdfTitleConfig.color,
      fontSize: pdfTitleConfig.fontSize,
      bold: pdfTitleConfig.bold
    });
    const { w, h, y, x } = getScaledWidthAndHeight(
      metaData,
      slideWidth,
      slideHeight - 2 * titleHeight,
      defaultImageX,
      titleHeight
    );
    // 2.2 Add an Image
    slide.addImage({
      data: pngDataUrl,
      x,
      y,
      w,
      h: h + pieChartDeviation
    });
  });
  // 3. Save the Presentation
  pptx.writeFile({ fileName });
};
