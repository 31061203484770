import { css } from "emotion";

import { useOverlay } from "@certa/blocks";
import { Gear } from "@certa/icons";
import { PermissionTypes } from "@certa/types";
import { intl } from "main/src/modules/common/components/IntlCapture";

import { WrapperWithIcon } from "../common";
import { useCheckPermission } from "../../../../../utils/permissions/Chowkidar";
import { Stack, Text } from "@certa/blocks/thanos";
import { DropdownMenuItem } from "@certa/catalyst";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
const INSTANCE_SETTINGS_MODAL_WIDTH = "1280px";

export const InstanceSettings = () => {
  const canAccessInstanceSettings = useCheckPermission(
    PermissionTypes.CAN_ACCESS_ADMIN_SETTINGS
  );

  const [showInstanceSettings] = useOverlay(
    "instanceSettings-global",
    () => {
      return (
        <iframe
          src="/settings"
          name="instanceSettings"
          title="Instance settings"
          className={css`
            border: none;
            width: 100%;
            height: 75vh;
          `}
        />
      );
    },
    {
      width: `min(90%, ${INSTANCE_SETTINGS_MODAL_WIDTH})`,
      hideFooter: true,
      title: (
        <Stack align="center" gap="s2">
          <Gear />
          <Text variant="h1-bold">Instance settings</Text>
        </Stack>
      ),

      className: css`
        .ant-modal-header {
          margin: var(--s1) var(--s00) var(--s5) var(--s00) !important;
        }
      `,
      footer: null,
      centered: true,
      destroyOnClose: true
    }
  );

  const onInstanceSettingsClick = () => {
    MixPanelActions.track(
      MixPanelEvents.studioInstanceSettingsEvents
        .STUDIO_INSTANCE_SETTINGS_HEADER_USER_PROFILE_CLICK
    );
    showInstanceSettings();
  };

  return canAccessInstanceSettings ? (
    <DropdownMenuItem
      key="instanceSettingsModal"
      value={intl.formatMessage({
        id: "loginPageInstances.InstanceSettingsText"
      })}
      onSelect={onInstanceSettingsClick}
    >
      <WrapperWithIcon icon={<Gear />}>
        {intl.formatMessage({
          id: "loginPageInstances.InstanceSettingsText"
        })}
      </WrapperWithIcon>
    </DropdownMenuItem>
  ) : null;
};
