import { useMemo } from "react";
import { Table } from "antd";
import { css, cx } from "emotion";
import styled from "@emotion/styled";
import type { ColumnsType } from "antd/es/table";
import { Cell } from "./Cell";
import { SUB_TOTAL_LABEL, AVERAGE_LABEL } from "../constants";
import type { HeatMapProps, DataPlusUndefined } from "../types";
import { Summary } from "./Summary";
import { getAxesLebels, getTranslatedData } from "../utils/HeatMapUtils";
import { Heading } from "./Heading";
import { ColumnTitle } from "./ColumnTitle";
import { INDEX_ZERO, ONE } from "@certa/common";
import { useDataColumns } from "../hooks/useGetDataColumns";

const DEFAULT_HEIGHT = 392;
const FIRST_COLUMN_WIDTH = 200;

export const HeatMap = (props: HeatMapProps) => {
  const {
    data,
    xAxisKeys,
    yAxisKeys,
    valueKey,
    dataCellFormatter,
    cellBaseColors,
    shouldShowColumnTypeName = false,
    shouldShowRowTypeName = false,
    columnTypeHeading = "",
    rowTypeHeading = "",
    height = DEFAULT_HEIGHT,
    activeColumnIdentifier,
    onClick
  } = props;

  const multipleXAxisLabels = useMemo(
    () => getAxesLebels(xAxisKeys, data),
    [data, xAxisKeys]
  );

  const yAxesLabels = useMemo(
    () => getAxesLebels(yAxisKeys, data),
    [data, yAxisKeys]
  );

  const {
    dataSource,
    minValue = 0,
    maxValue = 0,
    columnSubTotalAndAvg
  } = useMemo(
    () =>
      getTranslatedData({
        data,
        xAxisKeys,
        yAxisKeys,
        multipleXAxisLabels,
        yAxesLabels,
        valueKey
      }),
    [data, valueKey, multipleXAxisLabels, xAxisKeys, yAxesLabels, yAxisKeys]
  );

  const minMaxDiff = maxValue - minValue === 0 ? 1 : maxValue - minValue;

  const dataColumns: ColumnsType<DataPlusUndefined> = useDataColumns(
    data,
    xAxisKeys,
    yAxisKeys,
    multipleXAxisLabels,
    dataCellFormatter,
    minValue,
    minMaxDiff,
    cellBaseColors,
    onClick,
    activeColumnIdentifier
  );

  const yDataColumns = useMemo(() => {
    return {
      title: "Y-axis",
      colSpan: yAxisKeys.length,
      children: yAxisKeys.reverse().map(yAxis => ({
        title: () => <ColumnTitle title={yAxis} />,
        dataIndex: yAxis,
        width: FIRST_COLUMN_WIDTH,
        ellipsis: true,
        render: (cellValue: number, rowRecord: any, rowIndex: number) => {
          const shouldHideValue =
            yAxis !== yAxisKeys[yAxisKeys.length - ONE] &&
            dataSource?.[rowIndex - ONE]?.[yAxis] === cellValue;
          return (
            <ColumnTitle
              title={!shouldHideValue ? `${cellValue}` : ""}
              width={FIRST_COLUMN_WIDTH}
            />
          );
        }
      }))
    };
  }, [yAxisKeys, dataSource]);

  const columns: ColumnsType<DataPlusUndefined> = useMemo(
    () => [
      yDataColumns,
      ...dataColumns,
      {
        title: SUB_TOTAL_LABEL,
        dataIndex: SUB_TOTAL_LABEL,
        width: 100,
        render: (cellValue, rowRecord, rowIndex) => (
          <Cell
            cellBaseColors={cellBaseColors}
            dataCellFormatter={dataCellFormatter}
            cellValue={cellValue}
            columnHeaderKey={SUB_TOTAL_LABEL}
            rowRecord={rowRecord}
            rowIndex={rowIndex}
            ratio={(cellValue - minValue) / minMaxDiff}
          />
        )
      },
      {
        title: AVERAGE_LABEL,
        dataIndex: AVERAGE_LABEL,
        width: 100,
        render: (cellValue, rowRecord, rowIndex) => (
          <Cell
            dataCellFormatter={dataCellFormatter}
            cellValue={cellValue}
            columnHeaderKey={AVERAGE_LABEL}
            rowRecord={rowRecord}
            rowIndex={rowIndex}
            ratio={(cellValue - minValue) / minMaxDiff}
          />
        )
      }
    ],
    [
      cellBaseColors,
      dataCellFormatter,
      dataColumns,
      minMaxDiff,
      minValue,
      yDataColumns
    ]
  );
  return (
    <Col>
      <Heading
        show={shouldShowColumnTypeName}
        title={columnTypeHeading}
        titleFor="column"
      />
      <Row>
        <RowLabel>
          <Heading
            show={shouldShowRowTypeName}
            title={rowTypeHeading}
            titleFor="row"
          />
        </RowLabel>
        <Table
          rowKey={yAxisKeys[INDEX_ZERO]}
          // Antd table considers the thead and tbody as separate table and puts
          // each of them in a separate table tag when the scroll prop is passed.
          // This causes issues in accessibility as the screen reader reads the thead
          // and tbody as separate tables. To fix this we are not passing the scroll
          // props to the table and instead we are setting the max height of the table
          // content to the scroll height passed in the scroll props.
          className={cx(
            tableStyle(yAxisKeys.length),
            css`
              & .ant-table-content {
                max-height: ${height}px;
                overflow-y: auto !important;
              }
            `
          )}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: "max-content" }}
          pagination={false}
          bordered
          summary={() => (
            <Summary
              cellBaseColors={cellBaseColors}
              lastRowIndex={yAxesLabels[INDEX_ZERO]?.length}
              minMaxDiff={minMaxDiff}
              minValue={minValue}
              yAxisKeys={yAxisKeys}
              dataCellFormatter={dataCellFormatter}
              columnSubTotalAndAvg={columnSubTotalAndAvg}
              dataColumns={dataColumns}
            />
          )}
        />
      </Row>
    </Col>
  );
};

const tableStyle = (yAxisRows: number) => css`
   {
    width: calc(100% - 32px);
    & .ant-table-container {
      border: 1px solid #eaeaea;
      border-radius: 8px;
      background: #f9f9f9;
    }
    & .ant-table-thead .ant-table-cell {
      text-align: center;
    }
    & td:nth-child(-n + ${yAxisRows})  {
      text-align: center !important;
    }
    & .ant-table-cell {
      padding: 4px !important;
    }
    & td.ant-table-cell:nth-child(-n + ${yAxisRows})  {
      background: #f9f9f9;
      text-align: center;
    }
    & td.ant-table-cell {
      background: #fff;
      text-align: right;
    }
    & td.ant-table-cell.summary {
      text-align: right !important;
    }
    & .heat-map-cell-div {
      padding: 4px;
      border-radius: 4px;
    }
  }
  tr:not(.ant-table-measure-row) {
    th {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
`;

const Col = styled.div`
 {
  display: flex;
  flex-direction: column;
  align-items: center;
  > * + * {
    margin-top: 16px;
}
`;

const Row = styled.div`
 {
  width: 100%;
  display: flex;
  align-items: center;
  > * + * {
    margin-inline-start: 16px;
}
`;

const RowLabel = styled.div`
  writing-mode: vertical-lr;
  rotate: 180deg;
`;
