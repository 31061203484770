import { FieldTypes } from "@certa/types";

export const FETCH_STEP_ATTR = "##fetch_step_attr##";
export const FETCH_STEPGROUP_ATTR = "##fetch_stepgroup_attr##";
export const FETCH_PROCESS_ATTR = "##fetch_process_attr##";

export const numberFilterTypes = [
  FieldTypes.INTEGER,
  FieldTypes.DECIMAL,
  FieldTypes.CURRENCY,
  FieldTypes.SLIDER
];
export const listFilterTypes = [
  FieldTypes.SELECT,
  FieldTypes.MULTI_SELECT,
  FieldTypes.BOOL,
  FieldTypes.CASCADER,
  FieldTypes.CHECKBOX,
  FieldTypes.GROUPED_CHECKBOX,
  FieldTypes.RADIO,
  FieldTypes.REGION,
  FieldTypes.BUSINESS_UNIT
];

export const dateFilterTypes = [FieldTypes.DATE, FieldTypes.NAIVE_DATE];
export const MY_SUBSCRIPTIONS_MODAL_ID = "mySubscriptionsModal";
