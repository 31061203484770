import { TOGGLE_LEFT_SIDEBAR } from "../constants";

export function showLeftSidebar(state = false, action) {
  const { type } = action;
  switch (type) {
    case TOGGLE_LEFT_SIDEBAR:
      return !state;
    default:
      return state;
  }
}
