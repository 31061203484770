import type { FC } from "react";

export const ListGroupIllustration: FC = () => {
  return (
    <svg
      width="217"
      height="145"
      viewBox="0 0 217 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1370_30099)">
        <rect
          x="22.5635"
          y="31"
          width="150.861"
          height="46.5102"
          rx="5"
          fill="#EAEAEA"
        />
      </g>
      <rect
        x="62.3691"
        y="56"
        width="43.6893"
        height="7"
        rx="3.5"
        fill="#DBDBDB"
      />
      <rect
        opacity="0.37"
        x="62.3691"
        y="46"
        width="34.9515"
        height="6"
        rx="3"
        fill="#8F8F91"
      />
      <rect
        opacity="0.37"
        x="101.204"
        y="46"
        width="16.5049"
        height="6"
        rx="3"
        fill="#8F8F91"
      />
      <ellipse cx="41.4951" cy="54.5" rx="11.165" ry="11.5" fill="#DBDBDB" />
      <path
        d="M41.1593 58.554C41.3728 59.1688 42.3209 59.1407 42.491 58.5149L44.0319 52.8626L44.9323 55.2282C45.0309 55.4854 45.2931 55.6572 45.5868 55.6572H48.0833C48.4658 55.6572 48.7768 55.3689 48.7768 55.0143C48.7768 54.6597 48.4658 54.3714 48.0833 54.3714H46.0776L44.5769 50.4287C44.3483 49.8291 43.4186 49.8682 43.2506 50.485L41.738 56.0297L40.4214 52.2457C40.2221 51.6721 39.3585 51.645 39.1179 52.2035L38.1828 54.3713H35.8781C35.4956 54.3713 35.1846 54.6596 35.1846 55.0141C35.1846 55.3687 35.4956 55.657 35.8781 55.657H38.652C38.9359 55.657 39.1905 55.4973 39.2956 55.2532L39.6911 54.3361L41.1593 58.554Z"
        fill="#C8C8C9"
      />
      <g filter="url(#filter1_d_1370_30099)">
        <rect
          x="45.8643"
          y="68.4922"
          width="150.861"
          height="46.5102"
          rx="5"
          fill="white"
        />
      </g>
      <rect
        x="90.0488"
        y="93.8596"
        width="30.7879"
        height="6.3423"
        rx="3.17115"
        fill="#F6F6F6"
      />
      <rect
        x="90.0488"
        y="83.2891"
        width="66.707"
        height="6.3423"
        rx="3.17115"
        fill="#EAEAEA"
      />
      <ellipse cx="68.6797" cy="91.5" rx="11.165" ry="11.5" fill="#EAEAEA" />
      <path
        d="M68.3439 95.554C68.5574 96.1688 69.5055 96.1407 69.6756 95.5149L71.2164 89.8626L72.1169 92.2282C72.2155 92.4854 72.4777 92.6572 72.7714 92.6572H75.2679C75.6504 92.6572 75.9614 92.3689 75.9614 92.0143C75.9614 91.6597 75.6504 91.3714 75.2679 91.3714H73.2622L71.7615 87.4287C71.5328 86.8291 70.6031 86.8682 70.4352 87.485L68.9225 93.0297L67.606 89.2457C67.4066 88.6721 66.543 88.645 66.3025 89.2035L65.3674 91.3713H63.0626C62.6801 91.3713 62.3691 91.6596 62.3691 92.0141C62.3691 92.3687 62.6801 92.657 63.0626 92.657H65.8365C66.1204 92.657 66.3751 92.4973 66.4802 92.2532L66.8757 91.3361L68.3439 95.554Z"
        fill="#C8C8C9"
      />
      <ellipse cx="211.883" cy="82" rx="3.8835" ry="4" fill="#C8C8C9" />
      <ellipse cx="4.11689" cy="50" rx="3.8835" ry="4" fill="#C8C8C9" />
      <path
        d="M62.3406 15C62.3406 17.2372 60.5873 19 58.4862 19C56.3852 19 54.6318 17.2372 54.6318 15C54.6318 12.7628 56.3852 11 58.4862 11C60.5873 11 62.3406 12.7628 62.3406 15Z"
        stroke="#C8C8C9"
        strokeWidth="2"
      />
      <path
        d="M185.641 5C185.641 7.23721 183.888 9 181.787 9C179.686 9 177.933 7.23721 177.933 5C177.933 2.76279 179.686 1 181.787 1C183.888 1 185.641 2.76279 185.641 5Z"
        stroke="#C8C8C9"
        stroke-width="2"
      />
      <path
        d="M123.048 8.125C122.631 8.125 122.292 8.47386 122.292 8.90385V10.7212H120.528C120.11 10.7212 119.771 11.07 119.771 11.5C119.771 11.93 120.11 12.2788 120.528 12.2788H122.292V14.0962C122.292 14.5261 122.631 14.875 123.048 14.875C123.466 14.875 123.804 14.5261 123.804 14.0962V12.2788H125.569C125.986 12.2788 126.325 11.93 126.325 11.5C126.325 11.07 125.986 10.7212 125.569 10.7212H123.804V8.90385C123.804 8.47386 123.466 8.125 123.048 8.125Z"
        fill="#C8C8C9"
      />
      <path
        d="M187.612 39.75C187.334 39.75 187.108 39.9826 187.108 40.2692V41.4808H185.932C185.654 41.4808 185.428 41.7133 185.428 42C185.428 42.2867 185.654 42.5192 185.932 42.5192H187.108V43.7308C187.108 44.0174 187.334 44.25 187.612 44.25C187.891 44.25 188.116 44.0174 188.116 43.7308V42.5192H189.293C189.571 42.5192 189.797 42.2867 189.797 42C189.797 41.7133 189.571 41.4808 189.293 41.4808H188.116V40.2692C188.116 39.9826 187.891 39.75 187.612 39.75Z"
        fill="#C8C8C9"
      />
      <path
        d="M28.3895 94.75C28.1112 94.75 27.8854 94.9826 27.8854 95.2692V96.4808H26.7092C26.4309 96.4808 26.2051 96.7133 26.2051 97C26.2051 97.2867 26.4309 97.5192 26.7092 97.5192H27.8854V98.7308C27.8854 99.0174 28.1112 99.25 28.3895 99.25C28.6679 99.25 28.8937 99.0174 28.8937 98.7308V97.5192H30.0699C30.3482 97.5192 30.574 97.2867 30.574 97C30.574 96.7133 30.3482 96.4808 30.0699 96.4808H28.8937V95.2692C28.8937 94.9826 28.6679 94.75 28.3895 94.75Z"
        fill="#C8C8C9"
      />
      <defs>
        <filter
          id="filter0_d_1370_30099"
          x="12.5635"
          y="23"
          width="170.86"
          height="66.5102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1370_30099"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1370_30099"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1370_30099"
          x="25.8643"
          y="58.4922"
          width="190.86"
          height="86.5102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1370_30099"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1370_30099"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
