import {
  useQuery,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryResult
} from "react-query";
import { type GetAllKindsArgs, getAllKinds } from "../services/kinds.services";
import { useCheckPermission, type UseQueryReturnType } from "@certa/common";
import { type Kind, PermissionTypes } from "@certa/types";

export const useKinds = (
  params?: GetAllKindsArgs,
  config?: UseQueryOptions<UseQueryReturnType<typeof getAllKinds>>
): UseQueryResult<Kind[], unknown> => {
  return useQuery({
    // 5 hrs
    staleTime: 1000 * 60 * 60 * 5,
    ...config,
    queryKey: ["kinds", params] as QueryKey,
    queryFn: () => getAllKinds(params)
  });
};

export const useKindId = (kindTag: string) => {
  const { data: kinds } = useKinds();
  return kinds?.find(kind => kind.tag === kindTag)?.id;
};

export const useKind = (kindTag: string) => {
  const { data: kinds } = useKinds();
  return kinds?.find(kind => kind.tag === kindTag);
};

export const useKindFromId = (kindId: number) => {
  const { data: kinds } = useKinds();
  return kinds?.find(kind => kind.id === kindId);
};

export const useCreatableWorkflowKind = (
  kindTag: string,
  disableIsRelatedKindCheck?: boolean
) => {
  const { data: kinds } = useKinds();
  const canCreateWorkflows = useCheckPermission(
    PermissionTypes.CAN_ADD_WORKFLOW
  );
  return canCreateWorkflows
    ? kinds?.find(
        kind =>
          kindTag === kind.tag &&
          (disableIsRelatedKindCheck || kind.isRelatedKind) &&
          kind.features.includes("add_workflow")
      )
    : undefined;
};
