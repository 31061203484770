import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";
import { getIntlBody } from "main/src/js/_helpers/intl-helpers";

type HomePageConfig = {
  greetingMessage: string | null;
  processShortcuts: Record<string, string[]>;
  shortcutColors: Record<string, string>;
  myProcessReport: { dashboard_name?: string; report_name?: string };
  createNewMessage: string | null;
};

// Shows the homepage by default to all users
export const useHomePage = (): boolean => {
  return true;
};

export const useHomePageConfig = (): HomePageConfig => {
  const homepageConfig = useSelector((state: ReduxState) =>
    lodashGet(state, "config.custom_ui_labels.c2_home_page_config", null)
  );

  return {
    greetingMessage:
      (homepageConfig && getIntlBody(homepageConfig, "greeting_message")) ||
      null,
    processShortcuts: lodashGet(homepageConfig, "process_shortcuts", {}),
    shortcutColors: lodashGet(homepageConfig, "process_shortcut_colors", {}),
    myProcessReport: lodashGet(homepageConfig, "my_process_report", {}),
    createNewMessage:
      (homepageConfig && getIntlBody(homepageConfig, "create_new_message")) ||
      null
  };
};
