import { useMemo } from "react";
import { useCheckPermission } from "../..";
import { PermissionTypes } from "@certa/types";
import { CERTA_SUPPORT } from "../constants";
import type { Kind } from "@certa/types";
import { useKinds } from "@certa/queries";
import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";
import { useFilterHeaderOptionBasedOnUserGroup } from "./useFilterHeaderOptionBasedOnUserGroup";

export const useCanSeeCreateSupportButton = () => {
  const { data: kinds } = useKinds();

  const canCreateSupportTicketConfig = useSelector((state: ReduxState) =>
    lodashGet(state, "config.custom_ui_labels.create_support_ticket", {})
  );

  const hasAddPermissionForCreateSupportKind = useMemo(
    () =>
      kinds?.find(
        (kind: Kind) =>
          kind.tag === CERTA_SUPPORT && kind.features.includes("add_workflow")
      ),
    [kinds]
  );
  const hasAddWorkflowPermission = useCheckPermission(
    PermissionTypes.CAN_ADD_WORKFLOW
  );

  const createSupportTicketOption = useFilterHeaderOptionBasedOnUserGroup([
    canCreateSupportTicketConfig
  ]);
  const supportTicketData =
    hasAddPermissionForCreateSupportKind &&
    hasAddWorkflowPermission &&
    createSupportTicketOption.length
      ? createSupportTicketOption[0]
      : null;

  return { supportTicketData };
};
