import { useInfiniteQuery } from "react-query";
import { userImpersonation } from "main/src/js/services/impersonateUser";
import type { ImpersonateeUserKindType } from "../components/Header/types";
import type { UseInfiniteQueryExtraConfigType } from "../types";

export const DEFAULT_PAGE_LIMIT = 20;

const useGetUsersToImpersonate = ({
  params,
  config
}: {
  params: {
    kind: ImpersonateeUserKindType;
    search: string;
  };
  config?: UseInfiniteQueryExtraConfigType;
}) => {
  const { kind, search } = params;

  return useInfiniteQuery(
    ["impersonate-user-list", params],
    ({ pageParam = { offset: 0, limit: DEFAULT_PAGE_LIMIT } }) =>
      userImpersonation.getImpersonateeList({
        ...pageParam,
        kind,
        search
      }),
    {
      getNextPageParam: lastPage => {
        const { count: totalCount, limit, offset } = lastPage;
        const nextPageOffset = offset + limit;
        if (nextPageOffset < totalCount) {
          return { limit, offset: nextPageOffset };
        }
        return false;
      },

      ...config
    }
  );
};

export { useGetUsersToImpersonate };
