import {
  Stack,
  TypographyColors,
  TypographyVariants,
  Typography,
  Popover
} from "@certa/catalyst";
import type { PopoverPlacements } from "@certa/catalyst";
import { useIntl } from "react-intl";
import type { ChannelUserGroup } from "@certa/types";
import { Fragment } from "react";
import { memberTagStyles } from "./CommentsRedesigned.styles";
import { css } from "emotion";

type MembersViewProps = {
  threadUserGroups: ChannelUserGroup[];
  placement?: PopoverPlacements;
  children: React.ReactElement;
};

const MembersViewTooltip = ({
  threadUserGroups,
  placement,
  children
}: MembersViewProps) => {
  const intl = useIntl();
  const membersText = intl.formatMessage({
    id: "comments.members",
    defaultMessage: "Members"
  });
  const noMembers = intl.formatMessage({
    id: "comments.noMembers",
    defaultMessage: "No Members"
  });

  return (
    <Popover
      trigger="hover"
      placement={placement}
      ariaDescription=""
      content={
        <Stack padding="24 20" gap="8" width="16rem">
          {threadUserGroups?.length > 0 ? (
            <Fragment>
              <Stack justify="start" align="start">
                <Typography
                  variant={TypographyVariants.OVERLINE_SMALL}
                  color={TypographyColors.NEUTRAL_700}
                >
                  {membersText}
                </Typography>
              </Stack>
              <Stack
                direction="horizontal"
                gap="4"
                align="start"
                justify="start"
                dangerouslySetClassName={css({
                  flexWrap: "wrap",
                  maxHeight: "25rem",
                  overflowY: "auto"
                })}
              >
                {threadUserGroups.map(member => {
                  return (
                    <div key={member.id} className={memberTagStyles}>
                      <Typography
                        width="100%"
                        variant={TypographyVariants.LABEL_SM}
                        color={TypographyColors.NEUTRAL_700}
                      >
                        {member.name}
                      </Typography>
                    </div>
                  );
                })}
              </Stack>
            </Fragment>
          ) : (
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_700}
            >
              {noMembers}
            </Typography>
          )}
        </Stack>
      }
    >
      {children}
    </Popover>
  );
};

export { MembersViewTooltip };
