import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst";
import { SearchHighlighter } from "../SearchHighlighter";
import { breadcrumbItem } from "./Breadcrumbs.styles";
import type { RefObject, CSSProperties } from "react";

export const BreadcrumbItem = ({
  text,
  highlights,
  style,
  elementRef
}: {
  text: string;
  highlights?: string[];
  style?: CSSProperties;
  elementRef?: RefObject<HTMLElement>;
}) => {
  return (
    <Typography
      className={breadcrumbItem}
      ref={elementRef}
      style={style}
      variant={TypographyVariants.BODY_BOLD}
      color={TypographyColors.NEUTRAL_600}
    >
      {highlights ? (
        <SearchHighlighter textToHighlight={text} highlights={highlights} />
      ) : (
        text
      )}
    </Typography>
  );
};
