/**
 * According to latest guidelines as of 14/04/2020 we are making alerts use Tags
 * and not a custom element.
 *
 * AlertTag component is a general purpose alert who's responsibilit is to JUST
 * VIEW the alert text as it is. It accepts all the props that ANTD Tag supports
 * with additiona `alert` prop of type IAlert, which helps in navigating to the
 * specific alert when this is clicked upon.
 */

import type { MouseEventHandler } from "react";
import React, { useCallback } from "react";
import { Tag } from "antd";
import type { TagProps } from "antd/lib/tag";
import type { Alert } from "@certa/types";
import { useHistory } from "react-router-dom";

/**
 * @deprecated
 */
export const AlertTag = React.memo(
  ({
    alert,
    ...otherProps
  }: {
    alert: Partial<Alert>;
  } & TagProps) => {
    const history = useHistory();

    const onAlertClick: MouseEventHandler = useCallback(
      e => {
        e.preventDefault();
        e.stopPropagation();
        if (alert.workflowId && alert.stepGroupId && alert.stepId) {
          history.push(
            `/process/${alert.workflowId}?group=${alert.stepGroupId}&step=${alert.stepId}`
          );
        }
      },
      [alert.stepGroupId, alert.stepId, alert.workflowId, history]
    );

    return (
      <Tag {...otherProps} color={alert.catColorLabel} onClick={onAlertClick}>
        {alert.alertTag}
        {alert.value !== undefined && alert.value !== null
          ? `: ${alert.value}`
          : null}
      </Tag>
    );
  }
);
