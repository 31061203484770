import React from "react";
import { ClassNames } from "@emotion/core";
import type { FC } from "react";
import { Tooltip as AntTooltip } from "antd";
import type { TooltipProps as AntTooltipProps } from "antd/lib/tooltip";
import { Text } from "../componentsTh/Typography/Text";
import { getLayoutContainer } from "../utils";

export type TooltipProps = AntTooltipProps & {
  isBody?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement | null;
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * // For simple text
 * import { Tooltip } from '@certa/catalyst';
 * // For custom content, or for click trigger
 * import { Popover } from '@certa/catalyst';
 */
// TODO: [VET-23362] Make this generic with intl support or modify the IntlTooltip
const Tooltip: FC<TooltipProps> = props => {
  const { overlayClassName, isBody, getPopupContainer, ...otherProps } = props;

  if (otherProps.overlay && isBody) {
    otherProps.overlay = (
      <Text
        as="span"
        variant="p1-regular"
        dangerouslySetInnerHTML={{
          __html: otherProps.overlay as string
        }}
      />
    );
  }

  return (
    <ClassNames>
      {({ css, cx }) => (
        <AntTooltip
          overlayClassName={cx(
            overlayClassName,
            css`
              .ant-tooltip-arrow:before {
                background: var(--neutral-100);
              }
              .ant-tooltip-inner {
                border-radius: var(--small-border-radius);
                background: var(--neutral-100);
                color: var(--neutral-0);
                font-size: var(--p3);
                line-height: var(--p3-line-height);
                font-weight: var(--medium);
                padding: var(--s1) var(--s2);
                min-height: 28px;
                display: inline-flex;
                align-items: center;
              }
            `
          )}
          getPopupContainer={triggerNode =>
            getPopupContainer?.(triggerNode) || getLayoutContainer()
          }
          {...otherProps}
        />
      )}
    </ClassNames>
  );
};

export { Tooltip };
