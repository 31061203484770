import { setUserTimezoneService } from "../services/userTimezone.services";
import { useMutation } from "react-query";
import { useGodaamSyncState } from "@certa/common/src/hooks/useGodaamSyncState";

const USER_TIMEZONE = "timezone_set_in_user_session";

const useSetTimezoneInSession = () => {
  const [isTimezoneInSession, setIsTimezoneInSession] =
    useGodaamSyncState<boolean>(USER_TIMEZONE, false);

  const { mutateAsync: setTimezoneInUserSession } = useMutation(
    setUserTimezoneService.handleUpdateUserTimezone,
    {
      onSuccess: () => setIsTimezoneInSession(true),
      onError: () => setIsTimezoneInSession(false)
    }
  );

  return {
    isTimezoneInSession,
    setIsTimezoneInSession,
    setTimezoneInUserSession
  };
};

export { useSetTimezoneInSession };
