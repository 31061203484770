import { serverlessAPIFetch, tenant } from "@certa/network";
import { activityLogModelCreator } from "../models/activityLog.model";
import type { ActivityLog } from "../types/activityLog.types";
import { serverlessAPIFetch as serverlessAPIFetchDownload } from "../../../main/src/js/utils/request";

export const getActivityLogs = (
  params: { id: number | undefined; actions: any[] },
  skip = 0
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-tenant": tenant
    },
    credentials: "include"
  };
  const url = `workflows/activity/?workflows=${
    params.id
  }&actions=${params.actions.join(",")}&skip=${skip}`;
  return serverlessAPIFetch<ActivityLog>(url, requestOptions).then(
    activityLogModelCreator
  );
};

export const downloadActivityLogs = (params: {
  id: number | undefined;
  startDate: string;
}) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-tenant": tenant
    },
    credentials: "include",
    mode: "cors"
  };
  const url = `workflows/activity/export?workflows=${params.id}&start_date=${params.startDate}`;
  return serverlessAPIFetchDownload(url, requestOptions, "AUDIT_LOG"); // todo - find why it doesn't give response with new fetchWrapper fn
};
