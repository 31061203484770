import { cloneDeep } from "lodash-es";
import type {
  AttributesPayloadIC,
  ChartConfigIC,
  ColumnIC,
  FilterConditionIC,
  FilterIC,
  FilterPayloadIC,
  GroupByIC,
  GroupByPayloadIC,
  OperationArgumentIC,
  OperationIC,
  OperationPayloadIC,
  OrderByIC,
  OrderByPayloadIC,
  TableDataIC,
  TableDataValueIC
} from "@certa/types";
import { LENGTH_ZERO } from "../constants/common";
import { defaultChartConfigORM } from "../constants/chartConfig";
import { otherConfigurationsModalCreatorED } from "./emailDeliveryReportModelCreator";
const DATA_POINT_INDEX = 1;

export const chartConfigModelCreatorIC = (data: any) => {
  const chartConfig: ChartConfigIC = {
    attributes: [],
    operations: [],
    groupBy: [],
    orderBy: [],
    otherConfigurations: cloneDeep(defaultChartConfigORM.otherConfigurations)
  };
  if (data.attributes && Array.isArray(data.attributes)) {
    chartConfig.attributes = attributesModalCreatorIC(data.attributes);
  }
  if (data.operations && Array.isArray(data.operations)) {
    const operations = operationsModalCreatorIC(data.operations);
    if (operations) {
      chartConfig.operations = operations;
    }
  }
  if (data.group_by && Array.isArray(data.group_by)) {
    chartConfig.groupBy = groupByModalCreatorIC(data.group_by);
  }

  if (data.order_by && Array.isArray(data.order_by)) {
    chartConfig.orderBy = orderByModalCreatorIC(data.order_by);
  }

  if (data.other_configurations) {
    chartConfig.otherConfigurations = otherConfigurationsModalCreatorED(
      data.other_configurations
    );
  }

  return chartConfig;
};

export const columnsModelCreatorIC = (data: any) => {
  let columnsDataIC: ColumnIC[] = [];
  if (Array.isArray(data)) {
    columnsDataIC = attributesModalCreatorIC(data);
  }

  return columnsDataIC;
};

export const columnsDataModelCreatorIC = (
  data: any,
  columnAttribues?: AttributesPayloadIC[]
): { results: TableDataIC[]; count: number } => {
  let dataPointsArr: TableDataValueIC[][] = [];
  if (Array.isArray(data?.results)) {
    dataPointsArr = data?.results.slice(DATA_POINT_INDEX);
  }

  const tableData = dataPointsArr.map(dataPoint => {
    const row: TableDataIC = {};
    columnAttribues?.forEach((colAttr, index) => {
      row[colAttr.value] = dataPoint[index];
    });
    return row;
  });
  return {
    results: tableData,
    count: data?.count || LENGTH_ZERO
  };
};

export const filtersDataModelCreatorIC = (
  data: any,
  columnAttribues?: ColumnIC[]
): TableDataIC[] => {
  let dataPointsArr: TableDataValueIC[][] = [];
  if (Array.isArray(data)) {
    dataPointsArr = data?.slice(DATA_POINT_INDEX);
  }

  const tableData = dataPointsArr.map(dataPoint => {
    const row: TableDataIC = {};
    columnAttribues?.forEach((colAttr, index) => {
      row[colAttr.value] = dataPoint[index];
    });
    return row;
  });
  return tableData;
};

const attributesModalCreatorIC = (
  attributes: AttributesPayloadIC[]
): ColumnIC[] => {
  return attributes.map(attribute => ({
    type: attribute.type,
    value: attribute.value,
    label: attribute.label,
    extraJSON: {
      fieldType: attribute.extra_json?.field_type,
      isHidden: attribute.extra_json?.is_hidden,
      addedBy: attribute.extra_json?.added_by
    }
  }));
};

const operationsModalCreatorIC = (
  operations: OperationPayloadIC[]
): OperationIC[] => {
  return operations.map(operation => ({
    type: operation.type,
    label: operation.label,
    dataType: operation.data_type,
    function: operation.function,
    arguments: operationArgumentModalCreatorIC(operation.arguments),
    extraArgs: {
      separator: operation.extra_args?.separator,
      compareValue: operation.extra_args?.compare_value
    },
    extraJSON: {
      outputDataType: operation.extra_json?.output_data_type,
      axisName: operation.extra_json?.axis_name,
      fieldType: operation.extra_json.field_type,
      tag: operation.extra_json.tag
    }
  }));
};

const operationArgumentModalCreatorIC = (
  argumentsData: OperationPayloadIC["arguments"]
) => {
  return argumentsData.map(argument => {
    if (typeof argument === "string") {
      return argument;
    }
    const newArgument: OperationArgumentIC = {
      label: argument.label,
      dataType: argument.data_type,
      function: argument.function,
      arguments: operationArgumentModalCreatorIC(argument.arguments),
      extraArgs: {
        separator: argument.extra_args?.separator,
        compareValue: argument.extra_args?.compare_value
      }
    };
    return newArgument;
  });
};

const groupByModalCreatorIC = (groupBys: GroupByPayloadIC[]): GroupByIC[] => {
  return groupBys.map(groupBy => ({
    type: groupBy.type,
    source: groupBy.source,
    operations: groupBy.operations
      ? operationsModalCreatorIC(groupBy?.operations)
      : undefined,
    extraJSON: {
      label: groupBy.extra_json?.label,
      fieldType: groupBy.extra_json?.field_type,
      dataType: groupBy.extra_json?.data_type,
      labelOutputType: groupBy.extra_json?.label_output_type,
      isForSecondaryXAxis: groupBy.extra_json?.is_for_secondary_x_axis,
      operationType: groupBy.extra_json?.operation_type,
      dataSources: groupBy.extra_json?.data_sources
    }
  }));
};

export const orderByModalCreatorIC = (
  orderBys: OrderByPayloadIC[]
): OrderByIC[] => {
  return orderBys.map(orderBy => ({
    type: orderBy.type,
    ordering: orderBy.ordering,
    source: orderBy.source,
    operations: orderBy.operations
      ? operationsModalCreatorIC(orderBy.operations)
      : undefined
  }));
};

export const filterModalCreatorIC = (filter: FilterPayloadIC): FilterIC => {
  const conditions: (FilterIC | FilterConditionIC)[] = filter.conditions.map(
    condition => {
      if ("type" in condition) {
        return filterModalCreatorIC(condition);
      }
      return {
        lhsType: condition.lhs_type,
        dataType: condition.data_type,
        lhsSource: condition.lhs_source,
        operation: condition.op,
        rhsValue: condition.rhs_value,
        extraJSON: {
          fieldType: condition.extra_json.field_type,
          fieldValue: condition.extra_json.field_value
        }
      };
    }
  );
  return {
    type: filter.type,
    conditions
  };
};
