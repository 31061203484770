import { authHeader, handleResponse } from "../_helpers";
import { apiBaseURL } from "../../config";

const routeBaseURL = `${apiBaseURL}impersonation/`;

const getImpersonateeList = ({ limit, offset, search, kind }) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  const params = new URLSearchParams({
    limit,
    offset,
    search,
    kind
  });

  const url = routeBaseURL + `impersonatees/?${params}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(data => ({
      ...data,
      limit,
      offset
    }));
};

const startImpersonation = userId => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader.post(),
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify({ impersonatee: userId })
  };

  return fetch(routeBaseURL + "start/", requestOptions).then(async response => {
    if (![200, 434].includes(response.status)) {
      return Promise.reject(response);
    }
    if (response.status === 434) {
      const params = await response.json();
      return { status: response.status, ...params };
    }
    return response;
  });
};

const stopImpersonation = () => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader.post(),
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  return fetch(routeBaseURL + "stop/", requestOptions).then(() =>
    window.location.reload()
  );
};

export const userImpersonation = {
  getImpersonateeList,
  startImpersonation,
  stopImpersonation
};
