import styled from "@emotion/styled";
import { Radio as AntRadio } from "antd";
import type { RadioGroupProps as AntRadioGroupProps } from "antd/lib/radio/interface";

export type RadioGroupProps = Omit<
  AntRadioGroupProps,
  "buttonStyle" | "options" | "optionType" | "size"
>;

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { RadioGroup } from '@certa/catalyst';
 */
export const RadioGroup = styled(AntRadio.Group)`
  & .ant-radio-button-wrapper {
    margin-right: var(--s2);
  }

  --label: RadioGroup;
`;
