import React from "react";
import { useIntl } from "react-intl";
import { WorkflowLink } from "./WorkflowLink";
import { Timestamp } from "@certa/common";
import { Stack, Text } from "@certa/blocks/thanos";
import type { ActivityLogModelType } from "@certa/queries";
import { ActivityLogBulkAction } from "./ActivityLogBulkAction";

export const ActivityLogSimple = ({ item }: { item: ActivityLogModelType }) => {
  const intl = useIntl();
  if (item.actionCode === "bulk_action_triggered") {
    return <ActivityLogBulkAction item={item} />;
  }
  const isImpersonationAction = item.details?.impersonator_email;
  return (
    <Stack direction="vertical">
      <span>
        <a
          href={
            "mailto:" + isImpersonationAction
              ? item?.details?.impersonator_email
              : item.actorEmail
          }
          style={{ color: " var(--neutral-100)" }}
        >
          <Text variant="p1-bold" color="neutral-100">
            {isImpersonationAction
              ? item?.details?.impersonator_name
              : item.actorEmail}
          </Text>
        </a>{" "}
        <Text variant="p1-bold" color="neutral-70">
          {item.actionType}{" "}
        </Text>
        {/* If workflow is viewed then render link of workflow else the name*/}
        {item.actionType === "viewed" && item.objectType === "workflow" ? (
          <WorkflowLink item={item} />
        ) : (
          <Text variant="p1-bold" color="neutral-100">
            {item.objectName}
          </Text>
        )}
        {/* If workflow kind exists and type is not alert then render it */}
        {item.workflowKind && item.objectType !== "alert" ? (
          <Text
            variant="p1-bold"
            color="neutral-100"
          >{` of ${item.workflowKind}`}</Text>
        ) : (
          ""
        )}
        {/* Don't render workflow link for alert and if workflow is viewed*/}
        {item.actionType !== "viewed" &&
        item.objectType !== "workflow" &&
        item.objectType !== "alert" ? (
          <>
            {` - `}
            <WorkflowLink item={item} />
          </>
        ) : null}
        {isImpersonationAction && (
          <>
            <Text variant="p1-bold" color="neutral-70">
              {" "}
              {intl.formatMessage({
                id: "activityLog.onBehalfOf",
                defaultMessage: "on behalf of"
              })}{" "}
            </Text>
            <a
              href={"mailto:" + item.actorEmail}
              style={{ color: " var(--neutral-100)" }}
            >
              <Text variant="p1-bold" color="neutral-100">
                {item.actorEmail}
              </Text>
            </a>
          </>
        )}
      </span>
      <Timestamp timestamp={item.timestamp.$date} fontSize="var(--p3)" />
    </Stack>
  );
};
