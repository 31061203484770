import type { Config as DOMPurifyConfig } from "dompurify";
import DOMPurify from "dompurify";
import {
  ALLOWED_ATTRIBUTES,
  ALLOWED_TAGS
} from "../constants/htmlSanitization";

/**
 * Below hooks are used to add rel="noopener noreferrer" to all target="_blank" links
 * Idea is to capture 'target' value onto a buffer attribute
 * before sanitization and add it back along with rel after sanitization
 */

const BUFFER_TARGET_ATTR = "bufferTargetAttr";

DOMPurify.addHook("uponSanitizeAttribute", (node: Element) => {
  // to capture 'target' value before it is sanitized
  const bufferTarget = node.getAttribute("target");

  if (bufferTarget) {
    // buffer attribute for holding 'target' value
    node.setAttribute(BUFFER_TARGET_ATTR, bufferTarget);
  }
});

DOMPurify.addHook("afterSanitizeAttributes", (node: Element) => {
  const bufferTargetValue = node.getAttribute(BUFFER_TARGET_ATTR);
  if (bufferTargetValue) {
    // remove buffer attribute
    node.removeAttribute(BUFFER_TARGET_ATTR);

    // add rel and target attr back
    // only if target was '_blank' before sanitization
    if (bufferTargetValue === "_blank") {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    }
  }
});

const defaultConfig: DOMPurifyConfig = {
  /**
   * Using spread operator to convert readonly tuple to array
   * since DOMPurify expects an array of strings
   */
  ALLOWED_TAGS: [...ALLOWED_TAGS],
  ALLOWED_ATTR: [...ALLOWED_ATTRIBUTES]
};

export const sanitizeHTML = (label: string, config?: DOMPurifyConfig) => {
  // custom config (if any) takes precedence
  if (config) {
    return DOMPurify.sanitize(label, config) as string;
  }

  // else use default config
  return DOMPurify.sanitize(label, defaultConfig) as string;
};

export const removeHTMLTagsAndTruncate = (
  htmlString: string,
  maxLength?: number
): string => {
  const sanitizedText = DOMPurify.sanitize(htmlString, { ALLOWED_TAGS: [] });

  if (maxLength && sanitizedText.length > maxLength) {
    return `${sanitizedText.slice(0, maxLength)}...`; // Truncate if needed
  }

  return sanitizedText;
};
