import { css } from "emotion";
import React from "react";
import { ChevronLeft, ChevronRight } from "@certa/icons";
import { Button } from "./Button/";
import { Text } from "./Typography/Text";

type TableStyleConfig = {
  isExpandable?: boolean;
};

export const getProcessTableClassName = (config?: TableStyleConfig) => {
  if (config?.isExpandable)
    return `${ProcessTableClassName} ${ExpandedViewClass}`;

  return ProcessTableClassName;
};

const ExpandedViewClass = css`
  // Applied to remove antd table default padding between expand icon and first column cell
  td.ant-table-cell {
    &:nth-child(1) {
      padding-right: 0 !important;
    }
    &:nth-child(2) {
      padding-left: 0 !important;
    }
  }
  td {
    background-color: var(--neutral-0) !important;
  }
`;

export const paginationCSS = `
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
    padding-left: var(--s9);
  }

  .ant-pagination-item,
  .ant-pagination-next,
  .ant-pagination-prev {
    color: var(--colors-neutral-700);
  }

  .ant-pagination-item {
    background-color: transparent;
    border-color: transparent;
    border-radius: var(--s1);
    margin-right: var(--s1);
    align-items: center;
    justify-content: center;
    display: inline-flex;
    &:focus {
      border: none;
      box-shadow: none !important;
      outline: 2px solid var(--colors-brand-400) !important;
    }
    &:hover,
    &.ant-pagination-item-active {
      color: var(--colors-brand-400) !important;
      background-color: var(--colors-brand-100) !important;
      border-color: transparent;
    }
    &.ant-pagination-item-active {
      font-weight: bold;
    }
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    align-items: center;
    justify-content: center;
    display: inline-flex;
  }
  .ant-pagination-next:not(.ant-pagination-disabled) *,
  .ant-pagination-prev:not(.ant-pagination-disabled) * {
    color: var(--colors-brand-400) !important;
  }
  .ant-pagination-next:not(.ant-pagination-disabled) button:focus,
  .ant-pagination-prev:not(.ant-pagination-disabled) button:focus {
    box-shadow: none !important;
    outline: 2px solid var(--colors-brand-400) !important;
  }
  .ant-pagination-next:not(.ant-pagination-disabled):hover *,
  .ant-pagination-prev:not(.ant-pagination-disabled):hover * {
    color: var(--colors-brand-500) !important;
  }
  .ant-pagination-prev {
    margin-right: var(--s1);
  }
`;

const ProcessTableClassName = css`
  table {
    /* This will allow giving border to tr, which is required for drag and drop to highlight drop target */
    border-collapse: collapse !important;
  }
  .ant-table-container {
    position: relative;
  }
  th {
    font-weight: bold !important;
    font-size: 12px;
    line-height: 16px;
    color: var(--colors-neutral-800) !important;
    border-bottom: none;
  }
  tr:not(.ant-table-measure-row) {
    th,
    td {
      padding: var(--s3) var(--s5) !important;
      text-overflow: ellipsis !important;
      overflow: hidden;
      overflow-wrap: normal !important;
    }
  }
  tr:hover {
    td {
      background-color: var(--neutral-5) !important;
    }
    .ant-progress-circle-trail {
      stroke: var(--teal) !important;
      opacity: 0.3;
    }
  }
  /* This would be used for the actual table row not the expanded td */
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: var(--teal-40) !important;
  }
  /* td:first-child {
    align-items: center;
    display: flex;
  } */
  // The below css is to make the column header fixed when list is scrolled.
  // Antd latest version supports sticky prop so replace this css with that prop when antd is updated
  .ant-table-header {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .ant-table-body {
    overflow: auto !important;
  }
  .ant-table-content {
    overflow: auto !important;
  }

  ${paginationCSS}
  .ant-table-column-sorters {
    padding: var(--s0) !important;
  }
`;

export function paginationShowTotalOverride(
  total: number,
  range: [number, number]
) {
  return (
    <Text variant="p2-bold" color="var(--colors-neutral-700)">
      SHOWING {range[0]}-{range[1]} OUT OF {total} ITEMS
    </Text>
  );
}

export function paginationButtonsOverride(
  current: number,
  type: string,
  originalElement: JSX.Element
) {
  if (type === "prev") {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Button type="link" size="small">
        <ChevronLeft
          style={{
            marginRight: "var(--s2)"
          }}
        />
        Prev
      </Button>
    );
  }
  if (type === "next") {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Button type="link" size="small">
        Next
        <ChevronRight
          style={{
            marginLeft: "var(--s2)"
          }}
        />
      </Button>
    );
  }
  return originalElement;
}

export const statusColumnExtras = {
  backgroundColor: "var(--teal)",
  style: {
    color: "white",
    borderRadius: "3px",
    padding: "var(--s1) 6px",
    border: "none",
    fontSize: 10,
    fontWeight: "bold",
    lineHeight: "12px",
    textTransform: "uppercase"
  }
};
