import React, { useState, useEffect } from "react";
import { CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import type { DropdownContentProps } from "@certa/catalyst";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItemNoResults,
  DropdownMenuTrigger,
  DropdownMenuItem
} from "@certa/catalyst";
import type { Option } from "@certa/blocks/src/componentsTh/ToggleTabsNew";

const cache = new CellMeasurerCache({ fixedWidth: true });

/*
  - Responsibilities of this component:
    1. Render the dropdown menu
    2. Render the dropdown trigger
    3. Render the dropdown menu content
    4. Render the dropdown menu items
    5. Render the dropdown menu no results
    6. Render the dropdown menu inline search conditionally
  
  - Why this component is needed?
    We have dropdown component in catalyst but when we wanted to use it at any other places then,
    It becomes so much clutter since at that place we need to import multiple things
    And then will also have implement search logic.
    Better to make some generic derived component from that dropdown component

*/

export const VirtualizedSearchDropdown = ({
  dropdownCmp,
  items,
  onSelect,
  inlineSearchVisibilityThreshold = 15,
  menuWidth = 340,
  menuHeight = 400,
  onDropdownMenuOpenChange,
  dropdownMenuContentProps = {}
}: {
  dropdownCmp: React.ReactElement;
  items: Option[];
  onSelect: (value: string) => void;
  inlineSearchVisibilityThreshold?: number;
  menuWidth?: number;
  menuHeight?: number;
  onDropdownMenuOpenChange?: (open: boolean) => void;
  dropdownMenuContentProps?: Partial<DropdownContentProps>;
}) => {
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  useEffect(() => {
    setFilteredOptions(items);
  }, [items]);

  const onInlineSearch = (searchText: string) => {
    const filteredKinds = items.filter(item => {
      if (item && item.label && typeof item.label === "string") {
        return item.label.toLowerCase?.().includes(searchText.toLowerCase());
      }
      return false;
    });
    // To reset the cached row heights when the list changes due to search
    // because the row heights are cached based on the index of the row
    // and when the list changes the index of the rows change
    // and the cached row heights are not valid anymore
    cache.clearAll();
    setFilteredOptions(filteredKinds);
  };
  const onClearInlineSearch = () => {
    cache.clearAll();
    setFilteredOptions(items);
  };

  return (
    <DropdownMenu
      onOpenChange={open => {
        if (!open) {
          setFilteredOptions(items);
        }
        onDropdownMenuOpenChange?.(open);
      }}
    >
      <DropdownMenuTrigger>{dropdownCmp}</DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        showInlineSearch={items.length > inlineSearchVisibilityThreshold}
        onInlineSearch={onInlineSearch}
        onClearInlineSearch={onClearInlineSearch}
        {...dropdownMenuContentProps}
      >
        {filteredOptions.length === 0 ? (
          <DropdownMenuItemNoResults>
            No results found
          </DropdownMenuItemNoResults>
        ) : filteredOptions.length <= 15 ? (
          filteredOptions.map(option => {
            return (
              <DropdownMenuItem
                key={option.value}
                onSelect={onSelect}
                value={option.value}
              >
                {option.label}
              </DropdownMenuItem>
            );
          })
        ) : (
          <List
            width={menuWidth}
            height={menuHeight}
            deferredMeasurementCache={cache}
            rowCount={filteredOptions.length}
            rowHeight={cache.rowHeight}
            rowRenderer={({ index, key, style, parent }) => {
              return (
                <CellMeasurer
                  key={key}
                  cache={cache}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  {({ registerChild }) => {
                    const item = filteredOptions[index];
                    return (
                      <DropdownMenuItem
                        key={key}
                        value={item.value}
                        onSelect={onSelect}
                        style={style}
                        ref={ref => {
                          if (ref) {
                            registerChild?.(ref);
                          }
                        }}
                      >
                        {item.label}
                      </DropdownMenuItem>
                    );
                  }}
                </CellMeasurer>
              );
            }}
          />
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
