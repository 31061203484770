import { APIFetch, RequestHeaders } from "@certa/network";

// Set Auth token for Comms module in the cookie
const setCommsAuthToken = () => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include"
  };
  return APIFetch(`comms/generate_token/`, requestOptions);
};

export { setCommsAuthToken };
