import type { FieldHierarchyTree, FieldMap } from "@certa/common";
import {
  createMapping,
  createMappingForSLAAndSGLA,
  getFieldTypeFromSourceMapping
} from "@certa/common";
import type {
  LCData,
  WorkflowFamilyMember,
  Workflow,
  Step,
  StepGroup,
  Alert,
  WorkflowDynamicAPIResultType,
  FieldTypes
} from "@certa/types";
import type {
  StepGroupsMetaData,
  WorkflowFamily,
  WorkflowItem,
  WorkflowList,
  WorkflowLCData,
  StepGroupsData,
  TaskDetails,
  ReportWorkflows,
  WorkflowItemDetails,
  WorkflowItemDetailsResposne,
  RawWorkflowFamily,
  RelatedKindType,
  ReportWorkflowsStepGroups,
  ReportWorkflowsSteps,
  StepRaw,
  MigrationStatsResponse,
  MigrationStats,
  StepUserTagsWorkflowResponse,
  StepUserTags
} from "../types/workflow.types";
import { queryClient } from "@certa/queries/src/utils/utils";

export const getFilteredLCData = (rawLCData?: any[]) => {
  const filteredLCData: LCData[] = [];
  rawLCData?.forEach(item => {
    if (
      !item.hide_from_workflow &&
      item.value !== null &&
      item.value !== undefined &&
      item.value !== "" &&
      item.display_type === "normal"
    ) {
      // creating the map
      filteredLCData.push({
        label: item.translated_label || item.label,
        value: item.value,
        format: item.format
      });
    }
  });
  return filteredLCData;
};

export const addParentDataToWorkflowFamily = (
  data: RawWorkflowFamily[],
  id: number,
  name: string,
  lcData: LCData[],
  shouldFormat = true
) => {
  const parent = shouldFormat
    ? { id, name, lcData }
    : { id, name, lc_data: lcData };
  data?.push(parent as RawWorkflowFamily);

  const _workflowFamily = shouldFormat
    ? data?.map(
        (item: any): WorkflowFamilyMember => ({
          id: item.id,
          name: item.name,
          lcData: getFilteredLCData(item.lc_data)
        })
      )
    : data;

  const DEFAULT_WORKFLOW_FAMILY: never[] = [];

  const workflowFamily =
    _workflowFamily !== undefined && _workflowFamily?.length !== 0
      ? _workflowFamily
      : DEFAULT_WORKFLOW_FAMILY;

  return workflowFamily;
};

/**
 * THIS IS NOT BE USED WITH RESPONSE OF WORKFLOW CREATION
 * The structure of create workflow's response is a bit different
 * and this model creator doesn't fit that exactly
 * @param response
 */
export const createWorkflowModel = (
  response: WorkflowItemDetailsResposne
): Workflow => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  const { results } = response;
  const data = results[0];

  const workflowFamily = addParentDataToWorkflowFamily(
    data?.workflow_family,
    data?.id,
    data?.name,
    data?.lc_data
  ) as WorkflowFamilyMember[];

  return {
    uid: data.uid,
    id: data.id,
    name: data.name,
    workflowFamily: workflowFamily,
    lcData: getFilteredLCData(data.lc_data),
    status: {
      id: data.status.id,
      label: data.status.label || data.status?.display_name,
      colorCode: availableStatusesKeyedById[data.status.id]?.color_code
    },
    root: data.root,
    nodeOrder: data.definition.node_order,
    defaultStepTags: data.definition.default_step_tags || [],
    region: data.region,
    businessUnit: data.business_unit
    /* TO BE ADDED WHEN NEEDED
      nodeOrder, relatedTypes, region, alerts
    */
  };
};

/**
 * TODO: Add Proper types here @rohitmalhotra
 */
const getStepsFromTodoGroups = (
  todoStepGroups: any,
  allStepGroups: StepGroup[]
) => {
  if (!todoStepGroups.length) {
    return [];
  }

  const todoStep: string[] = [];
  todoStepGroups.forEach((tag: string) => {
    const group: any = allStepGroups.find(
      (group: any) => group.definitionTag === tag
    );
    if (group) {
      todoStep.push(...group.steps.map((item: any) => item.definitionTag));
    }
  });
  return todoStep;
};

const getMyStepGroups = (
  allStepGroups: StepGroup[],
  myStepTags: string[],
  isGroup = false // will get all the steps from the user group.
) => {
  const myGroups: StepGroup[] = [];

  allStepGroups.forEach(group => {
    // Spreading this to avoid reference and state mutation errors
    const mySteps = getMySteps(group, myStepTags);
    if (mySteps.length > 0) {
      const newGroup = {
        ...group,
        steps: isGroup ? group?.steps : mySteps
      };
      myGroups.push(newGroup);
    }
  });
  return myGroups;
};

const getMySteps = (group: StepGroup, myStepTags: String[]) => {
  const mysteps = group.steps.filter(step => {
    return myStepTags.includes(step.definitionTag);
  });
  return mysteps;
};

export const stepGroupModelCreator = (response: any): StepGroupsData => {
  const modeledStepGroupData = {
    todoStepGroups: response?.todo_step_groups || [],
    todoSteps: response?.todo_steps_for_groups || [],
    defaultStepTags: response?.default_step_tags || [],
    defaultStepTagsForGroup: response?.default_step_tags_for_group || [],
    taggedSteps: response?.tagged_steps || [],
    progress: response?.progress,
    results:
      response?.results?.map((stepGroup: any) => {
        const firstStepWithAlerts = stepGroup.steps?.find(
          (step: StepRaw) => !!step.alerts?.length
        );

        return {
          id: stepGroup.id,
          name: stepGroup.definition.name,
          definitionId: stepGroup.definition.id,
          definitionTag: stepGroup.definition.tag,
          isCompleted: stepGroup.steps
            .filter((step: any) => step.step_type !== "FYI")
            .every((step: any) => step.completed_by !== null),
          isHidden: !stepGroup.is_enabled,
          overdue: stepGroup.overdue,
          alertCount: stepGroup.alerts.length,
          firstAlertColor:
            firstStepWithAlerts?.alerts?.[0]?.label ||
            (stepGroup.alerts?.length ? stepGroup.alerts[0].label : ""),
          alerts: firstStepWithAlerts?.alerts || stepGroup.alerts,
          accessibleBy: stepGroup.accessible_by?.map((item: any) => ({
            label: item.name,
            value: item.id
          })),
          assignedTo: stepGroup.todo_user_groups?.map((item: any) => ({
            label: item,
            value: item
          })),
          steps: stepGroup.steps.map((step: StepRaw) => {
            const isStepOverdue =
              step.deadline &&
              new Date().getTime() > new Date(step.deadline).getTime();
            return {
              id: step.id,
              name: step.name,
              isCompleted: !!step.completed_by,
              overdue: isStepOverdue,
              deadline: step.deadline,
              isLocked: step.is_locked,
              isHidden: !step.is_enabled,
              alertCount: step.alerts.length,
              firstAlertColor: step.alerts?.length ? step.alerts[0].label : "",
              stepType: step.step_type || "doable",
              alerts: step.alerts,
              definitionTag: step.tag,
              availableUserTags: step.available_user_tags,
              isWizard: step?.def_extra?.is_wizard || false
            } as Step;
          })
        } as StepGroup;
      }) || []
  } as StepGroupsMetaData;

  const filteredStepGroups = {
    ...modeledStepGroupData,
    results: modeledStepGroupData.results.filter(
      (stepGroup: StepGroup) => stepGroup?.steps?.length
    )
  };

  const { todoStepGroups, results } = filteredStepGroups;

  const todoStepGroup = getStepsFromTodoGroups(todoStepGroups, results);

  /* NOTE: This is for OLD UI assigments */
  const mySteps = [
    ...filteredStepGroups.taggedSteps,
    ...filteredStepGroups.todoSteps,
    ...todoStepGroup
  ];

  const myStepGroups = getMyStepGroups(filteredStepGroups.results, mySteps);

  /* Direct user assigned steps */
  const myTaggedSteps = getMyStepGroups(filteredStepGroups.results, [
    ...filteredStepGroups.taggedSteps
  ]);

  /* Only specific to user-role */
  const userStepGroups = getMyStepGroups(filteredStepGroups.results, [
    ...filteredStepGroups.todoSteps,
    ...todoStepGroup
  ]);

  const combinedStepGroups = {
    ...filteredStepGroups,
    myStepGroups,
    myTaggedSteps,
    userStepGroups
  };

  return combinedStepGroups;
};

export const workflowModelCreator = (workflow: any): WorkflowItem => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  return {
    id: workflow.id,
    name: workflow.name,
    createdAt: workflow.created_at,
    sortingPrimaryField: workflow.sorting_primary_field,
    lcData: workflow.lc_data.map(
      (data: any): WorkflowLCData => ({
        value: data.value,
        label: data.translated_label || data.label,
        displayType: data.display_type,
        format: data.format,
        hideFromWorkflow: !!data.hide_from_workflow
      })
    ),
    definition: {
      name: workflow.definition.name,
      kind: workflow.definition.kind
    },
    workflowFamily:
      workflow?.workflow_family?.map(
        (family: any): WorkflowFamily => ({
          name: family.name,
          id: family.id
        })
      ) || [],
    alerts: workflow.alerts.map(
      (alert: any): Alert => ({
        id: alert.id,
        fieldId: alert.field_id,
        stepId: alert.step_id,
        stepGroupId: alert.step_group_id,
        workflowId: alert.workflow_id,
        catColorLabel: alert.cat_color_label,
        alertTag: alert.alert_tag
      })
    ),
    status: {
      id: workflow.status.id,
      displayName: workflow.status.display_name,
      colorCode: availableStatusesKeyedById[workflow.status.id]?.color_code
    },
    progress: workflow.progress,
    rank: workflow.rank,
    logo: workflow?.logo || null,
    isWorkflowBeingMigrated: !!workflow?.is_being_migrated
  };
};

export const workflowListModelCreator = (response: any): WorkflowList => {
  return {
    count: response.count,
    results: response.results.map(workflowModelCreator),
    next: response.next,
    previous: response.previous
  };
};

/**
 * Task Details model creators and helper functions
 */

const getLCDataForTaskDetails = (lcData: any): LCData[] => {
  const filteredLcData = lcData.filter(
    (lc: any) => lc.display_type === "normal" && lc.value
  );
  const visibleLcData = filteredLcData.map((lc: any) => ({
    value: lc.value,
    label: lc.translated_label || lc.label,
    format: lc.format || ""
  }));

  return visibleLcData;
};
// TODO: Change it's name to processDetailsModelCreator
export const taskDetailsModelCreator = (
  response: WorkflowItemDetailsResposne
): TaskDetails => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  const { results } = response;
  const data = results[0];
  const workflowFamily = addParentDataToWorkflowFamily(
    data?.workflow_family,
    data?.id,
    data?.name,
    data?.lc_data
  ) as WorkflowFamilyMember[];

  const result = {
    name: data?.name || "",
    status: data?.status?.display_name || "",
    statusColorCode:
      data?.status?.color_code ||
      availableStatusesKeyedById[data?.status?.id]?.color_code ||
      "",
    lcData: getLCDataForTaskDetails(data?.lc_data || []),
    family: workflowFamily,
    definition: {
      defaultStepTags: data?.definition?.default_step_tags || [],
      name: data?.definition?.name || "",
      dynamicGroupNamesWithPerm:
        data?.definition?.dynamic_group_names_with_perm || {},
      kindId: data?.definition?.kind
    },
    businessUnit: data?.business_unit || null,
    region: data?.region || null,
    uid: data?.uid,
    id: data?.id,
    workflowFamily: workflowFamily,
    logo: data?.logo,
    root: data?.root,
    nodeOrder: data?.definition.node_order,
    defaultStepTags: data?.definition.default_step_tags || [],
    createdAt: data?.created_at,
    isWorkflowBeingMigrated: !!data?.is_being_migrated
  };

  return result;
};

export const taskMigrationStatsModelCreator = (
  response: MigrationStatsResponse
): MigrationStats => ({
  isAtdMigrationOngoing: response.is_atd_migration_ongoing,
  kindsBeingMigrated: response?.kinds_being_migrated || [],
  kindsBlocked: response?.kinds_blocked || [],
  estimatedTimeOfCompletion: response.estimated_time_of_completion,
  errorCode: response?.error_code,
  message: response?.message
});

export const stepUsersModelCreator = (
  jsonFromAPI: StepUserTagsWorkflowResponse | undefined
): StepUserTags => {
  const stepUsers = jsonFromAPI?.results ?? [];
  return stepUsers.reduce((stepUsersAggregator: StepUserTags, stepUser) => {
    if (stepUser.step && stepUser.user) {
      stepUsersAggregator[stepUser.step] = {
        ...stepUser,
        groups: stepUser.user_static_groups || [],
        users: [
          ...(stepUsersAggregator[stepUser.step]?.users || []),
          {
            id: stepUser.id,
            user: stepUser.user,
            user_full_name: stepUser.user_full_name,
            user_email: stepUser.user_email,
            groups: stepUser.user_static_groups || []
          }
        ]
      };
    }
    return stepUsersAggregator;
  }, {});
};

export const reportWorkflowsModelCreator = (data: any): ReportWorkflows => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  return {
    count: data.count,
    results: data.results.map((workflow: any) => ({
      id: workflow.id,
      name: workflow.name,
      createdAt: workflow.created_at,
      processType: workflow.kind_name,
      createdBy: {
        email: workflow.created_by.email,
        id: workflow.created_by.id,
        firstName: workflow.created_by.first_name,
        lastName: workflow.created_by.last_name
      },
      lastActivity: workflow.last_activity,
      //TODO: convert myTaskCount to myTasksCount
      myTaskCount: workflow.my_tasks_count,
      progress: workflow.progress,
      logo: workflow.logo,
      status: {
        id: workflow.status.id,
        displayName: workflow.status.display_name,
        colorCode: availableStatusesKeyedById[workflow.status.id]?.color_code
      },
      alerts: workflow.alerts.map(
        (alert: any): Alert => ({
          id: alert.id,
          fieldId: alert.field_id,
          stepId: alert.step_id,
          stepGroupId: alert.step_group_id,
          workflowId: alert.workflow_id,
          catColorLabel: alert.cat_color_label,
          alertTag: alert.alert_tag
        })
      ), // TODO: Map
      lcData: workflow.lc_data,
      fields: workflow.fields,
      workflowFamily: workflow.workflow_family,
      updatedAt: workflow.updated_at,
      ageing: workflow.ageing,
      cycleTime: workflow.cycle_time,
      cycleStart: workflow.cycle_start,
      cycleEnd: workflow.cycle_end,
      isCycleRunning: workflow.is_cycle_running,
      definitionId: workflow.definition_id,
      isWorkflowBeingMigrated: !!workflow.is_being_migrated,
      steps: Object.keys(workflow.steps).reduce((steps, stepTag: string) => {
        const step = workflow.steps[stepTag];
        steps[stepTag] = {
          id: step.id,
          definition: step.definition,
          isLocked: step.is_locked,
          isEnabled: step.is_enabled,
          initiatedAt: step.initiated_at,
          updatedAt: step.updated_at,
          completedAt: step.completed_at,
          completedBy: {
            id: step.completed_by.id,
            email: step.completed_by.email,
            firstName: step.completed_by.first_name,
            lastName: step.completed_by.last_name
          },
          ageing: step.ageing,
          cycleTime: step.cycle_time,
          cycleStart: step.cycle_start,
          cycleEnd: step.cycle_end,
          isCycleRunning: step.is_cycle_running,
          overdue: step.overdue,
          submitCount: step.submit_count
        };
        return steps;
      }, {} as ReportWorkflowsSteps),
      stepGroups: Object.keys(workflow.step_groups).reduce(
        (stepGroups, stepGroupTag: string) => {
          const stepGroup = workflow.step_groups[stepGroupTag];
          stepGroups[stepGroupTag] = {
            id: stepGroup.id,
            definition: stepGroup.definition,
            ageing: stepGroup.ageing,
            cycleTime: stepGroup.cycle_time,
            cycleStart: stepGroup.cycle_start,
            cycleEnd: stepGroup.cycle_end,
            isCycleRunning: stepGroup.is_cycle_running
          };
          return stepGroups;
        },
        {} as ReportWorkflowsStepGroups
      )
    }))
  };
};

export const fieldHierarchyModelCreator = (data: any) => {
  if (
    !data ||
    !data.results ||
    !Array.isArray(data.results) ||
    !data.results.length
  ) {
    return {
      hierarchy: [],
      mapping: {} as FieldMap
    };
  }
  const {
    children: stepGroups,
    label,
    value,
    field_type: fieldType
  }: FieldHierarchyTree = data.results[0];
  const transformedStepGroups = stepGroups?.map(
    (stepGroup: FieldHierarchyTree) => ({
      ...stepGroup,
      children: stepGroup.children?.map(step => ({
        ...step,
        children: step?.children?.map(field => ({
          ...field,
          field_type: getFieldTypeFromSourceMapping(
            field?.field_type as FieldTypes,
            field?.source_mapping
          )
        }))
      })),
      label: stepGroup.label,
      value: stepGroup.tag
    })
  );
  return {
    hierarchy: transformedStepGroups,
    mapping: createMapping({
      children: transformedStepGroups,
      label,
      value,
      field_type: fieldType
    } as FieldHierarchyTree),
    stepGroupAndStepMapping: createMappingForSLAAndSGLA(transformedStepGroups)
  };
};
export type FieldHierarchyReturnType = ReturnType<
  typeof fieldHierarchyModelCreator
>;

export const workflowsItemModelCreator = (
  response: WorkflowItemDetailsResposne
): WorkflowItemDetails => {
  const { results } = response;
  const data = results[0];
  const workflowFamily = addParentDataToWorkflowFamily(
    data?.workflow_family,
    data?.id,
    data?.name,
    data?.lc_data,
    false
  ) as RawWorkflowFamily[];

  return {
    alerts: data?.alerts,
    businessUnit: data?.business_unit,
    createdAt: data?.created_at,
    definition: data?.definition,
    id: data?.id,
    logo: data?.logo,
    name: data?.name,
    selected_flag: {},
    uid: data?.uid,
    workflowFamily
  };
};

export const relatedKindModalCreator = (data: any[]): RelatedKindType[] => {
  return data.map(kindItem => ({
    id: kindItem?.id,
    name: kindItem?.name,
    tag: kindItem?.tag,
    latestDefinitionId: kindItem?.latest_definition_id
  }));
};

export const workflowDetailsCreator = (
  data: WorkflowDynamicAPIResultType<any>
) => {
  return {
    ...data,
    results: data.results.map(kind => ({ ...kind, lcData: kind?.lc_data }))
  };
};
