import type { FC } from "react";
import React from "react";
import { Menu as AntMenu } from "antd";
import type { SubMenuProps as AntSubMenuProps } from "antd/lib/menu/SubMenu";
import styled from "@emotion/styled";
import { Stack } from "../Stack";
import { Text } from "../Typography/Text";
import type { IconProps } from "@certa/icons";
import { ChevronRight } from "@certa/icons";
import { activeDropdownClassName } from "./MenuItem";

type SubMenuProps = {
  //** Submenu target item active, Supports in dropdown only  */
  activeItem?: boolean;
  icon?: React.ComponentType<IconProps>;
  maxHeight?: string;
} & AntSubMenuProps;
export const CustomSubMenu: FC<SubMenuProps> = props => {
  const {
    title,
    className,
    activeItem,
    icon: Icon,
    popupOffset,
    ...otherSubMenuProps
  } = props;
  return (
    <AntMenu.SubMenu
      title={
        <Stack justify="space-between" align="center" gap="s3">
          <Stack align="center" gap="s2">
            {Icon ? (
              <Stack>
                <Icon />
              </Stack>
            ) : null}
            <Text>{title}</Text>
          </Stack>
          {!otherSubMenuProps.disabled && (
            <ChevronRight className="button-disclosure" size={12} />
          )}
        </Stack>
      }
      popupClassName={className}
      popupOffset={popupOffset || [8, -8]}
      {...otherSubMenuProps}
      className={activeItem ? activeDropdownClassName : ""}
    />
  );
};

export const SubMenu = styled(CustomSubMenu)`
  min-width: 200px !important;
  overflow: visible !important;
  border-radius: 0;
  box-shadow: none;
  .ant-menu-sub,
  .ant-dropdown-menu-sub {
    background: var(--neutral-0) !important;
    box-shadow: 0px 2px 16px rgba(0, 22, 78, 0.1) !important;
    border-radius: var(--s2) !important;
    padding: var(--s2) !important;
    overflow-y: auto !important;
    margin: 0 !important;
    ${({ maxHeight }) => {
      if (maxHeight)
        return {
          "max-height": maxHeight
        };
      return {
        maxHeight: window.innerHeight / 3 + "px !important"
      };
    }}
    max-height:
  }
  --label: CustomSubMenu;
`;
