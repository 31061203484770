import { APIFetch, APIFetchV2, RequestHeaders } from "@certa/network";
import type {
  GetCommentsParams,
  UpdateWorkflowStatusParams,
  UpdateIntegrationStatusParams,
  UpdateFlagParams,
  AddCommentParams,
  NewThreadParams
} from "../types/comments.types";
import type { HashMap } from "@certa/types";
import qs from "query-string";
import {
  adjudicationFlagsModelCreator,
  commentsModelCreator,
  integrationStatusesModalCreator
} from "../models/comments.models";
import { captureException } from "@sentry/react";
import { notification } from "@certa/blocks/src/componentsTh/Notification/notification";

const newThread = (payload: NewThreadParams) =>
  getComments({
    ...payload,
    newThread: true,
    thread_name: payload.threadName,
    thread_user_groups: payload.threadUserGroups
  });

const getComments = ({
  object_id: objectId,
  type,
  field_id: fieldId,
  uid,
  newThread,
  // thread_id,
  thread_name: threadName,
  thread_user_groups: threadUserGroups
}: GetCommentsParams) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  const requestSearchParams: {
    object_id: number;
    type: string;
    uid?: string;
    field_id?: number;
    new_thread?: boolean;
    thread_user_groups?: number[];
    thread_name?: string;
  } = {
    object_id: objectId,
    type
  };

  if (uid) {
    requestSearchParams.uid = uid;
  }
  if (fieldId) {
    requestSearchParams.field_id = fieldId;
  }

  // Commented because of code-coverage, as this is not being used, but not
  // removed entirely because this is supported by the API, and could be used
  // in cases such as refreshing a particular thread.
  // if (thread_id) {
  //   requestURL += `&thread_id=${thread_id}`;
  // }

  if (newThread) {
    requestSearchParams.new_thread = true;
    if (threadUserGroups && Array.isArray(threadUserGroups) && threadName) {
      requestSearchParams.thread_user_groups = threadUserGroups;
      requestSearchParams.thread_name = threadName;
    }
  }
  const requestURL = `channels/?${qs.stringify(requestSearchParams, {
    encode: true
  })}`;
  return APIFetch(requestURL, requestOptions).then(response =>
    newThread ? response : commentsModelCreator(response)
  );
};

const addComment = (payload: AddCommentParams & HashMap) => {
  let data: FormData | string | null = null;
  if (payload.attachment) {
    data = new FormData();
    Object.keys(payload).forEach(key => {
      if (Array.isArray(payload[key])) {
        // because array can't be sent normally
        if (payload[key].length) {
          payload[key].forEach((item: any) => {
            (data as FormData).append(key + "[]", item);
          });
        }
      } else {
        (data as FormData).append(key, payload[key]);
      }
    });
  } else {
    data = JSON.stringify(payload);
  }

  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: data
  };

  if (payload.attachment) {
    delete (requestOptions.headers as HashMap)["Content-Type"];
  }

  return APIFetch("channels/addmessage/", requestOptions);
};

function updateFlag(payload: UpdateFlagParams) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(payload)
  };
  return APIFetch("flags/", requestOptions);
}

function updateIntegrationStatus(payload: UpdateIntegrationStatusParams) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(payload)
  };
  return APIFetch(
    "integrations/status-update/" + payload["row_uid"] + "/",
    requestOptions
  );
}

function updateWorkflowStatus(payload: UpdateWorkflowStatusParams) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      status: payload.statusId,
      thread_id: payload.thread_id,
      addComment: payload.addComment || "",
      integration_field_tag: payload.integration_field_tag || "",
      parent_workflow_id: payload.parent_workflow_id || ""
    })
  };

  const url = "workflows/" + payload.workflowId + "/change-status/?format=json";
  return APIFetch(url, requestOptions);
}

function getCommentFlags() {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  return APIFetchV2("comment-flag-options/", requestOptions).then(
    adjudicationFlagsModelCreator
  );
}

const getCommentsCount = (objectId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch(
    `channels/getmessagecount/?object_id=${Number(objectId)}&type=workflow`,
    requestOptions
  ).then(
    (response: any) =>
      ({
        count: response.message_count__sum
      }) as {
        count: number;
      }
  );
};

const getUnreadCommentsCount = (workflowId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(`unread-messages-count/${workflowId}`, requestOptions)
    .then((response: any) => response.count as number)
    .catch(e => {
      if (e?.status === 404) {
        return notification["error"]({
          message: e?.statusText || "Workflow does not exist."
        });
      }
      return captureException(e);
    });
};

const getIntegrationStatuses = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  return APIFetchV2("integration-result-status/", requestOptions).then(
    integrationStatusesModalCreator
  );
};

export const commentsServices = {
  getComments,
  newThread,
  addComment,
  updateFlag,
  updateIntegrationStatus,
  updateWorkflowStatus,
  getCommentFlags,
  getCommentsCount,
  getUnreadCommentsCount,
  getIntegrationStatuses
};
