import { css } from "emotion";
import { getAvatarLetters } from "@certa/blocks/thanos";
import type { UserMention } from "@certa/queries";
import {
  ABSOLUTE_DATE_TIME_FORMAT,
  getUserMentionTag,
  userMentionRegEx,
  UserDetailsTooltip,
  sanitizeHTML
} from "@certa/common";
import moment from "moment";
import {
  AvatarColors,
  AvatarSizes,
  Avatar,
  Stack,
  TypographyColors,
  TypographyVariants,
  Tooltip,
  Typography
} from "@certa/catalyst";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { notificationsListItemCustomStyles } from "./notificationsItem.styles";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import { Loader } from "@certa/blocks";
import { MentionsPath } from "./MentionsPath";
import { useIntl } from "react-intl";

type MentionItemProps = {
  mention: UserMention;
};

const MentionItem = ({ mention }: MentionItemProps) => {
  const {
    mentionId,
    process,
    task,
    field,
    isRead,
    postedBy,
    createdAt,
    message
  } = mention;
  const userDisplay = postedBy.fullName || postedBy.email;
  const intl = useIntl();

  const { data: processDetails, status: processDetailsStatus } =
    useProcessDetails(process?.processId);

  /**
   * TODO: Add support to get the list of all mentions here and then
   * use useCommentMessageFormat hook to highlight the mentions
   *
   * NOTE: For now the mention of the logged in user will not be rendered
   * with different CSS. We need to get the data of all the mentions here first.
   */
  const transformedMessage = message
    .replace(userMentionRegEx, getUserMentionTag(false, "$1"))
    .replace(/\n/g, "<br />");

  const mentionedYouText = intl.formatMessage({
    id: "notifications.mentionedYou",
    defaultMessage: "mentioned you"
  });

  const user = {
    id: postedBy.userId,
    fullName: postedBy.fullName,
    email: postedBy.email,
    groups: postedBy.groups
  };

  const avatarLetters = getAvatarLetters(user.fullName || user.email);
  const workflowType = processDetails?.definition.name;

  // TODO: fix this to use constants from dashboard package.
  // Currently using constants from dashboard package is giving error.
  // 'Can't use before initialisation'
  const isDashboardMention = workflowType === "report_dashboard";
  const isReportMention = workflowType === "report_workflow";

  return (
    <Stack
      data-testid="mention-list-item"
      key={`${mentionId}`}
      direction="vertical"
      justify="center"
      align="start"
      gap="16"
      dangerouslySetClassName={notificationsListItemCustomStyles}
    >
      <Stack
        direction="horizontal"
        justify="space-between"
        align="center"
        width="100%"
      >
        {processDetailsStatus === "loading" ? (
          <Loader size="extra-small" />
        ) : (
          <MentionsPath
            workflowFamily={
              isDashboardMention || isReportMention
                ? processDetails?.workflowFamily || []
                : []
            }
            isDashboardMention={isDashboardMention}
            isReportMention={isReportMention}
            processName={process.processName}
            workflowType={workflowType}
            taskName={task?.taskName || null}
            fieldName={field?.name || null}
          />
        )}
        <Tooltip content={moment(createdAt).format(ABSOLUTE_DATE_TIME_FORMAT)}>
          <Typography
            as="div"
            color={TypographyColors.NEUTRAL_600}
            variant={TypographyVariants.LABEL_SM}
            className={css({
              width: "6rem",
              textAlign: "right"
            })}
          >
            {formatDistanceToNowStrict(parseISO(createdAt), {
              addSuffix: true
            })}
          </Typography>
        </Tooltip>
      </Stack>
      <Stack direction="horizontal" align="center" gap="12" width="100%">
        <div>
          <UserDetailsTooltip placement="top" user={user}>
            <div className={avatarWrapperClassName} aria-label={avatarLetters}>
              <Avatar
                aria-label={userDisplay}
                color={AvatarColors.DARK}
                size={AvatarSizes.EXTRA_LARGE}
              >
                {avatarLetters}
              </Avatar>
            </div>
          </UserDetailsTooltip>
        </div>
        <Stack width="100%">
          <Stack direction="horizontal" justify="space-between" align="center">
            <Stack direction="horizontal" align="center" gap="4">
              {!isRead && (
                <div
                  className={css({
                    borderRadius: "50%",
                    width: "0.375rem",
                    height: "0.375rem",
                    backgroundColor: "var(--colors-neutral-800)"
                  })}
                />
              )}
              <Typography
                variant={TypographyVariants.LABEL_SM_BOLD}
                color={TypographyColors.NEUTRAL_800}
              >
                {userDisplay.length > 20 ? (
                  <Tooltip content={userDisplay}>
                    <span>{userDisplay.substring(0, 20) + "..."}</span>
                  </Tooltip>
                ) : (
                  userDisplay
                )}
                {"  "}
                {mentionedYouText}
              </Typography>
            </Stack>
          </Stack>
          <Typography
            as="div"
            color={TypographyColors.NEUTRAL_700}
            variant={TypographyVariants.LABEL_SM}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(
                  transformedMessage.length > 120
                    ? transformedMessage.slice(0, 120) + "..."
                    : transformedMessage
                )
              }}
            />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { MentionItem };

const avatarWrapperClassName = css`
  height: min-content;
  margin-top: 0.125rem;
  &:focus {
    outline: 2px solid var(--colors-brand-400);
    outline-offset: 2px;
    border-radius: 50%;
  }
`;
