import { useSelector } from "react-redux";

import { DropdownMenuItem } from "@certa/catalyst";
import { getIntlBody } from "main/src/js/_helpers/intl-helpers";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { isSupplierUser } from "main/src/modules/common/selectors";

import { WrapperWithIcon } from "../common";

const openFooterLink = (url: string) => () => window.open(url, "_blank");

export const SupportLinks = () => {
  const isSupplier = useSelector(isSupplierUser);
  const footerLinks = useSelector(
    (state: ReduxState) => state?.config?.custom_ui_labels?.header_links || []
  );

  if (isSupplier) return null;

  if (footerLinks && footerLinks.length) {
    return footerLinks.map((links: any) => (
      <DropdownMenuItem
        key={links.label}
        onSelect={openFooterLink(links.url)}
        value={getIntlBody(links, "label")}
      >
        <WrapperWithIcon>{getIntlBody(links, "label")}</WrapperWithIcon>
      </DropdownMenuItem>
    ));
  }

  return null;
};
