// App

import type { StudioTemplateTypes, HashMap } from "@certa/types";
import type { Operation } from "fast-json-patch";
import type { AppSettingsRaw } from "./appSettings.types";

export type CreateStudioAppParams = {
  name: string;
  description?: string;
  workflow_id: number;
};

export type UpdateStudioAppParams = {
  id: number;
  name?: string;
  description?: string;
};

export type DeleteStudioAppParams = {
  id: number;
};

export type StudioAppResponse = {
  id: number;
  name: string;
  description?: string;
  workflow_id: number;
};

export type StudioApp = {
  id: number;
  name: string;
  description?: string;
  workflowId: number;
};

// Process

export type CreateStudioProcessParams = {
  name: string;
  description?: string;
  workflow_kind_tag: string;
  package_id: number;
};

export type UpdateStudioProcessParams = {
  processId: number;
  name?: string;
  description?: string;
};

export type UpdateStudioProcessResponse = {
  id: number;
  name: string;
  description: string;
};

export type StudioProcessResponse = {
  id: number;
  name: string;
  description?: string;
  workflow_kind_tag: string;
  created_by?: UserResponse;
};

export type StudioProcess = {
  id: number;
  name: string;
  description?: string;
  workflowKindTag: string;
  createdBy?: User;
};

// Branch/Savepoint
export type StudioBranchResponse = {
  id: number;
  name: string;
  created_at: string;
};

export type StudioBranch = {
  id: number;
  name: string;
  createdAt: string;
};

export type StudioBranchSavePointResponse = {
  id: number;
  title: string;
  description?: string;
  stage: string;
  created_at: string;
  updated_at: string;
  stage_set_by: UserResponse;
};

export type StudioBranchSavePoint = {
  id: number;
  title: string;
  description?: string;
  stage: string;
  createdAt: Date;
  updatedAt: Date;
  stageSetBy: User | null;
};

export type StudioBranchSavepointPayload = {
  savepointId: number;
  title: string;
  description?: string;
};

export type UpdateStudioBranchSavepointResponse = {
  id: number;
  title: string;
  description?: string;
};

export type StudioProcessBySavePointResponse = {
  id: number; // Process Savepoint
  process: StudioProcessResponse;
};

export type StudioProcessBySavePoint = {
  id: number;
  process: StudioProcess;
};

export type AddStudioProcessToBranchParams = {
  branch_id: number;
  process_id: number;
};

export type UpdateDeployableJSONParams = {
  branch: number;
  process: number;
  data: HashMap;
};

// CRUD

export type GetStudioProcessMetaJSONBySavepointParams = {
  process_savepoint_id: number;
  path?: string; // when path is not passed, entire Meta JSON will be requested
};

export type GetStudioProcessDeployableJSONParams = {
  branch: number;
  process: number;
  path?: string; // when path is not passed, entire Meta JSON will be requested
};

export type GetStudioProcessMetaJSONResponse = {
  meta_json: HashMap;
};

export type GetStudioProcessDeployableJSONResponse = {
  deployable_json: HashMap;
};

export type UpdateStudioProcessMetaJSONParams = {
  branch: number;
  process: number;
  data: MetaJSONChanges;
};

export type MetaJSONChanges = {
  create_or_update?: MetaJSONPayload[];
  delete?: { path: string }[];
};

export type MetaJSONPayload = {
  // when path is missing, the data replaces the entire document,
  // rather than doing a selective update on the existing one, based
  // the path.
  path: string;
  data: any;
};

// Possibly generic

export type UserResponse = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};
export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
};

// Deployment logs

export type SavepointDeploymentLogResponse = {
  id: string;
  status: string;
  errors: string;
  updated_at: string;
  platform_settings_errors: AppSettingsDeploymentError;
  stage_timestamps: HashMap<{
    start_time: number;
    end_time: number;
    duration: number; // -1 = not evaluated yet
  }>;
};

export type SavepointDeploymentLog = {
  id: string;
  status: string;
  errors: string;
  updatedAt: string;
  appSettingsErrors: AppSettingErrorSaveLog;
  timestamps: HashMap<{
    startTime: number;
    endTime: number;
    duration: number; // -1 = not evaluated yet
  }>;
};

export type ProcessDeploymentLogResponse = {
  id: string;
  status: string;
  errors: string;
  config_errors_warnings: {
    errors: Record<string, string[]>;
    warnings: Record<string, string[]>;
  };
  warnings: string;
  workflow_kind_tag: string;
  branch_log: string;
  updated_at: string;
  stage_timestamps: HashMap<{
    start_time: number;
    end_time: number;
    duration: number; // -1 = not evaluated yet
  }>;
};

export type ProcessDeploymentLog = {
  id: string;
  status: string;
  errors: string;
  warnings: string;
  workflowKindTag: string;
  branchLogId: string;
  updatedAt: string;
  configErrorsWarnings: {
    errors: Record<string, string[]>;
    warnings: Record<string, string[]>;
  };
  timestamps: HashMap<{
    startTime: number;
    endTime: number;
    duration: number; // -1 = not evaluated yet
  }>;
};

export enum ErrorLocation {
  KIND = "kind",
  STEPGROUP = "stepgroup",
  STEP = "step",
  RULE = "rule"
}

// TODO: This might be divided into individual types when API is done
// KindDeploymentErrorMeta | SwimlaneDeploymentErrorMeta | ...
export type DeploymentErrorMetaRaw = {
  error_location: ErrorLocation;
  process: number;
  stepgroup?: string;
  stepgroup_name?: string;
  step?: string;
  step_name?: string;
  field?: string;
  field_name?: string;
  ruleType?: string;
  ruleIndex?: number;
  property?: string;
};

export type DeploymentErrorDataRaw = {
  error_code: string;
  error_msg: string;
  error_description: string;
  meta: DeploymentErrorMetaRaw;
};

// TODO: same here as DeploymentErrorMetaRaw
export type DeploymentErrorMeta = {
  errorLocation: ErrorLocation;
  processId: number;
  swimlaneTag?: string;
  swimlaneName?: string;
  stepTag?: string;
  stepName?: string;
  fieldTag?: string;
  fieldName?: string;
  ruleType?: string;
};

export type DeploymentErrorData = {
  errorCode: string;
  errorMsg: string;
  errorDescription: string;
  meta: DeploymentErrorMeta;
};

export type StudioPermissionsResponse = {
  can_access_studio: boolean;
  can_deploy_to_production: boolean;
  can_deploy_to_staging: boolean;
  can_deploy_to_qa: boolean;
  can_deploy_to_development: boolean;
  can_edit_branch: boolean;
  can_merge_branch: boolean;
  can_edit_package: boolean;
  can_edit_process: boolean;
  studio_superuser_perm: boolean;
};

export type StudioPermissions = {
  canAccessStudio: boolean;
  canDeployToProduction: boolean;
  canDeployToStaging: boolean;
  canDeployToQA: boolean;
  canDeployToDevelopment: boolean;
  canEditBranch: boolean;
  canMergeBranch: boolean;
  canEditPackage: boolean;
  canEditProcess: boolean;
  isStudioSuperuser: boolean;
};

export type GetWFUpdateInProgressResponse = {
  enable_update_in_progress: boolean;
};

export type CreateBranchSavepointResponse = {
  id: number;
  branch_id: number;
  title: string;
  description: string;
};

export type StudioCentralTemplate = {
  id: number;
  uid: string;
  name: string;
  description: string;
  type: StudioTemplateTypes;
  integrationType?: TemplateIntegrationTypes;
  category?: string;
  source?: string;
  author?: string;
  templateStatus?: string;
  clientNames?: string[];
  code: string;
};

export enum TemplateIntegrationTypes {
  STEP = "step",
  SWIMLANE = "swimlane",
  MODULE = "module",
  PROCESSES = "processes"
}

export enum TemplateStatusTypes {
  DRAFT = "Draft",
  PUBLIC = "Public",
  PRIVATE = "Private",
  RETIRED = "Retired",
  CLIENT_SPECIFIC = "Client/Partner Specific"
}

export type TransactionalChange = {
  actor: {
    id: string;
    email: string;
  };
  timestamp: string;
  type: TransactionalChangeTypes;
  data: // JSONPatch operations
  Operation[];
  //TODO: Branch merge operations & Process Intialization, other events
};

export enum TransactionalChangeTypes {
  JSONPATCH = "jsonpatch",
  BRANCH_MERGE = "branch_merge"
}

export type AppSettingsDeploymentError = Record<
  keyof AppSettingsRaw,
  Record<string, Array<{ [key: string]: string | string[] }>>
>;

export type AppSettingErrorSaveLog = {
  group?: AppsettingsErrorDetails;
  emailTemplate?: AppsettingsErrorDetails;
  region?: AppsettingsErrorDetails;
  extraJSON?: AppsettingsErrorDetails;
  processKind?: AppsettingsErrorDetails;
  processStatus?: AppsettingsErrorDetails;
  processPDFTemplate?: AppsettingsErrorDetails;
  alertCategory?: AppsettingsErrorDetails;
  commentFlagOption?: AppsettingsErrorDetails;
  feExtraMetaData?: AppsettingsErrorDetails;
};

export type AppsettingsErrorDetails = Record<string, string[]>;
