import type { FC } from "react";
import type { DataCellFormatter, DataPlusUndefined } from "../types";
import { Cell } from "./Cell";
import {
  SUB_TOTAL_LABEL,
  AVERAGE_LABEL,
  GRAND_AVERAGE_LABEL,
  GRAND_SUB_TOTAL_LABEL
} from "../constants";
import { INDEX_ZERO, LENGTH_ZERO } from "@certa/common";
import type { ColumnGroupType, ColumnType, ColumnsType } from "antd/lib/table";

type ColumnSubTotalAndAvg = (
  | {
      [x: string]: string | number;
      grandSubTotal: number;
    }
  | {
      [x: string]: string | number;
      grandAverage: number;
    }
)[];

type SummaryProps = {
  cellBaseColors?: string[];
  lastRowIndex: number;
  minValue: number;
  minMaxDiff: number;
  yAxisKeys: string[];
  dataCellFormatter: DataCellFormatter;
  columnSubTotalAndAvg: ColumnSubTotalAndAvg | {}[];
  dataColumns: ColumnsType<DataPlusUndefined>;
};

type ColumnNode<RecordType> =
  | ColumnType<RecordType>
  | ColumnGroupType<RecordType>;

export const Summary: FC<SummaryProps> = props => {
  const {
    cellBaseColors,
    dataCellFormatter,
    columnSubTotalAndAvg,
    yAxisKeys,
    lastRowIndex,
    minValue,
    minMaxDiff,
    dataColumns
  } = props;
  const subTotalRow = columnSubTotalAndAvg[0] as ColumnSubTotalAndAvg[0];
  const averageRow = columnSubTotalAndAvg[1] as ColumnSubTotalAndAvg[1];
  const yAxisKey = yAxisKeys[INDEX_ZERO];

  const extractDataIndexValuesRecursive = (
    obj: ColumnNode<DataPlusUndefined>,
    result: string[] = []
  ) => {
    if (
      "children" in obj &&
      obj.children &&
      obj.children.length > LENGTH_ZERO
    ) {
      obj.children.forEach((child: ColumnNode<DataPlusUndefined>) =>
        extractDataIndexValuesRecursive(child, result)
      );
    } else if ("dataIndex" in obj && obj.dataIndex) {
      result.push(...(obj.dataIndex as string[]));
    }
    return result;
  };

  const extractDataIndexValuesFromNestedObject = (
    nestedObject: ColumnsType<DataPlusUndefined>
  ): string[] => {
    return nestedObject.reduce((acc: string[], item) => {
      return acc.concat(extractDataIndexValuesRecursive(item));
    }, []);
  };

  const heatMapXAxisColumnKeysOrdered = [
    ...extractDataIndexValuesFromNestedObject(dataColumns),
    GRAND_SUB_TOTAL_LABEL,
    GRAND_AVERAGE_LABEL
  ];

  if (!subTotalRow || !averageRow) return null;
  return (
    <>
      <tr data-row-key={SUB_TOTAL_LABEL}>
        <td className="ant-table-cell" colSpan={yAxisKeys.length}>
          {subTotalRow[yAxisKey]}
        </td>
        {heatMapXAxisColumnKeysOrdered
          .filter(columnHeading => !yAxisKeys.includes(columnHeading))
          .map(columnHeading => (
            <td key={columnHeading} className="ant-table-cell summary">
              <Cell
                cellBaseColors={cellBaseColors}
                dataCellFormatter={dataCellFormatter}
                cellValue={subTotalRow[columnHeading]}
                columnHeaderKey={subTotalRow[yAxisKey] as string}
                rowRecord={subTotalRow}
                rowIndex={lastRowIndex + 1}
                ratio={
                  ((subTotalRow[columnHeading] as number) - minValue) /
                  minMaxDiff
                }
              />
            </td>
          ))}
      </tr>
      <tr data-row-key={AVERAGE_LABEL}>
        <td className="ant-table-cell" colSpan={yAxisKeys.length}>
          {averageRow[yAxisKey]}
        </td>
        {heatMapXAxisColumnKeysOrdered
          .filter(columnHeading => !yAxisKeys.includes(columnHeading))
          .map(columnHeading => (
            <td
              colSpan={columnHeading === GRAND_AVERAGE_LABEL ? 2 : 1}
              key={columnHeading}
              className="ant-table-cell summary"
            >
              <Cell
                cellBaseColors={cellBaseColors}
                dataCellFormatter={dataCellFormatter}
                cellValue={averageRow[columnHeading]}
                columnHeaderKey={averageRow[yAxisKey] as string}
                rowRecord={averageRow}
                rowIndex={lastRowIndex + 1}
                ratio={
                  ((averageRow[columnHeading] as number) - minValue) /
                  minMaxDiff
                }
              />
            </td>
          ))}
      </tr>
    </>
  );
};
