import { css } from "emotion";
import type { FC } from "react";
import { Fragment } from "react";
import {
  Stack,
  Gravatar,
  ProcessLogo,
  Tooltip as AntTooltip,
  Text
} from "@certa/blocks/thanos";
import { ProcessTooltip } from "@certa/common";
import type { LCData } from "@certa/types";
import { Info, RelatedProcesses } from "@certa/icons";
import { useHistory } from "react-router-dom";
import { TooltipPlacements, Tooltip } from "@certa/catalyst";

export type ProcessLogoWithLCDataProps = {
  processId: number;
  processName: string;
  logo: string | null;
  lcData?: LCData[];
  disableLCDataPopup?: boolean;
  size?: string;
  status?: string;
  showProcessNameWithIcon?: boolean;
  shouldHideLogo?: boolean;
  hideNameOverlay?: boolean;
  processIndex?: number;
  showIconOverlay?: boolean;
  newDefaultProcessLogo?: boolean;
};

const ProcessLogoWithLCData: FC<ProcessLogoWithLCDataProps> = ({
  processId,
  processName,
  lcData,
  logo,
  disableLCDataPopup = false,
  size = "40",
  status,
  showProcessNameWithIcon = false,
  shouldHideLogo = false,
  newDefaultProcessLogo = false,
  hideNameOverlay = false,
  showIconOverlay = false,
  processIndex
}) => {
  const history = useHistory();

  const tooltipLcData = status
    ? [...(lcData || []), { label: "Status", value: status }]
    : lcData;

  const overlay = disableLCDataPopup ? null : (
    <ProcessTooltip
      lcData={tooltipLcData ?? []}
      id={processId}
      name={processName}
      logoURL={logo}
    />
  );

  const overlayClassName = css`
    max-width: 450px !important;
    .ant-tooltip-content {
      .ant-tooltip-arrow:before {
        background: var(--neutral-0);
      }
      .ant-tooltip-inner {
        background: var(--neutral-0);
        color: var(--neutral-100);
        box-shadow: 0px 2px 16px rgba(0, 22, 78, 0.1);
        border-radius: var(--big-border-radius);
        padding: 0;
      }
    }
  `;
  const onProcessRedirect = () => {
    if (hideNameOverlay) {
      history.push(`/process/${processId}`);
    }
  };
  return (
    <Fragment>
      {!shouldHideLogo && (
        <AntTooltip
          overlay={showProcessNameWithIcon && !showIconOverlay ? null : overlay}
          trigger={["click"]}
          placement="topLeft"
          overlayClassName={overlayClassName}
        >
          <Stack
            className={css`
              cursor: pointer;
              border-radius: var(--small-border-radius);
            `}
          >
            {logo ? (
              <ProcessLogo
                logoURL={logo}
                name={processName}
                width={Number(size)}
                height={Number(size)}
              />
            ) : newDefaultProcessLogo ? (
              <Stack
                align="center"
                justify="center"
                className={css`
                  width: ${size};
                  height: ${size};
                  padding: 12px;
                  border-radius: 50%;
                  background-color: #eaeaea;
                `}
              >
                <RelatedProcesses color="neutral-600" />
              </Stack>
            ) : (
              <Gravatar size={size} />
            )}
          </Stack>
        </AntTooltip>
      )}

      {showProcessNameWithIcon && (
        <Stack
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: var(--small-border-radius);
            > * + * {
              margin-inline-start: var(--s1) !important;
            }
            z-index: 1;
            &:hover {
              #task-details-process-${processId} {
                color: var(--brand) !important;
              }
            }
          `}
        >
          <>
            <Tooltip
              content={processName}
              placement={TooltipPlacements.BOTTOM}
              showArrow
            >
              <Text
                color="neutral-100"
                variant="p1-bold"
                as="span"
                align="center"
                useMaxWidth
                lineClamp={1}
                className={css`
                  word-break: break-all;
                  max-width: 400px;
                `}
                onClick={onProcessRedirect}
              >
                {processName}
              </Text>
            </Tooltip>
            <Text
              color="neutral-70"
              variant="p1-regular"
              as="span"
              align="center"
              ellipsis={0}
              useMaxWidth={true}
              className={css`
                font-size: var(--s3);
              `}
              onClick={onProcessRedirect}
            >
              {`(#${processId})`}
            </Text>
          </>
          <AntTooltip
            overlay={overlay}
            trigger={["hover"]}
            placement="topRight"
            overlayClassName={overlayClassName}
          >
            <Stack
              className={css`
                width: 16px;
                height: 16px;
                cursor: default;
              `}
            >
              <Info id={`task-details-process-${processId}`} color="teal" />
            </Stack>
          </AntTooltip>
        </Stack>
      )}
    </Fragment>
  );
};

export { ProcessLogoWithLCData };
