import React from "react";
import { Tooltip } from "antd";
import { gmtDate } from "../../utils/gmtDate";
import { fromNowWithFloorRounding } from "../../../modules/common/utils/timeUtils";
import { css, cx } from "emotion";

/**
 *
 * @deprecated
 */
export const Timestamp: React.FC<{ timestamp: string; fontSize?: string }> = ({
  timestamp,
  fontSize
}) => {
  return (
    <span
      className={cx([
        "text-light",
        css`
          font-size: ${fontSize ? fontSize : "80%"};
        `
      ])}
    >
      <Tooltip title={gmtDate(timestamp)}>
        <time>{fromNowWithFloorRounding(timestamp)}</time>
      </Tooltip>
    </span>
  );
};
