import {
  getDashboardsGenericRoute,
  getTasksRoute,
  getHomeRoute,
  getStudioGenericRoute
} from "../../utils/routes";
import { PermissionTypes } from "@certa/types";
import { Tasks, App, Chart, Bell, House, Search } from "@certa/icons";
import type { LinkProps } from "../Header/types";
import { AssignedTaskCount } from "../Header/AssignedTaskCount";

// TODO: INTL

export const headerNavLinks: LinkProps[] = [
  {
    route: "/search",
    icon: Search,
    label: "Search",
    hideForSuppliers: false
  },
  {
    route: getHomeRoute(),
    icon: House,
    label: "Home",
    hideForSuppliers: false
  },
  {
    route: getDashboardsGenericRoute(),
    icon: Chart,
    label: "Dashboards",
    alias: [/\/dashboard\/\d+/],
    hideForSuppliers: false,
    checkPermissions: [PermissionTypes.CAN_VIEW_DASHBOARD]
  },
  {
    route: getTasksRoute(),
    icon: Tasks,
    label: "Tasks",
    extra: AssignedTaskCount,
    hideForSuppliers: false,
    checkPermissions: [PermissionTypes.CAN_VIEW_TASKS]
  },
  {
    route: "/notif",
    icon: Bell,
    label: "Notifications",
    hideForSuppliers: false
  }
];

export const studioNavLink: LinkProps = {
  route: getStudioGenericRoute(),
  icon: App,
  label: "Studio",
  checkPermissions: [PermissionTypes.CAN_ACCESS_STUDIO],
  hideForSuppliers: true,
  target: "_blank"
};
