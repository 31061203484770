import { css } from "emotion";

export const HeaderWrapper = css({
  zIndex: 100,
  padding: "var(--s4) var(--s7)",
  height: "3.87rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});
