import React from "react";
import { WorkflowLink } from "./WorkflowLink";
import { Timestamp } from "../UTCTimestamp";

export const ActivityLogSimple = ({ item }) => {
  return (
    <div>
      <a className="text-medium text-base" href={"mailto:" + item.actor_email}>
        {item.actor_email}
      </a>{" "}
      {item.action_type}{" "}
      {/* If workflow is viewed then render link of workflow else the name*/}
      {item.action_type === "viewed" && item.object_type === "workflow" ? (
        <WorkflowLink item={item} />
      ) : (
        item.object_name
      )}
      {/* If workflow kind exists and type is not alert then render it */}
      {item.workflow_kind_name && item.object_type !== "alert"
        ? ` of ${item.workflow_kind_name}`
        : ""}
      {/* Don't render workflow link for alert and if workflow is viewed*/}
      {item.action_type !== "viewed" &&
      item.object_type !== "workflow" &&
      item.object_type !== "alert" ? (
        <>
          {` - `}
          <WorkflowLink item={item} />
        </>
      ) : null}
      <br />
      <Timestamp timestamp={item.timestamp.$date} />
    </div>
  );
};
