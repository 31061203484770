import { Gravatar, ProcessLogo } from "@certa/blocks/thanos";
import { LOGO_SIZE } from "./constants";
import { css } from "emotion";

export const WorkflowLogo = ({
  logoUrl,
  name,
  style
}: {
  logoUrl: string | null;
  name: string;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      className={css({
        width: LOGO_SIZE,
        flexShrink: 0,
        flexGrow: 0,
        height: LOGO_SIZE,
        marginRight: "var(--s2)",
        marginLeft: "var(--s2)"
      })}
      style={style}
    >
      {logoUrl ? (
        <ProcessLogo
          logoURL={logoUrl}
          name={name}
          height={LOGO_SIZE}
          width={LOGO_SIZE}
        />
      ) : (
        <Gravatar size={LOGO_SIZE.toString()} />
      )}
    </div>
  );
};
