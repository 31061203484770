import { useState, useEffect, useRef } from "react";
import type { KeyboardEvent } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { listBoxWrapperCSS, listItemCSS } from "./ListWrapper.styles";
import { Stack } from "@certa/blocks/thanos";
import { classNames } from "@certa/catalyst";
import { Loader } from "@certa/blocks";

type ListWrapperProps<T> = {
  options: T[];
  "aria-label": string;
  getKey: (item: T) => string;
  getLabel: (item: T) => string;
  onClick: (item: T, itemIndex: number) => void;
  render: (item: T) => JSX.Element;
  listItemClassName?: string;
  loadMore?: () => void;
  hasMore?: boolean;
  initialSelectedItemIndex?: number | null;
};

const ListWrapper = <T,>({
  options,
  "aria-label": ariaLabel,
  getKey,
  getLabel,
  onClick,
  render,
  listItemClassName,
  loadMore,
  hasMore,
  initialSelectedItemIndex = null
}: ListWrapperProps<T>) => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(
    initialSelectedItemIndex
  );
  const listBoxRef = useRef<HTMLDivElement>(null);
  const initialScrollingData = useRef({
    initialSelectedItemIndex,
    flag: false
  });

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault(); // Prevent default scroll behavior
      setFocusedIndex(prevIndex =>
        prevIndex === null || prevIndex >= options.length - 1
          ? prevIndex // Do not change focus if already at the last item, to avoid getting stuck in loop
          : prevIndex + 1
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault(); // Prevent default scroll behavior
      setFocusedIndex(prevIndex =>
        prevIndex === null || prevIndex <= 0
          ? prevIndex // Do not change focus if already at the first item, to avoid getting stuck in loop
          : prevIndex - 1
      );
    } else if (event.key === "Enter" && focusedIndex !== null) {
      onClick(options[focusedIndex], focusedIndex);
    } else if (event.key === "Tab") {
      setFocusedIndex(null); // Focus on the tabbed element and unfocus from list
      if (listBoxRef.current) {
        // Move focus to the next or previous focusable element
        const focusableElements = Array.from(
          document.querySelectorAll<HTMLElement>(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
          )
        );
        const currentIndex = focusableElements.indexOf(listBoxRef.current);
        if (event.shiftKey) {
          // Shift + Tab: Move focus to the previous focusable element
          if (currentIndex > 0) {
            focusableElements[currentIndex - 1].focus();
            event.preventDefault();
          }
        } else {
          // Tab: Move focus to the next focusable element
          if (
            currentIndex > -1 &&
            currentIndex < focusableElements.length - 1
          ) {
            focusableElements[currentIndex + 1].focus();
            event.preventDefault();
          }
        }
      }
    }
  };

  useEffect(() => {
    const getFocusedListItem = (idx: number | null) => {
      if (idx === null || !listBoxRef.current) return undefined;

      return listBoxRef.current?.querySelector<HTMLElement>(
        `[data-index='${idx}']`
      );
    };

    // Scroll to the initial selected item
    if (!initialScrollingData.current.flag) {
      initialScrollingData.current.flag = true;
      getFocusedListItem(
        initialScrollingData.current.initialSelectedItemIndex
      )?.scrollIntoView({
        block: "nearest"
      });
      return;
    }
    getFocusedListItem(focusedIndex)?.focus();
  }, [focusedIndex]);

  // Todo: when item is clicked then focus ring should come
  const handleClick = (item: T, index: number) => {
    onClick(item, index);
    if (focusedIndex !== index) {
      setFocusedIndex(index);
    }
  };

  const items = options.map((item, index) => (
    <Stack
      key={getKey(item) || index}
      role="listitem"
      aria-label={getLabel(item)}
      onMouseDown={e => e.preventDefault()} // Prevent default behavior
      onClick={() => handleClick(item, index)}
      data-index={index}
      tabIndex={focusedIndex === index ? 0 : -1}
      direction="vertical"
      gutter="s2 s00"
      className={classNames(
        listItemCSS({ focussed: index === focusedIndex }),
        listItemClassName
      )}
    >
      {render(item)}
    </Stack>
  ));

  return (
    <div
      ref={listBoxRef}
      aria-label={ariaLabel}
      role="list"
      onFocus={() => {
        if (focusedIndex == null) {
          setFocusedIndex(0);
        }
      }}
      onKeyDown={handleKeyDown}
      className={listBoxWrapperCSS}
      tabIndex={0}
    >
      {loadMore ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          useWindow={false}
          loader={
            <Stack align="center" justify="center">
              <Loader />
            </Stack>
          }
        >
          {items}
        </InfiniteScroll>
      ) : (
        items
      )}
    </div>
  );
};

export { ListWrapper };
