import { APIFetch, APIFetchV2 } from "../../js/utils/request";
import { handleResponse, authHeader } from "../../js/_helpers";

/**
 * @deprecated
 */
// TODO: Remove getUserMentions once the migration to new Comms microservice is completed
export function getUserMentions(page: number): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  return APIFetchV2(`user-mentions/?page=${page}`, requestOptions).then(
    handleResponse
  );
}

/**
 * @deprecated
 */
export function markAsRead(id: number) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  return APIFetch(`messages/${id}/mark_as_read/`, requestOptions);
}

/**
 * @deprecated
 */
export function getUnreadMessageCount(): Promise<{ count: number }> {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  return APIFetchV2(`unread-mentions-count/`, requestOptions).then(
    handleResponse
  );
}
