import { APIFetchV2, RequestHeaders, APIFetch } from "@certa/network";
import { mentionListModelCreator } from "../models/mentions.models";

export const getUserMentions = (page: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(`user-mentions/?page=${page || 1}`, requestOptions).then(
    mentionListModelCreator
  );
};

export const markMentionAsRead = (id: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch(`messages/${id}/mark_as_read/`, requestOptions);
};

export const getUnReadMentionCount = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(`unread-mentions-count/`, requestOptions).then(
    (response: any): number => response?.count || 0
  );
};
