import { useCallback, useState } from "react";
import { isFirstOption, getTabbaleElements } from "./GlobalSelect.utils";
import type { SelectInstance, GroupBase } from "react-select";
import type { SelectOption } from "./GlobalSelect.types";

type KeyDownHandlerType = {
  filteredOptions: SelectOption[];
  selectRef: React.MutableRefObject<
    SelectInstance<SelectOption, boolean, GroupBase<SelectOption>> | undefined
  >;
  handleReturnKey: () => void;
  menuIsOpen: boolean;
};

export function useKeyDownHandler({
  filteredOptions,
  selectRef,
  handleReturnKey,
  menuIsOpen
}: KeyDownHandlerType) {
  const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(-1);

  // React select currently doesn't allow a way to not select first option
  // We want user to navigate to advanced search when pressing entry key, so
  // first option should not be selected before user presses up/down key. We cannot
  // specifically do it, but we do this by removing focus styling and preventing
  // key press events when user hasn't yet started pressing up/down keys
  const [hasUserStartedSelectingOption, setHasUserStartedSelectingOption] =
    useState(false);

  const handleKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      const sequentialTabbableElements = getTabbaleElements();
      const currentElementIndex = sequentialTabbableElements.findIndex(
        element =>
          !hasUserStartedSelectingOption
            ? currentFocusIndex !== -1 && element.tabIndex === currentFocusIndex
            : element.classList.contains("global-select__option")
      );
      const currentElement = sequentialTabbableElements[currentElementIndex];

      if (
        (evt.key === "ArrowDown" || evt.key === "ArrowUp") &&
        !hasUserStartedSelectingOption
      ) {
        evt.preventDefault();

        setCurrentFocusIndex(-1);
        setHasUserStartedSelectingOption(true);
      } else if (evt.key === "Escape" && currentElement) {
        evt.preventDefault();

        setCurrentFocusIndex(-1);
        setHasUserStartedSelectingOption(false);
      } else if (
        (evt.key === "Enter" || evt.key === " ") &&
        currentElement &&
        !hasUserStartedSelectingOption
      ) {
        // Pressing enter triggers click for focussed element
        evt.preventDefault();
        currentElement.click();
      } else if (
        evt.key === "Tab" &&
        // Below conditions prevent search input from trapping focus
        // when user is navigating from other elements on the page
        // If menu isn't open or there are no sequential tabbable elements
        // then allow default tab behaviour
        menuIsOpen &&
        sequentialTabbableElements.length > 0
      ) {
        if (!evt.shiftKey) {
          const nextElementIndex = Math.min(
            currentElementIndex + 1,
            sequentialTabbableElements.length - 1
          );
          const nextElement = sequentialTabbableElements[nextElementIndex];

          if (
            nextElementIndex === sequentialTabbableElements.length - 1 &&
            currentElementIndex === sequentialTabbableElements.length - 1
          ) {
            evt.preventDefault();
            setCurrentFocusIndex(-1);
            setHasUserStartedSelectingOption(false);
          } else if (isFirstOption(nextElement)) {
            evt.preventDefault();
            setHasUserStartedSelectingOption(true);
            setCurrentFocusIndex(-1);
          } else if (nextElement?.tabIndex !== undefined) {
            evt.preventDefault();
            setHasUserStartedSelectingOption(false);
            setCurrentFocusIndex(nextElement.tabIndex);
          }
        } else {
          const nextElementIndex = Math.max(currentElementIndex - 1, -1);
          const nextElement = sequentialTabbableElements[nextElementIndex];

          if (nextElementIndex === -1 && currentElementIndex === 0) {
            // Focus back to the input
            evt.preventDefault();
            setCurrentFocusIndex(-1);
            setHasUserStartedSelectingOption(false);
          } else if (nextElementIndex === -1 && currentElementIndex === -1) {
            // Loop back to the last element
            evt.preventDefault();
            setCurrentFocusIndex(sequentialTabbableElements.length - 1);
            setHasUserStartedSelectingOption(false);
          } else if (isFirstOption(nextElement)) {
            evt.preventDefault();
            setHasUserStartedSelectingOption(true);
            setCurrentFocusIndex(-1);
          } else if (nextElement?.tabIndex !== undefined) {
            evt.preventDefault();
            setHasUserStartedSelectingOption(false);
            setCurrentFocusIndex(nextElement.tabIndex);
          }
        }
      }

      // To prevent keyboard navigation from looping (selecting) the
      // list when first and last options are focused as it is not
      // scrolling to the focused option
      if (
        evt.key === "ArrowUp" &&
        selectRef.current?.state.focusedOption?.value ===
          filteredOptions[0]?.value
      ) {
        evt.preventDefault();
        // Allow going back to input to press enter key
        setHasUserStartedSelectingOption(false);
      } else if (
        evt.key === "ArrowDown" &&
        selectRef.current?.state.focusedOption?.value ===
          filteredOptions[filteredOptions.length - 1]?.value
      ) {
        evt.preventDefault();
      } else if (
        evt.key === "Enter" &&
        !hasUserStartedSelectingOption &&
        !currentElement
      ) {
        evt.preventDefault();
        handleReturnKey();
      }
    },
    [
      selectRef,
      filteredOptions,
      handleReturnKey,
      hasUserStartedSelectingOption,
      currentFocusIndex,
      menuIsOpen
    ]
  );

  return {
    handleKeyDown,
    currentFocusIndex,
    setCurrentFocusIndex,
    hasUserStartedSelectingOption
  };
}
