import { groupBy, mapValues } from "lodash-es";
import type { ActivityLogModel, ActivityLogModelType } from "@certa/queries";
import type { InfiniteData } from "react-query";

function nestedGroupBy(seq: any[], keys: any[]): any {
  if (!keys.length) return seq;
  const first = keys[0];
  const rest = keys.slice(1);
  return mapValues(groupBy(seq, first), function (value) {
    return nestedGroupBy(value, rest);
  });
}

export const groupActivityLogs = (data: Array<ActivityLogModelType>) => {
  return nestedGroupBy(data, [
    "requestId",
    (item: any) => {
      if (item.isCreatedByBot) {
        return "activityLogsByBot";
      } else {
        return "activityLog";
      }
    }
  ]);
};

export const activityLogResultsList = (
  data: InfiniteData<ActivityLogModel> | undefined,
  startDate: string | undefined
) => {
  return data
    ? data?.pages?.reduce(
        (acc: ActivityLogModel["results"], pagesData: ActivityLogModel) => {
          return [
            ...acc,
            ...pagesData.results?.filter(
              (activityLog: ActivityLogModelType) =>
                !startDate ||
                new Date(activityLog.timestamp.$date) > new Date(startDate)
            )
          ];
        },
        []
      )
    : [];
};

export function getGroupActivityForBotOnly(
  activityLogInit: ActivityLogModelType[],
  activityLogsByBotInit: ActivityLogModelType[]
) {
  const activityLog = [...activityLogInit];
  const activityLogsByBot = [...activityLogsByBotInit];
  const hasNoHumanActivity = !activityLog.length;
  // human activity caused another automatic activities
  const hasAutoActivity = !!(activityLog.length && activityLog.length > 1);

  if (hasNoHumanActivity && activityLogsByBot[0]) {
    // lets pull one record out from bot activity and show as parent
    activityLog.push(activityLogsByBot[0]);
    activityLogsByBot.shift();
  }

  if (hasAutoActivity) {
    return {
      activityLog: [activityLog[activityLog.length - 1]],
      activityLogsByBot: [...activityLog.slice(0, -1), ...activityLogsByBot],
      hasAutoActivity
    };
  }
  return { activityLog, activityLogsByBot };
}

type GetIconAndColorArgs = {
  actionType: string;
  actionCode: string;
  objectType: string;
};
export const getIconAndColor = ({
  actionType,
  actionCode,
  objectType
}: GetIconAndColorArgs): [string, string] => {
  let icon = "panorama_fish_eye";
  let color = "blue";
  if (actionType === "viewed") {
    icon = "remove_red_eye";
  } else if (actionType === "submitted") {
    icon = "check_circle_outline";
    color = "green";
  } else if (objectType === "email") {
    icon = "email";
    if (actionType === "queued") {
      color = "gray";
    } else if (actionType === "rejected") {
      color = "red";
    } else if (actionType === "delivered") {
      color = "green";
    }
  } else if (actionType === "approved") {
    icon = "check_circle_outline";
    color = "green";
  } else if (actionType === "undo") {
    icon = "restore";
    color = "orange";
  } else if (actionCode === "alert_created") {
    icon = "alarm_add";
    color = "red";
  } else if (actionCode === "alert_dismissed") {
    icon = "alarm_off";
    color = "green";
  }
  return [icon, color];
};
