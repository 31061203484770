import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { Tabs as AntTabs } from "antd";
import type { CountBadgeProps } from "../Badges/CountBadge";
import { CountBadge } from "../Badges/CountBadge";
import { Text } from "../Typography/Text";
import { Stack } from "../Stack";
import type { IconProps } from "@certa/icons";
import type { TabPaneProps, TabsProps } from "antd/lib/tabs";
import { TYPOGRAPHY_VARIANTS } from "../../constants";
import { css } from "@emotion/core";

type TabPane = Omit<TabPaneProps, "tab" | "closable"> & {
  label: string | React.ReactNode;
  icon?: React.ComponentType<IconProps>;
  countBadgeProps?: CountBadgeProps;
};

type TabProps = TabsProps & {
  tabs: TabPane[];
  fullContainerWidth?: boolean;
};

const CustomTabs: FC<TabProps> = props => {
  const { tabs, ...tabProps } = props;
  return (
    <AntTabs {...tabProps}>
      {tabs.map(tabOption => {
        const {
          icon: Icon,
          label,
          countBadgeProps,
          disabled,
          ...tabPaneProps
        } = tabOption;
        return (
          // eslint-disable-next-line react/jsx-key
          <AntTabs.TabPane
            {...tabPaneProps}
            disabled={disabled}
            tab={
              //TODO: using min-height here for Icon alignment here
              <Stack
                gap="s2"
                align="center"
                justify="center"
                style={{
                  minHeight: "19px",
                  fontSize: TYPOGRAPHY_VARIANTS["p1-medium"].fontSize
                }}
              >
                {Icon && <Icon autoSize />}
                <Text variant="p1-medium">{label}</Text>
                {countBadgeProps && (
                  <CountBadge
                    {...countBadgeProps}
                    color={disabled ? "neutral-50" : countBadgeProps.color}
                  />
                )}
              </Stack>
            }
            {...tabPaneProps}
          />
        );
      })}
    </AntTabs>
  );
};

const StyledAntTabs = styled(CustomTabs)`
  ${props =>
    props.fullContainerWidth
      ? css`
          width: 100% !important;
        `
      : ""}

  .ant-tabs-nav {
    display: block;
    //prevent box-shadow from cutting off
    margin: var(--s1) var(--s1) 0 var(--s1);
    > div:first-child {
      display: flex;
    }

    .ant-tabs-tab {
      color: var(--neutral-70);
      border-bottom: 3px solid rgba(0, 0, 0, 0);
      padding: var(--s2);
      margin: 0 var(--s3) 0 0;
      &:hover {
        color: var(--brand-15);
        border-bottom: 2px solid var(--brand-35);
      }
      ${props =>
        props.fullContainerWidth
          ? css`
              flex: 1;
            `
          : ""}
    }

    .ant-tabs-tab-disabled {
      opacity: 0.6;
      span {
        cursor: not-allowed;
      }
      &:hover {
        color: var(--neutral-70);
        border-bottom: 3px solid rgba(0, 0, 0, 0);
      }
    }

    .ant-tabs-tab-active {
      &:focus-visible {
        outline: none;
        border: none;
        border-radius: var(--s2);
        margin-bottom: var(--s1);
        box-shadow:
          0 0 0 1px #fff,
          0 0 0 3px var(--brand-15) !important;
        &:hover {
          border: none;
        }
      }
      color: var(--brand);
      &:hover {
        color: var(--brand);
        border-bottom: 3px solid rgba(0, 0, 0, 0);
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: var(--brand);
    height: 2px;
  }

  .ant-tabs-bar.ant-tabs-top-bar {
    border: none;
    margin: 0;
  }

  .ant-tabs-nav-wrap {
    margin: 0;
  }

  .ant-tabs-tab-btn-disabled {
    display: none;
  }
  .ant-tabs-nav-container-scrolling {
    padding-left: var(--s6);
  }
  .ant-tabs-tab-prev-icon path {
    d: path(
      "M4.584 9.467l4.147 4.146a1.333 1.333 0 002.012-.153 1.365 1.365 0 00-.167-1.764L6.878 8l3.734-3.734a1.332 1.332 0 00-.155-2.014 1.364 1.364 0 00-1.763.167l-4.11 4.114a2.079 2.079 0 000 2.935z"
    );
  }
  .ant-tabs-tab-next-icon path {
    d: path(
      "M11.416 9.467L7.27 13.613a1.333 1.333 0 01-2.012-.153 1.365 1.365 0 01.167-1.764L9.122 8 5.388 4.265a1.332 1.332 0 01.155-2.014 1.364 1.364 0 011.763.167l4.11 4.114a2.079 2.079 0 010 2.935z"
    );
  }
  .ant-tabs-tab-prev-icon {
    margin-left: var(--s2);
  }
  .ant-tabs-tab-next-icon,
  .ant-tabs-tab-prev-icon {
    svg {
      transform: scale(1.3);
    }
    path {
      transform: scale(70);
      fill: var(--brand-15);
    }
    background: var(--neutral-0);
    height: 29px;
    width: 29px;
    border-radius: 100%;
    filter: drop-shadow(0px 2px 4px rgba(0, 22, 78, 0.2));
    &:hover {
      path {
        fill: var(--brand);
      }
    }
  }
`;

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Tabs } from '@certa/catalyst';
 */
export const Tabs = StyledAntTabs;
