import { useEffect } from "react";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { AppRouter } from "../modules/router/AppRouter";
import { GlobalStyle } from "@certa/common";
import { ErrorBoundary } from "./components/common/ErrorBoundary";
import { IntlCapture } from "../modules/common/components/IntlCapture";
import { AsyncIntlProvider } from "../modules/common/components/AsyncIntlProvider";
import { store } from "../js/_helpers/store";
import { queryClient } from "@certa/queries/src/utils/utils";

export const App = () => {
  useEffect(() => {
    // Add JIRA widget if the env is UAT
    if (import.meta.env.REACT_APP_CERTA_ENV === "UAT") {
      const script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://thevetted.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-wkhsc1/b/45/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-UK&collectorId=5fcd3be7"
      );
      document.body.appendChild(script);
    }
  }, []);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <AsyncIntlProvider>
              <GlobalStyle />
              <AppRouter />
              <IntlCapture />
            </AsyncIntlProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </ErrorBoundary>
        </QueryClientProvider>
      </HelmetProvider>
    </Provider>
  );
};
