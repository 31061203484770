/**
 * Contains query hooks for filters
 */
import { useQuery } from "react-query";
import { filterServices } from "../services/filters.services";

export const useWorkflowStatuses = ({
  workflowKindId
}: {
  workflowKindId: number;
}) => {
  const query = useQuery({
    retry: false,
    queryKey: [`workkflowStatuses-${workflowKindId}`, workflowKindId],
    queryFn: () => filterServices.getStatusData(workflowKindId)
  });

  return query;
};
