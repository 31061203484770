import type { PermissionTypes } from "@certa/types";
import {
  getHomeRoute,
  getStudioGenericRoute,
  getAppListGenericRoute,
  getSearchRoute
} from "../../../..";
import type { LinkProps } from "../../Header/types";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

export const mixpanelHandler = (label: string) => {
  MixPanelActions.track(MixPanelEvents.headerEvents.TAB_CLICK, {
    tab: label
  });
};

export const handleMixPanelEventsForNav = (label: string) => {
  switch (label) {
    case "Search":
      MixPanelActions.track(MixPanelEvents.navbar.NAV_CLICK_SEARCH);
      break;
    case "Home":
      MixPanelActions.track(MixPanelEvents.navbar.NAV_CLICK_HOME);
      break;
    case "Dashboards":
      MixPanelActions.track(MixPanelEvents.navbar.NAV_CLICK_DASHBOARDS);
      break;
    case "Tasks":
      MixPanelActions.track(MixPanelEvents.navbar.NAV_CLICK_TASKS);
      break;
    case "Notifications":
      MixPanelActions.track(MixPanelEvents.navbar.NAV_CLICK_NOTIFICATIONS);
      break;
    case "Studio":
      MixPanelActions.track(MixPanelEvents.navbar.NAV_CLICK_STUDIO);
      break;
    case "Tableau":
      MixPanelActions.track(MixPanelEvents.navbar.NAV_CLICK_TABLEAU);
      break;
    case "Profile":
      MixPanelActions.track(MixPanelEvents.navbar.NAV_CLICK_PROFILE);
      break;
    case "View My Profile":
      MixPanelActions.track(MixPanelEvents.navbar.NAV_PROFILE_CLICK_MYPROFILE);
      break;
    case "My Scheduled Reports":
      MixPanelActions.track(
        MixPanelEvents.navbar.NAV_PROFILE_CLICK_SCHEDULED_REPORTS
      );
      break;
    case "Instance Settings":
      MixPanelActions.track(
        MixPanelEvents.navbar.NAV_PROFILE_CLICK_INSTANCE_SETTINGS
      );
      break;
    case "Impersonate Internal User":
      MixPanelActions.track(
        MixPanelEvents.navbar.NAV_PROFILE_VIEW_AS_CLICK_CERTA_USER
      );
      break;
    case "Impersonate External User":
      MixPanelActions.track(
        MixPanelEvents.navbar.NAV_PROFILE_VIEW_AS_CLICK_EXTERNAL_USER
      );
      break;

    default:
      break;
  }
};

/**
 * Checks the visibility of navigation links based on various conditions.
 * @param checkPermissions - A function that checks if the user has the required permissions.
 * @param isSupplierUser - A boolean indicating if the user is a supplier user.
 * @param isHomePageEnabled - A boolean indicating if the home page is enabled.
 * @param canViewStudio2 - A boolean indicating if the user can view Studio 2.
 * @param canViewTasks - A boolean indicating if the user can view tasks.
 * @returns A function that filters the navigation links based on the provided conditions.
 */
export const checkLinkVisibility = (
  checkPermissions: (permissions: PermissionTypes[]) => boolean,
  args: {
    isSupplierUser: boolean;
    isHomePageEnabled: boolean;
    canViewStudio2: boolean;
    canViewTasks: boolean;
  }
) => {
  const { isSupplierUser, isHomePageEnabled, canViewTasks, canViewStudio2 } =
    args;

  /**
   * Determines if a navigation link should be shown based on the route.
   */
  const showNavLink = (route: string) => {
    if (route === getHomeRoute()) return isHomePageEnabled;
    else if (route === getStudioGenericRoute()) return canViewStudio2;
    else if (route === getAppListGenericRoute()) return !canViewStudio2;
    else if (route === getSearchRoute()) return true;
    else return true;
  };

  /**
   * Filters the navigation links based on various conditions.
   * @param navLinks - An array of navigation links.
   * @returns The filtered navigation links.
   */
  return (navLinks: LinkProps[]) =>
    navLinks
      .filter(link => (isSupplierUser ? !link.hideForSuppliers : true))
      .filter(link => showNavLink(link.route))
      .filter(link =>
        link.checkPermissions && !(link.label === "Tasks" && !canViewTasks)
          ? checkPermissions(link.checkPermissions)
          : true
      );
};
