import { Stack } from "@certa/blocks/thanos";
import {
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst";
import type { ChartIdentifiers, ChartSorting } from "@certa/common";
import { ChevronDown, ChevronUp } from "@certa/icons";
import { css } from "emotion";

type CustomSorterProps = {
  sorting?: ChartSorting[];
  chartType?: ChartIdentifiers;
  handleSorterChange?: (sorter: ChartSorting) => void;
};

const SorterIcon = ({
  sorting,
  onClick
}: {
  sorting: ChartSorting["order"];
  onClick: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className={css({
        display: "flex",
        flexDirection: "column",
        cursor: "pointer"
      })}
    >
      <ChevronUp color={sorting === "ASC" ? "brand" : "neutral-70"} autoSize />
      <ChevronDown
        color={sorting === "DESC" ? "brand" : "neutral-70"}
        autoSize
      />
    </div>
  );
};

export const CustomSorter = ({
  sorting,
  chartType,
  handleSorterChange
}: CustomSorterProps) => {
  const isPieOrSpiderChart = ["PIE_CHART", "SPIDER_CHART"].includes(
    chartType ?? ""
  );

  if (!sorting || !handleSorterChange) {
    return null;
  }

  return (
    <>
      {sorting?.map(item => (
        <Stack
          key={item.tag}
          gap="s2"
          direction="horizontal"
          justify="center"
          align="center"
          gutter={isPieOrSpiderChart ? "s0" : "s0 s0 s0 s20"}
        >
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.NEUTRAL_600}
          >
            {item.label}
          </Typography>
          <SorterIcon
            onClick={() => handleSorterChange(item)}
            sorting={item.order}
          />
        </Stack>
      ))}
    </>
  );
};
