/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  DesignTheme,
  ProcessDetailPlaceholder,
  normalizeCSSClass
} from "@certa/blocks";
import { ProcessLogo, Stack, Text } from "@certa/blocks/thanos";
import type { LCData as LCDataPropTypes } from "@certa/types";
import { LCDataFormat } from "@certa/types";
import { LCData } from "@certa/common";
import { Divider } from "antd";

/**
 * Process Tooltip - https://www.notion.so/certa/Process-Tooltip-0148eca2698a47b697e773a3d900be42
 * @param param0
 * @returns
 */
const ProcessTooltip: FC<{
  id: number;
  name: string;
  lcData: LCDataPropTypes[];
  logoURL: string | null;
}> = ({ lcData, name, id, logoURL }) => {
  const intl = useIntl();
  const formattedLCData = useMemo(() => {
    const tag = lcData.find(lc => isTagTypeLC(lc)) || "";

    const tagList =
      tag && tag.value && typeof tag.value === "string"
        ? tag.value.split("~").filter(Boolean)
        : [];

    const genericLCData = lcData
      .filter(lc => isGenericTypeLC(lc))
      .filter(data => data.value || data.value === 0);

    return {
      genericLCData,
      tagList
    };
  }, [lcData]);

  return (
    <DesignTheme css={normalizeCSSClass}>
      <Stack
        gap="s4"
        direction="vertical"
        css={css`
          padding: var(--s6);
        `}
        data-testId="process-tooltip"
      >
        <Text variant="p2-bold-upper" color="neutral-70">
          {intl.formatMessage({
            id: "process.overview",
            defaultMessage: "Process Overview"
          })}
        </Text>
        <Stack
          gap="s3"
          align="flex-start"
          justify="flex-start"
          css={css`
            svg {
              min-width: 40px;
            }
          `}
        >
          {logoURL ? (
            <ProcessLogo logoURL={logoURL} name={name} />
          ) : (
            <ProcessDetailPlaceholder />
          )}
          <Stack gap="s0" direction="vertical">
            <Text
              variant="h2-bold"
              color="neutral-100"
              ellipsis={560} //show 50 characters before ellipsing
              useMaxWidth
            >
              {name}
            </Text>
            <Text as="span" variant="p1-regular" color="neutral-70">
              #{id}
            </Text>
            {formattedLCData.tagList && formattedLCData.tagList.length > 0 && (
              <TagList tagList={formattedLCData.tagList} />
            )}
          </Stack>
        </Stack>
        {formattedLCData.genericLCData.length > 0 && (
          <LCDataList genericLCData={formattedLCData.genericLCData} />
        )}
      </Stack>
    </DesignTheme>
  );
};

const TagList = ({ tagList }: { tagList?: string[] }) => {
  if (!tagList || tagList.length === 0) return null;
  return (
    <Stack
      css={css`
        padding: var(--s3) 0 0 0;
      `}
    >
      <Stack
        align="center"
        justify="flex-start"
        css={css`
          flex-wrap: wrap;
          gap: var(--s1);
        `}
      >
        {tagList.map(tag => (
          <Stack
            css={css`
              padding: var(--s1);
              border-radius: 3px;
              background: var(--neutral-20);
              max-width: max-content;
            `}
            key={tag}
          >
            <Stack gap="0" align="center" justify="center">
              <Text
                as="span"
                variant="p1-semibold"
                color="neutral-100"
                align="center"
              >
                {tag}
              </Text>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

type LCDataListProps = {
  genericLCData?: LCDataPropTypes[];
};

const LCDataList = ({ genericLCData }: LCDataListProps) => {
  if (!genericLCData || genericLCData.length === 0) return null;
  return (
    <React.Fragment>
      <Divider
        css={css`
          margin-bottom: 0;
        `}
      />
      <Stack
        gap="0"
        align="flex-start"
        justify="space-between"
        css={css`
          flex-wrap: wrap;
          margin-top: var(--s00);
        `}
      >
        {genericLCData.map(data => {
          return (
            <LCDataWithLabel
              label={data.label}
              value={data.value}
              format={data.format}
              key={data.label}
            />
          );
        })}
      </Stack>
    </React.Fragment>
  );
};

// TODO: See if we can make this generic afterwards
const LCDataWithLabel: FC<LCDataPropTypes> = ({
  value,
  label,
  format = LCDataFormat.DEFAULT
}) => {
  const lcComponent = <LCData value={value} label={label} format={format} />;

  return (
    <Stack
      css={css`
        width: 175px;
        padding: var(--s4) 0 0;
      `}
    >
      <Stack gap="0" direction="vertical">
        <Text variant="p1-bold" color="neutral-100">
          {label}
        </Text>
        {lcComponent}
      </Stack>
    </Stack>
  );
};

const isTagTypeLC = (lc: LCDataPropTypes) => {
  return (
    lc.format === LCDataFormat.TAGS ||
    (typeof lc.value === "string" && lc.value.includes("~"))
  );
};

const isGenericTypeLC = (lc: LCDataPropTypes) => {
  return (
    lc.format !== LCDataFormat.TAGS &&
    !(typeof lc.value === "string" && lc.value.includes("~"))
  );
};

export { ProcessTooltip, TagList, LCDataList, isTagTypeLC, isGenericTypeLC };
