import { useMemo } from "react";
import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { FEATURE_FLAGS } from "main/src/modules/common/constants";

export const useGetOrphanKinds = (): string[] => {
  const state = useSelector((state: ReduxState) => state);
  const featureToggles = lodashGet(state, "config.feature_toggles", {});
  const isLinkDelinkOrphanFeatureEnabled =
    featureToggles[FEATURE_FLAGS.ENABLE_WORKFLOW_LINK_DELINK_FEATURE] || false;

  const orphanKinds = useMemo(
    () =>
      isLinkDelinkOrphanFeatureEnabled
        ? lodashGet(state, "config.orphan_kinds", [])
        : [],
    [isLinkDelinkOrphanFeatureEnabled, state]
  );

  return orphanKinds;
};
