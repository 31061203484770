import type { ActivityLogModelType } from "../types";

type GroupedActivityLogType = {
  activityLog: ActivityLogModelType;
  activityLogsByBot: Array<ActivityLogModelType>;
};

export const groupActivityLogs = (data: Array<ActivityLogModelType>) =>
  data.reduce<Record<string, GroupedActivityLogType>>(
    (groupedActivityLogs, currentActivityLog) => {
      if (currentActivityLog.requestId) {
        const { requestId } = currentActivityLog;
        let activityLog = groupedActivityLogs[requestId]?.activityLog;
        const activityLogsByBot =
          groupedActivityLogs[requestId]?.activityLogsByBot || [];
        if (!activityLog) {
          activityLog = currentActivityLog;
        } else if (activityLog.isCreatedByBot) {
          activityLogsByBot.unshift(activityLog);
          activityLog = currentActivityLog;
        } else {
          activityLogsByBot.unshift(currentActivityLog);
        }
        groupedActivityLogs[requestId] = { activityLog, activityLogsByBot };
      } else {
        groupedActivityLogs[currentActivityLog.id] = {
          activityLog: currentActivityLog,
          activityLogsByBot: []
        };
      }
      return groupedActivityLogs;
    },
    {}
  );

type GetIconAndColorArgs = {
  actionType: string;
  actionCode: string;
  objectType: string;
};
export const getIconAndColor = ({
  actionType,
  actionCode,
  objectType
}: GetIconAndColorArgs): [string, string] => {
  let icon = "panorama_fish_eye";
  let color = "blue";
  if (actionType === "viewed") {
    icon = "remove_red_eye";
  } else if (actionType === "submitted") {
    icon = "check_circle_outline";
    color = "green";
  } else if (objectType === "email") {
    icon = "email";
    if (actionType === "queued") {
      color = "gray";
    } else if (actionType === "rejected") {
      color = "red";
    } else if (actionType === "delivered") {
      color = "green";
    }
  } else if (actionType === "approved") {
    icon = "check_circle_outline";
    color = "green";
  } else if (actionType === "undo") {
    icon = "restore";
    color = "orange";
  } else if (actionCode === "alert_created") {
    icon = "alarm_add";
    color = "red";
  } else if (actionCode === "alert_dismissed") {
    icon = "alarm_off";
    color = "green";
  }
  return [icon, color];
};
