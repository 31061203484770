import { colord } from "colord";

export const getBackgroundColor = (
  ratio: number,
  showInherit: boolean,
  getBackgroundColor?: string[]
) => {
  if (showInherit) return "inherit";
  else if (Array.isArray(getBackgroundColor)) {
    if (getBackgroundColor?.length === 1) {
      const opacity = colord(getBackgroundColor[0]).alpha();
      return colord(getBackgroundColor[0])
        .alpha(opacity * (ratio / 2 + 0.4))
        .toRgbString();
    } else if (getBackgroundColor.length === 2) {
      const color1 = colord(getBackgroundColor[0]).toRgb();
      const color2 = colord(getBackgroundColor[1]).toRgb();
      return `rgba(${Math.abs(
        color2.r * ratio - color1.r * (1 - ratio)
      )}, ${Math.abs(color2.g * ratio - color1.g * (1 - ratio))},  ${Math.abs(
        color2.b * ratio - color1.b * (1 - ratio)
      )},  ${Math.abs(color2.a * ratio - color1.a * (1 - ratio))})`;
    }
  }
  return `rgb(12, 160, 44, 1)`;
};
