import styled from "@emotion/styled";
import { Input as AntInput } from "antd";
import type { InputProps as AntInputProps } from "antd/lib/input";
import { getVariantStyles } from "../Typography/utils";

export type InputProps = {
  error?: boolean;
} & Omit<AntInputProps, "size">;

/**
 * TODO: Change prefix and suffix icon color to neutral-70 when no value in input
 */

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { TextInput } from '@certa/catalyst';
 */
export const Input = styled(AntInput)<InputProps>`
  && {
    font-family: var(--font-family);
    height: calc(var(--s10) + 2px);
    color: ${({ error }) => (error ? "var(--red)" : "var(--neutral-100)")};
    background-color: ${({ error }) =>
      error ? "var(--red-60)" : "var(--neutral-5)"};

    border-radius: var(--big-border-radius);
    box-shadow: none;
    transition: border 0s;
    line-height: var(--h3-regular-line-height);
    &:not(.ant-input-group-wrapper) {
      padding: 0 var(--s4);
    }
    &:focus {
      box-shadow: 0 0 0 2px
        ${({ error }) => (error ? "var(--red-40)" : "var(--brand-15)")};
    }
    &:not(.ant-input-group-wrapper) {
      border: 1px solid
        ${({ error }) => (error ? "var(--red)" : "var(--neutral-5)")};
    }

    &.ant-input,
    .ant-input {
      ${getVariantStyles("h3-regular", true)}
      font-family: var(--font-family) !important; // fix to global style override
      color: ${({ error }) => (error ? "var(--red)" : "var(--neutral-100)")};
      background-color: ${({ error }) =>
        error ? "var(--red-60)" : "var(--neutral-5)"};

      &::placeholder {
        ${getVariantStyles("h3-regular")}
        color: var(--neutral-70);
      }
    }
    .ant-input {
      border: none;
    }

    &.ant-input-disabled,
    &.ant-input-affix-wrapper-disabled {
      color: var(--neutral-70);
      background-color: var(--neutral-10);
      border: 1px solid var(--neutral-20);

      & .ant-input-disabled {
        color: var(--neutral-70);
        background-color: var(--neutral-10);
      }
    }

    .ant-input-prefix {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: var(--s2);
    }

    .ant-input-suffix {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: var(--s2);
    }

    &.ant-input-affix-wrapper {
      line-height: var(--h3-regular-line-height);
      &::before {
        content: unset;
      }
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: ${props =>
        !props.addonBefore &&
        (props.error
          ? "0 0 0 2px var(--red-40)"
          : "0 0 0 2px var(--brand-15)")};
    }

    .ant-input-group-addon {
      border: 1px solid var(--neutral-10);
      background: var(--neutral-10);
      color: var(--neutral-100);
      padding: 0;
      border-radius: var(--s2) 0 0 var(--s2);
      & + .ant-input-affix-wrapper-focused {
        &:focus-within {
          box-shadow: 0 0 0 2px
            ${({ error }) => (error ? "var(--red-40)" : "var(--brand-15)")};
        }
      }
    }
  }
`;
