import { APIFetchV2, RequestHeaders } from "@certa/network";
import {
  createUserProfileDataModel,
  getUserRecentActivityModal
} from "../models/user.model";
import type { UserRecentActivityParams } from "../types/user.types";

/**
 * While this is a generic service that can retrieve any field tag's answer
 * within any given workflow, currently this is only being used to
 * retrieve user's profile data from user's workflow. More precisely the
 * preferred_region. In future, we may need to expand and query for
 * multiple field tags (which will also need BE enhancement)
 * @param workflowId
 * @param fieldTag
 */
const getUserProfileData = (workflowId: number, fieldTag: string) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  return APIFetchV2(
    `responses/get-answer/?workflow_id=${workflowId}&field_tag=${fieldTag}`,
    requestOptions
  ).then(createUserProfileDataModel);
};

const getUserRecentActivity = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  return APIFetchV2<UserRecentActivityParams>(
    "user-recent-activity/",
    requestOptions
  ).then(getUserRecentActivityModal);
};

const updateUserRecentActivity = (params: UserRecentActivityParams) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };
  return APIFetchV2<UserRecentActivityParams>(
    "user-recent-activity/",
    requestOptions
  );
};

export const userServices = {
  getUserProfileData,
  getUserRecentActivity,
  updateUserRecentActivity
};
