import type { FC } from "react";
import React, { useMemo, useState } from "react";
import { css } from "emotion";
import {
  Dots,
  Messages,
  RelatedProcesses,
  Activity,
  Archive
} from "@certa/icons";
import { Stack } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";
import { useCommentsDrawerContext } from "@certa/comments";
import { CommentObjectTypes } from "@certa/types";
import { useHistory, useLocation } from "react-router-dom";
import { useProcessFamily } from "@certa/processfamily";
import {
  getHomeRoute,
  getProcessDetailRoute,
  getDashboardsGenericRoute
} from "../utils/routes";
import { useActivityLogOverlay } from "@certa/processdetails/src/sidebar/hooks/useActivityLogOverlay"; // TODO: check why not working as @certa/common
import { useProcessArchive } from "../hooks/useProcessArchive";
import type { ProcessActionTypes } from "../..";
import { WorkflowLevelPermissionType } from "@certa/queries/src/types/permissions.types";
import { useCheckLevelPermission, useWorkflowDetailsNewUX } from "../..";
import {
  ButtonSizes,
  ButtonTypes,
  Button,
  DropdownMenuItemTypes,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  AriaLive
} from "@certa/catalyst";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { WrapWithMigrationTooltip } from "./WrapWithMigrationTooltip";
import { Loader } from "@certa/blocks";
import { ActivityLogsDrawer } from "main/src/js/components/ActivityLogs";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";

// We display loader as an overlay
// to ensure that we have the actual width of menu
// from rendered options and there is not jitter
// when the menu is opened and loading is complete
const loaderContainerStyles = css({
  position: "absolute",
  zIndex: 10,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const ProcessActions: FC<{
  rowset: {
    id: number;
    name: string;
    rootProcess?: { id: number };
    isWorkflowBeingMigrated?: boolean;
  };
  refreshData: () => void;
  hiddenActions?: ProcessActionTypes[];
  handleProcessClick?: (actionName: string) => void;
}> = ({ rowset, refreshData, hiddenActions, handleProcessClick }) => {
  const {
    id: processId,
    name: taskName,
    rootProcess,
    isWorkflowBeingMigrated: isWorkflowBeingMigratedFromParams
  } = rowset;
  const history = useHistory();
  const location = useLocation();

  const onStepClick = (groupId: number, stepId: number, processId: number) =>
    history.push(
      `${getProcessDetailRoute(processId)}?group=${groupId}&step=${stepId}`
    );

  // In case migration info is not passed in props,
  // we need to fetch it from process details
  const {
    data: processDetails,
    isLoading: isProcessDetailsLoading,
    refetch: refreshProcessDetails
  } = useProcessDetails(processId, {
    enabled: false
  });

  const isWorkflowBeingMigrated =
    isWorkflowBeingMigratedFromParams ||
    processDetails?.isWorkflowBeingMigrated;

  const [showProcessFamily] = useProcessFamily({
    selectedProcessId: processId,
    rootProcessId: rootProcess?.id || null,
    isSelectedProcessMigrating: isWorkflowBeingMigrated
  });

  const { showComments } = useCommentsDrawerContext();

  const intl = useIntl();
  const isActivityLogsNewUIEnabled = useWorkflowDetailsNewUX();
  const { showActivityLogs } = useActivityLogOverlay(processId);
  const [isActivityLogsDrawerOpen, setIsActivityLogsDrawerOpen] =
    useState(false);

  const {
    canArchiveProcess,
    isLoading: isProcessPermissionLoading,
    handleArchive,
    refetch: processArchiveRefetch
  } = useProcessArchive(processId, refreshData, { enabled: false });
  const { status: canViewActivityLogStatus, refetch: checkLvlPermsRefetch } =
    useCheckLevelPermission({
      processId,
      checkFor: WorkflowLevelPermissionType.CAN_VIEW_ACTIVITY_LOG,
      config: { enabled: false }
    });

  const {
    shouldShowRelatedProcesses,
    shouldShowCommentsAction,
    shouldShowMetaActions
  } = useMemo(
    () => ({
      shouldShowRelatedProcesses: !hiddenActions?.includes("relatedProcesses"),
      shouldShowCommentsAction: !hiddenActions?.includes("comments"),
      shouldShowMetaActions: !hiddenActions?.includes("metaActions")
    }),
    [hiddenActions]
  );
  const mixPanelHandlers = {
    dashboardWFActions: () => {
      MixPanelActions.track(
        MixPanelEvents.dashboardEvernts.DASHBOARD_REPORT_CLICK_WFACTIONS
      );
    },
    homeClickComment: () => {
      MixPanelActions.track(MixPanelEvents.homepageEvents.HOME_CLICK_COMMENT);
    },
    homeWFActions: () => {
      MixPanelActions.track(MixPanelEvents.homepageEvents.HOME_CLICK_WFACTIONS);
    }
  };

  const handleProcessActionClick = () => {
    if (
      location.pathname === getDashboardsGenericRoute() ||
      location.pathname === getDashboardsGenericRoute()
    )
      mixPanelHandlers.dashboardWFActions();
  };

  const handleOnCommentsActionClick = () => {
    MixPanelActions.track(
      MixPanelEvents.searchPageEvents.ADVANCE_SEARCH_CLICK_CTA,
      {
        cta_label: "comments"
      }
    );
    if (location.pathname === getHomeRoute()) {
      mixPanelHandlers.homeClickComment();
    }
    showComments({
      objectId: processId,
      objectType: CommentObjectTypes.CONSOLIDATED,
      onStepClick,
      header: taskName
    });
  };

  const handleOnOpenChange = (open: boolean) => {
    if (open) {
      processArchiveRefetch();
      checkLvlPermsRefetch();
      // In case of ORM reports, workflow data is not returned by reports
      // API, so we need to fetch that data separately
      if (isWorkflowBeingMigratedFromParams === undefined) {
        refreshProcessDetails();
      }

      if (location.pathname === getHomeRoute()) {
        mixPanelHandlers.homeWFActions();
      }
    }
  };

  const onShowProcessFamily = () => {
    MixPanelActions.track(
      MixPanelEvents.searchPageEvents.ADVANCE_SEARCH_CLICK_CTA,
      {
        cta_label: "hierarchy"
      }
    );
    showProcessFamily();
  };

  const onHandleProcessClick = (actionName: string) => {
    MixPanelActions.track(
      MixPanelEvents.searchPageEvents.ADVANCE_SEARCH_CLICK_CTA,
      {
        cta_label: "options",
        cta_type: actionName
      }
    );
    handleProcessClick?.(actionName);
  };

  const arePermissionsLoading =
    isProcessPermissionLoading ||
    canViewActivityLogStatus === "loading" ||
    isProcessDetailsLoading;

  return (
    <Stack
      gap="s1"
      gutter="s1"
      align="center"
      justify="flex-end"
      onClick={handleProcessActionClick}
    >
      {shouldShowRelatedProcesses && (
        <Button
          aria-label="Related process"
          rightIcon={<RelatedProcesses />}
          size={ButtonSizes.SMALL}
          type={ButtonTypes.ICON}
          onClick={onShowProcessFamily}
          data-testid="relatedProcessesIcon"
        />
      )}
      {shouldShowCommentsAction && (
        <Button
          aria-label="Comments"
          rightIcon={<Messages />}
          size={ButtonSizes.SMALL}
          onClick={handleOnCommentsActionClick}
          data-testid="commentsIcon"
          type={ButtonTypes.ICON}
        />
      )}
      {shouldShowMetaActions && (
        <DropdownMenu onOpenChange={handleOnOpenChange}>
          <DropdownMenuTrigger>
            <Button
              aria-label="Meta actions"
              leftIcon={<Dots />}
              size={ButtonSizes.SMALL}
              type={ButtonTypes.ICON}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {arePermissionsLoading ? (
              <>
                <AriaLive
                  message={intl.formatMessage({
                    id: "workflowsInstances.loadingReportActions",
                    defaultMessage: "Loading Report Actions"
                  })}
                />
                <div className={loaderContainerStyles}>
                  <Loader />
                </div>
              </>
            ) : (
              <></>
            )}
            <DropdownMenuItem
              value="archive"
              onSelect={() => {
                onHandleProcessClick?.("archiveWorkflow");
                handleArchive();
              }}
              type={DropdownMenuItemTypes.DESTRUCTIVE}
              disabled={
                arePermissionsLoading ||
                isWorkflowBeingMigrated ||
                !canArchiveProcess
              }
              style={arePermissionsLoading ? { visibility: "hidden" } : {}}
            >
              <WrapWithMigrationTooltip show={!!isWorkflowBeingMigrated}>
                <Stack align="center" gap="s2">
                  <div
                    className={css`
                      margin-right: 8px;
                    `}
                  >
                    <Archive />
                  </div>
                  {intl.formatMessage({
                    id: "stepBodyFormInstances.archiveWorkflow",
                    defaultMessage: "Archive Workflow"
                  })}
                </Stack>
              </WrapWithMigrationTooltip>
            </DropdownMenuItem>
            <DropdownMenuItem
              value="viewActivityLog"
              onSelect={() => {
                onHandleProcessClick?.("viewActivity");
                isActivityLogsNewUIEnabled
                  ? setIsActivityLogsDrawerOpen(true)
                  : showActivityLogs(true);
              }}
              disabled={
                arePermissionsLoading || canViewActivityLogStatus !== "granted"
              }
              style={arePermissionsLoading ? { visibility: "hidden" } : {}}
            >
              <Activity />
              {intl.formatMessage({
                id: "workflowsInstances.viewActivityLog",
                defaultMessage: "View Activity Log"
              })}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {isActivityLogsNewUIEnabled && (
        <ActivityLogsDrawer
          id={processId}
          isActivityLogsDrawerOpen={isActivityLogsDrawerOpen}
          setIsActivityLogsDrawerOpen={setIsActivityLogsDrawerOpen}
        />
      )}
    </Stack>
  );
};

export { ProcessActions };
