import type { ChartViewEDProps } from "../types";
import type { OtherChartConfig } from "@certa/common";
import { useConvertToChartDataED } from "./useConvertToChartDataED";
import { getOtherConfigXAxisED, getOtherConfigYAxisED } from "../utils";
import { getChartHeight } from "../../../utils";
import { useSetTotalCount } from "../../../hooks/useSetTotalCount";

export const useChartViewED = (props: ChartViewEDProps) => {
  const { chartConfigED, apiData, viewType, setTotalCount, isLoading } = props;

  const chartData = useConvertToChartDataED({
    chartConfigED,
    apiData
  });

  const xAxisDataLabels = chartConfigED.groupBy
    .filter(groupBy => !groupBy.extraJSON.isForSecondaryXAxis)
    .map(x => x.extraJSON.label);

  const chartHeight = getChartHeight(viewType);

  // create otherConfig for chart frp, chartConfigORM.otherConfigurations
  const otherConfig: OtherChartConfig = {
    ...chartConfigED.otherConfigurations,
    xAxis: getOtherConfigXAxisED(chartConfigED),
    yAxis: getOtherConfigYAxisED(chartConfigED),
    shouldShowPercentagesInValueLabels:
      chartConfigED.otherConfigurations.shouldShowPercentagesInValueLabels
  };

  useSetTotalCount({ chartData, isLoading, setTotalCount });

  return {
    chartData,
    hasData: !!chartData?.data?.length,
    xAxisDataLabels: xAxisDataLabels,
    chartHeight,
    otherConfig
  };
};
