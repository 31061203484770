import React, { useState, useEffect } from "react";
import qs from "query-string";
import { Search } from "@certa/icons";
import { Input, Stack } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

export const ProcessEditorSearchInput = () => {
  const history = useHistory();
  const intl = useIntl();
  const [processEditorSearchString, setProcessEditorSearchString] =
    useState("");

  useEffect(() => {
    const search = qs.parse(history.location.search);
    if (search?.q) {
      setProcessEditorSearchString(search?.q as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProcessEditorSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProcessEditorSearchString(event.target.value);

    if (!event.target.value) {
      history.push(`${history.location.pathname}`);
    }
  };

  const processEditorHandleSearch = () => {
    if (processEditorSearchString) {
      history.push(
        `${history.location.pathname}?q=${encodeURIComponent(
          processEditorSearchString
        )}`
      );
    } else {
      history.push(`${history.location.pathname}`);
    }
  };

  return (
    <Stack gap="s1">
      <Input
        style={{ width: 230 }}
        placeholder={`${intl.formatMessage({
          id: "commonTextInstances.search"
        })}...`}
        onPressEnter={processEditorHandleSearch}
        value={processEditorSearchString}
        onChange={handleProcessEditorSearch}
        prefix={<Search color="neutral-70" />}
      />
    </Stack>
  );
};
