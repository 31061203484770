import type { LegacyRef } from "react";
import { useState, useEffect, forwardRef, useMemo } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart as ReBarChart,
  Cell,
  LabelList,
  ReferenceArea,
  CartesianGrid
} from "recharts";
import { TYPOGRAPHY_VARIANTS } from "@certa/blocks/thanos";
import {
  tooltipFormatter,
  xAxisFormatter,
  axisFormatter,
  getLabelsFromChartData,
  commonChartMargin,
  CustomXAxesLabel,
  CustomYAxesLabel,
  MultiXAxisCustomTooltip,
  CustomLegendWrapper,
  useGetLegendDataCommon,
  SHOW_ALL_TICKS
} from "@certa/chartreport";
import type { ChartClickEvent, ChartComponentProps } from "@certa/common";
import { INDEX_ZERO } from "@certa/common";
import {
  CustomAxesTick,
  CustomYAxesTick
} from "../../customAxesTick/CustomAxesTick";
import { activeXLabel, lineStyle, tooltipCSS } from "./Barchart.styles";
import { createUniqueXAxis } from "../utils";
import { UNIQUE_X_AXIS_IDENTIFIER } from "../constants";
import { getYAxesWidthMapping } from "./BarChart.utils";

export const SimpleStackedBarChartHorizantal = forwardRef(
  (props: ChartComponentProps, ref: LegacyRef<typeof ReBarChart.prototype>) => {
    const {
      // The default value of isAnimationActive is set to false, therefore the
      // isAnimationActive parameter is not required in all test cases.
      // Only in test scenarios, if isAnimationActive is true, the value label will not be shown.
      isAnimationActive = false,
      xAxisDataLabels,
      chartData,
      height = 500,
      onClick,
      xActiveIdentifier,
      otherConfig,
      sorting,
      handleSorterChange
    } = props;
    const { data, probableDataType, xAxisKey } = chartData;

    const [chartXLabels, setChartXLabels] = useState<Array<string>>([]);

    const [showValueFor, setShowValueFor] = useState<string | null>(null);
    const xAxisDataLabelsLength = xAxisDataLabels.length;
    const isMultiXAxis = xAxisDataLabelsLength > 1;

    useEffect(() => {
      setChartXLabels(
        getLabelsFromChartData(data).filter(
          label => !xAxisDataLabels.includes(label)
        )
      );
    }, [data, xAxisDataLabels]);
    const {
      colors,
      shouldShowValueLabels,
      showValueOn,
      showLegend,
      xAxis: otherConfigXAxis,
      xAxisLabel,
      yAxisLabel,
      showXAxisLabel,
      showYAxisLabel,
      valueLabelAngle
    } = otherConfig;

    useEffect(() => {
      setShowValueFor(null);
    }, [showValueOn]);

    const legendData = useGetLegendDataCommon({
      colors: colors,
      labels: chartXLabels,
      xAxisKey,
      fillOpacity: 0.6
    });

    const multiXAxisLabelFormatter = useMemo(
      () =>
        xAxisDataLabels.reduce((acc, label, index) => {
          Object.assign(acc, {
            [label]: xAxisFormatter(
              otherConfigXAxis?.dataTypes?.[index],
              otherConfigXAxis?.labelOutputTypes?.[index]
            )
          });
          return acc;
        }, {}),
      [
        otherConfigXAxis?.dataTypes,
        otherConfigXAxis?.labelOutputTypes,
        xAxisDataLabels
      ]
    );

    const yAxesWidthMapping = useMemo(
      () =>
        getYAxesWidthMapping({
          data,
          dataTypes: otherConfigXAxis?.dataTypes,
          labelOutputTypes: otherConfigXAxis?.labelOutputTypes,
          xAxisDataLabels
        }),
      [
        xAxisDataLabels,
        otherConfigXAxis?.dataTypes,
        otherConfigXAxis?.labelOutputTypes,
        data
      ]
    );

    return (
      <CustomLegendWrapper
        sorting={sorting}
        handleSorterChange={handleSorterChange}
        data={legendData}
        showLegend={showLegend}
      >
        <ResponsiveContainer
          width="100%"
          height={height}
          className={showValueOn === "hover" ? lineStyle : activeXLabel}
        >
          <ReBarChart
            ref={ref}
            data={createUniqueXAxis(data)}
            onClick={(data: Omit<ChartClickEvent, "activeXIdentifier">) => {
              if (onClick && data) {
                onClick({
                  ...data,
                  activeXIdentifier:
                    data.activePayload?.[INDEX_ZERO]?.payload?.[
                      UNIQUE_X_AXIS_IDENTIFIER
                    ] ?? data.activeLabel
                });
              }
            }}
            margin={commonChartMargin}
            layout="vertical"
          >
            <CartesianGrid
              strokeDasharray="4"
              stroke="#DFE2E7"
              vertical={false}
            />
            <XAxis
              type="number"
              fontSize="12"
              tickLine={false}
              tickSize={15}
              tickFormatter={axisFormatter(probableDataType, "MMM DD, YYYY")}
              fontWeight={400}
              interval="preserveStartEnd"
              tick={CustomAxesTick}
              stroke="#DFE2E7"
              label={
                <CustomXAxesLabel
                  label={yAxisLabel}
                  showAxisLabel={showYAxisLabel}
                />
              }
            />
            {xAxisDataLabels.map((label, index) => (
              <YAxis
                key={label}
                type="category"
                fontSize="12"
                yAxisId={index}
                dataKey={label}
                tickSize={15}
                tickLine={false}
                width={yAxesWidthMapping[label]}
                fontWeight={400}
                tickFormatter={xAxisFormatter(
                  otherConfigXAxis?.dataTypes?.[index],
                  otherConfigXAxis?.labelOutputTypes?.[index],
                  label,
                  data,
                  xAxisDataLabels[xAxisDataLabels.length - 1]
                )}
                interval={isMultiXAxis ? SHOW_ALL_TICKS : "preserveStartEnd"}
                label={
                  <CustomYAxesLabel
                    label={xAxisLabel}
                    showAxisLabel={
                      xAxisDataLabelsLength !== index + 1
                        ? "none"
                        : showXAxisLabel
                    }
                  />
                }
                tick={CustomYAxesTick}
              />
            ))}
            <YAxis
              type="category"
              yAxisId={xAxisDataLabels.length}
              hide
              dataKey={UNIQUE_X_AXIS_IDENTIFIER}
            />
            <Tooltip
              formatter={tooltipFormatter("BAR_CHART", probableDataType)}
              content={
                <MultiXAxisCustomTooltip
                  multiXAxisLabelFormatter={multiXAxisLabelFormatter}
                  xAxisDataLabels={xAxisDataLabels}
                />
              }
              wrapperClassName={tooltipCSS(height)}
              cursor={{
                fill: "var(--neutral-10)",
                style: onClick
                  ? { cursor: "pointer", pointerEvents: "all" }
                  : {}
              }}
            />
            <ReferenceArea
              yAxisId={xAxisDataLabels.length}
              y1={xActiveIdentifier}
              y2={xActiveIdentifier}
              fill="var(--neutral-50)"
              fillOpacity={1}
              label={{
                value: "",
                position: "center",
                angle: -90,
                offset: 12,
                style: TYPOGRAPHY_VARIANTS["p2-medium-upper"] as {}
              }}
            />
            {legendData.map(legend => (
              <Bar
                isAnimationActive={isAnimationActive}
                key={legend.label}
                dataKey={legend.label}
                fill={legend.background}
                fillOpacity={legend.fillOpacity}
                stackId={xAxisKey}
                style={showValueOn === "click" ? { cursor: "pointer" } : {}}
                onClick={() => setShowValueFor(legend.label)}
              >
                {shouldShowValueLabels &&
                (showValueOn !== "click" || showValueFor === legend.label) ? (
                  <LabelList
                    dataKey={legend.label}
                    angle={valueLabelAngle}
                    formatter={tooltipFormatter("BAR_CHART", probableDataType)}
                    fill="var(--neutral-100)"
                    style={{
                      textAnchor: "middle"
                    }}
                  />
                ) : null}
                {data.map((_, itemIndex) => (
                  <Cell
                    cursor={onClick ? "pointer" : ""}
                    key={`cell-${itemIndex}`}
                  />
                ))}
              </Bar>
            ))}
          </ReBarChart>
        </ResponsiveContainer>
      </CustomLegendWrapper>
    );
  }
);
