/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import { type FC } from "react";
import type { CommentThread } from "@certa/types";
import {
  ThreadContext,
  useChannelContext,
  useCommentsContext
} from "../../comments.context";
import { CommentFormNew } from "./CommentFormNew";
import { NoComments } from "./NoComments";
import { useIntl } from "react-intl";
import { MessageViewNew } from "./MessageViewNew";
import { useMentions } from "../../hooks/useMentions";
import { NewThreadForm } from "./NewThreadForm";
import { css } from "emotion";
import { useScreenResolution } from "@certa/common";
import { Stack } from "@certa/catalyst";

type ThreadContainerProps = {
  thread: CommentThread;
  showNewThread: boolean;
  onThreadCreate: (payload: any) => void;
  onThreadCancel: () => void;
};

const ThreadContainer: FC<ThreadContainerProps> = ({
  thread,
  showNewThread,
  onThreadCreate,
  onThreadCancel
}) => {
  const intl = useIntl();
  const { isReadOnly } = useCommentsContext();
  const {
    fieldName,
    stepName: taskName,
    childWorkflowName
  } = useChannelContext();

  const { isMobileResolution } = useScreenResolution();
  const mentions = useMentions(thread?.threadId);
  const onCommentPost = () => showNewThread && onThreadCancel();

  return (
    <ThreadContext.Provider value={thread}>
      <Stack
        padding={isMobileResolution ? "0" : "0 24"}
        dangerouslySetClassName={css({
          flex: "1 1 0%",
          overflowY: "hidden"
        })}
      >
        {showNewThread ? (
          <NewThreadForm
            onNewThread={onThreadCreate}
            onCancel={onThreadCancel}
          />
        ) : thread?.messageCount > 0 ? (
          <MessageViewNew
            groupedMessages={thread?.groupedMessages}
            mentions={mentions}
          />
        ) : (
          <NoComments
            heading={`${intl.formatMessage({
              id: "comments.noCommentsForThread",
              defaultMessage: "No new Comments to show for"
            })} ${fieldName || taskName || childWorkflowName}`}
            subHeading={intl.formatMessage({
              id: "comments.noCommentsForThreadSubHeading",
              defaultMessage:
                "Add a new comment by typing your message below. You can use the @ symbol to mention a person, group or organization."
            })}
          />
        )}
      </Stack>
      {!isReadOnly && !showNewThread && (
        <div
          className={css({
            backgroundColor: "var(--colors-neutral-300)",
            position: "sticky",
            bottom: "0",
            zIndex: 2,
            padding: isMobileResolution
              ? "var(--space-16) var(--space-4)"
              : "var(--space-16)"
          })}
        >
          <CommentFormNew onCommentPost={onCommentPost} />
        </div>
      )}
    </ThreadContext.Provider>
  );
};

export { ThreadContainer };
