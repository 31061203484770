import { useMemo } from "react";
import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { removeBracketsAndAppendDash } from "../utils/helper";
import type { OptionItem } from "@certa/types";

export const useGetSupportedLanguages = () => {
  const supportedLanguages = useSelector((state: ReduxState) =>
    lodashGet(state, "config.supported_languages_json", {})
  );

  return useMemo(
    () =>
      Object.keys(supportedLanguages).map(languageCode => ({
        value: languageCode,
        label: `${removeBracketsAndAppendDash(
          supportedLanguages[languageCode]
        )} (${languageCode})`
      })) as OptionItem[],
    [supportedLanguages]
  );
};
