/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import { Link } from "react-router-dom";
import { Text } from "@certa/blocks/thanos";
import type { ActivityLogModelType } from "@certa/queries";

export const WorkflowLink = ({ item }: { item: ActivityLogModelType }) => {
  return item.workflowId && item.workflowName ? (
    <Link
      to={`/process/${item.workflowId}`}
      target="_blank"
      css={css`
        &:hover,
        &:focus {
          color: var(--neutral-100) !important;
          text-decoration: underline !important;
        }
      `}
    >
      <Text variant="p1-bold" color="neutral-100">
        {item.workflowName}
      </Text>
    </Link>
  ) : null;
};
