import type { StepGroup } from "@certa/studio/src/types/rawProcess.types";
import type { HashMap } from "@certa/types";

export enum IntentType {
  ADD_ACCESS_RULE = "add_access_rule",
  ADD_ATTACHMENT_INHERITANCE = "add_attachment_inheritance",
  ADD_BULK_ACTION = "add_bulk_action",
  ADD_CHILD_FIELD_MAPPING = "add_child_field_mapping",
  ADD_CHILD_WORKFLOW_TABLE = "add_child_workflow_table",
  ADD_COLUMNS_EDITABLE_TABLE = "add_columns_editable_table",
  ADD_CROSS_PROCESS_INHERITANCE = "add_cross_process_inheritance",
  ADD_DEADLINE = "add_deadline",
  ADD_DEFAULT_STEP = "add_default_step",
  ADD_DEPENDENCY = "add_dependency",
  ADD_EDITABLE_TABLE = "add_editable_table",
  ADD_FIELD = "add_field",
  ADD_GROUP = "add_group",
  ADD_INHERITANCE = "add_inheritance",
  ADD_INTEGRATION = "add_integration",
  ADD_NOTIFICATION = "add_notification",
  ADD_REDUCTION = "add_reduction",
  ADD_RELATED_TYPES = "add_related_types",
  ADD_REVOKE_RULE = "add_revoke_rule",
  ADD_STATUS_INDICATOR = "add_status_indicator",
  ADD_STEP = "add_step",
  ADD_STEP_ACTION = "add_step_action",
  ADD_SWIMLANE = "add_swimlane",
  ADD_TEMPLATE = "add_template",
  ADD_VISIBILITY = "add_visibility",
  ADD_WORKFLOW_MAPPING = "add_workflow_mapping",
  CHANGE_COLUMNS_EDITABLE_TABLE = "change_columns_editable_table",
  CHANGE_DEFAULT_STEP = "change_default_step",
  CHANGE_EDITABLE_TABLE = "change_editable_table",
  CHANGE_FIELD = "change_field",
  CHANGE_FIELD_OPTIONS = "change_field_options",
  CHANGE_STEP_TYPE = "change_step_type",
  COPY_PROPERTIES = "copy_properties",
  CREATE_WORKFLOW = "create_workflow",
  NA = "na",
  REMOVE_ACCESS_RULE = "remove_access_rule",
  REMOVE_ATTACHMENT_INHERITANCE = "remove_attachment_inheritance",
  REMOVE_CHILD_FIELD_MAPPING = "remove_child_field_mapping",
  REMOVE_COLUMNS_EDITABLE_TABLE = "remove_columns_editable_table",
  REMOVE_CROSS_PROCESS_INHERITANCE = "remove_cross_process_inheritance",
  REMOVE_DEADLINE = "remove_deadline",
  REMOVE_DEFAULT_STEP = "remove_default_step",
  REMOVE_DEPENDENCY = "remove_dependency",
  REMOVE_FIELD = "remove_field",
  REMOVE_GROUP = "remove_group",
  REMOVE_INHERITANCE = "remove_inheritance",
  REMOVE_NOTIFICATION = "remove_notification",
  REMOVE_REDUCTION = "remove_reduction",
  REMOVE_RELATED_TYPES = "remove_related_types",
  REMOVE_STATUS_INDICATOR = "remove_status_indicator",
  REMOVE_STEP = "remove_step",
  REMOVE_STEP_ACTION = "remove_step_action",
  REMOVE_SWIMLANE = "remove_swimlane",
  REMOVE_VISIBILITY = "remove_visibility",
  REMOVE_WORKFLOW_MAPPING = "remove_workflow_mapping",
  RENAME = "rename",
  REPOSITION = "reposition"
}

export type ExtraAPIConfigPayload = {
  userName: string;
  sessionId: string;
};

// CertaAssist Token
export type TokenAPIResponse = {
  auth_token: string;
};

// Session ID
export type SessionAPIResponse = {
  message: string;
  session_id: string;
};

export enum Sender {
  USER = "user",
  PROCESS_EXPERT = "process_expert",
  CERTA_EXPERT = "certa_expert"
}

export type ConversationHistoryItem = {
  sender: Sender;
  message: string;
};

// CBPE (Client Business Process Expert) Describe [EXPERIMENTAL - FOR DEMO PURPOSES]
export type CBPEDescribeRequestPayload = {
  message: string;
  branchSavepointId: number;
  workflowKinds: string[];
  /**
   * Used to pass down agents conversation history stored at FE.
   *
   * @deprecated Use 'conversationId' instead. This property is left
   * as is for backward compatibility and will eventually be removed.
   */
  conversationHistory: ConversationHistoryItem[];
  /**
   * Id of the conversation. This is used to store the conversation
   * history in BE db.
   */
  conversationId?: string;
};

export type CBPEDescribeResponse = {
  description: string;
  conversationId: string;
};

// Certa Expert Suggest [EXPERIMENTAL - FOR DEMO PURPOSES]
export type CertaExpertSuggestRequestPayload = {
  userSays: string;
  processExpertSays: string;
  /**
   * Used to pass down agents conversation history stored at FE.
   *
   * @deprecated Use 'conversationId' instead. This property is left
   * as is for backward compatibility and will eventually be removed.
   */
  conversationHistory: ConversationHistoryItem[];
  conversationId: string;
};

export type CertaExpertSuggestResponse = {
  response: string;
  commands?: string[];
  conversationHistory: ConversationHistoryItem[];
  conversationId: string;
};

// Initiate Process Index [EXPERIMENTAL - FOR DEMO PURPOSES]
export type InitiateProcessIndexRequestPayload = {
  branchSavepointId: number;
  processSavepointId: number;
  indexType?: ProcessIndexType;
};

export type InitiateProcessIndexResponse = {
  message: string;
  type: ProcessIndexType;
  requestId: string;
};

export type ProcessIndexType = "business_process";

// Process Index Status [EXPERIMENTAL - FOR DEMO PURPOSES]
export type ProcessIndexStatusRequestPayload = {
  requestId: string;
};

export enum ProcessIndexStatus {
  QUEUED = "queued",
  QUEUE_FAILED = "queue_failed",
  PROCESSING = "processing",
  SUCCESS = "success",
  FAIL = "fail",
  TERMINATED = "terminated"
}

export type ProcessIndexStatusResponse = {
  createdAt: string;
  updatedAt: string;
  vectorIndexRequestId: string;
  indexType: ProcessIndexType;
  status: ProcessIndexStatus;
  data: unknown;
};

// Task Generator
export type TaskGeneratorResult = {
  intent: IntentType;
  subMessage: string;
  subTaskId: string;
};

export enum TaskGeneratorMode {
  NORMAL = "normal",
  BRD = "brd",
  CSV_BRD = "csv_brd"
}

export enum ExecutionMode {
  NORMAL = "normal",
  NON_STOP = "non_stop"
}

export enum ComponentType {
  SWIMLANE = "swimlane",
  STEP = "step",
  FIELD = "field"
}

export type SelectedComponentDetails = {
  name: string;
  tag: string;
};

export type SelectedComponent =
  | {
      componentType: ComponentType.SWIMLANE;
      [ComponentType.SWIMLANE]: SelectedComponentDetails;
      [ComponentType.STEP]?: never;
      [ComponentType.FIELD]?: never;
    }
  | {
      componentType: ComponentType.STEP;
      [ComponentType.SWIMLANE]: SelectedComponentDetails;
      [ComponentType.STEP]: SelectedComponentDetails;
      [ComponentType.FIELD]?: never;
    }
  | {
      componentType: ComponentType.FIELD;
      [ComponentType.SWIMLANE]: SelectedComponentDetails;
      [ComponentType.STEP]: SelectedComponentDetails;
      [ComponentType.FIELD]: SelectedComponentDetails;
    };

export type TaskGeneratorRequestPayload = {
  userInput?: string | string[] | File;
  selectedComponent?: SelectedComponent;
  mode?: TaskGeneratorMode;
  executionMode?: ExecutionMode;
  useIntelligentMode?: boolean;
  branchId?: number;
  branchSavepointId?: number;
  processSavepointId?: number;
  packageId?: number;
  taskId?: string;
  firstCall?: boolean;
};

export enum TaskGeneratorStatus {
  BOOKED = "booked",
  QUEUED = "queued",
  PROCESSING = "processing",
  QUEUE_FAILED = "queue_failed",
  FAIL = "fail",
  SUCCESS = "success"
}

export type TaskGeneratorResponse = {
  result: TaskGeneratorResult[];
  status: TaskGeneratorStatus;
  taskId: string;
};

// Generate Tasks from CSV BRD
export type GenerateTasksFromCsvBrdRequestPayload = {
  csvFile: File;
  processSavepointId: number;
  branchSavepointId: number;
  branchId: number;
  packageId: number;
};

export type GenerateTasksFromCsvBrdResponse = {
  stepgroups: HashMap<StepGroup>;
  status: GenerateTasksFromCsvBrdStatus;
  messages: string[];
  /**
   * Subtasks to be queued for adding options to fields of type
   * `select`, `radio`, `checkbox`, `single-select`, `multi-select`, etc.
   */
  responseOptions?: TaskGeneratorResult[];
  /**
   * Extra conditions as strings to be added to the
   * process to build on top of returned swimlanes.
   */
  responseConditions?: string[];
};

export enum GenerateTasksFromCsvBrdStatus {
  SUCCESS = "success",
  FAIL = "failed"
}

// Terminate Task Generation
export type TerminateTaskGeneratorRequestPayload = {
  taskId: string;
  mode: TaskGeneratorMode;
};

// Create Interaction
export type UserInputWithIntent = {
  intent: IntentType | null;
  message: string;
};

export type CreateInteractionRequestPayload = {
  userInputsWithIntent: UserInputWithIntent[];
  selectedComponent?: SelectedComponent;
  mode: TaskGeneratorMode;
  executionMode: ExecutionMode;
  useIntelligentMode: boolean;
  /**
   * Possible values:
   * - 1 -> Response will have updated deployable JSON
   * - 2 -> Response will have only updated deployable JSON diffs
   */
  responseVersion?: number;
  packageId: number;
  branchId: number;
  branchSavepointId: number;
  processSavepointId: number;
  /** interaction_id of previous successful interaction API call */
  previousInteractionId: string | null;
  taskId: string | null;
  userId: number;
};

export type CreateInteractionResponse = {
  interactionId: string;
  status: "queued";
};

// Interaction Fullfillment
export type InteractionFulfillmentRequestPayload = {
  // Old method of polling subtask progress
  subtaskId?: string;
  // Old method of polling subtask progress
  // - used for backward compatibility with '/' commands
  // - interaction_id is returned from 'interaction/' API
  interactionId?: string;
};

export enum TargetType {
  STEP = "step",
  FIELD = "field",
  STEPGROUP = "stepgroup",
  TEMPLATE = "template"
}

export enum ReasonCode {
  /** User mentioned a component X and system didn't find a component with the name X at all. */
  NOT_FOUND = "not_found",
  /** User mentioned a component but multiple possible matches were found */
  MULTIPLE_FOUND = "multiple_found",
  /**
   * User mentioned a component but additional context is required.
   * E.g. In the case of "Add a field" prompt, system might ask for field type or name of a component
   * */
  MISSING_INFO = "missing_info"
}

export type RequiredContextPayload = {
  targetType: TargetType;
  name: string;
  reasonCode: ReasonCode;
  possibleMatches: string[] | null;
  kind: string;
  /** Will be true when BE explicitly wants to initiate free-text follow-up */
  requireFreeTextFollowup?: boolean;
};

export enum MetaDataItemStatus {
  SUCCESS = "success",
  CONTEXT_REQUIRED = "context_required",
  FAILED = "failed",
  NO_OP = "no_operation"
}

export type InteractionMetadata = {
  userInput: string;
  intent: IntentType;
  data: any;
  status: MetaDataItemStatus;
  message: string | null;
  requiredContext: RequiredContextPayload | null;
};

export enum FulfillmentStatus {
  SUCCESS = "success",
  PARTIAL_SUCCESS = "partial_success",
  FAILED = "failed",
  PROCESSING = "processing",
  NOT_SCHEDULED = "not_scheduled"
}

export type InteractionFulfillmentProcessingResponse = {
  code: FulfillmentStatus.PROCESSING | FulfillmentStatus.NOT_SCHEDULED;
};

export type InteractionFulfillmentFailureResponse = {
  code: FulfillmentStatus.FAILED;
  message: string;
};

export enum ChangeAction {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete"
}

export enum ChangeLevel {
  PROCESS = "process",
  SWIMLANE = "swimlane",
  STEP = "step",
  FIELD = "field"
}

export type ChangeDiff = {
  action: ChangeAction;
  level: ChangeLevel;
  tag: string;
  parentTag?: string;
  data?: HashMap;
  changedData?: {
    added?: string[];
    updated?: string[];
    removed?: string[];
  };
  atIndex?: number;
};

export type ChangeDiffWithoutData = Omit<ChangeDiff, "data"> & { data?: never };

export type InteractionFulfillmentSuccessResponse = {
  code: FulfillmentStatus.SUCCESS | FulfillmentStatus.PARTIAL_SUCCESS;
  interactionId: string;
  message: string;
  metadata: InteractionMetadata[];
  changes: ChangeDiff[];
  taskId: string;
  sessionId: string;
};

export type InteractionFulfillmentResponse =
  | InteractionFulfillmentProcessingResponse
  | InteractionFulfillmentFailureResponse
  | InteractionFulfillmentSuccessResponse;

// Stitch Workflow
export type StitchWorkflowContext = {
  targetType: TargetType;
  targetName: string;
  userInput: string;
  workflowKind: string;
  stepTag?: string;
  swimlaneTag?: string;
};

export type FollowUpChainMessage = {
  role: "assistant" | "human";
  message: string;
  data?: unknown;
};

export type StitchWorkflowRequestPayload = {
  interactionId: string;
  intent: string;
  userPrompt: string;
  actionJson: any;
  context: StitchWorkflowContext[];
  /** TODO: Remove after BE changes to derive useIntelligentMode from interactionId */
  useIntelligentMode: boolean;
  /** Only passed for Free Text followups */
  followUps?: FollowUpChainMessage[];
  packageId: number;
  branchId: number;
  branchSavepointId: number;
  subTaskId: string;
  userId: number;
};

export type StitchWorkflowResponse = InteractionFulfillmentResponse & {
  resolvedConflicts?: StitchWorkflowContext[];
};

// User Feedback
export type FeedbackRequestPayload =
  | FeedbackRequestInteractionPayload
  | FeedbackRequestGeneralPayload;

export type FeedbackRequestInteractionPayload = {
  type: "interaction";
  feedbackData: {
    rating: "like" | "dislike";
    usability?: never;
    velocity?: never;
    isUseful?: never;
    feedback: string;
  };
  interactionId: string;
};

export type FeedbackRequestGeneralPayload = {
  type: "general";
  feedbackData: {
    rating: number;
    usability?: "easy" | "moderate" | "difficult";
    velocity?: "improved" | "no_impact" | "reduced";
    isUseful?: boolean;
    feedback?: string;
  };
  interactionId?: never;
};
