import type { ReduxState } from "main/src/modules/common/interfaces";
import { useMemo } from "react";
import { get as lodashGet } from "lodash-es";
import { useSelector } from "react-redux";
import type { Step } from "@certa/types";
import moment from "moment";
import { PROCESS_CYCLE_TIME } from "../../constants";

export const useTaskWaiting = (processId?: number) => {
  const processDetail = useSelector(
    (state: ReduxState) => state.workflowDetails
  );

  const taskWaiting = useMemo(
    () => lodashGet(processDetail, `${processId}.stepsWaiting`, {}),
    [processDetail, processId]
  );

  return taskWaiting;
};

export type TaskViewProps = {
  isSelectedTask: boolean;
  task: Step;
  taskLaneId?: number;
  onSelect?: (taskId: number, taskLaneId?: number) => () => void;
  routeURL?: string;
  displayDeadline?: boolean;
  processId?: number;
  tooltipContainer?: (triggerNode: HTMLElement) => HTMLElement;
  isAIFillingAnswers?: boolean;
  hasAnyAIFilledAnswers?: boolean;
  isLockedStepHighlight?: boolean;
};

const HOUR_REMINDER = 24;
const MINUTE_REMINDER = 60;
const EMPTY_UNIT = 0;

type FormaterDDHHMMSSProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const formaterDDHHMMSS = ({
  days,
  hours,
  minutes,
  seconds
}: FormaterDDHHMMSSProps) => {
  const hourQuotient = hours % HOUR_REMINDER;

  if (days > EMPTY_UNIT) {
    return `${days}d${hourQuotient ? ": " + hourQuotient + "hr" : ""}`;
  }

  const minuteQuotient = minutes % MINUTE_REMINDER;

  // show "hr: min" if hours > 0 and min > 0
  // show "hr" if hours > 0 and min = 0
  if (hours > EMPTY_UNIT) {
    return `${hours}hr${minuteQuotient ? ": " + minuteQuotient + "min" : ""}`;
  }
  const secondQuotient = seconds % MINUTE_REMINDER;

  // show "min: sec" if min > 0 and sec > 0
  // show "sec" if sec > 0 and min = 0
  if (minutes > EMPTY_UNIT) {
    return `${minuteQuotient}min${
      secondQuotient ? ": " + secondQuotient + "sec" : ""
    }`;
  }
  return `${seconds}sec`;
};

export const calculateOverdueDuration = (date: string) => {
  // Removing startOf to fix inconsistency in overdue calculation
  const days = Math.abs(moment().diff(moment(date), "days"));
  const hours = Math.abs(moment().diff(moment(date), "hours"));

  // Changing logic to show till mins
  const hourQuotient = hours % HOUR_REMINDER;

  // show "d: hr" if days > 0 and hours > 0
  // show "d" if days > 0 and hours = 0
  if (days > EMPTY_UNIT) {
    return `${days}d${hourQuotient ? ": " + hourQuotient + "hr" : ""}`;
  }
  const minutes = Math.abs(moment().diff(moment(date), "minutes"));

  const minuteQuotient = minutes % MINUTE_REMINDER;

  // show "hr: min" if hours > 0 and min > 0
  // show "hr" if hours > 0 and min = 0
  if (hours > EMPTY_UNIT) {
    return `${hours}hr${minuteQuotient ? ": " + minuteQuotient + "min" : ""}`;
  }

  // show remaining minutes - "min"
  return `${minutes}min`;
};

/**
 *  Takes in deltaInSeconds and converts it to a format days / hours / minutes / seconds
 *  whose rounded non-zero value is closest to the greater unit among ^
 */
export const getFormattedCycleTime = (deltaInSeconds: number) => {
  const duration = new (moment as any).duration((deltaInSeconds || 0) * 1000);

  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());
  const seconds = Math.floor(duration.asSeconds());
  return formaterDDHHMMSS({ days, hours, minutes, seconds });
};

export const getCycleTimeLabel = (
  isCycleRunning: boolean | null | undefined,
  timeInSeconds: number | null | undefined
) => {
  const processCycleTime =
    isCycleRunning === true
      ? PROCESS_CYCLE_TIME.IN_PROGRESS
      : timeInSeconds === null || timeInSeconds === undefined
        ? PROCESS_CYCLE_TIME.NOT_AVAILABLE
        : getFormattedCycleTime(timeInSeconds);
  return processCycleTime;
};

export const getAgeingTimeLabel = (
  timeInSeconds: number | null | undefined
) => {
  const processCycleTime =
    timeInSeconds === null || timeInSeconds === undefined
      ? PROCESS_CYCLE_TIME.NOT_AVAILABLE
      : getFormattedCycleTime(timeInSeconds);
  return processCycleTime;
};
