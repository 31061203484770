import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import type { StackProps } from "../Stack";
import { Stack } from "../Stack";
import type { IconProps } from "@certa/icons";

const Node: FC<
  {
    active?: boolean;
    icon: React.ComponentType<IconProps>;
    padding?: string;
    defaultSVGColor?: string;
    hoverBackground?: string;
    hoverSVGColor?: string;
    defaultBackground?: string;
    svgColorProps?: string;
    circular?: boolean;
  } & StackProps
> = props => {
  const {
    icon: Icon,
    defaultSVGColor = "neutral-10",
    padding,
    circular,
    ...stackProps
  } = props;
  return (
    <Stack align="center" {...stackProps}>
      <Icon
        // @ts-expect-error: suppressed type error since this code is not actively maintained
        color={defaultSVGColor}
      />
    </Stack>
  );
};

export const TimelineNode = styled(Node)`
  padding: ${props => props.padding};
  border-radius: ${props => (props.circular ? "50%" : "4px")};
  outline: 0;
  background-color: ${props =>
    props.active ? `var(--${props.hoverBackground})` : props.defaultBackground};
`;
