import styled from "@emotion/styled";
import { Input as AntInput } from "antd";
import type { TextAreaProps as AntTextAreaProps } from "antd/lib/input/TextArea";
import { getVariantStyles } from "../Typography/utils";

export type TextAreaProps = {
  error?: boolean;
} & AntTextAreaProps;

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { TextArea } from '@certa/catalyst';
 */
export const TextArea = styled(AntInput.TextArea)<TextAreaProps>`
  && {
    font-family: var(--font-family);
    min-height: calc(var(--s10) + 2px);
    color: ${({ error }) => (error ? "var(--red)" : "var(--neutral-100)")};
    background-color: ${({ error }) =>
      error ? "var(--red-60)" : "var(--neutral-5)"};
    border: 1px solid
      ${({ error }) => (error ? "var(--red)" : "var(--neutral-5)")};
    border-radius: var(--big-border-radius);
    box-shadow: none;
    transition: border 0s;
    padding: var(--s3) var(--s4);
    ${getVariantStyles("h3-regular")}

    &.ant-input-disabled {
      color: var(--neutral-70);
      background-color: var(--neutral-10);
      border: 1px solid var(--neutral-20);
    }

    &::before {
      content: unset;
    }

    &::placeholder {
      ${getVariantStyles("h3-regular")}
      color: var(--neutral-70) !important;
    }
    &:focus {
      box-shadow: 0 0 0 2px
        ${({ error }) => (error ? "var(--red-40)" : "var(--brand-15)")} !important;
    }
  }
`;
