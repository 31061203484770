import React, { forwardRef } from "react";
import styles from "./TabButton.module.css";
import type { TabButtonProps } from "../../Tabs.types";
import {
  Stack,
  TypographyColors,
  TypographyVariants,
  Typography,
  classNames
} from "@certa/catalyst";

const TabButton = forwardRef<
  HTMLButtonElement,
  TabButtonProps & React.ComponentProps<"button">
>(
  (
    {
      value,
      badgeCount,
      "aria-label": ariaLabel,
      selectedTab,
      onTabChange,
      label,
      ...buttonProps
    },
    ref
  ) => {
    const isSelected = value === selectedTab;

    return (
      <button
        {...buttonProps}
        ref={ref}
        role="tab"
        onClick={() => onTabChange && onTabChange(value)}
        className={classNames({
          [styles.tabButton]: true,
          [styles.selectedTabButton]: isSelected
        })}
        aria-selected={isSelected}
        aria-label={ariaLabel}
        // TODO: add tabIndex -1 when not selected, But currently arrow keys do not focus them without them having tabIndex 0
        // tabIndex={isSelected ? undefined : -1}
      >
        <Typography
          variant={TypographyVariants.LABEL_SM_BOLD}
          color={
            isSelected
              ? TypographyColors.NEUTRAL_100
              : TypographyColors.NEUTRAL_700
          }
        >
          {label}
        </Typography>
        {badgeCount !== undefined && (
          <Stack
            align="center"
            justify="center"
            dangerouslySetClassName={classNames({
              [styles.tabBadge]: true,
              [styles.selectedTabBadge]: isSelected
            })}
          >
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={
                isSelected
                  ? TypographyColors.NEUTRAL_700
                  : TypographyColors.NEUTRAL_100
              }
            >
              {badgeCount.toLocaleString()}
            </Typography>
          </Stack>
        )}
      </button>
    );
  }
);

export { TabButton };
