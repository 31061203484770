import { css } from "emotion";

export const cardStyles = css({
  alignItems: "center",
  appearance: "none",
  borderRadius: "8px",
  margin: 0,
  backgroundColor: "var(--colors-neutral-100)",
  border: "1px solid var(--colors-neutral-400)",
  padding: "var(--space-16)",
  width: "100%",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0px 3px 13px 0px rgba(0, 0, 0, 0.06)"
  },
  "&:active,&:focus": {
    outline: "none"
  },
  "&:focus-visible": {
    outline: "2px solid var(--colors-brand-400)",
    outlineOffset: "-2px"
  }
});

export const cardContent = css({
  display: "flex",
  flexDirection: "row",
  columnGap: "var(--space-16)",
  alignItems: "center"
});

export const iconEllipse = css({
  borderRadius: "50%",
  backgroundColor: "var(--colors-brand-100)",
  padding: "16px",
  maxWidth: "45px",
  maxHeight: "45px",
  color: "var(--colors-brand-300)"
});

export const textWrapStyles = css({
  textAlign: "left",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis"
});
