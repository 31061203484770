import type { UseInfiniteQueryExtraConfigType } from "@certa/common";
import { useInfiniteQuery, useMutation } from "react-query";
import { downloadActivityLogs, getActivityLogs } from "../..";
import type { ActivityLogModel } from "../types/activityLog.types";

export function useGetActivityLogs(
  params: {
    id: number | undefined;
    actions: string[];
  },
  config?: UseInfiniteQueryExtraConfigType
) {
  return useInfiniteQuery<ActivityLogModel>(
    ["activity_logs", params],
    ({ pageParam }) => getActivityLogs(params, pageParam),
    {
      getNextPageParam: (data, allData) => {
        const skip = allData.reduce(
          (sum, data) => sum + (data.results.length || 0),
          0
        );
        if (data.next) {
          return skip;
        }
        return false;
      },
      ...config!
    }
  );
}

export const useDownloadActivityLog = () => useMutation(downloadActivityLogs);
