import { APIFetchV3, RequestHeaders } from "@certa/network";
import { globalSearchModelCreator } from "../models/globalSearch.model";
import type { RawAPIResponse } from "../types/globalSearch.types";

export async function getGlobalSearchResults(
  query: string,
  selectedCategories: string[]
) {
  /**
   * The main idea behind the following structure of the request body is to
   * allow FE to dictate the attributes that it needs in the response. In the
   * favour of avoiding over complications for now hard-coding the attributes
   * inside the service function since this API is being used only at one place.
   * Later - This needs to come as a argument to the service function still
   * avoiding this huge structure to be defined at the call site.
   */
  const requestBody = {
    data: {
      id: null,
      name: null,
      logo: null,
      created_at: null,
      parents: {
        id: null,
        name: null,
        logo: null,
        created_at: null
      },
      definition: {
        id: null,
        name: null,
        kind: {
          id: null,
          tag: null,
          name: null,
          icon: null,
          menu_index: null,
          related_kind_tags: null
        }
      },
      answers: {
        id: null,
        answer: null,
        attachment: null,
        submitted_by: {
          id: null,
          first_name: null,
          last_name: null,
          email: null
        },
        submitted_at: null,
        files: null,
        files_details: {
          name: null,
          url: null,
          uid: null
        },
        field: {
          id: null,
          definition: {
            id: null,
            tag: null,
            body: null,
            workflow_mapping: null
          },
          step: {
            id: null,
            definition: {
              id: null,
              tag: null,
              name: null
            }
          }
        }
      }
    },
    // Omitting the filters for now
    search: query,
    search_category: selectedCategories
  };
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(requestBody)
  };
  const url = "search/";

  const response = await APIFetchV3<RawAPIResponse.GlobalSearchResponse>(
    url,
    requestOptions
  );

  return globalSearchModelCreator(response, query);
}
