import type { AdjudicationFlag } from "@certa/types";
import { useMemo } from "react";

export const useGetApplicableRiskCodes = (
  adjudicationRiskCodes?: string[],
  data?: AdjudicationFlag[]
) => {
  const applicableRiskCodes = useMemo(() => {
    if (adjudicationRiskCodes && Array.isArray(adjudicationRiskCodes)) {
      const afterFiltereingAdjudicationCodes = (data || []).filter(item =>
        adjudicationRiskCodes.includes(item.tag)
      );
      return afterFiltereingAdjudicationCodes;
    } else {
      return data;
    }
  }, [adjudicationRiskCodes, data]);
  return applicableRiskCodes;
};
