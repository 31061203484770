import { css } from "emotion";
import { Dialog } from "@certa/catalyst";
import { useIntl } from "react-intl";

const INSTANCE_SETTINGS_MODAL_WIDTH = "80rem";

type InstanceSettingsProps = {
  showInstanceSettingsDialog: boolean;
  setShowInstanceSettingsDialog: (value: boolean) => void;
};

const InstanceSettingsIframe = () => {
  return (
    <iframe
      src="/settings"
      name="instanceSettings"
      title="Instance settings"
      className={css`
        border: none;
        width: 100%;
        height: 75vh;
      `}
    />
  );
};
export const InstanceSettings = ({
  showInstanceSettingsDialog,
  setShowInstanceSettingsDialog
}: InstanceSettingsProps) => {
  const intl = useIntl();

  return (
    <Dialog
      onClose={() => {
        setShowInstanceSettingsDialog(false);
      }}
      preventCloseOnClickOutside={false}
      width={`min(90%, ${INSTANCE_SETTINGS_MODAL_WIDTH})`}
      show={showInstanceSettingsDialog}
      title={intl.formatMessage({
        id: "loginPageInstances.InstanceSettingsText",
        defaultMessage: "Instance settings"
      })}
    >
      <InstanceSettingsIframe />
    </Dialog>
  );
};
