import { useAppSelector } from "../hooks";

// This hook will be removed once the migration to new Comms microservice
// is completed and enabled on production by default.
export const useCommsNewEndpoints = () => {
  const shouldUseCommsNewEndpointsBasedOnConfig = useAppSelector(
    state => state?.config?.custom_ui_labels?.use_comms_new_endpoints
  ) as boolean | undefined;

  return shouldUseCommsNewEndpointsBasedOnConfig;
};
