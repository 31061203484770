import type { SelectedComponent } from "../types/assist.types";
import { ComponentType } from "../types/assist.types";

export const getSelectedComponentPayload = (
  selectedComponent: SelectedComponent
) => {
  return {
    component_type: selectedComponent.componentType,
    [selectedComponent.componentType]: {
      name: selectedComponent?.[selectedComponent.componentType]?.name,
      tag: selectedComponent?.[selectedComponent.componentType]?.tag
    },
    ...(selectedComponent[ComponentType.SWIMLANE]
      ? {
          [ComponentType.SWIMLANE]: {
            name: selectedComponent?.[ComponentType.SWIMLANE]?.name,
            tag: selectedComponent?.[ComponentType.SWIMLANE]?.tag
          }
        }
      : {}),
    ...(selectedComponent[ComponentType.STEP]
      ? {
          [ComponentType.STEP]: {
            name: selectedComponent?.[ComponentType.STEP]?.name,
            tag: selectedComponent?.[ComponentType.STEP]?.tag
          }
        }
      : {})
  };
};

export let cancelExecutionAbortController = new AbortController();

/**
 * Used to abort the Design AI API calls involved in task generation
 * and execution when user clicks on 'Cancel' button in Design AI.
 */
export const cancelDesignAITaskAPICalls = () => {
  cancelExecutionAbortController.abort();
  cancelExecutionAbortController = new AbortController();
};
