import type { ReduxState } from "main/src/modules/common/interfaces";
import { useSelector } from "react-redux";
/**
 * Returns boolean value, based on whether or not debug mode is enabled
 * True - if Debug mode is enabled
 * False - if Debug mode is disabled
 */
export const useDebugMode = () => {
  const debugMode = useSelector(
    (state: ReduxState) => state?.authentication?.user?.field_debug_enabled
  );

  return debugMode;
};
