/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import type { FC } from "react";
import { useState, useMemo } from "react";
import type {
  DropdownStatusDefault,
  CommentChannel,
  AdjudicationStatus
} from "@certa/types";
import { useCommentsContext } from "../../comments.context";
import { NoComments } from "./NoComments";
import { useIntl } from "react-intl";
import { SingleChannelView } from "./SingleChannelView/SingleChannelView";
import { MultiChannelViewAppRedesign } from "./MultiChannelViewAppRedesign";

const ChannelViewIdentifier: FC<{
  channels: CommentChannel[];
  defaultStatus?: DropdownStatusDefault;
  defaultAdjudicationCode?: AdjudicationStatus;
  adjudicationRiskCodes?: string[];
  multiChannelWithTabs?: boolean;
}> = ({
  channels,
  defaultStatus,
  defaultAdjudicationCode,
  adjudicationRiskCodes,
  multiChannelWithTabs
}) => {
  const intl = useIntl();

  const [threadWithLatestMessage, setLatestMessageThread] =
    useState<null | Number>(null);

  const { isMultiChannel, defaultThreadId, activeChannel, setActiveChannel } =
    useCommentsContext();

  const totalMessagesInAllChannels = useMemo(
    () =>
      channels.reduce(
        (total: number, channelContext: CommentChannel) =>
          total +
          channelContext.allThreads.reduce(
            (total, threadMeta) => total + threadMeta.messageCount,
            0
          ),
        0
      ),
    [channels]
  );

  const defaultActiveThread = useMemo(() => {
    const channel = activeChannel || channels[0];
    if (defaultThreadId) {
      const threadId = defaultThreadId;
      if (channel?.allThreads.find(thread => thread.threadId === threadId)) {
        return threadId;
      }
    } else {
      return threadWithLatestMessage || channel?.allThreads[0]?.threadId;
    }
  }, [activeChannel, channels, defaultThreadId, threadWithLatestMessage]);

  const handleChannelSelect = (
    channel: CommentChannel,
    threadWithLatestMessage: number
  ) => {
    setActiveChannel(channel);
    setLatestMessageThread(threadWithLatestMessage);
  };

  const noCommentsMessage = intl.formatMessage({
    id: "comments.noCommentsYetMessage",
    defaultMessage: "No comments yet"
  });

  const noCommentsGenericSubHeading = intl.formatMessage({
    id: "comments.noCommentsInWorkflow",
    defaultMessage: "There are no comments in this workflow"
  });

  /**
   * If there are no channels
   * - OR
   * If the view is multi-channel and total messages in all channels=>threads is 0
   * - Then
   * Render the no comments UI
   */
  if (!channels.length || (isMultiChannel && totalMessagesInAllChannels === 0))
    return (
      <NoComments
        heading={noCommentsMessage}
        subHeading={noCommentsGenericSubHeading}
        isMultiChannel
      />
    );

  if (isMultiChannel && !activeChannel) {
    return (
      <MultiChannelViewAppRedesign
        channels={channels}
        handleChannelSelect={handleChannelSelect}
        multiChannelWithTabs={multiChannelWithTabs}
      />
    );
  }

  return (
    <SingleChannelView
      channel={activeChannel || channels[0]}
      defaultActiveThread={defaultActiveThread as number}
      defaultStatus={defaultStatus}
      defaultAdjudicationCode={defaultAdjudicationCode}
      adjudicationRiskCodes={adjudicationRiskCodes}
    />
  );
};

export { ChannelViewIdentifier };
