import { cloneDeep, merge as lodashMerge } from "lodash-es";
import {
  createProcessValueTag,
  createStepGroupValueTag,
  createStepValueTag,
  getCustomAttributeTag
} from "../utils/report";
import type {
  ChartConfigORM,
  ColumnORM,
  FilterConditionORM,
  FilterORM,
  GroupByORM,
  OperationArgumentORM,
  OperationORM,
  OrderByORM,
  OtherChartConfigurations,
  AttributesPayloadORM,
  FilterPayloadORM,
  GroupByPayloadORM,
  OperationPayloadORM,
  OrderByPayloadORM,
  OtherChartConfigurationsPayloadORM
} from "@certa/types";
import {
  defaultChartConfigORM,
  defaultOtherConfigurationsORM
} from "../constants";

export const chartConfigModelCreatorORM = (data: any) => {
  const chartConfig: ChartConfigORM = {
    attributes: [],
    operations: [],
    groupBy: [],
    orderBy: [],
    otherConfigurations: cloneDeep(defaultChartConfigORM.otherConfigurations)
  };

  if (data.attributes && Array.isArray(data.attributes)) {
    chartConfig.attributes = attributesModelCreatorORM(data.attributes);
  }
  if (data.operations && Array.isArray(data.operations)) {
    const operations = operationsModelCreatorORM(data.operations);
    if (operations) {
      chartConfig.operations = operations;
    }
  }
  if (data.group_by && Array.isArray(data.group_by)) {
    chartConfig.groupBy = groupByModelCreatorORM(data.group_by);
  }
  if (data.order_by && Array.isArray(data.order_by)) {
    chartConfig.orderBy = orderByModelCreatorORM(data.order_by);
  }

  if (data.other_configurations) {
    chartConfig.otherConfigurations = lodashMerge(
      chartConfig.otherConfigurations,
      otherConfigurationsModelCreatorORM(data.other_configurations)
    );
  }

  return chartConfig;
};

export const attributesModelCreatorORM = (
  attributes: AttributesPayloadORM[]
): ColumnORM[] => {
  return attributes.map(attribute => ({
    joinRelation: attribute.join_relation,
    kindId: attribute.kind_id,
    label: attribute.label,
    tag: attribute.tag,
    type: attribute.type,
    value: attribute.value,
    extraJSON: {
      //TODO:PLAT-16404 remove this backward compatibility
      addedBy:
        attribute.extra_json?.added_by ?? attribute.extra_json?.is_added_by,
      fieldType: attribute.extra_json?.field_type,
      //TODO:PLAT-16404 remove this backward compatibility
      isHidden: attribute.extra_json?.is_hidden ?? attribute.extra_json?.hidden,
      customTag: createCustomTag({
        kindId: attribute.kind_id,
        tag: attribute.tag,
        type: attribute.type,
        value: attribute.value
      })
    }
  }));
};

const operationsModelCreatorORM = (
  operations: OperationPayloadORM[]
): OperationORM[] => {
  return operations.map(operation => ({
    arguments: operationArgumentModelCreatorORM(operation.arguments),
    columnType: operation.column_type,
    dataType: operation.data_type,
    extraArgs: {
      separator: operation.extra_args?.separator,
      compareValue: operation.extra_args?.compare_value
    },
    function: operation.function,
    joinRelation: operation.join_relation,
    kindId: operation.kind_id,
    label: operation.label,
    tag: operation.tag,
    // TODO: PLAT-16404 remove this backward compatibility
    extraJSON: {
      axisName: operation.extra_json?.axis_name ?? operation.extra?.axisName,
      outputDataType:
        operation.extra_json?.output_data_type ??
        operation.extra?.outputDataType,
      customTag: operation.extra_json?.custom_tag ?? operation.extra?.customTag
    }
  }));
};

const operationArgumentModelCreatorORM = (
  argumentsData: OperationPayloadORM["arguments"]
) => {
  return argumentsData.map(argument => {
    if (typeof argument === "string") {
      return argument;
    }
    const newArgument: OperationArgumentORM = {
      arguments: operationArgumentModelCreatorORM(argument.arguments),
      columnType: argument.column_type,
      dataType: argument.data_type,
      function: argument.function,
      label: argument.label,
      extraArgs: {
        separator: argument.extra_args?.separator,
        compareValue: argument.extra_args?.compare_value
      }
    };
    return newArgument;
  });
};

const groupByModelCreatorORM = (groupBy: GroupByPayloadORM[]): GroupByORM[] => {
  return groupBy.map(groupBy => ({
    joinRelation: groupBy.join_relation,
    kindId: groupBy.kind_id,
    operations: groupBy.operations
      ? operationsModelCreatorORM(groupBy?.operations)
      : [],
    source: groupBy.source,
    tag: groupBy.tag,
    type: groupBy.type,
    // TODO: PLAT-16404 remove this backward compatibility
    extraJSON: {
      dataSources:
        groupBy.extra_json?.data_sources ?? groupBy.extra?.dataSources,
      dataType: groupBy.extra_json?.data_type ?? groupBy.extra?.dataType,
      fieldType: groupBy.extra_json?.field_type ?? groupBy.extra?.fieldType,
      isForSecondaryXAxis:
        groupBy.extra_json?.is_for_secondary_x_axis ??
        groupBy.extra?.forSecondaryXAxis,
      label: groupBy.extra_json?.label ?? groupBy.extra?.label,
      labelOutputType:
        groupBy.extra_json?.label_output_type ?? groupBy.extra?.labelOutputType,
      operationType:
        groupBy.extra_json?.operation_type ?? groupBy.extra?.operationType,
      customTag: createCustomTag({
        kindId: groupBy.kind_id,
        tag: groupBy.tag,
        type: groupBy.type,
        value: groupBy.source
      })
    }
  }));
};

export const orderByModelCreatorORM = (
  orderBys: OrderByPayloadORM[]
): OrderByORM[] => {
  return orderBys.map(orderBy => ({
    dataType: orderBy.data_type,
    joinRelation: orderBy.join_relation,
    kindId: orderBy.kind_id,
    operations: orderBy.operations
      ? operationsModelCreatorORM(orderBy.operations)
      : undefined,
    ordering: orderBy.ordering,
    source: orderBy.source,
    tag: orderBy.tag,
    type: orderBy.type,
    extraJSON: {
      customTag: createCustomTag({
        kindId: orderBy.kind_id,
        tag: orderBy.tag,
        type: orderBy.type,
        value: orderBy.source
      })
    }
  }));
};

const otherConfigurationsModelCreatorORM = (
  config: OtherChartConfigurationsPayloadORM
): OtherChartConfigurations => {
  return {
    colors: {
      activeColorType: config.colors.active_color_type,
      singleTone: config.colors.single_tone,
      spectrum: config.colors.spectrum,
      custom: config.colors.custom
    },
    cycleTimeTag: config.cycle_time_tag,
    geoMapTheme: config.geo_map_theme,
    isCycleTimeReport: config.is_cycle_time_report,
    isStackedBarChart: config.is_stacked_bar_chart,
    percentageThreshold: config.percentage_threshold,
    precision: config.precision,
    scale: config.scale,
    showLegend: config.show_legend,
    chartOrientation:
      config.chart_orientation ??
      defaultOtherConfigurationsORM.chartOrientation,
    shouldShowPercentagesInValueLabels:
      config.show_percentages_in_value_labels ??
      config.show_percentages_per_slice,
    shouldShowValueLabels: config.show_value_labels,
    showValueOn: config.show_value_on,
    showXAxisLabel: config.show_x_axis_label,
    showYAxisLabel: config.show_y_axis_label,
    valueLabelAngle: config.value_label_angle,
    xAxisLabel: config.x_axis_label,
    yAxisLabel: config.y_axis_label,
    isGroupedBarChart: config.is_grouped_bar_chart
  };
};

export const filterModelCreatorORM = (filter: FilterPayloadORM): FilterORM => {
  const conditions: (FilterORM | FilterConditionORM)[] = filter.conditions.map(
    condition => {
      if ("type" in condition) {
        return filterModelCreatorORM(condition);
      }
      return {
        dataType: condition.data_type,
        kindId: condition.kind_id,
        joinRelation: condition.join_relation,
        lhsSource: condition.lhs_source,
        lhsType: condition.lhs_type,
        operation: condition.op,
        rhsValue: condition.rhs_value,
        tag: condition.tag,
        extraJSON: {
          addedBy: condition.extra_json?.added_by,
          //TODO: PLAT-16404 remove this backward compatibility
          fieldType: condition.extra_json?.field_type ?? condition.field_type,
          customTag: createCustomTag({
            kindId: condition.kind_id,
            tag: condition.tag,
            type: condition.lhs_type,
            value: condition.lhs_source
          })
        }
      };
    }
  );
  return {
    type: filter.type,
    conditions
  };
};

export const createCustomTag = ({
  kindId,
  tag,
  type,
  value
}: Pick<ColumnORM, "kindId" | "tag" | "type" | "value">) => {
  if (type === "attr") {
    return getCustomAttributeTag(kindId, createProcessValueTag(value));
  } else if (type === "step" && tag) {
    return getCustomAttributeTag(kindId, createStepValueTag(tag, value));
  } else if (type === "step_group" && tag) {
    return getCustomAttributeTag(kindId, createStepGroupValueTag(tag, value));
  } else if (type === "field" && tag) {
    return getCustomAttributeTag(kindId, tag);
  }
  return getCustomAttributeTag(kindId, value);
};
