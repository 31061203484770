import type { ActivityLogModelType } from "../types";

export const useActivityLogModelCreator = (
  activityLogList: Array<any>
): Array<ActivityLogModelType> =>
  activityLogList.map(
    (activityLog: any): ActivityLogModelType =>
      ({
        actionCode: activityLog["action_code"],
        actionType: activityLog["action_type"],
        actorEmail: activityLog["actor_email"],
        actorName: activityLog["actor_name"],
        changes: activityLog["changes"],
        details: activityLog["details"],
        objectId: activityLog["object_id"],
        objectType: activityLog["object_type"],
        objectName: activityLog["object_name"],
        parentObjectId: activityLog["parent_object_id"],
        parentObjectType: activityLog["parent_object_type"],
        requestId: activityLog["request_id"],
        tenant: activityLog["tenant"],
        timestamp: activityLog["timestamp"],
        workflowId: activityLog["workflow_id"],
        workflowKind: activityLog["workflow_kind_name"],
        workflowName: activityLog["workflow_name"],
        id: activityLog["_id"]?.["$oid"],
        isCreatedByBot: !!(
          !activityLog["actor_email"] ||
          activityLog["actor_email"] === "certa_bot@certa.in"
        )
      }) as ActivityLogModelType
  );
