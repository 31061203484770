import {
  getFieldTypeFromField,
  isIntegrationField
} from "@certa/queries/src/utils/fields.utils";
import type {
  IStepVersionDetails,
  IStepVersionFieldDefinition,
  IFieldResponseAPI,
  IFieldDataInFieldResponse
} from "./interfaces";
import type {
  StepDetails,
  Field,
  FieldAlert,
  DependentSteps,
  FieldCommentFlag
} from "@certa/types";
import { shouldRenderField } from "./shouldRenderField";

export const getHiddenDependentFieldsAndNormalFields = (
  fields: any,
  mergeHiddenFields: boolean
) => {
  const hiddenDependentFields = fields.filter(
    (field: any) => !shouldRenderField(field)
  );
  if (mergeHiddenFields) {
    return {
      normalFields: fields,
      /**
       * We are returning hidden fields in case of `mergeHiddenFields`
       * Because it is the case of two order of dependent fields
       * 1. Hidden fields are dependent on normal fields
       * 2. Normal fields are dependent on hidden fields
       * And this 2nd order is not possible to handle in the current implementation of normal fields
       */
      hiddenDependentFields
    };
  }
  return {
    normalFields: fields.filter((field: any) => shouldRenderField(field)),
    hiddenDependentFields: hiddenDependentFields
  };
};

const getField = (field: any, jsonFromAPI: any): Field => ({
  id: field.id,
  alerts: field.alerts.map(
    (alert: any): FieldAlert => ({
      id: alert.id,
      catColorLabel: alert.cat_color_label,
      alertTag: alert.alert_tag,
      stepId: jsonFromAPI.id,
      fieldId: field.id
    })
  ),
  // TODO: Remove in the 2nd pass when table is not using it from here
  // anymore. Currently it helps table to use the useIsDisabled hook
  // which requires the step number to be part of field's model, to
  // get step props from redux. WHEN REMOVING, MAKE SURE TO UPDATE MODEL
  step: jsonFromAPI.id,
  commentCount: field.comment_count,
  integrationCommentCounts: field.integration_comment_counts,
  searchParamData: field.search_param_data,
  isRequired: field.is_required,
  regexValue: field.regex_value,
  regexError: field.regex_error,
  labelValue: field.label_value,
  createdAt: field.created_at,
  updatedAt: field.updated_at,
  isEnabled: field.is_enabled,
  integrationJSON: field.integration_json,
  extra: field.extra,
  statusIndicator: field.status_indicator
    ? {
        indicator: field.status_indicator.indicator,
        helpText: field.status_indicator.help_text
      }
    : null,
  commentFlags: field.comment_flags.map(
    (flag: any): FieldCommentFlag => ({
      uidOrFieldId: flag.uid_or_field_id,
      commentFlagOptionId: flag.comment_flag_option_id,
      commentFlagOptionTag: flag.comment_flag_option_tag
    })
  ),
  answer: field.answer
    ? {
        id: field.answer.id,
        submittedBy: field.answer.submitted_by
          ? {
              id: field.answer.submitted_by.id,
              firstName: field.answer.submitted_by.first_name,
              lastName: field.answer.submitted_by.last_name,
              email: field.answer.submitted_by.email
            }
          : null,
        submittedAt: field.answer.submitted_at,
        answer: field.answer.answer,
        files: field.answer.files,
        attachment: field.answer.attachment,
        extraJSON: field.answer.extra_json,
        updatedAt: field.answer.updated_at
      }
    : null,
  definition: {
    id: field.definition.id,
    fieldType: getFieldTypeFromField(field),
    body: field.definition.body,
    tag: field.definition.tag,
    helpText: field.definition.help_text,
    isRequired: field.definition.is_required,
    regexValue: field.definition.regex_value,
    regexError: field.definition.regex_error,
    size: field.definition.size,
    attachment: field.definition.attachment,
    defaultValue: field.definition.default_value,
    disabled: field.definition.disabled,
    hidden: field.definition.hidden,
    extra: field.definition.extra
      ? {
          ...field.definition.extra,
          isIntegrationField: isIntegrationField(field)
        }
      : field.definition.extra,
    meta: field.definition.meta,
    options: field.definition.options,
    isEncrypted: field.definition.is_encrypted
  },
  isReadOnly: field.is_read_only
});

/**
 * @deprecated
 *
 * Moved to @certa/queries
 */
export const stepDetailsModelCreator = (
  jsonFromAPI: any,
  mergeHiddenFields = false
): StepDetails => {
  const { hiddenDependentFields, normalFields } =
    getHiddenDependentFieldsAndNormalFields(
      jsonFromAPI.fields,
      mergeHiddenFields
    );

  return {
    id: jsonFromAPI.id,
    commentCount: jsonFromAPI.comment_count,
    isEditable: jsonFromAPI.has_write_access,
    completedBy: jsonFromAPI.completed_by
      ? {
          id: jsonFromAPI.completed_by.id,
          firstName: jsonFromAPI.completed_by.first_name,
          lastName: jsonFromAPI.completed_by.last_name,
          email: jsonFromAPI.completed_by.email
        }
      : null,
    completedAt: jsonFromAPI.completed_at,
    versions: jsonFromAPI.versions.map((version: any) => ({
      value: version.value,
      submittedAt: version.submitted_at,
      versionLabel: version.version_indicator_label,
      versionLabelColor: version.version_indicator_color
    })),
    approvedAt: jsonFromAPI.approved_at,
    createdAt: jsonFromAPI.created_at,
    updatedAt: jsonFromAPI.updated_at,
    isLocked: jsonFromAPI.is_locked,
    fields: normalFields.map(
      (field: any): Field => getField(field, jsonFromAPI)
    ),
    hiddenDependentFields: hiddenDependentFields.map(
      (field: any): Field => getField(field, jsonFromAPI)
    ),
    definition: {
      id: jsonFromAPI.definition.id,
      name: jsonFromAPI.definition.name,
      tag: jsonFromAPI.definition.tag,
      availableUserTags: jsonFromAPI.definition.available_user_tags,
      extra: jsonFromAPI.definition.extra,
      dependentSteps: jsonFromAPI.definition.dependent_steps.map(
        (step: any): DependentSteps => ({
          id: step.id,
          name: step.name,
          definitionTag: step.tag
        })
      ),
      stepType: jsonFromAPI.definition.step_type,
      disableSubmitReason: jsonFromAPI.definition.disable_submit_reason
    },
    /**
     * Permission that BE is handling for step submit
     * - User should have write access to the step
     * - The intersection groups of between user groups and the user groups of workflow, stepgroup and step -> if this intersection is containing step submit
     * - Step should be incomplete -> step.completed_at = None
     * - Step should be visible -> step.is_enabled = True
     * - Step should be not locked -> step.is_locked = False
     * - Step should be doable -> step.definition.step_type = "Doable"
     */
    canSubmitStep: jsonFromAPI.can_submit,
    /**
     * Permission that BE is handling for step undo
     * - User should have write access to the step
     * - The intersection groups of between user groups and the user groups of workflow,stepgroup and step -> if this intersection is containing step undo
     * - Step should be complete -> step.completed_at = DateTime
     * - Step should be visible -> step.is_enabled = True
     * - Step should be not locked -> step.is_locked = False
     * - Step should be doable -> step.definition.step_type = "Doable"
     */
    canUndoStep: jsonFromAPI.can_undo,
    hasWriteAccess: jsonFromAPI.has_write_access,
    isAppBuilderFrozen: jsonFromAPI.is_app_builder_frozen
  };
};

export const stepVersionModelCreator = (
  jsonFromAPI: any
): IStepVersionDetails => ({
  fields: jsonFromAPI.fields.map(
    (field: any): IStepVersionFieldDefinition => ({
      id: field.id,
      answer: {
        id: field.answer.id,
        submittedAt: field.answer.submitted_at,
        answer: field.answer.answer,
        attachment: field.answer.attachment,
        extraJSON: field.answer.extra_json,
        updatedAt: field.answer.updated_at,
        files:
          field.answer.files?.map((file: any) => ({
            name: file.name,
            size: file.size,
            storageName: file.storage_name,
            submittedAt: file.submitted_at,
            submittedById: file.submitted_by_id,
            uid: file.uid,
            url: file.url
          })) || null,
        submittedBy: field.answer.submitted_by
          ? {
              id: field.answer.submitted_by.id,
              firstName: field.answer.submitted_by.first_name,
              lastName: field.answer.submitted_by.last_name,
              email: field.answer.submitted_by.email
            }
          : null
      }
    })
  ),
  completedBy: jsonFromAPI.completed_by
    ? {
        id: jsonFromAPI.completed_by.id,
        firstName: jsonFromAPI.completed_by.first_name,
        lastName: jsonFromAPI.completed_by.last_name,
        email: jsonFromAPI.completed_by.email
      }
    : null,
  completedAt: jsonFromAPI.completed_at
});

export const fieldResponseModelCreator = (
  jsonFromAPI: any
): IFieldResponseAPI => ({
  id: jsonFromAPI.id,
  completedBy: jsonFromAPI.completed_by
    ? {
        id: jsonFromAPI.completed_by.id,
        firstName: jsonFromAPI.completed_by.first_name,
        lastName: jsonFromAPI.completed_by.last_name,
        email: jsonFromAPI.completed_by.email
      }
    : null,
  completedAt: jsonFromAPI.completed_at,
  approvedAt: jsonFromAPI.approved_at,
  updatedAt: jsonFromAPI.updated_at,
  isLocked: jsonFromAPI.is_locked,
  fields: jsonFromAPI.fields.map(
    (field: any): IFieldDataInFieldResponse => ({
      id: field.id,
      searchParamData: field.search_param_data,
      isRequired: field.is_required,
      regexValue: field.regex_value,
      regexError: field.regex_error,
      labelValue: field.label_value,
      isReadOnly: field.is_read_only,
      integrationJSON: field.integration_json,
      extra: field.extra,
      statusIndicator: field.status_indicator
        ? {
            indicator: field.status_indicator.indicator,
            helpText: field.status_indicator.help_text
          }
        : null,
      answer: field.answer
        ? {
            id: field.answer.id,
            submittedBy: field.answer.submitted_by
              ? {
                  id: field.answer.submitted_by.id,
                  firstName: field.answer.submitted_by.first_name,
                  lastName: field.answer.submitted_by.last_name,
                  email: field.answer.submitted_by.email
                }
              : null,
            submittedAt: field.answer.submitted_at,
            answer: field.answer.answer,
            attachment: field.answer.attachment,
            extraJSON: field.answer.extra_json,
            updatedAt: field.answer.updated_at,
            files: field.answer.files
          }
        : null
    })
  )
});
