import type { FunctionComponent } from "react";
import React from "react";
import { Header } from "./Header";

export const withHeader =
  (Component: FunctionComponent) =>
  (props: React.ComponentProps<typeof Component>) => (
    <>
      <Header />
      <Component {...props} />
    </>
  );
