import { useEnableV3SearchAPI } from "../../toggles";
import { SearchDropdownWrapper } from "./SearchDropdownWrapper";
import { GlobalSearch } from "../GlobalSearch";

const SearchSwitch = () => {
  const enableV3SearchAPI = useEnableV3SearchAPI();

  if (enableV3SearchAPI) {
    return <GlobalSearch />;
  } else {
    return <SearchDropdownWrapper />;
  }
};

export { SearchSwitch as SearchDropdown };
