import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst";
import { Fragment } from "react";
import type { BreadcrumbsProps } from "./Breadcrumbs.types";
import { css } from "emotion";

export const BreadcrumbsTooltip = (
  props: Pick<BreadcrumbsProps, "parentToChildHierarchy" | "highlightText">
) => {
  const { parentToChildHierarchy: hierarchy } = props;
  return (
    <div
      className={css({
        maxWidth: 600,
        padding: "var(--s2) var(--s4)",
        "> *": {
          display: "inline",
          verticalAlign: "middle",
          marginRight: 8
        }
      })}
    >
      {hierarchy.map((item, index) => (
        <Fragment key={item.id}>
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.NEUTRAL_800}
          >
            {item.name}
          </Typography>
          {index !== hierarchy.length - 1 && (
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_800}
            >
              &rarr;
            </Typography>
          )}
        </Fragment>
      ))}
    </div>
  );
};
