import loadable from "@loadable/component";
import { AnonOnlyRoute } from "./AnonOnlyRoute";
import { Route } from "react-router-dom";
import { certaGoogleLoginURL } from "../../config";
import { PrivateRoute } from "../../js/components/PrivateRoute";
import { ServiceUnavailableError } from "../../js/components/common/ServiceUnavailableError";
import { UserInteractionRequired } from "../../js/components/common/UserInteractionRequired";
import { withHeader } from "../header/components/withHeader";
import { GenericNotFound } from "../../js/components/notfound";
import ChartExport from "@certa/chartreport";
import {
  getDashboardsGenericRoute,
  getDashboardsRoute,
  getTasksRoute,
  getProcessDetailRoute,
  getSearchRoute,
  getCreateNewReportRoute,
  getEditReportRoute,
  getFullScreenReportRoute,
  HeaderMode,
  getProcessWizardRoute,
  getHomeRoute,
  getStudioGenericRoute,
  getStudioAppDetailsRoute,
  getStudioProcessEditorRoute,
  persistNextUrlOnSSO,
  getStudioAppSettingsRoute,
  getStudioProcessDesignerRoute,
  SERVICE_UNAVAILABLE_PATH,
  getInstanceSettingsRoute,
  getOldFullScreenReportRoute,
  USER_INTERACTION_REQUIRED_PATH,
  getOldEditReportRoute,
  RELOAD_REQUIRED_ROUTE,
  TABLEAU_PATH
} from "@certa/common";
import { ReloadRequiredPage } from "../../js/components/common/ReloadRequired";

// Platform
const Login = loadable(() => import("../LoginPage/Login"));
const Home = loadable(() => import("@certa/homepage"));
const DownloadFile = loadable(
  () => import("@certa/common/src/DownloadFileRoute")
);
const AttachmentView = loadable(() => import("../AttachmentView"));
const Dashboards = loadable(() => import("@certa/dashboards/DashboardsRoute"));
const CreateReport = loadable(
  () => import("@certa/dashboards/createReportRoute")
);
const MySubscriptions = loadable(
  () => import("@certa/dashboards/mySubscriptionsRoute")
);
const EditReport = loadable(() => import("@certa/dashboards/editReportRoute"));
const FullScreenReport = loadable(
  () => import("@certa/dashboards/fullScreenReportRoute")
);
const Tasks = loadable(() => import("@certa/tasks"));
const ProcessDetails = loadable(() => import("@certa/processdetails"));
const ProcessWizard = loadable(
  () => import("@certa/processdetails/src/wizard")
);
const Paypal = loadable(
  () => import("@certa/processdetails/src/integrationRedirects/Paypal")
);
const SearchLayout = loadable(() => import("@certa/search"));

// Studio
const Studio = loadable(() => import("@certa/studio"));
const ProcessEditorRoute = loadable(
  () => import("@certa/studio/ProcessEditorRoute")
);
const AppDetailsRoute = loadable(() => import("@certa/studio/AppDetailsRoute"));
const InstanceSettingsRoute = loadable(
  () => import("@certa/studio/InstanceSettingsRoute")
);
const StudioWebGLProcessEditorRoute = loadable(
  () => import("@certa/studio-webgl/ProcessEditorRoute")
);
const TableauWrapper = loadable(
  () => import("@certa/common/src/components/TableauReport")
);

/**
 * Used for re-direction to google login
 */
const redirectToCertaLogin = () => {
  persistNextUrlOnSSO();
  window.location.href = certaGoogleLoginURL;
  return null;
};

const dashboardSliceIndex = 3;

const redirectToNewReportRoute = (props: { location: Location }) => {
  const { hash, pathname, search } = props.location;
  const redirectUrl = `${document.location.origin}/${pathname
    .split("/")
    // Removes dashboard part from the url
    .slice(dashboardSliceIndex)
    // Add back search query and hash
    .join("/")}${search}${hash}`;
  window.location.href = redirectUrl;
  return null;
};

/**
 * NOTE: Comments in imports statements are added to get
 * readable chunk names in network tab when lazy components are loaded
 */

/**
 * Routes served to non-authenticated users
 */
const publicRoutes = [
  {
    exact: true,
    path: "/login/export-chart",
    routeType: AnonOnlyRoute,
    isLazy: false,
    component: ChartExport
  },
  {
    exact: true,
    path: "/login",
    routeType: AnonOnlyRoute,
    isLazy: true,
    component: Login
  },
  {
    exact: true,
    path: "/login/certa",
    component: redirectToCertaLogin,
    routeType: Route,
    isLazy: false
  },
  {
    exact: true,
    path: "/login/:loginType",
    routeType: AnonOnlyRoute,
    isLazy: true,
    component: Login
  }
];

/**
 * Routes served to authenticated user
 */
const privateRoutes = [
  /////////////////////////////////////////////////////
  // HOME ROUTES
  /////////////////////////////////////////////////////
  {
    exact: true,
    path: getHomeRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: Home,
    headerMode: HeaderMode.DEFAULT
  },

  /////////////////////////////////////////////////////
  // DASHBOARDS ROUTES
  /////////////////////////////////////////////////////
  {
    exact: true,
    // NOTE: This route is for general navigation when the user
    // or the code doesn't know the ID & just want to land on the dashboard
    path: getDashboardsGenericRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: Dashboards,
    headerMode: HeaderMode.DEFAULT,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: getDashboardsRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: Dashboards,
    headerMode: HeaderMode.DEFAULT,
    isMobileInCompatible: true
  },

  /////////////////////////////////////////////////////
  // REPORTS ROUTES
  /////////////////////////////////////////////////////
  {
    exact: true,
    path: getCreateNewReportRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: CreateReport,
    headerMode: HeaderMode.DEFAULT,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: getEditReportRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: EditReport,
    headerMode: HeaderMode.DEFAULT,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: getFullScreenReportRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: FullScreenReport,
    headerMode: HeaderMode.DEFAULT,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: getOldEditReportRoute(),
    routeType: PrivateRoute,
    isLazy: false,
    component: redirectToNewReportRoute,
    headerMode: HeaderMode.DEFAULT,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: getOldFullScreenReportRoute(),
    component: redirectToNewReportRoute,
    routeType: PrivateRoute,
    isLazy: false,
    isMobileInCompatible: true
  },

  /////////////////////////////////////////////////////
  // TASKS ROUTES
  /////////////////////////////////////////////////////
  {
    exact: true,
    path: getTasksRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: Tasks,
    headerMode: HeaderMode.DEFAULT,
    isMobileInCompatible: true
  },

  /////////////////////////////////////////////////////
  // PROCESS ROUTES
  /////////////////////////////////////////////////////
  {
    exact: true,
    path: getProcessDetailRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: ProcessDetails,
    headerMode: HeaderMode.DEFAULT
  },
  {
    path: getProcessWizardRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: ProcessWizard,
    headerMode: HeaderMode.NONE
  },

  /////////////////////////////////////////////////////
  // SEARCH ROUTES
  /////////////////////////////////////////////////////
  {
    path: getSearchRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: SearchLayout,
    headerMode: HeaderMode.DEFAULT
  },

  /////////////////////////////////////////////////////
  // INSTANCE SETTINGS ROUTES
  /////////////////////////////////////////////////////
  {
    exact: true,
    path: getInstanceSettingsRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: InstanceSettingsRoute,
    headerMode: HeaderMode.NONE,
    isMobileInCompatible: true
  },

  /////////////////////////////////////////////////////
  // STUDIO ROUTES
  /////////////////////////////////////////////////////
  {
    exact: true,
    path: getStudioGenericRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: Studio,
    headerMode: HeaderMode.STUDIO,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: getStudioProcessEditorRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: ProcessEditorRoute,
    headerMode: HeaderMode.NONE,
    isMobileInCompatible: true
  },
  {
    exact: false,
    path: getStudioAppSettingsRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: AppDetailsRoute,
    headerMode: HeaderMode.STUDIO,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: getStudioAppDetailsRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: AppDetailsRoute,
    headerMode: HeaderMode.STUDIO,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: getStudioAppDetailsRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: AppDetailsRoute,
    headerMode: HeaderMode.STUDIO,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: getStudioProcessDesignerRoute(),
    routeType: PrivateRoute,
    isLazy: true,
    component: StudioWebGLProcessEditorRoute,
    headerMode: HeaderMode.NONE,
    isMobileInCompatible: true
  },
  {
    exact: true,
    path: TABLEAU_PATH,
    routeType: PrivateRoute,
    isLazy: true,
    component: TableauWrapper
  },

  /////////////////////////////////////////////////////
  // MISCELLANEOUS ROUTES
  /////////////////////////////////////////////////////
  {
    exact: true,
    path: "/download/",
    routeType: PrivateRoute,
    isLazy: true,
    component: DownloadFile,
    headerMode: HeaderMode.LEGACY
  },
  {
    path: "/api/v1/attachment/",
    routeType: PrivateRoute,
    isLazy: true,
    component: AttachmentView,
    headerMode: HeaderMode.LEGACY
  },
  {
    exact: true,
    path: "/mysubscriptions/",
    routeType: PrivateRoute,
    isLazy: true,
    component: MySubscriptions,
    headerMode: HeaderMode.NONE
  },
  {
    exact: true,
    path: `/paypal/connect`,
    routeType: PrivateRoute,
    isLazy: true,
    component: Paypal,
    headerMode: HeaderMode.NONE
  }
];

/**
 * Routes common throughout platform
 */
const genericRoutes = [
  {
    routeType: Route,
    path: SERVICE_UNAVAILABLE_PATH,
    component: withHeader(ServiceUnavailableError)
  },
  {
    routeType: Route,
    path: USER_INTERACTION_REQUIRED_PATH,
    component: UserInteractionRequired
  },
  {
    routeType: Route,
    path: RELOAD_REQUIRED_ROUTE,
    component: ReloadRequiredPage
  },
  {
    routeType: Route,
    path: "/",
    component: withHeader(GenericNotFound)
  }
];

export const routeList = [...publicRoutes, ...privateRoutes, ...genericRoutes];

/**
 * Function to preload auth modules
 */
export const preloadAuthModules = () => {
  publicRoutes.forEach(route => {
    const module = route.component as any;
    route.isLazy && module.preload();
  });
};

/**
 * Function to preload private modules
 */
export const preloadPrivateModules = () => {
  privateRoutes.forEach(route => {
    const module = route.component as any;
    route.isLazy && module.preload();
  });
};
