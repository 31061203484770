import type { FC } from "react";
import React from "react";
import { css } from "emotion";
import { Timeline } from "antd";

type ActivityLogItemChangeProps = {
  event: string;
};
export const ActivityLogItemChange: FC<ActivityLogItemChangeProps> = props => {
  const { event } = props;
  return (
    <Timeline.Item
      style={{ paddingBottom: 10 }}
      key={event}
      className={css`
        .ant-timeline-item-content {
          width: 85%;
        }
      `}
    >
      {event}
    </Timeline.Item>
  );
};
