import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { Alert as AntdAlert } from "antd";
import { getConfig } from "./constants";
import type { AlertProps } from "./types";
import { Close } from "@certa/icons";
import { Text } from "../../componentsTh/Typography/Text";

const CustomAlert: FC<AlertProps> = props => {
  const { type, message, description, closable, showIcon, ...restProps } =
    props;
  const { icon: Icon, color } = getConfig(type);

  return (
    <AntdAlert
      type={type}
      message={
        message && (
          <Text
            variant={description ? "p1-bold" : "p1-regular"}
            color={type === "error" ? "red" : "neutral-100"}
          >
            {message}
          </Text>
        )
      }
      description={
        description && <Text variant="p1-regular">{description}</Text>
      }
      icon={
        <Icon
          color={color}
          style={{ height: "var(--s5)", width: "var(--s4)" }}
        />
      }
      showIcon={showIcon}
      closeText={closable ? <Close /> : undefined}
      {...restProps}
    />
  );
};

CustomAlert.defaultProps = {
  type: "info",
  showIcon: true
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Alert } from '@certa/catalyst';
 */
export const Alert = styled(CustomAlert)`
  &.ant-alert {
    width: 100%;
    border-radius: var(--big-border-radius);
    border-width: ${props => (props.showBorder ? "1px" : "0")};
    border-color: var(--${props => getConfig(props.type).color});
    padding: var(--s2) var(--s4);
  }

  .ant-alert-icon {
    position: absolute;
    left: var(--s4);
    top: var(--s2);
  }

  .ant-alert-close-icon {
    top: var(--s2);
    line-height: var(--s5);
    height: var(--s5);
    font-size: unset;
    opacity: 0.6;
    transition: 0.24s;

    &:hover {
      opacity: 1;
    }

    .ant-alert-close-text {
      line-height: var(--s5);
      color: var(--neutral-70);
      display: flex;
    }
  }

  &.ant-alert-success {
    background-color: var(--green-40);
    .ant-alert-icon {
      color: var(--green);
    }
  }

  &.ant-alert-info {
    background-color: var(--teal-40);
    .ant-alert-icon {
      color: var(--teal);
    }
  }

  &.ant-alert-error {
    background-color: var(--red-40);
    .ant-alert-icon {
      color: var(--red);
    }
  }

  &.ant-alert-warning {
    background-color: var(--orange-40);
    .ant-alert-icon {
      color: var(--orange);
    }
  }

  .ant-alert-message {
    display: block;
    margin-left: ${props => (props.showIcon ? "var(--s6)" : "0")};
    margin-right: ${props => (props.closable ? "var(--s7)" : "0")};
    margin-bottom: 0;
    color: var(--neutral-100);
    font-size: 12px;
    line-height: var(--s5);
    min-height: var(--s5);
    font-weight: ${props => (props.description ? "700" : "400")};
    &:empty {
      display: none;
    }
  }

  .ant-alert-description {
    display: block;
    margin-left: ${props => (props.showIcon ? "var(--s6)" : "0")};
    margin-right: ${props => (props.closable ? "var(--s7)" : "0")};
    color: var(--neutral-100);
    font-size: 12px;
    line-height: var(--s5);
    min-height: ${props => (props.description ? "var(--s5)" : "0")};
    &:empty {
      display: none;
    }
    & ul,
    & ol {
      padding-left: var(--s5);
      li {
        margin: var(--s1) 0;
      }
    }
  }

  --label: CustomAntdAlert;
`;
