import type { StudioTemplateTypes } from "@certa/types";
import type { PaginatedResultsResponse } from "../types/generic.types";
import type {
  StudioAppResponse,
  StudioApp,
  StudioProcessResponse,
  StudioProcess,
  UserResponse,
  User,
  StudioBranchSavePointResponse,
  StudioBranchSavePoint,
  StudioProcessBySavePointResponse,
  StudioProcessBySavePoint,
  SavepointDeploymentLogResponse,
  SavepointDeploymentLog,
  ProcessDeploymentLogResponse,
  ProcessDeploymentLog,
  StudioPermissions,
  StudioPermissionsResponse,
  StudioCentralTemplate,
  TemplateIntegrationTypes,
  TemplateStatusTypes,
  AppSettingsDeploymentError,
  AppSettingErrorSaveLog,
  AppsettingsErrorDetails,
  StudioBranch,
  StudioBranchResponse
} from "../types/studio.types";
import type { ExternalProcessResponse } from "../types/workflow.types";
import { isEmpty, flatMap, camelCase } from "lodash-es";
// App

export const studioAppModelCreator = (
  response: StudioAppResponse
): StudioApp => {
  return {
    id: response.id,
    name: response.name,
    description: response.description,
    workflowId: response.workflow_id
  };
};

export const studioAppsModelCreator = (
  response: PaginatedResultsResponse<StudioAppResponse>
) => ({
  count: response.count,
  results: response.results.map(studioAppModelCreator),
  next: response.next,
  previous: response.previous
});

// Process

export const studioProcessModelCreator = (
  response: StudioProcessResponse
): StudioProcess => ({
  id: response.id,
  name: response.name,
  description: response.description,
  workflowKindTag: response.workflow_kind_tag,
  createdBy: response.created_by && userModelCreator(response.created_by)
});

export const studioProcessesModelCreator = (
  response: PaginatedResultsResponse<StudioProcessResponse>
) => response.results.map(studioProcessModelCreator);

// Branch

export const studioBranchSavePointModelCreator = (
  response: StudioBranchSavePointResponse
): StudioBranchSavePoint => ({
  id: response.id,
  title: response.title,
  description: response.description,
  stage: response.stage,
  createdAt: new Date(response.created_at),
  updatedAt: new Date(response.updated_at),
  stageSetBy: response.stage_set_by && userModelCreator(response.stage_set_by)
});

export const studioBranchSavePointsModelCreator = (
  response: PaginatedResultsResponse<StudioBranchSavePointResponse>
) => ({
  ...response,
  results: response.results.map(studioBranchSavePointModelCreator)
});

// Savepoint

export const studioProcessBySavePointModelCreator = (
  response: StudioProcessBySavePointResponse
): StudioProcessBySavePoint => ({
  id: response.id,
  process: studioProcessModelCreator(response.process)
});

export const studioProcessesBySavePointsModelCreator = (
  response: PaginatedResultsResponse<StudioProcessBySavePointResponse>
) => response.results.map(studioProcessBySavePointModelCreator);

// TODO: Possibly generic

export const userModelCreator = (response: UserResponse): User => ({
  id: response.id,
  firstName: response.first_name,
  lastName: response.last_name,
  email: response.email
});

const savepointDeploymentLogModelCreator = (
  response: SavepointDeploymentLogResponse
): SavepointDeploymentLog => ({
  id: response.id,
  errors: response.errors,
  status: response.status,
  appSettingsErrors: appSettingsErrorModelCreator(
    response.platform_settings_errors
  ),
  updatedAt: response.updated_at,
  timestamps: (
    Object.keys(response.stage_timestamps) as Array<
      keyof SavepointDeploymentLogResponse["stage_timestamps"]
    >
  ).reduce(
    (a, c) => ({
      ...a,
      [c]: (
        Object.keys(response.stage_timestamps[c]) as Array<
          keyof SavepointDeploymentLogResponse["stage_timestamps"]
        >
      ).reduce(
        (d, e) => ({
          ...d,
          // to convert start_time to startTime with types
          [camelCase(e as string)]:
            response.stage_timestamps[c][
              e as string as keyof (keyof SavepointDeploymentLogResponse["stage_timestamps"])
            ]
        }),
        {} as { startTime: number; endTime: number; duration: number }
      )
    }),
    {} as SavepointDeploymentLog["timestamps"]
  )
});

export const savepointDeploymentLogsModelCreator = (
  response: PaginatedResultsResponse<SavepointDeploymentLogResponse>
) => response.results.map(savepointDeploymentLogModelCreator);

const processDeploymentLogModelCreator = (
  response: ProcessDeploymentLogResponse
): ProcessDeploymentLog => ({
  id: response.id,
  errors: response.errors,
  status: response.status,
  warnings: response.warnings,
  workflowKindTag: response.workflow_kind_tag,
  branchLogId: response.branch_log,
  updatedAt: response.updated_at,
  configErrorsWarnings: response.config_errors_warnings,
  timestamps: (
    Object.keys(response.stage_timestamps) as Array<
      keyof ProcessDeploymentLogResponse["stage_timestamps"]
    >
  ).reduce(
    (a, c) => ({
      ...a,
      [c]: (
        Object.keys(response.stage_timestamps[c]) as Array<
          keyof ProcessDeploymentLogResponse["stage_timestamps"]
        >
      ).reduce(
        (d, e) => ({
          ...d,
          // to convert start_time to startTime with types
          [camelCase(e as string)]:
            response.stage_timestamps[c][
              e as string as keyof (keyof ProcessDeploymentLogResponse["stage_timestamps"])
            ]
        }),
        {} as { startTime: number; endTime: number; duration: number }
      )
    }),
    {} as ProcessDeploymentLog["timestamps"]
  )
});

export const processDeploymentLogsModelCreator = (
  response: PaginatedResultsResponse<ProcessDeploymentLogResponse>
) => response.results.map(processDeploymentLogModelCreator);

export const studioPermissionsModelCreator = (
  response: StudioPermissionsResponse
): StudioPermissions => ({
  canAccessStudio: response.can_access_studio,
  canDeployToDevelopment: response.can_deploy_to_development,
  canDeployToProduction: response.can_deploy_to_production,
  canDeployToQA: response.can_deploy_to_qa,
  canDeployToStaging: response.can_deploy_to_staging,
  canEditBranch: response.can_edit_branch,
  canMergeBranch: response.can_merge_branch,
  canEditPackage: response.can_edit_package,
  canEditProcess: response.can_edit_process,
  isStudioSuperuser: response.studio_superuser_perm
});

const studioCentralTemplateModelCreator = (
  response: ExternalProcessResponse
): StudioCentralTemplate => ({
  id: response.id,
  uid: response.uid,
  name: response.name,
  description: response.lc_data.Description,
  type: response.lc_data.Type as StudioTemplateTypes,
  category: response.lc_data.Category,
  source: response.lc_data.Source,
  author: response.lc_data.Author,
  integrationType: response.lc_data?.[
    "Integration Type"
  ] as TemplateIntegrationTypes,
  templateStatus: response.lc_data["Template Status"] as TemplateStatusTypes,
  clientNames: response.lc_data["Client/Partner Names"]
    ? response.lc_data["Client/Partner Names"].split("~")
    : [],
  code: response.lc_data.Code
});

export const studioCentralTemplatesModelCreator = (
  response: PaginatedResultsResponse<ExternalProcessResponse>
): PaginatedResultsResponse<StudioCentralTemplate> => ({
  ...response,
  results: response.results.map(studioCentralTemplateModelCreator)
});

export const appSettingsErrorModelCreator = (
  appSettingErrors: AppSettingsDeploymentError
): AppSettingErrorSaveLog => {
  const data: AppSettingErrorSaveLog = {
    group: appSettingsErrorMsgModelCreator(appSettingErrors?.group),
    emailTemplate: appSettingsErrorMsgModelCreator({
      ...appSettingErrors?.emailtemplate,
      ...appSettingErrors?.emailattachment
    }),
    region: appSettingsErrorMsgModelCreator({
      ...appSettingErrors?.region,
      ...appSettingErrors?.businessunit
    }),
    extraJSON: appSettingsErrorMsgModelCreator({
      ...appSettingErrors?.extrajson
    }),
    processKind: appSettingsErrorMsgModelCreator(
      appSettingErrors?.workflowkind
    ),
    processStatus: appSettingsErrorMsgModelCreator(
      appSettingErrors?.workflowstatus
    ),
    processPDFTemplate: appSettingsErrorMsgModelCreator(
      appSettingErrors?.workflowpdftemplate
    ),
    alertCategory: appSettingsErrorMsgModelCreator(
      appSettingErrors?.alertcategory
    ),
    commentFlagOption: appSettingsErrorMsgModelCreator(
      appSettingErrors?.commentflagoption
    ),
    feExtraMetaData: appSettingsErrorMsgModelCreator(
      appSettingErrors?.frontend_extra_metadata
    )
  };

  for (const key in data) {
    if (isEmpty(data[key as keyof AppSettingErrorSaveLog])) {
      delete data[key as keyof AppSettingErrorSaveLog];
    }
  }
  return data;
};

export const appSettingsErrorMsgModelCreator = (
  data: Record<string, any[]>
): AppsettingsErrorDetails => {
  if (!isEmpty(data)) {
    for (const key in data) {
      const flatennedErrorMsg = flatMap(data[key], error =>
        Object.values(error)
      );
      // If error message is a list of error messages
      if (Array.isArray(flatennedErrorMsg[0])) {
        data[key] = flatennedErrorMsg[0];
      } else {
        data[key] = flatennedErrorMsg;
      }
    }
  }
  return data;
};

export const studioBranchesModelCreator = (
  response: PaginatedResultsResponse<StudioBranchResponse>
): PaginatedResultsResponse<StudioBranch> => {
  return {
    ...response,
    results: response.results.map(studioBranchModelCreator)
  };
};

const studioBranchModelCreator = (
  response: StudioBranchResponse
): StudioBranch => {
  return {
    id: response.id,
    name: response.name,
    createdAt: response.created_at
  };
};
