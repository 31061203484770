import { find, includes, isEmpty } from "lodash-es";
import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import React, { useCallback } from "react";
import type { PermissionTypes } from "@certa/types";
import type { ReactNode, FC, PropsWithChildren } from "react";
import { useAppSelector } from "../../hooks";
/**
 * TODO: Convert to hook (or have a separate hook that uses this),
 * where we don't have to pass it the "allowed" permissions,
 * just the one we want to check, and it can then get
 * allowed ones from react-query and then compare it using this method.
 */
export const checkPermission = ({
  permissionsAllowed,
  permissionName
}: {
  // Actual type for permissionsAllowed is
  //  permission: Record<
  //   number,
  //   {
  //     codename: string;
  //     name: string;
  //   }
  //  >;
  // This is working because of the usage of `find` and `includes` from lodash
  permissionsAllowed: string[];
  permissionName: string;
}) => {
  return (
    permissionsAllowed &&
    find(permissionsAllowed, perm => includes(perm, permissionName))
  );
};

export const useCheckPermission = (permissionName: string) => {
  const { loading: permissionsLoading, permissions } = useSelector(
    (state: ReduxState) => state.permissions
  );
  if (permissionsLoading || isEmpty(permissions)) return false;

  return !!(
    permissions && find(permissions, perm => includes(perm, permissionName))
  );
};

/**
 * Returns a functions that checks if the current user has permissions
 * NOTE: It internally uses Array.prototype.every
 * hence for empty permissions array will return true
 */
export const useCheckPermissionFn = () => {
  const { loading: permissionsLoading, permissions: permissionsAllowed } =
    useSelector((state: ReduxState) => state.permissions);

  const hasPermissions = useCallback(
    (permissions: PermissionTypes[]) => {
      // TODO: Return a loading state instead
      if (permissionsLoading || isEmpty(permissions)) return false;

      return permissions.every(permission =>
        checkPermission({ permissionsAllowed, permissionName: permission })
      );
    },
    [permissionsLoading, permissionsAllowed]
  );

  return hasPermissions;
};

type Props = PropsWithChildren<{
  check: PermissionTypes;
  deniedElement?: ReactNode;
}>;

export const Chowkidaar: FC<Props> = ({
  children,
  check,
  deniedElement = null
}) => {
  const { loading, permissions } = useAppSelector(state => state.permissions);
  const hasPermission = !!(
    permissions && find<string>(permissions, perm => includes(perm, check))
  );
  return <>{loading ? null : hasPermission ? children : deniedElement}</>;
};
