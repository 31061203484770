export const workflowDetailsConstants = {
  STEP_INTEGRATION_PENDING: "STEP_INTEGRATION_PENDING",
  STEP_INTEGRATION_FINISHED: "STEP_INTEGRATION_FINISHED",

  GET_STEPGROUPS_REQUEST: "WORKFLOW_DETAILS_STEPGROUP_GET_REQUEST",
  GET_STEPGROUPS_SUCCESS: "WORKFLOW_DETAILS_STEPGROUP_GET_SUCCESS",
  GET_STEPGROUPS_FAILURE: "WORKFLOW_DETAILS_STEPGROUP_GET_FAILURE",

  DELETE_REQUEST: "WORFLOW_DETAILS_DELETE_REQUEST",
  DELETE_SUCCESS: "WORFLOW_DETAILS_DELETE_SUCCESS",
  DELETE_FAILURE: "WORFLOW_DETAILS_DELETE_FAILURE",

  SET_STEP_ID: "SET_CURRENT_STEP_ID",
  REMOVE_STEP_ID: "REMOVE_CURRENT_STEP_ID",

  GET_MY_TASK_REQUEST: "GET_MY_TASK_REQUEST",
  GET_MY_TASK_SUCCESS: "GET_MY_TASK_SUCCESS",
  GET_MY_TASK_FAILURE: "GET_MY_TASK_FAILURE",

  UPDATE_MY_STEPGROUP_DATA: "UPDATE_MY_STEPGROUP_DATA",
  TOGGLE_MY_TASK: "TOGGLE_MY_TASK"
};
