import { permissionConstants } from "./constants";
import { permissionService } from "./services";

/**
 *
 * @param {boolean} hideLoader Pass this if you are calling permission API when IMPERSONATED session ends.
 * @returns
 */
function getPermissions(hideLoader = false) {
  return dispatch => {
    if (!hideLoader) {
      dispatch(request());
    }

    permissionService.getPermissions().then(
      permission => dispatch(success(permission)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: permissionConstants.PERMISSION_FETCH_REQUEST };
  }

  function success(permission) {
    return {
      type: permissionConstants.PERMISSION_FETCH_SUCCESS,
      permission
    };
  }
  function failure(error) {
    return { type: permissionConstants.PERMISSION_FETCH_FAILURE, error };
  }
}

export const permissionActions = {
  getPermissions
};
