import type { FC } from "react";
import React from "react";
import { css } from "emotion";
import { Dots, Archive } from "@certa/icons";
import { Stack, Text } from "@certa/blocks/thanos";
import type { IProcessItem } from "@certa/processfamily/src/types";
import {
  getProcessDetailRoute,
  ProcessLogoWithLCData,
  Progress,
  useProcessArchive,
  WorkflowStatus
} from "@certa/common";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useProcessTaskLaneOverlay } from "@certa/processdetails/src/taskDetail/hooks/useTaskStepgroupOverlay.hook";
import {
  ButtonSizes,
  ButtonTypes,
  Button,
  DropdownMenuItemTypes,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@certa/catalyst";
import { WrapWithMigrationTooltip } from "@certa/common/src/components/WrapWithMigrationTooltip";

/**
 *
 * TODO: Move this component to @certa/common package
 */
const ProcessItem: FC<IProcessItem> = ({
  process,
  onArchive,
  inTable,
  // showOnlyProcessLabel -> defaulting to false because status is required in most cases
  showOnlyProcessLabel = false
}) => {
  const intl = useIntl();
  const isWorkflowBeingMigrated = process.isWorkflowBeingMigrated;

  const { canArchiveProcess, handleArchive } = useProcessArchive(
    process.id,
    onArchive
  );
  const [showProcessOverlay] = useProcessTaskLaneOverlay(process.id);

  return (
    <Stack
      align="center"
      justify="space-between"
      gutter="s2 s00"
      css={
        inTable &&
        css`
          &:hover {
            background-color: var(--teal-60) !important;
            .ant-progress-circle-trail {
              stroke: var(--teal) !important;
              opacity: 0.3;
            }
          }
        `
      }
    >
      <Stack gap={"s2"} align="center">
        <Stack>
          <ProcessLogoWithLCData
            processId={process.id}
            processName={process.name}
            lcData={process.lcData}
            logo={process.logo}
            status={process.status.displayName}
          />
        </Stack>
        <Stack gap="s1">
          <Text
            variant="p1-bold"
            ellipsis={"40ch"}
            useMaxWidth
            color="neutral-100"
          >
            <Link
              to={getProcessDetailRoute(process.id)}
              css={css`
                font-size: inherit !important;
                line-height: inherit !important;
                font-weight: inherit !important;
                color: inherit !important;
                &:hover {
                  color: var(--brand) !important;
                }
              `}
            >
              {process.name}
            </Link>
          </Text>
          <Text variant="p1-regular" color="neutral-70">
            (#{process.id})
          </Text>
        </Stack>
      </Stack>
      {!showOnlyProcessLabel && (
        <Stack>
          <Stack gap="s4" align={"center"}>
            <Progress
              progressPercentage={process.progress}
              onClick={showProcessOverlay}
            />
            <WorkflowStatus
              onClick={showProcessOverlay}
              label={process.status.displayName}
              customColorCode={process.status.colorCode}
            />
          </Stack>
          {canArchiveProcess ? (
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  leftIcon={<Dots />}
                  type={ButtonTypes.ICON}
                  size={ButtonSizes.SMALL}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  value="archive"
                  onSelect={handleArchive}
                  type={DropdownMenuItemTypes.DESTRUCTIVE}
                  disabled={isWorkflowBeingMigrated}
                >
                  <WrapWithMigrationTooltip show={isWorkflowBeingMigrated}>
                    <Stack align="center" gap="s2">
                      <div
                        className={css`
                          margin-right: 8px;
                        `}
                      >
                        <Archive />
                      </div>

                      {intl.formatMessage({
                        id: "stepBodyFormInstances.archiveWorkflow",
                        defaultMessage: "Archive Workflow"
                      })}
                    </Stack>
                  </WrapWithMigrationTooltip>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : null}
        </Stack>
      )}
    </Stack>
  );
};

export { ProcessItem };
