import { Stack } from "@certa/blocks/thanos";
import { css } from "emotion";
import { Spinner } from "@certa/icons";

export const FullHeightSpinner = () => {
  return (
    <Stack
      className={css`
        height: 100%;
      `}
      direction="vertical"
      align="center"
      justify="center"
    >
      <Spinner size={32} />
    </Stack>
  );
};
