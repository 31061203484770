import {
  RequestHeaders,
  APIFetch,
  APIFetchV2,
  JSONToQueryParams,
  APIFetchV3
} from "@certa/network";
import {
  stepGroupModelCreator,
  workflowListModelCreator,
  taskDetailsModelCreator,
  stepUsersModelCreator,
  reportWorkflowsModelCreator,
  fieldHierarchyModelCreator,
  workflowsItemModelCreator,
  relatedKindModalCreator,
  workflowDetailsCreator,
  taskMigrationStatsModelCreator
} from "../models/workflow.model";
import type {
  GetAdvancedFilterDataORMType,
  GetAdvancedFilterDataORMReturnType,
  CreateWorkflowServiceParams,
  SearchUserWorkflowServiceParams,
  UpdateChildWorkflowServiceParams,
  CreateWorkflowResponse,
  WorkflowFiltersQuery,
  WorkflowItemDetailsResposne,
  ReportExportParams,
  ReportOrdering,
  WorkflowPermissionsType,
  ReportExportORMParams,
  ReportWorkflowsORM,
  MigrationStatsResponse,
  StepUserTagsWorkflowResponse,
  ReportWorkflowsResponseORM
} from "../types/workflow.types";
import type { GetORMReportWorkflowsParams } from "../types/reportChartData.types";
import queryString from "query-string";
import { refactorFieldTagsForBE } from "../utils/fields.utils";
import type { WorkflowDynamicAPIResultType } from "@certa/types/src/common";
import type {
  AggregateResponse,
  FieldMap,
  ProcessCycleChartAPIResponseType,
  UseQueryExtraConfigType,
  UseQueryReturnType
} from "@certa/common";
import { INDEX_ZERO, getCustomAttributeTag } from "@certa/common";
import {
  ormDynamicAPIDataModelCreator,
  ormReportWorkflowsModelCreator
} from "../models/workflowORM.model";
import { readonlyTableServices } from "./readonlyTable.services";
import type { ExportEmailDeliveryReportParms } from "../types/emailDeliveryReport.types";
import type { ExportIntegrationCallReportParms } from "../types/integrationCallReport.types";
import type { WorkflowReportORMDynamicAPIDataPayload } from "../types/dynamicAPI.types";
import { getWorkflowUniqKey, queryClient } from "../..";
import {
  INVALID_VALUE_IN_DATABASE_ERROR,
  SOMETHING_WENT_WRONG_ERROR
} from "../constants/ormReportWorkflow.constants";

const DEFAULT_OFFSET = 0;
/**
 * Creates a child workflow of the specified kind under the specified workflow
 * parent ID provided to it.
 * @param {CreateWorkflowServiceParams} params
 * @returns {Promise<Workflow>} array of model is returned if the API is successful.
 */
function createWorkflow(payload: CreateWorkflowServiceParams) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(payload),
    signal: payload?.signal
  };

  return APIFetch<CreateWorkflowResponse>("workflows/", requestOptions);
}

/**
 * Creates a report workflow and updates the column label mappings
 * @param {CreateWorkflowServiceParams} params
 * @returns {Promise<Workflow>} created workflow data is returned if the API is successful.
 */
const createReportWorkFlowWithLabelMappings = async (
  payload: CreateWorkflowServiceParams & {
    labelMappings: Record<string, string>;
    updateKey: string;
  }
) => {
  const { labelMappings, updateKey, ...rest } = payload;

  const workflowResponse = await createWorkflow(rest);

  if (labelMappings && updateKey) {
    const reportId = workflowResponse?.id;
    const kindId = workflowResponse?.definition?.kind;
    const dashboardId = workflowResponse?.parents?.[INDEX_ZERO];

    if (kindId && dashboardId) {
      const reports = await readonlyTableServices.getChildWorkflows({
        kind: kindId,
        parents: dashboardId,
        field_tags: [updateKey]
      });

      const report = reports?.find(
        (report: { id: number }) => report.id === reportId
      );

      const updateFieldInfo = report?.fields[updateKey];

      const requestOptions: RequestInit = {
        method: "POST",
        headers: RequestHeaders.POST,
        credentials: "include",
        body: JSON.stringify({
          extra_json: labelMappings,
          field: updateFieldInfo?.id,
          answer: updateFieldInfo?.answer
        })
      };

      await APIFetchV2("responses/", requestOptions);
    }
  }

  return workflowResponse;
};

/**
 * Updates parents' of the given workflow.
 * @param {UpdateChildWorkflowServiceParams} params
 * @returns {Promise} resolves if update is successful, otherwise rejected
 */
function updateChildWorkflow(params: UpdateChildWorkflowServiceParams) {
  const { id, ...payload } = params;
  const requestOptions: RequestInit = {
    method: "PATCH",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(payload)
  };

  return APIFetch(`workflows/${id}/`, requestOptions);
}

/**
 * Find workflow ID of user workflow associated with the provided email address
 * @param {SearchUserWorkflowServiceParams} params
 * @returns {Promise<number>} resolves to the workflow ID of the workflow found
 * against the provided email
 */
function getUserWorkflowByEmail(params: SearchUserWorkflowServiceParams) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };

  return APIFetch(`workflows/search-user-workflow/`, requestOptions).then(
    (response: any) => response.workflow as number
  );
}

function archiveWorkflow(workflowId: number | undefined) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include"
  };

  const deleteUrl = `workflows/${workflowId}/archive/`;
  return APIFetch(deleteUrl, requestOptions);
}

function exportReport(params: ReportExportParams) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };

  const exportUrl = "workflow-reports/export/";
  return APIFetchV2(exportUrl, requestOptions);
}

const exportReportORM = (params: ReportExportORMParams) => {
  const { reportId, filters, allFieldsExport: isAllFieldsExport } = params;
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: filters ? JSON.stringify({ filters }) : undefined
  };

  const exportUrl = isAllFieldsExport
    ? `workflow-reports/${reportId}/export-all-fields/`
    : `workflow-reports/${reportId}/export/`;
  return APIFetchV3(exportUrl, requestOptions);
};

/**
 * Hard deletes a workflow and its child workflows
 * Throw 400 if following rules are not met
 * 1. Workflow should be created by the user discarding it. Not necessarily the child workflows
 * 2. The workflow was created in the last 10 mins
 * 3. First accessible step is not submitted
 * 4. User discarding it, should have the write access to it
 */
function discardWorkflow(workflowId: number) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include"
  };

  return APIFetchV2(`workflows/${workflowId}/discard/`, requestOptions);
}

function getStepGroupList(
  workflowId: number | undefined,
  params?: Record<string, string | number>
) {
  if (!workflowId) {
    return Promise.reject("Workflow ID must be a number");
  }

  const urlParams = queryString.stringify(params || {});
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  const paramString = urlParams ? `?${urlParams}` : "";

  return APIFetchV2(
    `workflows/${workflowId}/stepgroups/${paramString}`,
    requestOptions
  ).then(stepGroupModelCreator);
}

const getWorkflowList = (params: any, page = 1) => {
  const urlParams = JSONToQueryParams(params);
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  const url = `workflows/?${urlParams}&page=${page}`;
  return APIFetchV2(url, requestOptions).then(workflowListModelCreator);
};

const getTaskDetails = (taskId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const detailsURL = `workflows/?id=${taskId}`;
  return APIFetchV2<WorkflowItemDetailsResposne>(
    detailsURL,
    requestOptions
  ).then(taskDetailsModelCreator);
};
const getTaskMigrationStats = ({
  taskId,
  includeTotalEstimatedCompletionTime = false
}: {
  taskId: number | null;
  includeTotalEstimatedCompletionTime?: boolean;
}) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const statsUrl = `migration-stats/?workflow_id=${taskId}`;
  return APIFetchV2<MigrationStatsResponse>(statsUrl, requestOptions)
    .catch(response => response.json())
    .then(taskMigrationStatsModelCreator);
};

// Note: We tried using useInfiniteQuery instead of the below strategy,
// but it did not function as planned and had more complexity.
// See the first commit and comments of the 8963 PR for the useInfiniteQuery approach.
const getStepUserTags = async (taskId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `step-user-tags/?workflow=${taskId}`;

  const fetchAllPages = async (
    url: string,
    accumulatedData?: StepUserTagsWorkflowResponse
  ): Promise<StepUserTagsWorkflowResponse> => {
    try {
      const data = await APIFetch<StepUserTagsWorkflowResponse>(
        url,
        requestOptions
      );

      if (!accumulatedData) {
        accumulatedData = { ...data, results: [...data.results] };
      } else {
        accumulatedData.results = [...accumulatedData.results, ...data.results];
      }
      const page = data.next
        ? new URL(data.next).searchParams.get("page")
        : null;
      if (page) {
        return fetchAllPages(`${url}&page=${page}`, accumulatedData);
      }

      return accumulatedData;
    } catch (error) {
      console.error(`Failed to fetch data for : ${url}`, error);
      throw new Error("Failed to fetch data.");
    }
  };

  return fetchAllPages(url).then(stepUsersModelCreator);
};

// Move this to appropriate file
const getSelectableFieldOptions = (
  kindId: string | undefined,
  fieldTag: string
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `selectable-field-option?kind_id=${kindId}&field_tag=${fieldTag}`;
  return APIFetchV3(url, requestOptions).then((response: any) => ({
    options: response?.options || []
  }));
};

const getAdvancedFilterData = (
  {
    kindTag,
    processTypeId
  }: {
    kindTag?: string;
    processTypeId?: number;
  },
  config?: Omit<RequestInit, "method" | "headers">
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include",
    ...config
  };

  const queryParams = JSONToQueryParams({
    active_kind: kindTag || "",
    active_kind_id: processTypeId || ""
  });

  const url = `fields/export-json/?${queryParams}`;
  return APIFetch(url, requestOptions).then(data =>
    fieldHierarchyModelCreator(data)
  );
};

const getAdvancedFilterDataORM = (
  {
    processTypeId,
    childrenKindIds = [],
    grandChildrenKindIds = [],
    useORMReport
  }: GetAdvancedFilterDataORMType,
  config?: Omit<RequestInit, "method" | "headers">
): Promise<GetAdvancedFilterDataORMReturnType> => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include",
    ...config
  };

  const recursiveFunction = async (id: string) => {
    const url = `fields/export-json/?active_kind_id=${id}`;
    return await APIFetch(url, requestOptions).then(data => ({
      kindId: id,
      advancedFilterData: fieldHierarchyModelCreator(data)
    }));
  };

  const url = `fields/export-json/?active_kind_id=${processTypeId}`;
  return APIFetch(url, requestOptions).then(async data => {
    const parentOption = fieldHierarchyModelCreator(data);
    const childrenOptions = childrenKindIds.map(id => recursiveFunction(id));
    const grandChildrenOptions = grandChildrenKindIds.map(id =>
      recursiveFunction(id)
    );
    const children = await Promise.all(childrenOptions);
    const grandChildren = await Promise.all(grandChildrenOptions);
    const mapping = getMapping({
      processTypeId,
      parentOption,
      children,
      grandChildren,
      useORMReport
    });
    return { parentOption, children, grandChildren, ...mapping };
  });
};

type AdvancedFilterDataORMChild = {
  kindId: string;
  advancedFilterData: ReturnType<typeof fieldHierarchyModelCreator>;
};

type GetMappingType = {
  processTypeId: number | undefined;
  parentOption: ReturnType<typeof fieldHierarchyModelCreator>;
  children: AdvancedFilterDataORMChild[];
  grandChildren: AdvancedFilterDataORMChild[];
  useORMReport: boolean;
};
const getMapping = ({
  processTypeId,
  parentOption,
  children,
  grandChildren,
  useORMReport
}: GetMappingType) => {
  if (!useORMReport)
    return {
      fieldTagMapping: { ...parentOption.mapping },
      stepGroupAndStepMapping: { ...parentOption.stepGroupAndStepMapping }
    };
  const fieldTagMapping: FieldMap = {
    ...getMappingHelper(parentOption.mapping, processTypeId?.toString())
  };
  const stepGroupAndStepMapping = {
    ...getMappingHelper(
      parentOption.stepGroupAndStepMapping,
      processTypeId?.toString()
    )
  };
  children.forEach(({ kindId, advancedFilterData }) => {
    Object.assign(
      fieldTagMapping,
      getMappingHelper(advancedFilterData.mapping, kindId)
    );
    Object.assign(
      stepGroupAndStepMapping,
      getMappingHelper(advancedFilterData.stepGroupAndStepMapping, kindId)
    );
  });
  grandChildren.forEach(({ kindId, advancedFilterData }) => {
    Object.assign(
      fieldTagMapping,
      getMappingHelper(advancedFilterData.mapping, kindId)
    );
    Object.assign(
      stepGroupAndStepMapping,
      getMappingHelper(advancedFilterData.stepGroupAndStepMapping, kindId)
    );
  });
  return { fieldTagMapping, stepGroupAndStepMapping };
};

const getMappingHelper = <T>(
  mapping: Record<string, T> | undefined,
  kindId: string | undefined
) => {
  const fieldMapping = {} as Record<string, T>;
  if (!mapping) return fieldMapping;
  Object.keys(mapping).forEach(key => {
    fieldMapping[getCustomAttributeTag(kindId, key)] = mapping[key];
  });
  return fieldMapping;
};

const getReportWorkflowsCount = (
  filters: WorkflowFiltersQuery,
  config?: Omit<RequestInit, "method" | "headers" | "body">
) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      filters
    }),
    ...config
  };
  return APIFetchV2(`workflow-reports/count/`, requestOptions);
};

const getReportWorkflows = (
  filters: WorkflowFiltersQuery & { offset: number },
  fieldTags: string[],
  ordering?: ReportOrdering[],
  config?: Omit<RequestInit, "method" | "headers" | "body">,
  customPageSize?: number
) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      ...refactorFieldTagsForBE(fieldTags),
      filters,
      ordering
    }),
    ...config
  };
  return APIFetchV2(
    `workflow-reports/?offset=${filters.offset}${customPageSize ? `&limit=${customPageSize}` : ""}&count=false`,
    requestOptions
  ).then(reportWorkflowsModelCreator);
};

const getORMReportWorkflows = ({
  filters,
  attributes = [],
  orderBy = [],
  operations = [],
  groupBy = [],
  convertData,
  limit,
  extraData,
  shouldSkipAccessCheck,
  config
}: GetORMReportWorkflowsParams): Promise<
  ReportWorkflowsORM | AggregateResponse
> => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      attributes,
      filters,
      operations,
      group_by: groupBy,
      order_by: orderBy,
      extra_data: extraData,
      extra_settings: {
        skip_access_check: shouldSkipAccessCheck
      }
    }),
    ...config
  };
  return APIFetchV3<ReportWorkflowsResponseORM | AggregateResponse>(
    `workflow-reports/?offset=${filters?.offset ?? DEFAULT_OFFSET}${
      limit ? "&limit=" + limit : ""
    }`,
    requestOptions
  )
    .then(async data =>
      convertData ? ormReportWorkflowsModelCreator(data, attributes) : data
    )
    .catch(async (error: Response) => {
      let body: unknown | null = null;
      try {
        body = await error.json();
      } catch (error) {
        // Do nothing
      }
      if (Array.isArray(body)) {
        if (
          body.includes(
            "Invalid value in the database for this query. Please contact customer care."
          )
        ) {
          throw new Error(INVALID_VALUE_IN_DATABASE_ERROR);
        }
      }
      throw new Error(SOMETHING_WENT_WRONG_ERROR);
    });
};

const getORMWorkflowInfoDynamicAPI = (
  data: WorkflowReportORMDynamicAPIDataPayload,
  ids: number[]
) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      data,
      filters: {
        id__in: ids?.join(",")
      }
    })
  };
  return APIFetchV3(`workflows-list-drest/`, requestOptions).then(
    ormDynamicAPIDataModelCreator
  );
};

const exportEmailDeliveryReport = ({
  attributes,
  reportId,
  filters
}: ExportEmailDeliveryReportParms) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      attributes,
      filters
    })
  };

  const exportUrl = `sendgrid-email-status-reports/${reportId}/export/`;
  return APIFetchV3(exportUrl, requestOptions);
};

function exportIntegrationReport({
  attributes,
  reportId,
  filters
}: ExportIntegrationCallReportParms) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      attributes,
      filters
    })
  };

  const exportUrl = `api-call-logs-reports/${reportId}/export/`;
  return APIFetchV3(exportUrl, requestOptions);
}

export const getApiCallLogs = (queryUrlParams: string, offset: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `api-call-logs/${queryUrlParams}${
      queryUrlParams ? "&" : "?"
    }limit=20&offset=${offset}`,
    requestOptions
  );
};

export const getEmailDeliveryReports = (
  queryUrlParams: string,
  offset: number
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `email-delivery-reports/${queryUrlParams}${
      queryUrlParams ? "&" : "?"
    }limit=20&offset=${offset}`,
    requestOptions
  );
};
export const getEmailDeliveryReportsStatuses = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `email-delivery-reports/distinct/status/
   `,
    requestOptions
  );
};

export const getEmailDeliveryReportsSubjects = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `email-delivery-reports/distinct/subject/
   `,
    requestOptions
  );
};

export const getEmailDeliveryReportsSmtpCodes = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `email-delivery-reports/distinct/smtp_status_code/
   `,
    requestOptions
  );
};

export const getEmailDeliveryReportsKindIds = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `email-delivery-reports/distinct/wf_kind/
   `,
    requestOptions
  );
};

export const getGetIntegrationReportKindIds = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `api-call-logs/distinct/kind_id/
   `,
    requestOptions
  );
};

export const getGetIntegrationReportStatues = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `api-call-logs/distinct/status/
   `,
    requestOptions
  );
};

export const getGetIntegrationReportApiTypes = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `api-call-logs/distinct/api_type/
   `,
    requestOptions
  );
};

function getWorkflowDetailsById(id: number | undefined) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<WorkflowItemDetailsResposne>(
    `workflows/?id=${id}`,
    requestOptions
  ).then(workflowsItemModelCreator);
}

function geWorkflowPermissionsTypeById(id: number) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<WorkflowPermissionsType>(
    `workflow-lvl-perms/${id}/`,
    requestOptions
  );
}

function dynamicWorkflowAPI<T>(body: T) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(body)
  };

  return APIFetchV2<WorkflowDynamicAPIResultType<T>>(
    `workflows-ql/`,
    requestOptions
  ).then(workflowDetailsCreator);
}

const getRelatedWorkflows = (kindId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.POST,
    credentials: "include"
  };
  return APIFetchV2<ProcessCycleChartAPIResponseType>(
    `related-workflow-kinds/?kind=${kindId}`,
    requestOptions
  ).then(data => relatedKindModalCreator(data));
};

const lazyFetchProcessDetails = async (
  workflowId: number,
  config?: UseQueryExtraConfigType
) =>
  await queryClient.fetchQuery<UseQueryReturnType<typeof getTaskDetails>>(
    getWorkflowUniqKey(workflowId),
    () => getTaskDetails(workflowId),
    {
      retry: 0,
      ...config
    }
  );

export const workflowServices = {
  createWorkflow,
  createReportWorkFlowWithLabelMappings,
  archiveWorkflow,
  updateChildWorkflow,
  getUserWorkflowByEmail,
  getStepGroupList,
  getWorkflowList,
  getTaskDetails,
  discardWorkflow,
  getStepUserTags,
  getAdvancedFilterData,
  getAdvancedFilterDataORM,
  getReportWorkflowsCount,
  getReportWorkflows,
  getORMReportWorkflows,
  getORMWorkflowInfoDynamicAPI,
  exportReport,
  exportReportORM,
  getWorkflowDetailsById,
  geWorkflowPermissionsTypeById,
  dynamicWorkflowAPI,
  getRelatedWorkflows,
  getApiCallLogs,
  getEmailDeliveryReports,
  getEmailDeliveryReportsStatuses,
  getEmailDeliveryReportsSubjects,
  getEmailDeliveryReportsSmtpCodes,
  getEmailDeliveryReportsKindIds,
  getGetIntegrationReportKindIds,
  getGetIntegrationReportApiTypes,
  getGetIntegrationReportStatues,
  exportEmailDeliveryReport,
  exportIntegrationReport,
  getSelectableFieldOptions,
  getTaskMigrationStats,
  lazyFetchProcessDetails
};
