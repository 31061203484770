import type {
  UserMentionList,
  UserMentionResponse,
  MentionData
} from "../types/mentions.types";
import queryString from "query-string";
import { getProcessDetailRoute } from "../../../common";
import type { CommentObjectTypes } from "@certa/types";

const getCommentData = (
  userMention: UserMentionResponse
): [string, MentionData] => {
  const {
    workflow,
    field,
    step,
    content_object_type: type,
    thread,
    integration_result: integrationResult
  } = userMention;

  const searchParams: MentionData = {
    type: type as CommentObjectTypes,
    thread_id: thread
  };

  if (field) {
    searchParams["field_id"] = field.id;
  }

  if (step) {
    searchParams["step"] = step.id;
    searchParams["group"] = step.step_group_id;
  }

  switch (type) {
    case "workflow":
      searchParams["object_id"] = workflow.id;
      break;
    case "integrationresult":
      if (integrationResult) {
        searchParams["uid"] = integrationResult.uid;
      }
      if (field) {
        searchParams["object_id"] = field.id;
      }
      break;
    case "step":
      if (step) {
        searchParams["object_id"] = step.id;
      }
      break;
    case "field":
      if (field) {
        searchParams["object_id"] = field.id;
      }
      break;
  }
  return [
    `${getProcessDetailRoute(workflow.id)}?${queryString.stringify(
      searchParams
    )}`,
    searchParams
  ];
};

export const mentionListModelCreator = (response: any): UserMentionList => {
  const data = {
    count: response.count,
    next: response.next,
    previous: response.previous,
    results:
      response?.results?.map((mention: any) => {
        const [threadURL, mentionData] = getCommentData(mention);
        return {
          channelId: mention.channel,
          type: mention.content_object_type,
          createdAt: mention.created_at,
          field: mention.field
            ? { name: mention.field.body, id: mention.field.id }
            : null,
          mentionId: mention.id,
          integrationResult: mention.integration_result,
          isRead: mention.is_read,
          message: mention.message,
          messageType: mention.message_type,
          postedBy: {
            userId: mention.posted_by.id,
            fullName: mention?.posted_by?.first_name
              ? mention?.posted_by?.last_name
                ? `${mention.posted_by?.first_name} ${mention.posted_by?.last_name}`
                : mention.posted_by?.first_name
              : null,
            email: mention.posted_by.email,
            groups: mention.posted_by.groups
          },
          task: mention.step
            ? {
                taskId: mention.step.id,
                taskName: mention.step.name,
                taskLaneId: mention.step.step_group_id
              }
            : null,
          threadId: mention.thread,
          process: {
            processId: mention.workflow.id,
            processName: mention.workflow.name
          },
          threadURL,
          mentionData
        };
      }) || []
  };

  return data;
};
