import type { FC } from "react";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { css, cx } from "emotion";
import { Radio as AntRadio } from "antd";
import type { RadioButtonProps as AntRadioButtonProps } from "antd/lib/radio/radioButton";
import { Checkmark, Circle } from "@certa/icons";
import { Stack } from "../Stack";
import { Text } from "../Typography/Text";

export type RadioButtonProps = {
  /** Color of checked state */
  checkedColor?: "brand" | "red" | "green";
} & AntRadioButtonProps;

const CustomRadioButton: FC<RadioButtonProps> = ({
  checkedColor = "brand",
  className,
  children,
  ...restProps
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <AntRadio.Button
      ref={antRadioButton => {
        if (antRadioButton) {
          setChecked(antRadioButton.props.checked);
        }
      }}
      className={cx(checked && "ant-radio-button-wrapper-checked", className)}
      {...restProps}
    >
      <Stack
        direction="horizontal"
        gap="s1"
        align="center"
        className={css`
          height: var(--s7);
        `}
      >
        {checked ? (
          <Checkmark color={checkedColor} />
        ) : (
          <Circle color="neutral-70" />
        )}
        <Text variant="p1-regular" color="neutral-100">
          {children}
        </Text>
      </Stack>
    </AntRadio.Button>
  );
};

CustomRadioButton.defaultProps = {
  checkedColor: "brand"
};

export const RadioButton = styled(CustomRadioButton)`
  &.ant-radio-button-wrapper {
    background-color: var(--neutral-10);
    border: none !important;
    border-radius: var(--small-border-radius) !important;
    box-shadow: none !important;
    padding: var(--s00) var(--s2) var(--s00) var(--s1);
    height: var(--s7);
    outline: none;

    .ant-radio-button + span {
      line-height: var(--s7);
    }

    &::before {
      display: none;
    }

    &-checked {
      background-color: var(
        --${props => (props.checkedColor === "brand" ? "brand-35" : `${props.checkedColor}-40`)}
      );
    }

    &-disabled {
      opacity: 0.6;
    }

    /* TODO: Set box-shadow on :focus-visible only */
    &:focus-within {
      box-shadow:
        0 0 0 1px var(--neutral-0),
        0 0 0 3px var(--brand-15) !important;
      outline: none;
    }
  }

  --label: RadioButton;
`;
