import { ImpersonateAsUser } from "./ImpersonateAsUser";
import type { FC } from "react";

import { DropdownMenuContent } from "@certa/catalyst";

import { DROPDOWN_MENU_WIDTH } from "./constants";
import { InstanceSettings } from "./InstanceSettings";
import { MySubscriptions } from "./MySubscriptions";
import { SupportLinks } from "./SupportLinks";
import { UserProfile } from "./UserProfile";
import { Logout } from "./Logout";

export const OverlayMenu: FC = () => {
  return (
    <DropdownMenuContent align="end" width={DROPDOWN_MENU_WIDTH}>
      <MySubscriptions />
      <SupportLinks />
      <ImpersonateAsUser />
      <InstanceSettings />
      <UserProfile />
      <Logout />
    </DropdownMenuContent>
  );
};
