import { Collapse as AntCollapse } from "antd";
import type { ComponentProps } from "react";
import styled from "@emotion/styled";

/**
 * @deprecated
 */

export const Collapse = styled(AntCollapse)`
  &.ant-collapse {
    border: none;
    background: transparent;
  }

  .ant-collapse-item {
    box-shadow: none !important;
    border: 0 !important;
  }

  .ant-collapse-content.ant-collapse-content-active {
    border-top: medium none;
    background: white none repeat scroll 0% 0%;
  }

  .ant-collapse-content-box {
    padding: ${props =>
      props.bordered ? "var(--s3)" : "var(--s4) var(--s7) !important;"};
    border: ${props => props.bordered && "1px solid var(--neutral-20)"};
    border-radius: ${props => props.bordered && "var(--s3)"};
  }
  .ant-collapse-header {
    padding: var(--s2) var(--s7) var(--s2) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: unset !important;
    &:hover {
      .ant-collapse-arrow {
        background-color: var(--brand-35);
        color: var(--brand) !important;
      }
    }
    border-radius: 4px !important;
    &:focus {
      outline: 2px solid var(--brand) !important;
      outline-offset: -2px !important;
    }
  }

  .ant-collapse-arrow {
    left: ${props =>
      props.expandIconPosition === "right" ? "unset" : "0 !important"};
    color: var(--brand-15) !important;
  }
`;

export const Panel = AntCollapse.Panel;
export type CollapseExpandIconProps = ComponentProps<
  typeof AntCollapse
>["expandIcon"];
