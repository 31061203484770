import React, { Component } from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button } from "antd";
import { requestOptions } from "../../../services/auth-header-auditlog-service";
import PropTypes from "prop-types";
import { serverlessAPIFetch } from "../../../utils/request";
import { FormattedMessage } from "react-intl";
import {
  Loader,
  ELoaderSize
} from "../../../../modules/common/components/Loader";
import { ActivityLogList } from "./components/ActivityLogList";

export class AuditList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        results: [],
        next: null
      },
      activityLoading: true,
      initialLoad: true,
      logErrorCode: null,
      isLoading: false,
      hasMore: true
    };
  }

  loadData = () => {
    const { data } = this.state;
    const { startDate } = this.props;
    const url = `workflows/activity/?workflows=${
      this.props.id
    }&actions=${this.props.actions.join(",")}&skip=${
      data?.results?.length || 0
    }&start_date=${startDate}`;
    this.setState({
      isLoading: true,
      hasMore: false
    });
    serverlessAPIFetch(url, requestOptions(), "AUDIT_LOG")
      .then(response => {
        if (!response.ok) {
          this.setState({
            logErrorCode: response.status,
            isLoading: false
          });
          throw Error(response.statusText);
        } else {
          response.json().then(data => {
            this.appendData(data);
          });
          this.setState({
            logErrorCode: null,
            isLoading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
      });
  };

  appendData = body => {
    const oldData = this.state.data.results;
    let newData = [];
    newData = [...oldData, ...body.results];
    this.setState({
      data: {
        results: newData,
        next: body.next
      },
      activityLoading: false,
      initialLoad: false,
      hasMore: body.next ? true : false
    });
  };

  render = () => {
    if (this.props.isFocused) {
      if (!this.state.initialLoad && this.state.data.results.length === 0) {
        return (
          <div style={{ textAlign: "center" }}>
            <LegacyIcon type={"exclamation-circle"} />{" "}
            <FormattedMessage
              id="workflowsInstances.activityLog.noActivityLog"
              values={{
                type: (
                  <FormattedMessage
                    id={
                      "workflowsInstances.activityLog." +
                      this.props.logType +
                      "Tab"
                    }
                  />
                )
              }}
            ></FormattedMessage>
          </div>
        );
      } else if (this.state.logErrorCode) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <p
              style={{
                marginBottom: "6px",
                fontSize: "16px",
                color: "#f44336"
              }}
            >
              <FormattedMessage id="notificationInstances.somethingWentWrong" />
            </p>
            <Button type="primary" onClick={() => this.loadData()}>
              Retry
            </Button>
          </div>
        );
      } else {
        return (
          <div>
            <ActivityLogList
              data={this.state.data}
              hasMore={this.state.hasMore}
              loadData={this.loadData}
              isLoading={this.state.isLoading}
            />
            {this.state.isLoading ? (
              <div
                className="loader"
                key={0}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%"
                }}
              >
                <Loader size={ELoaderSize.SMALL} />
              </div>
            ) : null}
          </div>
        );
      }
    } else {
      return null;
    }
  };
}

AuditList.propTypes = {
  id: PropTypes.array.isRequired,
  isFocused: PropTypes.bool,
  actions: PropTypes.array.isRequired,
  logType: PropTypes.string.isRequired,
  startDate: PropTypes.string
};
