/** @jsx jsx */
/** @jsxRuntime classic */
import { Text } from "@certa/blocks/thanos";
import { css, jsx } from "@emotion/core";
import type { FC } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import type { LinkProps } from "./types";
import { TypographyColors } from "@certa/catalyst";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { useIntl } from "react-intl";

const mixpanelHandler = (label: string) => {
  MixPanelActions.track(MixPanelEvents.headerEvents.TAB_CLICK, {
    tab: label
  });
};

export const NavLink: FC<{ link: LinkProps }> = ({ link }) => {
  const {
    route,
    icon: Icon,
    label,
    alias = [],
    extra: ExtraCmp,
    userGroups,
    useRoute,
    target
  } = link;

  const intl = useIntl();

  const currentRoute = window.location.pathname;
  //TODO: Fix the active state for preview link
  const isActiveRoute =
    currentRoute.includes(route) ||
    alias.some(aliasRoute => aliasRoute.test(currentRoute));

  /**
   * Decides whether or not to show the route link based on user group:
   * - If no group is provided, the route is always visible
   * - If a group name is provided and user doesn't belong to that group
   * then the route is hidden
   */
  const isVisibleToUserGroup = useSelector<ReduxState>(state => {
    if (userGroups) {
      return userGroups.some(userGroup =>
        Boolean(
          state.authentication?.user?.groups?.find(
            (group: { name: string; id: number }) => group.name === userGroup
          )
        )
      );
    } else {
      return true;
    }
  });

  const { route: routeFromHook } = useRoute?.() || {};

  const color = isActiveRoute
    ? TypographyColors.BRAND_400
    : TypographyColors.NEUTRAL_700;

  if (!isVisibleToUserGroup) {
    return null;
  }
  return (
    <Link
      to={routeFromHook || route}
      tabIndex={0}
      css={css`
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;

        &:hover {
          text-decoration: none !important;
        }
        &:hover span {
          color: var(--brand);
          font-weight: var(--medium);
        }
        &:focus > div {
          outline: 2px solid var(--colors-brand-400);
        }
        ${isActiveRoute
          ? `&::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background: var(--brand);
        }`
          : ""}

        &:hover path {
          fill: var(--brand);
        }
      `}
      target={target}
      onClick={() => mixpanelHandler(label)}
    >
      <div
        style={{ padding: "var(--s2)", position: "relative" }}
        css={css`
          display: flex;
          align-items: center;
          column-gap: var(--s2);
          border-radius: 4px;
          min-height: 38px;
        `}
      >
        <Icon color={color} />
        <Text
          as="span"
          variant={"h3-medium"}
          color={color}
          css={css`
            white-space: nowrap;
          `}
        >
          {intl.formatMessage({
            id: `dashboard.common.${label}`,
            defaultMessage: label
          })}
        </Text>
        {ExtraCmp ? <ExtraCmp isActiveRoute={isActiveRoute} /> : null}
      </div>
    </Link>
  );
};
