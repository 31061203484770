import React from "react";
import {
  getColorByBgColor,
  getHEXCodeFromString
} from "main/src/modules/common/utils";
import type { FC } from "react";
import { CustomLabel, CustomLabelNew } from "./CustomLabel";
import type { TagSizes } from "@certa/catalyst";

type WorkflowLabelTypes = "teal";

type Config = {
  backgroundColor: string;
  color: string;
};

const colorConfigs: Record<WorkflowLabelTypes, Config> = {
  teal: {
    backgroundColor: "var(--teal)",
    color: "neutral-0"
  }
};

type WorkflowStatusProps = {
  label?: string | number;
  customColorCode?: string;
  ellipsis?: string | number;
  onClick?: () => void;
};

/**
 * This component is deprecated and should not be used.
 * @example
 * // Preferred Usage
 * WorkflowStatusNew
 */
export const WorkflowStatus: FC<WorkflowStatusProps> = ({
  label,
  ellipsis,
  customColorCode,
  onClick
}) => {
  let config = colorConfigs["teal"];
  if (customColorCode) {
    const hexCode = getHEXCodeFromString(
      customColorCode,
      config.backgroundColor
    );
    config = {
      backgroundColor: hexCode,
      color: getColorByBgColor(hexCode, config.color)
    };
  }

  return (
    <CustomLabel
      onClick={onClick}
      ellipsis={ellipsis === undefined ? "190px" : ellipsis}
      label={label || ""}
      backgroundColor={config.backgroundColor}
      color={config.color}
    />
  );
};

type WorkflowStatusNewProps = WorkflowStatusProps & {
  size?: TagSizes;
};

export const WorkflowStatusNew: FC<WorkflowStatusNewProps> = ({
  label,
  ellipsis,
  customColorCode,
  onClick,
  size
}) => {
  let config = colorConfigs["teal"];
  if (customColorCode) {
    const hexCode = getHEXCodeFromString(
      customColorCode,
      config.backgroundColor
    );
    config = {
      backgroundColor: hexCode,
      color: getColorByBgColor(hexCode, config.color)
    };
  }

  return (
    <CustomLabelNew
      onClick={onClick}
      ellipsis={ellipsis === undefined ? "190px" : ellipsis}
      label={label || ""}
      aria-label={`Workflow Status: ${label || ""}`}
      backgroundColor={config.backgroundColor}
      color={config.color}
      size={size}
    />
  );
};
