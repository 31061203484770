import React from "react";
import { Link } from "react-router-dom";

export const WorkflowLink = ({ item }) =>
  item.workflow_id && item.workflow_name ? (
    <Link
      to={`/workflows/instances/${item.workflow_id}`}
      target="_blank"
      className="text-base"
    >
      {item.workflow_name}
    </Link>
  ) : null;
