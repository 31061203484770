/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import type { UploadFile } from "antd/lib/upload/interface";
import { Stack } from "@certa/blocks/thanos";
import { Dialog } from "@certa/catalyst";
import { Image } from "@certa/blocks";
import { Send } from "@certa/icons";
// TODO: Use this with useOverlay

/**
 * AttachImageModal previews the image pasted from clipboard onto the comments.
 */

export type UploadFileBlob = UploadFile & {
  arrayBuffer: any;
  slice: any;
  stream: any;
  text: any;
};

export const AttachmentViewModal = (props: {
  attachment: UploadFileBlob; // Putting this as any because of discrepancy between UploadFile and Blob type for image which misses 4 parameters that should be required.
  visible: boolean;
  children: React.ReactChild;
  onOk: () => void;
  onCancel: () => void;
  isLoading: boolean;
  isDisabled: boolean;
}) => {
  /**
   * Convert the Blob from clipboard into a URL that can be used as source
   * for the image element to preview it.
   */
  const imageURL = useMemo(() => {
    const createObjectURL =
      window?.URL?.createObjectURL || window?.webkitURL?.createObjectURL;

    const isNotImage = !props.attachment?.type.startsWith("image");
    // Creates a DOMString containing a URL representing the object given in the parameter
    // namely the original Blob
    if (!props.attachment || !createObjectURL || isNotImage) {
      return "";
    }
    if (createObjectURL) {
      return createObjectURL(props.attachment);
    }
  }, [props.attachment]);

  const intl = useIntl();

  return (
    <Dialog
      show={props.visible}
      onPrimaryAction={props.onOk}
      title={intl.formatMessage({
        id: "comments.addAttachment",
        defaultMessage: "Add Attachment"
      })}
      primaryActionText={intl.formatMessage({
        id: "stepBodyFormInstances.postButtonText",
        defaultMessage: "Post"
      })}
      primaryButtonProps={{
        loading: props.isLoading,
        disabled: props.isDisabled,
        leftIcon: <Send />
      }}
      onClose={props.onCancel}
      secondaryActionText={intl.formatMessage({
        id: "comments.cancelButtonText",
        defaultMessage: "Cancel"
      })}
      onSecondaryAction={props.onCancel}
      preventCloseOnClickOutside={false}
      showCloseIcon={true}
      width="760px"
    >
      <Stack direction="vertical" gap="s4">
        <Stack direction="vertical" gap="s6">
          {imageURL ? (
            <Image
              src={imageURL}
              alt={"Clipboard Attachment"}
              data-testid="clipboard-image"
              height="100%"
              style={{
                maxWidth: "100%",
                maxHeight: "calc(60vh - 100px)"
              }}
            />
          ) : null}
          {props.children}
        </Stack>
      </Stack>
    </Dialog>
  );
};
