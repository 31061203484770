// Include all your general utils/helper codes used by @certa/queries in this file

import { getStatusesList } from "../services/common.services";

import { QueryClient, QueryCache } from "react-query";
// TODO: react-query v4 has this API ready for production use, while in v3 this is
// just marked as experimental. It should be fine as long as we are not switching
// between other versions of v3
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import {
  qkAppSettingsFromDB,
  qkAppSettingsPermissionList,
  qkappSettingsSchema
} from "../constants/appSettings.constants";
import type { PaginatedResultsResponse } from "../types/generic.types";

const CACHE_TIME = 1000 * 60 * 60 * 24; // 1 day
const persistQueryKeys = [
  qkAppSettingsFromDB,
  qkAppSettingsPermissionList,
  qkappSettingsSchema
];

export const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  queryCache
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
  maxAge: CACHE_TIME,
  dehydrateOptions: {
    // TODO: This only assumes that we're only dealing with
    // string keys. Since most of the times these keys will be the
    // ones that do not take any parameter, and hence the
    // response should mostly stay the same
    shouldDehydrateQuery: ({ queryKey, state }) =>
      typeof queryKey === "string" &&
      state.status === "success" &&
      persistQueryKeys.includes(queryKey)
  }
});

/**
 *   fetchStatusesListConditionally fetches all the statuses defined on the platform
 *   only if the key `availableStatusesKeyedById` is undefined in query-cache.
 *   Once the statuses are fetched it will get cached under `availableStatusesKeyedById` key
 *   causing this to simply return void.
 */
export const fetchStatusesListConditionally = async () => {
  if (!queryClient.getQueryData("availableStatusesKeyedById")) {
    await getStatusesList();
  }
};

export const paginatedParams = {
  getNextPageParam: (lastPage: PaginatedResultsResponse<unknown>) => {
    if (lastPage.next) {
      const urlParams = new URLSearchParams(lastPage.next);
      const offset = urlParams.get("offset");
      return offset;
    }
    return false;
  }
};

export const shouldRetryFunction = <T extends Function>(
  func: T,
  failureCount: number,
  retryCount: number
) => {
  if (failureCount < retryCount) {
    func();
    return true;
  }
  return false;
};
