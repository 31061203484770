export const workflowConstants = {
  GETALL_REQUEST: "WORKFLOW_GETALL_REQUEST",
  GETALL_SUCCESS: "WORKFLOW_GETALL_SUCCESS",
  GETALL_FAILURE: "WORKFLOW_GETALL_FAILURE",

  GET_REQUEST: "WORKFLOW_REQUEST",
  GET_SUCCESS: "WORKFLOW_SUCCESS",
  GET_FAILURE: "WORKFLOW_FAILURE",

  SEARCH_REQUEST: "SEARCH_REQUEST",
  SEARCH_SUCCESS: "SEARCH_SUCCESS",
  SEARCH_FAILURE: "SEARCH_FAILURE",

  GET_CHILD_REQUEST: "GET_CHILD_REQUEST",
  GET_CHILD_SUCCESS: "GET_CHILD_SUCCESS",
  GET_CHILD_FAILURE: "GET_CHILD_FAILURE",
  EXPANDED_WORKFLOWS: "EXPANDED_WORKFLOWS",

  SHOW_USER_WORKFLOW_MODAL: "SHOW_USER_WORKFLOW_MODAL",
  HIDE_USER_WORKFLOW_MODAL: "HIDE_USER_WORKFLOW_MODAL",

  UPDATE_PARENT_EXTRA_FILTER: "UPDATE_PARENT_EXTRA_FILTER",

  SET_WORKFLOW_MODAL_ID: "SET_WORKFLOW_MODAL_ID",
  SET_WORKFLOW_OVERLAY_MODE: "SET_WORKFLOW_OVERLAY_MODE"
};
