import Highlighter from "react-highlight-words";
import { css } from "emotion";

const highlightClassName = css({
  padding: 0,
  background: "var(--highlight-yellow)"
});

export const SearchHighlighter = ({
  textToHighlight,
  highlights = []
}: {
  textToHighlight: string;
  highlights?: string[];
}) => {
  const searchWords = highlights.reduce((acc: string[], item: string) => {
    // Elastic search returns highlighted text ash "...prefix ... <strong>highlight<strong>...suffix..."
    // we use this regex to match the keywords to be highlighted and use them to highlight in FE
    const words = [...item.matchAll(/<strong>([^<]+)<strong>/g)].map(
      matches => matches[1]
    );

    return [...acc, ...words];
  }, []);

  return (
    <Highlighter
      searchWords={searchWords}
      textToHighlight={textToHighlight}
      highlightClassName={highlightClassName}
      autoEscape={true}
    />
  );
};
