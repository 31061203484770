import JSZip from "jszip";
import { ONE, ZERO } from "../../constants";

type AddFileProps = {
  name: string;
  content: string;
  option: {
    base64?: boolean;
    binary?: boolean;
  };
};

export class Zip {
  public zip: JSZip;
  constructor() {
    this.zip = new JSZip();
  }
  // add file to zip
  public async addFile({ name, content, option }: AddFileProps): Promise<void> {
    try {
      // sanitize individual file names too
      const sanitizedFileName = name.replace(/[<>:"/\\|?*]/g, "_");
      const data = await fetch(content).then(response => response.blob());
      this.zip.file(sanitizedFileName, data, option);
    } catch (err) {
      this.errorHandler(err);
    }
  }
  //add files to zip
  public async addFiles(files: AddFileProps[]): Promise<void> {
    try {
      const formattedFiles = this.makeFileNameUnique(files);
      for (const file of formattedFiles) {
        await this.addFile(file);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  }
  // generate zip file
  public async generateAsync(): Promise<Blob | void> {
    const content = await this.zip
      .generateAsync({ type: "blob" })
      .then(content => {
        return content;
      })
      .catch(this.errorHandler);
    return content;
  }
  // download zip file
  public async downloadAsync(fileName: string): Promise<void> {
    const content = await this.generateAsync();
    if (!content) return;
    const blob = new Blob([content], { type: "application/zip" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName.replace(/[<>:"/\\|?*]/g, "_");
    link.click();
  }
  // error handler
  private errorHandler(err: any): void {
    console.log(err);
  }
  //make file name unique
  private makeFileNameUnique(files: AddFileProps[]): AddFileProps[] {
    const filesNameCounter = files.reduce(
      (acc: { [x: string]: number }, file) => {
        const { name } = file;
        if (acc[name]) {
          acc[name] = acc[name] + ONE;
        } else {
          acc[name] = ONE;
        }
        return acc;
      },
      {}
    );
    const uniqueFiles = files.map(file => {
      const { name } = file;
      const count = filesNameCounter[name];
      if (count > 1) {
        const fileNameWithoutExtension = name.split(".")[ZERO];
        const extension = name.split(".")[ONE];
        const uniqueFileName = `${fileNameWithoutExtension}(${count}).${extension}`;
        filesNameCounter[name] = count - ONE;
        return {
          ...file,
          name: uniqueFileName
        };
      }
      return file;
    });
    return uniqueFiles;
  }
}
