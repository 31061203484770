import { css } from "emotion";

const tickCss = css`
   {
    stroke: var(--neutral-70) !important;
    stroke-width: 2;
  }
`;

type CustomAxesTickProps = {
  x: string | number;
  y: string | number;
  payload: {
    value: string | number;
  };
  tickFormatter?: (value: string | number, index: number) => string;
  index: number;
};
export const CustomAxesTick = (props: CustomAxesTickProps) => {
  const { x, y, payload, tickFormatter, index } = props;
  const formatedValue = tickFormatter?.(payload.value, index) ?? payload.value;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={0} dy={12} textAnchor="middle" fill="#666">
        {formatedValue}
      </text>
      {formatedValue ? (
        <line className={tickCss} x1="0" y1="-17" x2="0" y2="0"></line>
      ) : null}
    </g>
  );
};

export const MAX_Y_AXIS_VALUE_LABEL_LENGTH = 15;

export const CustomYAxesTick = (props: CustomAxesTickProps) => {
  const { x, y, payload, tickFormatter, index } = props;
  const formatedValue = `${tickFormatter?.(payload.value, index) ?? payload.value}`;
  const ellipsisText =
    formatedValue.length > MAX_Y_AXIS_VALUE_LABEL_LENGTH
      ? formatedValue.slice(0, MAX_Y_AXIS_VALUE_LABEL_LENGTH) + "..."
      : formatedValue;
  return (
    <g transform={`translate(${x},${y})`}>
      <title>{formatedValue}</title>
      <text x={0} y={0} dx={0} dy={3} textAnchor="end" fill="#666">
        {ellipsisText}
      </text>
      {formatedValue ? (
        <line className={tickCss} x1="0" y1="0" x2="17" y2="0"></line>
      ) : null}
    </g>
  );
};
