import { useState, useEffect, useRef } from "react";

export const useClickAway = ({
  listenerType = "mouseup",
  ignoreElementsQuery
}: {
  /**
   * click away listener event
   */
  listenerType?: "mousedown" | "mouseup" | "click";
  /**
   * css query selector path
   */
  ignoreElementsQuery?: string[];
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const visibleElementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        ignoreElementsQuery &&
        ignoreElementsQuery.some(elementQuery => {
          return document
            .querySelector(elementQuery)
            ?.contains(event.target as HTMLElement);
        })
      ) {
        return;
      }
    }

    // Bind the event listener
    document.addEventListener(listenerType, handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(listenerType, handleClickOutside);
    };
  }, [listenerType, ignoreElementsQuery, visibleElementRef]);

  return {
    visibleElementRef,
    isVisible,
    setIsVisible
  };
};
