const FIRST_OPTION_CLASS_NAME = "global-select__option";

export function isFirstOption(element?: HTMLElement) {
  return element?.classList.contains(FIRST_OPTION_CLASS_NAME);
}

/**
 * Gets list of all elements in select dropdown that are tabbable.
 * It gets all elements with non-negative tab index, and the first option
 * and then organizes them in order of tab index. It also insets first
 * option in the list based on it's dom position relative to the tabbable
 * elements.
 *
 * @returns Array of tabbable elements in the dropdown
 */
export function getTabbaleElements() {
  const elementsWithTabIndex = Array.from<HTMLElement>(
    document.querySelectorAll(
      "div.global-select__menu-portal [tabindex]:not([tabindex='-1'])"
    )
  ).sort((a, b) => a.tabIndex - b.tabIndex);
  const firstOption = document.querySelector(
    ".global-select__option"
  ) as HTMLElement;

  const sequentialTabbableElements = elementsWithTabIndex.reduce(
    (acc, element, index) => {
      acc.push(element);

      if (firstOption) {
        if (
          elementsWithTabIndex[index].compareDocumentPosition(firstOption) ===
            Node.DOCUMENT_POSITION_FOLLOWING &&
          elementsWithTabIndex[index + 1].compareDocumentPosition(
            firstOption
          ) === Node.DOCUMENT_POSITION_PRECEDING
        ) {
          acc.push(firstOption);
        }
      }

      return acc;
    },
    [] as HTMLElement[]
  );

  return sequentialTabbableElements;
}
