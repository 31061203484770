import type { ReactNode } from "react";
import type React from "react";
import { notification } from "antd";
import type { IconType, NotificationPlacement } from "antd/lib/notification";
import type { HashMap } from "@certa/types";

type ExtraParams = {
  key?: string;
  description?: React.ReactChild;
};

type ShowNotification = {
  type?: IconType;
  message?: string;
  messageData?: HashMap;
  description?: string | ReactNode;
  descriptionData?: any;
  key?: string;
  placement?: NotificationPlacement;
  duration?: number;
};

/**
 *
 * @param {string} type Defines the type of notification that you want to show
 * @param {string} message Translation path of title text
 * @param {object} messageData Additional data to substitute variables from the message
 * @param {string} description Translation path of body text
 * @param {object} descriptionData Additional data to substitute variables from the message
 * @param {any} key Any unique string or symbol
 * @param {"topLeft" | "topRight" | "bottomLeft" | "bottomRight"} placement placement of the notification on screen
 * @param {number} duration Duration for which the notification should stay
 */

/**
 * @deprecated
 * TODO: Check if we really need this on frontend
 * - Deprecating it since we have moved intl logic out of it and not
 * this wrapper makes no sense as we can directly use antd notification instead of this in our component.
 * TODO: #2 We need a some way to have common notification method/component to
 * be used throughout the application so it can be controlled or changed from
 * one point,if needed. Suggested alternative was to just directly export
 * ANTD's notification from here and use it across application.
 */
export const showNotification = (params: ShowNotification) => {
  const {
    type = "info",
    message = "",
    description,
    key,
    placement = "bottomLeft",
    duration = 4.5
  } = params;
  if (!!message === false) return;
  const extraProps: ExtraParams = {};
  if (!!key) extraProps.key = key;

  notification[type]({
    message,
    description,
    placement,
    duration,
    ...extraProps
  });
};
