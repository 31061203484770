import {
  Stack,
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst";
import { css } from "emotion";
import { extractAttachmentName } from "../../comments.utils";
import { Download } from "@certa/icons";

type AttachmentViewProps = { attachment: string; isCurrentUser: boolean };

const AttachmentView = ({ attachment, isCurrentUser }: AttachmentViewProps) => {
  if (!attachment) return null;
  return (
    <Stack
      direction="horizontal"
      justify={isCurrentUser ? "flex-end" : "flex-start"}
      dangerouslySetClassName={css({
        margin: "var(--space-4) 0"
      })}
    >
      <a
        href={attachment}
        className={css({
          textDecoration: "none !important",
          color: "unset !important"
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Stack
          direction="horizontal"
          align="center"
          gap="4"
          dangerouslySetClassName={css({
            padding: "4px 6px",
            backgroundColor: "var(--neutral-20)",
            borderRadius: "var(--small-border-radius)"
          })}
        >
          <Download size={12} />
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_800}
          >
            {extractAttachmentName(attachment)}
          </Typography>
        </Stack>
      </a>
    </Stack>
  );
};

export { AttachmentView };
