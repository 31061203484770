import { SAVE_WORKFLOW_FAMILY } from "../constants/workfow_family";

export const workflowFamily = (state = [], action) => {
  switch (action.type) {
    case SAVE_WORKFLOW_FAMILY:
      return action.payload;
    default:
      return state;
  }
};
