import React, { useState } from "react";
import { Popover } from "@certa/blocks/thanos";
import { css } from "emotion";
import { colord } from "colord";
import type { RgbaColor } from "react-colorful";
import { ColorPickerCard } from "@certa/dashboards/src/components/common/ColorPicker/ColorPickerCard";

export function ColorPicker(props: ColorPickerProps) {
  const { onChange, rgbaColor, children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const onChangeLocal = (changedColorInRGBA: RgbaColor) => {
    onChange(colord(changedColorInRGBA).toRgbString());
  };
  return (
    <Popover
      overlayClassName={popoverStyle}
      content={
        <ColorPickerCard
          rgbaColor={colord(rgbaColor).rgba}
          onChange={onChangeLocal}
          onClose={onClose}
          isSmall
        />
      }
      placement="right"
      trigger="click"
      getPopupContainer={triggerNode =>
        triggerNode?.parentElement || document.body
      }
      visible={isOpen}
      onVisibleChange={setIsOpen}
      destroyTooltipOnHide={true}
    >
      {children}
    </Popover>
  );
}

type ColorPickerProps = {
  onChange: (newColor: string) => void;
  rgbaColor: string;
  children: JSX.Element;
};

const popoverStyle = css`
   {
    & .ant-popover-inner {
      border-radius: var(--s3);
    }
    & .ant-popover-inner-content {
      padding: var(--s4) var(--s4);
    
  }
`;
