import React from "react";
import { Tag as AntTag } from "antd";
import type { TagProps as AntTagProps } from "antd/lib/tag";
import styled from "@emotion/styled";

export type TagProps = AntTagProps & { width?: string };

const CustomTag = ({ children, ...rest }: TagProps) => {
  return <AntTag {...rest}>{children}</AntTag>;
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Tag } from '@certa/catalyst';
 */
export const Tag = styled(CustomTag)`
  &.ant-tag {
    width: ${props => props.width || "auto"};
    background-color: ${props => !props.color && "var(--neutral-20)"};
    color: ${props => !props.color && "var(--neutral-60)"};
    line-height: 1;
    padding: var(--s1) var(--s2);
    border-radius: var(--s1);
    font-size: var(--p3);
    font-family: inherit;
    border-width: 0px;
    margin: var(--s1) var(--s2) var(--s1) 0px;
    display: flex;
    align-items: center;

    & > span[role="img"]:first-child:not(:last-child) {
      margin-right: 0.5em;
    }

    .anticon-close {
      font-size: inherit;
      margin-left: var(--s2);
      &::after {
        content: "";
        display: block;
        width: var(--s3);
        height: var(--s3);
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'%3E%3Cpath d='M10.2945 2.20551C8.75179 0.71511 6.53712 0.149159 4.46867 0.716276C2.39978 1.28348 0.783573 2.89975 0.21631 4.96864C-0.350895 7.03709 0.21517 9.25176 1.70554 10.7945C3.24827 12.2849 5.46295 12.8508 7.5314 12.2837C9.60028 11.7165 11.2165 10.1002 11.7838 8.03136C12.351 5.96292 11.7849 3.74824 10.2945 2.20551ZM8.58778 8.22555V8.22498C8.70282 8.33945 8.76774 8.49436 8.76774 8.65665C8.76774 8.81839 8.70282 8.97386 8.58778 9.08775C8.47331 9.20164 8.31784 9.26485 8.15668 9.26371C7.99495 9.26485 7.84005 9.20164 7.72502 9.08775L6.00005 7.35651L4.27508 9.08775C4.16004 9.20164 4.00514 9.26485 3.84341 9.26371C3.68224 9.26485 3.52677 9.20164 3.41232 9.08775C3.29728 8.97385 3.23235 8.81837 3.23235 8.65665C3.23235 8.49434 3.29727 8.33944 3.41232 8.22498L5.14355 6.50001L3.41232 4.77504C3.17427 4.53643 3.17427 4.15031 3.41232 3.91227C3.65036 3.67422 4.03647 3.67422 4.27509 3.91227L6.00006 5.64351L7.72503 3.91227C7.96364 3.67422 8.34977 3.67422 8.58781 3.91227C8.82585 4.15031 8.82585 4.53642 8.58781 4.77504L6.85657 6.50001L8.58778 8.22555Z' fill='%23A2A2A2' /%3E%3C/svg%3E");
        //@todo closeIcon will be supported after antd update
      }
      &:hover {
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'%3E%3Cpath d='M10.2945 2.20551C8.75179 0.71511 6.53712 0.149159 4.46867 0.716276C2.39978 1.28348 0.783573 2.89975 0.21631 4.96864C-0.350895 7.03709 0.21517 9.25176 1.70554 10.7945C3.24827 12.2849 5.46295 12.8508 7.5314 12.2837C9.60028 11.7165 11.2165 10.1002 11.7838 8.03136C12.351 5.96292 11.7849 3.74824 10.2945 2.20551ZM8.58778 8.22555V8.22498C8.70282 8.33945 8.76774 8.49436 8.76774 8.65665C8.76774 8.81839 8.70282 8.97386 8.58778 9.08775C8.47331 9.20164 8.31784 9.26485 8.15668 9.26371C7.99495 9.26485 7.84005 9.20164 7.72502 9.08775L6.00005 7.35651L4.27508 9.08775C4.16004 9.20164 4.00514 9.26485 3.84341 9.26371C3.68224 9.26485 3.52677 9.20164 3.41232 9.08775C3.29728 8.97385 3.23235 8.81837 3.23235 8.65665C3.23235 8.49434 3.29727 8.33944 3.41232 8.22498L5.14355 6.50001L3.41232 4.77504C3.17427 4.53643 3.17427 4.15031 3.41232 3.91227C3.65036 3.67422 4.03647 3.67422 4.27509 3.91227L6.00006 5.64351L7.72503 3.91227C7.96364 3.67422 8.34977 3.67422 8.58781 3.91227C8.82585 4.15031 8.82585 4.53642 8.58781 4.77504L6.85657 6.50001L8.58778 8.22555Z' fill='%230042eb' /%3E%3C/svg%3E");
        }
      }
      svg {
        display: none;
      }
    }
  }
  --label: CustomTag;
`;
