import { Checkbox as AntCheckbox } from "antd";
import styled from "@emotion/styled";

const Checkbox = styled(AntCheckbox)`
  .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #d2d6dd;
  }
  .ant-checkbox-inner::after {
    width: 6px;
    height: 9px;
    top: 40%;
  }
`;
const CheckboxGroup = AntCheckbox.Group;
export { Checkbox, CheckboxGroup };
