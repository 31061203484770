import { APIFetchExt } from "@certa/network";
import type { HashMap } from "@certa/types";
import { studioCentralTemplatesModelCreator } from "../models/studio.models";
import type { PaginatedResultsResponse } from "../types/generic.types";
import { TemplateStatusTypes } from "../types/studio.types";
import type {
  ExternalProcessResponse,
  ExternalUpsertWorkflow
} from "../types/workflow.types";

const INSTACERTA_TOKEN = import.meta.env.REACT_APP_MODULE_REPO_TOKEN;
const SUPPORT_INSTANCE_TOKEN = import.meta.env.REACT_APP_SUPPORT_INSTANCE_TOKEN;
const INSTACERTA_MODULE_KIND_TAG = "reusable-components";

export async function getCentralizedStudioTemplates(
  page: number = 1,
  templateState?: TemplateStatusTypes
) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "X-DTS-SCHEMA": "instacerta",
      "Content-Type": "application/json",
      Authorization: `Token ${INSTACERTA_TOKEN}`
    }
  };

  const response = await APIFetchExt<
    PaginatedResultsResponse<ExternalProcessResponse>
  >(
    `workflows/?kind_tag=${INSTACERTA_MODULE_KIND_TAG}&page=${page}&status=${
      templateState ?? TemplateStatusTypes.PUBLIC
    }`,
    requestOptions
  );
  return studioCentralTemplatesModelCreator(response);
}

export function getCentralizedStudioTemplateJSON(workflowUID: string) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "X-DTS-SCHEMA": "instacerta",
      "Content-Type": "application/json",
      Authorization: `Token ${INSTACERTA_TOKEN}`
    }
  };

  return APIFetchExt<
    PaginatedResultsResponse<
      { id: number; answer: string; tag: string } & HashMap
    >
  >(`workflows/${workflowUID}/fields/`, requestOptions);
}

export function upsertWorkflow(
  reqBody: ExternalUpsertWorkflow,
  tenant: string = "support"
) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: {
      "X-DTS-SCHEMA": tenant,
      "Content-Type": "application/json",
      Authorization: `Token ${SUPPORT_INSTANCE_TOKEN}`
    },
    body: JSON.stringify(reqBody)
  };
  return APIFetchExt<{ id: number; uid: string }>(`workflows/`, requestOptions);
}
