export function hasStorageAccess(): Promise<boolean> {
  return document.hasStorageAccess();
}

export function requestStorageAccess(): Promise<void> {
  return document.requestStorageAccess();
}

export function supportStorageAccessApi(): boolean {
  return "hasStorageAccess" in document && "requestStorageAccess" in document;
}

export function requiresStoragePermissions(): boolean {
  return supportStorageAccessApi();
}
