import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { ActivityLogItemChange } from "./ActivityLogItemChange";
import { ActivityLogEmail } from "./ActivityLogEmail";
import { ActivityLogSimple } from "./ActivityLogSimple";
import { Bot, Caret } from "@certa/icons";
import type { ActionType } from "@certa/blocks/thanos";
import { Button, Stack, Text } from "@certa/blocks/thanos";
import {
  TimelineItem,
  Timeline
} from "@certa/blocks/src/componentsTh/Timeline";
import { css } from "emotion";
import type { ActivityLogModelType } from "@certa/queries";

type ActivityLogItemProps = {
  activityLog: ActivityLogModelType;
  activityLogsByBot?: Array<ActivityLogModelType>;
  hasAutoActivity?: boolean;
};

export const ActivityLogItem: FC<ActivityLogItemProps> = props => {
  const { activityLog, activityLogsByBot, hasAutoActivity = false } = props;
  const { actionType, objectType, changes, isCreatedByBot } = activityLog;
  const [isOpen, updateIsOpen] = useState(false);
  const toggleIsOpen = useCallback(
    () => updateIsOpen(isOpen => !isOpen),
    [updateIsOpen]
  );

  const isChangeListVisible = useMemo(
    () => objectType !== "email" && changes?.length,
    [objectType, changes]
  );
  return (
    <TimelineItem
      key={activityLog.id}
      actionType={
        isCreatedByBot && objectType !== "email"
          ? "performed"
          : (actionType as ActionType)
      }
    >
      <Stack gap="s4">
        {objectType === "email" ? (
          <ActivityLogEmail item={activityLog} />
        ) : (
          <ActivityLogSimple item={activityLog} />
        )}
        {activityLogsByBot?.length || isChangeListVisible ? (
          // TODO: To support purple button color, we need to add it
          // to the catalyst Button component
          // color={BUTTON_COLORS.PURPLE}

          <Button
            onClick={toggleIsOpen}
            type={isChangeListVisible || hasAutoActivity ? "ghost" : "default"}
            className={css`
              &.ant-btn {
                margin-top: calc(var(--s1) * -1) !important;
                border-radius: var(--s1) !important;
                height: 28px !important;
                padding: var(--s1) var(--s2) !important;
                ${!isChangeListVisible &&
                !hasAutoActivity &&
                `
                  color: var(--purple) !important;
                  border: 1px solid var(--purple) !important;
                  background: var(--purple-60) !important;
                `}
              }
            `}
          >
            <Stack align="center" gap="s1">
              {!isChangeListVisible && !hasAutoActivity && (
                <Bot type={isOpen ? "up" : "down"} />
              )}
              <Text variant="p1-regular">
                {activityLogsByBot?.length || activityLog.changes?.length}
              </Text>
              <Caret
                style={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"
                }}
              />
            </Stack>
          </Button>
        ) : null}
      </Stack>
      {isOpen ? (
        <>
          {isChangeListVisible ? (
            <Timeline edges="tail-top">
              {activityLog.changes?.map(({ event }) => (
                <ActivityLogItemChange
                  event={event}
                  key={event}
                  actionType={"edited"}
                />
              ))}
            </Timeline>
          ) : null}
          {activityLogsByBot?.length ? (
            <Timeline edges="tail-top">
              {activityLogsByBot?.map(activityLogByBot => (
                <ActivityLogItem
                  activityLog={activityLogByBot}
                  key={activityLogByBot.id}
                />
              ))}
            </Timeline>
          ) : null}
        </>
      ) : null}
    </TimelineItem>
  );
};
