import { sortByKey } from "./helper";

export const sortMentions = sortByKey.bind(null, "display");

export const getDynamicGroupName = (name: string) => {
  let groupName = name;
  const dynamicGroupNameRegex = /-\d+-/;
  const isDynamicGroup = dynamicGroupNameRegex.test(groupName);
  if (isDynamicGroup) {
    //split the kind name, wf id and group name
    const splittedList = groupName.split(dynamicGroupNameRegex);
    // get the group name
    groupName = splittedList[splittedList.length - 1];
    //remove - or _ between the name
    groupName = groupName.replace(/-|_/g, " ");

    return groupName;
  }

  return groupName;
};

const MENTION_REGEX = /~\[([^\]]*)\]\((?:u\d+|g\d+)\)/g;
const INVALID_MENTION_REGEX = /-\[[^\]]*\]\((?:u\d+|g\d+)\)/g;

/*
  - What below fn does?
  - This hook will replace the invalid mentions with valid ones.
    if invalid mentioned users/invalid mentioned groups are passed, then it will replace only those mentions with valid ones.
    else it will replace all invalid mentions with valid ones.

  - Why this is needed?
  - This is needed to handle the case where user is typing a comment and
    meanwhile the mentioned user/group might not have access to that thread.
*/

export const getReplaceValueForInvalidMentions = (
  value: string,
  invalidMentionedUsers: number[],
  invalidMentionedGroups: number[]
): string => {
  let replacedValue = value;
  if (invalidMentionedUsers?.length > 0 || invalidMentionedGroups?.length > 0) {
    let matchMentionRegex = MENTION_REGEX.exec(value);
    while (matchMentionRegex !== null) {
      const [, , id] = matchMentionRegex;
      let shouldReplace = false;
      if (id.includes("u")) {
        const numUserId = Number(id.replace("u", ""));
        shouldReplace = invalidMentionedUsers?.includes(numUserId);
      } else if (id.includes("g")) {
        const numGroupId = Number(id.replace("g", ""));
        shouldReplace = invalidMentionedGroups?.includes(numGroupId);
      }

      if (shouldReplace) {
        replacedValue = replacedValue.replace(
          matchMentionRegex[0],
          matchMentionRegex[0].replace("~", "-")
        );
      }
      matchMentionRegex = MENTION_REGEX.exec(value);
    }
  } else if (
    invalidMentionedUsers?.length === 0 &&
    invalidMentionedGroups?.length === 0
  ) {
    let matchInvalidMentionRegex = INVALID_MENTION_REGEX.exec(value);
    // replace all invalid mentions with valid one
    while (matchInvalidMentionRegex !== null) {
      replacedValue = replacedValue.replace(
        matchInvalidMentionRegex[0],
        matchInvalidMentionRegex[0].replace("-", "~")
      );
      matchInvalidMentionRegex = INVALID_MENTION_REGEX.exec(value);
    }
  }
  return replacedValue;
};
