/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import {
  Stack,
  HoverAbleSVG,
  Tooltip,
  LAYOUT_ID,
  MAX_PROGRESS_PERCENTAGE
} from "@certa/blocks/thanos";
import { AccordionCollapse } from "@certa/icons";
import type { Step, StepGroup } from "@certa/types";
import type { ReactElement } from "react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { getSwimlaneBgByProgressStatus } from "../../utils";
import { TaskView } from "@certa/common/src/components/TaskView";
import TaskGroupHeader from "../TaskGroupHeader";
import AccessibleTagsPopover from "../AccessibleTagsPopover";
import { useIntl } from "react-intl";
import { useClickAway } from "@certa/common";
import type { NodeState, TaskChangeArgs } from "../../../types";
import { calculateOverdueDuration } from "@certa/common/src/components/TaskView/utils";
import { useGetTasklaneStepProgressCount } from "../../../sidebar/hooks/utils";
import AssignedToTags from "./AssignedToTags";

import OverdueTag from "./OverdueTag";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

type Props = {
  group: StepGroup;
  onTaskClick: (payload: TaskChangeArgs) => void;
  showHiddenSteps: boolean;
  taskCategoryFilter: string;
  taggedStepGroups: number[];
  expandCollapseAll: NodeState;
  handleExpandCollapseAllSteps: (value: NodeState) => void;
};

export function FlowNode({
  group,
  onTaskClick,
  showHiddenSteps,
  taskCategoryFilter,
  taggedStepGroups,
  expandCollapseAll,
  handleExpandCollapseAllSteps
}: Props): ReactElement {
  const { percent: swimlaneProgressPercent } = useGetTasklaneStepProgressCount(
    group.steps
  );
  const isGroupOverdue = useMemo(
    () =>
      group.overdue &&
      group.steps.some(step => step.overdue && step?.stepType !== "FYI"),
    [group]
  );
  const swimlaneStyleByStatus = getSwimlaneBgByProgressStatus(
    group,
    swimlaneProgressPercent > 0 &&
      swimlaneProgressPercent < MAX_PROGRESS_PERCENTAGE,
    isGroupOverdue
  );
  const intl = useIntl();
  const [isHiddenNode, setIsHiddenNode] = useState(false);

  /**
   *  NOTE, Approaches for hanlding expand all behaviour:
   *    1. Global state for handling expand behaivour of each swimlane and expand all together in a single state.
   *    2. Local state and one global expand all state, and then combine them in each swimlane.
   *    Going for second approach as its easy to implement with current implementation, will go for first if needed later.
   */

  // Local expand state for a single swimlane
  const {
    isVisible: isExpand,
    setIsVisible: onExpand,
    visibleElementRef: expandedRef
  } = useClickAway({
    ignoreElementsQuery: [".react-flow__controls", ".progress-map-header"]
  });

  // Combined expand state for a single swimlane with expand all context
  const [isExpanded, setIsExpanded] = useState(false);

  const steps = useMemo(
    () =>
      showHiddenSteps
        ? group.steps
        : group.steps.filter(step => !step.isHidden),
    [group.steps, showHiddenSteps]
  );

  const overdueDays = useMemo(() => {
    const stepsWithOverdueDeadline = group.steps.filter(
      step => !!step.deadline && step.stepType !== "FYI"
    );
    if (stepsWithOverdueDeadline.length && isGroupOverdue) {
      return calculateOverdueDuration(
        stepsWithOverdueDeadline[0].deadline as string
      );
    }
  }, [group, isGroupOverdue]);

  /**
   *  useEffects
   *  Local state -> expanded
   *  Expand all state -> expandAll
   *  Combined state -> isExpanded -> this is used finally to expand or collpase the UI nodes
   */

  useEffect(() => {
    // aria state for expanded node (zIndexed top)
    const activeNode = document.querySelector(
      `[data-id="${group.definitionTag}"]`
    );
    activeNode?.setAttribute("aria-expanded", String(isExpanded));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  // When "Expand all" toggle is triggered this will change our local and combined state
  useEffect(() => {
    if (expandCollapseAll === "expanded") {
      setIsExpanded(true);
      onExpand(true);
    } else if (expandCollapseAll === "collapsed") {
      setIsExpanded(false);
      onExpand(false);
    }
  }, [expandCollapseAll, onExpand]);

  // When we change local state we update our combined state and now local state takes precendence
  useEffect(() => {
    setIsExpanded(isExpand);
  }, [isExpand]);

  useEffect(() => {
    if (!showHiddenSteps && !steps.length) {
      setIsHiddenNode(true);
    } else {
      // If Expand all toggle is on -> we expand its hidden steps as well
      if (expandCollapseAll === "expanded") {
        setIsExpanded(true);
        onExpand(true);
      } else if (expandCollapseAll === "collapsed") {
        setIsExpanded(false);
        onExpand(false);
      }
      setIsHiddenNode(false);
    }
  }, [showHiddenSteps, steps.length, expandCollapseAll, onExpand]);

  useEffect(() => {
    if (taskCategoryFilter && taskCategoryFilter !== "ALL_TASKS") {
      onExpand(taggedStepGroups.includes(group.id));
      handleExpandCollapseAllSteps(
        taggedStepGroups.includes(group.id) ? "expanded" : "collapsed"
      );
    } else {
      onExpand(false);
    }
    /**
     *  Changes in dependency
     *  taggedStepGroups -> taggedStepGroups.length,
     *  As the it was unnecessarily re-rendering even when the array is empty everytime
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    taskCategoryFilter,
    onExpand,
    group,
    taggedStepGroups.length,
    handleExpandCollapseAllSteps
  ]);

  const handleTaskViewClick = (taskId: number, step: Step) => {
    if (!step.isHidden) {
      MixPanelActions.track(
        MixPanelEvents.tasksPageEvents.PROGRESS_MAP_CLICK_STEP
      );
      onTaskClick({ taskLaneId: group.id, taskId });
    }
  };

  const handleSwimlaneClick = (expand: boolean) => {
    if (expand) {
      MixPanelActions.track(
        MixPanelEvents.tasksPageEvents.PROGRESS_MAP_CLICK_SWIMLANE,
        { expand: true }
      );
      if (!isHiddenNode) {
        handleExpandCollapseAllSteps("mixed");
        onExpand(true);
      }
    } else {
      MixPanelActions.track(
        MixPanelEvents.tasksPageEvents.PROGRESS_MAP_CLICK_SWIMLANE,
        { expand: false }
      );
      handleExpandCollapseAllSteps("mixed");
      onExpand(false);
      setIsExpanded(false);
    }
  };

  // Checking our combined state to expand the swimlane
  if (isExpanded) {
    return (
      <Fragment>
        <Stack
          key={group.id}
          direction="vertical"
          css={css`
            background: var(--neutral-0);
            text-align: left;
            min-width: 250px !important;
            max-width: 370px !important;
            box-shadow: 0px 4px 8px rgba(0, 22, 78, 0.06);
            background: ${swimlaneStyleByStatus.background} !important;
            border-radius: var(--big-border-radius) !important;
            padding: 14px 18px;
            opacity: ${!(
              taskCategoryFilter === "ALL_TASKS" ||
              taggedStepGroups.indexOf(group.id) > -1
            ) && 0.5};
            @keyframes borderFadeInOut {
              0% {
                border-color: var(--neutral-50);
              }
              100% {
                border-color: var(--teal);
              }
            }

            animation: ${!isGroupOverdue &&
            !group.isCompleted &&
            swimlaneProgressPercent > 0
              ? "borderFadeInOut 1s ease-in-out infinite alternate !important"
              : ""};
          `}
          gap="s2"
          gutter="s3"
          ref={expandedRef}
        >
          <Stack justify={"space-between"}>
            <TaskGroupHeader
              progressWidth={30}
              group={group}
              overdueDays={overdueDays}
              shouldCalcPercentageOfAllSteps={showHiddenSteps}
              showProgress
              noEllipsis={false}
              isProcessFlow
            />
            <HoverAbleSVG
              icon={AccordionCollapse}
              onClick={() => handleSwimlaneClick(false)}
              css={css`
                margin-left: 8px;
              `}
            />
          </Stack>
          <Stack justify="flex-start" align="center">
            {!!group.accessibleBy?.length && (
              <AccessibleTagsPopover accessibleBy={group.accessibleBy} />
            )}
          </Stack>
          <Stack
            direction="vertical"
            css={css`
              border-radius: var(--small-border-radius) !important;
              background: var(--neutral-0);
              border: 1px solid ${swimlaneStyleByStatus.border} !important;
              width: 100%;
              overflow-y: auto;
              max-height: 300px;
            `}
            className="custom-node-expanded"
          >
            {steps.map(step => (
              <TaskView
                task={step}
                isSelectedTask={false}
                key={step.id}
                displayDeadline={true}
                onSelect={(taskId: number) => () =>
                  handleTaskViewClick(taskId, step)
                }
                tooltipContainer={() =>
                  document.getElementById(LAYOUT_ID) as HTMLElement
                }
              />
            ))}
          </Stack>
        </Stack>
        {isGroupOverdue && overdueDays && <OverdueTag />}
      </Fragment>
    );
  }

  return (
    <Tooltip
      title={
        isHiddenNode
          ? ""
          : intl.formatMessage({
              id: "tasklaneProgress.showAllStepsText",
              defaultMessage: "Click to show all steps"
            }) + "..."
      }
      placement="left"
    >
      <Fragment>
        <Stack
          gap="s4"
          gutter="s3"
          align="flex-start"
          justify="flex-start"
          direction="vertical"
          className={`custom-node ${isHiddenNode ? "disabled-node" : ""}`}
          css={css`
            min-width: 250px !important;
            max-width: 370px !important;
            box-shadow: 0px 4px 8px rgba(0, 22, 78, 0.06);
            background: ${swimlaneStyleByStatus.background} !important;
            border: ${swimlaneStyleByStatus.border};
            border-radius: var(--big-border-radius) !important;
            padding: 14px 18px;
            opacity: ${!(
              taskCategoryFilter === "ALL_TASKS" ||
              taggedStepGroups.indexOf(group.id) > -1
            ) && 0.5};
            @keyframes borderFadeInOut {
              0% {
                border-color: var(--neutral-50);
              }
              100% {
                border-color: var(--teal);
              }
            }

            animation: ${!isGroupOverdue &&
            !group.isCompleted &&
            swimlaneProgressPercent > 0
              ? "borderFadeInOut 1s ease-in-out infinite alternate !important"
              : ""};
          `}
          onClick={() => handleSwimlaneClick(true)}
        >
          <TaskGroupHeader
            progressWidth={30}
            group={group}
            overdueDays={overdueDays}
            shouldCalcPercentageOfAllSteps={showHiddenSteps}
            showProgress
            noEllipsis={false}
            isProcessFlow
          />
          <AssignedToTags group={group} />
        </Stack>
        {isGroupOverdue && overdueDays && <OverdueTag />}
      </Fragment>
    </Tooltip>
  );
}
