import type { ReactElement } from "react";
import React from "react";
import { ClassNames } from "@emotion/core";
import {
  Tooltip,
  Stack,
  Text,
  Tag,
  LAYOUT_ID,
  getAvatarLetters
} from "@certa/blocks/thanos";
import { AvatarColors, AvatarSizes, Avatar } from "@certa/catalyst";
import type { IStepUser, SelectOption } from "@certa/types";
import { useIntl } from "react-intl";
import { UserGroup } from "@certa/icons";
import { useCurrentUser } from "@certa/common";

type Props = {
  assignedToGroups: SelectOption[];
  directAssigneeList?: IStepUser[];
};

const AssignedToTagsPopover = ({
  assignedToGroups,
  directAssigneeList
}: Props): ReactElement => {
  const intl = useIntl();

  const currentUser = useCurrentUser();

  return (
    <ClassNames>
      {({ cx, css }) => (
        <Tooltip
          id="tooltip"
          placement="bottomRight"
          overlayClassName={css`
            &.ant-tooltip {
              .ant-tooltip-arrow:before {
                background: var(--neutral-0);
              }
              .ant-tooltip-inner {
                border-radius: var(--big-border-radius);
                background: var(--neutral-0);
                color: var(--neutral-70);
                font-size: var(--p3);
                line-height: var(--p3-line-height);
                font-weight: var(--regular);
                padding: var(--s4);
                min-height: 28px;
                min-width: 300px;
                align-items: center;
                & > * {
                  flex-grow: 1;
                }
              }
            }
          `}
          getPopupContainer={() =>
            document.getElementById(LAYOUT_ID) || document.body
          }
          overlay={
            <Stack direction="vertical" gap="s3" align={"flex-start"}>
              <Text variant="p2-bold-upper">
                {intl.formatMessage({
                  id: "tasklaneProgress.assignedTo",
                  defaultMessage: "Assigned To"
                })}
              </Text>
              <Stack wrap="wrap" align="center">
                {assignedToGroups?.map(({ label, value }) => (
                  <Tag
                    icon={<UserGroup color="neutral-100" autoSize />}
                    key={value}
                  >
                    <Text variant="p1-medium" color="neutral-100">
                      {label}
                    </Text>
                  </Tag>
                ))}
                {directAssigneeList?.map(user => {
                  const isCurrentUser = currentUser.email === user?.user_email;
                  const userNameOrEmail =
                    user?.user_full_name?.trim() || user?.user_email;
                  const avatarLetters = getAvatarLetters(userNameOrEmail);
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Avatar
                      color={
                        isCurrentUser
                          ? AvatarColors.BRAND
                          : AvatarColors.NEUTRAL
                      }
                      aria-label={userNameOrEmail}
                      size={AvatarSizes.SMALL}
                    >
                      {avatarLetters}
                    </Avatar>
                  );
                })}
              </Stack>
            </Stack>
          }
        >
          <Tag
            style={{ cursor: "pointer" }}
            icon={<UserGroup color="neutral-100" autoSize />}
            key="more"
          >
            <Text variant="p1-medium" color="neutral-100">
              +{assignedToGroups.length - 1}
            </Text>
          </Tag>
        </Tooltip>
      )}
    </ClassNames>
  );
};

export default AssignedToTagsPopover;
