import type {
  AggregateModelledResponse,
  ChartConfigOperationFunction,
  ChartData
} from "../types";
import { INDEX_ZERO } from "../constants/common";
import type { AggregateResponse } from "./aggregateModelCreator";
import { CYCLE_TIME_METRICS, commonXAxisLabel } from "../constants/chartConfig";
import { matricObject } from "./stepCycleChartModelCreator";

export const cycleTimeModelCreatorORM = (
  data: AggregateResponse | undefined,
  isSwimlaneLevelCycleTime: boolean,
  metrics: ChartConfigOperationFunction[] | undefined
): AggregateModelledResponse => {
  const X_AXIS_KEY = isSwimlaneLevelCycleTime
    ? commonXAxisLabel.PROCESS_SWIMLANES
    : commonXAxisLabel.ALL_STEPS;

  if (!data || !metrics) {
    return { xAxisKey: X_AXIS_KEY, probableDataType: "cycle-time", data: [] };
  }
  const [labels, dataPoints] = data;
  if (!Array.isArray(labels) || !Array.isArray(dataPoints)) {
    return { xAxisKey: X_AXIS_KEY, probableDataType: "cycle-time", data: [] };
  }

  const metricsType = getAggregateMetricType(metrics);
  let results: Record<string, ChartData>[] = [];

  if (metricsType === "all") {
    results = getChartDataForAllMetrics(labels, dataPoints, X_AXIS_KEY);
  } else {
    results = getChartDataForMetrics(
      labels,
      dataPoints,
      X_AXIS_KEY,
      metricsType
    );
  }

  return {
    xAxisKey: X_AXIS_KEY,
    probableDataType: "cycle-time",
    data: results
  };
};

const getAggregateMetricType = (metrics: ChartConfigOperationFunction[]) => {
  const metric = metrics[INDEX_ZERO];
  // if there is more then one type of metric, then metric is "all"
  const isMetricTypeAll = metrics.some(item => item !== metric);
  return isMetricTypeAll ? "all" : metric;
};

const cycleTimeMatricsLength = CYCLE_TIME_METRICS.length;
const getChartDataForAllMetrics = (
  labels: string[],
  dataPoints: ChartData[],
  XAxisKey: string
): Record<string, ChartData>[] => {
  return labels.reduce(
    (acc, label, index) => {
      if (index % cycleTimeMatricsLength === INDEX_ZERO) {
        // sequence of value is dependent on the sequence of CYCLE_TIME_METRICS
        const [min, max, avg] = dataPoints.slice(
          index,
          index + cycleTimeMatricsLength
        );
        return [
          ...acc,
          {
            [XAxisKey]: label,
            [matricObject["min"]]: min,
            [matricObject["max"]]: max,
            [matricObject["avg"]]: avg
          }
        ];
      }
      return acc;
    },
    [] as Record<string, ChartData>[]
  );
};

const getChartDataForMetrics = (
  labels: string[],
  dataPoints: ChartData[],
  XAxisKey: string,
  metricsType: ChartConfigOperationFunction
) => {
  return labels.map((label, index) => {
    const returnObject: Record<string, ChartData> = {
      [XAxisKey]: label
    };
    const metric = matricObject[metricsType];
    const value = dataPoints[index];

    if (typeof value === "number" || value === null) {
      returnObject[metric] = value;
    }
    return returnObject;
  });
};
