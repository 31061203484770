import { css, cx } from "emotion";
import { ClassNames } from "@emotion/core";
import type { FC } from "react";
import { Progress as AntProgress } from "antd";
import { getColor } from "../../utils";
import React from "react";
import { Stack } from "../../componentsTh/Stack";
import { Checkmark } from "@certa/icons";
import type { TooltipProps } from "../Tooltip";
import { Tooltip } from "../Tooltip";

export const MAX_PROGRESS_PERCENTAGE = 100;
export const NA_PROGRESS_PERCENTAGE = -1;

export type ProgressType = {
  onClick?: () => void;
  progressPercentage: number;
  strokeColor?: "teal" | "orange" | "red" | "brand";
  applyHoverStyle?: boolean;
  className?: string;
  type?: "circle" | "line";
  width?: number;
  strokeWidth?: number;
  size?: "default" | "small";
  showInfo?: boolean;
  toolTipProps?: TooltipProps;
};

const Progress: FC<ProgressType> = ({
  onClick,
  progressPercentage,
  strokeColor,
  applyHoverStyle = true,
  className,
  type = "circle",
  width,
  strokeWidth = 18,
  size = "default",
  showInfo = false,
  toolTipProps
}) => {
  if (type === "line") {
    return (
      <ClassNames>
        {({ cx, css }) => (
          <AntProgress
            type={type}
            className={cx(
              className,
              css`
                width: ${width || 32}px;
                cursor: pointer;
                .ant-progress-bg {
                  height: var(--s1) !important;
                }
                display: inline-block;
              `
            )}
            percent={progressPercentage}
            strokeColor={getColor(strokeColor || "green")}
            showInfo={showInfo}
            data-testid="progress-bar-line"
            size={size}
          />
        )}
      </ClassNames>
    );
  }

  // -1 is also consider completed since there are no progressive steps
  const ProgressCircle =
    progressPercentage >= MAX_PROGRESS_PERCENTAGE ||
    progressPercentage === NA_PROGRESS_PERCENTAGE ? (
      <Checkmark
        className={css`
          margin: 2px;
          color: var(--green);
        `}
        size={20}
      />
    ) : (
      <AntProgress
        type={type}
        width={width || 24}
        className={cx(css`
          &.ant-progress-circle {
            height: ${width || 24}px;
          }
        `)}
        strokeWidth={strokeWidth}
        percent={progressPercentage}
        strokeColor={getColor(strokeColor || "teal")}
        trailColor={`var(--${strokeColor || "teal"}-40)`}
        showInfo={false}
        strokeLinecap="square"
        data-testid="progress-bar-circle"
      />
    );

  return (
    <Stack
      onClick={onClick}
      className={cx(
        applyHoverStyle
          ? css`
              border-radius: var(--s1);
              padding: 6px;
              display: inline-block;
              cursor: ${onClick && `pointer`};
              &:hover {
                background: ${progressPercentage >= 100
                  ? "var(--green-40)"
                  : `var(--${strokeColor || "teal"}-40)`};
                .ant-progress-circle-trail {
                  stroke: var(--${strokeColor || "teal"}) !important;
                  opacity: 0.3;
                }
              }
            `
          : undefined,
        className
      )}
    >
      {toolTipProps?.title ? (
        <Tooltip {...toolTipProps}>{ProgressCircle}</Tooltip>
      ) : (
        <React.Fragment>{ProgressCircle}</React.Fragment>
      )}
    </Stack>
  );
};

export { Progress };
