import type { FC } from "react";
import React, { useRef, useLayoutEffect, useState } from "react";
import { Stack } from "../../componentsTh/Stack";
import { Tooltip, Badge } from "@certa/catalyst";
import { Text } from "../../componentsTh/Typography/Text";
import { Loader } from "../../layout/Loader";
import { Image } from "../../layout/Image";
import { css, cx } from "emotion";
import {
  Checkmark,
  Circle,
  Clock,
  DelayedClock,
  Hidden,
  Lock,
  Info,
  Sparks
} from "@certa/icons";
import type { TaskProps } from "./types";
import { TASK_VARIANTS } from "./types";
import AvatarStack from "./AvatarStack";

const getTaskStatus = (type: TASK_VARIANTS) => {
  switch (type) {
    case TASK_VARIANTS.COMPLETED:
      return "Task Completed";
    case TASK_VARIANTS.DELAYED:
      return "Task Delayed";
    case TASK_VARIANTS.TO_BE_DELAYED:
      return "Task to be Delayed";
    case TASK_VARIANTS.HIDDEN:
      return "Task Hidden";
    case TASK_VARIANTS.FYI:
      return "Task FYI";
    case TASK_VARIANTS.LOCKED:
      return "Task Locked";
    case TASK_VARIANTS.ASSISTED_BY_AI:
      return "Task Assisted by AI";
    default:
      return "Task";
  }
};

const Task: FC<TaskProps> = props => {
  const {
    type,
    isSelectedTask = false,
    title,
    avatars,
    taskWidth,
    overdueDays,
    canShowInlineLoader,
    stepEnhancer = false,
    avatarWrapper,
    alertCount,
    alertColor = "var(--colors-teal-300)",
    alertWrapper,
    alertWrapperProps,
    onClick,
    integrationImageSrc,
    extraAvatarWrapper,
    extraAvatarWrapperProps,
    isLockedStepHighlight
  } = props;
  const infoStackRef = useRef<HTMLDivElement>(null);
  const AlertWrapper = alertWrapper || Stack;
  const wrapperRef = useRef<HTMLButtonElement>(null);
  const [ellipsisWidth, setEllipsisWidth] = useState(0);

  useLayoutEffect(() => {
    setEllipsisWidth(
      (wrapperRef.current?.clientWidth || taskWidth) -
        (infoStackRef?.current?.clientWidth || 0) -
        48
    );
  }, [taskWidth, props]);

  const Icon = () => {
    switch (type) {
      case TASK_VARIANTS.COMPLETED:
        return <Checkmark color="green" aria-label={getTaskStatus(type)} />;
      case TASK_VARIANTS.DELAYED:
        return <Clock color="red" aria-label={getTaskStatus(type)} />;
      case TASK_VARIANTS.TO_BE_DELAYED:
        return <DelayedClock color="orange" aria-label={getTaskStatus(type)} />;
      case TASK_VARIANTS.HIDDEN:
        return <Hidden color="neutral-50" aria-label={getTaskStatus(type)} />;
      case TASK_VARIANTS.FYI:
        return <Info color="teal" aria-label={getTaskStatus(type)} />;
      case TASK_VARIANTS.LOCKED:
        return <Lock color="neutral-70" aria-label={getTaskStatus(type)} />;
      case TASK_VARIANTS.MY_TASK:
        return <Circle color="neutral-100" svgProps={{ fill: "white" }} />;
      case TASK_VARIANTS.ASSISTED_BY_AI:
        return <Sparks color="brand" aria-label={getTaskStatus(type)} />;
      default:
        return <Circle color="neutral-100" svgProps={{ fill: "white" }} />;
    }
  };

  return (
    <button
      onClick={onClick}
      className={cx(
        css`
          width: 100%;
          padding: var(--s1) var(--s2);
          border-radius: var(--small-border-radius);
          min-height: 34px;
          border: 1px solid transparent; // For hover state jump fix
          cursor: pointer;
          transition: all ease-in-out 160ms;
          justify-content: center;
          min-width: 200px;
          width: 100%;
          display: flex;
          background: transparent;
          flex-direction: column;
          &:focus {
            outline: 2px solid var(--colors-brand-400);
            outline-offset: 0;
          }
        `,
        style.taskHoverStyle(
          type,
          isSelectedTask,
          stepEnhancer,
          isLockedStepHighlight
        )
      )}
      ref={wrapperRef}
      aria-label={`Task: ${title}`}
    >
      <Stack align="center" justify="space-between" itemWidth="100%">
        <Stack gap="s2" align="center" css={style.taskView}>
          <Icon />
          <Tooltip content={title} showArrow={true}>
            <Text
              as="span"
              variant="p1-regular"
              ellipsis={ellipsisWidth}
              useMaxWidth
              aria-label={title + " " + getTaskStatus(type)}
            >
              {title}
            </Text>
          </Tooltip>
        </Stack>
        {canShowInlineLoader ? (
          <Loader size="small" />
        ) : (
          <Stack ref={infoStackRef} gap="s1" align="center">
            {integrationImageSrc && (
              <Image
                alt="myTaskCompleted"
                src={integrationImageSrc}
                height="24px" //height of the image (same of the height of Avartar)
              />
            )}
            {overdueDays && (
              <Text
                as="span"
                variant="p1-regular"
                align="right"
                aria-label={overdueDays + " overdue"}
              >
                {overdueDays}
              </Text>
            )}
            {alertCount && (
              <AlertWrapper {...alertWrapperProps}>
                <div
                  tabIndex={0}
                  aria-label={alertCount + " Alerts"}
                  className={avatarWrapperClassName}
                >
                  <Badge label={alertCount} backgroundColor={alertColor} />
                </div>
              </AlertWrapper>
            )}
            <AvatarStack
              avatars={avatars}
              avatarWrapper={avatarWrapper}
              extraAvatarWrapper={extraAvatarWrapper}
              extraAvatarWrapperProps={extraAvatarWrapperProps}
            />
          </Stack>
        )}
      </Stack>
    </button>
  );
};

const avatarWrapperClassName = css`
  &:focus {
    outline: 2px solid var(--colors-brand-400);
    outline-offset: 2px;
    border-radius: 0.25rem;
  }
`;

const style = {
  taskHoverStyle: (
    type: TASK_VARIANTS,
    isSelectedTask: boolean,
    stepEnhancer: boolean,
    isLockedStepHighlight?: boolean
  ) => {
    switch (type) {
      case TASK_VARIANTS.COMPLETED:
        return css`
          color: var(--neutral-100);
          background-color: ${isSelectedTask && "var(--green-40)"};
          &:hover {
            background: var(--green);
            color: var(--neutral-0);
            path {
              fill: var(--neutral-0);
            }
          }
        `;
      case TASK_VARIANTS.ASSISTED_BY_AI:
      case TASK_VARIANTS.NEUTRAL:
        return css`
          color: var(--neutral-100);
          background-color: ${isSelectedTask && `var(--neutral-10)`};
          &:hover {
            background: var(--brand-35);
          }
        `;
      case TASK_VARIANTS.DELAYED:
        return css`
          color: var(--red);
          background-color: ${isSelectedTask && `var(--neutral-10)`};
          border-color: ${stepEnhancer && `var(--red)`};
          &:hover {
            border-color: transparent;
            background: var(--red-40);
          }
        `;
      case TASK_VARIANTS.TO_BE_DELAYED:
        return css`
          color: var(--orange);
          background-color: ${isSelectedTask && `var(--neutral-10)`};
          border-color: ${stepEnhancer && `var(--orange)`};
          &:hover {
            border-color: transparent;
            background: var(--orange-40);
          }
        `;
      case TASK_VARIANTS.HIDDEN:
        return css`
          color: var(--neutral-50);
          cursor: no-drop;
        `;
      case TASK_VARIANTS.FYI:
        return css`
          color: var(--neutral-100);
          background-color: ${isSelectedTask && `var(--neutral-10)`};
          &:hover {
            background: var(--brand-35);
          }
        `;
      case TASK_VARIANTS.LOCKED:
        return css`
          color: ${isLockedStepHighlight
            ? "var(--colors-orange-500)"
            : "var(--neutral-70)"};
          background-color: ${isSelectedTask && `var(--neutral-10)`};
          &:hover {
            background: var(--brand-35);
            color: var(--neutral-100);
            path {
              fill: var(--neutral-100);
            }
          }
        `;
      case TASK_VARIANTS.MY_TASK:
        return css`
          background: ${isSelectedTask
            ? "var(--neutral-10)"
            : "var(--teal-40)"};
          color: var(--neutral-100);
          border-color: ${!isSelectedTask && `var(--teal)`};
          &:hover {
            background: var(--teal);
            color: var(--neutral-0);
          }
        `;
    }
  },
  taskView: css`
    span:not([role="img"]) {
      padding: var(--s1) 0;
    }
  `
};

export { Task };
