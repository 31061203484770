import { Stack } from "@certa/blocks";
import { Text } from "@certa/blocks/thanos";

export const ReloadRequiredPage = () => {
  return (
    <Stack
      style={{ height: "100vh", width: "100%" }}
      align="center"
      justify="center"
    >
      <Stack justify="center">
        <Text variant="h1-bold">
          Something went wrong. Please reload your browser.
        </Text>
      </Stack>
    </Stack>
  );
};
