import { ClassNames } from "@emotion/core";
import type { FC } from "react";
import React from "react";
import { Popover as AntPopover } from "antd";
import type { PopoverProps as AntPopoverProps } from "antd/lib/popover";
import { getLayoutContainer } from "../utils";

export type PopoverProps = Omit<AntPopoverProps, "getPopupContainer"> & {
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement | null;
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Popover } from '@certa/catalyst';
 */
const Popover: FC<PopoverProps> = props => {
  const { overlayClassName, getPopupContainer, ...otherProps } = props;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <AntPopover
          overlayClassName={cx(
            overlayClassName,
            // NOTE: Add custom css here whenever required.
            css``
          )}
          getPopupContainer={triggerNode =>
            getPopupContainer?.(triggerNode) || getLayoutContainer(triggerNode)
          }
          {...otherProps}
        />
      )}
    </ClassNames>
  );
};

export { Popover };
