// import { getReduxKey } from "../../_helpers/store";
import { languageConstants } from "../../constants";
import Godaam from "../../utils/storage";

export const languages = {
  endonyms: {
    en: "English",
    es: "Español",
    "es-cl": "Español Chileno",
    "es-419": "Español Latino",
    pt: "Português",
    "pt-br": "Português Brasil",
    ru: "русский",
    fr: "Français",
    "fr-ca": "Français canadien",
    de: "Deutsche",
    ja: "日本語 (にほんご)",
    ko: "한국어",
    ms: "Bahasa Melayu",
    th: "ไทย",
    vi: "Tiếng Việt",
    id: "Bahasa Indonesia",
    "zh-hans": "简体中文",
    "zh-hant": "正體中文",
    hi: "हिंदी",
    ar: "عربي",
    it: "Italiana",
    nl: "Nederlands",
    pl: "Polski",
    ro: "Română",
    tr: "Türkçe",
    ua: "українська",
    bs: "bosanski",
    he: "עִברִית",
    bg: "български",
    cs: "čeština",
    da: "dansk",
    el: "Ελληνικά",
    et: "eesti keel",
    fi: "Suomalainen",
    hr: "Hrvatski",
    hu: "Magyar",
    lt: "lietuvių",
    lv: "latviski",
    no: "norsk",
    sk: "slovenský",
    sr: "Српски",
    sv: "svenska",
    "es-ar": "Español Argentino",
    "es-co": "Español Colombia",
    "es-cr": "Español Costa Rica",
    "es-sv": "Español El Salvador",
    "es-gt": "Español guatemalteco",
    "es-ni": "Española Nicaragua"
  },
  names: {
    en: "English",
    es: "Spanish",
    "es-cl": "Spanish Chilean",
    "es-419": "Spanish Latin",
    pt: "Portuguese",
    "pt-br": "Portuguese (Brazil)",
    ru: "Russian",
    fr: "French",
    "fr-ca": "Canadian French",
    de: "German",
    ja: "Japanese",
    ko: "Korean",
    ms: "Malay",
    th: "Thai",
    vi: "Vietnamese",
    id: "Indonesian",
    "zh-hans": "Chinese (Simplified)",
    "zh-hant": "Chinese (Traditional)",
    hi: "Hindi",
    ar: "Arabic",
    it: "Italian (Standard)",
    nl: "Dutch (Standard)",
    pl: "Polish",
    ro: "Romanian",
    tr: "Turkish",
    ua: "Ukrainian",
    bs: "Bosnian",
    he: "Hebrew",
    bg: "Bulgarian",
    cs: "Czech",
    da: "Danish",
    el: "Greek",
    et: "Estonian",
    fi: "Finnish",
    hr: "Croatian",
    hu: "Hungarian",
    lt: "Lithuanian",
    lv: "Latvian",
    no: "Norwegian",
    sk: "Slovak",
    sr: "Serbian",
    sv: "Swedish",
    "es-ar": "Spanish (Argentina)",
    "es-co": "Spanish (Colombia)",
    "es-cr": "Spanish (Costa Rica)",
    "es-sv": "Spanish (El Salvador)",
    "es-gt": "Spanish (Guatemala)",
    "es-ni": "Spanish (Nicaragua)"
  }
};

export const getUserLanguage = () => {
  const { prefered_language: preferedLanguage } = JSON.parse(
    Godaam.user || "{}"
  );
  return (
    preferedLanguage ||
    Godaam.preferredLanguage ||
    languageConstants.DEFAULT_LOCALE
  ).toLowerCase();
};
