import { useMemo } from "react";
import { Paper, Text } from "@certa/blocks/thanos";
import type { TooltipProps } from "recharts";
import type { AxisFormatterResult, ChartIdentifiers } from "@certa/common";
import { INDEX_ZERO } from "@certa/common";

export const CustomTooltip = (
  props: TooltipProps<any, any> & {
    chartType?: ChartIdentifiers;
    xAxisLabelFormatter: (data: any, index: number) => any;
  }
) => {
  const {
    active: isActive,
    payload: tooltipPayload,
    label,
    chartType,
    xAxisLabelFormatter,
    formatter = (value: any) => value // since formatter can be undefined
  } = props;
  const [xLabel, payload] = useMemo(() => {
    if (tooltipPayload && tooltipPayload.length) {
      if (chartType === "PIE_CHART") {
        const {
          name,
          value,
          payload: { total, shouldShowPercentagesInValueLabels }
        } = tooltipPayload[0];

        return [
          xAxisLabelFormatter(name, 0) +
            percentageLabel(shouldShowPercentagesInValueLabels, value, total),
          tooltipPayload
        ];
      }
      if (chartType === "SCATTER_CHART") {
        return [
          xAxisLabelFormatter(tooltipPayload[0].payload.x, 0),
          tooltipPayload.filter((item, index) => index !== 0)
        ];
      }
    }

    return [xAxisLabelFormatter(label, 0), tooltipPayload];
  }, [chartType, label, tooltipPayload, xAxisLabelFormatter]);

  if (isActive && payload && payload.length) {
    return (
      <Paper gutter="s2" gap="s1" direction="vertical">
        <Text variant="p1-bold">{xLabel}</Text>
        {payload.map((payloadItem, index) => {
          const color =
            chartType !== "PIE_CHART"
              ? payloadItem.color
              : payloadItem.payload.fill;
          return (
            <Text key={payloadItem.dataKey}>
              <span style={{ color }}>
                {chartType === "SCATTER_CHART"
                  ? payloadItem.payload[`${payloadItem.dataKey}Label`]
                  : payloadItem.dataKey}
                :
              </span>{" "}
              {
                // @ts-expect-error - formatter function expects 5 arguments, but only 1 passed
                formatter(payloadItem.value)
              }
            </Text>
          );
        })}
      </Paper>
    );
  }

  return null;
};

export const percentageLabel = (
  shouldShowPercentagesInValueLabels: boolean | undefined,
  value: any,
  total: number,
  nextToValue: boolean = false
) => {
  if (shouldShowPercentagesInValueLabels && !isNaN(value)) {
    if (total > 0) {
      const percentage = (((value as number) / total) * 100).toPrecision(4);
      return nextToValue ? ` (${percentage}%)` : `: ${percentage}%`;
    } else return nextToValue ? "( 0%)" : ": 0%";
  }
  return "";
};

export const MultiXAxisCustomTooltip = (
  props: TooltipProps<any, any> & {
    multiXAxisLabelFormatter?: Record<string, AxisFormatterResult>;
    xAxisDataLabels?: Array<string>;
  }
) => {
  const {
    active: isActive,
    payload: tooltipPayload,
    multiXAxisLabelFormatter,
    xAxisDataLabels,
    formatter = (value: any) => value // since formatter can be undefined
  } = props;

  const xLabels = useMemo(() => {
    const xAxisLabels = xAxisDataLabels ? xAxisDataLabels : [];
    return xAxisLabels.map((xAxisLabel, index) => {
      const xLabel = xAxisLabel;
      const xAxisLabelFormatter = multiXAxisLabelFormatter?.[xAxisLabel];
      const xValue = tooltipPayload?.[INDEX_ZERO]?.payload?.[xAxisLabel];
      const xValueFormated = xAxisLabelFormatter?.(xValue, index);
      return `${xLabel}: ${xValueFormated}`;
    });
  }, [multiXAxisLabelFormatter, tooltipPayload, xAxisDataLabels]);

  if (isActive && tooltipPayload && tooltipPayload.length) {
    return (
      <Paper gutter="s2" gap="s1" direction="vertical">
        {xLabels.map(label => (
          <Text key={label} variant="p1-bold">
            {label}
          </Text>
        ))}
        {tooltipPayload.map((payloadItem, index) => {
          const color = payloadItem.color;
          return (
            <Text key={payloadItem.dataKey}>
              <span style={{ color }}>{payloadItem.dataKey}:</span>{" "}
              {
                // @ts-expect-error - formatter function expects 5 arguments, but only 1 passed
                formatter(payloadItem.value)
              }
            </Text>
          );
        })}
      </Paper>
    );
  }

  return null;
};
