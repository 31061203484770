import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import { PermissionTypes } from "@certa/types";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { FEATURE_FLAGS } from "main/src/modules/common/constants";
import { useCheckPermission } from "../utils/permissions/Chowkidar";

export const useIsProcessOverviewEnabled = (): boolean => {
  const hasWorkflowProfilePermission = useCheckPermission(
    PermissionTypes.CAN_VIEW_WORKFLOW_PROFILE
  );

  const isProcessOverviewEnabled = useSelector((state: ReduxState) =>
    lodashGet(state, "config.custom_ui_labels.specialFeatures", []).includes(
      FEATURE_FLAGS.ENABLE_PROCESS_OVERVIEW
    )
  );

  return isProcessOverviewEnabled && hasWorkflowProfilePermission;
};
