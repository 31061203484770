/**
 * NOTE: Import ordering is important in this component
 * to maintain correct CSS insertion order.
 *
 * Please follow the below order:
 * 1. Global CSS imports
 * 2. Third-party packages
 * 3. Other imports
 */

// Global CSS imports
import "./css/App.css";
import "antd/dist/antd.css";
/**
 * Importing `react-flow-renderer` and `@certa/cataylst` as side effect imports
 * so that all the global styles of the library are inserted before Emotion CSS
 * styles being used throughout platform. This is so that the library styles can
 * be overridden by Emotion styles.
 */
import "react-flow-renderer";
import "@certa/catalyst";

// Third-party packages
/**
 * It's better to import third-party packages before other imports because
 * third-party package may contain CSS imports as side effects which we may
 * want to override with our own styles.
 */
import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import { matchPath } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";

// Other imports
import { App } from "./js/App";
import { routeList } from "./modules/router/routesConfig";
import { initializeMixPanel } from "./js/_helpers/mixpanel";
import { tenant, envTag } from "./config";

if (import.meta.env.REACT_APP_MIXPANEL_PACKAGE_TOKEN) {
  initializeMixPanel();
}

if (import.meta.env.REACT_APP_ENABLE_SENTRY) {
  const history = createBrowserHistory();

  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    environment: envTag,

    integrations: [
      // This enables automatic instrumentation
      // The React Router instrumentation uses the React Router library to create pageload/navigation transactions
      // to ensure you collect meaningful performance data about the health of your page loads and associated requests.
      // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v4v5
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        routes: routeList.map(route => ({ path: route.path })),
        matchPath
      })
    ],

    // What fraction of transactions to send for performance monitoring
    tracesSampleRate: 0.05,

    // These origins will have `sentry-trace` and `baggage` headers attached for tracing.
    // TODO: Enable after https://thevetted.atlassian.net/browse/PLAT-21796 is implemented by BE
    // tracePropagationTargets: ["localhost", getAPIOrigin()],

    // We are using this to supress some errors from being sent to sentry
    ignoreErrors: [
      // Ignores both
      // "ResizeObserver loop limit" (deprecated) and
      // "ResizeObserver loop completed with undelivered notifications" errors
      /^ResizeObserver loop/,
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource",

      // This is an open bug on chromium project, which results in accidental closure of IndexDB.
      // Ignoring this error, because it is eating up our sentry quota.
      // Chromium Project Link: https://bugs.chromium.org/p/chromium/issues/detail?id=146284
      // DexieJS Thread Link: https://github.com/dexie/Dexie.js/issues/613#issuecomment-841608979
      "InvalidStateError: Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
    ],

    // This is a callback that will be called before sending the event to sentry
    beforeSend(event, hint: any) {
      const error = hint.originalException;
      if (
        error &&
        error.message &&
        error.message.match(/UnhandledRejection 403/i)
      ) {
        return null;
      }
      return event;
    }
  });

  const scope = Sentry.getCurrentScope();
  scope.setTag("tenant", tenant);
}

const container = document.getElementById("root");

if (import.meta.env.REACT_APP_USE_REACT_17 === "true") {
  // eslint-disable-next-line no-console
  console.info("Using React 17");
  render(<App />, container);
} else {
  // eslint-disable-next-line no-console
  console.info("Using React 18");
  const root = createRoot(container!);
  root.render(<App />);
}
