import React, { useEffect } from "react";
import { OverlayType, useOverlay } from "@certa/blocks";
import { FamilyContainer } from "../components/FamilyContainer";

export type SelectedProcessConfig = {
  selectedProcessId: number;
  rootProcessId: number | null;
  onCancel?: () => void;
  withoutHeader?: Boolean;
  isSelectedProcessMigrating?: boolean;
};

const PROCESS_FAMILY_OVERLAY_IDENTIFIER = "process-family-overlay-identifier";

export const useProcessFamily = (
  selectedProcessConfig: SelectedProcessConfig
) => {
  const [show, hide] = useOverlay(
    PROCESS_FAMILY_OVERLAY_IDENTIFIER,
    <FamilyContainer {...selectedProcessConfig} />,
    {
      type: OverlayType.MODAL,
      closable: false,
      keyboard: false,
      width: 800,
      height: "auto",
      footer: null,
      destroyOnClose: true,
      isCERTA2Enabled: true // Directly passing true since this in only used in new UI
    }
  );

  useEffect(() => {
    return () => hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [show];
};
