import React from "react";
import { Stack, Text, NewRequestIllustration } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";
import { TAB_LABEL_MAPPER } from "../constants";

export const NoResults = ({ logType }: { logType: string }) => {
  return (
    <Stack
      justify="center"
      align="center"
      direction="vertical"
      style={{ minHeight: "calc(100vh - 300px)" }} // to vertically center
    >
      <Stack align="center" gap="s2" direction="vertical">
        <NewRequestIllustration />
        <NoResultsText logType={logType} />
      </Stack>
    </Stack>
  );
};

export const NoResultsText = ({ logType }: { logType: string }) => {
  const intl = useIntl();

  return (
    <>
      <Text variant="h3-bold" color="neutral-70">
        {intl.formatMessage({
          id: "workflowsInstances.noText",
          defaultMessage: "No"
        })}{" "}
        <span style={{ textTransform: "lowercase" }}>
          {intl.formatMessage({
            id: "workflowsInstances.activityLog." + logType + "Tab",
            defaultMessage:
              TAB_LABEL_MAPPER[logType as keyof typeof TAB_LABEL_MAPPER]
          })}
        </span>{" "}
        {intl.formatMessage({
          id: "workflowsInstances.yetText",
          defaultMessage: "yet"
        })}
      </Text>
      <Text variant="p1-regular" color="neutral-70">
        {intl.formatMessage({
          id: "workflowsInstances.activityLog.noResultsText1",
          defaultMessage: "When new"
        })}{" "}
        <span style={{ textTransform: "lowercase" }}>
          {intl.formatMessage({
            id: "workflowsInstances.activityLog." + logType + "Tab",
            defaultMessage:
              TAB_LABEL_MAPPER[logType as keyof typeof TAB_LABEL_MAPPER]
          })}
        </span>{" "}
        {intl.formatMessage({
          id: "workflowsInstances.activityLog.noResultsText2",
          defaultMessage: "appears for this item, you will see it here"
        })}
      </Text>
    </>
  );
};
