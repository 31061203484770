import { useState, type FC } from "react";
import { useSubscriptions } from "@certa/queries";
import type { Subscription } from "@certa/types";
import {
  LoadingAndEmptyStatesWrapper,
  ListWrapper,
  SomethingWentWrong,
  getProcessDetailRoute,
  getDashboardsRoute,
  getFullScreenReportRoute
} from "@certa/common";
import { useMentionsContext, type CommentData } from "../../MentionsContext";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import { useHistory } from "react-router-dom";
import { Stack } from "@certa/catalyst";
import { SubscriptionItem } from "./SubscriptionItem";
import { Spinner3 } from "@certa/icons";
import { useIntl } from "react-intl";
import {
  MixPanelActions,
  MixPanelEvents
} from "../../../../../main/src/js/_helpers/mixpanel";

const getKey = (subscription: Subscription) => subscription.id.toString();
const getLabel = (subscription: Subscription) => subscription.name;

const renderSubscriptionItem = (subscription: Subscription) => (
  <SubscriptionItem subscription={subscription} />
);

const getObjectIdAsPerType = (subscription: Subscription) => {
  const { type } = subscription;
  switch (type) {
    case "workflow":
      return subscription?.workflow?.id;
    case "integrationresult":
      return subscription?.integrationResult?.uid;
    case "step":
      return subscription?.step?.id;
    case "field":
      return subscription?.field?.id;
    default:
      return null;
  }
};

const SubscriptionsContainer: FC = () => {
  const { status, data, isFetching, hasNextPage, fetchNextPage } =
    useSubscriptions();
  const [workflowId, setWorkflowId] = useState<number | null>(null);

  const { data: processDetails, status: processDetailsStatus } =
    useProcessDetails(workflowId);
  const { updateMode, updateCommentData } = useMentionsContext();
  const history = useHistory();

  const intl = useIntl();
  const noSubscriptionsMessage = intl.formatMessage({
    id: "notifications.noSubscriptionsMessage",
    defaultMessage: "You have no subscriptions!"
  });

  const noSubscriptionsSecondaryMessage = intl.formatMessage({
    id: "notifications.noSubscriptionsSecondaryMessage",
    defaultMessage:
      "Comment threads you subscribe to will appear here for quick access"
  });

  const loadingSubscriptionsMessage = intl.formatMessage({
    id: "notifications.loadingSubscriptionsMessage",
    defaultMessage: "Loading your subscriptions, please wait..."
  });

  if (status === "error") return <SomethingWentWrong />;

  const subscriptions = data?.pages.flatMap(page => page.results);
  const handleOnSubscriptionClick = (subscription: Subscription) => {
    MixPanelActions.track(
      MixPanelEvents.notificationEvents
        .NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_OPEN_SUBSCRIBED_THREAD
    );

    setWorkflowId(subscription?.workflow?.id);
    if (processDetailsStatus === "loading") return;
    const processName = subscription?.workflow?.name;

    // TODO: fix this to use proper constants REPORT_KIND_NAME and DASHBOARD_KIND_NAME
    // currently using them from dashboards package throws error
    const isDashboardMention = processName === "report_dashboard";
    const isReportMention = processName === "report_workflow";

    const objectId = getObjectIdAsPerType(subscription) || 0;
    const { type: objectType } = subscription;

    const location = history.location;
    const [, type, id] = location?.pathname?.split("/") || [];
    const subscriptionProcessId = subscription?.workflow?.id;
    const subscriptionStepGroupId = subscription?.stepGroup?.id;
    const stepId = subscription?.step?.id;
    const commentData: CommentData = {
      objectId,
      objectType,
      threadId: subscription?.id,
      processId: subscription?.workflow?.id,
      processName: subscription?.workflow?.name,
      workflowFamily:
        isDashboardMention || isReportMention
          ? processDetails?.workflowFamily
          : []
    };
    if (
      !(
        ["process", "dashboard", "report"].includes(type) &&
        subscriptionProcessId &&
        subscriptionProcessId === Number(id)
      )
    ) {
      if (isDashboardMention) {
        history.push(
          `${getDashboardsRoute(
            subscriptionProcessId
          )}?redirectFromComments=true`
        );
        return;
      }
      if (isReportMention) {
        if (isReportMention) {
          history.push(
            `${getFullScreenReportRoute(
              subscriptionProcessId
            )}?redirectFromComments=true`
          );
          return;
        }
      }

      history.push(
        `${getProcessDetailRoute(
          subscriptionProcessId
        )}?group=${subscriptionStepGroupId}&step=${stepId}&redirectFromComments=true&redirectCommentData=${JSON.stringify(
          commentData
        )}`
      );
    }

    updateCommentData(commentData);

    // changing drawer mode to comments drawer
    updateMode("comments");
  };

  return (
    <Stack direction="vertical">
      {subscriptions && subscriptions.length > 0 ? (
        <ListWrapper
          options={subscriptions}
          aria-label="subscriptions list box"
          getKey={getKey}
          getLabel={getLabel}
          onClick={subscriptionItem =>
            handleOnSubscriptionClick(subscriptionItem)
          }
          render={renderSubscriptionItem}
          loadMore={fetchNextPage}
          hasMore={hasNextPage || false}
        />
      ) : (
        <LoadingAndEmptyStatesWrapper
          primaryMessage={!isFetching ? noSubscriptionsMessage : ""}
          secondaryMessage={
            !isFetching
              ? noSubscriptionsSecondaryMessage
              : loadingSubscriptionsMessage
          }
          secondaryIcon={isFetching ? <Spinner3 size={12} /> : undefined}
        />
      )}
    </Stack>
  );
};

export { SubscriptionsContainer };
