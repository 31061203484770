import { css } from "emotion";
import type { FC } from "react";
import { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ProcessDetailPlaceholder, Loader } from "@certa/blocks";
import {
  ProcessLogo,
  Stack,
  Text,
  Button,
  Switch,
  ToggleTabsNew,
  Paper
} from "@certa/blocks/thanos";
import { useIntl, FormattedMessage } from "react-intl";
import { Divider } from "antd";
import {
  useGetStepUserTags,
  useGetProcessDetails,
  useGetProgressMap,
  useGetTaskDependencies
} from "@certa/queries";
import { useTaskUpdate } from "../hooks/taskUpdateSideEffects";
import { useTaskLaneUserFormatData } from "../hooks/taskConstraints";
import type { TaskChangeArgs } from "../../types";
import {
  getTaskRoute,
  getProcessDetailRoute,
  withErrorBoundary
} from "@certa/common";
import { FlowDiagram } from "./FlowChart/FlowDiagram";
import { TaskCategoryTypes } from "@certa/tasks/src/types";
import { useTaskCategoryFilters } from "./FlowChart/hooks/useTaskCategoryFilters";
import { Thunder } from "@certa/icons";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

const TaskStepgroupOverlayComponent: FC<{
  processId: number;
  hideOverlay: () => void;
  height?: string;
  overviewPage?: boolean;
}> = ({ processId, hideOverlay, height, overviewPage = false }) => {
  const intl = useIntl();
  const history = useHistory();
  const [canShowHiddenSteps, setCanShowHiddenSteps] = useState(false);

  const { data: taskDetails, status: taskStatus } =
    useGetProcessDetails(processId);

  const { data: taskLanes, status: taskLanesStatus } =
    useGetProgressMap(processId);

  const { data: stepUsers, status: stepUsersStatus } =
    useGetStepUserTags(processId);

  const { data: stepsDependencyData, status: stepsDependencyStatus } =
    useGetTaskDependencies(processId);

  const groupsList = taskLanes?.results;

  const isTaskRoute =
    history.location.pathname === getProcessDetailRoute(processId);

  const { handleTaskChange } = useTaskUpdate();
  const onTaskClick = (payload: TaskChangeArgs) => {
    // if on process details page
    if (isTaskRoute) handleTaskChange(payload);
    else {
      const { taskId, taskLaneId } = payload;
      history.push(
        getTaskRoute({
          processId: processId,
          taskLaneId,
          taskId
        })
      );
    }
    hideOverlay();
  };

  const taskLanesData = useTaskLaneUserFormatData(taskLanes, stepUsers);

  const isLoading =
    taskStatus === "idle" ||
    taskStatus === "loading" ||
    taskLanesStatus === "loading" ||
    stepUsersStatus === "idle" ||
    stepUsersStatus === "loading" ||
    stepsDependencyStatus === "idle" ||
    stepsDependencyStatus === "loading";

  const isError =
    taskStatus === "error" ||
    taskLanesStatus === "error" ||
    stepUsersStatus === "error";

  const filteredTaskLaneData = useMemo(
    () =>
      taskLanesData && {
        ...taskLanesData,
        results: taskLanesData.results.filter(
          group =>
            canShowHiddenSteps || group.steps.find(step => !step.isHidden)
        )
      },
    [canShowHiddenSteps, taskLanesData]
  );

  const {
    taskCategoryFilter,
    setTaskCategoryFilter,
    tasklaneFilteredByCategory,
    taggedStepGroups,
    taskCounts
  } = useTaskCategoryFilters({
    initialFilter: TaskCategoryTypes.ALL_TASKS,
    tasklane: filteredTaskLaneData
  });

  const onToggleShowHiddenSteps = (checked: boolean) => {
    MixPanelActions.track(
      MixPanelEvents.tasksPageEvents
        .PROGRESS_MAP_CLICK_SHOW_INACTIVE_STEPS_TOGGLE,
      { checked }
    );
    setCanShowHiddenSteps(checked);
  };

  const onChangeToggleTabs = (value: string) => {
    MixPanelActions.track(
      MixPanelEvents.tasksPageEvents.PROGRESS_MAP_CLICK_TAB,
      { tab: value }
    );
    setTaskCategoryFilter(value as TaskCategoryTypes);
  };

  if (isLoading)
    return (
      <Stack
        justify="center"
        align="center"
        className={css`
          min-height: 84px;
        `}
      >
        <Loader />
      </Stack>
    );

  if (isError)
    return (
      <Stack direction="vertical" align="center" justify="center">
        <Text variant="h2-regular" color="red">
          <FormattedMessage id="notificationInstances.somethingWentWrong" />
        </Text>
      </Stack>
    );

  return (
    <Stack direction="vertical">
      <Stack
        justify="space-between"
        align="center"
        className={
          !overviewPage
            ? css`
                padding: var(--s6) var(--s8) var(--s3) var(--s8);
              `
            : ""
        }
      >
        {!overviewPage && (
          <Stack gap="s2" align="center">
            {taskDetails?.logo ? (
              <ProcessLogo
                logoURL={taskDetails?.logo}
                name={taskDetails?.name}
              />
            ) : (
              <ProcessDetailPlaceholder />
            )}
            <Stack gap="s1" align="start" direction="vertical">
              <Text
                variant="h3-bold"
                color="neutral-100"
                useMaxWidth
                ellipsis={490} //renders 50 characters before ellipsing
              >
                {taskDetails?.name}
              </Text>
              <Text
                variant="h3-medium"
                color="neutral-70"
                useMaxWidth
                ellipsis={480} //renders 50 characters before ellipsing
              >
                {taskDetails?.definition.name}
              </Text>
            </Stack>
          </Stack>
        )}

        <Stack
          gap="s6"
          align="center"
          className={
            overviewPage
              ? css`
                  margin-bottom: var(--s2);
                  margin-left: auto;
                  right: 0px;
                `
              : css`
                  // !important is used to override the stack's margin-right used for gap
                  margin-right: 50px !important;
                  margin-bottom: var(--s2);
                `
          }
        >
          <Switch
            size="small"
            label="Show inactive steps"
            onChange={onToggleShowHiddenSteps}
            checked={canShowHiddenSteps}
          />
          {!isTaskRoute && (
            <Button
              type="ghost"
              onClick={() => {
                history.push(getProcessDetailRoute(processId));
                hideOverlay();
              }}
            >
              {intl.formatMessage({
                id: "process.viewDetails",
                defaultMessage: "View details"
              })}
            </Button>
          )}
        </Stack>
      </Stack>
      {!overviewPage && (
        <Divider
          className={css`
            margin-bottom: var(--s00);
          `}
        />
      )}
      {/* taskLanesData will be empty only when there is no permission to view the progressmap */}
      {taskLanesData ? (
        <>
          <Paper
            gutter="s1"
            className={css`
              position: absolute;
              top: ${overviewPage ? "var(--s10)" : "calc(var(--s20) + 26px)"};
              left: var(--s6);
              z-index: var(--zindex-fixed);
              padding: var(--s1);
              box-shadow: 0px 1px 3px rgba(0, 22, 78, 0.15);
              & + div {
                border-radius: var(--big-border-radius);
                border: var(--s0) solid var(--neutral-20);
              }
            `}
          >
            <ToggleTabsNew
              options={[
                {
                  label: "All",
                  value: TaskCategoryTypes.ALL_TASKS
                },
                {
                  label: "Waiting on me",
                  value: TaskCategoryTypes.ME,
                  icon: Thunder,
                  countBadgeProps: {
                    count: taskCounts.ME,
                    showZero: true
                  }
                },
                {
                  label: "Waiting on my groups",
                  value: TaskCategoryTypes.MY_GROUPS,
                  countBadgeProps: {
                    count: taskCounts.MY_GROUPS,
                    showZero: true
                  }
                }
              ]}
              onChange={onChangeToggleTabs}
            />
          </Paper>
          {tasklaneFilteredByCategory ? (
            <FlowDiagram
              taskCategoryFilter={taskCategoryFilter}
              onTaskClick={onTaskClick}
              taskLanesData={tasklaneFilteredByCategory}
              taggedStepGroups={taggedStepGroups}
              groupsList={groupsList}
              stepDependencies={stepsDependencyData}
              showHiddenSteps={canShowHiddenSteps}
              height={height}
            />
          ) : null}
        </>
      ) : (
        <Stack
          align="center"
          justify="center"
          className={css({
            // The flow diagram height is 65vh, so this is the same height as the flow diagram
            height: "65vh",
            padding: "var(--s8)"
          })}
        >
          <Text variant="h1-bold" align="center">
            {intl.formatMessage({
              id: "progressMap.noPermissions",
              defaultMessage:
                "You don't have permission to view the workflow map for this workflow. You can view the tasks of this workflow from the workflow details page."
            })}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export const TaskStepgroupOverlay = withErrorBoundary(
  TaskStepgroupOverlayComponent
);
