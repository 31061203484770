import { Stack } from "@certa/blocks";
import { Text } from "@certa/blocks/thanos";
import { ButtonVariants, Button } from "@certa/catalyst";

export const GetStoragePermission = ({
  askForPermission
}: {
  askForPermission: () => void;
}) => {
  return (
    <Stack
      style={{ width: "100%", height: "100vh" }}
      align="center"
      justify="center"
    >
      <Stack>
        <Text variant="h2-regular">
          Please follow below mentioned instructions:{" "}
        </Text>
        <li>
          Click on authenticate. This will authenticate you and redirect
          automatically.
        </li>
      </Stack>
      <Button onClick={askForPermission} variant={ButtonVariants.FILLED}>
        Authenticate
      </Button>
    </Stack>
  );
};
