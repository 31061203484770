import type { TActionGroup } from "./types";

export const ACTIVITY_TABS = [
  "significantEvents",
  "views",
  "edits",
  "emails",
  "alerts"
];

export const ACTIVITY_ACTION_GROUPS: TActionGroup = {
  significantEvents: [
    "step_submitted",
    "workflow_status_changed",
    "step_undo",
    "field_decrypted"
  ],
  edits: [
    "step_submitted",
    "step_undo",
    "step_approved",
    "response_changed",
    "child_workflow_created",
    "task_assigned",
    "task_unassigned",
    "workflow_status_changed",
    "adjudication_done",
    "integration_status_change",
    "integration_triggered_manually",
    "bulk_action_triggered"
  ],
  emails: [
    "sendgrid_email",
    "sendgrid_queued",
    "sendgrid_sent",
    "sendgrid_rejected",
    "sendgrid_failed",
    "sendgrid_bounced",
    "sendgrid_deferred",
    "sendgrid_delivered",
    "sendgrid_autoresponded",
    "sendgrid_opened",
    "sendgrid_clicked",
    "sendgrid_complained",
    "sendgrid_unsubscribed",
    "sendgrid_subscribed",
    "sendgrid_inbound",
    "sendgrid_inbound_failed",
    "sendgrid_unknown"
  ],
  views: ["step_viewed", "workflow_viewed", "field_decrypted"],
  alerts: ["alert_created", "alert_dismissed"]
};

export const TAB_LABEL_MAPPER = {
  significantEvents: "Significant Events",
  edits: "Edits",
  emails: "Emails",
  views: "Views",
  alerts: "Notifications"
};
