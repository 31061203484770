import type { ChangeEvent } from "react";
import { useState, useCallback, useEffect, useRef } from "react";
import { debounce } from "lodash-es";

/**
 *
 * @param value
 * @param time
 * @returns debouncedValue
 */
export const useDebouncedValue = <T>(value: T, time: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedValue(value);
    }, time);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [value, time]);

  return debouncedValue;
};

const eventToValue = (ev: any) => ev.target.value;

type UseDebouncedChange = <T = ChangeEvent<Element>>(props: {
  handleChange: (path: string, value: any) => void;
  defaultValue: any;
  data: any;
  path: string;
  eventToValueFunction?: (ev: any) => any;
  timeout?: number;
}) => {
  data: any;
  onChange: (value: T) => void;
  onClear: () => void;
};

export const useDebouncedChange: UseDebouncedChange = ({
  handleChange,
  defaultValue,
  data,
  path,
  eventToValueFunction = eventToValue,
  timeout = 300
}) => {
  const [input, setInput] = useState(data ?? defaultValue);
  useEffect(() => {
    setInput(data ?? defaultValue);
  }, [data, defaultValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce((newValue: string) => handleChange(path, newValue), timeout),
    [handleChange, path, timeout]
  );

  const onChange = useCallback(
    (ev: any) => {
      const newValue = eventToValueFunction(ev);
      setInput(newValue ?? defaultValue);
      debouncedUpdate(newValue);
    },
    [debouncedUpdate, defaultValue, eventToValueFunction]
  );

  const onClear = useCallback(() => {
    setInput(defaultValue);
    handleChange(path, undefined);
  }, [defaultValue, handleChange, path]);

  return { data: input, onChange, onClear };
};

export const useDebounceWithCancel = <T extends (...args: any[]) => void>(
  callback: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  const debouncedFuncRef = useRef<ReturnType<typeof debounce>>();

  const debouncedFunc = (...args: any) => {
    if (debouncedFuncRef.current) {
      debouncedFuncRef.current.cancel();
    }
    debouncedFuncRef.current = debounce(callback, delay);
    debouncedFuncRef.current(...args);
  };

  return debouncedFunc;
};
