import React from "react";
import type { SelectValue } from "@certa/blocks/src/componentsTh";
import { Select, SelectOption } from "@certa/blocks/src/componentsTh";
import { useDispatch } from "react-redux";
import { css } from "emotion";
import { languageActions } from "../../actions";
import {
  renderLanguageName,
  useGetPreferredLanguage
} from "../../utils/languageHelpers";

type MaintananceSelectLanguageProps = {
  languageData: Record<string, string>;

  onChangeLanguage: (value: string) => void;
};

export const MaintananceSelectLanguage = (
  props: MaintananceSelectLanguageProps
) => {
  const { languageData, onChangeLanguage } = props;
  const dispatch = useDispatch();

  const preferredLanguage = useGetPreferredLanguage();

  const handleLanguageChange = (value: SelectValue) => {
    dispatch(languageActions.setLanguage(value));
    onChangeLanguage(languageData[`${value}`]);
  };

  return (
    <Select
      value={preferredLanguage}
      className={css`
        color: var(--brand-15) !important;
        font-size: 12px !important;
        text-align: left;
        .ant-select-arrow {
          font-size: 10px;
          color: inherit;
        }
        &:hover {
          color: var(--brand) !important;
        }
      `}
      onChange={handleLanguageChange}
      bordered={false}
    >
      {Object.keys(languageData).map((locale: string) => (
        <SelectOption key={locale} value={locale}>
          {renderLanguageName(locale)}
        </SelectOption>
      ))}
    </Select>
  );
};
