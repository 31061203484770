import { css } from "emotion";

export const notificationsToggleStyles = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "var(--space-8)",
  boxShadow: "none",
  color: "var(--colors-neutral-100)",
  borderRadius: "var(--space-4)",
  border: "0.0625rem solid transparent",
  backgroundColor: "transparent",
  boxSizing: "border-box",
  cursor: "pointer",
  width: "fit-content",
  userSelect: "none",
  padding: "var(--space-0)",
  outline: "none",
  "&:focus": {
    outline: "none"
  },
  "&:focus-visible": {
    outline: "0.125rem solid var(--colors-brand-400)"
  }
});
