import type { FC } from "react";
import type { Title } from "../types";
import { css } from "emotion";

type HeadingProps = {
  show: boolean;
  title: Title;
  titleFor: "column" | "row";
};

export const Heading: FC<HeadingProps> = ({ show, title, titleFor }) => {
  if (!show) return null;
  else if (["string", "number", "undefined"].includes(typeof title))
    return (
      <h3 className={`${titleFor}-type-heading ${headingSytle}`}>{title}</h3>
    );
  return <>{title}</>;
};

const headingSytle = css`
   {
    text-align: center;
    max-height: 200px;
    max-width: 400px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
