import type { FC } from "react";
import { useIntl } from "react-intl";
import type { StackProps } from "@certa/blocks/thanos";
import { Stack, Text } from "@certa/blocks/thanos";

type NoDataInReportsProps = StackProps & {
  error?: string | null;
};

export const NoDataInReports: FC<NoDataInReportsProps> = props => {
  const intl = useIntl();
  return (
    <Stack
      direction="vertical"
      align="center"
      justify="center"
      style={{
        backgroundColor: "var(--neutral-5)",
        minHeight: 500,
        borderRadius: 8,
        padding: "var(--s4)",
        textAlign: "center"
      }}
    >
      <Text variant="p1-regular" color="var(--colors-neutral-800)">
        {props.error ||
          intl.formatMessage({
            id: "savedSearch.noResultsReports",
            defaultMessage: "There are no results for this Report"
          })}
      </Text>
    </Stack>
  );
};
