import type React from "react";
import { useMemo } from "react";
import type {
  StylesConfig,
  SelectInstance,
  CSSObjectWithLabel,
  GroupBase
} from "react-select";
import type { SelectOption } from "./GlobalSelect.types";

type SelectStylesHookArgs = {
  width: string;
  minMenuWidth?: string | number;
  noOptionsMessage?: string | null;
  hasUserStartedSelectingOption: boolean;
  selectRef: React.MutableRefObject<
    SelectInstance<SelectOption, boolean, GroupBase<SelectOption>> | undefined
  >;
  headerVisible?: boolean;
};

export function useSelectStyles({
  width,
  minMenuWidth,
  noOptionsMessage,
  hasUserStartedSelectingOption,
  selectRef,
  headerVisible
}: SelectStylesHookArgs): StylesConfig<SelectOption> {
  const stylesConfig: StylesConfig<SelectOption> = useMemo(
    () => ({
      container: (base, props) => ({
        ...base,
        width
      }),
      control: (base, props) => {
        const backgroundColor = "var(--colors-neutral-100)";
        let color = "var(--colors-neutral-700)";
        const hoverFocusBackgroundColor = "var(--colors-neutral-100)";

        if (props.hasValue || props.selectProps.inputValue) {
          color = "var(--colors-neutral-800)";
        }
        const borderRadius = "0.25rem";
        const borderBottom = "0.0625rem solid var(--colors-neutral-400)";

        return {
          ...base,
          position: "relative",
          display: "flex",
          alignItems: "center",
          cursor: props.isDisabled ? "not-allowed" : "text",
          fontWeight: 600,
          fontSize: "1rem",
          lineHeight: "1.25rem",
          borderRadius,
          minHeight: "4.37rem",
          padding: "var(--s6)",
          color,
          borderBottom,
          backgroundColor,
          ":focus-within": {
            // React Select menu portal has z-index of 9999 because we have menuShouldBlockScroll=true.
            // When react select dropdown is visible, none of the control components are clickable (eg, cross icon)
            // To overcome this we set z-index above 9999 here
            zIndex: 10000,
            backgroundColor: hoverFocusBackgroundColor
          },
          ":hover": {
            backgroundColor: hoverFocusBackgroundColor
          }
        };
      },
      indicatorSeparator: (base, props) => ({
        ...base,
        display: "none"
      }),
      input: (base, props) => {
        const isFocused = selectRef.current?.state.isFocused;

        const topPosition: CSSObjectWithLabel = {
          position: "absolute",
          top: "0"
        };
        return {
          ...base,
          padding: "0 0.5rem",
          ...(isFocused ? {} : topPosition),
          color: "var(--colors-neutral-800)"
        };
      },
      valueContainer: (base, props) => ({
        ...base,
        display: base.display
      }),
      menu: (base, props) => ({
        ...base,
        background: "var(--colors-neutral-100)",
        display: "flex",
        minWidth: minMenuWidth,
        width: selectRef.current?.controlRef?.getBoundingClientRect().width,
        height: "min(34.375rem, 95vh - 4.4rem)",
        borderBottomLeftRadius: "var(--s2)",
        borderBottomRightRadius: "var(--s2)",
        overflow: "hidden"
      }),
      menuList: (base, props) => ({
        ...base,
        width: "100%",
        maxHeight: "auto",
        height: headerVisible
          ? "min(27.75rem, 95vh - 11.06rem)"
          : "min(31.65rem, 95vh - 7.2rem)",
        paddingLeft: "var(--s7)",
        paddingRight: "var(--s7)"
      }),
      menuPortal: (base, props) => ({
        ...base,
        zIndex: 9999
      }),
      placeholder: (base, props) => ({
        ...base,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: "var(--colors-neutral-600)",
        padding: "0 0.5rem"
      }),
      option: (base, props) => {
        const backgroundColor = "var(--colors-neutral-100) ";
        const color = "var(--colors-neutral-800)";

        return {
          ...base,
          // minHeight: "2rem",
          display: "flex",
          alignItems: "center",
          fontWeight: "500",
          fontSize: "0.75rem",
          lineHeight: "1rem",
          borderRadius: "0rem",
          flexWrap: "wrap",
          overflowWrap: "anywhere",
          color,
          backgroundColor,
          // using outline as focus border and border bottom
          // are of different widths
          outline:
            props.isFocused &&
            !props.isDisabled &&
            hasUserStartedSelectingOption
              ? "0.125rem solid var(--colors-brand-400)"
              : "none",
          outlineOffset: "-2px",
          ":hover": props.isDisabled
            ? {
                cursor: "not-allowed"
              }
            : {
                cursor: "pointer",
                backgroundColor: "var(--colors-neutral-300)"
              }
        };
      },
      indicatorsContainer: base => ({
        ...base,
        display: "flex",
        columnGap: "0.5rem",
        paddingLeft: "0.5rem"
      }),
      clearIndicator: base => ({
        ...base,
        color: "var(--colors-neutral-700)"
      }),
      noOptionsMessage: base => ({
        ...base,
        padding: noOptionsMessage === null ? 0 : "0.5rem",
        fontSize: "0.75rem",
        lineHeight: "1rem"
      }),
      group: base => ({
        ...base,
        width: "100%"
      }),
      groupHeading: base => ({
        ...base,
        padding: "0.87rem 0.5rem",
        fontWeight: 600,
        fontSize: "0.625rem",
        lineHeight: "0.75rem",
        textTransform: "uppercase",
        color: "var(--colors-neutral-600)",
        letterSpacing: "0.0938rem",
        textDecoration: "capitalize"
      })
    }),
    [
      width,
      minMenuWidth,
      noOptionsMessage,
      hasUserStartedSelectingOption,
      selectRef,
      headerVisible
    ]
  );

  return stylesConfig;
}
