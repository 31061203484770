import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";
import type { ComponentType } from "react";
import { useEffect } from "react";

/**
 * @description Returns the component to load for external scripts
 * There can be two types of scripts
 *
 * 1. JS that needs to be executed (check userflow.js):
 * For such scripts we need to returns a custom component that calls this JS code
 *
 * 2. Scripts that needs to be loaded from a URL
 * For such scripts we need to returns a ScriptTag component that loads the script from the URL asynchronously
 */
export const useExternalScripts = () => {
  const scripts: string[] = useSelector((state: ReduxState) =>
    lodashGet(state, "config.custom_ui_labels.external_scripts", [])
  );
  return scripts
    .map(script => SCRIPT_IDENTIFIER_TO_COMPONENT_MAPPING[script])
    .filter(Boolean);
};

// This is a mapping of script identifiers to components
// Instead of hard coding the scripts in the admin panel, we will be using the identifiers
// and code for the script will remain in the FE codebase
const SCRIPT_IDENTIFIER_TO_COMPONENT_MAPPING: Record<string, ComponentType> = {
  USER_FLOW: function UserFlow() {
    useEffect(() => {
      if (import.meta.env.REACT_APP_USERFLOW_TOKEN) {
        import(/* webpackChunkName: "user-flow" */ "userflow.js").then(
          userflow => {
            userflow.default.init(
              `${import.meta.env.REACT_APP_USERFLOW_TOKEN}`
            );
          }
        );
      }
    }, []);
    return null;
  }
};
