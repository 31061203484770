import React, { useState, useMemo } from "react";
import { Tabs, Divider, message } from "antd";
import moment from "moment";
import download from "downloadjs";
import { ActivityLogList } from "./components/ActivityLogList";
import {
  Gravatar,
  Stack,
  ToggleTabs,
  Text,
  ProcessLogo
} from "@certa/blocks/thanos";
import { useIntl, FormattedMessage } from "react-intl";
import { css } from "emotion";
import {
  useDownloadActivityLog,
  useGetActivityLogs,
  useGetWorkflowDetails,
  useKinds
} from "@certa/queries";
import type { GroupedActivityLogs, TActionGroup } from "./types";
import {
  ACTIVITY_ACTION_GROUPS,
  ACTIVITY_TABS,
  TAB_LABEL_MAPPER
} from "./constants";
import { Download, Arrow } from "@certa/icons";
import { Loader } from "@certa/blocks";
import { activityLogResultsList, groupActivityLogs } from "./utils";
import { NoResults } from "./components/NoResults";
import { useScreenResolution } from "@certa/common";
import {
  showToast,
  ToastTypes,
  Button,
  ButtonTypes,
  ButtonSizes,
  Tooltip
} from "@certa/catalyst";

const TabPane = Tabs.TabPane;

type Props = {
  id: number | undefined;
  defaultActiveTab?: keyof TActionGroup;
};

const renderSomethingWentWrong = () => (
  <Stack direction="vertical" align="center" justify="center">
    <Text variant="h2-regular" color="red">
      <FormattedMessage id="notificationInstances.somethingWentWrong" />
    </Text>
  </Stack>
);

const renderLoader = () => (
  <Stack direction="vertical" align="center" justify="center">
    <Loader />
  </Stack>
);

export const ActivityLogs = ({
  id,
  defaultActiveTab = "significantEvents"
}: Props) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const { data: workflowDetails, status: workflowQuerytatus } =
    useGetWorkflowDetails(id);
  const { isMobileResolution } = useScreenResolution();

  const {
    name,
    createdAt: startDate,
    definition,
    logo
  } = workflowDetails || {};
  const { kind: kindId } = definition || {};
  const { data: kinds } = useKinds();
  const kindName = useMemo(() => {
    if (kinds && kindId) {
      const workflowKindsObj = kinds.find(item => item.id === kindId);
      return workflowKindsObj?.name;
    }
    return "";
  }, [kindId, kinds]);
  const intl = useIntl();
  const { data, status, fetchNextPage, hasNextPage, isLoading } =
    useGetActivityLogs({
      id,
      actions: ACTIVITY_ACTION_GROUPS[activeTab as keyof TActionGroup]
    });

  const resultsList = useMemo(
    () => activityLogResultsList(data, startDate),
    [data, startDate]
  );

  const groupedActivityLogs: GroupedActivityLogs = useMemo(
    () => groupActivityLogs(resultsList),
    [resultsList]
  );
  const { mutate: initDownload } = useDownloadActivityLog();
  const onTabChange = (key: string) => {
    setActiveTab(key as keyof TActionGroup);
  };

  const downloadFile = () => {
    const hideLoadingMessage = message.loading({
      content: intl.formatMessage({
        id: "commonTextInstances.preparingDownload",
        defaultMessage: "Preparing for download"
      }),
      duration: 0,
      icon: (
        <Loader
          size="small"
          className={css`
            margin-right: 12px;
          `}
        />
      )
    });
    initDownload(
      { id, startDate: startDate || "" },
      {
        onSuccess: resp => {
          resp.blob().then(function (blob) {
            // Allowing filename and MIME type to be decided by the backend
            // though it's possible to specify here
            download(
              blob,
              getFileName(resp) ||
                "activity_log_" + moment().format("YYYY-MM-DD") + ".xlsx"
            );
          });
        },
        onError: () => {
          showToast({
            type: ToastTypes.ERROR,
            title: intl.formatMessage({
              id: "errorMessageInstances.somethingWentWrong"
            }),
            description: intl.formatMessage({
              id: "errorMessageInstances.downloadFailed"
            })
          });
        },
        onSettled: () => {
          hideLoadingMessage();
        }
      }
    );
    function getFileName(resp: any) {
      const filename = resp.headers.get("Content-Disposition");
      return filename && (filename.match(/filename="(.*)"/) || []).pop();
    }
  };

  const tabOptions = useMemo(
    () =>
      ACTIVITY_TABS.map((key: string) => ({
        label: intl.formatMessage({
          id: "workflowsInstances.activityLog." + key + "Tab",
          defaultMessage: TAB_LABEL_MAPPER[key as keyof typeof TAB_LABEL_MAPPER]
        }),
        value: key
      })),
    [intl]
  );

  const hasNoResults =
    status === "success" && data && data?.pages?.[0]?.total === 0;
  if (workflowQuerytatus === "loading") {
    return renderLoader();
  }
  if (workflowQuerytatus === "error") {
    return renderSomethingWentWrong();
  }

  return (
    <Stack direction="vertical" gap="s3">
      <Stack gap="s2" align="center">
        {logo ? <ProcessLogo logoURL={logo} name={name} /> : <Gravatar />}
        <Text variant="h3-bold" color="neutral-100">
          {name}
        </Text>
        {kindName && (
          <>
            <Arrow color="neutral-70" />
            <Text variant="h3-bold" color="neutral-100">
              {kindName}
            </Text>
          </>
        )}
      </Stack>
      <Divider style={{ marginBottom: 0 }} />
      <Stack justify="space-between" align="center">
        <Stack gap="s3" align="center">
          <Text variant="p1-bold" color="neutral-100">
            {intl.formatMessage({
              id: "workflowsInstances.activityLog.actionText",
              defaultMessage: "Action"
            })}
          </Text>
          <ToggleTabs
            onChange={onTabChange}
            value={activeTab}
            options={tabOptions}
            max={isMobileResolution ? 2 : tabOptions.length}
          ></ToggleTabs>
        </Stack>
        <Tooltip
          content={intl.formatMessage({
            id: "workflowsInstances.activityLog.downloadTab",
            defaultMessage: "Download all"
          })}
          showArrow
        >
          <Button
            size={ButtonSizes.SMALL}
            type={ButtonTypes.ICON}
            rightIcon={<Download />}
            onClick={downloadFile}
            aria-label={intl.formatMessage({
              id: "workflowsInstances.activityLog.downloadTab",
              defaultMessage: "Download all"
            })}
          />
        </Tooltip>
      </Stack>
      <Tabs
        defaultActiveKey={defaultActiveTab}
        onChange={onTabChange}
        activeKey={activeTab}
        className={css`
          margin-right: calc(var(--s6) * -1) !important;
          .ant-tabs-bar {
            display: none; // used reusable ToggleTabs component intead of tabs
          }
        `}
      >
        {ACTIVITY_TABS.map(key => {
          return (
            <TabPane key={key} tab={key}>
              {hasNoResults && <NoResults logType={key} />}
              {status === "error" && renderSomethingWentWrong()}
              {status === "loading" ? (
                renderLoader()
              ) : (
                <ActivityLogList
                  loadData={() => {
                    fetchNextPage(); // to avoid page params to pass
                  }}
                  hasMore={hasNextPage || false}
                  groupedActivityLogs={groupedActivityLogs}
                  isLoading={isLoading}
                />
              )}
            </TabPane>
          );
        })}
      </Tabs>
    </Stack>
  );
};
