import type { Action } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import type { ThunkAction } from "redux-thunk";
import { rootReducer } from "../reducers";

export const store = configureStore({
  // When running tests, rootReducer is returned undefined
  // which throws an error since it is being imported in fetch.ts
  // which is getting imported before we mount redux store.
  // So, we need to provide default value for rootReducer.
  // Since we are manually mounting the store in tests,
  // this doesn't affect rests using redux store.
  //
  reducer: rootReducer || {}
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, any, null, Action<string>>;
