import { createBrowserHistory } from "history";
import { basename } from "../../config";

const browserHistory = createBrowserHistory({ basename });

/**
 * This will only run for PR Previews
 * This is removing #! from the URL as it's append to handle s3 404 not found error
 * when virtual path URL get reloaded
 */
if (basename) {
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    browserHistory.replace(path);
  }
}

export const history = browserHistory;
