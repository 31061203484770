import { usePlatformSettingsProcessID } from "@certa/queries";

export const usePlatformSettingsRoute = () => {
  const { isLoading, data: platformSettingsProcessID } =
    usePlatformSettingsProcessID();

  return {
    isLoading,
    route: platformSettingsProcessID
      ? `/process/${platformSettingsProcessID}`
      : null
  };
};
