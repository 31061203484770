/**
 * @deprecated Lets use query-string for this
 */

export const JSONToQueryParams = (query: any) => {
  const searchParams = new URLSearchParams();

  Object.keys(query).forEach(key => {
    if (query[key]) {
      if (Array.isArray(query[key])) {
        query[key].forEach((value: any) => searchParams.append(key, value));
      } else {
        searchParams.append(key, query[key]);
      }
    }
  });
  return searchParams.toString();
};

export const isHeyCertaAPI = (url: string) => {
  return url.indexOf("https://heycerta.") > -1;
};

export const isSubscriptionAPI = (url: string) => {
  return url.includes("subscriptions/");
};
