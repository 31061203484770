import type { ChartData } from "@certa/common";
import { UNIQUE_X_AXIS_IDENTIFIER } from "./constants";

export const createUniqueXAxis = (chartData: Record<string, ChartData>[]) => {
  return chartData.map(data => {
    const uniqueIdentifier = Object.values(data).join("~");
    return {
      ...data,
      [UNIQUE_X_AXIS_IDENTIFIER]: uniqueIdentifier
    };
  });
};
