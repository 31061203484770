import type { FC, ReactNode } from "react";
import React from "react";
import { Badge as AntBadge } from "antd";
import { ClassNames } from "@emotion/core";
import { useIntl } from "react-intl";
import type { Colors } from "../../types";
import { getColor } from "../../utils";

export type CountBadgeProps = {
  /** Number to show in badge */
  count?: number | string;
  /** Set background color of badge */
  color?: Colors | string;
  /** Whether to show badge when count is zero */
  showZero?: boolean;
  /** Max count to show */
  overflowCount?: number;
  /** Whether to show comma in longer numbers */
  separator?: boolean;
  /** Set offset of the badge [left, top] */
  offset?: [number, number];
  className?: string;
  children?: ReactNode;
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Badge } from '@certa/catalyst';
 */
export const CountBadge: FC<CountBadgeProps> = ({
  count: countProp,
  color,
  showZero = true,
  overflowCount = Infinity,
  separator = true,
  offset,
  className,
  children,
  ...rest
}) => {
  const intl = useIntl();

  const count =
    typeof countProp === "number" && separator
      ? intl.formatNumber(countProp)
      : countProp;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <AntBadge
          count={count}
          showZero={showZero}
          overflowCount={overflowCount}
          offset={offset}
          className={cx(
            css`
              &.ant-badge,
              &.ant-badge * {
                font-family: var(--font-family);
                font-size: ${children
                  ? "var(--p2-bold-upper-font-size)"
                  : "var(--p1-bold-font-size)"};
                font-weight: ${children
                  ? "var(--p2-bold-upper-font-weight)"
                  : "var(--p1-bold-font-weight)"};
              }
              & .ant-badge-count {
                height: ${children ? "16px" : "19px"};
                line-height: ${children ? "16px" : "19px"};
                border-radius: 3px;
                padding: 0 ${children ? "4px" : "5px"};
                min-width: unset;
                background: ${color ? getColor(color) : "var(--neutral-50)"};
                color: ${color ? "var(--neutral-0)" : "var(--neutral-70)"};
                ${children ? "pointer-events: none;" : ""}

                & .ant-scroll-number-only {
                  height: ${children ? "16px" : "19px"};

                  & > p.ant-scroll-number-only-unit {
                    height: ${children ? "16px" : "19px"};
                    color: ${color ? "var(--neutral-0)" : "var(--neutral-70)"};
                  }
                }
              }
              & .ant-badge-multiple-words {
                padding: 0 ${children ? "4px" : "5px"};
              }

              --label: CountBadge;
            `,
            className
          )}
          {...rest}
        >
          {children}
        </AntBadge>
      )}
    </ClassNames>
  );
};

CountBadge.defaultProps = {
  showZero: true,
  overflowCount: Infinity,
  separator: true
};
