import { useHistory } from "react-router-dom";

import { Alert, Stack } from "@certa/catalyst";

import { useAppSelector } from "../../hooks";

import { Bell2, Close } from "@certa/icons";
import { useMentionsContext } from "@certa/mentions";
import { useGetUnReadMentionCount } from "@certa/queries";
import { useHeaderBanner } from "../../..";
import styles from "./MobileHeader.module.css";

type MobileHeaderProps = {};

export const MOBILE_HEADER_ID = "certa-mobile-header";

export const MobileHeader = (props: MobileHeaderProps) => {
  const config = useAppSelector(state => state.config);

  const history = useHistory();

  const bannerConfig = useHeaderBanner();
  const { data: unReadMentionCount = 0 } = useGetUnReadMentionCount();
  const {
    toggleShow: toggleNotificationDrawer,
    show: shouldShowNotificationDrawer
  } = useMentionsContext();

  const handleOnClick = () => {
    history.push("/");
  };
  const shouldShowNotificationIcon = !shouldShowNotificationDrawer;

  const NotificationDrawerTriggerIcon = shouldShowNotificationDrawer
    ? Close
    : Bell2;

  return (
    <>
      {bannerConfig && (
        <Alert
          type={bannerConfig.type}
          message={bannerConfig.message}
          hasIcon={bannerConfig.hasIcon}
          width="100%"
        />
      )}
      <Stack
        height="3.75rem"
        width="100%"
        direction="horizontal"
        justify="space-between"
        padding="0 20"
        align="center"
        dangerouslySetClassName={styles.mobileHeader}
        id={MOBILE_HEADER_ID}
      >
        <button
          className={styles.mobileHeaderLogoContainer}
          onClick={handleOnClick}
          data-testid="mobile-header-logo-button"
        >
          <img src={config.logo} alt={config.name} />
        </button>

        <button
          onClick={() => toggleNotificationDrawer()}
          className={styles.notificationTriggerButton}
          data-testid="notification-trigger-button"
        >
          <NotificationDrawerTriggerIcon />
          {unReadMentionCount > 0 && shouldShowNotificationIcon && (
            <span className={styles.notifyDot} />
          )}
        </button>
      </Stack>
    </>
  );
};
