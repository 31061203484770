import type { FC } from "react";
import React from "react";
import styled from "@emotion/styled";
import { Radio as AntRadio } from "antd";
import type { RadioProps as AntRadioProps } from "antd/lib/radio";
import { Text } from "../Typography/Text";
import type { TypographyVariants } from "../Typography/types";
import { getVariantStyles } from "../Typography/utils";

export type RadioProps = {
  /** Makes label text color greyish if checkbox state is uncheck */
  textMuted?: boolean;
  /** Label text variant */
  variants?: TypographyVariants;
} & AntRadioProps;

const CustomRadio: FC<RadioProps> = props => {
  const {
    children,
    variants = "p1-regular",
    textMuted,
    disabled,
    ...restProps
  } = props;

  return (
    <AntRadio {...restProps} disabled={disabled}>
      <Text
        className="checkbox-text-label"
        variant={variants}
        color={textMuted || disabled ? "neutral-70" : "neutral-100"}
      >
        {children}
      </Text>
    </AntRadio>
  );
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Radio } from '@certa/catalyst';
 */
export const Radio = styled(CustomRadio)`
  ${props => getVariantStyles(props.variants || "p1-regular")};
  .ant-radio {
    font-size: inherit;
    vertical-align: bottom;
  }
  .ant-radio-inner {
    width: 1.2em;
    height: 1.2em;
    border-width: 2px;
    border-color: var(--neutral-70);
    padding: 2px;
  }
  .ant-radio-inner::after {
    content: none; // removes ant radio mark
  }
  .ant-radio-checked {
    & + span .radio-text-label {
      color: ${props => props.textMuted && "var(--neutral-100)"};
    }
    .ant-radio-inner {
      border-color: var(--brand);
      &:before {
        // creates custom radio mark
        content: "";
        background-color: var(--brand);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    &:after {
      content: none;
    }
  }
  &:hover .ant-radio-input:not(:disabled) + .ant-radio-inner,
  .ant-radio-input:focus-visible + .ant-radio-inner {
    box-shadow:
      0 0 0 1px #fff,
      0 0 0 3px var(--brand-15) !important;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
  &.ant-radio-wrapper:hover .ant-radio:not(.ant-radio-checked) .ant-radio-inner,
  .ant-radio-input:focus:not(:checked) + .ant-radio-inner {
    border-color: var(--neutral-70);
  }

  .ant-radio-disabled .ant-radio-inner {
    background-color: transparent !important;
    border-color: var(--neutral-50) !important;
    &:before {
      background-color: var(--neutral-50);
    }
  }
  --label: Radio;
`;
