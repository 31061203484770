import { css } from "emotion";
import { DEFAULT_AXIS_HEIHGT, MAX_TOOLTIP_WIDTH } from "../../../constants";

export const lineStyle = css({
  cursor: "pointer",
  "& .recharts-bar:not(:hover) .recharts-label-list text": {
    display: "none"
  },
  "& .recharts-reference-line:not(:hover) .recharts-label tspan": {
    visibility: "hidden"
  }
});

export const activeXLabel = css({
  "& .recharts-reference-line:not(:hover) .recharts-label tspan": {
    visibility: "hidden"
  }
});

export const tooltipCSS = (height: number) =>
  css({
    maxHeight: height - DEFAULT_AXIS_HEIHGT,
    maxWidth: MAX_TOOLTIP_WIDTH,
    overflow: "auto",
    pointerEvents: "auto"
  });
