import type { TaskLaneTaskType } from "../../../../types";
import { submittableSteps } from "../../../../types";
import type { StepGroup } from "@certa/types";

const DEFAULT_TASK_COUNT = 0;

const getSubmittableIncompleteTasksCount = (
  stepGroups: StepGroup[]
): number => {
  return stepGroups.reduce((tasksCount, group) => {
    const submittableIncompleteSteps = group.steps.filter(
      step =>
        !step.isCompleted &&
        submittableSteps.includes(step.stepType as TaskLaneTaskType)
    );

    return tasksCount + submittableIncompleteSteps.length;
  }, DEFAULT_TASK_COUNT);
};

export { getSubmittableIncompleteTasksCount };
