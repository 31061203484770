/** @jsx jsx */
/** @jsxRuntime classic */
import {
  getAppListGenericRoute,
  getDashboardsGenericRoute,
  getTasksRoute,
  getTemplatesGenericRoute,
  getHomeRoute,
  getStudioGenericRoute
} from "../../utils/routes";
import { AssignedTaskCount } from "./AssignedTaskCount";
import type { LinkProps } from "./types";
import { PermissionTypes } from "@certa/types";
import { Tasks, App, Chart, Gear, Template, House } from "@certa/icons";
import { usePlatformSettingsRoute } from "./header.hook";

// TODO: INTL

export const defaultNavLinks: LinkProps[] = [
  {
    route: getHomeRoute(),
    icon: House,
    label: "Home",
    hideForSuppliers: false
  },
  {
    route: getDashboardsGenericRoute(),
    icon: Chart,
    label: "Dashboards",
    alias: [/\/dashboard\/\d+/],
    hideForSuppliers: false,
    checkPermissions: [PermissionTypes.CAN_VIEW_DASHBOARD]
  },
  {
    route: getTasksRoute(),
    icon: Tasks,
    label: "Tasks",
    extra: AssignedTaskCount,
    hideForSuppliers: false,
    checkPermissions: [PermissionTypes.CAN_VIEW_TASKS]
  },
  {
    route: getStudioGenericRoute(),
    icon: App,
    label: "Studio",
    checkPermissions: [PermissionTypes.CAN_ACCESS_STUDIO],
    hideForSuppliers: true,
    target: "_blank"
  }
];

export const appBuilderNavLinks: LinkProps[] = [
  {
    route: getAppListGenericRoute(),
    icon: App,
    label: "Apps",
    alias: [/\/appbuilder\/apps\/\d+/],
    hideForSuppliers: true
  },
  {
    route: getTemplatesGenericRoute(),
    icon: Template,
    label: "Templates",
    hideForSuppliers: true
  },
  {
    // TODO: Make the route prop optional iff when hook is passed
    // Another approach: Use a hook to generate `appBuilderNavLinks` constant itself
    // will also give us the ability to support intl
    route: "/dummy-route",
    useRoute: usePlatformSettingsRoute,
    icon: Gear,
    label: "Configuration Values",
    hideForSuppliers: true
  }
];

export const studioNavLinks: LinkProps[] = [
  {
    route: getStudioGenericRoute(),
    icon: App,
    label: "Apps",
    alias: [/\/studio\/apps\/\d+/],
    hideForSuppliers: true
  }
];
