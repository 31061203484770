import React from "react";
import { css } from "emotion";
import { Stack, Text } from "@certa/blocks/thanos";
import { User, Vendor } from "@certa/icons";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { useSelector } from "react-redux";
import { userImpersonation } from "main/src/js/services/impersonateUser";
import { capitalize } from "lodash-es";
import { useIntl } from "react-intl";

const ImpersonationBanner = () => {
  const intl = useIntl();

  const impersonatee = useSelector(
    (state: ReduxState) => state?.authentication?.user
  );

  const isSupplierUser = impersonatee?.kind === 2;

  const userName =
    impersonatee?.first_name && impersonatee?.last_name
      ? `${impersonatee.first_name} ${impersonatee.last_name}`
      : impersonatee.username || impersonatee.email;

  return (
    <Stack
      className={css`
        width: 100%;
        height: 32px;
        padding: 0 var(--s6);
        background-color: var(--neutral-100);
      `}
      gap="s1"
      align="center"
      justify="center"
      direction="horizontal"
      gutter="s1"
    >
      <Text
        className={css`
          width: auto !important;
          margin-right: var(--s2) !important;
        `}
        variant="p1-medium"
        color="neutral-0"
      >
        {intl.formatMessage({
          id: "impersonation.viewingAs",
          defaultMessage: "Viewing as"
        })}
      </Text>
      <Stack
        className={css`
          background-color: var(--neutral-20);
          line-height: 1;
          padding: var(--s1) var(--s2);
          border-radius: var(--s1);
          font-size: var(--p3);
          font-family: inherit;
          border-width: 0px;
          margin: var(--s1) var(--s1) var(--s1) 0 !important;
          height: 23px;
        `}
        gap="s1"
        align="center"
        justify="center"
      >
        <User
          className={css`
            width: var(--s3);
            height: var(--s3);
          `}
          color="neutral-100"
        />
        <Text variant="p1-bold" color="neutral-100">
          {userName}
        </Text>
      </Stack>

      <Stack
        className={css`
          background-color: var(--brand);
          line-height: 1;
          padding: var(--s1) var(--s2);
          border-radius: var(--s1);
          font-size: var(--p3);
          font-family: inherit;
          border-width: 0px;
          margin: var(--s1) var(--s2) var(--s1) 0px !important;
          height: 23px;
        `}
        gap="s1"
        align="center"
        justify="center"
      >
        <Vendor
          className={css`
            width: var(--s3);
            height: var(--s3);
          `}
          color="neutral-0"
        />
        <Text variant="p1-bold" color="neutral-0">
          {isSupplierUser
            ? "External"
            : capitalize(impersonatee.tenant) || "Certa"}
        </Text>
      </Stack>

      <Text
        className={css`
          width: auto !important;
          right: var(--s4);
          position: absolute;
          cursor: pointer;
          padding-bottom: 1px;
          text-align: center;
          border-bottom: 1px dashed transparent;

          &:hover {
            border-bottom: 1px dashed var(--brand);
          }
        `}
        variant="p1-medium"
        color="neutral-0"
        onClick={userImpersonation.stopImpersonation}
      >
        {intl.formatMessage({
          id: "impersonation.stopImpersonationText",
          defaultMessage: "Switch back to your user"
        })}
      </Text>
    </Stack>
  );
};

export default ImpersonationBanner;
