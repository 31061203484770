import { chartConfigModelCreatorED } from "./emailDeliveryReportModelCreator";
import { chartConfigModelCreatorIC } from "./integrationCallReportModelCreator";
import { chartConfigModelCreatorORM } from "./ormReportModelCreator";
import type { ReportTableType } from "../constants";
import type { ChartConfig, ParsedChartFieldAnswer } from "../types";

export const chartConfigModelCreator = (
  reportType: ReportTableType,
  chartConfigBE: any
): Omit<ParsedChartFieldAnswer, "chartType"> => {
  if (!chartConfigBE) {
    return { ...data };
  }
  try {
    const isORMReport = reportType === "ORM Workflow Report";
    const isICReport = reportType === "Integration Call Report";
    const isEDReport = reportType === "Email Delivery Report";
    const isWorkflowReport = reportType === "Workflow Report";

    return {
      chartConfig: isWorkflowReport
        ? (chartConfigBE as ChartConfig)
        : undefined,
      chartConfigORM: isORMReport
        ? chartConfigModelCreatorORM(chartConfigBE)
        : undefined,
      chartConfigIC: isICReport
        ? chartConfigModelCreatorIC(chartConfigBE)
        : undefined,
      chartConfigED: isEDReport
        ? chartConfigModelCreatorED(chartConfigBE)
        : undefined
    };
  } catch (e) {
    return { ...data };
  }
};

const data: Omit<ParsedChartFieldAnswer, "chartType"> = {
  chartConfig: undefined,
  chartConfigIC: undefined,
  chartConfigED: undefined,
  chartConfigORM: undefined
};
