import React from "react";
import { gmtDate, fromNowWithFloorRounding } from "../utils/time";
import { css, cx } from "emotion";
import { Tooltip } from "@certa/catalyst";

export const Timestamp: React.FC<{ timestamp: string; fontSize?: string }> = ({
  timestamp,
  fontSize
}) => {
  return (
    <span
      className={cx([
        "text-light",
        css`
          font-size: ${fontSize ? fontSize : "12px"};
        `
      ])}
    >
      <Tooltip content={gmtDate(timestamp)} showArrow>
        <time>{fromNowWithFloorRounding(timestamp)}</time>
      </Tooltip>
    </span>
  );
};
