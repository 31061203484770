/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import type { FocusEvent } from "react";
import { forwardRef, useRef } from "react";

type TrueFocusBlurProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * TrueBlur is a helper component to only invoke onBlur even when the component
 * truly loses focus. Which means if focus jumps from any child component, to any
 * other child component, then it will NOT invoke the onBlur event. However, if focus
 * jumps from this element, or any of its child elements, to an element outside of
 * this element's tree, then only the onBlur even will take place
 */
export const TrueFocusBlur = forwardRef<HTMLDivElement, TrueFocusBlurProps>(
  (props, ref) => {
    const { children, ...htmlProps } = props;
    /**
     * We keep a timeout reference so that we can track when the actual blur
     * occurs. If one component loses focus and then other one gain, while both
     * the components are part of this parent component, then technically this
     * component is still very much in focus. So we use the timer to wait for
     * a "tick", and if the focused element is child of this component itself, we
     * clear the timer to prevent this component from being marked as "blurred"
     */
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleOnFocus = (e: FocusEvent<HTMLDivElement>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      } else {
        // to be only triggered if the focus has jumped to this component, from
        // outside this component's DOM tree
        htmlProps.onFocus?.(e);
      }
    };

    const handleOnBlur = (e: FocusEvent<HTMLDivElement>) => {
      timeoutRef.current = setTimeout(() => {
        // to be only triggered if the focus has jumped from this component, to
        // outside this component's DOM tree
        htmlProps.onBlur?.(e);
        timeoutRef.current = null; // making it null, so we can detect true-focus
      }, 0);
    };

    return jsx("div", {
      ...htmlProps,
      children,
      ref,
      onFocus: handleOnFocus,
      onBlur: handleOnBlur
    });
  }
);
