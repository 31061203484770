import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Timeline } from "antd";
import { css } from "emotion";
import type { ActivityLogModelType } from "../types";
import { getIconAndColor } from "../utils";
import { ActivityLogItemChange } from "./ActivityLogItemChange";
import { ActivityLogEmail } from "../ActivityLogEmail";
import { ActivityLogSimple } from "../ActivityLogSimple";

type ActivityLogItemProps = {
  activityLog: ActivityLogModelType;
  activityLogsByBot?: Array<ActivityLogModelType>;
};

export const ActivityLogItem: FC<ActivityLogItemProps> = props => {
  const { activityLog, activityLogsByBot } = props;
  const {
    actionCode,
    actionType,
    objectType,
    details,
    objectName,
    timestamp,
    workflowName,
    workflowId,
    actorEmail,
    workflowKind,
    changes
  } = activityLog;
  const [icon, color] = useMemo(
    () => getIconAndColor({ actionType, actionCode, objectType }),
    [actionType, actionCode, objectType]
  );
  const [isOpen, updateIsOpen] = useState(false);
  const toggleIsOpen = useCallback(
    () => updateIsOpen(isOpen => !isOpen),
    [updateIsOpen]
  );
  const isChangeListVisible = useMemo(
    () => objectType !== "email" && changes?.length,
    [objectType, changes]
  );
  return (
    <Timeline.Item
      className={css`
        .ant-timeline-item-content {
          width: 85%;
        }
      `}
      key={activityLog.id}
      dot={<i className="material-icons t-14">{icon}</i>}
      color={color}
    >
      {objectType === "email" ? (
        <ActivityLogEmail
          item={{
            details,
            object_name: objectName,
            action_type: actionType,
            object_type: objectType,
            action_code: actionCode,
            workflow_name: workflowName,
            workflow_id: workflowId,
            timestamp
          }}
        />
      ) : (
        <ActivityLogSimple
          item={{
            details,
            actor_email: actorEmail,
            object_name: objectName,
            action_type: actionType,
            object_type: objectType,
            workflow_name: workflowName,
            workflow_id: workflowId,
            workflow_kind_name: workflowKind,
            timestamp
          }}
        />
      )}
      {isOpen ? (
        <>
          {isChangeListVisible ? (
            <Timeline
              className={css`
                padding-top: 20px !important;
                margin-bottom: -20px !important;
                ${activityLogsByBot?.length
                  ? "margin-left: 30px !important"
                  : ""}
              `}
            >
              {activityLog.changes?.map(({ event }) => (
                <ActivityLogItemChange event={event} key={event} />
              ))}
            </Timeline>
          ) : null}
          {activityLogsByBot?.length ? (
            <Timeline
              className={css`
                padding-top: 20px !important;
                margin-bottom: -20px !important;
              `}
            >
              {activityLogsByBot?.map(activityLogByBot => (
                <ActivityLogItem
                  activityLog={activityLogByBot}
                  key={activityLogByBot.id}
                />
              ))}
            </Timeline>
          ) : null}
        </>
      ) : null}
      {activityLogsByBot?.length || isChangeListVisible ? (
        <Button
          onClick={toggleIsOpen}
          size={"small"}
          style={{ position: "absolute", top: 0, right: -30 }}
          icon={<LegacyIcon type={isOpen ? "up" : "down"} />}
          className={"activity-log-collapse-btn"}
        />
      ) : null}
    </Timeline.Item>
  );
};
