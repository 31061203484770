import { useCallback, useRef } from "react";
import type { InterSectionObserverHookType } from "../types/utils.types";

const useIntersectionObserver = ({
  loadMore
}: InterSectionObserverHookType) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  const ref = useCallback(
    (node: HTMLElement | null) => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      if (!node) return;
      observerRef.current = new IntersectionObserver(entries => {
        const [firstEntry] = entries;
        if (firstEntry && firstEntry.isIntersecting && loadMore) {
          loadMore();
        }
      });
      observerRef.current.observe(node);
    },
    [loadMore]
  );
  return { ref };
};

export { useIntersectionObserver };
