import { useIntl } from "react-intl";
import { useCallback } from "react";
import { useOverlayConfirm } from "@certa/blocks";
import { showMessage } from "../components/showMessage";
import { useArchiveWorkflowMutation } from "@certa/queries";
// SYNC: See of we need a message component in @certa/blocks
import { message } from "antd";
import { useCheckLevelPermission } from "../Chokidar";
import { WorkflowLevelPermissionType } from "@certa/queries/src/types/permissions.types";
import { showAlertDialog, showToast, ToastTypes } from "@certa/catalyst";
import type { UseQueryExtraConfigType } from "../types";

/**
 * @deprecated This hook is no longer used and will be removed in future versions.
 *
 * @example
 * // Preferred usage (for accessibility)
 * useProcessArchiveNew(processId, onSuccess)
 */
const useProcessArchive = (
  processId: number,
  onSuccess?: Function,
  config?: UseQueryExtraConfigType
) => {
  const intl = useIntl();

  const { confirm } = useOverlayConfirm();

  const { mutate: archiveWorkflow } = useArchiveWorkflowMutation();
  const { status: canArchiveProcessStatus, refetch } = useCheckLevelPermission({
    processId,
    checkFor: WorkflowLevelPermissionType.CAN_ARCHIVE_WORKFLOWS,
    config
  });

  const handleArchive = useCallback(() => {
    confirm({
      title: intl.formatMessage({
        id: "commonTextInstances.archiveConfirmText",
        defaultMessage: "Are you sure you want to archive this workflow?"
      }),
      content: intl.formatMessage({
        id: "commonTextInstances.archiveConfirmContent",
        defaultMessage:
          "Note : Archived workflows will no longer be visible in the system"
      }),
      onOk: () => {
        showMessage(
          "loading",
          intl.formatMessage({
            id: "stepBodyFormInstances.archivingWorkflow",
            defaultMessage: "Archiving workflow"
          }),
          { duration: 0 }
        );
        archiveWorkflow(processId, {
          onSettled: () => message.destroy(),
          onSuccess: () => onSuccess?.(),
          onError: () =>
            showToast({
              type: ToastTypes.ERROR,
              title: intl.formatMessage({
                id: "readonlyTable.archiveErrorMessage",
                defaultMessage: "Failed discarding changes"
              }),
              description: intl.formatMessage(
                {
                  id: "readonlyTable.archiveErrorDescription",
                  defaultMessage: "Please contact {email} for resolution"
                },
                { email: "care@getcerta.com" }
              )
            })
        });
      },
      onCancel: () => {},
      okText: intl.formatMessage({
        id: "commonTextInstances.yes",
        defaultMessage: "Yes"
      }),
      cancelText: intl.formatMessage({
        id: "commonTextInstances.no",
        defaultMessage: "No"
      }),
      zIndex: 1200
    });
  }, [confirm, intl, archiveWorkflow, processId, onSuccess]);

  return {
    canArchiveProcess: canArchiveProcessStatus === "granted",
    isLoading: canArchiveProcessStatus === "loading",
    handleArchive,
    refetch
  };
};

const useProcessArchiveNew = (processId: number, onSuccess?: Function) => {
  const intl = useIntl();

  const { mutate: archiveWorkflow } = useArchiveWorkflowMutation();
  const { status: canArchiveProcessStatus } = useCheckLevelPermission({
    processId,
    checkFor: WorkflowLevelPermissionType.CAN_ARCHIVE_WORKFLOWS
  });

  const handleArchive = useCallback(() => {
    showAlertDialog({
      title: intl.formatMessage({
        id: "commonTextInstances.archiveConfirmText",
        defaultMessage: "Are you sure you want to archive this workflow?"
      }),
      children: intl.formatMessage({
        id: "commonTextInstances.archiveConfirmContent",
        defaultMessage:
          "Note : Archived workflows will no longer be visible in the system"
      }),
      onPrimaryAction: () => {
        showMessage(
          "loading",
          intl.formatMessage({
            id: "stepBodyFormInstances.archivingWorkflow",
            defaultMessage: "Archiving workflow"
          }),
          { duration: 0 }
        );
        archiveWorkflow(processId, {
          onSettled: () => message.destroy(),
          onSuccess: () => onSuccess?.(),
          onError: () =>
            showToast({
              type: ToastTypes.ERROR,
              title: intl.formatMessage({
                id: "readonlyTable.archiveErrorMessage",
                defaultMessage: "Failed discarding changes"
              }),
              description: intl.formatMessage(
                {
                  id: "readonlyTable.archiveErrorDescription",
                  defaultMessage: "Please contact {email} for resolution"
                },
                { email: "care@getcerta.com" }
              )
            })
        });
      },
      primaryActionText: intl.formatMessage({
        id: "commonTextInstances.yes",
        defaultMessage: "Yes"
      }),
      secondaryActionText: intl.formatMessage({
        id: "commonTextInstances.no",
        defaultMessage: "No"
      })
    });
  }, [intl, archiveWorkflow, processId, onSuccess]);

  return {
    canArchiveProcess: canArchiveProcessStatus === "granted",
    handleArchive
  };
};

export { useProcessArchive, useProcessArchiveNew };
