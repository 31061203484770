import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import type { ReduxState } from "main/src/modules/common/interfaces";
import { useGodaamSyncState } from "./useGodaamSyncState";
import { isEqual } from "lodash-es";
import { updateRecentlyViewedState } from "../utils/recentlyViewedUtils";

/**
 * A hook to sync / update the recently viewed routes or path to the local storage (`recentlyViewed`) based on the user id.
 * The recent routes or path is stored based on the key that is sent as arguments to this hook.
 * This stores maximum of `MAX_ITEMS_IN_RECENTLY_VIEWED` recently viewed routes
 *
 * @param matchedPath - The route / path / :id
 * @param initialRecentlyViewedList - An array
 * @returns updated `recentlyViewedList` and a function `onUpdateRecentlyViewed` to manually update the recently viewed list
 */
export const useSyncRecentlyViewedGodaam = <T extends string | number>(
  matchedPath?: T,
  initialRecentlyViewedList?: T[]
) => {
  const authentication = useSelector(
    (state: ReduxState) => state.authentication
  );

  const [recentlyViewed, setRecentlyViewed] = useGodaamSyncState<
    Record<string, T[]> | undefined
  >("recentlyViewed");

  useEffect(() => {
    if (authentication.user.id && initialRecentlyViewedList && matchedPath) {
      setRecentlyViewed(prevState => {
        const hasCurrentUserRecentWorflowsInGodaam = Boolean(
          prevState?.[authentication.user.id]?.length &&
            !isEqual(
              prevState[authentication.user.id],
              initialRecentlyViewedList
            )
        );

        // If the user has recently viewed workflows in godaam
        // or if they have navigated to a new process
        // then godaam will have the updated value
        if (hasCurrentUserRecentWorflowsInGodaam) {
          return prevState;
        }
        return prevState
          ? {
              ...prevState,
              [authentication.user.id]: initialRecentlyViewedList
            }
          : { [authentication.user.id]: initialRecentlyViewedList };
      });
    }
  }, [
    initialRecentlyViewedList,
    authentication.user.id,
    setRecentlyViewed,
    matchedPath
  ]);

  const onUpdateRecentlyViewed = useCallback(
    (matchedPath: T) => {
      if (!matchedPath || !authentication.user.id) return;

      setRecentlyViewed(prevState => {
        const updatedState = updateRecentlyViewedState<T>(
          prevState,
          authentication.user.id,
          matchedPath
        );

        return updatedState;
      });
    },
    [authentication.user.id, setRecentlyViewed]
  );

  useEffect(() => {
    if (matchedPath) {
      onUpdateRecentlyViewed(matchedPath);
    }
  }, [matchedPath, onUpdateRecentlyViewed]);

  const recentlyViewedWorkflows =
    recentlyViewed?.[authentication.user.id] || [];

  return { recentlyViewedWorkflows, onUpdateRecentlyViewed };
};
