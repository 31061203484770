import { notification } from "@certa/blocks/thanos";
import type { UseQueryExtraConfigType } from "@certa/common";
import { useGetProcessDetails } from "@certa/queries";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

export const useProcessDetails = (
  processId: number | null | undefined,
  config: UseQueryExtraConfigType = {}
) => {
  const intl = useIntl();
  const history = useHistory();

  return useGetProcessDetails(processId, {
    ...config,
    onError: (error: unknown) => {
      if (error instanceof Response && error.status === 404) {
        notification.error({
          message: intl.formatMessage(
            {
              id: "errorMessageInstances.ws002",
              defaultMessage: `Workflow with ID ${processId} is unavailable`
            },
            {
              workflowId: processId
            }
          ),
          description: intl.formatMessage(
            {
              id: "errorMessageInstances.errorCode",
              defaultMessage: "Error Code: WS002"
            },
            {
              errorCode: "WS002"
            }
          ),
          placement: "bottomLeft"
        });

        // The above notification will render inside the design layout container
        // which will be unmounted when the user go back one route
        // hence adding a setTimeout of 2sec to
        // let the user see the notification
        setTimeout(() => {
          history.goBack();
        }, 2000);
      }
      config.onError?.(error);
    }
  });
};
