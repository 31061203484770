import { css } from "emotion";

export const fieldNameStyles = css({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  marginLeft: "auto",
  gap: "var(--space-4)"
});

export const ellipsisContainerStyles = css({
  fontWeight: 600,
  overflow: "hidden",
  whiteSpace: "nowrap",
  maxWidth: "20rem"
});

export const threadContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  maxHeight: "100%",
  overflowY: "auto"
});
