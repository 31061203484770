import React from "react";
import { HeaderWrapper } from "./MenuHeader.styles";
import { ButtonFilter } from "../ButtonFilter";
import type { ButtonFilterType } from "../ButtonFilter";
import type { MenuHeaderProps as GlobalSelectMenuHeaderProps } from "../GlobalSelect/GlobalSelect.types";

type MenuHeaderProps = GlobalSelectMenuHeaderProps & {
  categories: ButtonFilterType[];
  numberOfResults: number;
  handleCategoryClick: (value: string) => void;
  selectedCategories: string[];
  loading: boolean;
};

const MenuHeader = ({
  categories,
  handleCategoryClick,
  selectedCategories,
  currentFocusTabIndex
}: MenuHeaderProps) => {
  return (
    <div className={HeaderWrapper}>
      <ButtonFilter
        options={categories}
        handleFilterClick={handleCategoryClick}
        selectedFilters={selectedCategories}
        currentFocusTabIndex={currentFocusTabIndex}
      />
    </div>
  );
};

export { MenuHeader };
