import { css } from "emotion";

export const RangePickerClass = css`
  border-radius: var(--normal-border-radius) !important;
  height: 36px !important;
  border: 1px solid #c1cbd3 !important;

  input {
    &::placeholder {
      font-size: var(--p);
      font-weight: 400;
      font-size: 15px;
    }
  }
`;
