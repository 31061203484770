import { useState, useEffect, forwardRef } from "react";
import type { LegacyRef } from "react";
import {
  Tooltip,
  ResponsiveContainer,
  PieChart as RePieChart,
  Pie,
  Cell
} from "recharts";
import type { PieLabelRenderProps } from "recharts";
import {
  tooltipFormatter,
  CustomTooltip,
  percentageLabel,
  CustomLegendWrapper,
  useGetLegendDataForPieChart,
  NoDataInReports
} from "@certa/chartreport";
import type {
  ChartComponentProps,
  ChartClickEvent,
  ShowValue
} from "@certa/common";
import { RADIAN } from "@certa/common";
import { usePieChart } from "./usePieChart";
import { css } from "emotion";

const lineStyle = css({
  cursor: "pointer",
  "& .recharts-pie:not(:hover) .recharts-pie-labels g": {
    display: "none"
  }
});

const activeXLabel = css({
  "& .recharts-reference-line:not(:hover) .recharts-pie-labels text": {
    visibility: "hidden"
  }
});

export const PieChart = forwardRef(
  (props: ChartComponentProps, ref: LegacyRef<typeof RePieChart.prototype>) => {
    const {
      chartData,
      height = 500,
      onClick,
      xActiveIdentifier,
      otherConfig,
      isCycleTimeReport,
      sorting,
      handleSorterChange
    } = props;
    const {
      showLegend,
      shouldShowValueLabels,
      showValueOn,
      shouldShowPercentagesInValueLabels,
      xAxis,
      percentageThreshold
    } = otherConfig;
    const { pies, xAxisLabelFormatter } = usePieChart({
      chartData,
      shouldShowPercentagesInValueLabels,
      xAxis
    });

    const { legendData, piesColorMapping } = useGetLegendDataForPieChart({
      pies,
      xAxisLabelFormatter,
      colors: otherConfig?.colors,
      isCycleTimeReport
    });
    const radius = generateRadius(200, pies.length);
    const handlePieClick = (data: { name: string; yLabel: string }) => {
      setShowValueFor(data.yLabel);
      if (onClick) {
        const payload = chartData.data.find(
          entry => entry[chartData.xAxisKey] === data.name
        );
        if (payload) {
          const _data: ChartClickEvent = {
            activeLabel: data.name,
            activePayload: [
              {
                payload
              }
            ],
            activeXIdentifier: data.name
          };
          onClick(_data);
        }
      }
    };

    const maxOuterRadius = radius.outerRadius[pies.length - 1];

    const [showValueFor, setShowValueFor] = useState<string | null>(null);

    useEffect(() => {
      setShowValueFor(null);
    }, [showValueOn]);

    const labelFN = renderCustomizedLabel(
      maxOuterRadius,
      shouldShowValueLabels,
      showValueOn,
      showValueFor,
      percentageThreshold
    );
    const labelLineFN = renderCustomizedLabelLine(
      maxOuterRadius,
      shouldShowValueLabels,
      showValueOn,
      showValueFor,
      percentageThreshold
    );

    return (
      <CustomLegendWrapper
        data={legendData}
        sorting={sorting}
        handleSorterChange={handleSorterChange}
        showLegend={showLegend}
        chartType="PIE_CHART"
      >
        {!pies?.length || pies.every(pie => !pie?.length) ? (
          <NoDataInReports />
        ) : (
          <ResponsiveContainer
            width="100%"
            height={height}
            className={showValueOn === "hover" ? lineStyle : activeXLabel}
          >
            <RePieChart ref={ref}>
              <Tooltip
                formatter={tooltipFormatter(
                  "PIE_CHART",
                  chartData.probableDataType
                )}
                content={
                  <CustomTooltip
                    chartType={"PIE_CHART"}
                    xAxisLabelFormatter={xAxisLabelFormatter}
                  />
                }
                cursor={{
                  stroke: "var(--teal)",
                  strokeDasharray: "4"
                }}
              />
              {pies.map((pie, index) => {
                return (
                  <Pie
                    // Animation is turned off to enable labels in Pie chart. Another way would be to apply key=Math.random.
                    // The bug is in the recharts library animation. Having multiple pie charts causes multiple re-renders and animation gets stuck
                    // Because of this the inner handler to call labels never gets called.
                    isAnimationActive={false}
                    dataKey={pie[0]?.yLabel}
                    data={pie}
                    key={index}
                    label={labelFN}
                    labelLine={labelLineFN}
                    onClick={handlePieClick}
                    outerRadius={radius.outerRadius[index]}
                    innerRadius={radius.innerRadius[index]}
                  >
                    {pie.map((entry, pieIndex) => {
                      return (
                        <Cell
                          key={entry.name}
                          cursor={onClick ? "pointer" : ""}
                          fill={
                            entry.name === xActiveIdentifier
                              ? "var(--brand)"
                              : piesColorMapping?.[index]?.[pieIndex]
                                  ?.background
                          }
                          fillOpacity={
                            entry.name === xActiveIdentifier
                              ? 1
                              : piesColorMapping?.[index]?.[pieIndex]
                                  ?.fillOpacity
                          }
                        />
                      );
                    })}
                  </Pie>
                );
              })}
            </RePieChart>
          </ResponsiveContainer>
        )}
      </CustomLegendWrapper>
    );
  }
);

function generateRadius(containerRadius: number, count: number) {
  const distanceRatio = 0.5;
  const widthRatio = 1;
  const firstInnerRadiusRatio = 3;
  const radiusRatio = Math.floor(
    containerRadius /
      (firstInnerRadiusRatio + (count - 1) * (distanceRatio + widthRatio))
  );
  const outerRadius = Array(count)
    .fill(1)
    .map((_, index) => {
      const isFirstItem = index === 0;
      if (isFirstItem) {
        return firstInnerRadiusRatio * radiusRatio;
      } else {
        const radiusTakeByFirst = firstInnerRadiusRatio * radiusRatio;
        const radiusTakenByOthers =
          (distanceRatio + widthRatio) * radiusRatio * index; // Including itself
        return radiusTakeByFirst + radiusTakenByOthers;
      }
    });
  const innerRadius = Array(count)
    .fill(1)
    .map((_, index) => {
      const isFirstItem = index === 0;
      if (isFirstItem) {
        return 0;
      } else {
        const radiusTakeByFirst = 3 * radiusRatio;
        const radiusTakeByOtherExceptItself =
          (distanceRatio + widthRatio) * radiusRatio * (index - 1);
        const radiusTakenByDistance = distanceRatio * radiusRatio;
        return (
          radiusTakeByFirst +
          radiusTakeByOtherExceptItself +
          radiusTakenByDistance
        );
      }
    });
  return {
    innerRadius,
    outerRadius,
    radiusRatio
  };
}

const renderCustomizedLabelLine = (
  maxOuterRadius: number,
  shouldShowValueLabels?: boolean,
  showValueOn?: ShowValue,
  showValueFor?: string | null,
  percentageThreshold?: number
) => {
  if (shouldShowValueLabels)
    return (props: any) => {
      const { cx, cy, midAngle, points, stroke, value, yLabel, total } = props;
      if (showValueOn === "click" && showValueFor !== yLabel) {
        return <></>;
      }
      const radiusEnd = maxOuterRadius + 20;
      const xEnd = Number(cx) + radiusEnd * Math.cos(-midAngle * RADIAN);
      const yEnd = Number(cy) + radiusEnd * Math.sin(-midAngle * RADIAN);

      if (percentageThreshold !== undefined) {
        const percentage = (((value as number) / total) * 100).toPrecision(4);
        if (parseFloat(percentage) <= percentageThreshold) {
          return <></>;
        }
      }

      return (
        <path
          stroke={stroke}
          fill="none"
          cx={cx}
          cy={cy}
          points="[object Object],[object Object]"
          type="linear"
          className="recharts-curve recharts-pie-label-line"
          d={`M${points[0].x},${points[0].y}L${xEnd},${yEnd}`}
        ></path>
      );
    };
  else return false;
};

const renderCustomizedLabel = (
  maxOuterRadius: number,
  shouldShowValueLabels?: boolean,
  showValueOn?: ShowValue,
  showValueFor?: string | null,
  percentageThreshold?: number
) => {
  if (shouldShowValueLabels)
    return (props: PieLabelRenderProps) => {
      const { cx = 0, cy = 0, midAngle, yLabel, formattedValue, fill } = props;
      if (showValueOn === "click" && showValueFor !== yLabel) return <></>;
      const { value, total, shouldShowPercentagesInValueLabels } = props;
      const radius = maxOuterRadius + 30;
      const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
      const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

      if (percentageThreshold !== undefined) {
        const percentage = (((value as number) / total) * 100).toPrecision(4);
        if (parseFloat(percentage) <= percentageThreshold) return <></>;
      }

      return (
        <text
          x={x}
          y={y}
          fill={fill}
          textAnchor={x > Number(cx) ? "start" : "end"}
          dominantBaseline="central"
        >
          {formattedValue}{" "}
          {percentageLabel(
            shouldShowPercentagesInValueLabels,
            value,
            total,
            true
          )}
        </text>
      );
    };
  else return false;
};

export type PieData = {
  [x: string]: any;
  name: string | number | null;
  xLabel: string | number | null;
  yLabel: string;
  total: number;
  formattedValue: any;
};
