export type ActionType<T = unknown> = (
  | "viewed"
  | "edited"
  | "submitted"
  | "undo"
  | "queued"
  | "delivered"
  | "performed"
  | "bounced"
  | "deferred"
  | "deleted"
  | "updated"
  | "created"
) &
  T;

export enum TimelineEdges {
  TAIL = "tail",
  NODE = "node",
  TAILTOP = "tail-top",
  TAILBOTTOM = "tail-bottom"
}
