import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import type { ReduxState } from "main/src/modules/common/interfaces";

export const useIsNewNavigationEnabled = (): boolean => {
  const isNewNavigationEnabled = useSelector((state: ReduxState) =>
    lodashGet(
      state,
      "config.custom_ui_labels.feature_toggles.NEW_NAVIGATION_STANDARD",
      false
    )
  );

  return isNewNavigationEnabled;
};
