import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import type { User } from "../types/user.types";
import { get as lodashGet } from "lodash-es";

export const useCurrentUser = (): User => {
  return useSelector((state: ReduxState) => {
    return lodashGet(state, `authentication.user`, null);
  });
};
