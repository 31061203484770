const COPY_TEXT = {
  DEFAULT_MESSAGES: {
    UNABLE_TO_SUBSCRIBE: "Unable to change subscription.",
    UNABLE_TO_UNSUBSCRIBE: "Unable to unsubscribe from the thread."
  },
  SWITCH_ON_TEXT:
    "You will now receive email notifications for each comment on this thread",
  SWITCH_OFF_TEXT:
    "You will not receive any email notifications for this thread unless tagged",
  SUBSCRIBE_TEXT: "Subscribe to thread",
  DOWNLOAD_FILE: {
    ERROR: {
      DEFAULT: {
        TITLE: "An unknown error occurred!",
        DESCRIPTION: "Please reach out to your administrator or support team"
      }
    }
  }
} as const;
export default COPY_TEXT;
