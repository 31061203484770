import { css } from "emotion";

export const catalystDialogStyles = css({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  height: "fit-content",
  backgroundColor: "var(--colors-neutral-100)",
  margin: "auto",
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  boxShadow: "0rem 0.5rem 1.5rem rgba(0, 22, 78, 0.15)",
  borderRadius: "0.5rem",
  zIndex: 9999,
  isolation: "isolate",
  overflow: "hidden",
  "*": {
    fontFamily: "var(--catalyst-font-family)"
  }
});

export const catalystDialogContentStyles = css({
  overflow: "auto",
  padding: 0
});
