import type { FC } from "react";
import React from "react";
import { Menu as AntMenu } from "antd";
import type { MenuItemProps } from "antd/lib/menu/MenuItem";
import type { TooltipProps } from "antd/lib/tooltip";
import type { IconProps } from "@certa/icons";
import { Text } from "../Typography/Text";
import { Stack } from "../Stack";
import { Tooltip } from "../Tooltip";
import { getVariantStyles } from "../Typography/utils";
import styled from "@emotion/styled";

type TooltipBodyWrapperType = TooltipProps & { isBody?: boolean };

export const CustomMenuItem: FC<
  MenuItemProps & {
    title: React.ReactNode | string;
    icon?: React.ComponentType<IconProps>;
    danger?: boolean;
    isBodyText?: boolean;
    toolTipProps?: TooltipBodyWrapperType;
    textProps?: {
      ellipsis?: number;
    };
    helperText?: string;
  }
> = props => {
  const {
    title,
    icon: Icon,
    isBodyText,
    toolTipProps,
    textProps,
    helperText,
    ...itemProps
  } = props;

  const renderMenuItem = () => {
    return (
      <>
        <Stack gap="s2" align="center" itemWidth="100%">
          {Icon ? (
            <Stack>
              <Icon />
            </Stack>
          ) : null}
          {isBodyText && typeof title === "string" ? (
            <Text
              as="span"
              dangerouslySetInnerHTML={{ __html: title }}
              align="left"
              {...textProps}
            />
          ) : (
            <Text as="div" align="left" {...textProps}>
              {title}
            </Text>
          )}
        </Stack>
        {helperText && (
          <Text
            color="neutral-70"
            className="menu-item-helper-text"
            align="left"
          >
            {helperText}
          </Text>
        )}
      </>
    );
  };
  return (
    <AntMenu.Item {...itemProps}>
      {toolTipProps ? (
        <Tooltip {...toolTipProps}>{renderMenuItem()}</Tooltip>
      ) : (
        renderMenuItem()
      )}
    </AntMenu.Item>
  );
};

export const MenuItem = styled(CustomMenuItem)`
  &.ant-menu-item,
  &.ant-dropdown-menu-item {
    color: ${props =>
      props.danger ? "var(--red)" : "var(--neutral-100)"} !important;
    padding: var(--s3) var(--s2) !important;
    margin: 0 !important;
    height: auto !important;
    border-radius: var(--s1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    ${getVariantStyles("p1-regular")};
    &.ant-menu-item-disabled,
    &.ant-dropdown-menu-item-disabled {
      color: var(--neutral-70) !important;
    }
    &.ant-menu-item-selected,
    &.ant-dropdown-menu-item-selected {
      position: relative;
      background-color: var(--teal-40);
      padding-right: var(--s6) !important;
      &:hover {
        background-color: var(--teal-40) !important;
      }
      &:before {
        content: "";
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.336 0L4.3282 6.57253L1.44273 4.13014L0 6.17512L3.70688 9.30876L4.52829 10L12 1.82599L10.336 0Z' fill='%231F5EFF'/%3E%3C/svg%3E");
        width: 12px;
        height: 10px;
        position: absolute;
        right: 10px;
        top: calc(50% - 5px);
      }
    }
    &:not(.ant-menu-item-disabled, .ant-dropdown-menu-item-disabled):hover {
      background-color: ${props =>
        props.danger ? "var(--red-40)" : "var(--neutral-10)"};
      color: ${props =>
        props.danger ? "var(--red)" : "var(--brand)"} !important;
    }
    .menu-item-helper-text {
      display: block;
      margin-top: var(--s1);
    }
    &:focus-visible {
      box-shadow:
        0 0 0 1px #fff,
        0 0 0 3px var(--brand-15) !important;
      outline: 0;
    }
    /* TODO: needs to be updated in design system container */
    a {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      transition: inherit;
    }
  }
  --label: CustomMenuItem;
`;

export const activeDropdownClassName = "ant-dropdown-menu-item-selected";
export const activeMenuClassName = "ant-menu-item-selected";
