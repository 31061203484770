import { Tag as AntTag } from "antd";
import styled from "@emotion/styled";

export const Tag = styled(AntTag)`
  border-radius: 2px;
  border: none;
  background: #b0b0b0;
  padding: 1px var(--s2);
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 16px;
`;
