import React from "react";
import { format as dateFormat, isValid } from "date-fns";

type TareekhProps = {
  timestamp: string | number;
  format?: string;
  style?: object;
  className?: any;
};

export const Tareekh: React.FC<TareekhProps> = React.memo(props => {
  const { timestamp, format, style, className } = props;
  const date = new Date(timestamp);

  if (!isValid(date)) {
    return null;
  }

  return (
    <span style={style} className={className}>
      {dateFormat(date, format || "yyyy-MMM-dd")}
    </span>
  );
});
