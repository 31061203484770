export enum TaskCategoryTypes {
  MY_GROUPS = "MY_GROUPS",
  ALL_TASKS = "ALL_TASKS",
  ME = "ME"
}

export enum TaskGroupTypes {
  STATUS = "STATUS",
  TASKLANE = "TASKLANE" //Stepgroup
}

export enum TaskSortTypes {
  MANUALLY = "MANUALLY",
  PRIORITY = "PRIORITY",
  DUE_DATE = "DUE_DATE"
}

export enum ViewTypes {
  TABLE = "TABLE",
  COLUMN = "COLUMN"
}

export enum GenericFilterTypes {
  REGION = "regions",
  BUSINESS_UNIT = "businessUnits",
  PROCESS_TYPE = "kindName",
  STATUS = "statuses",
  START_DATE = "startDate",
  END_DATE = "endDate",
  ANSWER = "answer",
  Q = "q"
}

export enum CompletedTaskStatus {
  ALL = "all",
  MY_GROUP = "group",
  MINE = "mine"
}

export type ActionTypes =
  | "SET_TASK_CATEGORY"
  | "SET_PROCESS_TYPE"
  | "SET_GROUP_TYPE"
  | "SET_SORT_TYPE"
  | "SET_TASK_VIEW"
  | "SET_GENERIC_FILTER"
  | "SET_ALL_GENERIC_FILTER"
  | "RESET_GENERIC_FILTERS"
  | "SET_SELECTED_SAVED_FILTERS"
  | "SET_SHOW_SELECTED_FILTER_BAR"
  | "SET_SHOW_COMPLETED_PROCESS"
  | "SET_ORDERS";

export type ProcessType = string | null;

export type GenericFilters = {
  [GenericFilterTypes.REGION]: string[];
  [GenericFilterTypes.BUSINESS_UNIT]: string[];
  [GenericFilterTypes.PROCESS_TYPE]: string[];
  [GenericFilterTypes.STATUS]: string[];
  [GenericFilterTypes.START_DATE]: string;
  [GenericFilterTypes.END_DATE]: string;
  [GenericFilterTypes.ANSWER]: string;
  [GenericFilterTypes.Q]: string;
};

export type TaskFiltersInitialState = {
  taskCategory: TaskCategoryTypes;
  selectedProcessType: ProcessType;
  taskGroupType: TaskGroupTypes;
  taskSortType: TaskSortTypes;
  taskViewType: ViewTypes;
  genericFilters: GenericFilters;
  selectedSavedFilters: string | null;
  showSelectedFilterBar: boolean;
  showCompletedProcess: boolean;
  ordering: string | undefined;
};

export type TasksContextTypes = {
  state: TaskFiltersInitialState;
  setData: (action: { type: ActionTypes; payload: any }) => void;
};

export type SavedFilter = {
  [key: string]: {
    filter: GenericFilters;
  };
};

export type AppliedFilter = {
  placeholder: string;
  label: string;
  value: string;
  type: string;
  renderer?: () => React.ReactElement;
};
