import { BarChart } from "../components/charts/BarChart/BarChart";
import { LineChart } from "../components/charts/LineChart";
import { PieChart } from "../components/charts/pieChart";
import { ScatterChart } from "../components/charts/ScatterChart";
import { SpiderChart } from "../components/charts/SpiderChart";
import { HeatMapChart } from "../components/charts/HeatMapChart";
import type { ChartIdentifiers } from "@certa/common";
import loadable from "@loadable/component";
import { FallbackLoader } from "@certa/common/src/components/FallbackLoader";

const GeoMapChart = loadable(
  () =>
    import("../components/charts/GeoMap/GeoMapChart").then(
      module => module.GeoMapChart
    ),
  {
    fallback: <FallbackLoader />
  }
);

export const getCharComponent = (chartType: ChartIdentifiers) => {
  switch (chartType) {
    case "LINE_CHART":
      return LineChart;
    case "BAR_CHART":
      return BarChart;
    case "PIE_CHART":
      return PieChart;
    case "SCATTER_CHART":
      return ScatterChart;
    case "SPIDER_CHART":
      return SpiderChart;
    case "HEAT_MAP_CHART":
      return HeatMapChart;
    case "GEO_MAP_CHART":
      return GeoMapChart;
    default:
      // defaulting this to "LINE_CHART" to prevent UI crash
      // this happens when PANO sends us an incompatible chartType
      return LineChart;
  }
};
