type UserWithMobileProps = {
  width?: number;
  height?: number;
};

export const UserWithMobile = ({
  width = 80,
  height = 140
}: UserWithMobileProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 80 140"
      fill="none"
    >
      <g clip-path="url(#clip0_73_14183)">
        <path
          d="M61.7835 131.375H20.0029C18.4804 131.375 16.9728 131.075 15.5662 130.493C14.1596 129.91 12.8815 129.056 11.805 127.98C10.7284 126.903 9.87441 125.625 9.29177 124.218C8.70914 122.812 8.40926 121.304 8.40927 119.782V11.5937C8.41275 8.51991 9.63534 5.57304 11.8088 3.39955C13.9823 1.22607 16.9292 0.00347965 20.0029 0H61.7834C64.8571 0.00347012 67.804 1.22606 69.9775 3.39954C72.151 5.57302 73.3736 8.51991 73.3771 11.5937V31.1341H74.0969V45.5315H73.3771V119.782C73.3771 122.856 72.1556 125.805 69.9814 127.98C67.8072 130.154 64.8583 131.375 61.7835 131.375ZM20.0029 0.360042C17.0246 0.363405 14.1693 1.54803 12.0633 3.65401C9.95729 5.76 8.77268 8.61537 8.76931 11.5937V119.782C8.7693 121.257 9.05985 122.718 9.62438 124.081C10.1889 125.443 11.0164 126.682 12.0595 127.725C13.1027 128.768 14.341 129.596 15.704 130.16C17.0669 130.725 18.5277 131.015 20.0029 131.015H61.7834C64.7627 131.015 67.62 129.832 69.7267 127.725C71.8335 125.618 73.017 122.761 73.017 119.782V45.1714H73.7369V31.4942H73.017V11.5937C73.0137 8.61537 71.829 5.76 69.7231 3.65401C67.6171 1.54803 64.7617 0.363405 61.7834 0.360042H20.0029Z"
          fill="#3F3D56"
        />
        <path
          d="M62.2438 128.406H20.2629C17.9553 128.403 15.7429 127.486 14.1112 125.854C12.4794 124.222 11.5615 122.01 11.5589 119.702V11.6733C11.5615 9.36563 12.4794 7.15328 14.1112 5.52154C15.7429 3.88979 17.9553 2.97191 20.2629 2.96924H25.6233L25.5224 3.21712C25.2833 3.80474 25.1923 4.44212 25.2573 5.0732C25.3223 5.70427 25.5414 6.3097 25.8953 6.83623C26.2492 7.36276 26.727 7.79426 27.2868 8.09277C27.8466 8.39128 28.4712 8.54765 29.1056 8.54813H53.0411C53.6755 8.54763 54.3 8.39124 54.8598 8.09272C55.4196 7.79421 55.8975 7.36271 56.2514 6.83618C56.6053 6.30966 56.8243 5.70424 56.8894 5.07317C56.9544 4.44211 56.8633 3.80473 56.6242 3.21712L56.5233 2.96924H62.2438C64.5514 2.97192 66.7637 3.88981 68.3955 5.52155C70.0272 7.1533 70.9451 9.36564 70.9478 11.6733V119.702C70.9451 122.01 70.0272 124.222 68.3955 125.854C66.7637 127.486 64.5514 128.403 62.2438 128.406ZM20.2629 3.32928C18.0507 3.33183 15.9299 4.21175 14.3656 5.776C12.8014 7.34024 11.9215 9.46109 11.9189 11.6733V119.702C11.9215 121.914 12.8014 124.035 14.3657 125.599C15.9299 127.164 18.0507 128.043 20.2629 128.046H62.2438C64.4559 128.043 66.5768 127.164 68.141 125.599C69.7052 124.035 70.5852 121.914 70.5877 119.702V11.6733C70.5852 9.46109 69.7052 7.34026 68.141 5.77601C66.5768 4.21176 64.4559 3.33184 62.2438 3.32928H57.0498C57.2633 3.96544 57.3226 4.64327 57.2226 5.30683C57.1226 5.9704 56.8663 6.60068 56.4747 7.14565C56.0831 7.69062 55.5676 8.13465 54.9706 8.44111C54.3736 8.74757 53.7123 8.90766 53.0412 8.90817H29.1055C28.4344 8.90767 27.7731 8.74759 27.1761 8.44114C26.5791 8.13469 26.0635 7.69066 25.6719 7.14569C25.2803 6.60072 25.024 5.97044 24.924 5.30687C24.824 4.64329 24.8832 3.96545 25.0968 3.32928H20.2629Z"
          fill="#CCCCCC"
        />
        <path
          d="M75.5653 70.3462H4.43472C3.42656 70.345 2.46002 69.944 1.74714 69.2312C1.03426 68.5183 0.633276 67.5518 0.632141 66.5436V43.7281C0.633276 42.72 1.03426 41.7534 1.74714 41.0405C2.46002 40.3277 3.42656 39.9267 4.43472 39.9255H75.5653C76.5735 39.9267 77.54 40.3277 78.2529 41.0405C78.9658 41.7534 79.3668 42.72 79.3679 43.7281V66.5436C79.3668 67.5518 78.9658 68.5183 78.2529 69.2312C77.54 69.944 76.5735 70.345 75.5653 70.3462Z"
          fill="white"
        />
        <path
          d="M75.5653 70.3462H4.43472C3.42656 70.345 2.46002 69.944 1.74714 69.2312C1.03426 68.5183 0.633276 67.5518 0.632141 66.5436V43.7281C0.633276 42.72 1.03426 41.7534 1.74714 41.0405C2.46002 40.3277 3.42656 39.9267 4.43472 39.9255H75.5653C76.5735 39.9267 77.54 40.3277 78.2529 41.0405C78.9658 41.7534 79.3668 42.72 79.3679 43.7281V66.5436C79.3668 67.5518 78.9658 68.5183 78.2529 69.2312C77.54 69.944 76.5735 70.345 75.5653 70.3462ZM4.43472 40.3729C3.54517 40.3739 2.69234 40.7277 2.06334 41.3567C1.43433 41.9857 1.08051 42.8386 1.0795 43.7281V66.5436C1.08051 67.4331 1.43433 68.286 2.06334 68.915C2.69234 69.544 3.54517 69.8978 4.43472 69.8988H75.5653C76.4549 69.8978 77.3077 69.544 77.9367 68.915C78.5657 68.286 78.9195 67.4331 78.9205 66.5436V43.7281C78.9195 42.8386 78.5657 41.9857 77.9367 41.3567C77.3077 40.7277 76.4549 40.3739 75.5653 40.3729H4.43472Z"
          fill="#00164E"
        />
        <path
          d="M49.3277 53.9056H24.4991C24.0245 53.9056 23.5694 53.717 23.2338 53.3814C22.8982 53.0459 22.7097 52.5907 22.7097 52.1161C22.7097 51.6415 22.8982 51.1864 23.2338 50.8508C23.5694 50.5152 24.0245 50.3267 24.4991 50.3267H49.3277C49.8023 50.3267 50.2575 50.5152 50.5931 50.8508C50.9287 51.1864 51.1172 51.6415 51.1172 52.1161C51.1172 52.5907 50.9287 53.0459 50.5931 53.3814C50.2575 53.717 49.8023 53.9056 49.3277 53.9056Z"
          fill="#1F5EFF"
        />
        <path
          d="M72.3669 59.945H24.4991C24.0245 59.945 23.5694 59.7565 23.2338 59.4209C22.8982 59.0853 22.7097 58.6301 22.7097 58.1555C22.7097 57.6809 22.8982 57.2258 23.2338 56.8902C23.5694 56.5546 24.0245 56.3661 24.4991 56.3661H72.3669C72.8415 56.3661 73.2966 56.5546 73.6322 56.8902C73.9678 57.2258 74.1564 57.6809 74.1564 58.1555C74.1564 58.6301 73.9678 59.0853 73.6322 59.4209C73.2966 59.7565 72.8415 59.945 72.3669 59.945Z"
          fill="#1F5EFF"
        />
        <path
          d="M12.5542 61.8463C16.2603 61.8463 19.2646 58.8419 19.2646 55.1358C19.2646 51.4298 16.2603 48.4254 12.5542 48.4254C8.84811 48.4254 5.84375 51.4298 5.84375 55.1358C5.84375 58.8419 8.84811 61.8463 12.5542 61.8463Z"
          fill="#1F5EFF"
        />
        <path
          d="M72.3643 67.2145H66.1012C65.6266 67.2145 65.1715 67.026 64.8359 66.6904C64.5003 66.3548 64.3118 65.8997 64.3118 65.4251C64.3118 64.9505 64.5003 64.4953 64.8359 64.1597C65.1715 63.8242 65.6266 63.6356 66.1012 63.6356H72.3643C72.5993 63.6356 72.832 63.6819 73.0491 63.7718C73.2662 63.8618 73.4634 63.9936 73.6296 64.1597C73.7958 64.3259 73.9276 64.5232 74.0175 64.7403C74.1074 64.9574 74.1537 65.1901 74.1537 65.4251C74.1537 65.6601 74.1074 65.8928 74.0175 66.1099C73.9276 66.327 73.7958 66.5242 73.6296 66.6904C73.4634 66.8566 73.2662 66.9884 73.0491 67.0783C72.832 67.1682 72.5993 67.2145 72.3643 67.2145Z"
          fill="white"
        />
        <path
          d="M72.3643 67.2145H66.1012C65.6266 67.2145 65.1715 67.026 64.8359 66.6904C64.5003 66.3548 64.3118 65.8997 64.3118 65.4251C64.3118 64.9505 64.5003 64.4953 64.8359 64.1597C65.1715 63.8242 65.6266 63.6356 66.1012 63.6356H72.3643C72.5993 63.6356 72.832 63.6819 73.0491 63.7718C73.2662 63.8618 73.4634 63.9936 73.6296 64.1597C73.7958 64.3259 73.9276 64.5232 74.0175 64.7403C74.1074 64.9574 74.1537 65.1901 74.1537 65.4251C74.1537 65.6601 74.1074 65.8928 74.0175 66.1099C73.9276 66.327 73.7958 66.5242 73.6296 66.6904C73.4634 66.8566 73.2662 66.9884 73.0491 67.0783C72.832 67.1682 72.5993 67.2145 72.3643 67.2145Z"
          fill="#CCCCCC"
        />
        <path
          d="M61.5902 34.0759H22.0103C21.4493 34.0753 20.9115 33.8522 20.5148 33.4555C20.1182 33.0588 19.895 32.521 19.8944 31.96V19.2646C19.895 18.7036 20.1182 18.1658 20.5148 17.7691C20.9115 17.3724 21.4493 17.1493 22.0103 17.1487H61.5902C62.1512 17.1493 62.689 17.3724 63.0857 17.7691C63.4824 18.1658 63.7055 18.7036 63.7061 19.2646V31.96C63.7055 32.521 63.4824 33.0588 63.0857 33.4555C62.689 33.8522 62.1512 34.0753 61.5902 34.0759ZM22.0103 17.3976C21.5153 17.3982 21.0408 17.595 20.6908 17.9451C20.3408 18.2951 20.1439 18.7696 20.1433 19.2646V31.96C20.1439 32.455 20.3408 32.9295 20.6908 33.2796C21.0408 33.6296 21.5153 33.8264 22.0103 33.827H61.5902C62.0852 33.8264 62.5597 33.6296 62.9097 33.2796C63.2597 32.9295 63.4566 32.455 63.4572 31.96V19.2646C63.4566 18.7696 63.2597 18.2951 62.9097 17.9451C62.5597 17.595 62.0852 17.3982 61.5902 17.3976H22.0103Z"
          fill="#CCCCCC"
        />
        <path
          d="M46.9905 24.9277H33.1749C32.911 24.9274 32.6581 24.8223 32.4717 24.6356C32.2852 24.4489 32.1805 24.1959 32.1805 23.932C32.1805 23.6682 32.2852 23.4151 32.4717 23.2284C32.6581 23.0417 32.911 22.9366 33.1749 22.9363H46.9905C47.2544 22.9366 47.5073 23.0417 47.6937 23.2284C47.8802 23.4151 47.9849 23.6682 47.9849 23.932C47.9849 24.1959 47.8802 24.4489 47.6937 24.6356C47.5073 24.8223 47.2544 24.9274 46.9905 24.9277Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.8104 28.2883H33.1749C32.9111 28.288 32.6581 28.1829 32.4717 27.9962C32.2852 27.8095 32.1805 27.5564 32.1805 27.2926C32.1805 27.0287 32.2852 26.7757 32.4717 26.589C32.6581 26.4023 32.9111 26.2972 33.1749 26.2969H59.8104C59.9413 26.2967 60.0709 26.3223 60.1919 26.3723C60.3129 26.4222 60.4228 26.4956 60.5154 26.588C60.608 26.6805 60.6814 26.7903 60.7316 26.9112C60.7817 27.0321 60.8075 27.1617 60.8075 27.2926C60.8075 27.4235 60.7817 27.5531 60.7316 27.6739C60.6814 27.7948 60.608 27.9047 60.5154 27.9971C60.4228 28.0896 60.3129 28.1629 60.1919 28.2129C60.0709 28.2629 59.9413 28.2885 59.8104 28.2883Z"
          fill="#E6E6E6"
        />
        <path
          d="M26.5283 29.3463C28.5905 29.3463 30.2622 27.6746 30.2622 25.6124C30.2622 23.5502 28.5905 21.8784 26.5283 21.8784C24.4661 21.8784 22.7943 23.5502 22.7943 25.6124C22.7943 27.6746 24.4661 29.3463 26.5283 29.3463Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.8089 32.3335H56.3239C56.193 32.3337 56.0634 32.308 55.9424 32.2581C55.8215 32.2081 55.7116 32.1348 55.619 32.0423C55.5264 31.9498 55.4529 31.84 55.4028 31.7191C55.3526 31.5982 55.3268 31.4686 55.3268 31.3378C55.3268 31.2069 55.3526 31.0773 55.4028 30.9564C55.4529 30.8355 55.5264 30.7257 55.619 30.6332C55.7116 30.5407 55.8215 30.4674 55.9424 30.4175C56.0634 30.3675 56.193 30.3419 56.3239 30.342H59.8089C60.0728 30.3424 60.3257 30.4475 60.5121 30.6342C60.6986 30.8209 60.8033 31.0739 60.8033 31.3378C60.8033 31.6016 60.6986 31.8547 60.5121 32.0414C60.3257 32.2281 60.0728 32.3331 59.8089 32.3335Z"
          fill="white"
        />
        <path
          d="M59.8089 32.3335H56.3239C56.193 32.3337 56.0634 32.308 55.9424 32.2581C55.8215 32.2081 55.7116 32.1348 55.619 32.0423C55.5264 31.9498 55.4529 31.84 55.4028 31.7191C55.3526 31.5982 55.3268 31.4686 55.3268 31.3378C55.3268 31.2069 55.3526 31.0773 55.4028 30.9564C55.4529 30.8355 55.5264 30.7257 55.619 30.6332C55.7116 30.5407 55.8215 30.4674 55.9424 30.4175C56.0634 30.3675 56.193 30.3419 56.3239 30.342H59.8089C60.0728 30.3424 60.3257 30.4475 60.5121 30.6342C60.6986 30.8209 60.8033 31.0739 60.8033 31.3378C60.8033 31.6016 60.6986 31.8547 60.5121 32.0414C60.3257 32.2281 60.0728 32.3331 59.8089 32.3335Z"
          fill="#CCCCCC"
        />
        <path
          d="M61.5902 92.7628H22.0103C21.4493 92.7622 20.9115 92.5391 20.5148 92.1424C20.1182 91.7457 19.895 91.2079 19.8944 90.6469V77.9515C19.895 77.3905 20.1182 76.8527 20.5148 76.456C20.9115 76.0593 21.4493 75.8362 22.0103 75.8356H61.5902C62.1512 75.8362 62.689 76.0593 63.0857 76.456C63.4824 76.8527 63.7055 77.3905 63.7061 77.9515V90.6469C63.7055 91.2079 63.4824 91.7457 63.0857 92.1424C62.689 92.5391 62.1512 92.7622 61.5902 92.7628ZM22.0103 76.0845C21.5153 76.0851 21.0408 76.2819 20.6908 76.6319C20.3408 76.9819 20.1439 77.4565 20.1433 77.9515V90.6469C20.1439 91.1419 20.3408 91.6164 20.6908 91.9664C21.0408 92.3164 21.5153 92.5133 22.0103 92.5139H61.5902C62.0852 92.5133 62.5597 92.3164 62.9097 91.9664C63.2597 91.6164 63.4566 91.1419 63.4572 90.6469V77.9515C63.4566 77.4565 63.2597 76.9819 62.9097 76.6319C62.5597 76.2819 62.0852 76.0851 61.5902 76.0845H22.0103Z"
          fill="#CCCCCC"
        />
        <path
          d="M46.9905 83.6146H33.1749C32.9108 83.6146 32.6576 83.5097 32.4708 83.323C32.2841 83.1362 32.1792 82.883 32.1792 82.6189C32.1792 82.3548 32.2841 82.1015 32.4708 81.9148C32.6576 81.7281 32.9108 81.6232 33.1749 81.6232H46.9905C47.2544 81.6235 47.5073 81.7286 47.6938 81.9153C47.8802 82.102 47.9849 82.355 47.9849 82.6189C47.9849 82.8827 47.8802 83.1358 47.6938 83.3225C47.5073 83.5092 47.2544 83.6143 46.9905 83.6146Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.8104 86.9752H33.1749C32.9111 86.9749 32.6581 86.8698 32.4717 86.6831C32.2852 86.4964 32.1805 86.2433 32.1805 85.9795C32.1805 85.7156 32.2852 85.4626 32.4717 85.2759C32.6581 85.0892 32.9111 84.9841 33.1749 84.9838H59.8104C60.0745 84.9838 60.3278 85.0887 60.5145 85.2754C60.7012 85.4621 60.8062 85.7154 60.8062 85.9795C60.8062 86.2436 60.7012 86.4968 60.5145 86.6836C60.3278 86.8703 60.0745 86.9752 59.8104 86.9752Z"
          fill="#E6E6E6"
        />
        <path
          d="M26.5283 88.0331C28.5905 88.0331 30.2622 86.3613 30.2622 84.2991C30.2622 82.2369 28.5905 80.5652 26.5283 80.5652C24.4661 80.5652 22.7943 82.2369 22.7943 84.2991C22.7943 86.3613 24.4661 88.0331 26.5283 88.0331Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.809 91.0202H56.324C56.0599 91.0202 55.8066 90.9153 55.6199 90.7286C55.4332 90.5419 55.3282 90.2886 55.3282 90.0245C55.3282 89.7604 55.4332 89.5072 55.6199 89.3205C55.8066 89.1337 56.0599 89.0288 56.324 89.0288H59.809C60.0728 89.0292 60.3258 89.1342 60.5122 89.3209C60.6987 89.5076 60.8034 89.7607 60.8034 90.0245C60.8034 90.2884 60.6987 90.5414 60.5122 90.7281C60.3258 90.9148 60.0728 91.0199 59.809 91.0202Z"
          fill="white"
        />
        <path
          d="M59.809 91.0202H56.324C56.0599 91.0202 55.8066 90.9153 55.6199 90.7286C55.4332 90.5419 55.3282 90.2886 55.3282 90.0245C55.3282 89.7604 55.4332 89.5072 55.6199 89.3205C55.8066 89.1337 56.0599 89.0288 56.324 89.0288H59.809C60.0728 89.0292 60.3258 89.1342 60.5122 89.3209C60.6987 89.5076 60.8034 89.7607 60.8034 90.0245C60.8034 90.2884 60.6987 90.5414 60.5122 90.7281C60.3258 90.9148 60.0728 91.0199 59.809 91.0202Z"
          fill="#CCCCCC"
        />
        <path
          d="M61.5902 116.346H22.0103C21.4493 116.345 20.9115 116.122 20.5148 115.725C20.1182 115.328 19.895 114.791 19.8944 114.23V101.534C19.895 100.973 20.1182 100.435 20.5148 100.039C20.9115 99.6421 21.4493 99.419 22.0103 99.4183H61.5902C62.1512 99.419 62.689 99.6421 63.0857 100.039C63.4824 100.435 63.7055 100.973 63.7061 101.534V114.23C63.7055 114.791 63.4824 115.328 63.0857 115.725C62.689 116.122 62.1512 116.345 61.5902 116.346ZM22.0103 99.6673C21.5153 99.6678 21.0408 99.8647 20.6908 100.215C20.3408 100.565 20.1439 101.039 20.1433 101.534V114.23C20.1439 114.725 20.3408 115.199 20.6908 115.549C21.0408 115.899 21.5153 116.096 22.0103 116.097H61.5902C62.0852 116.096 62.5597 115.899 62.9097 115.549C63.2597 115.199 63.4566 114.725 63.4572 114.23V101.534C63.4566 101.039 63.2597 100.565 62.9097 100.215C62.5597 99.8647 62.0852 99.6678 61.5902 99.6673H22.0103Z"
          fill="#CCCCCC"
        />
        <path
          d="M46.9905 107.197H33.1749C32.9108 107.197 32.6576 107.092 32.4708 106.906C32.2841 106.719 32.1792 106.466 32.1792 106.202C32.1792 105.938 32.2841 105.684 32.4708 105.498C32.6576 105.311 32.9108 105.206 33.1749 105.206H46.9905C47.2544 105.206 47.5073 105.311 47.6938 105.498C47.8802 105.685 47.9849 105.938 47.9849 106.202C47.9849 106.466 47.8802 106.719 47.6938 106.905C47.5073 107.092 47.2544 107.197 46.9905 107.197Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.8104 110.558H33.1749C32.9111 110.557 32.6581 110.452 32.4717 110.266C32.2852 110.079 32.1805 109.826 32.1805 109.562C32.1805 109.298 32.2852 109.045 32.4717 108.859C32.6581 108.672 32.9111 108.567 33.1749 108.566H59.8104C60.0745 108.566 60.3278 108.671 60.5145 108.858C60.7012 109.045 60.8062 109.298 60.8062 109.562C60.8062 109.826 60.7012 110.079 60.5145 110.266C60.3278 110.453 60.0745 110.558 59.8104 110.558Z"
          fill="#E6E6E6"
        />
        <path
          d="M26.5283 111.616C28.5905 111.616 30.2622 109.944 30.2622 107.882C30.2622 105.82 28.5905 104.148 26.5283 104.148C24.4661 104.148 22.7943 105.82 22.7943 107.882C22.7943 109.944 24.4661 111.616 26.5283 111.616Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.809 114.603H56.324C56.0599 114.603 55.8066 114.498 55.6199 114.311C55.4332 114.125 55.3282 113.871 55.3282 113.607C55.3282 113.343 55.4332 113.09 55.6199 112.903C55.8066 112.716 56.0599 112.612 56.324 112.612H59.809C60.0728 112.612 60.3258 112.717 60.5122 112.904C60.6987 113.09 60.8034 113.343 60.8034 113.607C60.8034 113.871 60.6987 114.124 60.5122 114.311C60.3258 114.498 60.0728 114.603 59.809 114.603Z"
          fill="white"
        />
        <path
          d="M59.809 114.603H56.324C56.0599 114.603 55.8066 114.498 55.6199 114.311C55.4332 114.125 55.3282 113.871 55.3282 113.607C55.3282 113.343 55.4332 113.09 55.6199 112.903C55.8066 112.716 56.0599 112.612 56.324 112.612H59.809C60.0728 112.612 60.3258 112.717 60.5122 112.904C60.6987 113.09 60.8034 113.343 60.8034 113.607C60.8034 113.871 60.6987 114.124 60.5122 114.311C60.3258 114.498 60.0728 114.603 59.809 114.603Z"
          fill="#CCCCCC"
        />
        <path
          d="M36.9346 137.861H39.1417L40.1915 129.348L36.9342 129.348L36.9346 137.861Z"
          fill="#FFB8B8"
        />
        <path
          d="M36.3716 137.14L40.718 137.14H40.7182C41.082 137.14 41.4421 137.212 41.7782 137.351C42.1143 137.49 42.4196 137.694 42.6768 137.951C42.934 138.208 43.1381 138.514 43.2773 138.85C43.4165 139.186 43.4881 139.546 43.4881 139.91V140L36.3718 140L36.3716 137.14Z"
          fill="#2F2E41"
        />
        <path
          d="M28.1136 137.861H30.3207L31.3705 129.348L28.1133 129.348L28.1136 137.861Z"
          fill="#FFB8B8"
        />
        <path
          d="M27.5506 137.14L31.897 137.14H31.8972C32.2609 137.14 32.6211 137.212 32.9572 137.351C33.2932 137.49 33.5986 137.694 33.8558 137.951C34.113 138.208 34.317 138.514 34.4562 138.85C34.5954 139.186 34.6671 139.546 34.6671 139.91V140L27.5507 140L27.5506 137.14Z"
          fill="#2F2E41"
        />
        <path
          d="M37.3751 78.9252C39.817 78.9252 41.7966 76.9456 41.7966 74.5037C41.7966 72.0617 39.817 70.0822 37.3751 70.0822C34.9332 70.0822 32.9536 72.0617 32.9536 74.5037C32.9536 76.9456 34.9332 78.9252 37.3751 78.9252Z"
          fill="#FFB8B8"
        />
        <path
          d="M40.3484 136.023H36.2528C36.1456 136.023 36.0395 136.002 35.9405 135.961C35.8416 135.919 35.7519 135.859 35.6765 135.783C35.6012 135.706 35.5417 135.616 35.5016 135.516C35.4615 135.417 35.4415 135.311 35.4428 135.203L35.6808 115.755C35.6816 115.686 35.656 115.62 35.6091 115.569C35.5623 115.518 35.4977 115.487 35.4288 115.483C35.3599 115.478 35.2918 115.5 35.2386 115.544C35.1854 115.588 35.1511 115.651 35.1427 115.719L32.7591 135.311C32.735 135.507 32.64 135.688 32.4919 135.819C32.3437 135.951 32.1528 136.023 31.9549 136.023H27.82C27.7083 136.023 27.5978 136 27.4954 135.955C27.3931 135.911 27.3011 135.845 27.2252 135.763C27.1494 135.681 27.0913 135.584 27.0547 135.479C27.0181 135.373 27.0036 135.261 27.0124 135.15L29.7428 100.212L29.8171 100.204L42.8918 98.7924L41.1576 135.252C41.148 135.46 41.0584 135.656 40.9077 135.8C40.757 135.943 40.5567 136.024 40.3484 136.023Z"
          fill="#00164E"
        />
        <path
          d="M29.902 102.357C29.6919 102.357 29.4903 102.275 29.3397 102.128C29.189 101.982 29.1012 101.783 29.0948 101.573C29.0234 99.3636 28.9011 91.7197 30.3229 86.108C30.7109 84.575 31.5819 83.2075 32.8071 82.2077C34.0323 81.208 35.5467 80.629 37.1264 80.5563C38.6812 80.4714 40.2233 80.8783 41.5339 81.7192C42.8445 82.5601 43.857 83.7923 44.4278 85.2411C46.8176 91.3084 49.0755 98.787 46.2605 99.9599C42.571 101.497 32.8448 102.182 29.9517 102.356C29.935 102.357 29.9185 102.357 29.902 102.357Z"
          fill="#1F5EFF"
        />
        <path
          d="M46.8008 70.0348C46.8703 70.1062 46.9453 70.1719 47.0251 70.2315L46.561 79.1473L44.6304 80.1357L45.7412 83.2443L49.329 81.8266C49.6188 81.7121 49.8637 81.5066 50.0269 81.2411C50.19 80.9756 50.2626 80.6643 50.2338 80.3539L49.2938 70.2405C49.6014 70.011 49.8318 69.6933 49.9544 69.3297C50.0771 68.9661 50.0861 68.5737 49.9802 68.2049C49.8744 67.836 49.6588 67.5081 49.3621 67.2647C49.0654 67.0214 48.7016 66.8741 48.3192 66.8426C47.9368 66.811 47.5538 66.8967 47.2212 67.0881C46.8886 67.2795 46.6222 67.5676 46.4573 67.9142C46.2925 68.2607 46.2371 68.6492 46.2984 69.028C46.3598 69.4068 46.535 69.758 46.8008 70.0348H46.8008Z"
          fill="#FFB8B8"
        />
        <path
          d="M46.5975 79.4792L47.9556 83.3386C47.9943 83.4484 48.0088 83.5653 47.9982 83.6812C47.9876 83.7971 47.9521 83.9094 47.8942 84.0104C47.8363 84.1114 47.7574 84.1987 47.6627 84.2665C47.568 84.3342 47.4598 84.3807 47.3455 84.4028L43.3409 85.1783C42.7779 85.3729 42.1607 85.3363 41.6247 85.0765C41.0887 84.8167 40.6776 84.355 40.4815 83.7925C40.2855 83.2301 40.3204 82.6128 40.5788 82.0761C40.8371 81.5394 41.2978 81.1271 41.8597 80.9295L45.4554 79.0316C45.5584 78.9772 45.6718 78.9458 45.7881 78.9393C45.9043 78.9327 46.0206 78.9514 46.129 78.9938C46.2373 79.0363 46.3353 79.1017 46.4162 79.1854C46.497 79.2692 46.5589 79.3694 46.5975 79.4792V79.4792Z"
          fill="#1F5EFF"
        />
        <path
          d="M27.6489 70.0348C27.5794 70.1062 27.5044 70.1719 27.4246 70.2315L27.8888 79.1473L29.8193 80.1357L28.7085 83.2443L25.1207 81.8266C24.8309 81.7121 24.586 81.5066 24.4229 81.2411C24.2597 80.9756 24.1871 80.6643 24.216 80.3539L25.1559 70.2405C24.8483 70.011 24.6179 69.6933 24.4953 69.3297C24.3727 68.9661 24.3637 68.5737 24.4695 68.2049C24.5753 67.836 24.7909 67.5081 25.0876 67.2647C25.3843 67.0214 25.7481 66.8741 26.1305 66.8426C26.513 66.811 26.8959 66.8967 27.2285 67.0881C27.5611 67.2795 27.8276 67.5676 27.9924 67.9142C28.1572 68.2607 28.2127 68.6492 28.1513 69.028C28.0899 69.4068 27.9147 69.758 27.6489 70.0348Z"
          fill="#FFB8B8"
        />
        <path
          d="M28.3207 78.9939C28.4291 78.9514 28.5454 78.9327 28.6616 78.9393C28.7778 78.9458 28.8913 78.9773 28.9942 79.0316L32.5899 80.9296C33.1518 81.1272 33.6125 81.5395 33.8709 82.0762C34.1292 82.6129 34.1642 83.2302 33.9681 83.7926C33.772 84.3551 33.3609 84.8168 32.8249 85.0766C32.2889 85.3364 31.6717 85.373 31.1088 85.1784L27.1041 84.4029C26.9898 84.3808 26.8817 84.3343 26.787 84.2665C26.6923 84.1988 26.6133 84.1115 26.5554 84.0105C26.4975 83.9095 26.462 83.7972 26.4514 83.6813C26.4409 83.5654 26.4554 83.4485 26.494 83.3387L27.8521 79.4793C27.8907 79.3694 27.9526 79.2692 28.0334 79.1854C28.1143 79.1017 28.2123 79.0363 28.3207 78.9939Z"
          fill="#1F5EFF"
        />
        <path
          d="M39.0923 75.2573C39.0691 74.6933 39.2107 74.1348 39.4997 73.6499C39.7887 73.165 40.2127 72.7747 40.7198 72.5268C42.0638 71.833 42.9082 70.3531 42.5382 69.0017C42.0646 67.2719 39.7103 66.15 37.8027 66.7451C37.2865 66.9284 36.7886 67.1598 36.3156 67.4363L35.0693 68.1044C34.5369 68.3709 34.0236 68.6739 33.533 69.0113C32.7982 69.5528 32.2222 70.2816 31.8654 71.1218C31.5085 71.9619 31.3837 72.8824 31.5041 73.7872C31.7495 75.5458 32.9012 77.1586 34.4776 78.2627C35.0805 78.7133 35.7768 79.0228 36.5153 79.1684C37.2709 79.293 38.1121 79.1514 38.6646 78.6815C39.528 77.9471 39.4388 76.7173 39.1596 75.6902C39.1227 75.5486 39.1001 75.4035 39.0923 75.2573Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0_73_14183">
          <rect
            width="78.7358"
            height="140"
            fill="white"
            transform="translate(0.632141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
