import { css } from "emotion";

export const styles = {
  content: css({
    margin: "4vh 4vw"
  }),
  frameView: css({
    minHeight: "400px",
    width: "100%"
  }),
  iframe: css({
    width: "100%",
    minHeight: "85vh",
    border: "none"
  }),
  loaderView: css({
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }),
  errorView: css({
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  })
};
