import { useMemo, useCallback } from "react";
import type { ColumnGroupType, ColumnsType } from "antd/es/table";
import { Cell } from "../components/Cell";
import type {
  DataPlusUndefined,
  CellData,
  DataCellFormatter,
  CellProps,
  ActiveColumnIdentifier
} from "../types";
import { ColumnTitle } from "../components/ColumnTitle";
import { ONE, ZERO } from "@certa/common";

const FIRST_X_AXIS_LABELS = 0;
const NO_OF_ADDITIONAL_COLUMNS = 3;
const MAX_COLUMN_WIDTH = 100;

type DataType = Record<string, CellData>;

export const useDataColumns = (
  data: DataType[],
  xAxisKeys: string[],
  yAxisKeys: string[],
  multipleXAxisLabels: string[][],
  dataCellFormatter: DataCellFormatter,
  minValue: number,
  minMaxDiff: number,
  cellBaseColors?: string[],
  onClick?: CellProps["onClick"],
  activeColumnIdentifier?: ActiveColumnIdentifier
) => {
  const groupDataInReqFormat = useCallback(
    (
      data: DataType[],
      xAxisKeys: string[],
      yAxisKeys: string[],
      index = 0,
      parentDataIndexSet = new Set()
    ): ColumnGroupType<DataPlusUndefined>[] => {
      const result = [];
      let newParentDataIndexSet = new Set(parentDataIndexSet);

      if (index === xAxisKeys.length - 1) {
        // Base case:
        // The function checks if it's at the last key in the hierarchy
        // (index === xAxisKeys.length - 1). If so, it creates column
        // definitions for the leaf nodes:
        data.forEach((item: DataType, colIndex) => {
          const title = item[xAxisKeys[index]];
          // #GENERATE_KEY_FOR_NESTED_X_AXIS_LABELS
          // Search the above key to find references for the below key
          const dataIndex =
            title === null ? "$$$null$$$" : generateDataIndex(item, xAxisKeys);
          const colSpan = 1;
          if (!newParentDataIndexSet.has(dataIndex)) {
            // For each group of children, it creates a column definition object
            // with properties like title, dataIndex, colSpan, and children.
            // This object represents a parent column with nested child columns.
            result.push({
              title: <ColumnTitle title={`${title}`} />,
              dataIndex: [dataIndex],
              colSpan,
              width: `${
                MAX_COLUMN_WIDTH /
                (multipleXAxisLabels[FIRST_X_AXIS_LABELS].length +
                  NO_OF_ADDITIONAL_COLUMNS)
              }%`,
              ellipsis: true,
              render: (
                cellValue: number,
                rowRecord: DataType,
                rowIndex: number
              ) => {
                const currentItem = data.find(item => {
                  const isYAxisKeyValueMatch = yAxisKeys.every(
                    key => item[key] === rowRecord[key]
                  );
                  const isXAxisKeyValueMatch =
                    item[xAxisKeys[xAxisKeys.length - 1]] === title;
                  return isYAxisKeyValueMatch && isXAxisKeyValueMatch;
                });

                return (
                  <Cell
                    cellBaseColors={cellBaseColors}
                    dataCellFormatter={dataCellFormatter}
                    cellValue={cellValue}
                    columnHeaderKey={dataIndex}
                    cellFiltersData={currentItem}
                    rowRecord={rowRecord}
                    rowIndex={rowIndex}
                    columnIndex={colIndex}
                    onClick={onClick}
                    ratio={(cellValue - minValue) / minMaxDiff}
                    activeColumnIdentifier={activeColumnIdentifier}
                  />
                );
              }
            });
            newParentDataIndexSet.add(dataIndex);
          }
        });
      } else {
        // Recursive case: Process children for the next key
        const currentKey = xAxisKeys[index];
        const groupedData = groupBy(data, currentKey);

        for (const [key, children] of Object.entries(groupedData)) {
          // #GENERATE_KEY_FOR_NESTED_X_AXIS_LABELS
          // Search the above key to find references for the below key
          const dataIndex = key === null ? `$$$nul$$$` : `$$$${key}$$$`;
          const title = key;
          newParentDataIndexSet = new Set();
          if (!newParentDataIndexSet.has(dataIndex)) {
            const childResult = groupDataInReqFormat(
              children,
              xAxisKeys,
              yAxisKeys,
              index + 1,
              newParentDataIndexSet
            );
            result.push({
              title: <ColumnTitle title={title} />,
              dataIndex: [dataIndex],
              colSpan: calculateNumberOfLeafChildren(childResult),
              children: childResult
            });
            newParentDataIndexSet.add(dataIndex);
          }
        }
      }

      return result;
    },
    [
      multipleXAxisLabels,
      cellBaseColors,
      dataCellFormatter,
      onClick,
      minValue,
      minMaxDiff,
      activeColumnIdentifier
    ]
  );

  const dataColumns: ColumnsType<DataPlusUndefined> = useMemo(() => {
    return groupDataInReqFormat(data, xAxisKeys.slice().reverse(), yAxisKeys);
  }, [groupDataInReqFormat, data, xAxisKeys, yAxisKeys]);

  return dataColumns;
};

// Helper function to group data by a specific key
const groupBy = (data: DataType[], key: string) => {
  return data.reduce(
    (result, item) => {
      // key is within the x-axis labels, and in modal creator
      // for x-axis values are converted to string
      const groupKey = `${item[key]}`;
      result[groupKey] = result[groupKey] || [];
      result[groupKey].push(item);
      return result;
    },
    {} as Record<string, DataType[]>
  );
};

type ProcessKeyParams = {
  item: DataType;
  label: string;
  xAxisKeys: string[];
  index: number;
  key: string;
};

const processKey = ({
  item,
  label,
  xAxisKeys,
  index,
  key
}: ProcessKeyParams) => {
  let updatedKey = key;
  if (item[label] !== undefined) {
    // #GENERATE_KEY_FOR_NESTED_X_AXIS_LABELS
    // Search the above key to find references for the below key
    updatedKey +=
      (index === ZERO ? "$$$" : "___") +
      (item[label] ? item[label] : "") +
      (index === xAxisKeys.length - ONE ? "$$$" : "");
  }
  return updatedKey;
};

const generateDataIndex = (item: DataType, xAxisKeys: string[]) => {
  let key = "";
  xAxisKeys
    .slice()
    .reverse()
    .forEach((label: string, index: number) => {
      key = processKey({ item, label, xAxisKeys, index, key });
    });
  return key;
};

// Helper function to calculate the number of leaf children in the deepest level
const calculateNumberOfLeafChildren = (
  children: ColumnGroupType<DataPlusUndefined>[] | undefined
) => {
  let count = 0;

  if (children) {
    for (const child of children) {
      if (child.children) {
        count += calculateNumberOfLeafChildren(
          child.children as ColumnGroupType<DataPlusUndefined>[]
        );
      } else {
        count += 1;
      }
    }
  }

  return count;
};
