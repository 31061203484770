import React, { forwardRef } from "react";
import NumberFormat from "react-number-format";
import type { TextAreaProps } from "@certa/catalyst";
import { TextArea } from "@certa/catalyst";

export type FormattedTextInputProps = Omit<
  TextAreaProps,
  "defaultValue" | "onChange" | "onBlur"
> & {
  hasFormattedValue?: boolean;
  format?: string;
  onChange: (value?: string) => void;
  onBlur?: (value?: string) => void;
};

/**
 * This Component is a wrapper around NumberFormat.
 * The purpose is to extend the functionality of a text input
 * component to allow formatted text input, such as D-U-N-S Number.
 *
 * In case of a unsupported format the component will show error,
 * rather than falling back to TextArea component. This ensures that
 * any configuration problems can be detected.
 */
export const FormattedTextInput = forwardRef<
  HTMLInputElement,
  FormattedTextInputProps
>((props, ref) => {
  const { hasFormattedValue, format, value, onChange, onBlur, ...otherProps } =
    props;

  const handleChange = (changed: { formattedValue: string; value: string }) => {
    const changedValue = hasFormattedValue
      ? changed.formattedValue
      : changed.value;
    onChange(changedValue);
  };

  const handleBlur = () => {
    onBlur?.(value);
  };

  // If we don't have a format, render the error message
  if (!format) return <span>Unspecified format</span>;
  if (!/#/.test(format)) return <span>Unsupported format {format}</span>;

  // Render the NumberFormat component
  return (
    <NumberFormat
      customInput={TextArea}
      format={format}
      {...otherProps}
      getInputRef={ref}
      value={value || ""}
      onBlur={handleBlur}
      onValueChange={handleChange}
    />
  );
});
