import { APIFetchV2, RequestHeaders } from "@certa/network";
import { subscriptionListModelCreator } from "../models/threadSubscription.model";

function addSubscriptionToThread(payload: {
  threadId: number;
  enable: boolean;
}) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include"
  };
  return APIFetchV2(
    `threads/${payload.threadId}/subscriptions/`,
    requestOptions
  );
}

function removeSubscriptionToThread(payload: {
  threadId: number;
  enable: boolean;
}) {
  const requestOptions: RequestInit = {
    method: "DELETE",
    headers: RequestHeaders.POST,
    credentials: "include"
  };
  return APIFetchV2(
    `threads/${payload.threadId}/subscriptions/`,
    requestOptions
  );
}

function getUserSubscriptions(limit: number, offset: number = 0) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    `threads/user-subscriptions/?limit=${limit}&offset=${offset}`,
    requestOptions
  ).then(subscriptionListModelCreator);
}

export {
  addSubscriptionToThread,
  removeSubscriptionToThread,
  getUserSubscriptions
};
