import React, { useState, useEffect, useMemo } from "react";
import { Tag } from "antd";
import { FormattedMessage } from "react-intl";
import { WorkflowLink } from "./WorkflowLink";
import { Timestamp } from "@certa/common";
import { Arrow } from "@certa/icons";
import { Stack, Text } from "@certa/blocks/thanos";
import type { ActivityLogModelType } from "@certa/queries";

export const ActivityLogEmail = ({ item }: { item: ActivityLogModelType }) => {
  const [toEmail, setToEmail] = useState("");
  const emailKeys = useMemo(
    () => Object.keys(item.details || {}),
    [item.details]
  ); // it can be to, cc, bcc, or nothing, on older logs
  useEffect(() => {
    // See if we have any keys (Changes are there's always one)
    if (emailKeys.length > 0) {
      // pick the first one (and probably the only one, until BE implementation changes and they start grouping them)
      setToEmail((item.details as any)[emailKeys[0]]);
    }
  }, [emailKeys, item.details]);
  return (
    <Stack direction="vertical">
      {item.objectName ? (
        <span>
          <Text variant="p1-bold" color="neutral-100">
            {item.objectName}
          </Text>{" "}
          {/* Don't render workflow link if workflow is viewed*/}
          {item.actionType !== "viewed" && item.objectName !== "workflow" ? (
            <>
              {` - `}
              {/* TODO: object_name from API in emails is giving workflow name at the end */}
              {<WorkflowLink item={item} />}
            </>
          ) : null}
        </span>
      ) : (
        ""
      )}
      <Stack gap="s2" align="center">
        <Text variant="p1-regular" color="neutral-100" transform="capitalize">
          {item.actionType ? (
            <FormattedMessage
              id={`activityLog.email.${item.actionType}`}
              defaultMessage={item.actionType}
            />
          ) : (
            item.actionCode
          )}
        </Text>
        {toEmail && <Arrow width={12} />}
        {toEmail && (
          <Tag>
            {emailKeys[0]}
            {" : "}
            <a href={"mailto:" + toEmail}>
              <Text variant="p1-regular" color="neutral-100">
                {toEmail}
              </Text>
            </a>
          </Tag>
        )}
      </Stack>
      <Timestamp timestamp={item.timestamp.$date} fontSize="var(--p3)" />
    </Stack>
  );
};
