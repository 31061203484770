import type { FC } from "react";
import { Fragment } from "react";
import type { WorkflowFamily } from "@certa/types/src/comments";
import {
  Ellipsis,
  Stack,
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst";
import {
  dashboardOrReportItemStyles,
  hierarchyItemStyles,
  mentionsPathStyles
} from "./notificationsItem.styles";
import { useIntl } from "react-intl";

type Hierarchy = {
  isDashboardMention: boolean;
  isReportMention: boolean;
  processName: string;
  workflowType: string | undefined;
  taskName: string | null;
  fieldName: string | null;
  workflowFamily: WorkflowFamily;
  taskGroupName?: string | null;
  threadName?: string | null;
};

const MentionsPath: FC<Hierarchy> = ({
  isDashboardMention,
  isReportMention,
  workflowType,
  processName,
  taskName,
  fieldName,
  workflowFamily,
  taskGroupName,
  threadName
}) => {
  const intl = useIntl();
  const dashboardText = intl.formatMessage({
    id: "notifications.dashboard",
    defaultMessage: "Dashboard"
  });

  const reportText = intl.formatMessage({
    id: "notifications.report",
    defaultMessage: "Report"
  });

  return (
    <Stack
      direction="horizontal"
      align="center"
      gap="4"
      dangerouslySetClassName={mentionsPathStyles}
    >
      {!workflowFamily.length ? (
        <Fragment>
          <Stack direction="horizontal" align="center">
            {workflowType && (
              <Typography
                variant={TypographyVariants.LABEL_SM}
                color={TypographyColors.NEUTRAL_600}
                className={hierarchyItemStyles}
              >
                <Ellipsis>{workflowType}</Ellipsis>
              </Typography>
            )}
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_600}
            >
              :{" "}
            </Typography>
          </Stack>
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.NEUTRAL_600}
            className={hierarchyItemStyles}
          >
            <Ellipsis>{processName}</Ellipsis>
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          {isDashboardMention && (
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_700}
              className={dashboardOrReportItemStyles}
            >
              <Ellipsis>
                {`${dashboardText}: ${workflowFamily?.[workflowFamily?.length - 1]?.name}`}
              </Ellipsis>
            </Typography>
          )}
          {isReportMention && (
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_700}
              className={dashboardOrReportItemStyles}
            >
              <Ellipsis>
                {`${reportText}: ${workflowFamily?.[workflowFamily?.length - 1]?.name}`}
              </Ellipsis>
            </Typography>
          )}
        </Fragment>
      )}
    </Stack>
  );
};

export { MentionsPath };
