import {
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
  Button,
  DrawerPlacements,
  Drawer,
  DrawerHeader,
  Stack,
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst";
import { Close } from "@certa/icons";
import { useIntl } from "react-intl";
import { ActivityLogsV2 } from "./ActivityLogsV2";
import { ErrorBoundary } from "@certa/common";

type ActivityLogsDrawerProps = {
  id: number | undefined;
  isActivityLogsDrawerOpen: boolean;
  setIsActivityLogsDrawerOpen: (value: boolean) => void;
};

const ActivityLogsDrawer = ({
  id,
  isActivityLogsDrawerOpen,
  setIsActivityLogsDrawerOpen
}: ActivityLogsDrawerProps) => {
  const intl = useIntl();

  return (
    <Drawer
      width="38rem"
      title={intl.formatMessage({
        id: "workflowsInstances.activity",
        defaultMessage: "Activity"
      })}
      aria-label={"activity logs drawer"}
      show={isActivityLogsDrawerOpen}
      onClose={() => {
        setIsActivityLogsDrawerOpen(false);
      }}
      padding="0 0"
      placement={DrawerPlacements.RIGHT}
      showCloseIcon={false}
    >
      <DrawerHeader>
        <Stack
          direction="horizontal"
          justify="space-between"
          align="center"
          padding="32 24 16 24"
        >
          <Typography
            variant={TypographyVariants.TITLE_SM}
            color={TypographyColors.NEUTRAL_800}
          >
            {intl.formatMessage({
              id: "workflowsInstances.activity",
              defaultMessage: "Activity"
            })}
          </Typography>
          <div>
            <Button
              size={ButtonSizes.SMALL}
              type={ButtonTypes.ICON}
              color={ButtonColors.BRAND}
              leftIcon={<Close />}
              aria-label={"collapse activity drawer"}
              onClick={() => {
                setIsActivityLogsDrawerOpen(false);
              }}
            />
          </div>
        </Stack>
      </DrawerHeader>
      <ErrorBoundary>
        <ActivityLogsV2 id={id} />
      </ErrorBoundary>
    </Drawer>
  );
};

export { ActivityLogsDrawer };
