import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  businessUnitPlaceholderSelector,
  regionPlaceholderSelector
} from "main/src/modules/dashboard/selectors";

import { FilterTypes } from "@certa/queries";
import { ADVANCED_FILTERS, DATE_RANGE } from "../..";

const filterIntlMapper = {
  [FilterTypes.BUSINESS_UNIT]:
    "workflowFiltersTranslated.filterPlaceholders.business_unit",
  [FilterTypes.REGION]: "workflowFiltersTranslated.filterPlaceholders.region",
  [FilterTypes.STATUS]: "workflowFiltersTranslated.filterPlaceholders.status",
  [DATE_RANGE]: "workflowFiltersTranslated.filterPlaceholders.dateRange",
  [ADVANCED_FILTERS]:
    "workflowFiltersTranslated.filterPlaceholders.advancedFilters",
  [FilterTypes.KIND_ID]:
    "workflowFiltersTranslated.filterPlaceholders.processType",
  [FilterTypes.QUERY]: "workflowFiltersTranslated.filterPlaceholders.contains"
};

export const useDefinedProcessLabel = (): {
  [FilterTypes.REGION]: string;
  [FilterTypes.BUSINESS_UNIT]: string;
  [FilterTypes.STATUS]: string;
  [date: string]: string;
  [FilterTypes.KIND_ID]: string;
  [FilterTypes.QUERY]: string;
} => {
  const intl = useIntl();
  const definedRegionPlaceholder: string = useSelector(
    regionPlaceholderSelector
  );
  const definedBuPlaceholder: string = useSelector(
    businessUnitPlaceholderSelector
  );

  const labels = useMemo(() => {
    const regionLabel =
      definedRegionPlaceholder ||
      intl.formatMessage({
        id: filterIntlMapper[FilterTypes.REGION]
      });
    const buLabel =
      definedBuPlaceholder ||
      intl.formatMessage({
        id: filterIntlMapper[FilterTypes.BUSINESS_UNIT]
      });
    const statusLabel = intl.formatMessage({
      id: filterIntlMapper[FilterTypes.STATUS]
    });
    const dateLabel = intl.formatMessage({
      id: filterIntlMapper[DATE_RANGE],
      defaultMessage: "Date"
    });
    const advancedFilterLabel = intl.formatMessage({
      id: filterIntlMapper[ADVANCED_FILTERS],
      defaultMessage: "Advanced Filters"
    });
    const kindTagLabel = intl.formatMessage({
      id: filterIntlMapper[FilterTypes.KIND_ID],
      defaultMessage: "Process Type"
    });
    const queryKindLabel = intl.formatMessage({
      id: filterIntlMapper[FilterTypes.QUERY],
      defaultMessage: "Contains"
    });

    return {
      [FilterTypes.REGION]: regionLabel,
      [FilterTypes.BUSINESS_UNIT]: buLabel,
      [FilterTypes.STATUS]: statusLabel,
      [DATE_RANGE]: dateLabel,
      [ADVANCED_FILTERS]: advancedFilterLabel,
      [FilterTypes.KIND_ID]: kindTagLabel,
      [FilterTypes.QUERY]: queryKindLabel
    };
  }, [definedBuPlaceholder, definedRegionPlaceholder, intl]);

  return labels;
};
