import type { XAxisDataTypes, GroupByDateLabels } from "@certa/common";
import {
  commonXAxisLabel,
  INDEX_ZERO,
  PROCESS_SWIMLANES_TAG,
  ALL_STEPS_TAG,
  CYCLE_TIME_OPTION_LABEL,
  INDEX_ONE,
  ONE
} from "@certa/common";
import type {
  FieldTypes,
  OtherChartConfigurations,
  ChartConfigORM,
  GroupByORM,
  OperationORM
} from "@certa/types";

type GetDefaultXAxisLabelORM = (
  groupBy?: GroupByORM[],
  cycleTimePath?: OtherChartConfigurations["cycleTimeTag"]
) => string;

export const getDefaultXAxisLabelORM: GetDefaultXAxisLabelORM = (
  groupBy,
  cycleTimeTag
) => {
  let label = "";
  if (cycleTimeTag?.includes(PROCESS_SWIMLANES_TAG))
    label = commonXAxisLabel.PROCESS_SWIMLANES;
  else if (cycleTimeTag?.includes(`${ALL_STEPS_TAG}-of-`))
    label = commonXAxisLabel.ALL_STEPS;
  else
    label =
      groupBy
        ?.filter(data => !data.extraJSON.isForSecondaryXAxis)
        .map(data => data.extraJSON.label)
        .filter(Boolean)
        .join(", ") ?? "";
  return label;
};

export const getDefaultYAxisLabelORM = (
  yAxis: OperationORM[] | undefined,
  isCycleTimeReport = false
) => {
  let yAxislabel = "";
  if (yAxis) {
    if (isCycleTimeReport) {
      yAxislabel = getDefaultYAxisLabelForCycleTimeORM(yAxis);
    } else {
      yAxislabel = yAxis
        .filter(data => data.extraJSON.axisName === "yAxis")
        .map(metricAttribute => metricAttribute?.label)
        .join(", ");
    }
  }
  return yAxislabel;
};

export const getDefaultYAxisLabelForCycleTimeORM = (yAxis: OperationORM[]) => {
  return `${CYCLE_TIME_OPTION_LABEL} (${getAggregateMetricTypeORM(yAxis)})`;
};

export const getAggregateMetricTypeORM = (
  yAxis: OperationORM[] | undefined
) => {
  const metrics = yAxis?.filter(data => data.extraJSON.axisName === "yAxis");
  if (!metrics) return "";
  // for cycle time report
  if (metrics[INDEX_ZERO]?.function) {
    if (metrics.length === ONE) {
      return metrics[INDEX_ZERO]?.function;
      // if there is more then one yAxis,
      // either all yAxis should be of same type or all yAxis should be of different type
      // like when aggregate (mtr_type) is "min" then all yAxis will be of type "min"
    } else if (
      typeof metrics[INDEX_ONE] !== "string" &&
      metrics[INDEX_ZERO]?.function === metrics[INDEX_ONE]?.function
    ) {
      return metrics[INDEX_ZERO]?.function;
    }
    // but if aggregate (mtr_type) is "all" then yaxis mtr_type will be sequence of "min", "max", "avg"
    return "all";
  }
  return "";
};
export const getOtherConfigXAxis = (
  chartConfigORM: ChartConfigORM | undefined
) => {
  const xAxis = {
    fieldTypes: [] as FieldTypes[],
    dataTypes: [] as XAxisDataTypes[],
    labelOutputTypes: [] as GroupByDateLabels[]
  };
  chartConfigORM?.groupBy
    ?.filter(groupBy => !groupBy.extraJSON.isForSecondaryXAxis)
    ?.forEach(groupBy => {
      const { fieldType, dataType, labelOutputType } = groupBy.extraJSON;
      xAxis.fieldTypes.push(fieldType);
      xAxis.dataTypes.push(dataType);
      xAxis.labelOutputTypes.push(labelOutputType);
    });
  return xAxis;
};

export const getSortingFromChartConfigORM = (
  groupBy: ChartConfigORM["groupBy"] | undefined,
  orderBy: ChartConfigORM["orderBy"] | undefined
) => {
  return groupBy?.map(item => ({
    label: item.extraJSON.label,
    tag: item.extraJSON.customTag,
    order: orderBy?.find(order => order.tag === item.tag)?.ordering || "ASC"
  }));
};
