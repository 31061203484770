import { Stack, Text } from "@certa/blocks/thanos";
import { FormattedMessage } from "react-intl";

export const SomethingWentWrong = () => (
  <Stack direction="vertical" align="center" justify="center">
    <Text variant="h2-regular" color="red">
      <FormattedMessage id="notificationInstances.somethingWentWrong" />
    </Text>
  </Stack>
);
