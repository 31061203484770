import { workflowDetailsConstants } from "../constants";

export function workflowDetails(state = {}, action) {
  switch (action.type) {
    /**
     * When the Integration is queued or started, we must add the fields ID
     * to the Set of the step that it belongs to.
     */
    case workflowDetailsConstants.STEP_INTEGRATION_PENDING: {
      const { workflowId, stepId, fieldId } = action.payload;
      const existingData = state[workflowId] || {};
      const stepsWaiting = { ...(existingData.stepsWaiting || {}) };

      // add the pending field's ID into stepID set.
      stepsWaiting[stepId] = new Set(stepsWaiting[stepId] || []);
      stepsWaiting[stepId].add(fieldId);

      return {
        ...state,
        [workflowId]: {
          ...existingData,
          stepsWaiting
        }
      };
    }
    /**
     * When the Integration is finished or failed, we must remove the fields ID
     * from the Set of the step that it belongs to. If the step itself is empty
     * after removing the field, then we remove the entire step itself.
     */
    case workflowDetailsConstants.STEP_INTEGRATION_FINISHED: {
      const { workflowId, stepId, fieldId } = action.payload;
      const existingData = state[workflowId] || {};
      const stepsWaiting = { ...(existingData.stepsWaiting || {}) };

      // Remove the field from stepIDs set if it exists
      stepsWaiting[stepId] && stepsWaiting[stepId].delete(fieldId);

      // If there are no other fields left in ths step
      // we remove the entire step
      stepsWaiting[stepId] &&
        stepsWaiting[stepId].size === 0 &&
        delete stepsWaiting[stepId];

      return {
        ...state,
        [workflowId]: {
          ...existingData,
          stepsWaiting
        }
      };
    }

    case workflowDetailsConstants.GET_STEPGROUPS_REQUEST:
      return {
        ...state,
        [action.id]: { loading: true }
      };
    case workflowDetailsConstants.GET_STEPGROUPS_SUCCESS:
      const existingData = state[action.id] || {};
      return {
        ...state,
        [action.id]: {
          ...existingData,
          loading: false,
          workflowDetails: { stepGroups: action.stepGroups },
          isActive: action["isActive"]
        },
        loading: false,
        workflowDetails: { stepGroups: action.stepGroups }
      };
    case workflowDetailsConstants.GET_STEPGROUPS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state;
  }
}

export function hasStepinfo(state = { stepInfo: false }, action) {
  switch (action.type) {
    //
    case workflowDetailsConstants.SET_STEP_ID:
      return {
        ...state,
        stepInfo: action.payload
      };

    case workflowDetailsConstants.REMOVE_STEP_ID:
      return {
        stepInfo: false
      };

    default:
      return state;
  }
}
