import React, { useState } from "react";
import { Stack, Text, Divider } from "@certa/blocks/thanos";
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  ButtonVariants,
  keyboardKeys
} from "@certa/catalyst";
import styled from "@emotion/styled";
import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import { css } from "emotion";
import { Close } from "@certa/blocks";
import type { RgbaColor } from "react-colorful";
import { RgbaColorPicker, HexColorInput } from "react-colorful";
import { colord } from "colord";
import { predefinedColorPickerColors } from "../../constants";

type ColorPickerCardProps = {
  rgbaColor: RgbaColor;
  onChange: (value: RgbaColor) => void;
  onClose: () => void;
  isSmall?: boolean;
};

export const ColorPickerCard = ({
  rgbaColor,
  onChange,
  onClose,
  isSmall
}: ColorPickerCardProps) => {
  const [rgba, setRgba] = useState(rgbaColor);
  const intl = useIntl();

  const onSubmit = () => {
    onChange(rgba);
    onClose();
  };

  return (
    <Stack gap="s4" direction="vertical" style={{ width: "296px" }}>
      <Stack justify="space-between" align="center">
        <Text variant="h3-bold">
          {intl.formatMessage({
            id: "common.colors",
            defaultMessage: "Colors"
          })}
        </Text>
        <Button
          onClick={onClose}
          type={ButtonTypes.ICON}
          size={ButtonSizes.SMALL}
          leftIcon={<Close />}
          aria-label="Close Color Picker"
        />
      </Stack>
      <RgbaColorPicker
        color={rgba}
        onChange={setRgba}
        className={reactColorfulStyle}
      />
      {!isSmall && (
        <>
          <Divider />
          <Stack justify="space-between" align="center" gap="s4">
            <SelectedColorBigBox color={colord(rgba).toRgbString()} />
            <Row
              gutter={[6, 4]}
              justify="space-between"
              style={{ marginLeft: "var(--s4)" }}
            >
              {predefinedColorPickerColors.map(color => (
                <PredefinedColorInput
                  key={color}
                  color={color}
                  setColor={setRgba}
                />
              ))}
            </Row>
          </Stack>
          <HexColorInput
            aria-label="Hex Color Input Field"
            className={hexColorInputFieldStyle}
            color={colord(rgba).toHex()}
            onChange={color => setRgba(colord(color).toRgb())}
            prefixed
          />
        </>
      )}
      <Stack justify="flex-end" align="center" gap="s4">
        <Button onClick={onClose}>
          {intl.formatMessage({
            id: "savedSearch.cancel",
            defaultMessage: "Cancel"
          })}
        </Button>
        <Button onClick={onSubmit} variant={ButtonVariants.FILLED}>
          {intl.formatMessage({
            id: "editableTable.save",
            defaultMessage: "Save"
          })}
        </Button>
      </Stack>
    </Stack>
  );
};

type PreDefinedColorInputProps = {
  color: string;
  setColor: (color: RgbaColor) => void;
};

const PredefinedColorInput = ({
  color,
  setColor
}: PreDefinedColorInputProps) => {
  const onClick = () => {
    setColor(colord(color).toRgb());
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === keyboardKeys.Enter) {
      onClick();
    }
  };

  return (
    <Col span={4.8}>
      <StyledPredefinedColorInputBox
        color={color}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={0}
        aria-label={`Choose color: ${color}`}
      />
    </Col>
  );
};

const reactColorfulStyle = css`
   {
    width: 100% !important;
    & div {
      border-radius: var(--normal-border-radius);
    }
    & .react-colorful__hue {
      margin: var(--s2) 0;
      border-bottom: none;
    }
  }
`;

const StyledPredefinedColorInputBox = styled.div`
   {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border-style: none;
    cursor: pointer;
    background-color: ${props => props.color};

    &:focus {
      outline: 2px solid var(--colors-brand-400);
      outline-offset: 2px;
    }
  }
`;

const SelectedColorBigBox = styled.div`
   {
    min-width: 72px;
    min-height: 72px;
    border-radius: var(--big-border-radius);
    border-style: none;
    background-color: ${props => props.color};
  }
`;

const hexColorInputFieldStyle = css`
  text-transform: uppercase;
  padding: var(--s2) var(--s4);
  background: var(--neutral-10);
  border-style: none;
  border-radius: var(--big-border-radius);
`;
