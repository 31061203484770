import React from "react";
import { Tooltip } from "antd";
import { useIntl } from "react-intl";

/**
 * Simple wrapper component that creates a Tooltip component
 * with inject-intl
 *
 * @param title Key that needs to be looked for in translation JSON
 * @param values JSON containing keys that needs to be replaced in string
 * @param children React child component(s)
 *
 * @deprecated
 */
export const IntlTooltip = ({
  children,
  title = "",
  values = {},
  ...otherProps
}) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={intl.formatMessage(
        {
          id: title
        },
        values
      )}
      {...otherProps}
    >
      {children}
    </Tooltip>
  );
};
