import React from "react";
import { Tag } from "antd";
import { Timestamp } from "../UTCTimestamp";
import { FormattedMessage } from "react-intl";
import { WorkflowLink } from "./WorkflowLink";

export const ActivityLogEmail = ({ item }) => {
  let toEmail = "";
  const emailKeys = Object.keys(item.details || {}); // it can be to, cc, bcc, or nothing, on older logs

  // See if we have any keys (Changes are there's always one)
  if (emailKeys.length > 0) {
    // pick the first one (and probably the only one, until BE implementation changes and they start grouping them)
    toEmail = item.details[emailKeys[0]];
  }
  return (
    <div className="pd-left-sm">
      {item.object_name ? (
        <span>
          &#8220;{item.object_name}&#8221;{" "}
          {/* Don't render workflow link if workflow is viewed*/}
          {item.action_type !== "viewed" && item.object_type !== "workflow" ? (
            <>
              {` - `}
              {<WorkflowLink item={item} />}
            </>
          ) : null}
        </span>
      ) : (
        ""
      )}
      <br />
      <div className="small text-light">
        {item.action_type ? (
          <FormattedMessage
            id={`activityLog.email.${item.action_type}`}
            defaultMessage={item.action_type}
          />
        ) : (
          item.action_code
        )}
        {/* If the email is available within details, we show it */}
        {toEmail && (
          <>
            {": "}
            <Tag>
              {emailKeys[0]}
              {" : "}
              <a className="text-medium text-base" href={"mailto:" + toEmail}>
                {toEmail}
              </a>
            </Tag>
          </>
        )}
      </div>
      <div className="text-light mr-top-xs">
        <Timestamp timestamp={item.timestamp.$date} />
      </div>
    </div>
  );
};
