import React, { Component } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import to from "await-to-js";
import styled from "@emotion/styled";

import { languageConstants } from "../../../js/constants";
import { IntlLoader } from "../../../translations";
import { getPreferredLocale } from "../selectors";
import { Loader } from "./Loader";

class AsyncIntlProviderComponent extends Component {
  state = {
    intlStrings: null, // to store language strings for currently selected language,
    locale: null
  };

  componentDidMount() {
    // Loads preferred initial language
    this.loadLanguage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.preferredLocale !== prevProps.preferredLocale) {
      // If the locale is different, let's update the language accordingly
      this.loadLanguage();
    }
  }

  /**
   * @def Loads the language strings into the state for the currently selected
   * language
   */
  loadLanguage = async languageOverride => {
    // use the override locale, if specified
    const language = languageOverride || this.props.preferredLocale;
    // Load the language strings async
    const [error, result] = await to(IntlLoader[language]());
    if (error) {
      // Reachable usually when there is a problem in importing the required language
      // due to some reason, like file doesn't exist.

      // Check if we're not using default locale, so we can fall-back to our
      // default locale.
      if (language !== languageConstants.DEFAULT_LOCALE) {
        this.loadLanguage(languageConstants.DEFAULT_LOCALE);
        return;
      } else {
        // Throw an error so user sees the Error page (and can submit feedback, if needed)
        throw Error("Error loading language :" + language);
      }
    }

    this.setState({
      intlStrings: result.default,
      // because we may have shifted to a DEFAULT_LOCALE and hence we can't rely on preferredLocale from props
      locale: language
    });
  };

  render() {
    // Show a loader with no text until the language strings are loaded
    if (!this.state.intlStrings)
      return (
        <CenteredDiv>
          <Loader data-testid="language-loader" />
        </CenteredDiv>
      );

    // When language is loaded, render the actual IntlProvider
    return (
      <IntlProvider
        textComponent="span"
        locale={this.state.locale}
        messages={this.state.intlStrings}
      >
        {/** Since IntlProvider expect only single child */}
        <>{this.props.children}</>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state) {
  const { languageSelector, authentication } = state;
  return {
    languageSelector,
    authentication,
    preferredLocale: getPreferredLocale(state)
  };
}

export const AsyncIntlProvider = connect(mapStateToProps)(
  AsyncIntlProviderComponent
);

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
