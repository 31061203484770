import React from "react";
import type {
  AnswerFilter,
  SelectOption,
  Kind,
  WorkflowFilters,
  OperatorsTypesValues,
  ReportCustomFilter,
  ProcessTypeFilterOption,
  WithinTheLastTimePeriod,
  ReportCustomFilterCondition,
  FilterORM
} from "@certa/types";
import { FieldTypes, CompareType } from "@certa/types";
import type {
  WorkflowFiltersQuery,
  ReportWorkflows,
  ReportOrdering,
  FilterByGroup,
  ReportWorkflowResult
} from "@certa/queries";
import { FormattedMessage } from "react-intl";
import type { FilterProps } from "@certa/table";
import moment from "moment";
import type {
  ORMReportDataType,
  ORMReportFilterConditionOperator
} from "../constants";
import {
  dateFilterTypes,
  defaultReportAnswerQuery,
  INDEX_ONE,
  INDEX_ZERO,
  LAST_INDEX,
  LENGTH_ZERO,
  listFilterTypes,
  numberFilterTypes,
  PROCESS_TYPE_COLUMN_PATH,
  RELATIVE_DATE_VALUE_START_INDEX,
  RELATIVE_DAYS_PREFIX
} from "../constants";
import {
  stepGroupLevelAttributeDetails,
  stepLevelAttributeDetails
} from "../constants/slaConstants";
import { capitalize, mapKeys, cloneDeep } from "lodash-es";
import type { Key } from "antd/lib/table/interface";
import type { ProcessType } from "@certa/tasks/src/types";
import { DEFAULT_DATE_FORMAT } from "@certa/search/src/constants";
import type { FieldMap } from "./fieldHierarchy";
import {
  destructureRelativeTime,
  withinTheLastValidation
} from "./withinTheLastUtils";
import type { TDateRangeValue } from "@certa/catalyst";

const NUMBER_ZERO = 0;
// children of first level are shown in cascader that is why we are using 1
const SWIMLANES_STARTING_INDEX = 1;

const commonLTGTRestrictedTypes = [
  ...listFilterTypes,
  FieldTypes.URL,
  FieldTypes.EMAIL,
  FieldTypes.ARRAY,
  FieldTypes.PHONE,
  FieldTypes.TEXT,
  FieldTypes.CHAR
];

export type OperatorsDetails = Array<{
  label: string | Omit<React.ReactNode, "null">;
  value: OperatorsTypesValues;
  beOperator?: ORMReportFilterConditionOperator;
  disableInput: boolean;
  disAllowedFields: FieldTypes[];
  isForORMOnly?: boolean;
}>;
export const operatorsDetails: OperatorsDetails = [
  {
    label: "=",
    value: "eq",
    disableInput: false,
    disAllowedFields: []
  },
  {
    label: "!=",
    value: "not_eq",
    disableInput: false,
    disAllowedFields: []
  },
  {
    label: "<",
    value: "lt",
    disableInput: false,
    disAllowedFields: [...commonLTGTRestrictedTypes]
  },
  {
    label: "<=",
    value: "lte",
    disableInput: false,
    disAllowedFields: [...commonLTGTRestrictedTypes]
  },
  {
    label: ">",
    value: "gt",
    disableInput: false,
    disAllowedFields: [...commonLTGTRestrictedTypes]
  },
  {
    label: ">=",
    value: "gte",
    disableInput: false,
    disAllowedFields: [...commonLTGTRestrictedTypes]
  },
  {
    label: (
      <FormattedMessage
        id="workflowFiltersTranslated.advancedFilterOperators.is_set"
        defaultMessage="Has value"
      />
    ),
    value: "is_set",
    disableInput: true,
    disAllowedFields: [FieldTypes.RELATIVE_DATE]
  },
  {
    label: (
      <FormattedMessage
        id="workflowFiltersTranslated.advancedFilterOperators.contains"
        defaultMessage="Contains"
      />
    ),
    value: "contains",
    disableInput: false,
    disAllowedFields: [
      FieldTypes.DATE,
      FieldTypes.NAIVE_DATE,
      FieldTypes.RELATIVE_DATE,
      FieldTypes.BOOL
    ]
  },
  {
    label: (
      <FormattedMessage
        id="workflowFiltersTranslated.advancedFilterOperators.not_contains"
        defaultMessage="Does not contain"
      />
    ),
    value: "not_contains",
    disableInput: false,
    disAllowedFields: [
      FieldTypes.DATE,
      FieldTypes.NAIVE_DATE,
      FieldTypes.RELATIVE_DATE,
      FieldTypes.BOOL
    ]
  },
  {
    label: (
      <FormattedMessage
        id="workflowFiltersTranslated.advancedFilterOperators.is_empty"
        defaultMessage="Is Empty"
      />
    ),
    value: "is_empty",
    disableInput: true,
    disAllowedFields: [FieldTypes.RELATIVE_DATE]
  },
  {
    label: (
      <FormattedMessage
        id="workflowFiltersTranslated.advancedFilterOperators.is_between"
        defaultMessage="Is between"
      />
    ),
    value: "range",
    disableInput: false,
    disAllowedFields: [
      ...commonLTGTRestrictedTypes,
      ...numberFilterTypes,
      FieldTypes.RELATIVE_DATE
    ],
    isForORMOnly: true
  },
  {
    label: (
      <FormattedMessage
        id="workflowFiltersTranslated.advancedFilterOperators.is_within_last"
        defaultMessage="Is within last"
      />
    ),
    value: "is_within_last",
    beOperator: "range",
    disableInput: false,
    disAllowedFields: [
      ...commonLTGTRestrictedTypes,
      ...numberFilterTypes,
      FieldTypes.RELATIVE_DATE
    ],
    isForORMOnly: true
  }
];
export const BACKEND_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss.SSSSSS";
export const VALID_DATE_RANGE_VALUE_LENGTH = 2;

const isValidOperatorAndValueForQuery = (
  operator: OperatorsTypesValues,
  value: ReportCustomFilterCondition["value"]
) => {
  const operatorObject = operatorsDetails.find(
    operatorObject => operatorObject.value === operator
  );
  if (!operatorObject) {
    return false;
  }
  if (operator === "is_within_last") {
    const { value: withinTheLast, timePeriod } = destructureRelativeTime(
      value?.[CREATED_RANGE_AFTER_INDEX]
    );
    if (!timePeriod) {
      return false;
    }
    const { isValid } = withinTheLastValidation(withinTheLast, timePeriod);
    if (!isValid) {
      return false;
    }
  }
  // Ignore the filter if the operator required value
  // but the user has not selected it
  if (
    !operatorObject.disableInput &&
    (typeof value === "undefined" ||
      value === "" ||
      (operator === "range" &&
        Array.isArray(value) &&
        value.filter(Boolean).length !== VALID_DATE_RANGE_VALUE_LENGTH))
  ) {
    return false;
  }
  return true;
};
/**
 * NOTE: Does not work on answer strings
 */
// NOTE: Check this
export const isValidAnswerQuery = (query: any) => {
  if (
    !query ||
    !Array.isArray(query?.field?.path) ||
    !query?.field?.path?.filter(Boolean).length ||
    !query?.operator
  ) {
    return false;
  }
  return isValidOperatorAndValueForQuery(query.operator, query.value);
};

/**
 * NOTE: Does not work on answer strings
 */
export const isValidAnswerForAndOrQuery = (answer: any) => {
  if (
    !answer ||
    !answer?.compareType ||
    !(answer.conditions?.length || answer.groups?.length)
  ) {
    return false;
  } else {
    let isSomeGroupValid = false;
    let isSomeConditionValid = false;
    if (answer.groups?.length) {
      isSomeGroupValid = answer.groups.some((group: any) =>
        isValidAnswerForAndOrQuery(group)
      );
    }
    if (answer.conditions?.length) {
      isSomeConditionValid = answer.conditions.some((condition: any) =>
        isValidOperatorAndValueForQuery(condition.operator, condition.value)
      );
    }
    if (!isSomeGroupValid && !isSomeConditionValid) {
      return false;
    }
  }
  return true;
};

const createAnswerQuery = (
  fieldTag: string,
  fieldType: string | null,
  operator: string,
  parsedValue?: string | null
) => {
  const answerArray = [
    fieldTag,
    parsedValue ? fieldType : null,
    operator,
    parsedValue
  ];
  return answerArray.filter(item => item !== null).join("__");
};

export const cleanFilter = (
  filter: ReportCustomFilter | undefined
): ReportCustomFilter | null => {
  if (!filter) {
    return null;
  }

  // Clean conditions at the top level
  const cleanedConditions = filter.conditions?.filter(isValidAnswerQuery) ?? [];

  // Clean conditions inside groups
  const cleanedGroups = filter.groups
    ? (filter.groups
        .map(group => cleanFilter(group))
        .filter(Boolean) as ReportCustomFilter[])
    : [];

  // If there are no valid conditions or groups, return null
  if (
    cleanedConditions.length === LENGTH_ZERO &&
    cleanedGroups.length === LENGTH_ZERO
  ) {
    return null;
  }

  // Return the cleaned filter object
  return {
    ...filter,
    conditions: cleanedConditions,
    groups: cleanedGroups
  };
};

const hasOldRelativeDateFormat = (value: string) => {
  return new RegExp(`^${RELATIVE_DAYS_PREFIX}-?\\d+$`).test(value.trim());
};

export const hasRelativeDate = (value: string, fieldType: string) => {
  return (
    dateFilterTypes.includes(fieldType as FieldTypes) && value?.includes("d")
  );
};

export const replaceOldRelativeDateFormat = (value: string) => {
  return hasOldRelativeDateFormat(value)
    ? value.replace(RELATIVE_DAYS_PREFIX, "") + "d"
    : value;
};

export const transformAnswerFilter = (
  answer?: AnswerFilter | ReportCustomFilter
) => {
  let filterByGroup: FilterByGroup = "false";
  let transformedAnswer = "";
  let transformedJSONAnswer: JSONAnswer | null = null;

  if (Array.isArray(answer) && answer.length) {
    transformedAnswer = createBarSeparatedQuery(answer);
    transformedJSONAnswer = null;
  } else if (isValidAnswerForAndOrQuery(answer)) {
    transformedJSONAnswer = createComplexJSONQuery(
      answer as ReportCustomFilter
    );
    filterByGroup = "true";
  }
  return {
    filterByGroup,
    transformedAnswer,
    transformedJSONAnswer
  };
};

export type JSONCondition = {
  field?: string;
  operator?: string;
  value?: string | Array<string> | null;
  data_type?: string | null;
};

export type JSONAnswer = {
  type?: string;
  operands?: (JSONAnswer | JSONCondition)[];
};

const createComplexJSONQuery = (group: ReportCustomFilter): JSONAnswer => {
  // do not create query for groups whose compareType is null
  if (!group.compareType) return {};

  const answerObj: JSONAnswer = {
    type: group.compareType
  };

  const operands: (JSONAnswer | JSONCondition)[] = group?.conditions
    .filter(isValidAnswerQuery)
    ?.map(condition => {
      const value = condition?.value ?? null;
      const fieldTag = condition.field.path[condition.field.path.length - 1];
      return {
        field: fieldTag,
        operator: condition.operator,
        value,
        data_type: value ? condition.field.fieldType || null : null
      };
    });

  const isConditionsPresent = operands?.length > NUMBER_ZERO;

  const groups = group?.groups
    ?.map(group => createComplexJSONQuery(group))
    ?.filter(answer => Object.keys(answer).length !== NUMBER_ZERO);

  const isValidGroupsPresent = groups?.length > NUMBER_ZERO;

  isValidGroupsPresent && operands.push(...groups);

  if (!isConditionsPresent && !isValidGroupsPresent) return {};

  answerObj.operands = operands;

  return answerObj;
};

const createBarSeparatedQuery = (answer: AnswerFilter) =>
  answer
    .filter(isValidAnswerQuery)
    .map(ans => {
      return createAnswerQuery(
        ans.field.path[ans.field.path.length - 1],
        ans.value ? ans.field?.fieldType || null : null,
        ans.operator,
        ans?.value ?? null
      );
    })
    .join("|");
/**
 * Four possibilities
 * tag__type__op__value
 * tag__type__op
 * tag__op__value
 * tag__op
 */
export const backwardCompatibleAnswerSplit = (
  answer: string
): [string, string | undefined, string, string | undefined] => {
  const afterSplit = answer.split("__");
  // tag__type__op__value
  if (afterSplit.length === 4) {
    return afterSplit as [string, string, string, string];
  }

  // tag__op
  if (afterSplit.length === 2) {
    return [
      afterSplit[INDEX_ZERO],
      undefined,
      afterSplit[INDEX_ONE],
      undefined
    ];
  }

  //  tag__type__op && tag__op__value
  if (afterSplit.length === 3) {
    const [tag, arg1, arg2] = afterSplit;
    const isArg1Operator = operatorsDetails.some(
      operator => operator.value === arg1
    );

    // tag__op__value
    if (isArg1Operator) {
      return [tag, undefined, arg1, arg2];
    }
    // tag__type__op
    else {
      return [tag, arg1, arg2, undefined];
    }
  }

  // Ideally this should never happened
  console.log("backwardCompatibleAnswerSplit", { answer });
  // @ts-expect-error since this case will never occur
  return [];
};

export const transformBEAnswerQueryToFE = (
  answer: string | undefined,
  fieldMapping:
    | Record<string, { path: string[]; fieldType: FieldTypes }>
    | undefined,
  forReport = false
): ReportCustomFilter | AnswerFilter | undefined => {
  let newAnswer = answer;
  if (!newAnswer) {
    return forReport ? defaultReportAnswerQuery : undefined;
  }
  let { isAndOr, hasBar } = getAnswerQueryProperties(newAnswer);
  // if forReport is true then convert old Bar query to new AndOr query
  if (hasBar && forReport) {
    newAnswer = `${CompareType.AND},[,${newAnswer?.replaceAll("|", ",")},]`;
    isAndOr = true;
    hasBar = false;
  }

  return hasBar
    ? transformBEAnswerQueryToFESeparatedByBar(newAnswer, fieldMapping)
    : isAndOr
      ? transformBEAnswerQueryTOFEAndOr(newAnswer, fieldMapping)
      : undefined;
};

export const isFilterAnswerQueryIsAndOr = (answer: string | undefined) => {
  return !!(answer?.includes(",[,") && answer?.includes(",]"));
};

export const getAnswerQueryProperties = (
  answer: string
): { isAndOr: boolean; hasBar: boolean } => {
  const isAndOr = isFilterAnswerQueryIsAndOr(answer);
  const hasBar =
    !isAndOr &&
    answer
      .split("|")
      .every(query => backwardCompatibleAnswerSplit(query).length > 2);
  return { isAndOr, hasBar };
};

export const transformBEAnswerQueryToFESeparatedByBar = (
  answer: string,
  fieldMapping:
    | Record<string, { path: string[]; fieldType: FieldTypes }>
    | undefined
) =>
  answer
    .split("|")
    ?.map(answer => transformStringToCondition(answer, fieldMapping));

const transformStringToCondition = (
  string: string,
  fieldMapping:
    | Record<string, { path: string[]; fieldType: FieldTypes }>
    | undefined
) => {
  const [filedTag, fieldType, operator, value = ""] =
    backwardCompatibleAnswerSplit(string);
  const fieldDetials = fieldMapping?.[filedTag];
  const fieldPath = [
    ...(fieldDetials?.path?.slice(SWIMLANES_STARTING_INDEX) || []),
    filedTag
  ];
  const newValue = replaceOldRelativeDateFormat(value);
  // search WHEN_FIELD_TYPE_IS_NULL, to know why this is done
  const finalFieldType = (fieldType ??
    fieldDetials?.fieldType ??
    FieldTypes.TEXT) as FieldTypes;
  return {
    field: {
      path: fieldPath,
      fieldType: finalFieldType
    },
    operator: operator as OperatorsTypesValues,
    value: newValue,
    useRelativeDates: hasRelativeDate(newValue, finalFieldType)
  };
};

type QueryNodeType = ReportCustomFilter;
const QueryNode = ({
  compareType = CompareType.OR,
  conditions = [],
  groups = []
}: Partial<QueryNodeType>) => ({
  groups,
  conditions,
  compareType
});

const transformBEAnswerQueryTOFEAndOr = (
  string: string,
  fieldMapping:
    | Record<string, { path: string[]; fieldType: FieldTypes }>
    | undefined
) => {
  // Split the string into an array of tokens
  const data: string[] = string.split(",");

  // Create root Node
  let node = QueryNode({
    compareType: data[0] as CompareType
  });

  if (data.length === 1) {
    const conditions = [transformStringToCondition(data[0], fieldMapping)];
    node = QueryNode({ conditions });
  }

  const deserialize = (
    data: string[],
    index: number[],
    node: QueryNodeType
  ) => {
    const total = data.length;
    while (index[0] < total) {
      if (data[index[0]] === "]") {
        // Child path covered increment the reference/data index val
        index[0]++;
        return;
      } else if (data[index[0]] === "[") {
        // Child-node creations starts after this index
        index[0]++;
      } else if (
        [CompareType.AND, CompareType.OR].includes(
          data[index[0]] as CompareType
        )
      ) {
        // Create child-main node
        const child = QueryNode({
          compareType: data[index[0]] as CompareType
        });
        node.groups.push(child);
        index[0]++;
        // Recursive create child-nodes
        deserialize(data, index, child);
      } else {
        // deserialize query value from str to Dict
        // field_tag__op__answer -> {"field": "some tag", "operator": "eq", "compare_value": "some value"}
        node.conditions.push(
          transformStringToCondition(data[index[0]], fieldMapping)
        );
        index[0]++;
      }
    }
  };
  // [1] array used (by-reference) here to keep track of last data covered in recursive order
  deserialize(data, [1], node);
  return node;
};

export const transformBEJSONAnswerQueryToFE = (
  answer: JSONAnswer | JSONCondition,
  fieldMapping: FieldMap | undefined
) => {
  const { type, operands } = answer as JSONAnswer;
  const output: ReportCustomFilter = {
    compareType: type as CompareType,
    conditions: [],
    groups: []
  };
  operands?.forEach(operand => {
    if ((operand as JSONAnswer).type) {
      output?.groups?.push(
        transformBEJSONAnswerQueryToFE(operand, fieldMapping)
      );
    } else {
      const fieldTag = (operand as JSONCondition).field as string;
      const fieldDetials = fieldMapping?.[fieldTag];
      const fieldPath = [
        ...(fieldTag
          ? fieldDetials?.path?.slice(SWIMLANES_STARTING_INDEX) || []
          : []),
        fieldTag
      ];
      let newValue = ((operand as JSONCondition).value as string) || "";
      newValue = replaceOldRelativeDateFormat(newValue);
      // WHEN_FIELD_TYPE_IS_NULL
      // operators for has value and is empty Field type must be null,
      // so we are saving null for these operators in the field type field,
      // however when we edit report again, we require the field type for other operators.
      const fieldType = ((operand as JSONCondition).data_type ??
        fieldDetials?.fieldType ??
        FieldTypes.TEXT) as FieldTypes;
      output?.conditions?.push({
        field: {
          path: fieldPath,
          fieldType
        },
        operator: (operand as JSONCondition).operator as OperatorsTypesValues,
        value: newValue,
        useRelativeDates: !!fieldType && hasRelativeDate(newValue, fieldType)
      });
    }
  });
  return output;
};

export const filterLabelValueSeparator = "~~";

export const getFilterLabel = (filter: string) =>
  filter.split(filterLabelValueSeparator)[INDEX_ZERO];

export const getFilterValue = (filter: string) =>
  filter.split(filterLabelValueSeparator)[INDEX_ONE];

export const getProcessTypeFilterOption = (
  processType: ProcessTypeFilterOption
) => {
  /**
   * processType can be split using the same filterLabelValueSeparator,
   * to get the name on the 0th index and id on the 1st index.
   * This is done by getFilterLabel and getFilterValue functions respectively.
   */
  return `${processType.name + filterLabelValueSeparator + processType.id}`;
};

export const getLabelValueCombinedString = (option: SelectOption) =>
  `${option.label}${filterLabelValueSeparator}${option.value}`;

//TODO: Add support to return array of string values if needed
export const getSeparatedFilterValues = (values: string[]) =>
  values
    .map(combinedValues => combinedValues.split(filterLabelValueSeparator))
    .map(separatedValues => separatedValues[1]);

export const getDisplayDateRange = (start: string, end: string) =>
  start && end
    ? `${moment.utc(start).local().format(DEFAULT_DATE_FORMAT)} - ${moment
        .utc(end)
        .local()
        .format(DEFAULT_DATE_FORMAT)}`
    : start
      ? `> ${moment.utc(start).local().format(DEFAULT_DATE_FORMAT)}`
      : end && `< ${moment.utc(end).local().format(DEFAULT_DATE_FORMAT)}`;

/**
 * @param  {string} pipeStr
 */
export const getAnswersListFromPipeStr = (pipeStr: string) =>
  pipeStr.split("|");

/**
 * @param  {string|undefined} kindField
 * @param  {string|undefined} transformedAnswerData
 */
export const concatAdvFilter = (...arg: (string | undefined)[]) =>
  arg.filter(item => !!item).join("|");

// Formats field tag to human readable
export const getReadableFieldTag = (fieldTag: string) =>
  capitalize(fieldTag.replace(/[_-]/g, " "));

/**
 * Remove keys with value either undefined or <empty string>
 * NOTE: Empty means undefined or <empty string>
 */
const removeEmptyValues = <T extends Record<string, any>>(obj: T) => {
  return Object.keys(obj)
    .filter(key => ![undefined, ""].includes(obj[key]))
    .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {} as Partial<T>);
};

export const CREATED_RANGE_AFTER_INDEX = 0;
export const CREATED_RANGE_BEFORE_INDEX = 1;

// While sending filters in the BE there are two scenarios
// 1. Saving filters in a report field answer (Report creation or edition)
// 2. Filtering processes
// The only case where it returns `null` is
// when the FE filters have invalid `KIND_TAG`
export const transformFEQueryToBEQuery = (
  values: Partial<WorkflowFilters>,
  kinds: Kind[],
  processId?: number
): WorkflowFiltersQuery | null => {
  const {
    ANSWER: answer,
    DATE_RANGE: dateRange,
    KIND_TAG: kindTag,
    RELATIVE_DATE_RANGE: relativeDateRange,
    DATE_FILTER_TYPE: dateFilterType,
    ...restValues
  } = values;

  const kindId = processId ?? kinds.find(kind => kind.tag === kindTag)?.id;

  if (!kindId) {
    console.log(
      `Invalid kind tag provided in the values: ${JSON.stringify(
        values
      )}, ${kindId}, ${kindTag}`
    );
    return null;
  }

  const params: Partial<WorkflowFiltersQuery> = {
    q: restValues.SEARCH,
    business_unit__in: restValues.BUSINESS_UNIT?.join(","),
    region__in: restValues.REGION?.join(","),
    status__in: restValues.STATUS?.join(",")
  };

  // Answer filter transformations
  const { transformedAnswer, filterByGroup, transformedJSONAnswer } =
    transformAnswerFilter(answer);

  if (transformedJSONAnswer) {
    params.filter_by_group = filterByGroup;
    params.json_answer = transformedJSONAnswer;
  } else if (transformedAnswer) {
    params.filter_by_group = filterByGroup;
    params.answer = transformedAnswer;
  }

  params.date_filter_type = dateFilterType;
  // Date filter transformations
  if (dateFilterType === "betweenDate" && dateRange) {
    if (dateRange[CREATED_RANGE_BEFORE_INDEX]) {
      params.created_range_before = moment(
        dateRange[CREATED_RANGE_BEFORE_INDEX]
      )
        .startOf("day")
        .utc()
        .format(BACKEND_DATE_TIME_FORMAT);
    }
    if (dateRange[CREATED_RANGE_AFTER_INDEX]) {
      params.created_range_after = moment(dateRange[CREATED_RANGE_AFTER_INDEX])
        .startOf("day")
        .utc()
        .format(BACKEND_DATE_TIME_FORMAT);
    }
  } else if (
    dateFilterType !== "betweenDate" &&
    relativeDateRange?.[CREATED_RANGE_AFTER_INDEX] &&
    relativeDateRange?.[CREATED_RANGE_BEFORE_INDEX]
  ) {
    const relativeDateRangeValue =
      prepareRelativeDateRangeValues(relativeDateRange);

    if (relativeDateRangeValue) {
      params.created_range_before =
        relativeDateRangeValue[CREATED_RANGE_BEFORE_INDEX];
      params.created_range_after =
        relativeDateRangeValue[CREATED_RANGE_AFTER_INDEX];
    } else {
      params.created_range_before = undefined;
      params.created_range_after = undefined;
    }
  } else {
    params.created_range_before = undefined;
    params.created_range_after = undefined;
  }
  return {
    ...removeEmptyValues(params),
    kind_id: kindId
  };
};

export const prepareRelativeDateRangeValues = (
  relativeDateRange: [string | null, string | null]
) => {
  // To learn how it will be decoded, search CREATED_RANGE_WITH_RELATIVE_DATE.
  const createdRangeAfter = relativeDateRange[CREATED_RANGE_AFTER_INDEX];
  const createdRangeBefore = relativeDateRange[CREATED_RANGE_BEFORE_INDEX];
  const isHoursOrMinutes = new RegExp(/(m|h)/).test(createdRangeAfter ?? "");

  const withinTheLast = Number(
    createdRangeAfter?.slice(RELATIVE_DATE_VALUE_START_INDEX, LAST_INDEX)
  );
  const timePeriod = createdRangeAfter?.slice(
    LAST_INDEX
  ) as WithinTheLastTimePeriod;
  const { isValid } = withinTheLastValidation(withinTheLast, timePeriod);
  if (isValid) {
    return [
      createdRangeAfter + (isHoursOrMinutes ? "" : "L"),
      createdRangeBefore + (isHoursOrMinutes ? "" : "U")
    ];
  } else {
    return undefined;
  }
};

export const prepareDateRangeValues = (value: TDateRangeValue) => {
  const dateRange = {
    createdRangeAfter: value?.[CREATED_RANGE_AFTER_INDEX]?.startOf("day")
      .utc()
      .format(BACKEND_DATE_TIME_FORMAT),
    createdRangeBefore: value?.[CREATED_RANGE_BEFORE_INDEX]?.startOf("day")
      .utc()
      .format(BACKEND_DATE_TIME_FORMAT)
  };
  return dateRange;
};

export const MATCH_TAG_IN_COLUMN_PATH = /fields\.(.+)/;

export const getSortPayload = (
  columnPath: string[],
  order: "ascend" | "descend" | null,
  workflow: ReportWorkflows["results"][0]
): ReportOrdering | null => {
  if (!order) {
    return null;
  }
  const sortOrder = order === "ascend" ? "asc" : "desc";
  if (columnPath[0] === "name") {
    return {
      col_type: "attr",
      col: "name",
      sort_order: sortOrder
    };
  }

  if (columnPath[0] === "status") {
    return {
      col_type: "attr",
      col: "status",
      sort_order: sortOrder
    };
  }

  if (columnPath[0] === PROCESS_TYPE_COLUMN_PATH) {
    return {
      col_type: "attr",
      col: "kind_name",
      sort_order: sortOrder
    };
  }

  if (MATCH_TAG_IN_COLUMN_PATH.test(columnPath.join("."))) {
    const fieldTag = columnPath[1];
    const fieldType = workflow?.fields?.[fieldTag]?.field_type;
    const sortFieldType = getSortFieldType(fieldType);
    return {
      col_type: "field",
      col: fieldTag,
      sort_order: sortOrder,
      field_data_type: sortFieldType
    };
  } else if (columnPath[0] === "steps" || columnPath[0] === "stepGroups") {
    const stepTag = columnPath?.[1];
    const isForStepGroup = columnPath[0] === "stepGroups";
    const path = columnPath.join(".").replace(stepTag, "steps_tag");
    let fieldAttributeDatails;
    if (isForStepGroup) {
      fieldAttributeDatails = Object.values(
        stepGroupLevelAttributeDetails
      ).find(details => details.path === path);
    } else {
      fieldAttributeDatails = Object.values(stepLevelAttributeDetails).find(
        details => details.path === path
      );
    }
    const { sortCol, systemFieldType } = fieldAttributeDatails ?? {};
    if (!sortCol || !systemFieldType) return null;
    return {
      col_type: isForStepGroup ? "stepgroup" : "step",
      col: sortCol,
      sort_order: sortOrder,
      tag: stepTag,
      field_data_type: getSortFieldType(systemFieldType)
    };
  }

  return null;
};

const getSortFieldType = (fieldType: FieldTypes) => {
  switch (fieldType) {
    case FieldTypes.DATE:
      return "datetime";
    case FieldTypes.NAIVE_DATE:
      return "date";
    case FieldTypes.INTEGER:
    case FieldTypes.DECIMAL:
    case FieldTypes.CURRENCY:
      return "float";
    default:
      return "str";
  }
};

/**
 * modifies keys from path to tag name
 */
export function replaceKeyPathsWithFieldTags(
  filters: FilterProps
): FilterProps {
  return mapKeys(filters, (value: any, key: string) => {
    let accessor = key;
    if (key === "status.displayName") {
      return "status";
    }
    if (key === PROCESS_TYPE_COLUMN_PATH) {
      return PROCESS_TYPE_COLUMN_PATH;
    }
    if (MATCH_TAG_IN_COLUMN_PATH.test(key)) {
      accessor = getFieldTagFromPath(key);
    }

    return accessor;
  });
}

export function getFilterListTag(path?: string) {
  if (!path) {
    return undefined;
  }
  return getFieldTagFromPath(path);
}

/**
 * extracts tag name from path eg fields.dun_number.answer --> dun_number
 */
export function getFieldTagFromPath(path: string): string {
  if (path === "status.displayName") {
    return "status";
  }
  return path.replace(MATCH_TAG_IN_COLUMN_PATH, "$1");
}

const getOperatorFromFieldType = (fieldType: FieldTypes) => {
  if (listFilterTypes.includes(fieldType)) {
    return "in";
  } else if (
    numberFilterTypes.includes(fieldType) ||
    dateFilterTypes.includes(fieldType)
  ) {
    return "eq";
  }
  return "contains";
};

export const getAdvQueryFilterList = (
  formattedFilterObj: FilterProps,
  workflowItem: ReportWorkflowResult | null,
  fieldMapping?: FieldMap | undefined,
  isJsonQuery = false
) => {
  return Object.keys(formattedFilterObj).reduce(
    (accumulator: (string | JSONCondition)[], itemKey) => {
      const fieldType =
        (workflowItem
          ? workflowItem?.fields?.[itemKey]?.field_type
          : fieldMapping?.[itemKey]?.fieldType) ?? FieldTypes.TEXT;
      // api expects field values as string
      // hence values to string
      const fieldValuesList = formattedFilterObj[itemKey]?.map(item =>
        item.toString()
      );
      const operator = getOperatorFromFieldType(fieldType);
      const dataType = getDataType(fieldType);
      const fieldTag = itemKey === "status.displayName" ? "status" : itemKey;
      if (fieldValuesList) {
        accumulator.push(
          isJsonQuery
            ? {
                field: fieldTag,
                operator,
                value:
                  fieldValuesList.length === 1 && operator !== "in"
                    ? fieldValuesList[NUMBER_ZERO]
                    : fieldValuesList,
                data_type: dataType
              }
            : `${fieldTag}__${dataType}__${operator}__${fieldValuesList.join(
                ","
              )}`
        );
      }
      return accumulator;
    },
    []
  );
};

export const getDataType = (
  fieldType: FieldTypes | undefined,
  useRelativeDates = false
): ORMReportDataType => {
  if (fieldType && numberFilterTypes.includes(fieldType)) {
    // for integer type fields also using decimal as data type,
    // as it has bigger range
    return FieldTypes.DECIMAL;
  } else if (fieldType && dateFilterTypes.includes(fieldType)) {
    return useRelativeDates ? FieldTypes.RELATIVE_DATE : FieldTypes.DATE;
  } else if (FieldTypes.BOOL === fieldType) {
    return FieldTypes.BOOL;
  }
  return FieldTypes.TEXT;
};

/**
 * Prepares filter query @object from old filter @object ,name and statusList
 */
export function getBasicFiltersQuery({
  nameList,
  statusList,
  processTypeList
}: {
  nameList: Key[] | null;
  statusList: Key[] | null;
  processTypeList?: Key[] | null;
}) {
  const workflowNameParams =
    nameList && nameList[0] ? { q: nameList[0] as string } : {};

  // Prioritize the filter from the column search/filter box
  //
  // No need to merge since the statuses selected from the box
  // are the filters that user want to apply
  //
  // Merging status filter won't give the correct result
  // Consider the following example
  // Ex: Filters from Report = status__in=1,2
  // Filters from column search/filter box = status__in=1
  // After merging status__in=1,2 => NOT WHAT THE USER WANTS
  //
  // If there are no status selected, use the status from report
  // else no status filter
  const statusFilterStr = statusList?.filter(item => !!item).join(",");
  const workflowStatusParams = statusFilterStr
    ? { status__in: statusFilterStr }
    : {};

  // If there are no process type selected, use the process type from report
  // else no process type filter
  const processTypeFilterStr = processTypeList
    ?.filter(item => !!item)
    .join(",");
  const workflowProcessTypeParams = processTypeFilterStr
    ? { kind_id__in: processTypeFilterStr }
    : {};

  return {
    ...workflowNameParams,
    ...workflowStatusParams,
    ...workflowProcessTypeParams
  };
}

// This util takes adhocFilters and persistentFilters from reportsTable and
// Finds if there are similarities between adhocFilters and persistentFilters then create a
// single mergedQueries to use in useGetReportWorkflows which will now use
// a single source of truth if exists

export function getCustomCombinedQuery(
  adhocFilters: WorkflowFiltersQuery | null,
  persistentFilters: WorkflowFiltersQuery | null
) {
  const mergedQueries = cloneDeep(persistentFilters) || {};

  if (adhocFilters?.q) {
    mergedQueries.q = adhocFilters.q;
  }
  if (adhocFilters?.status__in) {
    mergedQueries.status__in = adhocFilters.status__in;
  }
  if (adhocFilters?.kind_id__in) {
    mergedQueries.kind_id__in = adhocFilters.kind_id__in;
  }
  // column filters
  if (adhocFilters?.json_answer?.operands) {
    if (mergedQueries.json_answer?.operands) {
      if (mergedQueries.json_answer.type === "AND")
        mergedQueries.json_answer.operands?.push(
          ...adhocFilters.json_answer.operands
        );
      else {
        // Wrap AND query around persistentFilters's OR query
        mergedQueries.json_answer = {
          type: "AND",
          operands: [
            mergedQueries.json_answer,
            ...adhocFilters.json_answer.operands
          ]
        };
      }
    } else mergedQueries.json_answer = adhocFilters?.json_answer;
    mergedQueries.filter_by_group = "true";
  }
  return mergedQueries;
}

export const getCustomCombinedQueryORM = (
  adhocFilters: FilterORM | undefined,
  persistentFilters: FilterORM | undefined
) => {
  if (persistentFilters) {
    let newPersistentFilters = persistentFilters;
    if (adhocFilters?.conditions?.length) {
      newPersistentFilters = {
        type: persistentFilters.type,
        conditions: [...persistentFilters.conditions, adhocFilters]
      };
    }
    return newPersistentFilters;
  }
  return adhocFilters;
};

export const getAvailableStatuesFromKinds = ({
  kinds,
  statues,
  kindID
}: {
  kinds: Kind[] | undefined;
  statues: SelectOption[] | undefined;
  kindID: ProcessType;
}) => {
  if (!kinds || !statues || !kindID) return [];
  if (kindID === "all") return statues;
  const kind = kinds.find(kinds => String(kinds.id) === kindID);
  return kind
    ? statues.filter((status: any) =>
        kind.availableStatuses.includes(status.value)
      )
    : [];
};

// This function Extracts the value of tag,operator, and the value from the answer query. This is required because query have formats depending on the type of the filter.
export const splitAnswerQuery = (answerQuery: string) => {
  const splitQuery = answerQuery.split("__");
  let [fieldTag, , operator, filterKey] = answerQuery.split("__");
  if (splitQuery.length === 3) {
    [fieldTag, operator, filterKey] = answerQuery.split("__");
  }

  return { fieldTag, operator, filterKey };
};
