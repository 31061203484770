import React from "react";
import { OverlayType, useOverlay } from "@certa/blocks";
import { TaskStepgroupOverlay } from "../components/TaskStepGroupOverlay";
import { css } from "emotion";

const TASK_STEPGROUP_OVERLAY = "TASK_STEPGROUP_OVERLAY";

export const useProcessTaskLaneOverlay = (processId: number) => {
  const [showOverlay, hideOverlay] = useOverlay(
    TASK_STEPGROUP_OVERLAY,
    <TaskStepgroupOverlay
      processId={processId}
      hideOverlay={() => hideOverlay()}
    />,
    {
      type: OverlayType.MODAL,
      footer: null,
      width: "85%",
      centered: true,
      wrapClassName: css`
        display: flex;
        align-items: center;
        .ant-modal-close {
          margin-top: 6px !important;
          margin-right: -6px;
        }
        .ant-modal-content {
          padding: 0 !important;
        }
      `,
      className: css`
        min-width: 1100px;
      `
    }
  );

  return [showOverlay];
};
