const MAX_ITEMS_IN_RECENTLY_VIEWED = 50;

export const updateRecentlyViewedState = <T>(
  prevState: Record<string, T[]> | undefined,
  userId: string,
  visitedWorklowId: T
) => {
  const currentWorkflows = prevState?.[userId] ? [...prevState[userId]] : [];

  const visitedWorklowIdIndex = currentWorkflows.indexOf(visitedWorklowId);

  // Removed all occurances of already existing workflowIds
  if (visitedWorklowIdIndex > -1) {
    for (let i = 0; i <= currentWorkflows.length - 1; i++) {
      if (currentWorkflows[i] === visitedWorklowId) {
        currentWorkflows.splice(i, 1);
      }
    }
  }

  // Append the workflowId to the start of the list.
  currentWorkflows.unshift(visitedWorklowId);

  // Makes sure the length of the array is always limited to max limit
  if (currentWorkflows.length > MAX_ITEMS_IN_RECENTLY_VIEWED) {
    currentWorkflows.length = MAX_ITEMS_IN_RECENTLY_VIEWED;
  }

  return {
    ...prevState,
    [userId]: currentWorkflows
  };
};
