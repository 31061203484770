import type { UseCascaderSearchConfig } from "@certa/catalyst";
import {
  TypographyColors,
  TypographyVariants,
  classNames,
  Typography
} from "@certa/catalyst";
import Highlighter from "react-highlight-words";
import { Arrow } from "@certa/icons";
import { removeHTMLTagsAndTruncate } from "../..";
import { css } from "emotion";

export const useCascaderSearchConfig = ({
  showSearch = false,
  filter,
  render,
  sort,
  renderLabel
}: UseCascaderSearchConfig) => {
  if (!showSearch) return false;

  const defaultFilter = (inputValue: string, path: any) => {
    return path.some(
      (option: any) =>
        option?.label?.toLowerCase?.().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  const defaultRender = (inputValue: string, path: any) => {
    return path.map((option: any, index: number) => {
      const label = option?.label;
      const node =
        label?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ? (
          <Highlighter
            highlightClassName={cascaderHighlightSearchText}
            searchWords={[inputValue]}
            autoEscape={true}
            textToHighlight={label}
          />
        ) : (
          label
        );

      return [
        <Arrow
          key="arrow-icon"
          color="neutral-70"
          className={classNames({
            [cascaderArrowIcon]: true,
            [hideArrow]: index === 0
          })}
        />,
        <Typography
          key={label}
          color={
            index > 1
              ? TypographyColors.NEUTRAL_800
              : TypographyColors.NEUTRAL_700
          }
          variant={TypographyVariants.LABEL_SM}
          as="span"
        >
          {renderLabel
            ? renderLabel(option, node, index)
            : typeof node === "string"
              ? removeHTMLTagsAndTruncate(node)
              : node}
        </Typography>
      ];
    });
  };

  return {
    filter: filter ? filter : defaultFilter,
    render: render ? render : defaultRender,
    sort
  };
};

const cascaderHighlightSearchText = css({
  color: "var(--colors-red-500)",
  backgroundColor: "var(--colors-neutral-100)",
  padding: 0
});

const cascaderArrowIcon = css({
  margin: "0 var(--s2)"
});

const hideArrow = css({
  display: "none !important"
});
