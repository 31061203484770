import type { FC } from "react";
import React from "react";
import styled from "@emotion/styled";
import type { ButtonProps } from "./Button/";
import { Button } from "./Button/";

export type ClickableProps = Omit<
  ButtonProps,
  "type" | "size" | "destruct" | "disclosure" | "icon" | "iconOnly" | "loading"
>;

const CustomLinkButton: FC<ClickableProps> = props => {
  const { children, ...otherProps } = props;
  return (
    <Button {...otherProps} type="link">
      {children}
    </Button>
  );
};

/**
 * Basically a link Button without height, padding and border which can be wrapped around any component.
 */
const Clickable = styled(CustomLinkButton)`
  &&.ant-btn {
    height: auto;
    padding: 0;
    border: none;
    border-radius: 0;
    line-height: inherit;
  }
  --label: Clickable;
`;

export { Clickable };
