import type { FC } from "react";
import React from "react";
import type { ActionType } from "@certa/blocks/thanos";
import { TimelineItem, Text } from "@certa/blocks/thanos";

type ActivityLogItemChangeProps = {
  event: string;
  actionType: ActionType;
};
export const ActivityLogItemChange: FC<ActivityLogItemChangeProps> = props => {
  const { event, actionType } = props;
  return (
    <TimelineItem actionType={actionType} key={event}>
      <Text variant="p1-bold" color="neutral-100">
        {event}
      </Text>
    </TimelineItem>
  );
};
