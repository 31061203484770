import { Dialog } from "@certa/catalyst";
import { useIntl } from "react-intl";
import { MySubscriptionsIframe } from "../../Header/components/UserProfile/components/MySubscriptions";

type MySubscriptionsProps = {
  showMySubscriptionsDialog: boolean;
  setShowMySubscriptionsDialog: (value: boolean) => void;
};

const MY_SUBSCRIPTIONS_MODAL_WIDTH = "80rem";

const MySubscriptions = ({
  showMySubscriptionsDialog,
  setShowMySubscriptionsDialog
}: MySubscriptionsProps) => {
  const intl = useIntl();

  return (
    <Dialog
      onClose={() => {
        setShowMySubscriptionsDialog(false);
      }}
      noContentStyling
      preventCloseOnClickOutside={false}
      width={`min(90%, ${MY_SUBSCRIPTIONS_MODAL_WIDTH})`}
      show={showMySubscriptionsDialog}
      maxHeight="480px"
      title={intl.formatMessage({
        id: "report.mySubscriptions",
        defaultMessage: "My Scheduled Reports"
      })}
    >
      <MySubscriptionsIframe />
    </Dialog>
  );
};

export { MySubscriptions };
