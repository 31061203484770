export const DOWNLOAD_FILE_ERRORS = {
  404: {
    FIELD: {
      ERROR: "Field with the provided field tag and workflow doesn't exist.",
      MESSAGE:
        "Invalid workflow or you do not have permissions to access the file"
    },
    FILED_TAG: {
      ERROR: "Response with provided field tag doesn't exist.",
      MESSAGE: "No attachment data found to download"
    },
    ATTACHMENT: {
      ERROR:
        "Provided field tag doesn't have any attachment associated with uid: ",
      MESSAGE: "No attachment data found to download"
    }
  },
  400: {
    FIELD_TYPE: {
      ERROR: "UID is required when fetching file from multi-file field.",
      MESSAGE: "UID missing. Please contact your administrator or support team"
    },
    ATTACHMENT: {
      ERROR: "Provided field tag doesn't have any attachment.",
      MESSAGE: "No attachment data found to download"
    }
  }
};
