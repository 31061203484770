import type { FC } from "react";
import React, { useState } from "react";
import { Avatar } from "../../componentsTh/Avatar";
import { Stack } from "../../componentsTh/Stack";
import { css } from "emotion";
import type { Avatar as AvatarType } from "./types";
import type { SelectOption } from "@certa/types";

const AvatarStack: FC<{
  avatars: AvatarType[];
  avatarWrapper?: FC;
  extraAvatarWrapper?: FC;
  extraAvatarWrapperProps: { assignedTo: SelectOption[] };
}> = ({
  avatars,
  avatarWrapper,
  extraAvatarWrapper,
  extraAvatarWrapperProps
}) => {
  const [hovered, setHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  /* Using this to avoid flickering of state when we change 
         hover to different avatar, where the hover goes outside 
         the container stack and the onMouseEnter timeout is called.
      */
  const [showTooltipTimeout, setShowTooltipTimeout] =
    useState<NodeJS.Timeout>();
  const ToolTipWrapper = extraAvatarWrapper || Stack;

  const AvatarWrapper = avatarWrapper && showTooltip ? avatarWrapper : Stack;
  const moreAvatarsLabel = `+${avatars.length - 2}`;

  const onFocus = () => {
    if (showTooltipTimeout) {
      clearTimeout(showTooltipTimeout);
      setShowTooltipTimeout(undefined);
    }
    setShowTooltip(true);
  };

  const onBlur = () => {
    setShowTooltipTimeout(
      setTimeout(() => {
        setShowTooltip(false);
      }, 300)
    );
  };

  return !!avatars.length ? (
    <Stack
      className={css`
        width: ${Math.min(avatars.length, 3) * 12 + 12}px;
      `}
    >
      <Stack
        onMouseEnter={() => {
          setHovered(true);
          if (showTooltipTimeout) {
            clearTimeout(showTooltipTimeout);
            setShowTooltipTimeout(undefined);
          }
          setTimeout(() => {
            setShowTooltip(true);
          }, 300);
        }}
        onMouseLeave={() => {
          setHovered(false);
          setShowTooltipTimeout(
            setTimeout(() => {
              setShowTooltip(false);
            }, 300)
          );
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        className={css`
          position: relative;
          height: 24px;
          transform: translate(${Math.min(avatars.length, 3) * 12 - 12}px, 0px);
        `}
        align="center"
      >
        {avatars.slice(0, 2).map((avatar, index) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <Stack
              onMouseEnter={() => setHovered(true)}
              className={css`
                position: absolute;
                left: 0px;
                transform: translate(-${(hovered ? 26 : 12) * index}px, 0px);
                // High transition time to not to have fluctuation while moving cursor over the expanded avatars
                transition: 0.3s all ease;
                border: ${!hovered && "2px solid white"};
                border-radius: 50%;
              `}
            >
              <AvatarWrapper {...avatar.props}>
                <div
                  tabIndex={0}
                  aria-label={avatar.letters}
                  className={avatarWrapperClassName}
                >
                  <Avatar
                    backgroundColor={
                      avatar.secondary ? "var(--brand)" : "var(--neutral-20)"
                    }
                    color={avatar.secondary ? "" : "var(--neutral-100)"}
                    alt="task-assigned-logo"
                    size="small"
                    isBold={true}
                  >
                    {avatar.letters}
                  </Avatar>
                </div>
              </AvatarWrapper>
            </Stack>
          );
        })}
        {avatars?.length > 2 && (
          <Stack
            onMouseEnter={() => setHovered(true)}
            className={css`
              position: absolute;
              left: 0px;
              transform: translate(-${(hovered ? 26 : 12) * 2}px, 0px);
              // High transition time to not to have fluctuation while moving cursor over the expanded avatars
              transition: 0.3s all ease;
              border: ${!hovered && "2px solid white"};
              border-radius: 50%;
            `}
          >
            <ToolTipWrapper {...extraAvatarWrapperProps}>
              <div
                tabIndex={0}
                aria-label={moreAvatarsLabel}
                className={avatarWrapperClassName}
              >
                <Avatar
                  backgroundColor={"var(--neutral-20)"}
                  color={"var(--neutral-100)"}
                  alt="task-assigned-logo"
                  size="small"
                  isBold={true}
                >
                  {moreAvatarsLabel}
                </Avatar>
              </div>
            </ToolTipWrapper>
          </Stack>
        )}
      </Stack>
    </Stack>
  ) : null;
};

export default AvatarStack;

const avatarWrapperClassName = css`
  &:focus {
    outline: 2px solid var(--colors-brand-400);
    outline-offset: 2px;
    border-radius: 50%;
  }
`;
