import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";
import { getIntlBody } from "main/src/js/_helpers/intl-helpers";

export const useProcessDetailsConfig = () => {
  const processDetailsConfig = useSelector((state: ReduxState) =>
    lodashGet(state, "config.custom_ui_labels.process_details", null)
  );

  return {
    myTaskCompletedMessage:
      (processDetailsConfig &&
        getIntlBody(processDetailsConfig, "my_task_completed_message")) ||
      null,
    myGroupsCompletedMessage:
      (processDetailsConfig &&
        getIntlBody(processDetailsConfig, "my_group_completed_message")) ||
      null,
    allTaskCompletedMessage:
      (processDetailsConfig &&
        getIntlBody(processDetailsConfig, "all_task_completed_message")) ||
      null,
    showMyTaskCompletedMessage:
      processDetailsConfig &&
      processDetailsConfig.show_my_task_completed !== undefined
        ? processDetailsConfig.show_my_task_completed
        : true,
    showMyGroupsCompletedMessage:
      processDetailsConfig &&
      processDetailsConfig.show_my_group_completed !== undefined
        ? processDetailsConfig.show_my_group_completed
        : true,
    showAllTaskCompletedMessage:
      processDetailsConfig &&
      processDetailsConfig.show_all_task_completed !== undefined
        ? processDetailsConfig.show_all_task_completed
        : true
  };
};
