import { css } from "emotion";

const currentUserCSS = `border-radius: var(--s1);
background-color: var(--orange-40) !important;
color: #d78703;
padding: 2px var(--s2);`;

const otherUserCSS = `color: var(--neutral-100);`;

export const getUserMentionTag = (isCurrentUser: boolean, display: string) =>
  `<span class="${css`
    ${isCurrentUser ? currentUserCSS : otherUserCSS}
  `}">@${display}</span>`;
