/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import React from "react";
import { getCSSToken } from "../utils";

type ReelProps = {
  height?: string;
  trackColor?: string;
  thumbColor?: string;
  space: string;
  itemWidth?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Reel = React.forwardRef<HTMLDivElement, ReelProps>((props, ref) => {
  const {
    height,
    thumbColor,
    trackColor,
    children,
    space,
    itemWidth,
    ...htmlProps
  } = props;
  return (
    <div
      {...htmlProps}
      ref={ref}
      css={[
        css`
          display: flex;
          height: ${height};
          overflow-x: auto;
          overflow-y: hidden;
          scrollbar-color: ${thumbColor} ${trackColor};

          &::-webkit-scrollbar {
            height: ${getCSSToken(space)};
          }

          &::-webkit-scrollbar-track {
            background-color: ${trackColor};
          }

          &::-webkit-scrollbar-thumb {
            background-color: ${trackColor};
            background-image: linear-gradient(
              ${trackColor} 0,
              ${trackColor} 0.25rem,
              ${thumbColor} 0.25rem,
              ${thumbColor} 0.75rem,
              ${trackColor} 0.75rem
            );
          }

          & > * {
            flex: 0 0 ${itemWidth};
          }

          & > img {
            height: 100%;
            flex-basis: auto;
            width: auto;
          }

          & > * + * {
            margin-left: ${getCSSToken(space)};
          }

          &.overflowing {
            padding-bottom: ${getCSSToken(space)};
          }
        `
      ]}
    >
      {children}
    </div>
  );
});

Reel.defaultProps = {
  height: "auto",
  trackColor: "white",
  thumbColor: "black",
  itemWidth: "auto"
};

export { Reel };
