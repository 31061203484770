import { get as lodashGet } from "lodash-es";
import type {
  IKind,
  IFieldAnswer,
  IKindStatus,
  IAlert,
  IAlertSubCategory,
  ITodoStepGroupsWithKind,
  ITodoStepGroup,
  ILCData,
  IWorkflowFamily,
  IAlerts,
  IWorkflowItem,
  IMyStuffConsolidatedWorkflows,
  IFilteredWorkflowsAPIResponse
} from "./interfaces";
import { queryClient } from "@certa/queries/src/utils/utils";

/**
 *
 * @param {any} jsonFromAPI
 * @returns Array<Kind>
 */

export const kindModelCreator = (jsonFromAPI: any): Array<IKind> => {
  return jsonFromAPI.map((kind: IKind) => ({
    id: lodashGet(kind, "id", null),
    features: lodashGet(kind, "features", []),
    isSortingFieldEnabled: lodashGet(kind, "is_sorting_field_enabled", false),
    isRelatedKind: lodashGet(kind, "is_related_kind", false),
    name: lodashGet(kind, "name", null),
    tag: lodashGet(kind, "tag", null),
    icon: lodashGet(kind, "icon", null),
    availableStatuses: lodashGet(kind, "available_statuses", null),
    fieldTagsForFilter: lodashGet(
      kind,
      "field_tags_for_filter.0.options",
      []
    ).map((filterTag: IFieldAnswer) => ({
      label: lodashGet(filterTag, "label", null),
      value: lodashGet(filterTag, "value", null)
    })),
    fieldFilterTag: lodashGet(kind, "field_tags_for_filter.0.tag", null)
  }));
};

/**
 * @deprecated
 */
export const statusModelCreator = (jsonFromAPI: any): Array<IKindStatus> => {
  return (
    jsonFromAPI
      ?.map((status: any) => ({
        id: lodashGet(status, "id", null),
        displayName: lodashGet(status, "display_name", null)
      }))
      .sort((a: any, b: any) => a.displayName.localeCompare(b.displayName)) ||
    []
  );
};

export const myAlertsModelCreator = (jsonFromAPI: any): Array<IAlert> => {
  const { type, results }: { type: any; results: any } = jsonFromAPI;
  return results.map(
    (alert: any): IAlert => ({
      id: alert.id,
      name: alert.name,
      colorLabel: alert.color_label,
      visibilityPriority: alert.visibility_priority,
      count: alert.count,
      tag: alert.tag,
      subCategories: lodashGet(alert, "sub_categories", []).map(
        (item: any): IAlertSubCategory => ({
          id: item.id,
          name: item.name,
          colorLabel: item.color_label,
          visibilityPriority: item.visibility_priority,
          count: item.count,
          tag: item.tag,
          type
        })
      ),
      type
    })
  );
};

export const myTodoStepGroupsModelCreator = (
  jsonFromAPI: any
): Array<ITodoStepGroupsWithKind> => {
  return jsonFromAPI.map(
    (item: any): ITodoStepGroupsWithKind => ({
      id: item.id,
      name: item.name,
      stepGroups: item.step_groups.map(
        (group: any): ITodoStepGroup => ({
          id: group.id,
          name: group.name,
          workflowCount: group.workflow_count,
          isTodo: group.is_todo ? group.is_todo : false
        })
      )
    })
  );
};

/**
 * TODO: Add description
 * Workflow Item model creator
 * @param workflow => Workflow item json from the API
 * @returns workflowItem
 * @deprecated
 */
export const workflow$MC = (workflow: any): IWorkflowItem => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  return {
    id: workflow.id,
    name: workflow.name,
    createdAt: workflow.created_at,
    sortingPrimaryField: workflow.sorting_primary_field,
    lcData: workflow.lc_data.map(
      (data: any): ILCData => ({
        value: data.value,
        label: data.translated_label || data.label,
        displayType: data.display_type,
        format: data.format
      })
    ),
    workflowFamily: [
      ...workflow.workflow_family.map(
        (family: any): IWorkflowFamily => ({
          name: family.name,
          id: family.id
        })
      ),
      {
        id: workflow.id,
        name: workflow.name
      }
    ],
    alerts: workflow.alerts.map(
      (alert: any): IAlerts => ({
        id: alert.id,
        fieldId: alert.field_id,
        stepId: alert.step_id,
        stepGroupId: alert.step_group_id,
        workflowId: alert.workflow_id,
        catColorLabel: alert.cat_color_label,
        alertTag: alert.alert_tag
      })
    ),
    status: {
      id: workflow.status.id,
      displayName: workflow.status.display_name,
      colorCode: availableStatusesKeyedById[workflow.status.id]?.color_code
    },
    progress: workflow.progress,
    rank: workflow.rank
  };
};
/**
 * @deprecated
 */
export const myStuffFilteredWorkflows$MC = (
  jsonFromAPI: IFilteredWorkflowsAPIResponse
): any => {
  return {
    count: jsonFromAPI.count,
    results: jsonFromAPI.results.map((workflow: any) => workflow$MC(workflow)),
    next: jsonFromAPI.next,
    previous: jsonFromAPI.previous
  };
};

export const myStuffConsolidatedWorkflows$MC = (
  jsonFromAPI: any
): IMyStuffConsolidatedWorkflows => {
  return {
    taggedWorkflows: {
      counts: jsonFromAPI.tagged_workflows.count,
      results: jsonFromAPI.tagged_workflows.results.map(
        (item: any): IWorkflowItem => workflow$MC(item)
      )
    },
    createdByMe: {
      counts: jsonFromAPI.created_by_me.count,
      results: jsonFromAPI.created_by_me.results.map(
        (item: any): IWorkflowItem => workflow$MC(item)
      )
    },
    todoWorkflows: jsonFromAPI.todo_workflows.map((todoGroup: any) => ({
      id: todoGroup.id,
      name: todoGroup.name,
      kindId: todoGroup.kind_id,
      kindName: todoGroup.kind_name,
      count: todoGroup.count,
      results: todoGroup.results.map(
        (workflow: any): IWorkflowItem => workflow$MC(workflow)
      )
    }))
  };
};
