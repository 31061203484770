/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import type { FC, MouseEventHandler } from "react";
import { ExperimentOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { IntlTooltip } from "../../../js/components/common/IntlTooltip";
import { getDashboardsGenericRoute } from "@certa/common";

export const BetaIcon: FC = props => {
  const history = useHistory();
  const handleNavigation: MouseEventHandler = e => {
    e.preventDefault();
    history.push(getDashboardsGenericRoute());
  };
  return (
    <IntlTooltip title="betaFeatures.title" placement="left">
      <ExperimentOutlined
        onClick={handleNavigation}
        css={{
          fontSize: 22,
          marginRight: 18,
          color: "rgba(0, 0, 0, 0.3)"
        }}
      />
    </IntlTooltip>
  );
};
