import { useEffect, useState, useCallback } from "react";

export const hasMobileResolution = (width: number, height: number) => {
  if (width < 768) {
    return true;
  } else {
    return false;
  }
};
export const useScreenResolution = () => {
  const [isMobileResolution, setIsMobileResolution] = useState(
    hasMobileResolution(window.screen.availWidth, window.screen.availHeight)
  );
  const resizeEventCallback = useCallback(() => {
    setIsMobileResolution(
      hasMobileResolution(window.screen.availWidth, window.screen.availHeight)
    );
  }, []);
  useEffect(() => {
    window.addEventListener("resize", resizeEventCallback);
    return () => window.removeEventListener("resize", resizeEventCallback);
  }, [resizeEventCallback]);

  return { isMobileResolution };
};
