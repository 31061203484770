import { useLoadStaticData } from "../hooks/useLoadStaticData";

// Purpose: To fetch all the static data at the root level of the app
// the caller component should use the status and loader from this to take actions accordingly
//
// Returned data:
// status => combined status of all the fetch calls
// error => combined error of al the fetch calls
// For more info: See the docs for `useLoadStaticData` hooks
//
// NOTE: Later (once the root component is refactored into a functional component)
// this component should be deprecated and instead `useLoadStaticData` should be used directly
const LoadStaticData = () => {
  useLoadStaticData();
  return null;
};

export { LoadStaticData };
