/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import type { Alert } from "@certa/types";
import { Link } from "react-router-dom";
import { getTaskRoute } from "../utils/routes";
import styled from "@emotion/styled";

const AlertBubbleTag: FC<{
  alert: Alert;
  handleVisibleChange: (color: string | null) => void;
}> = ({ alert, handleVisibleChange }) => {
  const { workflowId, stepGroupId, stepId, alertTag, catColorLabel } = alert;

  if (workflowId && stepGroupId && stepId) {
    return (
      <Link
        to={`${getTaskRoute({
          processId: workflowId,
          taskLaneId: stepGroupId,
          taskId: stepId
        })}`}
        css={css`
          text-decoration: none !important;
          &:focus {
            outline: 2px solid var(--colors-brand-400);
            outline-offset: 2px;
            border-radius: 0.25rem;
          }
        `}
        target="_blank"
        tabIndex={0}
        onClick={() => handleVisibleChange(null)}
        onKeyPress={event => {
          if (event.key === "Enter") {
            // Handle Enter key press
            event.preventDefault(); // Prevent the default Enter behavior (e.g., form submission)
            // Trigger a click event on the anchor element
            event.currentTarget.click();
          }
        }}
      >
        <StyledAlertName color={catColorLabel}>{alertTag}</StyledAlertName>
      </Link>
    );
  } else {
    return <StyledAlertName color={catColorLabel}>{alertTag}</StyledAlertName>;
  }
};

export { AlertBubbleTag };

const StyledAlertName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  color: var(--neutral-0);
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  text-transform: uppercase;
  margin: 2px 5px 2px 0;
  background-color: ${props => props.color};
  text-align: center;
  cursor: pointer;
`;
