import React, { useState, useEffect } from "react";
import { OverlayType, useOverlay } from "@certa/blocks";
import { useIntl } from "react-intl";
import { css } from "emotion";
import { usePrevious, useScreenResolution } from "@certa/common";
import { ServerlessAuditListTabs } from "../../../../main/src/js/components/Navbar/ServerlessAuditListTabs/ServerlessAuditListTabs";
import { AuditListTabs } from "../../../../main/src/js/components/Navbar/audit_log";
import { ActivityLogs } from "main/src/js/components/ActivityLogs";

export const ACTIVITY_LOG_OVERLAY = "ACTIVITY_LOG_OVERLAY";

export const useActivityLogOverlay = (taskId: number | undefined) => {
  const intl = useIntl();
  const { isMobileResolution } = useScreenResolution();

  const [isVisible, setIsVisible] = useState(false);
  const isPrevVisible = usePrevious(isVisible);
  const [isNewUI, setIsNewUI] = useState(false); // certa 2.0 implemetation

  const showActivityLogs = (isCerta2Enabled?: boolean) => {
    if (isCerta2Enabled) {
      setIsNewUI(isCerta2Enabled);
    }
    setIsVisible(true);
  };

  const onClose = () => setIsVisible(false);

  const activityLogDrawerProps = isNewUI
    ? {
        subtitle: intl.formatMessage({
          id: "workflowsInstances.activityLog.title"
        }),
        type: OverlayType.DRAWER,
        destroyOnClose: true,
        width: isMobileResolution ? "100%" : 750,
        onClose,
        isCERTA2Enabled: true
      }
    : {
        title: intl.formatMessage({
          id: "workflowsInstances.activityLog.title"
        }),
        type: OverlayType.DRAWER,
        destroyOnClose: true,
        width: 500,
        onClose,
        className: css`
          .ant-drawer-content {
            box-shadow: none;
          }
        `
      };

  const [showOverlay, hideOverlay] = useOverlay(
    ACTIVITY_LOG_OVERLAY,
    <>
      {isNewUI ? (
        <ActivityLogs id={taskId} />
      ) : (
        <>
          {import.meta.env.REACT_APP_ACTIVITY_LOG_SERVERLESS === "true" ? (
            <ServerlessAuditListTabs id={[taskId]} />
          ) : (
            <AuditListTabs id={taskId} /> // this component need not to be maintained
          )}
        </>
      )}
    </>,
    activityLogDrawerProps
  );

  useEffect(() => {
    if (isVisible !== isPrevVisible && isPrevVisible !== undefined) {
      if (isVisible) {
        showOverlay();
      } else {
        hideOverlay();
      }
    }
  }, [hideOverlay, isVisible, isPrevVisible, showOverlay]);

  return { showActivityLogs };
};
