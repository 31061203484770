import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";

export const CountWidget = ({ value, innerColour }) => {
  const intl = useIntl();
  return (
    <StyledCountWidget background={innerColour}>
      {intl.formatNumber(value)}
    </StyledCountWidget>
  );
};

const StyledCountWidget = styled.span`
  border-radius: 20px;
  background-color: ${({ background }) => background || "red"};
  color: white;
  margin: 0;
  padding: 0 5px;
  font-size: 14px;
  min-width: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  display: inline-block;
`;
