import type { FC } from "react";
import { getCharComponent } from "../../../utils";
import type { ChartViewORMProps } from "../types";
import { NoDataInReports } from "../../noDataInReports/NoDataInReports";
import { useChartViewORM } from "../hooks/useChartViewORM";

export const ChartViewORM: FC<ChartViewORMProps> = props => {
  const {
    chartConfigORM,
    onChartClick,
    chartType,
    xActiveIdentifier,
    error,
    isAnimationActive = true
  } = props;
  const {
    chartData,
    otherConfig,
    chartHeight,
    hasData,
    xAxisDataLabels,
    handleSorterChange,
    sorting
  } = useChartViewORM(props);
  const ChartComponent = getCharComponent(chartType);

  if (!hasData || error) {
    return <NoDataInReports error={error} />;
  }

  return (
    <ChartComponent
      chartData={chartData}
      sorting={sorting}
      handleSorterChange={handleSorterChange}
      onClick={onChartClick}
      height={chartHeight}
      xActiveIdentifier={xActiveIdentifier}
      otherConfig={otherConfig}
      isCycleTimeReport={
        !!chartConfigORM?.otherConfigurations?.isCycleTimeReport
      }
      groupByORM={chartConfigORM?.groupBy}
      xAxisDataLabels={xAxisDataLabels}
      isAnimationActive={isAnimationActive}
    />
  );
};
