import {
  Stack,
  Typography,
  TypographyVariants,
  TypographyColors
} from "@certa/catalyst";
import styles from "./MobileInCompatiblePage.module.css";
import { UserWithMobile } from "../../assets/components/UserWithMobile";
import { useIntl } from "react-intl";

const MobileInCompatiblePage = () => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: "mobileInCompatiblePage.title",
    defaultMessage: "Desktop only for now"
  });
  const description = intl.formatMessage({
    id: "mobileInCompatiblePage.description",
    defaultMessage:
      "We're hard at work building a proper mobile experience. Please try accessing this page on a desktop computer for now."
  });
  return (
    <Stack
      direction="vertical"
      align="center"
      justify="center"
      dangerouslySetClassName={styles.messageContainer}
      gap="8"
    >
      <UserWithMobile />

      <Typography
        variant={TypographyVariants.TITLE_SM}
        color={TypographyColors.NEUTRAL_800}
        className={styles.title}
      >
        {title}
      </Typography>
      <Typography
        variant={TypographyVariants.BODY}
        color={TypographyColors.NEUTRAL_700}
        className={styles.description}
      >
        {description}
      </Typography>
    </Stack>
  );
};

export { MobileInCompatiblePage };
