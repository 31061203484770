import { getAPIOrigin } from "@certa/network";
import { REGEX } from "./regex";
import { authHeader } from "main/src/js/_helpers";
import { FileDownloadTypes, fileDownload } from "./fileDownload";
import type { HashMap } from "@certa/types";
import type { integrationStatuses } from "../constants";
import { ONE, TIMEOUTS, eventStatusNew, eventStatus } from "../constants";
import { notification } from "@certa/blocks/thanos";
import { ToastPlacements, ToastTypes, showToast } from "@certa/catalyst";
import COPY_TEXT from "@certa/comments/src/util/copyText";
import { DOWNLOAD_FILE_ERRORS } from "./fileDownloadErrors";
import type { UseScrollElementIntoViewProps } from "../hooks/useScrollElementIntoViewById";
import type { ImpersonateeUserListType } from "../components/Header/types";
import linkifyHtml from "linkify-html";

/**
 *
 * @param input String, pass string from where you want to remove all kinds of brackets
 * @returns String without brackets
 */
export function removeBrackets(input: string) {
  return input.replace(REGEX.ALL_BRACKETS, "");
}

/**
 *
 * @param input String, pass string
 * @returns  string on which on which brackets will be removed and string will be appened with -
 */
export function removeBracketsAndAppendDash(input: string) {
  if (input.match(REGEX.ALL_BRACKETS)) {
    const stringWithoutBrackets = removeBrackets(input);
    return stringWithoutBrackets.split(" ").join(" - ");
  }
  return input;
}

/**
 * @param scrollLeft Left position where you want to scroll to
 * @param scrollTop Top posision where you want to scroll to
 * @param behavior Smooth behaviour or strict behavior when performing scrolling
 */
export function resetScrollPosition(
  scrollLeft = 0,
  scrollTop = 0,
  behavior: ScrollBehavior = "auto"
) {
  if (window && window.scrollTo) {
    window.scrollTo({
      top: scrollTop,
      left: scrollLeft,
      behavior
    });
  }
}

/**
 *
 * @param {string} filePath This will be path to particular object but as a client you can's use this one.
 * @returns string => Presigned URL for object i.e. images, video, PDFs, ppts
 */
export const fetchObjectUrl = async (filePath: string) => {
  // TODO: remove redirect replace logic once BE change is done
  const splitUrlArray = filePath.split("uid=");
  const finalEndpointWithoutUIDIndex = 0;
  const allQueryParamsAfterUIDIndex = 1;
  const encodedUidURL =
    splitUrlArray[finalEndpointWithoutUIDIndex] +
    "uid=" +
    encodeURIComponent(splitUrlArray[allQueryParamsAfterUIDIndex]);

  try {
    const response = await fetch(
      `${getAPIOrigin()}${encodedUidURL.replace("redirect=True", "redirect=")}`,
      {
        method: "GET",
        headers: authHeader.get(),
        credentials: "include"
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data && data.attachment_url ? data.attachment_url : "";
    }
    let errorMessage = "";
    if (response.status === 404) {
      if (data?.field?.[0] === DOWNLOAD_FILE_ERRORS[404].FIELD.ERROR) {
        errorMessage = DOWNLOAD_FILE_ERRORS[404].FIELD.MESSAGE;
      } else if (
        data?.field_tag?.[0] === DOWNLOAD_FILE_ERRORS[404].FILED_TAG.ERROR
      ) {
        errorMessage = DOWNLOAD_FILE_ERRORS[404].FILED_TAG.MESSAGE;
      } else if (
        data?.attachment?.[0]?.startsWith(
          DOWNLOAD_FILE_ERRORS[404].ATTACHMENT.ERROR
        )
      ) {
        errorMessage = DOWNLOAD_FILE_ERRORS[404].ATTACHMENT.MESSAGE;
      }
    }
    if (response.status === 400) {
      if (
        data?.field_type?.[0] === DOWNLOAD_FILE_ERRORS[400].FIELD_TYPE.ERROR
      ) {
        errorMessage = DOWNLOAD_FILE_ERRORS[400].FIELD_TYPE.MESSAGE;
      } else if (
        data?.attachment?.[0] === DOWNLOAD_FILE_ERRORS[400].ATTACHMENT.ERROR
      ) {
        errorMessage = DOWNLOAD_FILE_ERRORS[400].ATTACHMENT.MESSAGE;
      }
    }
    if (!errorMessage) {
      errorMessage = COPY_TEXT.DOWNLOAD_FILE.ERROR.DEFAULT.TITLE;
    }
    showToast({
      type: ToastTypes.ERROR,
      placement: ToastPlacements.BOTTOM_RIGHT,
      title: errorMessage
    });
  } catch (error) {
    showToast({
      type: ToastTypes.ERROR,
      placement: ToastPlacements.BOTTOM_RIGHT,
      title: COPY_TEXT.DOWNLOAD_FILE.ERROR.DEFAULT.TITLE,
      description: COPY_TEXT.DOWNLOAD_FILE.ERROR.DEFAULT.DESCRIPTION
    });
  }
};
/**
 *
 * @param {string} filePath This will be path to particular object but as a client you can's use this one.
 * @returns string => Presigned URL for object i.e. images, video, PDFs, ppts
 */
export const fetchObjectUrlAndDownload = async (
  filePath: string,
  decryptDownload?: {
    fileName: string;
    blob: Blob;
  }
) => {
  if (decryptDownload) {
    fileDownload({
      downloadType: FileDownloadTypes.BLOB_DOWNLOAD,
      url: filePath,
      blob: decryptDownload.blob,
      fileName: decryptDownload.fileName
    });
  } else {
    const fileActualUrl = await fetchObjectUrl(filePath);
    if (fileActualUrl) {
      fileDownload({
        downloadType: FileDownloadTypes.NEW_TAB,
        url: fileActualUrl
      });
    }
  }
};

/**
 *
 * @returns boolean => true if browser is safari
 */
export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") === -1
  );
};

export const hideElement = (element: HTMLElement | null) => {
  if (element) {
    element.style.display = "none";
  }
};

export const getLoader = (): HTMLElement | null => {
  return document.getElementById("app-loader");
};

export const getLastObject = <T>(array: T[]) => {
  if (!array) return undefined;
  return array[array?.length - 1];
};
export const sortByKey = (
  key: string,
  mentionA: HashMap,
  mentionB: HashMap
) => {
  const textA = mentionA[key].toUpperCase();
  const textB = mentionB[key].toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
};

export const createCookie = (key: string, value: string) => {
  document.cookie = `${key}=${value}; path=/;`;
};

export const deleteCookie = (key: string) => {
  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const getLastArrayElement = <T>(array: T[] | undefined) => {
  return array?.[array.length - ONE];
};

export const closeWindow = () => {
  try {
    window.close();
  } catch (e) {
    console.log("Error while closing the window", e);
  }
};
export const isRokuTenant = (tenantName: string) => {
  return tenantName.includes("roku");
};

export function obfuscateEmail(email: string): string {
  const parts = email.split("@");
  const username = parts[0];
  const domain = parts[1];

  const obfuscatedUsername = username
    .split("")
    .map((char, index) =>
      index !== 0 && index !== username.length - 1 ? "*" : char
    )
    .join("");

  const obfuscatedDomain = domain
    .split("")
    .map((char, index) =>
      index !== 0 && index !== domain.length - 1 ? "*" : char
    )
    .join("");

  return `${obfuscatedUsername}@${obfuscatedDomain}`;
}

export const isLoadingInsideIframe = () => {
  return window.top !== window.self;
};

export const handleCopy = async ({ text }: { text: string }) => {
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(text ?? "");
    notification.success({
      message: "Copied to Clipboard",
      duration: 2,
      isCERTA2Enabled: true
    });
  } else {
    notification.error({
      message: "Could not copy to clipboard. Unsupported browser.",
      isCERTA2Enabled: true
    });
  }
};

/**
 *
 * @param data Data to be converted to excel
 * @param fileName name of the file
 * @param extension find all the supported extensions here- https://www.npmjs.com/package/xlsx
 */
export const exportExcel = async (
  data: Record<any, any>[],
  fileName: string,
  extension: string
) => {
  const XLSX = await import("xlsx");
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${fileName}.${extension}`, {
    compression: true
  });
};

/*
  Below function is written to,
  1. Get label and color from given status
  2. Here we need to give support for both, integrationStatus['value'] && integrationStatus['label']: Check PLAT-7802
  3. We also have given support for old theme colors
  4. If status is not found in integrationStatuses then we will check for label
  5. If label is not found in integrationStatuses then we will return undefined
*/
export type IntegrationStatuses = (typeof integrationStatuses)[number][
  | "text"
  | "value"];

export const getEventStatusColorAndLabel = (
  status: IntegrationStatuses,
  isUsingOldThemeColors = false
):
  | {
      class: string;
      label: string;
    }
  | undefined => {
  if (isUsingOldThemeColors) {
    if (eventStatus[status]) {
      return eventStatus[status];
    }
    const findStatusByLabel = Object.keys(eventStatus)
      .map(eventStatusKey => eventStatus[eventStatusKey])
      .find(eventStatusKey => eventStatusKey.label === status);
    if (findStatusByLabel) {
      return findStatusByLabel;
    }
  } else {
    if (eventStatusNew[status]) {
      return eventStatusNew[status];
    }
    const findStatusByLabel = Object.keys(eventStatusNew)
      .map(eventStatusKey => eventStatusNew[eventStatusKey])
      .find(eventStatusKey => eventStatusKey.label === status);
    if (findStatusByLabel) {
      return findStatusByLabel;
    }
  }
};

export function concatSet(a: Set<unknown>, b: Set<unknown>) {
  const newSet = new Set();
  a.forEach(item => newSet.add(item));
  b.forEach(item => newSet.add(item));
  return newSet;
}
/**
 *
 * @param data in string which we need to parse
 * @returns it returns parsed object if it is valid json else it returns empty object
 */
export const parseJSONSafely = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return {};
  }
};

export const encodeUrlSafeB64 = (str: string) => {
  // replace '+' with '.', '/' with '_' and '=' with '-'
  const urlSafeb64 = btoa(str)
    .replace(/\+/g, ".")
    .replace(/\//g, "_")
    .replace(/=/g, "-");

  return urlSafeb64;
};

export const decodeUrlSafeB64 = (str: string) => {
  // replace '.' with '+' and '_' with '/' and '-' with '='
  const base64 = str.replace(/\./g, "+").replace(/_/g, "/").replace(/-/g, "=");
  return atob(base64);
};

export function scrollElementIntoView({
  elementId,
  scrollIntoViewOptions
}: {
  elementId: UseScrollElementIntoViewProps["elementId"];
  scrollIntoViewOptions: ScrollIntoViewOptions;
}) {
  // Get the DOM element by its ID
  const element: HTMLElement | null = document.getElementById(elementId);
  // Check if the element exists before scrolling into view
  if (element) {
    // Scroll the element into view with a smooth behavior
    element.scrollIntoView(scrollIntoViewOptions);
  }
}

/**
 * This function returns list of users by kind
 * @param tenantName {Capitalize<string>} Tenant name
 * @returns {ImpersonateeUserListType[]} List of users by kind
 */
export const getUsersByKind = (
  tenantName: Capitalize<string>
): ImpersonateeUserListType[] => {
  return [
    { name: `${tenantName} User`, kind: "Client" },
    { name: "External User", kind: "ThirdParty" }
  ];
};

/**
 * Scrolls to an element with the specified ID.
 * @param {string} elementId - The ID of the element to scroll to.
 * @returns {boolean} - Returns true if scrolling was successful, false otherwise.
 */
function scrollToElement(elementId: string | number) {
  const element = document.getElementById(elementId.toString());
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
    return true;
  }
  return false;
}

/**
 * Highlights an element by adding a CSS class.
 * @param {string} elementId - The ID of the element to highlight.
 * @returns {boolean} - Returns true if highlighting was successful, false otherwise.
 */
function highlightElement(elementId: string | number) {
  const element = document.getElementById(elementId.toString());
  if (element) {
    // Below timeout is added to highlight the element after scrolling to it.
    // This is added to give user a visual indication of the element being scrolled to.
    setTimeout(() => {
      element.classList.add("hightlight-message");
      setTimeout(() => {
        element.classList.remove("hightlight-message");
      }, TIMEOUTS.ELEMENT_STANDARD_HIGHLIGHT);
    });
    return true;
  }
  return false;
}

/**
 * Scrolls to an element and highlights it by ID.
 * @param {string} elementId - The ID of the element to scroll to and highlight.
 * @returns {boolean} - Returns true if scrolling and highlighting were successful, false otherwise.
 */
export function scrollToElementAndHighlightById(elementId: string | number) {
  const isScrolled = scrollToElement(elementId.toString());
  if (isScrolled) {
    const isHighlighted = highlightElement(elementId);
    return isHighlighted;
  }
  return false;
}

export const convertURLToAnchor = (text: string) => {
  return linkifyHtml(text, {
    defaultProtocol: "https",
    rel: "noopener noreferrer",
    target: "_blank"
  });
};
