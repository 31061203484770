import type { Dispatch, SetStateAction } from "react";
import { useState, useEffect } from "react";

/**
 * WARNING: This will not work with multiple instances because if one of
 * the instances update the local storage, others will NOT be updated.
 *
 * This is fine when at a time you're only going to be having one instances of
 * a particular key loaded. But if you're using two `useLocalStorage` instances
 * at different places with same key, then changes made by one will not reflect
 * on the other one, until its hook is remounted (which likely won't happen).
 */

export const useLocalStorage = <T = string>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    let currentValue: T;

    try {
      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue)
      );
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};
