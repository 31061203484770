/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import { css } from "emotion";
import type { FC } from "react";
import { useState, useMemo } from "react";
import styled from "@emotion/styled";
import { Stack } from "@certa/blocks/thanos";
import type { Alert } from "@certa/types";
import { groupBy } from "lodash-es";
import { AlertBubbleTag } from "./AlertBubbleTag";
import { Popover } from "@certa/catalyst";

const AlertTagList = ({
  groupAlert,
  handleVisibleChange
}: {
  groupAlert: Alert[];
  handleVisibleChange: (color: string | null) => void;
}) => {
  return (
    <Stack
      direction="vertical"
      align="flex-start"
      className={css`
        padding: 12px 16px;
      `}
      onMouseLeave={() => handleVisibleChange(null)}
    >
      {groupAlert.map((alert, index) => (
        <AlertBubbleTag
          alert={alert}
          key={`${index}`}
          handleVisibleChange={handleVisibleChange}
        />
      ))}
    </Stack>
  );
};

const AlertsBubbleList: FC<{ alerts: Alert[] }> = ({ alerts }) => {
  const [visibleColor, setVisibleColor] = useState<string | null>(null);

  const groupAlert: { [key: string]: Alert[] } = useMemo(() => {
    const filteredAlert: Alert[] = alerts.filter(alert => alert.catColorLabel);
    return groupBy(filteredAlert, "catColorLabel");
  }, [alerts]);

  return (
    <Stack
      className={css`
        // TODO: See if we need to make css optional and add support for it
        height: 40px;
        cursor: pointer;
        position: relative;
        &:hover {
          span {
            left: var(--hover-position);
          }
        }
      `}
      align="center"
      justify="center"
      onMouseLeave={() => setVisibleColor(null)}
    >
      {Object.keys(groupAlert).map((alertColor, index) => (
        <Popover
          content={
            <AlertTagList
              groupAlert={groupAlert[alertColor]}
              handleVisibleChange={setVisibleColor}
            />
          }
          key={alertColor + index}
          visible={visibleColor === alertColor}
          onVisibleChange={() => setVisibleColor(alertColor)}
          focusLock
          onClose={() => setVisibleColor(null)}
        >
          <StyledCountWidget
            tabIndex={0}
            background={alertColor}
            rank={index}
            aria-label={`Field - ${groupAlert[alertColor].length}`}
            aria-haspopup="true"
            // --hover-position do not work on styled.button, hence added keypress to make span behave like button
            onClick={() => setVisibleColor(alertColor)}
            onKeyPress={event => {
              if (event.key === "Enter") {
                event.preventDefault(); // Prevent the default Enter behavior (e.g., form submission)
                // Trigger a click event on the anchor element
                event.currentTarget.click();
              }
            }}
          >
            {groupAlert[alertColor].length}
          </StyledCountWidget>
        </Popover>
      ))}
    </Stack>
  );
};

export { AlertsBubbleList };

const StyledCountWidget = styled.span<{ background: string; rank: number }>`
  border-radius: 20px;
  background-color: ${({ background }) => background};
  z-index: ${({ rank }) => 50 - rank};
  position: absolute;
  left: ${({ rank }) => 8 * rank}px;
  top: 10px;
  color: white;
  font-size: 12px;
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s all ease;
  --hover-position: ${({ rank }) => 25 * rank}px;
  :first-child {
    margin-left: 0;
  }

  &:focus {
    outline: 2px solid var(--colors-brand-400);
    outline-offset: 2px;
    z-index: 50;
    box-shadow: 0 0 0 4px white;
  }
`;
