import type { FC } from "react";
import React from "react";
import { useIntl } from "react-intl";
import type { TooltipProps } from "antd/es/tooltip";
import type { ProgressType } from "@certa/blocks/thanos";
import {
  NA_PROGRESS_PERCENTAGE,
  Progress as ProgressBlock
} from "@certa/blocks/thanos";

type ProgressExtendedType = Partial<TooltipProps> &
  ProgressType & {
    isToolTip?: boolean;
    hideIfNoProgress?: boolean;
  };

// A simple component that returns Progress Block wrapped with or without tooltip
export const Progress: FC<ProgressExtendedType> = ({
  isToolTip = true,
  toolTipProps,
  onClick,
  progressPercentage,
  strokeColor,
  applyHoverStyle,
  className,
  width,
  strokeWidth,
  size,
  showInfo,
  type,
  hideIfNoProgress = false
}) => {
  const intl = useIntl();
  if (hideIfNoProgress && progressPercentage === 0) return null;

  // progress percentage should not be shown if it is -1
  const percentageText =
    progressPercentage !== NA_PROGRESS_PERCENTAGE
      ? `${progressPercentage}% - `
      : "";
  return (
    <ProgressBlock
      type={type}
      onClick={onClick}
      progressPercentage={progressPercentage}
      strokeColor={strokeColor}
      applyHoverStyle={applyHoverStyle}
      className={className}
      width={width}
      strokeWidth={strokeWidth}
      size={size}
      showInfo={showInfo}
      toolTipProps={
        isToolTip
          ? {
              ...toolTipProps,
              title: `${percentageText}${
                onClick
                  ? intl.formatMessage({
                      id: "progressMap.showActionTooltip",
                      defaultMessage: "Click to view progress map"
                    })
                  : ""
              }`
            }
          : undefined
      }
    />
  );
};
