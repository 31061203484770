import { useMemo } from "react";
import type { ChartViewORMProps } from "../types";
import type {
  ChartSorting,
  MetricDataTypes,
  OtherChartConfig
} from "@certa/common";
import { useConvertToChartDataORM } from "./useConvertToChartDataORM";
import {
  getOtherConfigXAxis,
  getChartHeight,
  getSortingFromChartConfigORM
} from "../../../utils";
import { useSetTotalCount } from "../../../hooks/useSetTotalCount";
import type { OrderByORM, ChartConfigORM, GroupByORM } from "@certa/types";

export const useChartViewORM = (props: ChartViewORMProps) => {
  const {
    chartConfigORM,
    apiData,
    viewType,
    setTotalCount,
    isLoading,
    setChartConfigORM
  } = props;

  const chartData = useConvertToChartDataORM({
    chartConfigORM,
    apiData
  });
  const xAxisKey = chartData?.xAxisKey;
  const isCycleTimeReport =
    chartConfigORM?.otherConfigurations?.isCycleTimeReport;
  const xAxis = (chartConfigORM?.groupBy ?? []).filter(
    groupBy => !groupBy.extraJSON.isForSecondaryXAxis
  );
  const canShowSorting = !isCycleTimeReport && setChartConfigORM;

  const xAxisDataLabels = useMemo(() => {
    // when isCycleTimeReport = true at that time, xAxisKey will provide the correct label.
    const defaultxAxis = xAxisKey ? [{ extraJSON: { label: xAxisKey } }] : [];
    const xAxisData = isCycleTimeReport ? defaultxAxis : xAxis;
    return xAxisData.map(x => x.extraJSON.label);
  }, [xAxis, xAxisKey, isCycleTimeReport]);

  const chartHeight = getChartHeight(viewType);

  // create otherConfig for chart frp, chartConfigORM.otherConfigurations
  const otherConfig: OtherChartConfig = {
    ...chartConfigORM?.otherConfigurations,
    xAxis: getOtherConfigXAxis(chartConfigORM),
    yAxis: getOtherConfigYAxis(chartConfigORM),
    shouldShowPercentagesInValueLabels:
      chartConfigORM?.otherConfigurations?.shouldShowPercentagesInValueLabels
  };

  useSetTotalCount({ chartData, isLoading, setTotalCount });

  const handleSorterChange = (data: ChartSorting) => {
    const sorter: GroupByORM | undefined = chartConfigORM?.groupBy?.find(
      item => item.extraJSON.customTag === data.tag
    );
    if (!sorter) {
      return;
    }

    const hasFieldSorting = !!chartConfigORM?.orderBy?.find(
      item => item.tag === sorter.tag
    );
    let newOrderBy: OrderByORM[];
    if (hasFieldSorting) {
      newOrderBy =
        chartConfigORM?.orderBy?.map(orderBy => {
          if (orderBy.tag === sorter.tag) {
            return {
              ...orderBy,
              dataType: sorter.extraJSON.dataType,
              ordering: orderBy.ordering === "ASC" ? "DESC" : "ASC"
            };
          }
          return orderBy;
        }) || [];
    } else {
      newOrderBy = [
        ...(chartConfigORM?.orderBy || []),
        {
          kindId: sorter.kindId,
          type: sorter.type,
          dataType: sorter.extraJSON.dataType,
          ordering: "ASC",
          source: sorter.source,
          tag: sorter.tag,
          extraJSON: {
            customTag: sorter.extraJSON.customTag
          }
        }
      ];
    }
    setChartConfigORM?.(prev => ({
      ...prev,
      orderBy: newOrderBy
    }));
  };

  const sorting = canShowSorting
    ? getSortingFromChartConfigORM(
        chartConfigORM?.groupBy,
        chartConfigORM?.orderBy
      )
    : undefined;

  return {
    chartData,
    hasData: !!chartData?.data?.length,
    xAxisDataLabels: xAxisDataLabels,
    chartHeight,
    otherConfig,
    handleSorterChange,
    sorting
  };
};

const getOtherConfigYAxis = (chartConfigORM: ChartConfigORM | undefined) => {
  const yAxis = {
    outputDataType: [] as MetricDataTypes[]
  };
  // when axisName = xAxis at that time only outputDataType = "text"
  // that is why after filtering, outputDataType = MetricDataTypes only
  chartConfigORM?.operations
    ?.filter(operation => operation.extraJSON.axisName === "yAxis")
    ?.forEach(operation => {
      const { outputDataType } = operation.extraJSON;
      yAxis.outputDataType.push(outputDataType as MetricDataTypes);
    });
  return yAxis;
};
