import { Helmet } from "react-helmet-async";

export const HelmetMSClarity = () => {
  const msClarityToken = import.meta.env.REACT_APP_MS_CLARITY_TOKEN || "";
  const jsCode = `try{

    // SCRIPT FROM MS CLARITY---------
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', '${msClarityToken}')
    // ---------

    console.log("MS Clarity script loaded");
  }catch(e){
    console.log("MS Clarity failed to load",e);
  };
  `;

  return msClarityToken ? (
    <Helmet>
      <script type="text/javascript">{jsCode}</script>
    </Helmet>
  ) : null;
};
