import { get as lodashGet } from "lodash-es";
import { useAppSelector } from "../hooks";

export const useEnableV3SearchAPI = () => {
  return useAppSelector(state =>
    lodashGet(state, "config.custom_ui_labels.specialFeatures", []).includes(
      "ENABLE_V3_SEARCH_API"
    )
  );
};
