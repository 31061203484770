import type { FC } from "react";
import React, { useMemo } from "react";
import { Timeline } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { css } from "emotion";
import { useActivityLogModelCreator } from "../hooks";
import { groupActivityLogs } from "../utils";
import { ActivityLogItem } from "./ActivityLogItem";

type ActivityLogListProps = {
  loadData: (page: number) => void;
  hasMore: boolean;
  isLoading: boolean;
  data: {
    results: Array<object>;
  };
};

export const ActivityLogList: FC<ActivityLogListProps> = props => {
  const {
    loadData,
    hasMore,
    isLoading,
    data: { results }
  } = props;
  const activityLogs = useActivityLogModelCreator(results);
  const groupedActivityLogs = useMemo(
    () => groupActivityLogs(activityLogs),
    [activityLogs]
  );
  return (
    <div className="activity-log-wrapper text-left">
      <InfiniteScroll
        pageStart={0}
        loadMore={loadData}
        hasMore={hasMore && !isLoading}
        useWindow={false}
        className={css`
          .ant-timeline-item:last-of-type > .ant-timeline-item-tail {
            display: none;
          }
        `}
      >
        <Timeline>
          {Object.values(groupedActivityLogs).map(
            ({ activityLog, activityLogsByBot }) => (
              <ActivityLogItem
                activityLog={activityLog}
                activityLogsByBot={activityLogsByBot}
                key={activityLog.id}
              />
            )
          )}
        </Timeline>
      </InfiniteScroll>
    </div>
  );
};
