import { workflowConstants } from "../constants";

export function workflow(state = { workflowId: null }, action) {
  let workflow; // being used below
  switch (action.type) {
    //GET ALL THE WORKFLOWS
    case workflowConstants.GETALL_REQUEST:
      return {
        loading: true,
        search: false
      };
    case workflowConstants.GETALL_SUCCESS:
      workflow = action.workflow;
      return {
        search: false,
        loading: false,
        workflow: workflow.results,
        count: workflow.count,
        next: workflow.next,
        previous: workflow.previous
      };
    case workflowConstants.GETALL_FAILURE:
      return {
        search: false,
        loading: false,
        loadingStatus: "failed",
        error: action.error
      };

    //SEARCH LIST
    case workflowConstants.SEARCH_REQUEST:
      return {
        loading: true,
        search: true
      };
    case workflowConstants.SEARCH_SUCCESS:
      workflow = action.workflow;
      return {
        search: true,
        loading: false,
        workflow: workflow.results,
        count: workflow.count,
        next: workflow.next,
        previous: workflow.previous
      };
    case workflowConstants.SEARCH_FAILURE:
      return {
        search: true,
        loading: false,
        workflow: null,
        loadingStatus: "failed",
        error: action.error
      };

    //CREATE WORKFLOW
    case workflowConstants.CREATE_REQUEST:
      return {
        loading: true
      };
    case workflowConstants.CREATE_SUCCESS:
      return {
        loading: false,
        workflow: action.workflow
      };
    case workflowConstants.CREATE_FAILURE:
      return {
        loading: false,
        loadingStatus: "failed",
        error: action.error
      };

    //GET SINGLE WORKFLOW BY ID
    case workflowConstants.GET_REQUEST:
      return {
        loading: true
      };

    case workflowConstants.GET_SUCCESS:
      return {
        loading: false,
        workflow: action.workflow
      };

    case workflowConstants.GET_FAILURE:
      return {
        loading: false,
        loadingStatus: "failed",
        error: action.error
      };
    case workflowConstants.SET_WORKFLOW_ID:
      return {
        workflowId: action.payload.id
      };

    default:
      return state;
  }
}

export function workflowChildren(state = {}, action) {
  switch (action.type) {
    //GET ALL THE WORKFLOWS
    case workflowConstants.GET_CHILD_REQUEST:
      return {
        ...state,
        ...action.response
      };
    case workflowConstants.GET_CHILD_SUCCESS:
      return {
        ...state,
        ...action.response
      };
    case workflowConstants.GET_CHILD_FAILURE:
      return {
        ...state,
        ...action.response
      };

    default:
      return state;
  }
}

const initialStateOpen = {
  list: [],
  modalWorkflowId: null,
  modalWorkflowName: null,
  modalWorkflowMode: null
};

export function expandedWorkflows(state = initialStateOpen, action) {
  switch (action.type) {
    //WORKFLOW EXPANDED IN LIST VIEW
    case workflowConstants.EXPANDED_WORKFLOWS:
      return {
        ...state,
        list: action.payload
      };

    // TODO: move this to global context, workflow id of process in Modal
    case workflowConstants.SET_WORKFLOW_MODAL_ID:
      return {
        ...state,
        modalWorkflowId: action.payload?.id,
        modalWorkflowName: action.payload?.name
      };

    case workflowConstants.SET_WORKFLOW_OVERLAY_MODE:
      return {
        ...state,
        modalWorkflowMode: action.payload
      };

    default:
      return state;
  }
}
