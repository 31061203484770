import { DropdownMenu, DropdownMenuTrigger } from "@certa/catalyst";

import { UserAvatar, OverlayMenu } from "./components";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

type UserProfileProps = {
  userName: string;
};

export const UserProfile = (props: UserProfileProps) => {
  const { userName } = props;
  return (
    <DropdownMenu
      onOpenChange={open => {
        open &&
          MixPanelActions.track(
            MixPanelEvents.headerEvents.HEADER_CLICK_USERPROFILE_DROPDOWN
          );
      }}
    >
      <DropdownMenuTrigger>
        <UserAvatar userName={userName} />
      </DropdownMenuTrigger>
      <OverlayMenu />
    </DropdownMenu>
  );
};
