import type { CellData, Data } from "../types";
import {
  AVERAGE_LABEL,
  GRAND_AVERAGE_LABEL,
  GRAND_SUB_TOTAL_LABEL,
  SUB_TOTAL_LABEL
} from "../constants";
import { cloneDeep } from "lodash-es";

const FIRST_X_AXIS_LABELS = 0;
const ROUND_UP = 2;
const DEFAULT_VALUE_ZERO = 0;
const INCREMENT_BY_ONE = 1;

export const getAxesLebels = (axiskeys: string[], data: Data) => {
  const sets = axiskeys.reduce<Record<string, Set<string>>>((newSets, key) => {
    newSets[key] = new Set<string>();
    return newSets;
  }, {});
  data.forEach(cellData => {
    axiskeys.forEach(key => {
      if (cellData[key] !== undefined) sets[key].add(`${cellData[key]}`);
    });
  });
  return Object.keys(sets).map(setKey => Array.from(sets[setKey]));
};

type GetTranslatedDataParams = {
  data: Data;
  xAxisKeys: string[];
  yAxisKeys: string[];
  multipleXAxisLabels: string[][];
  yAxesLabels: string[][];
  valueKey: string;
};

export const getTranslatedData = ({
  data,
  xAxisKeys,
  yAxisKeys,
  multipleXAxisLabels,
  yAxesLabels,
  valueKey
}: GetTranslatedDataParams) => {
  if (!multipleXAxisLabels[FIRST_X_AXIS_LABELS])
    return {
      transformData: [],
      minValue: undefined,
      maxValue: undefined,
      columnSubTotalAndAvg: [{}, {}]
    };

  const yAxesLabelsData = data.map(eachData => {
    const newObj: Record<string, CellData> = {}; // Add index signature
    yAxisKeys.forEach(yAxisKey => {
      newObj[yAxisKey] = eachData[yAxisKey];
    });
    return newObj;
  });
  const yAxesLabelsDataWithUniqueValues = [
    ...new Set(yAxesLabelsData.map(obj => JSON.stringify(obj)))
  ].map(str => JSON.parse(str));

  const transformData: Record<string, CellData>[] = [];
  yAxesLabelsDataWithUniqueValues?.forEach(eachRowRecord => {
    const innerObj: Record<string, CellData> = {};
    data.forEach(eachData => {
      let keyIndex = "$$$";
      if (
        yAxisKeys.every(
          yAxisKey => eachRowRecord[yAxisKey] === eachData[yAxisKey]
        )
      ) {
        // #GENERATE_KEY_FOR_NESTED_X_AXIS_LABELS
        // Search the above key to find references for the below key
        xAxisKeys.forEach((xAxisKey, index) => {
          keyIndex =
            keyIndex +
            eachData[xAxisKey] +
            (index === xAxisKeys.length - 1 ? "$$$" : "___");
        });
        innerObj[keyIndex] = eachData[valueKey];
        yAxisKeys.forEach(yAxisKey => {
          innerObj[yAxisKey] = eachData[yAxisKey];
        });
      }
    });
    if (innerObj) transformData.push(innerObj);
  });

  let minValue: number | undefined = undefined;
  let maxValue: number | undefined = undefined;

  const emptyColumnSubTotal: Record<string, CellData> = {};
  cloneDeep(transformData).forEach(
    (eachRowRecord: Record<string, CellData>) => {
      Object.keys(eachRowRecord).forEach(label => {
        // #GENERATE_KEY_FOR_NESTED_X_AXIS_LABELS
        // Search the above key to find references for the below key
        if (label.startsWith("$$$") && label.endsWith("$$$")) {
          emptyColumnSubTotal[label] = DEFAULT_VALUE_ZERO;
        }
      });
    }
  );

  // columnSubTotal[0] is used to store subTotal
  const SUB_TOTAL_INDEX = 0;
  // columnSubTotal[1] is used to store numberValueCount
  const NUMBER_VALUE_COUNT_INDEX = 1;

  const columnSubTotal = [
    cloneDeep(emptyColumnSubTotal),
    cloneDeep(emptyColumnSubTotal)
  ];
  // update rows' subTotal and average and also calculate min and max
  transformData.forEach((row: Record<string, CellData>) => {
    let numberValueCount = DEFAULT_VALUE_ZERO;
    row[SUB_TOTAL_LABEL] = DEFAULT_VALUE_ZERO;
    Object.keys(row).forEach(label => {
      // #GENERATE_KEY_FOR_NESTED_X_AXIS_LABELS
      // Search the above key to find references for the below key
      if (
        label.startsWith("$$$") &&
        label.endsWith("$$$") &&
        !Number.isNaN(row[label])
      ) {
        const value = Number(row[label]);
        columnSubTotal[SUB_TOTAL_INDEX][label] =
          Number(columnSubTotal[SUB_TOTAL_INDEX][label]) + value;
        columnSubTotal[NUMBER_VALUE_COUNT_INDEX][label] =
          Number(columnSubTotal[NUMBER_VALUE_COUNT_INDEX][label]) +
          INCREMENT_BY_ONE;
        numberValueCount = numberValueCount + INCREMENT_BY_ONE;
        row[SUB_TOTAL_LABEL] = Number(row[SUB_TOTAL_LABEL]) + value;

        minValue = minValue === undefined ? value : Math.min(minValue, value);
        maxValue = maxValue === undefined ? value : Math.max(maxValue, value);
      }
    });
    row[AVERAGE_LABEL] = Number(
      (numberValueCount
        ? row[SUB_TOTAL_LABEL] / numberValueCount
        : DEFAULT_VALUE_ZERO
      ).toFixed(ROUND_UP)
    );
  });

  let grandSubTotal = DEFAULT_VALUE_ZERO;
  let totalNumberValueCount = DEFAULT_VALUE_ZERO;
  const columnAvgRow = Object.keys(columnSubTotal[SUB_TOTAL_INDEX]).reduce<
    Record<string, number>
  >((rows, xAxesLabel) => {
    const subTotal = Number(columnSubTotal[SUB_TOTAL_INDEX][xAxesLabel]);
    const numberValueCount = Number(
      columnSubTotal[NUMBER_VALUE_COUNT_INDEX][xAxesLabel]
    );
    grandSubTotal = grandSubTotal + subTotal;
    totalNumberValueCount = totalNumberValueCount + numberValueCount;
    const avg = Number(
      (numberValueCount
        ? subTotal / numberValueCount
        : DEFAULT_VALUE_ZERO
      ).toFixed(ROUND_UP)
    );
    rows[xAxesLabel] = avg;
    return rows;
  }, {});
  const yAxisSubTotalKeys = yAxisKeys.reduce((newObj, key) => {
    return { ...newObj, [key]: SUB_TOTAL_LABEL };
  }, {});

  const yAxisAvgKeys = yAxisKeys.reduce((newObj, key) => {
    return { ...newObj, [key]: AVERAGE_LABEL };
  }, {});

  const columnSubTotalAndAvg = [
    {
      ...columnSubTotal[SUB_TOTAL_INDEX],
      ...yAxisSubTotalKeys,
      [GRAND_SUB_TOTAL_LABEL]: grandSubTotal
    },
    {
      ...columnAvgRow,
      ...yAxisAvgKeys,
      [GRAND_AVERAGE_LABEL]: Number(
        (totalNumberValueCount
          ? grandSubTotal / totalNumberValueCount
          : DEFAULT_VALUE_ZERO
        ).toFixed(ROUND_UP)
      )
    }
  ];

  return {
    transformData,
    dataSource: transformData,
    minValue,
    maxValue,
    columnSubTotalAndAvg
  };
};
