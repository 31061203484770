import type { FC } from "react";
import { ChevronDown, Messages } from "@certa/icons";
import { Paper, Text, Divider, getAvatarLetters } from "@certa/blocks/thanos";
import type {
  CommentChannel,
  CommentMention,
  CommentThreadMeta
} from "@certa/types";
import {
  AvatarColors,
  AvatarSizes,
  Avatar,
  ButtonSizes,
  ButtonTypes,
  Button,
  Stack,
  TypographyColors,
  TypographyVariants,
  Tooltip,
  Typography
} from "@certa/catalyst";
import { HierarchyBreadcrumbs } from "./HierarchyBreadcrumbs";
import { ChannelContext, useChannelContext } from "../../comments.context";
import moment from "moment";
import { useCommentMessageFormat } from "../../hooks/useCommentMessageFormat";
import { prepareMentionOptions } from "../../comments.utils";
import { ABSOLUTE_DATE_TIME_FORMAT, useScreenResolution } from "@certa/common";
import { MultiChannelWithTabs } from "./MultiChannelWithTabs";
import { css } from "emotion";
import { messageStyles } from "./CommentsRedesigned.styles";

const getAllThreadsCounts = (allThreads: CommentThreadMeta[]) =>
  allThreads.reduce((acc, thread) => acc + thread.messageCount, 0);

const ChannelListItemAppRedesign: FC<{
  handleChannelSelect: (
    channel: CommentChannel,
    threadWithLatestMessage: number
  ) => void;
}> = ({ handleChannelSelect }) => {
  const channel = useChannelContext();

  /**
   * Get all the latest messages from all the threads of the current channel
   * The latest message from every thread will be last array element
   *
   * Also extracting complete thread data here to use it for getting mentions
   */
  const latestMessageListFromAllThreads = channel.allThreads
    .filter(thread => thread.messageCount > 0)
    .map(thread => ({
      thread: thread,
      message:
        channel.threads[thread.threadId].messages[
          channel.threads[thread.threadId].messages.length - 1
        ]
    }));

  /**
   * Compare the dates of every latest message of all the threads
   * and get the greatest date, that will be out latest message
   */
  const latestMessage = latestMessageListFromAllThreads.reduce((a, b) =>
    new Date(a.message.createdAt) > new Date(b.message.createdAt) ? a : b
  );

  const mentions: CommentMention[] = prepareMentionOptions({
    threadUserGroups: latestMessage.thread.threadUserGroups || [],
    threadUsers: latestMessage.thread.threadUsers || []
  });

  const formattedMessage = useCommentMessageFormat(
    latestMessage.message.message,
    mentions,
    true
  );

  return (
    <Paper
      direction="vertical"
      gutter="s2 s4"
      className={css({
        cursor: "pointer",
        padding: "16px",
        borderRadius: "var(--small-border-radius)",
        boxShadow: "var(--shadow-100)",
        "&:hover": {
          backgroundColor: "var(--teal-60)",
          ".message-count": {
            backgroundColor: "var(--colors-neutral-200)",
            span: {
              color: "var(--colors-neutral-700)"
            }
          }
        }
      })}
      onClick={() =>
        handleChannelSelect(channel, latestMessage.thread.threadId)
      }
    >
      <Stack direction="horizontal" align="center" justify="space-between">
        <HierarchyBreadcrumbs containerWidth="86%" isReadOnly={true} />
        <Stack direction="horizontal" align="center" gap="16" padding="8 4">
          <Stack
            direction="horizontal"
            align="center"
            gap="4"
            dangerouslySetClassName={
              css({
                backgroundColor: "var(--neutral-20)",
                borderRadius: "var(--small-border-radius)"
              }) + "message-count"
            }
          >
            <Messages size={12} color="neutral-70" />
            <Typography
              variant={TypographyVariants.BODY_BOLD}
              color={TypographyColors.NEUTRAL_700}
            >
              {getAllThreadsCounts(channel.allThreads)}
            </Typography>
          </Stack>
          <Button
            rightIcon={<ChevronDown style={{ transform: `rotate(-90deg)` }} />}
            type={ButtonTypes.ICON}
            size={ButtonSizes.SMALL}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="vertical" padding="8 0" gap="16" align="start">
        <Stack direction="horizontal" justify="space-between" width="100%">
          <Stack direction="horizontal" align="center" justify="start" gap="4">
            <Avatar
              size={AvatarSizes.SMALL}
              color={AvatarColors.DARK}
              aria-label={latestMessage.message.postedBy}
            >
              {getAvatarLetters(latestMessage.message.postedBy)}
            </Avatar>
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_700}
            >
              {latestMessage.message.postedBy}
            </Typography>
          </Stack>
          <Stack direction="vertical" align="end" gap="8" width="8rem">
            <Tooltip
              content={moment(latestMessage.message.createdAt).format(
                ABSOLUTE_DATE_TIME_FORMAT
              )}
            >
              <Typography
                variant={TypographyVariants.LABEL_SM}
                color={TypographyColors.NEUTRAL_700}
              >
                {moment(latestMessage.message.createdAt)
                  .startOf("minutes")
                  .fromNow()}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
        <Text
          variant="p1-regular"
          color="neutral-70"
          lineClamp={3}
          className={messageStyles}
          dangerouslySetInnerHTML={{
            __html: formattedMessage
          }}
        />
      </Stack>
    </Paper>
  );
};

export type AllChannelsProps = {
  channels: CommentChannel[];
  handleChannelSelect: (
    channel: CommentChannel,
    threadWithLatestMessage: number
  ) => void;
  multiChannelWithTabs?: boolean;
};

const MultiChannelViewAppRedesign: FC<AllChannelsProps> = ({
  channels,
  handleChannelSelect,
  multiChannelWithTabs
}) => {
  /**
   * We only require only those channels which have at least
   * one message sent under any of it's thread
   */
  const filteredChannels = channels.filter(channel => {
    const totalMessagesInChannel = channel.allThreads.reduce(
      (total, thread) => total + thread.messageCount,
      0
    );
    return totalMessagesInChannel > 0;
  });

  const { isMobileResolution } = useScreenResolution();

  return (
    <Stack
      gap="16"
      direction="vertical"
      padding={isMobileResolution ? "16 8" : "16 24"}
      dangerouslySetClassName={css({
        paddingBottom: "var(--space-16)",
        height: multiChannelWithTabs ? "100%" : "auto"
      })}
    >
      {multiChannelWithTabs ? (
        <MultiChannelWithTabs
          filteredChannels={filteredChannels}
          handleChannelSelect={handleChannelSelect}
        />
      ) : (
        filteredChannels.map(channel => (
          <ChannelContext.Provider value={channel} key={`${channel.objectId}`}>
            <ChannelListItemAppRedesign
              handleChannelSelect={handleChannelSelect}
            />
          </ChannelContext.Provider>
        ))
      )}
    </Stack>
  );
};

export { MultiChannelViewAppRedesign };
