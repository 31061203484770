/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import React from "react";
import { getCSSToken } from "../utils";

/**
 * Cursor deciding algorithm
 * If Box has onClick function prop and cursor prop too, priority is given to the cursor prop
 * If Box has onClick function prop and no cursor prop, cursor: pointer CSS will be given
 * If neither onClick nor cursor, cursor: inherit prop is given
 * NOTE: Any CSS provided inside style will always take the highest priority ignoring the above three rules.
 */

export type BoxProps = {
  padding?: string;
  borderDirection?: "top" | "left" | "right" | "bottom";
  borderWidth?: number;
  borderStyle?: string;
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: number;
  cursor?: string;
  shadow?: string;
  hover?: string;
} & { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>;

const Box = React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const {
    padding,
    // TODO: [VET-24718] @rajatvijay to find a better way to do this
    borderDirection,
    borderWidth,
    backgroundColor,
    children,
    borderColor,
    borderStyle,
    borderRadius,
    // TODO: [VET-24605] Replace cursor prop with onClick to make it touchable
    cursor,
    shadow,
    hover,
    ...htmlProps
  } = props;
  const hasOnClick = typeof props.onClick === "function";
  return (
    <div
      {...htmlProps}
      ref={ref}
      css={[
        css`
          cursor: ${cursor ? cursor : hasOnClick ? "pointer" : "inherit"};
          padding: ${getCSSToken(padding || "0")};
          ${borderDirection
            ? `border-${borderDirection}: ${borderWidth}px ${borderStyle} ${borderColor}`
            : `border: ${borderWidth}px ${borderStyle} ${borderColor}`};
          border-radius: ${borderRadius ? borderRadius : 0}px;
          background-color: ${"var(--" + backgroundColor + ")"};
          box-shadow: ${shadow ? shadow : "unset"};
          ${hover ? `&:hover{background-color:${"var(--" + hover + ")"};}` : ""}
          --label: box;
        `
      ]}
    >
      {children}
    </div>
  );
});

Box.defaultProps = {
  padding: "0px",
  borderWidth: 0,
  borderStyle: "solid",
  backgroundColor: "inherit",
  borderColor: "black"
};

export { Box };
