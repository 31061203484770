import type { ReactElement } from "react";
import React from "react";
import { ClassNames } from "@emotion/core";
import {
  Tooltip,
  Stack,
  Text,
  Tag,
  HoverAbleSVG,
  LAYOUT_ID
} from "@certa/blocks/thanos";
import type { SelectOption } from "@certa/types";
import { useIntl } from "react-intl";
import { Eye, UserGroup } from "@certa/icons";

type Props = {
  accessibleBy: SelectOption[];
};

export default function AccessibleTagsPopover({
  accessibleBy
}: Props): ReactElement {
  const intl = useIntl();
  return (
    <ClassNames>
      {({ cx, css }) => (
        <Tooltip
          id="tooltip"
          placement="bottomRight"
          overlayClassName={css`
            &.ant-tooltip {
              .ant-tooltip-arrow:before {
                background: var(--neutral-0);
              }
              .ant-tooltip-inner {
                border-radius: var(--big-border-radius);
                background: var(--neutral-0);
                color: var(--neutral-70);
                font-size: var(--p3);
                line-height: var(--p3-line-height);
                font-weight: var(--regular);
                padding: var(--s4);
                min-height: 28px;
                min-width: 300px;
                align-items: center;
                & > * {
                  flex-grow: 1;
                }
              }
            }
          `}
          getPopupContainer={() =>
            document.getElementById(LAYOUT_ID) || document.body
          }
          overlay={
            <Stack direction="vertical" gap="s3" align={"flex-start"}>
              <Text variant="p2-bold-upper">
                {intl.formatMessage({
                  id: "tasklaneProgress.accessibleBy",
                  defaultMessage: "Accessible By"
                })}
              </Text>
              <Stack style={{ flexWrap: "wrap" }}>
                {accessibleBy?.map(({ label, value }) => (
                  <Tag
                    icon={<UserGroup color="neutral-100" autoSize />}
                    key={value}
                  >
                    <Text variant="p1-medium" color="neutral-100">
                      {label}
                    </Text>
                  </Tag>
                ))}
              </Stack>
            </Stack>
          }
        >
          <HoverAbleSVG icon={Eye} />
        </Tooltip>
      )}
    </ClassNames>
  );
}
