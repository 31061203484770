import type {
  HashMap,
  ChildWorkflowFieldMap,
  AlertsMap,
  ChildWorkflowField,
  ChildWorkflow,
  LCDataMap
} from "@certa/types";
import { get as lodashGet } from "lodash-es";
import type {
  ChildWorkflowServicePaginatedModel,
  ChildWorkflowServicePaginatedResponse
} from "../types/readonly.types";
import { queryClient } from "@certa/queries/src/utils/utils";
import { INDEX_ZERO } from "@certa/common";
import type {
  WorkflowFieldDynamicAPIResults,
  Field,
  FieldDef,
  Workflow
} from "../types/childWorkflow.types";

/**
 * Q: Why map? how can we ensure the ordering won't change?
 * A: https://www.stefanjudis.com/today-i-learned/property-order-is-predictable-in-javascript-objects-since-es2015/
 *
 * This map will also enable config team to directly map to any LCData without knowing
 * it's ordering in the array. It won't even be possible anyway since we're not
 * keeping any LCData without values, so array sequence would've inevitably changed.
 *
 * Another reason is that without using map, on filtering we'll hav eto run `find` on each rowset's property, which
 * is not good performance-wise.
 */

/**
 * Model creator for fields data that we get. Also includes an injector to make
 * sure certain values are available within the context of integration rows.
 * @param fieldsMap fieldMap that comes from the API response
 */
function createFieldModel(fieldsMap: HashMap): ChildWorkflowFieldMap {
  const fields: ChildWorkflowFieldMap = {};
  // for all the field tags
  Object.keys(fieldsMap || {}).forEach(fieldTag => {
    fields[fieldTag] = {
      answer: fieldsMap[fieldTag].answer,
      fieldType: fieldsMap[fieldTag].field_type,
      integrationJSON: fieldsMap[fieldTag].integration_json,
      extra: fieldsMap[fieldTag].extra,
      id: fieldsMap[fieldTag].field_id,
      tag: fieldTag
    } as ChildWorkflowField;
  });
  return fields;
}

const createWorkflowField = (
  fieldIds: Workflow["fields"],
  fields: Field[],
  fieldDefs: FieldDef[]
) => {
  const result: ChildWorkflowFieldMap = {};
  fieldIds.forEach(fieldId => {
    const field = fields.find(f => f.id === fieldId);
    const fieldDef = fieldDefs.find(f => f.id === field?.definition);
    if (field && fieldDef) {
      result[fieldDef.tag] = {
        id: field.id,
        tag: fieldDef.tag,
        fieldType: fieldDef.field_type,
        // BE provides us answers as an array
        // Since a field can only have one answer
        // we're only interested in the first one
        answer: field.answers[INDEX_ZERO]?.answer,
        integrationJSON: field.integration_json ?? {},
        extra: field.extra ?? {}
      };
    }
  });

  return result;
};

export function createChildWorkflowModel(response: any[]): ChildWorkflow[] {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  return response.map((item: any) => ({
    id: item.id,
    name: item.name,
    root: item.root,
    status: lodashGet(item.status, "display_name"),
    statusColorCode:
      availableStatusesKeyedById[lodashGet(item.status, "id")]?.color_code,
    selectedFlag: {
      reasonCodes: item.selected_flag.reason_codes,
      label: item.selected_flag.label,
      color: lodashGet(item.selected_flag, "extra.color"),
      tag: item.selected_flag.tag
    },
    lcData: createLCDataModel(item.lc_data),
    alerts: createAlertModel(item.alerts, item.lc_data),
    fields: createFieldModel(item.fields),
    commentCount: item.comment_count,
    createdAt: item.created_at,
    progress: item.progress,
    ...(item.status && item.status.display_name && item.status.id !== undefined
      ? {
          defaultStatus: {
            displayName: item.status.display_name,
            id: item.status.id
          }
        }
      : {})
  }));
}

export const createWorkflowFieldModel = (response: any) => {
  const {
    workflows = [],
    field_defs: fieldDefs = [],
    fields = []
  } = (response ?? {}) as WorkflowFieldDynamicAPIResults;
  const result = workflows.map(wf => {
    return {
      id: wf.id,
      fields: createWorkflowField(wf.fields, fields, fieldDefs)
    };
  });

  return result;
};

export function createChildWorkflowPaginatedModel(
  response: ChildWorkflowServicePaginatedResponse
): ChildWorkflowServicePaginatedModel {
  return {
    count: response.count,
    next: response.next,
    childWorkflows: createChildWorkflowModel(response.results),
    previous: response.previous
  };
}

/**
 * Similar to createChildWorkflowModel but wth certain missing items, including
 * - root
 * - selected_flag
 * - alerts
 * - comment_count
 * @param response
 */
export function createPackagedWorkflowModel(response: any): ChildWorkflow[] {
  return response.results.map((item: any) => ({
    id: item.id,
    name: item.name,
    root: item.root,
    status: lodashGet(item.status, "display_name"),
    selectedFlag: {},
    lcData: createLCDataModel(item.lc_data),
    alerts: createAlertModel([], item.lc_data),
    fields: createFieldModel(item.fields),
    commentCount: item.comment_count,
    createdAt: item.created_at
  }));
}

export function createTestCaseWorkflowModel(response: any): ChildWorkflow[] {
  return response.results.map((item: any) => ({
    id: item.id,
    name: item.name,
    createdAt: item.created_at,
    lcData: createLCDataModel(item.lc_data),
    status: lodashGet(item.status, "display_name")
  }));
}

/**
 * Creates model of LCData array and removes the following
 * - Those where `value` is undefined, null or "" (0,"0" and false are allowed)
 * - Those where display_type is not `normal`
 * - Those where `hide_from_workflow` is true
 * @param {Array<any>} lCData lcData received externally
 */
export function createLCDataModel(lcData: Array<any>): LCDataMap {
  const lcMap: LCDataMap = {};

  lcData.forEach((item: any) => {
    // clearing out data that we don't need (read function doc)
    if (
      !item.hide_from_workflow &&
      item.value !== null &&
      item.value !== undefined &&
      item.value !== "" &&
      item.display_type === "normal"
    ) {
      // creating the map
      lcMap[item.label] = {
        label: item.translated_label || item.label,
        value: item.value,
        format: item.format
      };
    }
  });
  return lcMap;
}

/**
 * This model merges and transforms the alerts framework alerts and lc data
 * alerts into one.
 * @param alerts Actual alerts from Alert framework
 * @param lcData Entire LC Data from response from which we'll extract the alerts
 */
export function createAlertModel(
  alerts: any[] = [],
  lcData: any[] = []
): AlertsMap {
  const alertMap: AlertsMap = {};

  // Extracting alerts from LC Data
  lcData
    .filter(
      item =>
        (item.display_type === "alert" ||
          item.display_type === "alert_status") &&
        !!item.value &&
        item.value !== "0" &&
        !item.hide_from_workflow
    )
    // transforming them to our own shape
    .forEach((item: any, index: number) => {
      alertMap[item.label] = {
        id: index * -1,
        fieldId: 0,
        stepId: 0,
        stepGroupId: 0,
        workflowId: 0,
        catColorLabel: "#D40000",
        alertTag: item.translated_label || item.label,
        value: item.value
      };
    });

  // transforming alert framework alerts into our own shape
  alerts.forEach((alert: any, index: number) => {
    alertMap[alert.alert_tag] = {
      id: alert.id,
      fieldId: alert.field_id,
      stepId: alert.step_id,
      stepGroupId: alert.step_group_id,
      workflowId: alert.workflow_id,
      catColorLabel: alert.cat_color_label,
      alertTag: alert.alert_tag
    };
  });

  return alertMap;
}
