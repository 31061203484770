import { get as lodashGet } from "lodash-es";
import type { Kind } from "@certa/types";

export const kindsModelCreator = (jsonFromAPI: any[]): Array<Kind> => {
  return jsonFromAPI.map((kind: any) => ({
    id: lodashGet(kind, "id", null),
    features: lodashGet(kind, "features", []),
    isSortingFieldEnabled: lodashGet(kind, "is_sorting_field_enabled", false),
    isRelatedKind: lodashGet(kind, "is_related_kind", false),
    name: lodashGet(kind, "name", null),
    tag: lodashGet(kind, "tag", null),
    icon: lodashGet(kind, "icon", null),
    availableStatuses: lodashGet(kind, "available_statuses", null),
    fieldTagsForFilter: lodashGet(
      kind,
      "field_tags_for_filter.0.options",
      []
    ).map((filterTag: any) => ({
      label: lodashGet(filterTag, "label", null),
      value: lodashGet(filterTag, "value", null)
    })),
    fieldFilterTag: lodashGet(kind, "field_tags_for_filter.0.tag", null),
    description: lodashGet(kind, "description")
  }));
};
