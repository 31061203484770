import { useState } from "react";
import { Eye } from "@certa/icons";
import { useIntl } from "react-intl";
import type { ImpersonateeUserListType } from "@certa/common/src/components/Header/types";
import {
  getUsersByKind,
  toCapital,
  useAppSelector,
  useCheckPermission,
  useEnableUserImpersonation
} from "@certa/common";
import { PermissionTypes } from "@certa/types";
import {
  DropdownSubMenu,
  DropdownSubMenuTrigger,
  DropdownSubMenuContent
} from "@certa/catalyst";
import { DROPDOWN_MENU_WIDTH } from "./constants";

import { WrapperWithIcon } from "../common";
import { ImpersonationUsersList } from "./ImpersonationUsersList";

export const ImpersonateAsUser = () => {
  const intl = useIntl();

  const canUserViewAs = useEnableUserImpersonation();
  const hasPermissionToImpersonate = useCheckPermission(
    PermissionTypes.CAN_IMPERSONATE_OTHER_USERS
  );

  const tenantName = toCapital(
    (useAppSelector(state => state.config.name) as string) || "certa"
  ) as Capitalize<string>;

  const usersByKind = getUsersByKind(tenantName);

  const getSubMenuTitle = () =>
    intl.formatMessage({
      id: "loginPageInstances.viewAs",
      defaultMessage: "View As"
    });

  return hasPermissionToImpersonate && canUserViewAs ? (
    <DropdownSubMenu key="impersonate-as-user">
      <DropdownSubMenuTrigger>
        <WrapperWithIcon icon={<Eye />}>{getSubMenuTitle()}</WrapperWithIcon>
      </DropdownSubMenuTrigger>
      <DropdownSubMenuContent width={DROPDOWN_MENU_WIDTH}>
        {usersByKind.map(user => (
          <UsersByKind user={user} key={user.name} />
        ))}
      </DropdownSubMenuContent>
    </DropdownSubMenu>
  ) : null;
};

const UsersByKind = ({ user }: { user: ImpersonateeUserListType }) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleSubmenuOpenChange = (isOpen: boolean) => {
    setIsSubmenuOpen(isOpen);
  };

  return (
    <DropdownSubMenu onOpenChange={handleSubmenuOpenChange}>
      <DropdownSubMenuTrigger>
        <WrapperWithIcon>{user.name}</WrapperWithIcon>
      </DropdownSubMenuTrigger>
      <ImpersonationUsersList kind={user.kind} isOpen={isSubmenuOpen} />
    </DropdownSubMenu>
  );
};
