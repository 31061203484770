export const workflowFiltersConstants = {
  GETALL_REQUEST: "FILTER_GETALL_REQUEST",
  GETALL_SUCCESS: "FILTER_GETALL_SUCCESS",
  GETALL_FAILURE: "FILTER_GETALL_FAILURE",

  SET_REQUEST: "FILTER_SET_REQUEST",
  SET_SUCCESS: "FILTER_SET_SUCCESS",
  SET_FAILURE: "FILTER_SET_FAILURE",

  DELETE_REQUEST: "FILTER_DELETE_REQUEST",
  DELETE_SUCCESS: "FILTER_DELETE_SUCCESS",
  DELETE_FAILURE: "FILTER_DELETE_FAILURE",

  GET_KIND_REQUEST: "KIND_FILTER_GET_REQUEST",
  GET_KIND_SUCCESS: "KIND_FILTER_GET_SUCCESS",
  GET_KIND_FAILURE: "KIND_FILTER_GET_FALIURE",

  GET_STATUS_REQUEST: "STATUS_FILTER_GET_REQUEST",
  GET_STATUS_SUCCESS: "STATUS_FILTER_GET_SUCCESS",
  GET_STATUS_FAILURE: "STATUS_FILTER_GET_FALIURE",

  GET_REGION_REQUEST: "REGION_FILTER_LIST_GET_REQUEST",
  GET_REGION_SUCCESS: "REGION_FILTER_LIST_GET_SUCCESS",
  GET_REGION_FAILURE: "REGION_FILTER_LIST_GET_FALIURE",

  GET_BUSINESS_UNIT_REQUEST: "BUSINESS_UNIT_FILTER_LIST_GET_REQUEST",
  GET_BUSINESS_UNIT_SUCCESS: "BUSINESS_UNIT_FILTER_LIST_GET_SUCCESS",
  GET_BUSINESS_UNIT_FAILURE: "BUSINESS_UNIT_FILTER_LIST_GET_FALIURE",
  CLEAR_FILTERS: "CLEAR_FILTERS"
};
