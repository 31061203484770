import { APIFetch, RequestHeaders } from "@certa/network";

const handleUpdateUserTimezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({ timezone })
  };

  return APIFetch("users/set_user_timezone/", requestOptions);
};

export const setUserTimezoneService = {
  handleUpdateUserTimezone
};
