import { useState, useMemo } from "react";
import { TaskCategoryTypes } from "@certa/tasks/src/types";
import {
  type UseTaskCategoryFiltersConfig,
  type UseTaskCategoryFiltersProps
} from "../../../../types";
import type { StepGroup } from "@certa/types";
import { getSubmittableIncompleteTasksCount } from "../utils/tasks";

const DEFAULT_TASK_COUNT = 0;

const useTaskCategoryFilters = ({
  initialFilter,
  tasklane
}: UseTaskCategoryFiltersProps): UseTaskCategoryFiltersConfig => {
  const [taskCategoryFilter, setTaskCategoryFilter] =
    useState<TaskCategoryTypes>(initialFilter || TaskCategoryTypes.ALL_TASKS);
  const taggedStepGroups: number[] = [];
  // change the results value based on filter.
  switch (taskCategoryFilter) {
    case TaskCategoryTypes.ME:
      tasklane?.myStepGroups?.forEach((stepGroup: StepGroup) => {
        taggedStepGroups.push(stepGroup.id);
      });

      break;
    case TaskCategoryTypes.MY_GROUPS:
      tasklane?.userStepGroups?.forEach((stepGroup: StepGroup) => {
        taggedStepGroups.push(stepGroup.id);
      });
      break;
  }

  const { allMyTasksCount, allMyGroupsCount } = useMemo(() => {
    return {
      allMyTasksCount:
        tasklane && tasklane.myStepGroups
          ? getSubmittableIncompleteTasksCount(tasklane.myStepGroups)
          : DEFAULT_TASK_COUNT,
      allMyGroupsCount:
        tasklane && tasklane.userStepGroups
          ? getSubmittableIncompleteTasksCount(tasklane.userStepGroups)
          : DEFAULT_TASK_COUNT
    };
  }, [tasklane]);

  return {
    taskCategoryFilter,
    setTaskCategoryFilter,
    tasklaneFilteredByCategory: tasklane,
    taggedStepGroups: taggedStepGroups,
    taskCounts: {
      [TaskCategoryTypes.ME]: allMyTasksCount || DEFAULT_TASK_COUNT,
      [TaskCategoryTypes.MY_GROUPS]: allMyGroupsCount || DEFAULT_TASK_COUNT
    }
  };
};
export { useTaskCategoryFilters };
