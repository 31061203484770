import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";

export const useGetMaintenanceMode = () => {
  return useSelector((state: ReduxState) => ({
    isMaintenanceModeEnabled: lodashGet(
      state,
      "config.configuration.is_maintenance_mode_enabled",
      false
    ),
    messageOnMaintenancePage: lodashGet(
      state,
      "config.configuration.message_on_maintenance_page",
      ""
    )
  }));
};
