import type { ReactNode } from "react";
import React from "react";

type MarkerProps = {
  id: string;
  className?: string;
  children: ReactNode;
};

const Marker = ({ id, className, children }: MarkerProps) => (
  <marker
    className={className || "react-flow__arrowhead"}
    id={id}
    markerWidth="12.5"
    markerHeight="12.5"
    viewBox="-10 -10 20 20"
    orient="auto"
    markerUnits="userSpaceOnUse"
    refX="0"
    refY="0"
  >
    {children}
  </marker>
);

type MarkerDefinitionsProps = {
  id: string;
  color: string;
};

export function MarkerDefinition({ color, id }: MarkerDefinitionsProps) {
  return (
    <defs>
      <Marker id={id}>
        <polyline
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          fill={"none"}
          points="-9,-8 0,0 -9,8"
        />
      </Marker>
    </defs>
  );
}
