import { css } from "emotion";

export const listBoxWrapperCSS = css({
  "&:focus": {
    // Hide outline for entire list box since we are focussing individual items
    outline: "none"
  }
});

export const listItemCSS = ({ focussed }: { focussed: boolean }) =>
  css({
    borderBottom: "1px solid var(--neutral-20)",
    borderRadius: "0.125rem",
    "&:focus-visible": {
      outline: focussed ? "0.125rem solid var(--colors-brand-400)" : "none",
      outlineOffset: "-2px"
    }
  });
