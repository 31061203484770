import { get as lodashGet } from "lodash-es";
import queryString from "query-string";
import type { UserMentionResponseType, UserMentionType } from "./interfaces";

/**
 * This method will generate a user mention thread URL on basis of its type( workflow | integrationresult | step | field )
 *
 * @param {UserMentionResponseType} userMention
 * @deprecated
 */
const getThreadURL = (userMention: UserMentionResponseType): string => {
  const {
    workflow,
    field,
    step,
    content_object_type: type,
    thread,
    integration_result: integrationResult
  } = userMention;

  const searchParams: any = {};
  searchParams["type"] = type;
  searchParams["thread_id"] = thread;

  if (field) {
    searchParams["field_id"] = field.id;
  }

  if (step) {
    searchParams["step"] = step.id;
    searchParams["group"] = step.step_group_id;
  }

  switch (type) {
    case "workflow":
      searchParams["object_id"] = workflow.id;
      break;
    case "integrationresult":
      if (integrationResult) {
        searchParams["uid"] = integrationResult.uid;
      }
      if (field) {
        searchParams["object_id"] = field.id;
      }
      break;
    case "step":
      if (step) {
        searchParams["object_id"] = step.id;
      }
      break;
    case "field":
      if (field) {
        searchParams["object_id"] = field.id;
      }
      break;
  }
  return `/workflows/instances/${workflow.id}?${queryString.stringify(
    searchParams
  )}`;
};

/**
 * @param {Array} UserMentions
 */
export const userMentionsModelCreator = (
  userMentionsArray: UserMentionResponseType[]
): UserMentionType[] => {
  return userMentionsArray.map(
    (userMention: UserMentionResponseType): UserMentionType => ({
      id: lodashGet(userMention, "id"),
      message: lodashGet(userMention, "message", ""),
      postedBy: lodashGet(userMention, "posted_by.email", ""),
      workflowName: lodashGet(userMention, "workflow.name", ""),
      createdAt: lodashGet(userMention, "created_at"),
      threadURL: getThreadURL(userMention),
      fieldName: lodashGet(userMention, "field.body", null),
      stepName: lodashGet(userMention, "step.name", null),
      isRead: lodashGet(userMention, "is_read", false)
    })
  );
};
