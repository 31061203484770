import type { FC } from "react";
import { useRef, useEffect } from "react";
import type { CellProps } from "../types";
import { AVERAGE_LABEL, SUB_TOTAL_LABEL } from "../constants";
import { getBackgroundColor } from "../utils/cellUtils";

export const Cell: FC<CellProps> = props => {
  const {
    activeColumnIdentifier,
    cellBaseColors,
    cellValue,
    cellFiltersData,
    columnHeaderKey,
    dataCellFormatter,
    onClick,
    rowRecord,
    rowIndex,
    columnIndex,
    ratio,
    style
  } = props;
  const cellRef = useRef<HTMLDivElement>(null);
  const isSubTotalOrAverage = [SUB_TOTAL_LABEL, AVERAGE_LABEL].includes(
    columnHeaderKey
  );

  // #GET_ROW_AND_COL_INDEX_FOR_HIGHLIGHT
  // search the above string to find references of where
  // we are using this activeXIdentifier for row and column index
  const [activeRowIndex, activeColumnHeaderKey] =
    `${activeColumnIdentifier}`?.split("---");
  const isActiveCell =
    Number(activeRowIndex) === rowIndex &&
    activeColumnHeaderKey === columnHeaderKey;

  const backgroundColor = getBackgroundColor(
    ratio,
    isSubTotalOrAverage,
    cellBaseColors
  );

  const formatedCellValue =
    dataCellFormatter && cellValue !== undefined
      ? dataCellFormatter(cellValue, columnHeaderKey, rowRecord)
      : cellValue;

  const onClickPayload = {
    columnIndex,
    rowIndex,
    columnHeaderKey,
    cellValue,
    cellFiltersData,
    formatedCellValue,
    rowRecord
  };

  useEffect(() => {
    const el = cellRef.current;
    if (el?.parentElement)
      el.parentElement.style.backgroundColor = isActiveCell
        ? "var(--neutral-50)"
        : "var(--neutral-0)";
  }, [isActiveCell]);

  return (
    <div
      ref={cellRef}
      className="heat-map-cell-div"
      style={{
        color: `rgba(0, 0, 0, 0.8)`,
        cursor: onClick ? "pointer" : "initial",
        background: backgroundColor,
        ...style?.(cellValue, columnHeaderKey, rowRecord, rowIndex)
      }}
      onClick={e => onClick?.(onClickPayload, e)}
    >
      {formatedCellValue}
    </div>
  );
};
