import { css } from "emotion";

export const filterButtonStyles = css({
  backgroundColor: "var(--colors-neutral-100)",
  width: "7.125rem",
  padding: "var(--space-8) var(--space-12)",
  borderRadius: "10.3125rem",
  height: "2rem",
  border: "1px solid var(--colors-neutral-400)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  boxShadow: "none",
  outline: "none",
  "&.active": {
    backgroundColor: "var(--colors-neutral-700)",
    border: "1px solid var(--colors-neutral-700)"
  },
  "&:hover": {
    backgroundColor: "var(--colors-neutral-700)",
    border: "1px solid var(--colors-neutral-700)"
  },
  "&:hover> h5 > span, &.active > h5 > span": {
    color: "var(--colors-neutral-100)"
  },
  "&.focus-visible": {
    outline: "0.125rem solid var(--colors-brand-400)"
  }
});

export const buttonFilterContainerStyles = css({
  gap: "var(--s2)"
});
