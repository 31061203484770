/**
 * Contains query hooks for getting data related to user
 */
import type {
  UseMutationExtraConfigType,
  UseQueryExtraConfigType
} from "@certa/common";
import { useQuery, useMutation } from "react-query";
import { userServices } from "../services/user.services";

const profileFieldTags = ["preferred_region"];

/**
 * Pure function that returns the query key based on given workflowId
 * @param workflowId
 */
export const getUserProfileDataQueryKey = (workflowId: number) => [
  "userProfileData",
  workflowId,
  profileFieldTags[0]
];

export const useUserProfileData = (workflowId: number) => {
  const queryKey = getUserProfileDataQueryKey(workflowId);
  return useQuery(
    queryKey,
    () => userServices.getUserProfileData(workflowId, profileFieldTags[0]),
    {
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!workflowId,
      initialData: {}
    }
  );
};

export const useUserRecentActivity = (config?: UseQueryExtraConfigType) => {
  return useQuery(
    "userRecentActivity",
    userServices.getUserRecentActivity,
    config
  );
};

export const useUpdateUserRecentActivity = (
  extraConfig?: UseMutationExtraConfigType
) => {
  return useMutation(userServices.updateUserRecentActivity, extraConfig);
};
