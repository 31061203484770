import type { FC, PropsWithChildren } from "react";
import { createContext, useState, useContext } from "react";

type DrawerContextType = {
  isDashboardDrawerOpen: boolean;
  setIsDashboardDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchModalOpen: boolean;
  setIsSearchModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type DrawerProviderProps = PropsWithChildren<{}>;

/**
 * Provides a context for managing the state of a side navigation drawer.
 *
 * This context allows components within the provider to open or close the side navigation drawer
 * by accessing `isSideNavDrawerOpen` to check the drawer's state (open or closed) and
 * `setIsSideNavDrawerOpen` to change the state.
 *
 * @example
 * const { isSideNavDrawerOpen, setIsSideNavDrawerOpen } = useContext(DrawerContext);
 * setIsSideNavDrawerOpen(!isSideNavDrawerOpen); // Toggle drawer open/close state
 */
const DrawerContext = createContext<DrawerContextType>({
  isDashboardDrawerOpen: false,
  setIsDashboardDrawerOpen: () => {},
  isSearchModalOpen: false,
  setIsSearchModalOpen: () => {}
});

export const DrawerProvider: FC<DrawerProviderProps> = ({ children }) => {
  const [isDashboardDrawerOpen, setIsDashboardDrawerOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  return (
    <DrawerContext.Provider
      value={{
        isDashboardDrawerOpen,
        setIsDashboardDrawerOpen,
        isSearchModalOpen,
        setIsSearchModalOpen
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

// Create a custom hook to use the drawer context
export const useNavigationDrawer = () => {
  return useContext(DrawerContext);
};
