import type {
  AggregateModelledResponse,
  ChartData,
  YAxisChart
} from "../types";
import {
  commonXAxisLabel,
  getAggregateMetricType
} from "../constants/chartConfig";
import { INDEX_ZERO } from "../constants/common";

export type ProcessCycleChartAPIResponseType = (string[] | number | null)[];

export const matricObject: Record<string, string> = {
  min: "Minimum Cycle Time",
  max: "Maximum Cycle Time",
  avg: "Average Cycle Time"
};

const getAggregateMetricValues = (
  acc: Record<string, ChartData>[],
  cur: string,
  index: number,
  values: (number | string[] | null)[],
  XAxisKey: string
): Record<string, ChartData>[] => {
  if (index % 3 === INDEX_ZERO) {
    const [min, max, avg] = values.slice(index, index + 3);
    if (Array.isArray(min) || Array.isArray(max) || Array.isArray(avg))
      return acc;
    return [
      ...acc,
      {
        [XAxisKey]: cur,
        [matricObject["min"]]: min,
        [matricObject["max"]]: max,
        [matricObject["avg"]]: avg
      }
    ];
  }
  return acc;
};

export const stepCycleChartModelCreator = (
  data: ProcessCycleChartAPIResponseType,
  isSwimlaneLevelCycleTime: boolean,
  metrics: YAxisChart | undefined
): AggregateModelledResponse => {
  const X_AXIS_KEY = isSwimlaneLevelCycleTime
    ? commonXAxisLabel.PROCESS_SWIMLANES
    : commonXAxisLabel.ALL_STEPS;
  if (!data || !metrics)
    return { xAxisKey: X_AXIS_KEY, probableDataType: "cycle-time", data: [] };
  const [labels, ...values] = data;

  const metricsType = getAggregateMetricType(metrics);
  let results: Record<string, ChartData>[] = [];
  if (metricsType === "all" && Array.isArray(labels) && Array.isArray(values)) {
    results = labels?.reduce(
      (acc, cur, index): Record<string, ChartData>[] =>
        getAggregateMetricValues(acc, cur, index, values, X_AXIS_KEY),
      [] as Record<string, ChartData>[]
    );
  } else if (Array.isArray(labels) && Array.isArray(values)) {
    results = labels?.map((label, index) => {
      const returnObject: Record<string, ChartData> = {
        [X_AXIS_KEY]: label
      };
      const metric = matricObject[metricsType];
      const value = values[index];
      if (typeof value === "number" || value === null) {
        returnObject[metric] = value;
      }
      return returnObject;
    });
  }
  return {
    xAxisKey: X_AXIS_KEY,
    probableDataType: "cycle-time",
    data: results
  };
};
