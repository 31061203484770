import styled from "@emotion/styled";
import { InputNumber as AntInputNumber } from "antd";
import type { InputNumberProps as AntInputNumberProps } from "antd/lib/input-number";
import { getVariantStyles } from "../Typography/utils";

export type InputNumberProps = {
  error?: boolean;
} & AntInputNumberProps;

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { NumberInput } from '@certa/catalyst';
 */
export const InputNumber = styled(AntInputNumber)<InputNumberProps>`
  && {
    font-family: var(--font-family);
    height: calc(var(--s10) + 2px);
    color: ${({ error }) => (error ? "var(--red)" : "var(--neutral-100)")};
    background-color: ${({ error }) =>
      error ? "var(--red-60)" : "var(--neutral-5)"};
    border: 1px solid
      ${({ error }) => (error ? "var(--red)" : "var(--neutral-5)")};
    border-radius: var(--big-border-radius);
    box-shadow: none;
    padding: 0 var(--s4);
    line-height: var(--h3-regular-line-height);
    width: 100%;

    & .ant-input-number-handler-wrap {
      opacity: 1;
      border-radius: 0 8px 8px 0;
      border-left: none;
    }

    & .ant-input-number {
      border-right-width: 1px !important;
    }

    &.ant-input-number-focused {
      box-shadow: 0 0 0 2px
        ${({ error }) => (error ? "var(--red-40)" : "var(--brand-15)")};
    }

    & .ant-input-number-input {
      ${getVariantStyles("h3-regular")}
      color: ${({ error }) => (error ? "var(--red)" : "var(--neutral-100)")};
      background-color: ${({ error }) =>
        error ? "var(--red-60)" : "var(--neutral-5)"};
      height: var(--s10);
      padding: 0;

      &::placeholder {
        ${getVariantStyles("h3-regular")}
        color: var(--neutral-70);
      }
    }

    & .ant-input-number-input-wrap {
      height: var(--s10);
    }

    &.ant-input-number-out-of-range {
      color: var(--red);
      background-color: var(--red-60);
    }

    &.ant-input-number-disabled {
      color: var(--neutral-70);
      background-color: var(--neutral-10);
      border: 1px solid var(--neutral-20);

      & .ant-input-number-input {
        color: var(--neutral-70);
        background-color: var(--neutral-10);
      }
    }

    .ant-input-group-addon {
      border: 1px solid
        ${({ error }) => (error ? "var(--red)" : "var(--neutral-5)")};
      background: var(--neutral-5);
      color: var(--neutral-100);
      padding: 0;
      border-radius: var(--s2) 0 0 var(--s2);
      & + .ant-input-affix-wrapper-focused {
        &:focus-within {
          box-shadow: 0 0 0 2px
            ${({ error }) => (error ? "var(--red-40)" : "var(--brand-15)")};
        }
      }
    }

    .ant-input-number-handler {
      height: 50% !important;
      visibility: visible !important;
      background-color: var(--brand-15);
      &:hover {
        background-color: var(--brand);
      }
      & .ant-input-number-handler-up-inner,
      & .ant-input-number-handler-down-inner {
        color: #ffffff;
      }
    }
    & .ant-input-number-handler-up-inner {
      &:after {
        content: "+" !important;
        font-size: 16px;
        text-align: center;
        line-height: 9px;
      }
    }
    & .ant-input-number-handler-down-inner {
      &:after {
        content: "-" !important;
        font-size: 21px;
        width: 8px;
        line-height: 10px;
      }
    }

    & .ant-input-number-handler-up {
      border-top-right-radius: 8px;
      svg {
        display: none;
      }
    }
    & .ant-input-number-handler-down {
      svg {
        display: none;
      }
      border-bottom-right-radius: 8px;
      border-top: 1px solid #fff;
    }
  }
`;
