import React, { useCallback } from "react";
import {
  hasStorageAccess,
  requiresStoragePermissions,
  requestStorageAccess
} from "../utils/storageHelpers";
import { showMessage } from "../components/showMessage";

/**
 *  Why do we need this?
 *  This was created to handle the case where the user has not given storage permissions to the app.
 *  This is more specifically for Safari Browsers.
 *  Doc link:
 *   1. https://stackoverflow.com/questions/59723056/safari-13-iframe-blocks-cors-cookies
 *   2. https://webkit.org/blog/11545/updates-to-the-storage-access-api/
 *
 */

export const useStoragePermissions = (): {
  isPermissionNeeded: boolean;
  askForPermission: () => void;
  hasCheckedPermission: boolean;
} => {
  const [isPermissionNeeded, setIsPermissionNeeded] = React.useState(
    requiresStoragePermissions() ? true : false
  );
  const [hasCheckedPermission, setHasCheckedPermission] = React.useState(false);

  const isHavingPermissionFn = useCallback(async () => {
    try {
      return await hasStorageAccess();
    } catch (e: any) {
      if (e && e.message && typeof e.message === "string") {
        showMessage("error", e.message);
      }
      return false;
    }
  }, []);

  const checkPermission = useCallback(() => {
    isHavingPermissionFn().then((isHavingPerm: boolean) => {
      setIsPermissionNeeded(!isHavingPerm);
      setHasCheckedPermission(true);
    });
  }, [isHavingPermissionFn]);

  const askForPermission = useCallback(async () => {
    try {
      await requestStorageAccess();
      checkPermission();
    } catch (e: any) {
      if (e && e.message && typeof e.message === "string") {
        showMessage("error", e.message);
      }
    }
  }, [checkPermission]);

  React.useEffect(() => {
    if (requiresStoragePermissions()) {
      checkPermission();
    }
  }, [checkPermission]);

  return {
    isPermissionNeeded,
    askForPermission: requiresStoragePermissions()
      ? askForPermission
      : () => {},
    hasCheckedPermission
  };
};
