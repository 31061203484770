import { css } from "emotion";

export const globalSelectStyles = css`
  .global-select__menu-portal > .global-select__menu * {
    font-family: var(--global-font-family) !important;
  }

  .global-select__menu-portal > .global-select__menu {
    flex-direction: column;
  }

  .global-select__menu-portal .global-select__menu-list {
    overflow-x: hidden;
  }

  .global-select__option > div {
    width: 100%;
  }

  .global-select__option .global-select__option-container {
    border-top: 1px solid var(--colors-neutral-400);
    padding: 0.5rem;
    width: 100%;
  }

  .global-select__option .global-select__option-container {
    border-top: 1px solid var(--colors-neutral-400);
    padding: 0.5rem;
    width: 100%;
    min-height: 3.75rem;
    justify-content: center;

    &.global-select__workflow-option-container {
      min-height: 4.6875rem;
    }
  }

  .global-select__option:first-child .global-select__option-container {
    border-top: 1px solid transparent;
  }

  .global-select__control
    .global-select__value-container
    div.global-select__input-container {
    position: inherit;
    top: inherit;
  }

  .global-select__group-heading {
    margin-top: var(--s4);
  }

  .global-select__value-container > .global-select__placeholder {
    color: var(--colors-neutral-500);
    font-weight: 600;
  }
`;

export const globalSelectMinSpaceContainer = css({
  minHeight: "14.25rem",
  minWidth: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export const globalSelectClearButton = css({
  cursor: "pointer"
});
