import type { FC } from "react";
import React from "react";
import { css } from "emotion";
import { FlagTwoTone } from "@ant-design/icons";

export const Banner: FC<{ message: string; color: string }> = ({
  message,
  color
}) => (
  <div
    id="message-banner"
    className={css`
      background: #fff;
      width: 100%;
      text-align: center;
      height: 40px;
      color: ${color || "#138bd4"};
      font-weight: bold;
      font-size: 18px;
      background: white;
      padding-top: 8px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      background: #faebd7;
    `}
  >
    <FlagTwoTone
      twoToneColor={color || "#138bd4"}
      className={css`
        margin-right: 10px;
      `}
    />
    {message}
  </div>
);
