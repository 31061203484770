import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";
import { isSupplierUser } from "main/src/modules/common/selectors";
import { DYNAMIC_GROUP_REGEX } from "../constants";
import type { UserGroup } from "@certa/types";

export type DropdownOption = {
  label: string;
  url?: string;
  user_groups: string[];
  third_party_groups: boolean;
  tag?: string;
};

export const useFilterHeaderOptionBasedOnUserGroup = (
  dropdownOptions: DropdownOption[]
) => {
  const isSupplier = useSelector(isSupplierUser);
  const currentUserGroups: UserGroup[] = useSelector((state: ReduxState) =>
    lodashGet(state, "authentication.user.groups", [])
  );

  const staticUserGroups: string[] = [];
  const dynamicUserGroupsSuffix: string[] = [];

  currentUserGroups.forEach(userGroup => {
    const isDynamicGroup = userGroup.name.match(DYNAMIC_GROUP_REGEX);
    if (isDynamicGroup) {
      dynamicUserGroupsSuffix.push(isDynamicGroup[1] || "");
    } else {
      staticUserGroups.push(userGroup.name);
    }
  });

  const dropdownOptionsWithPermissions: DropdownOption[] = [];

  // below algorithm based on https://user-images.githubusercontent.com/11032856/215700305-8fed14e5-e86c-4b51-8ea7-55e449d540aa.png
  // Each dropdown option should only be shown once
  // Hence we need to break out of the loop once we push the option
  dropdownOptions.forEach(option => {
    const isWhiteListingEnabled = option.user_groups?.length ? true : false;
    if (isSupplier) {
      if (isWhiteListingEnabled) {
        for (const group of option.user_groups) {
          if (
            dynamicUserGroupsSuffix.includes(group) &&
            option.third_party_groups
          ) {
            dropdownOptionsWithPermissions.push(option);
            break;
          }
        }
      } else if (option.third_party_groups) {
        dropdownOptionsWithPermissions.push(option);
        return;
      }
    } else {
      if (isWhiteListingEnabled) {
        for (const group of option.user_groups) {
          if (staticUserGroups.includes(group)) {
            dropdownOptionsWithPermissions.push(option);
            break;
          }
        }
      } else {
        dropdownOptionsWithPermissions.push(option);
        return;
      }
    }
  });

  return dropdownOptionsWithPermissions;
};
