import React, { useContext } from "react";

type AccordionContextProps = {
  toggle: (() => void) | undefined;
  expanded: boolean;
};

export const AccordionContext = React.createContext<AccordionContextProps>({
  toggle: undefined,
  expanded: false
});

export const useAccordionContext = () => {
  const contextValue = useContext(AccordionContext);

  if (typeof contextValue === "undefined")
    throw Error(
      "useAccordionContext is being used outside of Accordion's range"
    );

  return contextValue;
};
