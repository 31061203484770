import { Tabs as AntTabs } from "antd";

/**
 * Creating a wrapper on top of antd
 * Use this everywhere instead of directly referencing antd Tabs
 * Later: Style overrides according to the new design system
 * Dummy Wrapper as of now!
 */
const Tabs = AntTabs;
const TabPane = AntTabs.TabPane;

export { Tabs, TabPane };
