import { ClassNames } from "@emotion/core";
import type { FC } from "react";
import React, { forwardRef } from "react";
import { Select as AntSelect } from "antd";
import type {
  SelectProps as AntdSelectProps,
  SelectValue as AntdSelectValue
} from "antd/lib/select";
import type { IconProps } from "@certa/icons";
import { Caret, Close, Spinner, Check } from "@certa/icons";
import { Stack } from "../Stack";
import { Text } from "../Typography";
import { getVariantStyles } from "../Typography/utils";
import { getLayoutContainer } from "../../utils";
import { COPY_TEXT } from "@certa/fields/src/util/copyText";
import { useIntl } from "react-intl";

type ValueType = string | number;

type LabelValueType = {
  key?: ValueType;
  value?: ValueType;
  label?: React.ReactNode;
};

export type DefaultValueType =
  | ValueType
  | ValueType[]
  | LabelValueType
  | LabelValueType[];

export type CustomTagProps = {
  label: DefaultValueType;
  value: DefaultValueType;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
};

export type SelectValue = AntdSelectValue;
export type SelectProps<T = SelectValue> = {
  error?: boolean;
  icon?: FC<IconProps>;
  onClear?: () => void;
  // This is done so that we can pass a RenderDOMFunc that can potentially return a null value
  // which then will be handled at this component level.
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
} & Omit<AntdSelectProps<T>, "getPopupContainer" | "size">;

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Select } from '@certa/catalyst';
 *
 * @update 28/12/2023
 * This component can be completely replaced by catalyst's select if we make updates
 * in catalyst for the following:
 * 1.) Add functionality for "tagRender" --> Custom selection tags in case of multi select
 * 2.) Either add or internally handle the functionality for "getPopupContainer"
 * 3.) Check for handling paginated responses.
 */
const Select = forwardRef<AntSelect<AntdSelectValue>, SelectProps<SelectValue>>(
  (props, ref) => {
    const {
      error,
      loading,
      placeholder,
      icon: Icon,
      optionFilterProp,
      dropdownClassName,
      className,
      onClear,
      getPopupContainer,
      inputValue,
      ...restProps
    } = props;
    const intl = useIntl();
    return (
      <ClassNames>
        {({ css, cx }) => (
          <AntSelect
            ref={ref}
            placeholder={
              <Stack align="center" gap="s2" itemWidth="100%">
                {Icon && <Icon color={error ? "red" : undefined} />}
                <Text variant="h3-regular" ellipsis="inherit">
                  {placeholder}
                </Text>
              </Stack>
            }
            showArrow={true}
            loading={loading}
            suffixIcon={loading ? <Spinner /> : <Caret />}
            removeIcon={<Close size={10} />}
            clearIcon={<Close onClick={onClear} />}
            menuItemSelectedIcon={<Check />}
            optionFilterProp={optionFilterProp ?? "label"}
            inputValue={inputValue}
            notFoundContent={
              <Stack direction="vertical" gutter="s4" align="center">
                {loading ? (
                  <Spinner color="brand" />
                ) : (
                  <Text variant="p1-regular" color="neutral-70">
                    {/**
                     * If input value is present and not itmes found, show "no match found" otherwise,
                     * show "no items available."
                     */}

                    {inputValue
                      ? intl.formatMessage({
                          id: "select.noMatchFound",
                          defaultMessage: COPY_TEXT.NO_MATCH_FOUND
                        })
                      : intl.formatMessage({
                          id: "select.noResultsFound",
                          defaultMessage: COPY_TEXT.NO_ITEMS_AVAILABLE
                        })}
                  </Text>
                )}
              </Stack>
            }
            getPopupContainer={triggerNode =>
              getPopupContainer?.(triggerNode) ||
              triggerNode ||
              getLayoutContainer()
            }
            dropdownClassName={cx(
              css`
                & * {
                  font-family: "Inter", sans-serif, "system-ui";
                }

                && {
                  /* Popup styling */
                  background-color: var(--neutral-0);
                  box-shadow: var(--popover-shadow);
                  padding: var(--s00);
                  border-radius: var(--s2);
                  overflow: auto;
                  padding: var(--s2) 0;
                  div > div + div {
                    max-height: 237px;
                  }
                }

                /* Fix for dropdown scrollbar */
                & > div > div:nth-child(2) {
                  padding: 0 var(--s2);
                }

                /* Option Styling */
                & .ant-select-item {
                  transition:
                    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                    background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

                  &.ant-select-item-group {
                    ${getVariantStyles("p2-bold-upper")}
                    color: var(--neutral-70) !important;
                    padding: var(--s5) var(--s2) var(--s2) var(--s2);
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;

                    &:first-child {
                      padding: var(--s3) var(--s2) var(--s2) var(--s2);
                    }

                    &:empty {
                      display: none;
                    }
                  }

                  &.ant-select-item-option {
                    ${getVariantStyles("p1-regular")}
                    color: var(--neutral-100) !important;
                    padding: var(--s3) var(--s2) !important;
                    border-radius: var(--s1);
                    display: flex;
                    align-items: center;

                    .ant-select-item-option-content {
                      line-height: 20px;
                      text-overflow: unset !important;
                      overflow: unset !important;
                      white-space: unset !important;
                    }
                  }
                }

                /* Selected Option and Hovered-over(active) option */
                .ant-select-item-option-active:not(
                    .ant-select-item-option-disabled
                  ) {
                  background-color: var(--neutral-10);
                  color: var(--brand) !important;
                }

                .ant-select-item-option-selected {
                  background-color: var(--teal-40);
                  font-weight: 400;
                }

                .ant-select-item-option-state {
                  color: var(--brand) !important;
                  height: 15px;
                  margin-left: var(--s1);
                }
              `,
              dropdownClassName
            )}
            className={cx(
              css`
                & * {
                  font-family: "Inter", sans-serif, "system-ui";
                }

                &.ant-select {
                  border: none !important;
                  outline: none;

                  .ant-select-selector {
                    padding: 0 var(--s4);
                    min-height: calc(var(--s10) + 2px) !important;
                    border-radius: var(--s2);
                    background-color: ${error
                      ? "var(--red-60)"
                      : "var(--neutral-5)"};
                    border: 1px solid
                      ${error ? "var(--red)" : "var(--neutral-5)"} !important;
                    outline: none;
                    transition: none;
                    .ant-select-selection-placeholder {
                      min-height: var(--s10) !important;
                      padding-right: var(--s4);
                      ${getVariantStyles("h3-regular")}
                      line-height: var(--s10);
                      color: var(--neutral-70);
                      opacity: 1;
                      display: flex;
                    }

                    .ant-select-selection-search {
                      left: var(--s4);
                      right: var(--s8);
                      display: flex;
                      align-items: center;

                      .ant-select-selection-search-input {
                        padding: 0;
                        ${getVariantStyles("h3-regular")}
                        color: var(--neutral-100);
                      }
                    }

                    .ant-select-selection-item {
                      min-height: var(--s10) !important;
                      padding-right: var(--s5);
                      ${getVariantStyles("h3-regular")}
                      line-height: var(--s10);
                      color: ${error
                        ? "var(--red)"
                        : "var(--neutral-100)"} !important;
                    }

                    &:hover,
                    &:focus,
                    &:focus-visible {
                      border: 1px solid
                        ${error ? "var(--red)" : "var(--neutral-5)"} !important;
                      outline: none;
                    }
                  }

                  .ant-select-arrow {
                    width: var(--s4);
                    height: var(--s4);
                    color: ${error ? "var(--red)" : "var(--brand-15)"};
                    transform: rotate(0deg);
                    transition: transform 0.3s ease;
                    pointer-events: none;
                    top: 48%;
                  }

                  .ant-select-clear {
                    width: var(--s4);
                    height: var(--s4);
                    background-color: var(--neutral-5);
                    outline: var(--s1) solid var(--neutral-5);
                    top: 48%;
                  }

                  &.ant-select-open {
                    .ant-select-arrow {
                      transform: rotate(180deg);
                    }
                  }

                  /* SYNC: Check design for blue outline in error state */
                  &.ant-select-focused {
                    .ant-select-selector {
                      box-shadow: 0 0 0 2px
                        ${error ? "var(--red-40)" : "var(--brand-15)"};
                      border: 1px solid
                        ${error ? "var(--red)" : "var(--neutral-5)"} !important;

                      .ant-select-selection-item {
                        color: var(--neutral-70);
                      }
                    }
                  }
                }

                /* Single mode */
                &.ant-select-single {
                  .ant-select-selection-item {
                    display: inline-block;
                  }
                }

                /* Multiple mode */
                &.ant-select-multiple {
                  .ant-select-selector {
                    padding: 0 var(--s7) 0 var(--s4);
                    .ant-select-selection-placeholder {
                      left: var(--s4);
                      right: var(--s4);
                    }

                    .ant-select-selection-search {
                      left: unset;
                      right: unset;

                      .ant-select-selection-search-input {
                        /* min-height: var(--s7); */
                      }

                      &:first-child {
                        .ant-select-selection-search-input {
                          margin-left: 0;
                        }
                      }
                    }

                    .ant-select-selection-item {
                      min-height: var(--s6) !important;
                      align-items: center;
                      background-color: ${error
                        ? "var(--red-40)"
                        : "var(--brand-35)"};
                      border: none !important;
                      border-radius: var(--s1);
                      line-height: var(--s6);
                      padding: 0 0 0 6px;

                      .ant-select-selection-item-content {
                        ${getVariantStyles("p1-semibold")}
                        color: ${error ? "var(--red)" : "var(--neutral-100)"};
                      }

                      .ant-select-selection-item-remove {
                        padding: 0 var(--s2) 0 var(--s1);
                        color: ${error ? "var(--red)" : "var(--brand)"};
                        font-size: unset;
                      }

                      &:hover {
                        .ant-select-selection-item-content {
                          color: ${error ? "var(--red)" : "var(--brand)"};
                        }
                      }
                    }
                  }
                }

                /* Disabled state */
                &&.ant-select-disabled {
                  .ant-select-selector {
                    background-color: var(--neutral-5);
                    border: 1px solid var(--neutral-20) !important;

                    .ant-select-selection-item {
                      color: var(--neutral-70) !important;
                    }
                  }

                  .ant-select-arrow > span {
                    border-top-color: var(--neutral-50);
                  }

                  &.ant-select-multiple {
                    .ant-select-selector {
                      .ant-select-selection-item {
                        background-color: var(--neutral-50);
                        padding: 0 6px 0 6px;

                        .ant-select-selection-item-content {
                          color: var(--neutral-70);
                          margin-right: 0;
                        }
                        .ant-select-selection-item-remove {
                          display: none;
                        }
                      }
                    }
                  }
                }

                &:not(.ant-select-disabled):hover .ant-select-selector {
                  border-color: ${error ? "var(--red)" : "var(--neutral-5)"};
                }
              `,
              className
            )}
            {...restProps}
          />
        )}
      </ClassNames>
    );
  }
);

const SelectOption = AntSelect.Option;
const SelectOptGroup = AntSelect.OptGroup;

export { Select, SelectOption, SelectOptGroup };
