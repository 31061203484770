import { Collapse as AntCollapse } from "antd";
import styled from "@emotion/styled";

const Collapse = styled(AntCollapse)`
  &.ant-collapse {
    border: none;
    background: transparent;
  }

  .ant-collapse-item {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    margin-bottom: var(--s2);
  }

  .ant-collapse-item.ant-collapse-item-active {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow:
      0px 2px 6px rgba(0, 0, 0, 0.08),
      0px 12px 18px rgba(0, 0, 0, 0.11);
    border-radius: 6px;

    .ant-collapse-header {
      color: var(--primary-color);
    }
  }

  .ant-collapse-header {
    box-shadow: none;
    border-bottom: none;
  }

  .ant-collapse-content.ant-collapse-content-active {
    border-top: medium none;
    background: white none repeat scroll 0% 0%;
  }

  .ant-collapse-content-box {
    padding: 0;
  }
`;

const Panel = AntCollapse.Panel;
export { Collapse, Panel };
