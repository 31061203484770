import type { FC } from "react";
import React, { useRef } from "react";
import styled from "@emotion/styled";
import { css, cx } from "emotion";
import { Link } from "@certa/icons";
import type { InputProps } from "./Input";
import { Input } from "./Input";
import { Stack } from "../Stack";
import { Button } from "../Button";
import { notification } from "../Notification/notification";
import type { default as AntInput } from "antd/lib/input";

export type InputLinkProps = {
  hideActionButtons?: boolean;
} & Omit<InputProps, "prefix" | "suffix">;

const CustomInput: FC<InputLinkProps> = ({
  hideActionButtons = false,
  disabled,
  error,
  className,
  ...restProps
}) => {
  const inputRef = useRef<InputLinkProps & AntInput>(null);

  const handleCopy = async () => {
    const valueToCopy = inputRef.current?.state?.value;
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(valueToCopy ? `${valueToCopy}` : "");
      notification.success({
        message: "Copied to Clipboard",
        duration: 2,
        isCERTA2Enabled: true
      });
    } else {
      notification.error({
        message: "Could not copy to clipboard. Unsupported browser.",
        isCERTA2Enabled: true
      });
    }
  };

  const handleVisit = () => {
    const link = inputRef.current?.state?.value;
    if (link) {
      window.open(`${link}`, "_blank");
    }
  };

  return (
    <Input
      ref={inputRef}
      className={cx(
        css`
          &&.ant-input-affix-wrapper:not(.ant-input-group-wrapper) {
            padding: 0 var(--s2) 0 var(--s4);
          }
        `,
        className
      )}
      disabled={disabled}
      error={error}
      {...restProps}
      prefix={<Link />}
      suffix={
        !hideActionButtons && (
          <Stack gap="s1">
            <Button
              size="small"
              type="ghost"
              onClick={handleCopy}
              disabled={error}
            >
              Copy
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={handleVisit}
              disabled={error}
            >
              Visit
            </Button>
          </Stack>
        )
      }
    />
  );
};

export const InputLink = styled(CustomInput)<InputLinkProps>``;
