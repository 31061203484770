import { APIFetchV2, APIFetch, RequestHeaders } from "@certa/network";
import type {
  CreatePackageVersionParams,
  DeployPackageVersionParams,
  SaveAppVersionConfigParams,
  PublishAppVersionConfigParams,
  WorkflowAsDropdownParams,
  CreateFromReusableComponentParams
} from "../types/appBuilder.types";
import {
  relatedProcessesModelCreator,
  editableTableColumnAsDropdownModelCreator,
  fieldSelectModelCreator,
  fieldSelectModelCreatorWithType,
  designerKindsDropdownModelCreator,
  appVersionsModelCreator,
  fieldLiteSelectModelCreator,
  deployedAppsModelCreator,
  workflowAsDropdownModelCreator
} from "../models/appBuilder.model";
import type { FieldResponse, ResultsResponse } from "../types/generic.types";
import type { WorkflowList } from "../types/workflow.types";
import qs from "querystring";

/**
 * TODO: To be extracted into @certa/queries as a generic
 * Workflow-as-dropdown query, with a custom hook here within
 * constraint UI component that specifically fetches the data
 * required to display it.
 * @param rootId
 */
export const getRelatedProcesses = (rootId: number | null) => {
  if (rootId === null) {
    return Promise.reject("Invalid root ID");
  }
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<ResultsResponse>(
    `relatedtype-workflowkinds/?workflow_id=${rootId}`,
    requestOptions
  ).then(relatedProcessesModelCreator);
};

/**
 * Generic service for retrieving response stored for any given field tag.
 * Returns 404, when the response is null
 * @param workflowId
 * @param fieldTag
 */
export const getFieldResponse = <T = FieldResponse>(
  workflowId: number,
  fieldTag: string
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  return APIFetchV2<T>(
    `responses/get-answer/?workflow_id=${workflowId}&field_tag=${fieldTag}`,
    requestOptions
  );
};

/**
 * Returns the array of all the responses within any editable table's answer,
 * based of the provided columnDataIndex.
 * @param workflowId
 * @param fieldTag
 * @param columnDataIndex
 */
export const getEditableTableColumn = (
  workflowId: number,
  fieldTag: string,
  params: { label: string; value: string }
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const queryParams = {
    ...params
  };

  const queryParamsString = qs.stringify(queryParams);

  return APIFetchV2<ResultsResponse>(
    `table-column-dropdown/${workflowId}/${fieldTag}/?${queryParamsString}`,
    requestOptions
  ).then(editableTableColumnAsDropdownModelCreator);
};
/**
 * TODO: To be extracted into @certa/queries as a generic
 * Workflow-as-dropdown query, with a custom hook here within
 * constraint UI component that specifically fetches the data
 * required to display it.
 * @param rootId
 */
export const getFieldWorkflows = ({
  rootId,
  ...extra
}: { rootId: number | null } & Record<string, unknown>) => {
  if (rootId === null) {
    return Promise.reject("Invalid root ID");
  }

  const queryParams = {
    kind_tag: "fields",
    field_tags: ["field_name", "field_tag", "step_name", "stepgroup_name"],
    label_tags: ["label", "value", "step", "step_group"],
    label: "field_name",
    value: "field_tag",
    step: "step_name",
    step_group: "stepgroup_name",
    root: rootId,
    ...(extra || {})
  };

  const queryParamsString = qs.stringify(queryParams);
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<ResultsResponse>(
    `workflow-as-dropdown/?${queryParamsString}`,
    requestOptions
  ).then(fieldSelectModelCreator);
};

export const getABProcessKinds = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<ResultsResponse>(
    `designer-kind-as-dropdown/`,
    requestOptions
  ).then(designerKindsDropdownModelCreator);
};

/**
 * Preview-workflowdefjson minimal query would return stepgroups,
 * steps and fields data.
 * @param param0
 * @returns
 */
export const getLiteFieldWorkflows = ({
  rootId
}: {
  rootId: number | null;
}) => {
  if (rootId === null) {
    return Promise.reject("Invalid root ID");
  }

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const queryParams = {
    minimal: true
  };

  const queryParamsString = qs.stringify(queryParams);

  return APIFetch(
    `workflows/${rootId}/preview-workflowdefjson/?${queryParamsString}`,
    requestOptions
  ).then(fieldLiteSelectModelCreator);
};

/**
 * TODO: To be extracted into @certa/queries as a generic
 * Workflow-as-dropdown query, with a custom hook here within
 * constraint UI component that specifically fetches the data
 * required to display it.
 * @param rootId
 */
export const getFieldWorkflowWithType = (rootId: number | null) => {
  if (rootId === null) {
    return Promise.reject("Invalid root ID");
  }
  const queryParams = {
    kind_tag: "fields",
    field_tags: [
      "field_name",
      "field_tag",
      "step_name",
      "stepgroup_name",
      "field_type_normal"
    ],
    label_tags: ["label", "value", "step", "step_group", "field_type"],
    label: "field_name",
    value: "field_tag",
    step: "step_name",
    step_group: "stepgroup_name",
    field_type: "field_type_normal",
    root: rootId
  };
  const queryParamsString = qs.stringify(queryParams);
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<ResultsResponse>(
    `workflow-as-dropdown/?${queryParamsString}`,
    requestOptions
  ).then(fieldSelectModelCreatorWithType);
};

/**
 * TODO: To be extracted into @certa/queries as a generic
 * Workflow-as-dropdown query, with a custom hook here within
 * constraint UI component that specifically fetches the data
 * required to display it.
 */
export const getWorkflowsAsDropdown = <T>(
  queryParams: WorkflowAsDropdownParams
) => {
  if (queryParams.root === null) {
    return Promise.reject("Invalid root ID");
  }

  const queryParamsString = qs.stringify(queryParams);
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<ResultsResponse<T>>(
    `workflow-as-dropdown/?${queryParamsString}`,
    requestOptions
  ).then(results => workflowAsDropdownModelCreator(results));
};

/**
 * Duplicate the workflow when the workflowId is passed
 * @param {number} workflowId
 */
export const duplicateWorkflow = (workflowId: number | undefined) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      workflow_id: workflowId
    })
  };

  return APIFetchV2("copy-component/", requestOptions);
};

/**
 * Returns the versions created of a particular app/
 * @param {number} packageWorkflowId
 */
export const getAppVersions = (packageWorkflowId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<ResultsResponse>(
    `package/${packageWorkflowId}/package-versions-list/`,
    requestOptions
  ).then(appVersionsModelCreator);
};

/**
 * Duplicate the workflow when the workflowId is passed
 * @param workflowId
 * @returns
 */
export const createPackageVersion = (params: CreatePackageVersionParams) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };

  return APIFetchV2("package/version-create/", requestOptions);
};

/**
 * Duplicate the workflow when the workflowId is passed
 * @param workflowId
 * @returns
 */
export const deployPackageVersion = (params: DeployPackageVersionParams) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };

  return APIFetchV2("package-deploy/initiate-deploy/", requestOptions);
};

/**
 * Duplicate the workflow when the workflowId is passed
 * @param workflowId
 * @deprecated
 */
export const createHotpatchPackageVersion = (
  params: CreatePackageVersionParams // This uses same params as SaveRelease
) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };

  return APIFetchV2("package/hotpatch-create/", requestOptions);
};

/**
 * Gets the list of all the package versions that are deployed
 * across all environments.
 * @param rootId
 */
export const getDeployedApps = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<ResultsResponse>(
    `package/deployed-package-list/`,
    requestOptions
  ).then(deployedAppsModelCreator);
};

/**
 * Gets the process id of Platform Settings workflow
 * @returns
 */
export const getPlatformSettingsProcessID = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<WorkflowList>(
    "workflows/?kind_tag=platform-settings&limit=1",
    requestOptions
  ).then(response => response?.results[0]?.id || null);
};

/**
 * Save configuration for a particular version
 * @param version_id
 * @returns
 */
export const saveAppVersionConfig = (params: SaveAppVersionConfigParams) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };

  return APIFetchV2("settings-deploy/save_admin_config/", requestOptions);
};

/**
 * Publishes the configuration of a given version to the provided environment.
 */
export const publishAppVersionConfig = (
  params: PublishAppVersionConfigParams
) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };

  return APIFetchV2("settings-deploy/initiate/", requestOptions);
};

/**
 * Gets Integration Configs available on the platform on
 * the current tenant.
 * @returns
 */
export const getPlatformIntegrationConfigs = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2<WorkflowList>(
    "integration-configs-dropdown/",
    requestOptions
  ).then(workflowAsDropdownModelCreator);
};

/**
 * Instantiates a template of a given tag within the provider designer
 */
export const createFromReusableComponent = (
  params: CreateFromReusableComponentParams
) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };

  return APIFetchV2("create-reusable-component/", requestOptions);
};

/**
 * Gets Categories of Templates
 * @returns
 */
export const getStudioTemplateCategories = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch<WorkflowList>("extra-json/rc_category/", requestOptions).then(
    props => workflowAsDropdownModelCreator(props)
  );
};

/**
 * Gets Sources of Templates
 * @returns
 */
export const getStudioTemplateSources = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch<WorkflowList>("extra-json/rc_source/", requestOptions).then(
    workflowAsDropdownModelCreator
  );
};
