import { useEffect, useCallback, useState, useMemo } from "react";
import type {
  SectionChangeArgs,
  TaskChangeArgs,
  TaskCompletionStates,
  TaskLaneTaskType
} from "../../types";
import { ActiveTaskPanel, submittableSteps } from "../../types";
import { useParams, useHistory } from "react-router-dom";
import type { Step, StepGroup } from "@certa/types";
import { useSelector } from "react-redux";
import type { StepGroupsData, TaskDetails } from "@certa/queries";
import { getTaskCompletion } from "../utils";
import queryString from "query-string";
import { CompletedTaskStatus } from "@certa/tasks/src/types";
import { useProcessDetailsConfig } from "./useProcessDetailsConfig";
import {
  useIsNewNavigationEnabled,
  useIsProcessOverviewEnabled,
  getProcessDetailRoute
} from "@certa/common";
import { startsWith } from "lodash-es";
export const useTaskUpdate = () => {
  const params = useParams<{
    id: string;
  }>();
  const history = useHistory();

  const searchParams = queryString.parse(history.location.search);

  // TODO: Use querystring here for getting the params.

  const selectedTask = searchParams?.step ? Number(searchParams?.step) : null;
  const selectedField = searchParams?.field
    ? Number(searchParams?.field)
    : null;

  const completed: TaskCompletionStates | null = searchParams?.completed
    ? (searchParams?.completed as TaskCompletionStates)
    : null;

  const handleTaskChange = useCallback(
    ({ taskLaneId, taskId, activeTab }: TaskChangeArgs) => {
      const searchParams = queryString.parse(history.location.search);

      // Get the current step and group in url params
      const currentTask = searchParams?.step;
      const currentTaskLane = searchParams?.group;

      Object.assign(searchParams, { group: taskLaneId, step: taskId });
      // Delete completed param to view the step fields
      delete searchParams.completed;

      if (activeTab) {
        searchParams.activeTab = activeTab;
      }

      if (currentTask && currentTaskLane) {
        /**
         * If the current step & group is set in the URL params, then
         * we just push the updated step id which browser treats as a new redirect
         */
        history.push({
          search: `?${queryString.stringify(searchParams)}`
        });
      } else {
        /**
         * If the URL does not contains step and group id then in that case we simply
         * replace the search params of URL which contains group and step Id
         * NOTE: A url without group and step id can be used for displaying other worfklow details
         * such as lc data
         */
        history.replace({
          search: `?${queryString.stringify(searchParams)}`
        });
      }
    },
    [history]
  );

  const handleSectionChange = ({ fieldId }: SectionChangeArgs) => {
    const searchParams = queryString.parse(history.location.search);

    // Get the current step and group in url params
    const currentTask = searchParams?.step;
    const currentTaskLane = searchParams?.group;
    const currentField = searchParams?.field;

    if (currentTaskLane && currentTask) {
      Object.assign(searchParams, {
        group: currentTaskLane,
        step: currentTask,
        field: fieldId
      });
      if (currentField) {
        history.push({
          search: `?${queryString.stringify(searchParams)}`
        });
      } else {
        history.replace({
          search: `?${queryString.stringify(searchParams)}`
        });
      }
    }
  };

  return {
    processId: Number(params.id),
    handleTaskChange,
    selectedTask,
    selectedField,
    handleSectionChange,
    completed
  };
};
/**
 * Returns the current step group and step to be
 * displayed when a process or workflow is opened
 *
 * @param taskLanesData Step groups data
 * @param isNewWorkflow When new workflow is created
 * @param hasProcessOverview Processoverview page is enabled
 * @returns stepGroup, step, activeTaskTab
 */
const getCurrentTask = (
  taskLanesData: StepGroupsData,
  isNewWFNavigation: boolean,
  hasProcessOverview: boolean
) => {
  if (isNewWFNavigation) {
    return {
      stepGroup: taskLanesData.results?.[0],
      step: taskLanesData.results?.[0]?.steps?.[0],
      activeTaskTab: ActiveTaskPanel.ALL_TASKS
    };
  }

  const defaultStepTags = taskLanesData.defaultStepTags;

  const myTasksStepGroups = taskLanesData.myStepGroups;
  const myGroupTasksStepGroups = taskLanesData.userStepGroups;
  const allTasksStepGroups = taskLanesData.results;

  if (myTasksStepGroups.length > 0) {
    let myTasksIncompleteStep: Step | undefined;

    const myTasksIncompleteStepGroup = myTasksStepGroups.find(
      myTasksStepGroup => {
        myTasksIncompleteStep = myTasksStepGroup.steps.find(
          step =>
            !step.isCompleted &&
            submittableSteps.includes(step.stepType as TaskLaneTaskType)
        );
        return myTasksIncompleteStep;
      }
    );

    const hasIncompleteStepsInMyTasks =
      !!myTasksIncompleteStepGroup && !!myTasksIncompleteStep;

    if (hasIncompleteStepsInMyTasks) {
      let myTasksDefaultStep: Step | undefined;

      const myTasksDefaultStepGroup = myTasksStepGroups.find(
        myTasksStepGroup => {
          myTasksDefaultStep = myTasksStepGroup.steps.find(step => {
            if (
              defaultStepTags.length &&
              defaultStepTags.includes(step.definitionTag)
            ) {
              myTasksDefaultStep = step;
              return step;
            } else {
              return undefined;
            }
          });
          return myTasksDefaultStep;
        }
      );

      if (!!myTasksDefaultStepGroup && !!myTasksDefaultStep) {
        return {
          stepGroup: myTasksDefaultStepGroup,
          step: myTasksDefaultStep,
          activeTaskTab: ActiveTaskPanel.MY_TASKS
        };
      } else if (!!myTasksIncompleteStepGroup && !!myTasksIncompleteStep) {
        return {
          stepGroup: myTasksIncompleteStepGroup,
          step: myTasksIncompleteStep,
          activeTaskTab: ActiveTaskPanel.MY_TASKS
        };
      }
    }
  }

  if (myGroupTasksStepGroups.length > 0) {
    let myGroupTasksIncompleteStep: Step | undefined;

    const myGroupTasksIncompleteStepGroup = myGroupTasksStepGroups.find(
      myGroupTasksStepGroup => {
        myGroupTasksIncompleteStep = myGroupTasksStepGroup.steps.find(step => {
          if (
            !step.isCompleted &&
            submittableSteps.includes(step.stepType as TaskLaneTaskType)
          ) {
            myGroupTasksIncompleteStep = step;
            return step;
          } else {
            return undefined;
          }
        });
        return myGroupTasksIncompleteStep;
      }
    );

    const hasIncompleteStepsInMyGroupTasks =
      !!myGroupTasksIncompleteStepGroup && !!myGroupTasksIncompleteStep;

    if (hasIncompleteStepsInMyGroupTasks) {
      let myGroupTasksDefaultStep: Step | undefined;

      const myGroupTasksDefaultStepGroup = myGroupTasksStepGroups.find(
        myGroupTasksStepGroup => {
          myGroupTasksDefaultStep = myGroupTasksStepGroup.steps.find(step => {
            if (
              defaultStepTags.length &&
              defaultStepTags.includes(step.definitionTag)
            ) {
              return step;
            } else {
              return undefined;
            }
          });
          return myGroupTasksDefaultStep;
        }
      );

      const hasDefaultStepInMyGroupTasks =
        !!myGroupTasksDefaultStepGroup && !!myGroupTasksDefaultStep;

      const hasIncompleteStepInMyGroupTasks =
        !!myGroupTasksIncompleteStepGroup && !!myGroupTasksIncompleteStep;

      if (hasDefaultStepInMyGroupTasks) {
        return {
          stepGroup: myGroupTasksDefaultStepGroup,
          step: myGroupTasksDefaultStep as Step,
          activeTaskTab: hasIncompleteStepInMyGroupTasks
            ? ActiveTaskPanel.MY_GROUPS
            : ActiveTaskPanel.ALL_TASKS
        };
      } else if (hasIncompleteStepInMyGroupTasks) {
        return {
          stepGroup: myGroupTasksIncompleteStepGroup,
          step: myGroupTasksIncompleteStep as Step,
          activeTaskTab: ActiveTaskPanel.MY_GROUPS
        };
      }
    }
  }

  let allTasksIncompleteStep: Step | undefined;
  let allTasksIncompleteStepGroup: StepGroup | undefined;
  let allTasksDefaultStep: Step | undefined;

  const allTasksCurrentStepGroup =
    allTasksStepGroups.find(allTasksStepGroup => {
      const selectedDefaultStep = allTasksStepGroup.steps.find(step =>
        defaultStepTags.includes(step.definitionTag)
      );

      if (selectedDefaultStep) {
        allTasksDefaultStep = selectedDefaultStep;
        return true;
      }

      // only take first incomplete step, and step group
      if (!allTasksIncompleteStep) {
        const selectedIncompleteStep = allTasksStepGroup.steps.find(
          step => !step.isCompleted
        );
        if (selectedIncompleteStep) {
          allTasksIncompleteStep = selectedIncompleteStep;
          allTasksIncompleteStepGroup = allTasksStepGroup;
        }
      }

      return false;
      // if there is no defualt step group, then take the first incomplete step group
    }) || allTasksIncompleteStepGroup;

  const hasDefaultStepInAllTasks =
    !!allTasksCurrentStepGroup && !!allTasksDefaultStep;

  const hasIncompleteStepInAllTasks =
    !!allTasksCurrentStepGroup && !!allTasksIncompleteStep;

  if (hasDefaultStepInAllTasks) {
    return {
      stepGroup: allTasksCurrentStepGroup,
      step: allTasksDefaultStep as Step,
      activeTaskTab: ActiveTaskPanel.ALL_TASKS
    };
  } else if (!hasProcessOverview && hasIncompleteStepInAllTasks) {
    return {
      stepGroup: allTasksCurrentStepGroup,
      step: allTasksIncompleteStep as Step,
      activeTaskTab: ActiveTaskPanel.ALL_TASKS
    };
  }
};

/**
 * @param stepGroups
 * @param defaultStepTags
 * @param lcData
 * @param handleStepChange
 *
 * This hook is responsible for deciding the step on which user will be redirected
 * when the task page will be opened for the first time. It decides by evaluating the following cases
 * Case: 1
 *  - When there is already a step id present in url then there is no need od decision. So we avoid any step evaluation
 *    in that case. The step is calculated only if the step id is not present in url
 * Case: 2
 *  - When the task is a newly created task then in that case the hook redirects the user to first step
 *    of the api response which is rendered on the UI.
 * Case: 3
 *  - When there is My Tasks, then there are two condition of redirect.
 *  1) If all the task inside My Tasks are completed then redirect to first one.
 *  2) The incomplete task from My Tasks would be selected.
 * Case: 4
 *  - Match the step tags of all the steps in the stepgroups response with the default step tags and make a step list
 *  - Now check the first incomplete step from this step list
 *  - If there is an incomplete step then redirect to it else redirect to the last step
 */
export const useDecideInitialTask = (payload: {
  taskLanesData: StepGroupsData | undefined;
  processDetails: TaskDetails | undefined;
  handleTaskChange: (taskArgs: TaskChangeArgs) => void;
  selectedTask: number | null;
  taskLanesStatus: string;
}) => {
  const {
    taskLanesData,
    processDetails,
    handleTaskChange,
    selectedTask,
    taskLanesStatus
  } = payload;

  const {
    showMyTaskCompletedMessage,
    showMyGroupsCompletedMessage,
    showAllTaskCompletedMessage
  } = useProcessDetailsConfig();

  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  // queryString.parse returns new object on every render so it's
  // dependency in handleCompletionState causes infinite rendering loop
  const searchParams = useMemo(
    () => queryString.parse(history.location.search),
    [history.location.search]
  );
  const currentPathname = history.location.pathname;
  const permissions = useSelector(
    (state: Record<string, any>) => state.permissions
  );
  const isReviewState = searchParams?.review === "true";
  /**
   * NOTE: Similar logic has been kept in ProcessDetails => handleTaskUpdateSideEffects
   * to handle the recalculation of completion state on step submit
   */
  const handleCompletionState = useCallback(
    (data: StepGroupsData) => {
      /**
       * If the page is already in completed state or came in to review the form then we don't need to do route redirection as
       * if all Assigned tasks is completed while all Group tasks were completed, it should remain at My Task Completed State
       */
      if (searchParams?.completed || isReviewState) return;
      const hasCompletedAllTasks = getTaskCompletion(data.results);

      /**
       * Below if else condition is to handle the case when the user complete step with the priority of My Tasks < My Group Tasks < All Tasks
       * This is for the reason if the user finished All Tasks and get back to the process, it should show All Tasks completed not just My Tasks completed or My Group Tasks completed
       */

      if (hasCompletedAllTasks && showAllTaskCompletedMessage) {
        searchParams["completed"] = CompletedTaskStatus.ALL;
        history.replace({ search: `?${queryString.stringify(searchParams)}` });
      }

      const hasCompletedMyGroups = getTaskCompletion(data.userStepGroups ?? []);

      if (
        hasCompletedMyGroups &&
        (!searchParams?.activeTab ||
          searchParams?.activeTab === ActiveTaskPanel.MY_GROUPS) &&
        showMyGroupsCompletedMessage
      ) {
        searchParams["completed"] = CompletedTaskStatus.MY_GROUP;
        history.replace({ search: `?${queryString.stringify(searchParams)}` });
      }

      const hasCompletedMyTasks = getTaskCompletion(data.myStepGroups);

      if (
        hasCompletedMyTasks &&
        (!searchParams?.activeTab ||
          searchParams?.activeTab === ActiveTaskPanel.MY_TASKS) &&
        showMyTaskCompletedMessage
      ) {
        searchParams["completed"] = CompletedTaskStatus.MINE;
        history.replace({ search: `?${queryString.stringify(searchParams)}` });
      }
    },
    [
      history,
      isReviewState,
      searchParams,
      showAllTaskCompletedMessage,
      showMyGroupsCompletedMessage,
      showMyTaskCompletedMessage
    ]
  );

  const hasProcessOverview = useIsProcessOverviewEnabled();
  const isNewWFNavigationEnabled = useIsNewNavigationEnabled();

  // This will run on initial mount only once when no stepgroup and no step selected
  useEffect(() => {
    /*
      Whenever I was changing the route from process. detail page to home 
      or dashbaord this function was getting called and add unwanted search 
      param to the url to avoid this issue. I have added check so if current
      page is not process detail page then this function won't work
    */
    if (!startsWith(currentPathname, getProcessDetailRoute(""), 0)) return;
    if (taskLanesData && processDetails && !selectedTask) {
      const isNewWorkflow = searchParams?.new === "true";
      const hasNewWFNavigation = isNewWFNavigationEnabled && isNewWorkflow;
      const currentTask = getCurrentTask(
        taskLanesData,
        hasNewWFNavigation,
        hasProcessOverview
      );
      if (currentTask) {
        handleTaskChange({
          taskId: currentTask.step.id,
          taskLaneId: currentTask.stepGroup.id,
          activeTab: currentTask.activeTaskTab
        });
      } else if (!hasProcessOverview) {
        handleCompletionState(taskLanesData);
      }
      setIsLoading(false);
    }
  }, [
    permissions.permissions,
    taskLanesData,
    processDetails,
    selectedTask,
    searchParams?.new,
    currentPathname,
    handleTaskChange,
    hasProcessOverview,
    handleCompletionState,
    isNewWFNavigationEnabled
  ]);

  // This will run on initial mount only once when we have a stepgroup and step selected
  useEffect(() => {
    if (taskLanesStatus === "success" && taskLanesData && selectedTask) {
      setIsLoading(false);
      handleCompletionState(taskLanesData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskLanesStatus]);

  return { isLoading };
};
