import type { ReportWorkflowExtraData } from "../types/reportChartData.types";

// For now we are fetching family tree data upto 10 levels deep
// in the future if we need to fetch more levels we can increase the depth
export const reportWorkflowExtraData: ReportWorkflowExtraData = {
  parents: {
    id: null,
    name: null,
    parents: {
      id: null,
      name: null,
      parents: {
        id: null,
        name: null,
        parents: {
          id: null,
          name: null,
          parents: {
            id: null,
            name: null,
            parents: {
              id: null,
              name: null,
              parents: {
                id: null,
                name: null,
                parents: {
                  id: null,
                  name: null,
                  parents: {
                    id: null,
                    name: null,
                    parents: { id: null, name: null }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export const INVALID_VALUE_IN_DATABASE_ERROR = "Invalid value in database";
export const SOMETHING_WENT_WRONG_ERROR = "Something went wrong";
