/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import React from "react";
import { getCSSToken } from "../utils";

type SidebarProps = {
  sideWidth?: string | null;
  contentMin?: string;
  space?: string;
  noStretch?: boolean;
  side?: "right" | "left";
} & { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>;

const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
  const {
    sideWidth,
    contentMin,
    space,
    noStretch,
    side,
    children,
    ...htmlProps
  } = props;
  if (React.Children.count(children) !== 2) {
    throw new Error(
      "Sidebar: Should have exactly two children " +
        React.Children.count(children)
    );
  }
  const adjustedSpace =
    props.space === "0" ? "0px" : getCSSToken(props.space || "0px");
  return (
    <div
      {...htmlProps}
      ref={ref}
      css={[
        css`
          overflow: hidden;

          & > * {
            display: flex;
            flex-wrap: wrap;
            margin: calc(${adjustedSpace} / 2 * -1);
            height: 100%;
            ${props.noStretch
              ? "align-items: flex-start;"
              : "align-items: stretch;"}
          }

          & > * > * {
            flex-grow: 1;
            margin: calc(${adjustedSpace} / 2);
            max-height: 100%;
            scrollbar-gutter: stable;
            ${props.sideWidth
              ? `flex-basis: ${getCSSToken(props.sideWidth)};`
              : ""}
          }

          & > * > ${props.side === "left" ? ":last-child" : ":first-child"} {
            flex-basis: 0;
            flex-grow: 999;
            min-width: calc(
              ${getCSSToken(props.contentMin || "50%")} - ${adjustedSpace}
            );
          }
        `
      ]}
    >
      <div>{children}</div>
    </div>
  );
});

Sidebar.defaultProps = {
  sideWidth: null,
  contentMin: "50%",
  space: "0",
  noStretch: false,
  side: "left"
};

export { Sidebar };
