import type { FC } from "react";
import { Fragment, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  useAppDispatch as useDispatch,
  CreateNewProcess,
  useCheckPermission,
  useCheckPermissionFn,
  useHeaderBanner,
  useHomePage,
  useHeaderReportLinkText,
  SelectLanguage,
  useShowHeaderDropdownOptions,
  useCanSeeCreateSupportButton,
  useScreenResolution,
  useDashboardNewUX,
  useNewVerticalSideNav
} from "@certa/common";
import { useRouteMatch, Link } from "react-router-dom";
import { css } from "emotion";
import { isEmpty } from "lodash-es";

import { Mentions } from "@certa/icons";
import { normalizeCSSClass, DesignTheme, Image, Z_INDEX } from "@certa/blocks";
import type { ButtonProps } from "@certa/blocks/thanos";
import { Stack, Text, StudioBetaLogo } from "@certa/blocks/thanos";
import {
  ButtonVariants,
  ButtonSizes,
  Button,
  Badge,
  BadgeColors,
  BadgeSizes,
  ButtonTypes,
  Alert
} from "@certa/catalyst";
import type { ReduxState } from "main/src/modules/common/interfaces";

import {
  getDashboardsGenericRoute,
  getTasksRoute,
  getAppProcessRoute,
  getHomeRoute,
  getProcessDetailRoute,
  getStudioGenericRoute,
  getAppListGenericRoute,
  getDashboardIdFromPathname,
  getDashboardsRoute,
  TABLEAU_PATH
} from "../../utils/routes";
import { NavLink } from "./NavLink";
import {
  appBuilderNavLinks,
  defaultNavLinks,
  studioNavLinks
} from "./header.constants";
import { HeaderMode } from "../../constants";
import { AppBuilderBanner } from "./AppBuilderBanner";
import {
  usePlatformSettingsProcessID,
  useGetUnReadMentionCount
} from "@certa/queries";
import { PermissionTypes } from "@certa/types";
import { ProcessEditorSearchInput } from "./ProcessEditorSearchInput";
import { useCanViewTasks } from "../../hooks/useCanViewTasks";
import ImpersonationBanner from "./ImpersonationBanner";
import { CreateCertaSupportDropdown } from "./HeaderHelpDropdown";
import { UserProfile } from "./components";

import { languageActions } from "main/src/js/actions";
import { useMentionsContext } from "@certa/mentions";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

// TODO: [VET-23313] Add header banner
type HeaderProps = {
  mode: HeaderMode;
};
const HEADERMODES_WITH_DISABLED_LANGUAGE_SELECTOR = [HeaderMode.STUDIO];

const Header: FC<HeaderProps> = ({ mode = HeaderMode.DEFAULT }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isNewVerticalSidenavEnabled = useNewVerticalSideNav();
  const impersonateeId = sessionStorage.getItem("impersonateeId");

  // TODO: Remove userMentionsCount once the migration to new Comms microservice is completed
  const { data: userMentionsCount } = useGetUnReadMentionCount();

  const { toggleShow } = useMentionsContext();

  const config = useSelector((state: ReduxState) => state.config);
  const isProcessDetailPage = useRouteMatch(getProcessDetailRoute());
  const shouldShowLanguage: boolean =
    !isEmpty(config.supported_languages) &&
    !HEADERMODES_WITH_DISABLED_LANGUAGE_SELECTOR.includes(mode);

  const authentication = useSelector(
    (state: ReduxState) => state?.authentication
  );

  // This is to prevent banner flicker
  // when logged in user becomes the impersonatee for a split second
  // while the impersonatee details are being fetched from the server
  const isSameUserImpersonation =
    (impersonateeId && parseInt(impersonateeId)) === authentication?.user?.id;

  const isSupplierUser = authentication?.user?.kind === 2;

  // Adding `!!` so that TS understands it as boolean automatically without typecasting since the authentication and user in the reducer are of type `any`
  const shouldShowTableauReports =
    !!authentication?.user?.features?.includes("view_reports") &&
    !!config?.report_embed_url;

  const userName =
    authentication?.user?.first_name && authentication?.user?.last_name
      ? `${authentication.user.first_name} ${authentication.user.last_name}`
      : authentication.user.email;

  const isAppBuilder =
    mode === HeaderMode.APP_BUILDER || mode === HeaderMode.STUDIO;

  const canViewDashboard = useCheckPermission(
    PermissionTypes.CAN_VIEW_DASHBOARD
  );
  const canViewTasks = useCanViewTasks();
  const checkPermissions = useCheckPermissionFn();
  const isHomePageEnabled = useHomePage();
  const reportText = useHeaderReportLinkText();

  const canViewStudio2 = useCheckPermission(PermissionTypes.CAN_ACCESS_STUDIO);

  const isProcessEditorSearch =
    useRouteMatch(getAppProcessRoute()) && isAppBuilder;

  /**
   * navLinks hold the final links that needs to be displayed on the header
   * which is based upon which mode the header is presented in
   */
  const navLinks =
    mode === HeaderMode.APP_BUILDER
      ? appBuilderNavLinks
      : mode === HeaderMode.STUDIO
        ? studioNavLinks
        : defaultNavLinks;

  const isDashboardNewUXEnabled = useDashboardNewUX();
  const dashboardId = getDashboardIdFromPathname();

  useEffect(() => {
    // TODO: remove isDashboardNewUXEnabled condition when new Dashboard UX is enabled on Prod
    if (isDashboardNewUXEnabled) {
      navLinks.map(navLink => {
        if (navLink.label === "Dashboards") {
          if (dashboardId) {
            navLink.route = getDashboardsRoute(dashboardId);
          } else {
            navLink.route = getDashboardsGenericRoute();
          }
        }
        return navLink;
      });
    }
  }, [isDashboardNewUXEnabled, dashboardId, mode, navLinks]);

  // TODO: Remove once Platform Settings UI (VET-34577) is done
  // Prefetches platform settings process ID
  usePlatformSettingsProcessID();

  const bannerConfig = useHeaderBanner();
  const showNavLink = (route: string) => {
    if (route === getHomeRoute()) return isHomePageEnabled;
    else if (route === getStudioGenericRoute()) return canViewStudio2;
    else if (route === getAppListGenericRoute()) return !canViewStudio2;
    else return true;
  };

  const showHeaderDropdownOptions = useShowHeaderDropdownOptions();
  const { supportTicketData } = useCanSeeCreateSupportButton();

  const { isMobileResolution } = useScreenResolution();

  const createNewProcessLabel = intl.formatMessage({
    id: "homepage.common.createProcessButton",
    defaultMessage: "Start new"
  });

  const handleLanguageChange = useCallback(
    (value: string) => {
      dispatch(languageActions.updateUserLanguage(value));
    },
    [dispatch]
  );
  return (
    <DesignTheme
      className={normalizeCSSClass}
      style={{
        width: "100%",
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        zIndex: Z_INDEX["zindex-fixed"]
      }}
    >
      {impersonateeId && !isSameUserImpersonation && <ImpersonationBanner />}

      {mode === HeaderMode.APP_BUILDER && <AppBuilderBanner />}

      {bannerConfig && !isMobileResolution && (
        <Alert
          type={bannerConfig.type}
          message={bannerConfig.message}
          hasIcon={bannerConfig.hasIcon}
          width="100%"
        />
      )}
      {(!isNewVerticalSidenavEnabled || mode === HeaderMode.STUDIO) && (
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: var(--s7);
            padding: 0 var(--s6);
            background-color: var(--neutral-0);
            width: 100%;
            height: 56px;
            max-height: 56px;
          `}
        >
          <Stack
            gap="s6"
            align="center"
            className={css`
              height: 100%;
            `}
          >
            {!isAppBuilder && (
              <Link
                to={
                  isHomePageEnabled
                    ? getHomeRoute()
                    : canViewDashboard
                      ? getDashboardsGenericRoute()
                      : getTasksRoute()
                }
                tabIndex={0}
                className={css`
                  border-radius: 2px;
                  &:focus {
                    outline: 2px solid var(--colors-brand-400);
                    outline-offset: 2px;
                  }
                `}
              >
                {config.logo ? (
                  <Image alt="Certa logo" src={config.logo} height="32px" />
                ) : (
                  <Text variant="h1-bold">{config.name}</Text>
                )}
              </Link>
            )}
            {mode === HeaderMode.STUDIO ? (
              <Stack gap="s4">
                <StudioBetaLogo />
              </Stack>
            ) : null}
            {!isMobileResolution &&
              navLinks
                .filter(link =>
                  isSupplierUser ? !link.hideForSuppliers : true
                )
                .filter(link => showNavLink(link.route))
                .filter(link =>
                  link.checkPermissions &&
                  !(link.label === "Tasks" && !canViewTasks)
                    ? checkPermissions(link.checkPermissions)
                    : true
                )
                .map((link, index) => <NavLink link={link} key={`${index}`} />)}
          </Stack>
          <Stack
            gap="s7"
            align="center"
            className={css`
              justify-content: flex-end;
              width: 100%;
            `}
          >
            {mode === HeaderMode.DEFAULT && (
              <Fragment>
                {shouldShowTableauReports && (
                  <HeaderLabelLink
                    target="_blank"
                    href={TABLEAU_PATH}
                    title={
                      reportText ||
                      intl.formatMessage({
                        id: "header.tableau",
                        defaultMessage: "Tableau"
                      })
                    }
                  />
                )}
                {!isProcessDetailPage && (
                  <CreateNewProcess
                    buttonProps={{
                      size: ButtonSizes.SMALL,
                      label: createNewProcessLabel,
                      isDropdownButton: true
                    }}
                  />
                )}
                {!isMobileResolution && userMentionsCount !== undefined && (
                  <Badge
                    role="status"
                    aria-label={userMentionsCount + " mentions"}
                    label={userMentionsCount}
                    color={BadgeColors.RED}
                    size={BadgeSizes.SMALL}
                    overflowCount={9}
                    showZero={false}
                  >
                    <Button
                      size={ButtonSizes.SMALL}
                      type={ButtonTypes.ICON}
                      leftIcon={<Mentions />}
                      aria-label={userMentionsCount + " mentions"}
                      onClick={() => {
                        toggleShow(true);
                        MixPanelActions.track(
                          MixPanelEvents.headerEvents.HEADER_CLICK_MENTIONS
                        );
                      }}
                    />
                  </Badge>
                )}
              </Fragment>
            )}
            {/* Process editor search input */}
            {isProcessEditorSearch && <ProcessEditorSearchInput />}

            {!isMobileResolution &&
            (showHeaderDropdownOptions.length || supportTicketData) ? (
              <CreateCertaSupportDropdown
                supportTicketData={supportTicketData}
                dropdownOptions={showHeaderDropdownOptions}
              />
            ) : null}

            {shouldShowLanguage && (
              <Stack
                style={{
                  maxWidth: 150
                }}
              >
                <SelectLanguage
                  onLanguageClick={(open: boolean) =>
                    open &&
                    MixPanelActions.track(
                      MixPanelEvents.headerEvents.HEADER_CLICK_LANGUAGE_DROPDOWN
                    )
                  }
                  onLanguageChange={handleLanguageChange}
                />
              </Stack>
            )}

            <UserProfile userName={userName} />
          </Stack>
        </div>
      )}
    </DesignTheme>
  );
};

export { Header };

const HeaderLabelLink: FC<
  { title: string } & (Pick<ButtonProps, "href" | "target"> | { to: string })
> = props => {
  const { title, ...buttonProps } = props;
  const diplayCompoent = (
    <Button
      variant={ButtonVariants.LINK}
      size={ButtonSizes.SMALL}
      {...buttonProps}
    >
      {title}
    </Button>
  );
  return "to" in props ? (
    <Link to={props.to}>{diplayCompoent}</Link>
  ) : (
    diplayCompoent
  );
};
