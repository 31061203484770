import type { FC, ReactNode, ReactElement } from "react";
import React from "react";
import { css, cx } from "emotion";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import type { TooltipPlacement } from "antd/lib/tooltip";
import { Text } from "../Typography/Text";
import { Stack } from "../Stack";
import { Check, Exclamation, Info, Messages, Spinner2 } from "@certa/icons";
import { Button } from "../Button/Button";
import { Tooltip } from "../Tooltip";
import type { StatusIndicator } from "@certa/types";
import { getRAGIndicator } from "../../utils";
import type { RAGIndicator } from "../../types";
import { sanitizeHTML } from "@certa/common";

export type FieldProps<T> = {
  /** Label text */
  label?: string;
  /** Description text */
  description?: string;
  /** Status of field */
  status?: "success" | "error" | "loading";
  /** Tooltip text on status icon hover */
  statusTooltip?: string | React.ReactNode;
  /** Tooltip text on info icon hover */
  infoTooltipText?: string;
  /** Info tooltip placement */
  infoTooltipPlacement?: TooltipPlacement;
  /** Whether to show required asterisk */
  required?: boolean;
  /** Error message */
  error?: string | boolean;
  /** Whether to hide Field (still collect and validate value) */
  hidden?: boolean;
  /** */
  disabled?: boolean;
  /** Comments count */
  commentsCount?: number;
  /** Function called on clicking comment button */
  onCommentClick?: () => void;
  /** Whether to hide comment button */
  hideCommentButton?: boolean;
  /** Alert button component */
  alertComponent?: ReactNode;
  className?: string;
  component: FC<T>;
  componentProps?: T;
  // children: ({ error }: FieldChildrenProps) => ReactNode | ReactNode;
  ragStatusIndicator?: StatusIndicator | null;
};

const StatusIcon: FC<{ status: FieldProps<any>["status"] }> = ({ status }) => {
  switch (status) {
    case "success":
      return <Check color="green" />;
    case "error":
      return <Exclamation color="red" />;
    case "loading":
    default:
      return <Spinner2 />;
  }
};

export function Field<T>(props: FieldProps<T>): ReactElement | null {
  const {
    label,
    description,
    status,
    statusTooltip,
    infoTooltipText,
    infoTooltipPlacement,
    required = false,
    error = false,
    hidden = false,
    disabled = false,
    commentsCount,
    onCommentClick,
    hideCommentButton = false,
    alertComponent = null,
    className,
    component: InputComponent,
    componentProps,
    ragStatusIndicator
  } = props;

  const intl = useIntl();

  if (hidden) {
    return null;
  }

  return (
    <Stack
      direction="vertical"
      className={cx(
        className,
        css`
          &:hover .field-comments-icon {
            visibility: visible;
          }
        `
      )}
    >
      <Stack
        direction="horizontal"
        justify="space-between"
        align="flex-end"
        gutter="s00 s00 s1 s00"
        gap="s2"
      >
        <Stack direction="vertical">
          <Stack
            direction="horizontal"
            align="center"
            gap="s1"
            gutter="s00 s00 s1 s00"
          >
            {status && (
              <Tooltip title={statusTooltip} trigger="hover">
                <Stack gutter="s00 s1 s00 s00">
                  <StatusIcon status={status} />
                </Stack>
              </Tooltip>
            )}
            {label && (
              <Text
                variant="p1-bold"
                color={disabled ? "neutral-70" : "neutral-100"}
                className={css`
                  cursor: ${disabled ? "not-allowed" : "default"};
                `}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(label)
                  }}
                />
                {required && <Text color="red">*</Text>}
              </Text>
            )}
            {infoTooltipText && (
              <Tooltip
                title={infoTooltipText}
                trigger="hover"
                placement={infoTooltipPlacement}
              >
                <Info color="teal" />
              </Tooltip>
            )}
            {!hideCommentButton && (
              <span
                className={cx(
                  css`
                    visibility: ${commentsCount ? "visible" : "hidden"};
                  `,
                  "field-comments-icon"
                )}
              >
                <Tooltip
                  trigger="hover"
                  placement="right"
                  overlayClassName={css`
                    &.ant-tooltip {
                      padding-left: var(--s00) !important;
                    }
                    .ant-tooltip-content .ant-tooltip-inner {
                      box-shadow: none !important;
                      background: var(--brand-35) !important;
                      color: var(--brand) !important;
                    }
                    .ant-tooltip-arrow {
                      display: none;
                    }
                    .ant-tooltip-inner {
                      padding: 0 !important;
                      min-height: unset !important;
                      height: var(--s5) !important;
                    }
                  `}
                  overlay={
                    <Button
                      onClick={onCommentClick}
                      isActive
                      size="small"
                      className={css`
                        &&& {
                          height: var(--s5);
                        }
                      `}
                    >
                      {intl.formatMessage({
                        id: "stepBodyFormInstances.commentsTooltip",
                        defaultMessage: "Add comment"
                      })}
                    </Button>
                  }
                >
                  {commentsCount ? (
                    <Button
                      icon={<Messages />}
                      size="small"
                      onClick={onCommentClick}
                      className={css`
                        &&& {
                          height: var(--s5);
                          .button-icon {
                            margin-right: var(--s1);
                          }
                        }
                      `}
                    >
                      <Text variant="p1-bold">{commentsCount}</Text>
                    </Button>
                  ) : (
                    <Button
                      icon={<Messages />}
                      size="small"
                      iconOnly
                      onClick={onCommentClick}
                    >
                      <Text variant="p1-bold">{commentsCount}</Text>
                    </Button>
                  )}
                </Tooltip>
              </span>
            )}
          </Stack>
          {description && (
            <Stack gutter="s00 s00 s1 s00">
              <Text variant="p1-regular" color="neutral-70">
                {description}
              </Text>
            </Stack>
          )}
        </Stack>
        <Stack gutter="s00 s00 s1 s00">{alertComponent}</Stack>
      </Stack>
      {/* this can be any component that needs to be wrapped with field */}

      {InputComponent ? (
        <Stack
          className={css`
            position: relative;
          `}
        >
          <div
            className={css`
              width: 100%;
            `}
          >
            <InputComponent
              error={status === "error" || !!error}
              required={required}
              {...componentProps!}
            />
          </div>
          {ragStatusIndicator && (
            <Stack
              className={css`
                width: 10px !important;
              `}
            >
              <StyledSuffix
                color={
                  getRAGIndicator(ragStatusIndicator.indicator as RAGIndicator)
                    .color
                }
              >
                {
                  getRAGIndicator(ragStatusIndicator.indicator as RAGIndicator)
                    ?.icon
                }
              </StyledSuffix>
            </Stack>
          )}
        </Stack>
      ) : null}
      {error && (
        <Stack gutter="s2 s00 s00 s00">
          <Text variant="p1-regular" color="red">
            {error}
          </Text>
        </Stack>
      )}
    </Stack>
  );
}

const StyledSuffix = styled.span<any>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 18px !important;
  line-height: 12.1px;
  border-radius: 8px 0 0 8px !important;
  color: #fff !important;
  -webkit-text-fill-color: #fff;
  text-align: center;
  vertical-align: middle;
  writing-mode: vertical-rl;
  font-size: 10px;
  font-weight: 700;
  padding-right: 2.5px;
  transform: rotate(180deg);
  background: ${props => (props.color ? props.color : "")};
`;
