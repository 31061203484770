import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";
import { useFilterHeaderOptionBasedOnUserGroup } from "./useFilterHeaderOptionBasedOnUserGroup";

export const useShowHeaderDropdownOptions = () => {
  const dropdownOptions = useSelector((state: ReduxState) =>
    lodashGet(state, "config.custom_ui_labels.header_dropdown_options", [])
  );

  const headerDropdownOptions =
    useFilterHeaderOptionBasedOnUserGroup(dropdownOptions);

  return headerDropdownOptions;
};
