import React from "react";
import styled from "@emotion/styled";
import { Switch as AntSwitch } from "antd";
import type { SwitchProps as AntSwitchProps } from "antd/lib/switch";
import { Stack } from "../Stack";
import type { TextProps } from "../Typography";
import { Text } from "../Typography";

export type SwitchProps = AntSwitchProps & {
  label?: string;
  labelTextProps?: TextProps;
};

const CustomSwitch = (props: SwitchProps) => {
  const { label, labelTextProps, ...switchProps } = props;
  return (
    <Stack gap="s3">
      <AntSwitch
        {...switchProps}
        // @ts-expect-error TS5 upgrade
        id="brandSwitch"
      />
      {/* id showing ts error */}
      {label && (
        <Text
          variant="p1-medium"
          color="neutral-70"
          as="label"
          htmlFor="brandSwitch"
          className="switch-label"
          style={!switchProps.disabled ? { cursor: "pointer" } : {}}
          {...labelTextProps}
        >
          {label}
        </Text>
      )}
    </Stack>
  );
};

export const Switch = styled(CustomSwitch)`
  box-shadow: none;
  background: var(--neutral-20);
  &:focus {
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow:
      0 0 0 1px #fff,
      0 0 0 3px var(--brand-15);
  }
  &.ant-switch-checked {
    background: var(--brand);
    & + .switch-label {
      color: var(--neutral-100);
    }
  }
  --label: Switch;
`;
