import { APIFetchV2, RequestHeaders } from "@certa/network";
import type { ProcessFilterSet } from "@certa/types";
import {
  taskLaneHeadersModeCreator,
  headerProcessListModelCreator,
  nonABProcessTypeModelCreator,
  taskDependenciesModel,
  taskDistinctStatuesModel,
  taskDetailsModelCreator
} from "../models/tasks.models";
import type {
  MyGroupTaskProcessListParams,
  TaskHeaderListParams,
  MyTaskProcessListParams,
  AllTaskProcessListParams
} from "../types/tasks.types";
import queryString from "query-string";

export const getDelayedTaskLaneCount = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `task-page/count/delayed`;
  return APIFetchV2(url, requestOptions).then(
    (response: any): number => response?.delayed
  );
};

export const getMyTaskTaskLaneCount = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `my-tagged-incomplete-steps-stepgroup-count`;
  return APIFetchV2(url, requestOptions).then(
    (response: any): number => response
  );
};

export const getMyTaskProcessCount = (processTypeId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `task-page/assigned-workflows-count/${processTypeId}/`;
  return APIFetchV2(url, requestOptions).then(
    (response: any): number => response
  );
};

export const getMyGroupTaskProcessCount = (processTypeId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `task-page/todo-workflows-count/${processTypeId}/`;
  return APIFetchV2(url, requestOptions).then(
    (response: any): number => response
  );
};

export const getInProgressTaskLaneCount = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `task-page/count/inprogress`;
  return APIFetchV2(url, requestOptions).then(
    (response: any): number => response?.inprogress
  );
};

export const getCompletedProcessCount = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `task-page/count/completed`;
  return APIFetchV2(url, requestOptions).then(
    (response: any): number => response?.completed
  );
};

export const getMyGroupsByTaskLane = (params: TaskHeaderListParams) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const { kind_id: kindId, ...otherParams } = params;

  const urlParams = queryString.stringify(otherParams);

  const url = `task-page/todo-workflows-count-by-step-group/${kindId}/?${urlParams}`;
  return APIFetchV2(url, requestOptions).then(taskLaneHeadersModeCreator);
};

// TODO: Remove this in future
export const getMyGroupsByStatus = (params: TaskHeaderListParams) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const { kind_id: kindId, ...otherParams } = params;

  const urlParams = queryString.stringify(otherParams);

  const url = `task-page/todo-workflows-count-by-status/${kindId}/?${urlParams}`;
  return APIFetchV2(url, requestOptions).then(taskLaneHeadersModeCreator);
};

export const getMyUserGroupProcessList = (
  params: MyGroupTaskProcessListParams,
  offset: number
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const { kind_id: kindIdParam, ...otherParams } = params;

  const urlParams = queryString.stringify(otherParams);

  const kindId = kindIdParam ? `${kindIdParam}/` : "";

  const filtersData = urlParams ? `${urlParams}&` : "";

  const offsetData = offset ? `&offset=${offset}` : "";

  const url = `task-page/todo-workflows/${kindId}?${filtersData}limit=20${offsetData}`;
  return APIFetchV2(url, requestOptions).then(headerProcessListModelCreator);
};

export const getAllTasksByTaskLane = (params: TaskHeaderListParams) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const { kind_id: kindId, ...otherParams } = params;

  const urlParams = queryString.stringify(otherParams);

  const url = `task-page/all-workflows-count-by-step-group/${kindId}/?${urlParams}`;
  return APIFetchV2(url, requestOptions).then(taskLaneHeadersModeCreator);
};

export const getAllTasksByStatus = (params: TaskHeaderListParams) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const { kind_id: kindId, ...otherParams } = params;

  const urlParams = queryString.stringify(otherParams);

  const url = `task-page/all-workflows-count-by-status/${kindId}/?${urlParams}`;
  return APIFetchV2(url, requestOptions).then(taskLaneHeadersModeCreator);
};

export const getAllTaskProcessList = (
  params: AllTaskProcessListParams,
  offset: number
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const { kind_id: kindId, ...otherParams } = params;

  const urlParams = queryString.stringify(otherParams);

  const url = `task-page/all-workflows/${kindId}/?${urlParams}&limit=20${
    offset ? `&offset=${offset}` : ""
  }`;

  return APIFetchV2(url, requestOptions).then(headerProcessListModelCreator);
};

type NonABKindsParams = {
  my_groups?: boolean;
  status_in?: number;
  region_in?: number;
  business_unit_in?: number;
  created_range_after?: string;
  created_range_before?: string;
  my_tasks?: boolean;
};

export const getNonABProcessType = (params: NonABKindsParams) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const urlParams = queryString.stringify(params);

  const url = `task-page/wf-kinds-with-count/${
    urlParams ? `?${urlParams}` : ""
  }`;

  return APIFetchV2(url, requestOptions).then(nonABProcessTypeModelCreator);
};
// TODO: Remove this in future
export const getMyTaskByTaskLane = (params: TaskHeaderListParams) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const { kind_id: kindId, ...otherParams } = params;

  const urlParams = queryString.stringify(otherParams);

  const url = `task-page/assigned-workflows-count-by-step-group/${kindId}/?${urlParams}`;
  return APIFetchV2(url, requestOptions).then(taskLaneHeadersModeCreator);
};
// TODO: Remove this in future
export const getMyTaskByStatus = (params: TaskHeaderListParams) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const { kind_id: kindId, ...otherParams } = params;

  const urlParams = queryString.stringify(otherParams);

  const url = `task-page/assigned-workflows-count-by-status/${kindId}/?${urlParams}`;
  return APIFetchV2(url, requestOptions).then(taskLaneHeadersModeCreator);
};

//TODO: Add Support for process filter set
// TODO: Remove this in future
export const getMyTaskProcessList = (
  params: MyTaskProcessListParams,
  offset: number
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const { kind_id: kindId, ...otherParams } = params;

  const urlParams = queryString.stringify(otherParams);

  const url = `task-page/assigned-workflows/${kindId}/?${urlParams}&limit=20${
    offset ? `&offset=${offset}` : ""
  }`;

  return APIFetchV2(url, requestOptions).then(headerProcessListModelCreator);
};

export const getMyTasksProcesses = (
  params: ProcessFilterSet,
  offset: number,
  ordering?: string
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const urlParams = queryString.stringify(params);

  const url = `task-page/assigned-workflows/?${urlParams}&limit=20&ordering=${ordering}${
    offset ? `&offset=${offset}` : ""
  }`;

  return APIFetchV2(url, requestOptions).then(headerProcessListModelCreator);
};

export const getDistinctAttributes = (
  params: ProcessFilterSet,
  offset: number,
  attribute: "kind_id" | "status_id"
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  const urlParams = queryString.stringify(params);
  const url = `task-page/assigned-workflows/distinct/${attribute}?${
    offset ? `offset=${offset}&` : ""
  }${urlParams}`;
  return APIFetchV2(url, requestOptions).then(
    // @ts-expect-error TS5 upgrade
    taskDistinctStatuesModel
  );
};

export const getTaskDetails = (taskId: number, mergeHiddenFields = false) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(`steps/${taskId}/`, requestOptions).then(
    (jsonFromAPI: any) =>
      taskDetailsModelCreator(jsonFromAPI, mergeHiddenFields)
  );
};

export const getTaskCount = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(`task-page/assigned-steps-count/`, requestOptions).then(
    (response: any): number => response
  );
};

export const getTaskDependencyData = (processId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  return APIFetchV2(`inter-step-dependency/${processId}/`, requestOptions).then(
    taskDependenciesModel
  );
};
