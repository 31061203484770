function requiredParam(param: string): any {
  throw new Error(`Required param ${param} is missing`);
}

/**
 * Returns a proxy for any storage backend for easy get/set
 * todo: move to an independent package in future so it can be used across projects
 * @param backend The storage back-end, e.g. localStorage, sessionStorage
 * @param prefix Prefix for your storage values
 * @returns {{Proxy}}
 */
function getStorage({
  backend = requiredParam("backend"),
  prefix = requiredParam("prefix")
}) {
  const getPropKey = (prop: string | number | symbol): string =>
    `${prefix}.${String(prop)}`;

  return new Proxy(
    {},
    {
      set: (obj: any, prop, value) => {
        const key = getPropKey(prop);
        if (value === null) {
          backend.removeItem(key);
        } else {
          obj[prop] = value;
          backend.setItem(key, value);
        }
        return true;
      },
      get: (obj, prop) => {
        if (prop === "clear") {
          return function (keysToKeepWithoutPrefix?: string[]) {
            if (keysToKeepWithoutPrefix) {
              const keysToKeepWithPrefix =
                keysToKeepWithoutPrefix.map(getPropKey);
              const valuesToKeep = keysToKeepWithPrefix.map(key =>
                backend.getItem(key)
              );
              backend.clear();
              valuesToKeep.forEach((value, index) => {
                backend.setItem(keysToKeepWithPrefix[index], value);
              });
              return undefined;
            }
            return backend.clear();
          };
        }

        return backend.getItem(getPropKey(prop));
      }
    }
  );
}

/**
 * Function to migrate usual storages to the new format
 * P.S. just adds prefixes to your current localStorage vars
 */
function migrateStorage({ storageBackend }: any) {
  const keysToMigrate = ["magicLogin", "customHistory", "user", "historyClone"];

  for (const currentKey of keysToMigrate) {
    const storageValue = storageBackend.getItem(currentKey);
    if (storageValue) {
      Godaam[currentKey] = storageValue;
      localStorage.removeItem(currentKey);
    }
  }
}

/**
 * @deprecated
 */
const Godaam = getStorage({
  backend: localStorage,
  prefix: "certa"
});

if (!Godaam.storageMigrated) {
  migrateStorage({ storageBackend: localStorage });
  Godaam.storageMigrated = true;
}

const getParsedUserFromGodaam = () => {
  try {
    return JSON.parse(Godaam.user);
  } catch (e) {
    return null;
  }
};
export const getUserDetailsFromGodaam = (): {
  email: string;
  id: string;
  username: string;
} | null => {
  const user = getParsedUserFromGodaam();
  if (user) {
    return {
      email: user.email,
      id: user.id,
      username: user.username
    };
  }
  return null;
};
// eslint-disable-next-line import/no-default-export
export default Godaam;
