import { get as lodashGet, groupBy, uniq } from "lodash-es";
import moment from "moment";
import type {
  CommentChannel,
  CommentThreadMeta,
  CommentThreads,
  CommentMessage,
  AdjudicationFlag,
  ThreadRaw,
  IntegrationStatus
} from "@certa/types";
import { CommentObjectTypes } from "@certa/types";
import {
  COMMENT_MESSAGE_GROUP_FORMAT,
  removeDuplicateThreads
} from "../utils/comments.utils";

/**
 * Provides most applicable display name by checking available name
 * data available within the object. Priority is given to first name +
 * last name, when both are available,
 * but otherwise, first name -> last name > email and if none of those
 * are available then "unnamed" is put instead.
 * @param param0
 */
const getDisplayName = ({
  first_name: firstName,
  last_name: lastName,
  email
}: {
  first_name: string;
  last_name: string;
  email: string;
}) => {
  if (firstName && lastName) {
    return firstName + " " + lastName;
  } else {
    return firstName || lastName || email || "unnamed";
  }
};

const getMessageList = (messages: any[]): CommentMessage[] => {
  return messages.map((messageContext: any, index: number) => ({
    message: messageContext.message,
    attachment: lodashGet(messageContext, "attachment", null),
    mentions: lodashGet(messageContext, "mentions", null),
    createdAt: lodashGet(messageContext, "created_at", null),
    postedBy: getDisplayName(lodashGet(messageContext, "posted_by", {})),
    postedById: lodashGet(messageContext, "posted_by.id"),
    messageId: lodashGet(messageContext, "id"),
    threadId: lodashGet(messageContext, "thread")
  }));
};
/**
 * This method will transform threads array into a dictionary/JSON object
 * where the keys are `thread_{threadID}` and the value is the respective
 * threads' data.
 * This is being done so that when only a single thread is received in
 * response, it gets merged without
 * @param {Array} threads
 */
const transformThreadsArray = (threadsArray: ThreadRaw[]) => {
  const threads: CommentThreads = {};
  threadsArray.forEach(threadContext => {
    const id = Number(threadContext.id);
    const allMessages = getMessageList(threadContext?.messages || []);
    const groupedMessages = groupBy(allMessages, message => {
      const messageCreationDate = moment
        .utc(message.createdAt)
        .local()
        .format(COMMENT_MESSAGE_GROUP_FORMAT);
      const currentDate = moment
        .utc()
        .local()
        .format(COMMENT_MESSAGE_GROUP_FORMAT);
      const previousDate = moment
        .utc()
        .local()
        .subtract(1, "days")
        .format(COMMENT_MESSAGE_GROUP_FORMAT);
      if (messageCreationDate === currentDate) return "Today";
      if (messageCreationDate === previousDate) return "Yesterday";
      return messageCreationDate;
    });
    threads[id] = {
      threadId: id,
      // @ts-expect-error - value possibly null
      threadName: lodashGet(threadContext, "name", null),
      // @ts-expect-error - value possibly null
      messageCount: lodashGet(threadContext, "message_count", null),
      threadUserGroups: lodashGet(threadContext, "user_groups", []),
      messages: allMessages,
      groupedMessages,
      subscribedBy: lodashGet(threadContext, "subscribed_by", [])
    };
  });

  return threads;
};

/**
 * Extracts `threads` from the a given state of the channel results.
 * @param param0
 */
const extractOldThreads = ({
  source,
  objectId
}: {
  source: CommentChannel[];
  objectId: number;
}) => {
  const channels = source || [];
  let oldThreads = {};
  channels.forEach(channelContext => {
    if (channelContext.objectId === objectId && channelContext.threads)
      oldThreads = channelContext.threads;
  });

  return oldThreads;
};

/**
 * Assigns the most applicable object type based on certain rules. These can
 * be one of -
 * - Integration Result
 * - Workflow
 * - Field
 * - Step
 * @param channelContext
 */
const getObjectType = (channelContext: any) => {
  let type = channelContext.content_type;

  if (!type) {
    const isIntegrationType = lodashGet(
      channelContext,
      "target.field_details.is_integration_type",
      null
    );
    const integrationUID = lodashGet(channelContext, "target.uid", null);

    if (isIntegrationType && integrationUID) {
      type = CommentObjectTypes.INTEGRATION_RESULT;
    } else if (channelContext.target.field_details) {
      type = CommentObjectTypes.FIELD;
    } else if (channelContext.target.workflow_details) {
      type = CommentObjectTypes.WORKFLOW;
    } else {
      type = CommentObjectTypes.STEP;
    }
  }

  return type;
};

export const commentsModelCreator = (
  response: any,
  previousResults: any[] = []
) => {
  return {
    ...response,
    results: response.results.map((channelContext: any): CommentChannel => {
      const excludedGroupFromTaggingNames = lodashGet(
        channelContext,
        "groups_excluded_from_tagging",
        []
      );
      return {
        objectId: channelContext.object_id,
        objectType: getObjectType(channelContext),
        target: channelContext.target, // ugly structure inside
        isIntegrationType: lodashGet(
          channelContext,
          "target.field_details.is_integration_type",
          null
        ),
        integrationUID: lodashGet(channelContext, "target.uid", null),
        fieldId: lodashGet(channelContext, "target.field_details.id", null),
        fieldName: lodashGet(channelContext, "target.field_details.name", null),
        fieldType: lodashGet(channelContext, "target.field_details.type", null),
        parentFieldId: lodashGet(
          channelContext,
          "target.row_json.parent_field_id",
          null
        ), // applicable for integration table's row
        parentRowUID: lodashGet(
          channelContext,
          "target.row_json.parent_row_uid",
          null
        ), // applicable for integrations table's row
        stepGroupId: lodashGet(
          channelContext,
          "target.step_group_details.id",
          null
        ),
        stepGroupName: lodashGet(
          channelContext,
          "target.step_group_details.name",
          null
        ),
        stepId: lodashGet(channelContext, "target.step_details.id", null),
        stepName: lodashGet(channelContext, "target.step_details.name", null),
        workflowId: lodashGet(channelContext, "target.workflow", null), // workflow to which the field belongs
        childWorkflowId: lodashGet(
          channelContext,
          "target.workflow_details.id",
          null
        ), // when the object is a child workflow
        childWorkflowName:
          getObjectType(channelContext) === CommentObjectTypes.WORKFLOW // only if its a workflow type ...
            ? lodashGet(channelContext, "target.name", null) // get the name from here
            : null,
        childWorkflowKind: lodashGet(
          channelContext,
          "target.definition.kind",
          null
        ),
        // flagOptions: sortCommentFlags(
        //   lodashGet(channelContext, "target.comment_flag_options", [])
        // ),
        channelUserGroups: lodashGet(
          channelContext,
          "channel_group_mentions",
          null
        ),
        channelUsers: lodashGet(channelContext, "user_mentions", null),
        allThreads: removeDuplicateThreads(
          lodashGet(channelContext, "all_threads", []).map(
            (thread: any) =>
              ({
                threadId: thread.id,
                threadName: thread.name,
                messageCount: thread.message_count,
                isPublic: thread.is_public,

                threadUserGroups: thread.user_groups.filter(
                  (groupDetails: { name: string; id: number }) => {
                    return !excludedGroupFromTaggingNames.find(
                      (groupName: string) => groupName === groupDetails.name
                    );
                  }
                ),
                threadUsers: thread.user_mentions
              }) as CommentThreadMeta
          )
        ) as CommentThreadMeta[],
        threads: {
          ...extractOldThreads({
            source: previousResults,
            objectId: channelContext.object_id
          }),
          ...transformThreadsArray(channelContext.threads)
        }
      };
    })
  };
};

export const adjudicationFlagsModelCreator = (
  response: any
): AdjudicationFlag[] =>
  response.map(
    (item: any): AdjudicationFlag => ({
      label: item.label,
      value: item.value,
      children: item.children,
      extra: item.extra,
      tag: item.tag
    })
  );

/**
 * Creates a model for the integration statuses
 * @param response
 * @returns IntegrationStatus[]
 */
export const integrationStatusesModalCreator = (
  response: any
): IntegrationStatus[] =>
  response.map((item: any) => ({
    label: item.label,
    colorCode: item.color_code,
    id: item.id,
    tag: item.tag
  }));

export const changeSubscriptionFromThreadModel = (
  response: {
    results: {
      threads: {
        [key: number]: {
          subscribedBy: number[];
        };
      };
    }[];
  },
  threadId: number,
  userId: number,
  shouldAdd: boolean
) => ({
  ...response,
  results: response.results.map((channelContext: any): CommentChannel => {
    return {
      ...channelContext,
      threads: {
        ...channelContext.threads,
        [threadId]: {
          ...(channelContext.threads[threadId] || {}),
          subscribedBy: shouldAdd
            ? uniq([
                ...(channelContext.threads[threadId]?.subscribedBy || []),
                userId
              ])
            : uniq(
                (channelContext.threads[threadId]?.subscribedBy || []).filter(
                  (cUserId: number) => cUserId !== userId
                )
              )
        }
      }
    };
  })
});
