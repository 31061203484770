/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import React from "react";
import { getCSSToken } from "../utils";

type ClusterProps = {
  space?: string;
  align?: string;
  justify?: string;
  avoidWrapping?: boolean;
  overflow?: string;
} & { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>;

const Cluster = React.forwardRef<HTMLDivElement, ClusterProps>((props, ref) => {
  const {
    space = "0",
    align,
    justify,
    children,
    avoidWrapping,
    // TODO: [VET-24720] @rohit find a way to remove overlfow prop from Cluster
    overflow,
    ...htmlProps
  } = props;
  return (
    <div
      {...htmlProps}
      ref={ref}
      css={[
        css`
          overflow: ${overflow ? overflow : "hidden"};
          --label: cluster;
          & > * {
            display: flex;
            flex-wrap: ${avoidWrapping ? "nowrap" : "wrap"};
            justify-content: ${justify};
            align-items: ${align};
            margin: calc(${getCSSToken(space)} / 2 * -1);
          }

          & > * > * {
            margin: calc(${getCSSToken(space)} / 2);
          }
        `
      ]}
    >
      <div>{children}</div>
    </div>
  );
});

Cluster.defaultProps = {
  justify: "flex-start",
  align: "center"
};

export { Cluster };
