import React from "react";
import { css, cx } from "emotion";
import styled from "@emotion/styled";

export enum ELoaderSize {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
  EXTRA_SMALL = "extra-small"
}

type ILoader = {
  color?: string;
  size?: ELoaderSize;
  className?: string;
};
/**
 * @deprecated
 */
export const Loader = (props: ILoader) => {
  const {
    color = "#148CD6",
    size = ELoaderSize.MEDIUM,
    className = "",
    ...rest
  } = props;
  return (
    <div
      role="alert"
      aria-busy="true"
      className={cx(
        ldsEllipsisCSS[size],
        css`
          & div {
            background: ${color};
          }
        `,
        className
      )}
      data-testid={"loader"}
      {...rest}
    >
      <LoaderChldren />
      <LoaderChldren />
      <LoaderChldren />
      <LoaderChldren />
    </div>
  );
};

const ldsEllipsisCSS = {
  [ELoaderSize.LARGE]: css`
    display: inline-block;
    position: relative;
    width: 5em;
    height: 1em;
    font-size: 7px;
  `,
  [ELoaderSize.MEDIUM]: css`
    display: inline-block;
    position: relative;
    width: 5em;
    height: 1em;
    font-size: 6px;
  `,
  [ELoaderSize.SMALL]: css`
    display: inline-block;
    position: relative;
    width: 5em;
    height: 1em;
    font-size: 5px;
  `,
  [ELoaderSize.EXTRA_SMALL]: css`
    display: inline-block;
    position: relative;
    width: 5em;
    height: 1em;
    font-size: 3px;
  `
};

const LoaderChldren = styled.div`
  position: absolute;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #148cd6;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    animation: lds-ellipsis-scale-up-animation 0.9s infinite;
  }

  &:nth-child(2) {
    animation: lds-ellipsis-translate-animation 0.9s infinite;
  }

  &:nth-child(3) {
    left: 2em;
    animation: lds-ellipsis-translate-animation 0.9s infinite;
  }

  &:nth-child(4) {
    left: 4em;
    animation: lds-ellipsis-scale-down-animation 0.9s infinite;
  }

  @keyframes lds-ellipsis-scale-up-animation {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis-scale-down-animation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis-translate-animation {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(2em, 0);
    }
  }
`;
