import { useMemo, useEffect } from "react";
import { ChartView } from "../chartView/ChartView";
import { useHistory, useLocation } from "react-router-dom";
import type {
  AggregateResponse,
  ChartIdentifiers,
  ChartConfig,
  ProcessCycleChartAPIResponseType,
  ReportTableType
} from "@certa/common";
import { ChartViewORM } from "../chartViewORM/components/ChartViewORM";
import type {
  ChartConfigPayloadED,
  ChartConfigPayloadIC,
  ChartConfigPayloadORM
} from "@certa/types";
import { chartConfigModelCreator } from "@certa/common";
import { ChartViewED } from "../chartViewED/components/ChartViewED";
import { ChartViewIC } from "../chartViewIC/components/ChartViewIC";

type Query = {
  data: AggregateResponse | ProcessCycleChartAPIResponseType;
  error?: string;
  reportType?: ReportTableType;
  graphConfig: {
    chartType: ChartIdentifiers;
    chartConfig:
      | ChartConfig
      | ChartConfigPayloadORM
      | ChartConfigPayloadED
      | ChartConfigPayloadIC;
  };
};

export const decodeUrlSafeB64 = (str: string) => {
  // replace '.' with '+' and '_' with '/' and '-' with '='
  const b64 = str.replace(/\./g, "+").replace(/_/g, "/").replace(/-/g, "=");
  return atob(b64);
};

const useQuery = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = useMemo(() => {
    const query = new URLSearchParams(search);
    const encodedString = query.get("data");
    try {
      const decodedString = decodeUrlSafeB64(encodedString || "");
      if (decodedString) {
        return JSON.parse(decodedString) as Query;
      }
    } catch (error) {
      console.error(error);
    }
  }, [search]);

  useEffect(() => {
    if (!query) {
      // redirect to home page if query is not present
      // and if user is not authenticated then it will redirect to login page
      history.replace("/");
    }
  }, [history, query]);

  return query;
};

const ChartExport = () => {
  const query = useQuery();
  const reportType = query?.reportType || "Workflow Report";
  const chartType = query?.graphConfig.chartType ?? "LINE_CHART";
  const { chartConfig, chartConfigED, chartConfigIC, chartConfigORM } =
    chartConfigModelCreator(reportType, query?.graphConfig.chartConfig);

  if (!query) {
    return null;
  } else if (reportType === "ORM Workflow Report") {
    return (
      <ChartViewORM
        apiData={query.data as AggregateResponse}
        chartType={chartType}
        chartConfigORM={chartConfigORM}
        isLoading={false}
        error={query.error}
        isAnimationActive={false}
      />
    );
  } else if (reportType === "Integration Call Report") {
    if (!chartConfigIC) {
      return null;
    }
    return (
      <ChartViewIC
        apiData={query.data as AggregateResponse}
        chartType={chartType}
        chartConfigIC={chartConfigIC}
        isLoading={false}
        error={query.error}
        isAnimationActive={false}
      />
    );
  } else if (reportType === "Email Delivery Report") {
    if (!chartConfigED) {
      return null;
    }
    return (
      <ChartViewED
        apiData={query.data as AggregateResponse}
        chartType={chartType}
        chartConfigED={chartConfigED}
        isLoading={false}
        error={query.error}
        isAnimationActive={false}
      />
    );
  }

  return (
    <ChartView
      apiData={query.data}
      chartType={chartType}
      chartConfig={chartConfig}
      isLoading={false}
      error={query.error}
      isAnimationActive={false}
    />
  );
};
/**
 * NOTE: This is a lazy component and requires default export
 */
export default ChartExport;
