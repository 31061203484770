import React from "react";
import Godaam from "../../js/utils/storage";
import { Redirect } from "react-router-dom";

/**
 * Simple React Router Redirect component wrapper that redirects to the private route
 * when the user is logged in otherwise falls back to other route.
 * @param {object} props
 * @param {string} props.from From route that you want to redirect
 * @param {string} props.to To route, that should be only accessible if user is logged in
 * @param {string} props.fallback Fallback to route, if user is not logged in
 * @param {boolean} props.exact to exactly match to URL when true.
 */
export function PrivateRedirect(props) {
  if (Godaam.user) {
    return <Redirect from={props.from} exact={props.exact} to={props.to} />;
  } else {
    return (
      <Redirect from={props.from} exact={props.exact} to={props.fallback} />
    );
  }
}
