import { DropdownMenuItem } from "@certa/catalyst";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch as useDispatch } from "@certa/common";
import { userLogout } from "main/src/modules/LoginPage/loginSlice";
import { Logout as LogoutIcon } from "@certa/icons";

import { WrapperWithIcon } from "../common";

export const Logout = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => dispatch(userLogout()), [dispatch]);

  return (
    <DropdownMenuItem
      key="logout"
      value={intl.formatMessage({
        id: "loginPageInstances.logoutText"
      })}
      onSelect={handleLogout}
      preventDropdownClose // on logout action hit, if it closes then it cause double redirection
    >
      <WrapperWithIcon icon={<LogoutIcon />}>
        {intl.formatMessage({
          id: "loginPageInstances.logoutText"
        })}
      </WrapperWithIcon>
    </DropdownMenuItem>
  );
};
