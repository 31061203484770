import { authHeader } from "../_helpers";
import { apiBaseURL } from "../../config";

export const configService = {
  getConfig
};

function handleConfigResponse(response, from) {
  if (!response.ok && response.status === 503) {
    return Promise.reject({ res: response, status: response.status });
  }
  if (!response.ok) {
    return Promise.reject(response.status);
  }
  return response.json();
}

function getConfig() {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  return fetch(apiBaseURL + "customers/configuration/", requestOptions).then(
    handleConfigResponse
  );
}
