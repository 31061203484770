export const workflowKindConstants = {
  GETALL_REQUEST: "WORKFLOWKIND_GETALL_REQUEST",
  GETALL_SUCCESS: "WORKFLOWKIND_GETALL_SUCCESS",
  GETALL_FAILURE: "WORKFLOWKIND_GETALL_FAILURE",

  //CONST FOR WORKFLOW GROUP COUNT FOR AGGERATED FILTERS
  GET_COUNT_REQUEST: "WORKFLOW_GROUP_COUNT_GET_REQUEST",
  GET_COUNT_SUCCESS: "WORKFLOW_GROUP_COUNT_GET_SUCCESS",
  GET_COUNT_FAILURE: "WORKFLOW_GROUP_COUNT_GET_FAILURE",

  GET_ALERT_COUNT_REQUEST: "WORKFLOW_ALERT_GROUP_COUNT_GET_REQUEST",
  GET_ALERT_COUNT_SUCCESS: "WORKFLOW_ALERT_GROUP_COUNT_GET_SUCCESS",
  GET_ALERT_COUNT_FAILURE: "WORKFLOW_ALERT_GROUP_COUNT_GET_FAILURE",

  GET_STATUS_REQUEST: "WORKFLOW_KIND_STATUS_GET_REQUEST",
  GET_STATUS_SUCCESS: "WORKFLOW_KIND_STATUS_GET_SUCCESS",
  GET_STATUS_FAILURE: "WORKFLOW_KIND_STATUS_GET_FAILURE",
  SET_VALUE: "WORKFLOW_KIND_SET_VALUE"
};
