import type { ChartViewICProps } from "../types";
import type { OtherChartConfig } from "@certa/common";
import { useConvertToChartDataIC } from "./useConvertToChartDataIC";
import { getOtherConfigXAxisIC, getOtherConfigYAxisIC } from "../utils";
import { getChartHeight } from "../../../utils";
import { useSetTotalCount } from "../../../hooks/useSetTotalCount";

export const useChartViewIC = (props: ChartViewICProps) => {
  const { chartConfigIC, apiData, viewType, setTotalCount, isLoading } = props;

  const chartData = useConvertToChartDataIC({
    chartConfigIC,
    apiData
  });

  const xAxisDataLabels = chartConfigIC.groupBy
    .filter(groupBy => !groupBy.extraJSON.isForSecondaryXAxis)
    .map(x => x.extraJSON.label);

  const chartHeight = getChartHeight(viewType);

  // create otherConfig for chart frp, chartConfigIC.otherConfigurations
  const otherConfig: OtherChartConfig = {
    ...chartConfigIC.otherConfigurations,
    xAxis: getOtherConfigXAxisIC(chartConfigIC),
    yAxis: getOtherConfigYAxisIC(chartConfigIC),
    shouldShowPercentagesInValueLabels:
      chartConfigIC.otherConfigurations.shouldShowPercentagesInValueLabels
  };

  useSetTotalCount({ chartData, isLoading, setTotalCount });

  return {
    chartData,
    hasData: !!chartData?.data?.length,
    xAxisDataLabels: xAxisDataLabels,
    chartHeight,
    otherConfig
  };
};
