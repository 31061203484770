import type {
  GroupByDateLabels,
  MetricDataTypes,
  XAxisDataTypes
} from "@certa/common";
import type { ChartConfigIC, FieldTypes } from "@certa/types";

export const getOtherConfigXAxisIC = (chartConfig: ChartConfigIC) => {
  const xAxis = {
    fieldTypes: [] as FieldTypes[],
    dataTypes: [] as XAxisDataTypes[],
    labelOutputTypes: [] as GroupByDateLabels[]
  };
  chartConfig.groupBy
    .filter(groupBy => !groupBy.extraJSON.isForSecondaryXAxis)
    .forEach(groupBy => {
      const { fieldType, dataType, labelOutputType } = groupBy.extraJSON;
      xAxis.fieldTypes.push(fieldType);
      xAxis.dataTypes.push(dataType);
      xAxis.labelOutputTypes.push(labelOutputType);
    });
  return xAxis;
};

export const getOtherConfigYAxisIC = (chartConfig: ChartConfigIC) => {
  const yAxis = {
    outputDataType: [] as MetricDataTypes[]
  };
  // when axisName = xAxis, at that time only outputDataType = "text"
  // that is why after filtering, outputDataType = MetricDataTypes only
  chartConfig.operations
    .filter(operation => operation.extraJSON.axisName === "yAxis")
    .forEach(operation => {
      const { outputDataType } = operation.extraJSON;
      yAxis.outputDataType.push(outputDataType as MetricDataTypes);
    });
  return yAxis;
};
