import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import { FormattedMessage, useIntl } from "react-intl";

import {
  ButtonSizes,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuTrigger
} from "@certa/catalyst";
import {
  Godaam,
  removeBracketsAndAppendDash,
  useGetSupportedLanguages
} from "@certa/common";
import { Language } from "@certa/icons";
import { languageActions } from "main/src/js/actions";
import { languageConstants } from "main/src/js/constants";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { renderLanguageNativeName } from "main/src/js/utils/languageHelpers";

import { NO_SUPPORTED_LANGUAGES, SELECT_LANGUAGE_HEIGHT } from "./constants";
import type { OptionItem } from "@certa/types";

type SelectLanguageProps = {
  width?: string;
  showShortLanguage?: boolean;
  onLanguageChange: (value: string) => void;
  onLanguageClick?: (open: boolean) => void;
};

export const SelectLanguage = (props: SelectLanguageProps) => {
  const {
    width,
    showShortLanguage: shouldShowShortLanguage = true,
    onLanguageChange,
    onLanguageClick
  } = props;
  const supportedLanguages = useGetSupportedLanguages();
  const _supportedLanguagesJson = useSelector((state: ReduxState) =>
    lodashGet(state, "config.supported_languages_json", {})
  );

  const intl = useIntl();

  const preferredLanguage = useSelector(
    (state: ReduxState) =>
      state.languageSelector.language || languageConstants.DEFAULT_LOCALE
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!Godaam.preferredLanguage) {
      dispatch(languageActions.setLanguage(languageConstants.DEFAULT_LOCALE));
    }
  }, [dispatch]);

  if (supportedLanguages.length === NO_SUPPORTED_LANGUAGES) return null;

  const renderLanguageMenuTitle = () => {
    return (
      <span style={{ whiteSpace: "nowrap" }}>
        <FormattedMessage id={"loginPageInstances.languageText"} />:{" "}
        <span style={{ textTransform: "uppercase" }}>{preferredLanguage}</span>
      </span>
    );
  };

  const renderLanguageMenuItem = (language: OptionItem) => {
    const translatedLanguage = intl.formatMessage({
      id: `language.${_supportedLanguagesJson[language.value]}`,
      defaultMessage: _supportedLanguagesJson[language.value] as string
    });
    const nativeLanguageWithBrackets = renderLanguageNativeName(language.value);
    const formattedTranslatedLanguage =
      removeBracketsAndAppendDash(translatedLanguage);
    if (nativeLanguageWithBrackets) {
      const nativeLanguage = removeBracketsAndAppendDash(
        nativeLanguageWithBrackets
      );
      return `${formattedTranslatedLanguage} (${nativeLanguage} - ${language.value})`;
    }
    return `${formattedTranslatedLanguage} (${language.value})`;
  };

  const translatedPreferredLanguage = intl.formatMessage({
    id: `language.${_supportedLanguagesJson[preferredLanguage]}`,
    defaultMessage: _supportedLanguagesJson[preferredLanguage] as string
  });

  return (
    <DropdownMenu
      key="language-menu"
      onOpenChange={open => onLanguageClick && onLanguageClick(open)}
    >
      <DropdownMenuTrigger>
        <Button
          size={ButtonSizes.SMALL}
          leftIcon={<Language />}
          aria-label="change language"
          showDropdownArrow
        >
          {shouldShowShortLanguage
            ? renderLanguageMenuTitle()
            : translatedPreferredLanguage}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent width={width} height={SELECT_LANGUAGE_HEIGHT}>
        {supportedLanguages.map(language => {
          return (
            <DropdownMenuCheckboxItem
              key={language.value}
              value={language.value}
              checked={preferredLanguage === language.value}
              onChange={(_, value) => onLanguageChange(value)}
            >
              {renderLanguageMenuItem(language)}
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
