import { css } from "emotion";

export const menuFooterWrapper = css({
  zIndex: 100,
  backgroundColor: "var(--colors-neutral-300)",
  height: "45px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  paddingRight: "var(--s8)",
  "&:hover": {
    cursor: "pointer"
  }
});

export const focusVisible = css({
  outline: "0.125rem solid var(--colors-brand-400)"
});
