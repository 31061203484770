import { ToastPlacements, ToastTypes, showToast } from "@certa/catalyst";
import { gmtDate } from "./time";
import COPY_TEXT from "@certa/comments/src/util/copyText";
import type { ExportToPngPdfPpt } from "@certa/types";
import { convertAndDownloadPdfFromPng, type ImageMetaData } from "./pngToPdf";
import { convertAndDownloadPptFromPng } from "./pngToPpt";
import { Zip } from "./zip";
import { downloadFile } from "./file";
import { toPng } from "html-to-image";
import { UNTITLED_FILE_NAME } from "../constants";

export enum FileDownloadTypes {
  BLOB_DOWNLOAD = "BLOB_DOWNLOAD",
  NEW_TAB = "NEW_TAB",
  FILE_DOWNLOAD = "FILE_DOWNLOAD"
}
/**
 * @downloadType String of enum FileDownloadTypes. It will select to either download via blob or open a new tab
 * @url String. This will be the url of where the file is stored at
 * @blob Blob type data of file object. Required only for single file download atm.
 * @fileName Filename string that will be the name of the file once downloaded. Required only for single file download atm.
 */
export function fileDownload({
  downloadType,
  url,
  blob,
  fileName
}: {
  downloadType: FileDownloadTypes;
  url: string;
  blob?: Blob;
  fileName?: string;
}): void {
  try {
    if (downloadType === FileDownloadTypes.FILE_DOWNLOAD && url) {
      // Download file when url is given
      const a = document.createElement("a");
      a.style.display = "none"; //optional
      a.href = url;
      a.download = fileName ? fileName : "";
      document.body.appendChild(a);
      a.click();
    } else if (
      downloadType === FileDownloadTypes.BLOB_DOWNLOAD &&
      blob &&
      url
    ) {
      const fileURL = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none"; //optional
      a.href = fileURL;
      a.download = fileName ? fileName : "";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else if (downloadType === FileDownloadTypes.NEW_TAB && url) {
      window.open(url, "_blank");
    }
  } catch (error) {
    showToast({
      type: ToastTypes.ERROR,
      placement: ToastPlacements.BOTTOM_RIGHT,
      title: COPY_TEXT.DOWNLOAD_FILE.ERROR.DEFAULT.TITLE,
      description: COPY_TEXT.DOWNLOAD_FILE.ERROR.DEFAULT.DESCRIPTION
    });
  }
}

export const fileNameWithTimeStamp = (fileName: string): string => {
  const date = new Date();
  const timeStamp = gmtDate(date);
  return fileName + " " + timeStamp;
};

export const downloadFromDataURL = async (
  dataURLs: string[],
  fileName: string,
  exportTo: ExportToPngPdfPpt = "PNG",
  metaDataArray: ImageMetaData[]
) => {
  if (exportTo === "PDF")
    await convertAndDownloadPdfFromPng(dataURLs, metaDataArray, fileName);
  else if (exportTo === "PPT") {
    await convertAndDownloadPptFromPng(dataURLs, metaDataArray, fileName);
  } else {
    if (dataURLs.length === 1) {
      dataURLs.forEach((dataURL, index) => {
        downloadFile(dataURL, metaDataArray[index]?.fileName || fileName);
      });
    } else {
      const zip = new Zip();
      const files = dataURLs.map((dataURL, index) => ({
        name: (metaDataArray[index].fileName as string) + ".png",
        content: dataURL,
        option: {
          base64: true,
          binary: true
        }
      }));
      await zip.addFiles(files);
      await zip.downloadAsync(fileName);
    }
  }
};

const HORISONTAL_PADDING = 24;
const VERTICAL_PADDING = 48;

export const getDataURL = (ref: React.RefObject<HTMLDivElement>) => {
  if (!ref?.current) {
    return undefined;
  }
  return toPng(ref?.current, {
    pixelRatio: 1,
    width: ref.current.offsetWidth + HORISONTAL_PADDING,
    height: ref.current.offsetHeight + VERTICAL_PADDING,
    backgroundColor: "#FFF"
  });
};

export const handleExportToPNGPPTPDF =
  (chartRef: React.RefObject<HTMLDivElement>, reportName?: string) =>
  async (exportTo: ExportToPngPdfPpt) => {
    const pngUrl = await getDataURL(chartRef);
    if (pngUrl) {
      const metaData = {
        width: chartRef.current?.offsetWidth,
        height: chartRef.current?.offsetHeight,
        name: reportName || UNTITLED_FILE_NAME,
        fileName: fileNameWithTimeStamp(reportName || UNTITLED_FILE_NAME)
      };
      downloadFromDataURL([pngUrl], metaData.fileName, exportTo, [metaData]);
    }
  };
