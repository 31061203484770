import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { DropdownMenuItem } from "@certa/catalyst";
import { Gear } from "@certa/icons";
import { intl } from "main/src/modules/common/components/IntlCapture";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { isCERTA2Enabled as isCERTA2EnabledSelector } from "main/src/modules/common/selectors";

import { WrapperWithIcon } from "../common";

export const UserProfile = () => {
  const history = useHistory();

  const authentication = useSelector(
    (state: ReduxState) => state?.authentication
  );

  /** @deprecated This flag is no longer in use. */
  const isCERTA2Enabled = useSelector(isCERTA2EnabledSelector);

  const userProfileId = authentication?.user?.user_profile_workflow || null;

  const goToUserProfile = useCallback(() => {
    if (isCERTA2Enabled) {
      history.push(`/process/${userProfileId}`);
    } else {
      history.push(`/workflows/instances/${userProfileId}`);
    }
  }, [history, isCERTA2Enabled, userProfileId]);

  return userProfileId ? (
    <DropdownMenuItem
      key="profile"
      value={intl.formatMessage({
        id: "workflowsInstances.profileText"
      })}
      onSelect={goToUserProfile}
    >
      <WrapperWithIcon icon={<Gear />}>
        {intl.formatMessage({ id: "workflowsInstances.profileText" })}
      </WrapperWithIcon>
    </DropdownMenuItem>
  ) : null;
};
