import { Avatar as AntAvatar } from "antd";
import styled from "@emotion/styled";
import type { AvatarProps as AntAvatarProps } from "antd/lib/avatar/index";

type AvatarProps = AntAvatarProps & {
  backgroundColor?: string;
  color?: string;
  isBold?: boolean;
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Avatar } from '@certa/catalyst';
 */
export const Avatar = styled(AntAvatar)`
  background-color: ${(props: AvatarProps) =>
    props.backgroundColor || "var(--brand)"};
  color: ${(props: AvatarProps) => props.color || "var(--neutral-0)"};
  font-weight: ${(props: AvatarProps) => (props.isBold ? "bold" : "normal")};
`;
