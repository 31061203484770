import type { CommentMention, CommentThreadMeta } from "@certa/types";
import { useContext, useMemo } from "react";
import { ChannelContext } from "../comments.context";
import { prepareMentionOptions } from "../comments.utils";

// TODO: Since allThreads is always generated in the model, the reference keeps changing
// hence the useMentions hook returns new reference of mentions each time, which
// in turn will execute all operation again that depend on it's reference.
// (example: useCommentMessageFormat)
// For performance improvements, certain values that don't change should not be altered.
// to prevent unnecessary re-renders.
/**
 * Provides a list of mentionable (users and groups), for a particular
 * thread.
 * @param threadId
 */
export const useMentions = (threadId: number) => {
  const { allThreads = [] } = useContext(ChannelContext);
  const threadMeta = useMemo(
    () =>
      allThreads.find(
        (thread: CommentThreadMeta) => thread.threadId === threadId
      ),
    [allThreads, threadId]
  );

  const mentions: CommentMention[] = useMemo(
    () =>
      prepareMentionOptions({
        threadUserGroups: threadMeta?.threadUserGroups || [],
        threadUsers: threadMeta?.threadUsers || []
      }),
    [threadMeta]
  );
  return mentions;
};
