import { authHeader, handleResponse } from "../../js/_helpers";
import { apiBaseURL } from "../../config";
import Godaam from "../../js/utils/storage";
import { APIFetchV2 } from "../../js/utils/request";

export function updateSelectedLanguage(payload) {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader.post(),
      credentials: "include",
      body: JSON.stringify({ to_language: payload })
    };

    const url = apiBaseURL + "users/change_prefered_language/";
    try {
      const response = await fetch(url, requestOptions).then(handleResponse);
      if (!!response.prefered_language) {
        // prefered_language was set successfully
        // so we're going to update user in our storage
        const user = JSON.parse(Godaam.user);
        user.prefered_language = response.prefered_language;
        Godaam.user = JSON.stringify(user);
      }
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
}

export const exportWorkflow = payload => {
  const requestOptions = {
    method: "POST",
    headers: authHeader.post(),
    credentials: "include",
    body: JSON.stringify(payload)
  };

  return APIFetchV2(`workflows/export/`, requestOptions);
};
