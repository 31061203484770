import { Input as AntInput } from "antd";
import styled from "@emotion/styled";

export const Input = styled(AntInput)`
  border-radius: 6px;
  border: 1px solid #c1cbd3;
  transition: border 0s !important;
  &::placeholder {
    font-size: var(--p);
    font-weight: 400;
    font-size: 15px;
  }
  .ant-input {
    font-weight: 400 !important;
    font-family: "Source Sans Pro", sans-serif, "system-ui";
  }
  &:focus {
    box-shadow: none;
    border: 2px solid #138cd6 !important;
  }
`;
