import React from "react";
import {
  ButtonSizes,
  ButtonVariants,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@certa/catalyst";

import { useCreateNewWorkflow } from "../../hooks/useCreateNewWorkflow";
import { Book, Question } from "@certa/icons";
import { CERTA_SUPPORT } from "../../constants";
import type { DropdownOption } from "@certa/common";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

type CreateCertaSupportDropdownProps = {
  dropdownOptions?: DropdownOption[];
  supportTicketData?: DropdownOption | null;
};

export const CreateCertaSupportDropdown = (
  props: CreateCertaSupportDropdownProps
) => {
  const { onSelect } = useCreateNewWorkflow();
  return (
    <DropdownMenu
      onOpenChange={open => {
        open &&
          MixPanelActions.track(
            MixPanelEvents.headerEvents.HEADER_CLICK_HELP_DROPDOWN
          );
      }}
    >
      <DropdownMenuTrigger>
        <Button
          size={ButtonSizes.SMALL}
          variant={ButtonVariants.LINK}
          leftIcon={<Question />}
          showDropdownArrow={true}
        >
          Help
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <>
          {props?.supportTicketData && (
            <DropdownMenuGroup
              hasSeparator={(props?.dropdownOptions || []).length > 0}
            >
              <DropdownMenuItem value={CERTA_SUPPORT} onSelect={onSelect}>
                <Book />
                {props?.supportTicketData.label}
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
          {props?.dropdownOptions?.map(
            (option: DropdownOption, index: number) => (
              <DropdownMenuItem
                key={index}
                value={option?.url || ""}
                onSelect={window.open}
              >
                {option.label}
              </DropdownMenuItem>
            )
          )}
        </>
      </DropdownMenuContent>
    </DropdownMenu>
  );
  // }
};
