import { useReducer } from "react";

export type ChangeType = "INTEGRATION_STATUS" | "STATUS" | "RISK_CODE";

export type AdjducationRiskCodeChange = {
  flag: number;
  reasonCode?: string;
};
export type WorkflowStatusChange = {
  id: number;
};

export type IntegrationStatusChange = {
  updatedStatus: string;
};

export type StateType = {
  initialState: {
    [k in ChangeType]?: k extends "RISK_CODE"
      ? AdjducationRiskCodeChange
      : k extends "STATUS"
        ? WorkflowStatusChange
        : IntegrationStatusChange;
  };
  changes?: {
    [k in ChangeType]?: k extends "RISK_CODE"
      ? AdjducationRiskCodeChange
      : k extends "STATUS"
        ? WorkflowStatusChange
        : IntegrationStatusChange;
  };
  changeSet: Set<ChangeType>;
};

type Action =
  | {
      type: `CHANGE_INTEGRATION_STATUS`;
      payload: IntegrationStatusChange;
    }
  | {
      type: "CHANGE_RISK_CODE";
      payload: AdjducationRiskCodeChange;
    }
  | {
      type: "CHANGE_STATUS";
      payload: WorkflowStatusChange;
    }
  | {
      type: "SET_INITIAL_RISK_CODE";
      payload?: AdjducationRiskCodeChange;
    }
  | {
      type: `RESET_${ChangeType}`;
    };

const changeReducer = (state: StateType, action: Action): StateType => {
  switch (action.type) {
    case "CHANGE_INTEGRATION_STATUS": {
      const isIntegrationStatusChanged =
        state.initialState.INTEGRATION_STATUS?.updatedStatus !==
        action.payload.updatedStatus;
      if (!isIntegrationStatusChanged) {
        state.changeSet.delete("INTEGRATION_STATUS");
      }
      return {
        ...state,
        changes: {
          ...state.changes,
          INTEGRATION_STATUS: action.payload
        },
        changeSet: isIntegrationStatusChanged
          ? state.changeSet.add("INTEGRATION_STATUS")
          : state.changeSet
      };
    }
    case "CHANGE_STATUS": {
      const isStatusChanged =
        state.initialState.STATUS?.id !== action.payload.id;
      if (!isStatusChanged) {
        state.changeSet.delete("STATUS");
      }
      return {
        ...state,
        changes: {
          ...state.changes,
          STATUS: action.payload
        },
        changeSet: isStatusChanged
          ? state.changeSet.add("STATUS")
          : state.changeSet
      };
    }
    case "CHANGE_RISK_CODE": {
      const isRiskCodeChanged =
        state.initialState.RISK_CODE?.flag !== action.payload?.flag ||
        state.initialState.RISK_CODE?.reasonCode !== action.payload?.reasonCode;
      if (!isRiskCodeChanged) {
        state.changeSet.delete("RISK_CODE");
      }
      return {
        ...state,
        changes: {
          ...state.changes,
          RISK_CODE: action.payload
        },
        changeSet: isRiskCodeChanged
          ? state.changeSet.add("RISK_CODE")
          : state.changeSet
      };
    }
    case "SET_INITIAL_RISK_CODE": {
      return {
        ...state,
        initialState: {
          ...state.initialState,
          RISK_CODE: action.payload
        }
      };
    }
    case "RESET_INTEGRATION_STATUS": {
      state.changeSet.delete("INTEGRATION_STATUS");
      return {
        ...state,
        initialState: {
          ...state.initialState,
          INTEGRATION_STATUS: state.changes?.INTEGRATION_STATUS
        }
      };
    }
    case "RESET_STATUS": {
      state.changeSet.delete("STATUS");
      return {
        ...state,
        initialState: {
          ...state.initialState,
          STATUS: state.changes?.STATUS
        }
      };
    }
    case "RESET_RISK_CODE": {
      state.changeSet.delete("RISK_CODE");
      return {
        ...state,
        initialState: {
          ...state.initialState,
          RISK_CODE: state.changes?.RISK_CODE
        }
      };
    }
  }
  return state;
};

const useAdjudicationRiskCodeAndStatusOnChange = (
  defaultStatus?: WorkflowStatusChange,
  defaultAdjudicationCode?: AdjducationRiskCodeChange,
  defaultIntegraton?: IntegrationStatusChange
) => {
  const [state, dispatch] = useReducer<typeof changeReducer>(changeReducer, {
    initialState: {
      STATUS: defaultStatus,
      RISK_CODE: defaultAdjudicationCode,
      INTEGRATION_STATUS: defaultIntegraton
    },
    changeSet: new Set()
  });

  return {
    isChanged: state.changeSet.size > 0,
    dispatcher: dispatch,
    changeSet: state.changeSet,
    changedPayload: state.changes
  };
};

export { useAdjudicationRiskCodeAndStatusOnChange };
