export const MODULE_NAME = "WORKFLOW_LIST";

export const WORKLFOW_ASC_SORT_PARAM = "sorting_primary_field";
export const WORKLFOW_DESC_SORT_PARAM = "-sorting_primary_field";
export const MY_STUFF_FILTERS_ENUM = {
  MY_TASKS: { key: "incomplete_step_tags", name: "MY_TASKS" },
  RECENT_TASKS: { key: "self_created", name: "RECENT_TASKS" },
  MY_GROUPS: { key: "incomplete_step_group", name: "MY_GROUPS" },
  MY_ALERTS: { key: "alert_category", name: "MY_ALERTS" }
};
export const FILTERS_ENUM = {
  KIND_FILTER: { key: "kind_tag", name: "KIND_FILTER" },
  MY_TASK_FILTER: { key: "incomplete_step_tags", name: "MY_TASK_FILTER" },
  TASK_QUEUE_FILTER: {
    key: "incomplete_step_group",
    name: "TASK_QUEUE_FILTER"
  },
  ALERT_FILTER: { key: "alert_category", name: "ALERT_FILTER" },
  STATUS_FILTER: { key: "status__in", name: "STATUS_FILTER" },
  REGION_FILTER: { key: "region__in", name: "REGION_FILTER" },
  BUSINESS_UNIT_FILTER: {
    key: "business_unit__in",
    name: "BUSINESS_UNIT_FILTER"
  },
  PAGE_FILTER: { key: "page", name: "PAGE_FILTER" },
  FIELD_ANSWER_FILTER: { key: "answer", name: "FIELD_ANSWER_FILTER" },
  ADVANCED_FILTER: { key: "answer", name: "ADVANCED_FILTER" },
  ORDERING_FILTER: { key: "ordering", name: "ORDERING_FILTER" },
  SEARCH_FILTER: { key: "q", name: "SEARCH_FILTER" },
  START_DATE_FILTER: { key: "created_range_after", name: "START_DATE_FILTER" },
  END_DATE_FILTER: { key: "created_range_before", name: "END_DATE_FILTER" },
  // Specific to frontend
  // Main use case is to add/remove kind_tag filter when doing text search
  REMOVE_KIND_FILTER: { key: "remove_kind_filter", name: "REMOVE_KIND_FILTER" },
  ...MY_STUFF_FILTERS_ENUM
};

export const DASHBOARD_VIEWS = {
  MY_STUFF: "MY_STUFF",
  KIND_VIEW: "KIND_VIEW"
};

export const FILTER_FIELD_TYPES = {
  SELECT: "SELECT",
  RANGE: "RANGE"
};
