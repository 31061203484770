import {
  ButtonTypes,
  ButtonVariants,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Stack,
  TypographyColors,
  TypographyVariants,
  Typography,
  Ellipsis,
  ButtonSizes
} from "@certa/catalyst";
import { ChevronLeft, Close, Dots } from "@certa/icons";
import type { CommentChannel, WorkflowFamily } from "@certa/types";
import { css } from "emotion";
import { useIntl } from "react-intl";
import { handleOnPrintComment } from "../commentsNew/SingleChannelView/SingleChannelView";
import { useScreenResolution } from "@certa/common";

export const DrawerTitle = ({
  isMultiChannel,
  channels,
  workflowFamily,
  fieldName,
  activeChannel,
  setActiveChannel,
  showBackButton = false,
  onClose
}: {
  isMultiChannel: boolean;
  channels: CommentChannel[] | undefined;
  workflowFamily: WorkflowFamily | undefined;
  fieldName: string | undefined;
  activeChannel: CommentChannel | null;
  setActiveChannel: (activeChannel: CommentChannel | null) => void;
  showBackButton?: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();

  const commentsText = intl.formatMessage({
    id: "workflowsInstances.commentsText",
    defaultMessage: "Comments"
  });

  const fieldText = intl.formatMessage({
    id: "commentsDrawer.fieldText",
    defaultMessage: "Field"
  });

  const isOnMultiChannelView = isMultiChannel && !activeChannel;
  const stepGroupName =
    activeChannel?.stepGroupName || channels?.[0]?.stepGroupName;
  const stepName = activeChannel?.stepName || channels?.[0]?.stepName;

  const titleForDashboardAndReports = workflowFamily
    ?.map(workflow => workflow.name)
    .join(": ");

  const commentsDrawerTitle = titleForDashboardAndReports
    ? titleForDashboardAndReports
    : `${stepGroupName}: ${stepName}`;

  const commentsDrawerSubHeading = fieldName
    ? `${fieldText}: ${fieldName}`
    : "";

  const { isMobileResolution } = useScreenResolution();

  const mobileResolutionPadding = isOnMultiChannelView ? "8 4 8 16" : "12 8";

  const desktopResolutionPadding = isOnMultiChannelView
    ? "24 20 12 24"
    : "12 20 8 24";

  return (
    <Stack
      direction="horizontal"
      align="center"
      justify="space-between"
      padding={
        isMobileResolution ? mobileResolutionPadding : desktopResolutionPadding
      }
      dangerouslySetClassName={drawerTitleWrapperStyles(isOnMultiChannelView)}
    >
      <Stack direction="horizontal" gap="4">
        {showBackButton && !isOnMultiChannelView && (
          <Button
            leftIcon={<ChevronLeft />}
            variant={ButtonVariants.TEXT}
            size={isMobileResolution ? ButtonSizes.SMALL : ButtonSizes.DEFAULT}
            onClick={() =>
              isMultiChannel && activeChannel
                ? setActiveChannel(null)
                : onClose()
            }
          />
        )}
        <Stack
          direction="vertical"
          align="start"
          justify={fieldName ? "flex-start" : "center"}
        >
          <Typography
            className={drawerTitleTextStyles}
            variant={
              isOnMultiChannelView
                ? TypographyVariants.TITLE_SM
                : TypographyVariants.BODY_BOLD
            }
            color={TypographyColors.NEUTRAL_800}
          >
            {!isOnMultiChannelView && stepGroupName && stepName ? (
              <Ellipsis>{commentsDrawerTitle}</Ellipsis>
            ) : (
              commentsText
            )}
          </Typography>
          {commentsDrawerSubHeading && (
            <Typography
              variant={TypographyVariants.BODY}
              color={TypographyColors.NEUTRAL_700}
              className={css({
                maxWidth: "25rem",
                textOverflow: "ellipsis"
              })}
            >
              <Ellipsis>{commentsDrawerSubHeading}</Ellipsis>
            </Typography>
          )}
        </Stack>
      </Stack>
      <div className={drawerActionButtonsStyles}>
        {(!isMultiChannel || !!activeChannel) && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <div>
                <Button
                  aria-label="Meta actions"
                  leftIcon={<Dots />}
                  type={ButtonTypes.ICON}
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" width="10.5rem">
              <DropdownMenuItem
                value="print"
                onSelect={() => {
                  handleOnPrintComment();
                }}
              >
                {intl.formatMessage({
                  id: "stepBodyFormInstances.printText",
                  defaultMessage: "Print"
                })}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}

        <Button
          leftIcon={<Close size={18} />}
          variant={ButtonVariants.TEXT}
          onClick={onClose}
        />
      </div>
    </Stack>
  );
};

const drawerTitleWrapperStyles = (isOnMultiChannelView: boolean) => {
  return css({
    borderBottom: isOnMultiChannelView
      ? "1px solid var(--colors-neutral-400)"
      : "none"
  });
};

const drawerTitleTextStyles = css({
  maxWidth: "20rem",
  overflow: "hidden",
  whiteSpace: "nowrap"
});

const drawerActionButtonsStyles = css({
  display: "flex",
  alignItems: "center",
  gap: "var(--space-16)",
  color: TypographyColors.BRAND_400
});
