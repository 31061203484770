import type {
  TaskHeaderProcess,
  TaskHeaderProcessList,
  TaskHeader
} from "../types/tasks.types";
import type {
  DependentSteps,
  Field,
  FieldAlert,
  FieldCommentFlag,
  StepDependencies,
  StepDetails as TaskDetails,
  DistinctStatues
} from "@certa/types";
import { queryClient } from "@certa/queries/src/utils/utils";
import {
  getFieldTypeFromField,
  isIntegrationField
} from "../utils/fields.utils";
import { getHiddenDependentFieldsAndNormalFields } from "main/src/js/components/WorkflowDetails/utils/modelCreator";

export const taskLaneHeadersModeCreator = (response: any): TaskHeader[] => {
  const data =
    response?.map(
      (header: any): TaskHeader => ({
        groupTag: header?.tag || null,
        kindId: header.workflow_kind_id,
        taskCount: header.count || null,
        groupName: header?.name || null,
        kindName: header.kind_name,
        label: header?.label || null,
        labelEN: header?.label_en || null,
        statusId: header?.id || null
      })
    ) || [];

  return data;
};

export const headerProcessListModelCreator = (
  response: any
): TaskHeaderProcessList => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  const data = {
    count: response.count,
    next: response.next,
    previous: response.previous,
    results:
      response?.results?.map(
        (task: any): TaskHeaderProcess => ({
          taskId: task.id,
          taskName: task.name,
          logo: task.logo,
          kindName: task.kind_name,
          progress: task.progress,
          createdBy: {
            id: task.created_by.id,
            fullName: task?.created_by?.first_name
              ? task?.created_by?.last_name
                ? `${task.created_by?.first_name} ${task.created_by?.last_name}`
                : task.created_by?.first_name
              : null,
            email: task?.created_by?.email || null,
            groups: task?.created_by?.groups || []
          },
          myTaskCount: task.my_tasks_count || 0,
          status: {
            displayName: task.status.display_name,
            colorCode: availableStatusesKeyedById[task.status.id]?.color_code
          },
          createdAt: task.created_at,
          rootProcess: task.root,
          workflowFamily: task.workflow_family,
          lcData: task.lc_data || [],
          isWorkflowBeingMigrated: !!task.is_being_migrated
        })
      ) || []
  };

  return data;
};

export const nonABProcessTypeModelCreator = (
  response: any
): { id: number; name: string }[] =>
  response?.map((kind: any) => ({
    id: kind.kind_id,
    name: kind.kind_name
  })) || [];

const fieldMapper = (field: any, jsonFromAPI: any): Field => ({
  id: field.id,
  alerts: field.alerts.map(
    (alert: any): FieldAlert => ({
      id: alert.id,
      catColorLabel: alert.cat_color_label,
      alertTag: alert.alert_tag,
      stepId: jsonFromAPI.id,
      fieldId: field.id
    })
  ),
  // TODO: Remove in the 2nd pass when table is not using it from here
  // anymore. Currently it helps table to use the useIsDisabled hook
  // which requires the step number to be part of field's model, to
  // get step props from redux. WHEN REMOVING, MAKE SURE TO UPDATE MODEL
  step: jsonFromAPI.id,
  commentCount: field.comment_count,
  integrationCommentCounts: field.integration_comment_counts,
  searchParamData: field.search_param_data,
  isRequired: field.is_required,
  regexValue: field.regex_value,
  regexError: field.regex_error,
  labelValue: field.label_value,
  createdAt: field.created_at,
  updatedAt: field.updated_at,
  isEnabled: field.is_enabled,
  integrationJSON: field.integration_json,
  extra: field.extra,
  statusIndicator: field.status_indicator
    ? {
        indicator: field.status_indicator.indicator,
        helpText: field.status_indicator.help_text
      }
    : null,
  commentFlags: field.comment_flags.map(
    (flag: any): FieldCommentFlag => ({
      uidOrFieldId: flag.uid_or_field_id,
      commentFlagOptionId: flag.comment_flag_option_id,
      commentFlagOptionTag: flag.comment_flag_option_tag
    })
  ),
  answer: field.answer
    ? {
        id: field.answer.id,
        submittedBy: field.answer.submitted_by
          ? {
              id: field.answer.submitted_by.id,
              firstName: field.answer.submitted_by.first_name,
              lastName: field.answer.submitted_by.last_name,
              email: field.answer.submitted_by.email
            }
          : null,
        submittedAt: field.answer.submitted_at,
        answer: field.answer.answer,
        files: field.answer.files,
        attachment: field.answer.attachment,
        extraJSON: field.answer.extra_json,
        updatedAt: field.answer.updated_at
      }
    : null,
  definition: {
    id: field.definition.id,
    fieldType: getFieldTypeFromField(field),
    body: field.definition.body,
    tag: field.definition.tag,
    helpText: field.definition.help_text,
    isRequired: field.definition.is_required,
    regexValue: field.definition.regex_value,
    regexError: field.definition.regex_error,
    size: field.definition.size,
    attachment: field.definition.attachment,
    defaultValue: field.definition.default_value,
    disabled: field.definition.disabled,
    hidden: field.definition.hidden,
    /**
     * Only assigning additional values to extra if
     * it has some value. We're doing this to preserve
     * extra's type in places where it isn't available.
     */
    extra: field.definition.extra
      ? {
          ...field.definition.extra,
          isIntegrationField: isIntegrationField(field)
        }
      : field.definition.extra,
    meta: field.definition.meta,
    options: field.definition.options,
    isEncrypted: field.definition.is_encrypted
  },
  isReadOnly: field.is_read_only
});

export const taskDetailsModelCreator = (
  jsonFromAPI: any,
  mergeHiddenFields: boolean
): TaskDetails => {
  const { hiddenDependentFields: hiddenFields, normalFields } =
    getHiddenDependentFieldsAndNormalFields(
      jsonFromAPI.fields,
      mergeHiddenFields
    );

  return {
    id: jsonFromAPI.id,
    commentCount: jsonFromAPI.comment_count,
    isEditable: jsonFromAPI.has_write_access,
    completedBy: jsonFromAPI.completed_by
      ? {
          id: jsonFromAPI.completed_by.id,
          firstName: jsonFromAPI.completed_by.first_name,
          lastName: jsonFromAPI.completed_by.last_name,
          email: jsonFromAPI.completed_by.email
        }
      : null,
    completedAt: jsonFromAPI.completed_at,
    versions: jsonFromAPI.versions.map((version: any) => ({
      value: version.value,
      submittedAt: version.submitted_at,
      versionLabel: version.version_indicator_label,
      versionLabelColor: version.version_indicator_color
    })),
    approvedAt: jsonFromAPI.approved_at,
    createdAt: jsonFromAPI.created_at,
    updatedAt: jsonFromAPI.updated_at,
    isLocked: jsonFromAPI.is_locked,
    hiddenDependentFields: hiddenFields.map(
      (field: any): Field => fieldMapper(field, jsonFromAPI)
    ),
    fields: normalFields.map(
      (field: any): Field => fieldMapper(field, jsonFromAPI)
    ),
    definition: {
      id: jsonFromAPI.definition.id,
      name: jsonFromAPI.definition.name,
      tag: jsonFromAPI.definition.tag,
      availableUserTags: jsonFromAPI.definition.available_user_tags,
      extra: jsonFromAPI.definition.extra,
      dependentSteps: jsonFromAPI.definition.dependent_steps.map(
        (step: any): DependentSteps => ({
          id: step.id,
          name: step.name,
          definitionTag: step.tag
        })
      ),
      stepType: jsonFromAPI.definition.step_type,
      disableSubmitReason: jsonFromAPI.definition.disable_submit_reason
    },
    /**
     * Permission that BE is handling for step submit
     * - User should have write access to the step
     * - The intersection groups of between user groups and the user groups of workflow, stepgroup and step -> if this intersection is containing step submit
     * - Step should be incomplete -> step.completed_at = None
     * - Step should be visible -> step.is_enabled = True
     * - Step should be not locked -> step.is_locked = False
     * - Step should be doable -> step.definition.step_type = "Doable"
     */
    canSubmitStep: jsonFromAPI.can_submit,
    /**
     * Permission that BE is handling for step undo
     * - User should have write access to the step
     * - The intersection groups of between user groups and the user groups of workflow,stepgroup and step -> if this intersection is containing step undo
     * - Step should be complete -> step.completed_at = DateTime
     * - Step should be visible -> step.is_enabled = True
     * - Step should be not locked -> step.is_locked = False
     * - Step should be doable -> step.definition.step_type = "Doable"
     */
    canUndoStep: jsonFromAPI.can_undo,
    hasWriteAccess: jsonFromAPI.has_write_access,
    isAppBuilderFrozen: jsonFromAPI.is_app_builder_frozen
  };
};

export const taskDependenciesModel = (response: any): StepDependencies => {
  const data = response["inter_step_dependency_data"];
  const formattedData: StepDependencies = {};
  Object.keys(data).forEach((groupTag: string) => {
    const currentStepGroup = data[groupTag];
    formattedData[groupTag] = {
      lockDependentOn: currentStepGroup["lock_dependent_on"],
      visibilityAffectedBy: currentStepGroup["visibility_affected_by"]
    };
  });
  return formattedData;
};

export const taskDistinctStatuesModel = (
  response: number[]
): DistinctStatues => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  const statues = response.map((statusId: number) => {
    const status = availableStatusesKeyedById[statusId];
    return {
      id: status?.id,
      name: status?.display_name,
      tag: status?.tag
    };
  });
  return statues;
};
