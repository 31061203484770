import { css } from "emotion";
import type { FC } from "react";
import React from "react";
import { Stack, Text } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";
import type { ProcessTypes } from "../../constants";
import ChildProcessTable from "./ChildProcessTable";
import { Archive } from "@certa/icons";

const ChildProcessTypeList: FC<{
  processTypes: ProcessTypes | undefined;
  selectedProcessId: number;
  handleCreateNewProcess: (wfKindId: number) => void;
  isWorkflowBeingMigrated: boolean;
}> = ({
  processTypes,
  selectedProcessId,
  handleCreateNewProcess,
  isWorkflowBeingMigrated
}) => {
  const intl = useIntl();

  const childProcessText = intl.formatMessage({
    id: "processFamily.childProcess",
    defaultMessage: "Child Processes"
  });

  const noChildProcessesText = intl.formatMessage({
    id: "processFamily.childProcessNotFound",
    defaultMessage: "No child processes"
  });

  return (
    <Stack direction="vertical" gap="s4">
      <Text variant="p2-bold-upper" color="neutral-70">
        {childProcessText}
      </Text>
      {processTypes?.length ? (
        <Stack
          className={css`
            &::-webkit-scrollbar {
              display: none;
            }
          `}
          direction="vertical"
          gutter="s00 s1 s00 s00"
        >
          <ChildProcessTable
            processTypes={processTypes}
            selectedProcessId={selectedProcessId}
            handleCreateNewProcess={handleCreateNewProcess}
            isWorkflowBeingMigrated={isWorkflowBeingMigrated}
          />
        </Stack>
      ) : (
        <Stack
          direction="vertical"
          align="center"
          justify="center"
          gap="s3"
          className={css`
            border: 1px solid var(--neutral-20);
            background-color: var(--neutral-5);
            margin-top: 20px;
            text-align: center;
            min-height: 100px;
            border-radius: var(--big-border-radius);
          `}
        >
          <Stack
            direction="horizontal"
            gap="s2"
            align="center"
            justify="center"
          >
            <Stack
              align="center"
              justify="center"
              className={css`
                background-color: var(--neutral-20);
                color: var(--neutral-50);
                border-radius: 50%;
                height: 40px;
                width: 40px;
              `}
            >
              <Archive />
            </Stack>

            <Text variant="h3-medium" color="neutral-70">
              {noChildProcessesText}
            </Text>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export { ChildProcessTypeList };
