import React, { useEffect, useMemo, useRef } from "react";
import {
  Tooltip as CatalystTooltip,
  HIDE_TOOLTIP_EVENTS,
  SHOW_TOOLTIP_EVENTS,
  TooltipPlacements,
  TooltipVariants,
  Typography,
  TypographyVariants,
  classNames
} from "@certa/catalyst";
import type { TooltipProps as CatalystTooltipProps } from "@certa/catalyst";
import styles from "./Tooltip.module.css";
import { getTooltipTopAndLeft, isPopoverAPISupported } from "./Tooltip.utils";

const TOOLTIP_SHOW_DELAY = 100; // Milliseconds
// To avoid creating a different test snapshots when using unique ids (like nanoid)
let _tooltipCount = 0;

type TooltipProps = Pick<
  CatalystTooltipProps,
  | "variant"
  | "content"
  | "children"
  | "maxWidth"
  | "placement"
  | "targetClassName"
  | "hideTooltipFromScreenReaders"
  | "show"
  | "showArrow"
> & {
  shouldApplyDefaultTypography?: boolean;
};
/**
 * Tooltip component driven by Native API, with Catalyst's Tooltip fallback
 * if browser does not support popover.
 * @param props showArrow: boolean - Show the arrow in the tooltip,
 * although it will not be displayed for the time being. This will be introduced in the futur.
 */
export const Tooltip = ({
  shouldApplyDefaultTypography,
  ...rest
}: TooltipProps) => {
  const isPopoverSupported = isPopoverAPISupported();

  if (isPopoverSupported) {
    return (
      <PopoverAPITooltip
        {...rest}
        shouldApplyDefaultTypography={shouldApplyDefaultTypography}
      />
    );
  }

  return <CatalystTooltip {...rest} />;
};

const PopoverAPITooltip = (props: TooltipProps) => {
  const {
    content,
    children,
    variant = TooltipVariants.DARK,
    hideTooltipFromScreenReaders: shouldHideTooltipFromScreenReaders = false,
    placement = TooltipPlacements.TOP,
    targetClassName,
    maxWidth,
    show: shouldShow = true,
    shouldApplyDefaultTypography = true
  } = props;
  const tooltipTargetRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const tooltipId = useMemo(() => "tooltip-popover-api-" + ++_tooltipCount, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const container = tooltipTargetRef.current;
    const popover: HTMLDivElement | null = popoverRef.current;
    const onScroll = () => {
      if (popover?.popover) {
        popover?.hidePopover();
      }
      window.removeEventListener("scroll", onScroll, true);
    };

    const showTooltip = () => {
      // get position right before showing the tooltip only,
      // this will make sure that the tooltip is shown at the correct position
      // if the target element is moved after initial render.
      const position = container?.getBoundingClientRect();
      window.addEventListener("scroll", onScroll, true);
      if (popover) {
        const popoverPosition = getTooltipTopAndLeft(position, placement);

        popover.style.top = popoverPosition.top;
        popover.style.left = popoverPosition.left;
        popover.style.transform = popoverPosition.transform;
      }
      // delay opening
      timeout = setTimeout(() => {
        if (shouldShow) {
          popover?.showPopover();
        }
      }, TOOLTIP_SHOW_DELAY);
    };

    const hideTooltip = () => {
      clearTimeout(timeout);
      popover?.hidePopover();
      window.removeEventListener("scroll", onScroll, true);
    };

    SHOW_TOOLTIP_EVENTS.forEach(event => {
      container?.addEventListener(event, showTooltip);
    });
    HIDE_TOOLTIP_EVENTS.forEach(event => {
      container?.addEventListener(event, hideTooltip);
    });

    return () => {
      clearTimeout(timeout);
      SHOW_TOOLTIP_EVENTS.forEach(showTooltipEvent => {
        container?.removeEventListener(showTooltipEvent, showTooltip);
      });

      HIDE_TOOLTIP_EVENTS.forEach(hideTooltipEvent => {
        container?.removeEventListener(hideTooltipEvent, hideTooltip);
      });
      window.removeEventListener("scroll", onScroll, true);
    };
  }, [placement, shouldShow]);

  return (
    <div
      // have to typecast here because Popover API is new and it is not supported by React 17 typings
      {...({ popovertarget: tooltipId } as { popovertarget: string })}
      ref={tooltipTargetRef}
      className={classNames(
        styles.catalystTooltipNativeTargetWrapper,
        targetClassName
      )}
    >
      {React.cloneElement(children, {
        "aria-describedby": shouldHideTooltipFromScreenReaders
          ? null
          : tooltipId
      })}
      <div
        id={tooltipId}
        ref={popoverRef}
        // have to typecast here because Popover API is new and it is not supported by React 17 typings
        {...({ popover: "auto" } as { popover: "auto" | "manual" })}
        role="tooltip"
        data-tooltip-placement={placement}
        className={classNames({
          [styles.catalystTooltipNative]: true,
          [styles["catalystTooltipNative" + variant]]: true
        })}
        style={{ maxWidth }}
      >
        {shouldApplyDefaultTypography ? (
          <Typography variant={TypographyVariants.LABEL_SM_BOLD}>
            {content}
          </Typography>
        ) : (
          content
        )}
      </div>
    </div>
  );
};
