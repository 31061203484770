/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import type { FC } from "react";
import type { IconProps } from "../types";

const ProcessDetailPlaceholder: FC<
  React.SVGProps<SVGSVGElement> & IconProps
> = props => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#EAEAEA" />
      <g clip-path="url(#clipProcessDetailPlaceholder)">
        <path
          d="M15 25.7305V28H17V26H19V28H21V26H23V28H25V25.7305C25.3027 25.5547 25.5547 25.3027 25.7305 25H28V23H26V21H28V19H26V17H28V15H25.7305C25.5547 14.6973 25.3027 14.4453 25 14.2695V12H23V14H21V12H19V14H17V12H15V14.2695C14.6973 14.4453 14.4453 14.6972 14.2695 15H12V17H14V19H12V21H14V23H12V25H14.2695C14.4453 25.3027 14.6972 25.5547 15 25.7305ZM16 21V16H24V24H16V21Z"
          fill="#8F8F91"
        />
      </g>
      <defs>
        <clipPath id="clipProcessDetailPlaceholder">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ProcessDetailPlaceholder };
