/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { AnchorHTMLAttributes } from "react";
import React from "react";
import { fetchObjectUrlAndDownload } from "../utils/helper";
import { MuultipleFiles, SingleFile } from "@certa/icons";

export const LCIcon = React.memo(
  ({
    value,
    type
  }: {
    value: string | string[];
    type: "tooltip" | "table_cell";
  }) => {
    const isFieldMultiFileField = Array.isArray(value);
    const styleForTableCellType =
      type === "table_cell"
        ? {
            // explanation for important: There is some other css from previous code which is taking precendence over
            display: "flex !important",
            height: "15px",
            alignItems: "center",
            justifyContent: "center",
            span: {
              height: "12px",
              width: "12px"
            }
          }
        : {
            svg: {
              height: "16px",
              width: "16px"
            }
          };
    const anchorAttributes: AnchorHTMLAttributes<
      HTMLAnchorElement | HTMLDivElement
    > = isFieldMultiFileField
      ? {
          css: css`
            ${styleForTableCellType}
            color: var(--brand-15);
            cursor: pointer;
            &:hover {
              color: var(--brand);
            }
          `,
          onClick: () => {
            value.forEach((path: string) => {
              fetchObjectUrlAndDownload(path);
            });
          },
          role: "link"
        }
      : {
          href: value,
          target: "_blank",
          rel: "noopener noreferrer",
          css: css`
            ${styleForTableCellType}
            color: var(--brand-15) !important;
            &:hover {
              color: var(--brand) !important;
            }
          `
        };

    const icon = isFieldMultiFileField ? (
      <MuultipleFiles data-testid="lc_icon" />
    ) : (
      <SingleFile data-testid="lc_icon" />
    );

    // For multi file field types, we are getting multiple urls which is string[]
    return Array.isArray(value) ? (
      <div {...anchorAttributes}>{icon}</div>
    ) : (
      <a {...anchorAttributes}>{icon}</a>
    );
  }
);
