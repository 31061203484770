import type { HashMap } from "@certa/types";
/**
 * Utility function to filter out any objects that may be similar based
 * on a key. Returned result is an array of objects where the specified
 * compare key is unique for that array list. No other object in that
 * array will have the same value for the compare key.
 * @param compareKey
 * @param objectOrArray
 */
export const removeDuplicateObjectsByKey = (
  compareKey: string,
  objectList: Array<HashMap>
) => {
  const uniqueKeys = new Set();
  return objectList.filter(obj => {
    if (!uniqueKeys.has(obj[compareKey])) {
      uniqueKeys.add(obj[compareKey]);
      return true;
    } else return false;
  });
};

/**
 * This is added because on walmart slackcart some bug is causing API to respond with 2 identical threads
 * within the thread data. Until that is resolved, this logic will help in removing the duplicate threads.
 * Spike raised: https://thevetted.atlassian.net/browse/VET-9918
 */
export const removeDuplicateThreads = removeDuplicateObjectsByKey.bind(
  null,
  "threadId"
);

export const extractAttachmentName = (attachmentURL: string) => {
  const parts = attachmentURL.split("/");
  return parts[parts.length - 1].split("?")[0];
};

export const COMMENT_MESSAGE_GROUP_FORMAT = "DD MMMM YYYY";
