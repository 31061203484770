import moment from "moment";

/**
 * @deprecated
 */
export const gmtDate = value => {
  const day = moment(value).format("dddd");
  const date = moment(value).format("MMM Do YYYY");
  const time = moment(value).format("LTS");
  const offset = moment(value).format("Z");
  const gmt = `${day}, ${date}, ${time} (GMT${offset})`;
  return gmt;
};
