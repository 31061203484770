import type { ElementType } from "react";
import React, { useMemo } from "react";
import { Route, Redirect } from "react-router-dom";
import Godaam from "../../js/utils/storage";
import queryString from "query-string";
import { get as lodashGet } from "lodash-es";

type ILocation = {
  search: string;
};

/**
 * @param props
 * - component is the final UI to be served on the route
 * - fallbackRoute is the route which is served when next route doesn't exist
 * - fallbackLoader is the loading UI to be served until loadable/lazy component is loaded
 */
type IAnonOnlyRouteProps = {
  component: ElementType<any>;
  fallbackRoute?: string;
  fallbackLoader: ElementType<any>;
};
export const AnonOnlyRoute = React.memo((props: IAnonOnlyRouteProps) => {
  const {
    component: Component,
    fallbackRoute = "/",
    fallbackLoader,
    ...rest
  } = props;
  const { location }: { location?: ILocation } = rest;

  const pathname = useMemo((): string => {
    const to = queryString.parse(lodashGet(location, "search", ""));
    return to.next ? (to.next as string) : fallbackRoute;
  }, [location, fallbackRoute]);

  return (
    <Route
      {...rest}
      render={props =>
        Godaam.user ? (
          <Redirect to={pathname} />
        ) : (
          <Component fallback={fallbackLoader} {...props} />
        )
      }
    />
  );
});
