import { cloneDeep } from "lodash-es";
import type {
  AttributesPayloadED,
  ChartConfigED,
  ColumnED,
  FilterConditionED,
  FilterED,
  FilterPayloadED,
  GroupByED,
  GroupByPayloadED,
  OperationArgumentED,
  OperationED,
  OperationPayloadED,
  OrderByED,
  OrderByPayloadED,
  OtherChartConfigurationsED,
  OtherChartConfigurationsPayloadED,
  TableDataED,
  TableDataValueED
} from "@certa/types";
import { LENGTH_ZERO } from "../constants/common";
import {
  defaultChartConfigORM,
  defaultOtherConfigurationsORM
} from "../constants/chartConfig";

const DATA_POINT_INDEX = 1;

export const chartConfigModelCreatorED = (data: any) => {
  const chartConfig: ChartConfigED = {
    attributes: [],
    operations: [],
    groupBy: [],
    orderBy: [],
    otherConfigurations: cloneDeep(defaultChartConfigORM.otherConfigurations)
  };
  if (data.attributes && Array.isArray(data.attributes)) {
    chartConfig.attributes = attributesModalCreatorED(data.attributes);
  }
  if (data.operations && Array.isArray(data.operations)) {
    const operations = operationsModalCreatorED(data.operations);
    if (operations) {
      chartConfig.operations = operations;
    }
  }
  if (data.group_by && Array.isArray(data.group_by)) {
    chartConfig.groupBy = groupByModalCreatorED(data.group_by);
  }

  if (data.order_by && Array.isArray(data.order_by)) {
    chartConfig.orderBy = orderByModalCreatorED(data.order_by);
  }

  if (data.other_configurations) {
    chartConfig.otherConfigurations = otherConfigurationsModalCreatorED(
      data.other_configurations
    );
  }

  return chartConfig;
};

export const columnsModelCreatorED = (data: any) => {
  let columnsDataED: ColumnED[] = [];
  if (data && Array.isArray(data)) {
    columnsDataED = attributesModalCreatorED(data);
  }

  return columnsDataED;
};

export const columnsDataModelCreator = (
  data: any,
  columnAttribues?: AttributesPayloadED[]
): { results: TableDataED[]; count: number } => {
  let dataPointsArr: TableDataValueED[][] = [];
  if (Array.isArray(data?.results)) {
    dataPointsArr = data?.results.slice(DATA_POINT_INDEX);
  }

  const tableData = dataPointsArr.map(dataPoint => {
    const row: TableDataED = {};
    columnAttribues?.forEach((colAttr, index) => {
      row[colAttr.value] = dataPoint[index];
    });
    return row;
  });

  return {
    results: tableData,
    count: data?.count || LENGTH_ZERO
  };
};

export const filtersDataModelCreatorED = (
  data: any,
  columnAttribues?: ColumnED[]
): TableDataED[] => {
  let dataPointsArr: TableDataValueED[][] = [];
  if (Array.isArray(data)) {
    dataPointsArr = data?.slice(DATA_POINT_INDEX);
  }

  const tableData = dataPointsArr.map(dataPoint => {
    const row: TableDataED = {};
    columnAttribues?.forEach((colAttr, index) => {
      row[colAttr.value] = dataPoint[index];
    });
    return row;
  });
  return tableData;
};

const attributesModalCreatorED = (
  attributes: AttributesPayloadED[]
): ColumnED[] => {
  return attributes.map(attribute => ({
    type: attribute.type,
    value: attribute.value,
    label: attribute.label,
    extraJSON: {
      fieldType: attribute.extra_json?.field_type,
      isHidden: attribute.extra_json?.is_hidden,
      addedBy: attribute.extra_json?.added_by
    }
  }));
};

const operationsModalCreatorED = (
  operations: OperationPayloadED[]
): OperationED[] => {
  return operations.map(operation => ({
    type: operation.type,
    label: operation.label,
    dataType: operation.data_type,
    function: operation.function,
    arguments: operationArgumentModalCreatorED(operation.arguments),
    extraArgs: {
      separator: operation.extra_args?.separator,
      compareValue: operation.extra_args?.compare_value
    },
    extraJSON: {
      outputDataType: operation.extra_json?.output_data_type,
      axisName: operation.extra_json?.axis_name,
      fieldType: operation.extra_json.field_type,
      tag: operation.extra_json.tag
    }
  }));
};

const operationArgumentModalCreatorED = (
  argumentsData: OperationPayloadED["arguments"]
) => {
  return argumentsData.map(argument => {
    if (typeof argument === "string") {
      return argument;
    }
    const newArgument: OperationArgumentED = {
      label: argument.label,
      dataType: argument.data_type,
      function: argument.function,
      arguments: operationArgumentModalCreatorED(argument.arguments),
      extraArgs: {
        separator: argument.extra_args?.separator,
        compareValue: argument.extra_args?.compare_value
      }
    };
    return newArgument;
  });
};

const groupByModalCreatorED = (groupBys: GroupByPayloadED[]): GroupByED[] => {
  return groupBys.map(groupBy => ({
    type: groupBy.type,
    source: groupBy.source,
    operations: groupBy.operations
      ? operationsModalCreatorED(groupBy?.operations)
      : undefined,
    extraJSON: {
      label: groupBy.extra_json?.label,
      fieldType: groupBy.extra_json?.field_type,
      dataType: groupBy.extra_json?.data_type,
      labelOutputType: groupBy.extra_json?.label_output_type,
      isForSecondaryXAxis: groupBy.extra_json?.is_for_secondary_x_axis,
      operationType: groupBy.extra_json?.operation_type,
      dataSources: groupBy.extra_json?.data_sources
    }
  }));
};

export const orderByModalCreatorED = (
  orderBys: OrderByPayloadED[]
): OrderByED[] => {
  return orderBys.map(orderBy => ({
    type: orderBy.type,
    ordering: orderBy.ordering,
    source: orderBy.source,
    operations: orderBy.operations
      ? operationsModalCreatorED(orderBy.operations)
      : undefined
  }));
};

export const otherConfigurationsModalCreatorED = (
  config: OtherChartConfigurationsPayloadED
): OtherChartConfigurationsED => {
  return {
    colors: {
      activeColorType: config.colors.active_color_type,
      singleTone: config.colors.single_tone,
      spectrum: config.colors.spectrum,
      custom: config.colors.custom
    },
    showLegend: config.show_legend,
    chartOrientation:
      config.chart_orientation ??
      defaultOtherConfigurationsORM.chartOrientation,
    shouldShowValueLabels: config.show_value_labels,
    showValueOn: config.show_value_on,
    showXAxisLabel: config.show_x_axis_label,
    showYAxisLabel: config.show_y_axis_label,
    yAxisLabel: config.y_axis_label,
    xAxisLabel: config.x_axis_label,
    valueLabelAngle: config.value_label_angle,
    isStackedBarChart: config.is_stacked_bar_chart,
    isGroupedBarChart: config.is_grouped_bar_chart,
    percentageThreshold: config.percentage_threshold,
    //TODO: PLAT-16404 remove second condition
    shouldShowPercentagesInValueLabels:
      config.show_percentages_in_value_labels ??
      config.show_percentages_per_slice,
    scale: config.scale,
    precision: config.precision,
    geoMapTheme: config.geo_map_theme
  };
};

export const filterModalCreatorED = (filter: FilterPayloadED): FilterED => {
  const conditions: (FilterED | FilterConditionED)[] = filter.conditions.map(
    condition => {
      if ("type" in condition) {
        return filterModalCreatorED(condition);
      }
      return {
        lhsType: condition.lhs_type,
        dataType: condition.data_type,
        lhsSource: condition.lhs_source,
        operation: condition.op,
        rhsValue: condition.rhs_value,
        extraJSON: {
          fieldType: condition.extra_json.field_type,
          fieldValue: condition.extra_json.field_value
        }
      };
    }
  );
  return {
    type: filter.type,
    conditions
  };
};
