import { useQuery, type QueryKey, type UseQueryOptions } from "react-query";
import { getGlobalSearchResults } from "../services/globalSearch.services";
import type { UseQueryReturnType } from "@certa/common";

export const QUERY_KEY_PREFIX = "globalSearch";

export const useGlobalSearch = (
  query: string,
  selectedCategories: string[],
  config?: UseQueryOptions<UseQueryReturnType<typeof getGlobalSearchResults>>
) => {
  // We sort categories to ensure that the query key is consistent for
  // the same categories in different order
  const sortedCategories = selectedCategories.sort();
  return useQuery({
    ...config,
    queryKey: [QUERY_KEY_PREFIX, ...sortedCategories, query] as QueryKey,
    queryFn: () => getGlobalSearchResults(query, selectedCategories)
  });
};
