/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, ClassNames } from "@emotion/core";
import type { FC } from "react";
import { Select as AntSelect } from "antd";
import type {
  SelectProps as AntdSelectProps,
  SelectValue as AntdSelectValue
} from "antd/lib/select";

export type SelectProps<T> = AntdSelectProps<T>;
export type SelectValue = AntdSelectValue;

const Select: FC<SelectProps<SelectValue>> = props => {
  const { dropdownClassName } = props;
  return (
    <ClassNames>
      {({ css, cx }) => (
        <AntSelect
          getPopupContainer={triggerNode =>
            triggerNode?.parentElement || document.body
          }
          dropdownClassName={cx(
            dropdownClassName,
            css`
              /* Popup styling */
              background: #ffffff;
              border: 1px solid rgba(0, 0, 0, 0.2);
              box-shadow:
                0px 4px 10px rgba(0, 0, 0, 0.05),
                0px 10px 24px rgba(0, 0, 0, 0.04);
              border-radius: var(--s1);

              /* Option Styling */
              .ant-select-item {
                min-height: var(--s8);
                font-size: 15px;
                font-weight: 600;
                line-height: var(--s5);
              }

              /* Selected Option and Hovered-over(active) option */
              .ant-select-item-option-active,
              .ant-select-item-option-selected:not(
                  .ant-select-item-option-disabled
                ) {
                background-color: #e0f6ff;
                color: var(--primary-color);
              }
            `
          )}
          className={css`
            /* Selected Item Label */
            .ant-select-selection-item {
              /**
               * Code below was added to cater for 'tags' and 'multi' mode
               * where the select field expands in height when certain number
               * of elements are selected. 
               * 
               * TODO: This could be done in more cleaner way. We just need
               * to make sure that all modes are working as they should
               * without visually breaking
               */
              ${props.mode ? "" : "line-height: var(--s8) !important;"}
            }

            /* Select Input */
            .ant-select-selector {
              border-radius: var(--normal-border-radius) !important;
              /**
               * TODO: Same as above
               */
              ${props.mode ? "" : "height: 36px !important;"}
              border: 1px solid #c1cbd3 !important;
              transition: border 0s !important;
            }

            input {
              height: 32px !important;
            }

            /* Placeholder */
            .ant-select-selection-placeholder {
              line-height: 36px !important;
              font-size: 15px;
              font-weight: 400;
            }

            /* Focussed State */
            &.ant-select-focused.ant-select-single {
              .ant-select-selector {
                border: 2px solid #138cd6 !important;
                box-shadow: none;
              }
            }
          `}
          {...props}
        />
      )}
    </ClassNames>
  );
};

const Option = AntSelect.Option;
const OptGroup = AntSelect.OptGroup;

export { Select, Option, OptGroup };
