import { css } from "emotion";
import type { FC } from "react";
import React from "react";
import { Stack, Text } from "@certa/blocks/thanos";
import type { SelectedProcessConfig } from "../hooks/useProcessFamily.hook";
import {
  workflowServices as processServices,
  useGetProcessFamily,
  useKinds
} from "@certa/queries";
import { ButtonSizes, ButtonTypes, Button } from "@certa/catalyst";
import { useQuery } from "react-query";
import { Loader } from "@certa/blocks";
import { useIntl } from "react-intl";
import { ProcessItem } from "./ProcessItem";
import { ChildProcessTypeList } from "./ChildProcessTypeList";
import { useCreateNewWorkflow } from "@certa/common";
import { Close } from "@certa/icons";

const { getWorkflowList: getProcessList } = processServices;

const Separator = () => (
  <hr
    className={css`
      height: var(--s00);
      margin: var(--s4) 0 var(--s2) 0;
      border: none;
      border-top: 1px solid var(--neutral-20);
    `}
  />
);

const FamilyContainer: FC<SelectedProcessConfig> = ({
  selectedProcessId,
  rootProcessId,
  onCancel,
  withoutHeader = false,
  isSelectedProcessMigrating
}) => {
  const intl = useIntl();
  const { data: kinds } = useKinds();
  const { onSelect } = useCreateNewWorkflow();
  /**
   * Get the complete hierarchy of the selected process
   * This includes:
   * - List of ids of the all the possible process types of the parent processes
   *   of current selected process
   * - List of all the possible process types of the child processes of
   *   current selected process
   */
  const { data: selectedProcessFamily, status: familyStatus } =
    useGetProcessFamily(selectedProcessId);

  const selectedProcessListParams = {
    id__in: rootProcessId
      ? `${selectedProcessId},${rootProcessId}`
      : selectedProcessId
  };
  /**
   * Get the details of the selected process and the root process
   * by using the id__in filter with process list api
   */
  const { data: selectedAndRootProcessData, status: selectedAndRootStatus } =
    useQuery(
      ["selected-and-root-process-data", selectedProcessListParams],
      () => getProcessList(selectedProcessListParams)
    );

  const parentProcessListParams = {
    children: selectedProcessId
  };

  const { data: parentProcessData, status: parentStatus } = useQuery(
    ["parent-process-list-data", parentProcessListParams],
    () => getProcessList(parentProcessListParams)
  );

  const selectedProcessData = selectedAndRootProcessData?.results?.find(
    process => process.id === selectedProcessId
  );

  if (
    familyStatus === "loading" ||
    selectedAndRootStatus === "loading" ||
    parentStatus === "loading"
  ) {
    return (
      <Stack
        align="center"
        justify="center"
        direction="vertical"
        className={css`
          height: 300px;
        `}
      >
        <Loader />
      </Stack>
    );
  }

  if (
    familyStatus === "error" ||
    selectedAndRootStatus === "error" ||
    parentStatus === "error"
  ) {
    return (
      <Stack
        align="center"
        justify="center"
        css={css`
          height: 300px;
        `}
      >
        <Text variant="h2-regular" color="red">
          {intl.formatMessage({
            id: "notificationInstances.somethingWentWrong",
            defaultMessage: "Something went wrong"
          })}
          .
        </Text>
      </Stack>
    );
  }

  const parentProcess = parentProcessData?.results[0];

  const handleCreateNewProcess = (wfKindId: number) => {
    const wfKind = kinds?.find(kind => kind.id === wfKindId);
    if (wfKind?.tag) {
      onSelect(wfKind.tag, undefined, selectedProcessId);
    }
  };

  return (
    <Stack gap="s4" direction="vertical">
      {!withoutHeader && (
        <>
          <Stack justify={"space-between"} align="center">
            {!!selectedProcessData && (
              <>
                <ProcessItem
                  process={selectedProcessData}
                  showOnlyProcessLabel={true}
                />
              </>
            )}
            <Button
              size={ButtonSizes.SMALL}
              type={ButtonTypes.ICON}
              rightIcon={<Close />}
              onClick={onCancel}
            />
          </Stack>

          <Separator />
        </>
      )}
      {parentProcess && (
        <Stack gap="s2" direction="vertical">
          <Text variant="p2-bold-upper" color="neutral-70">
            {intl.formatMessage({
              id: "familyContainer.parentProcess",
              defaultMessage: "PARENT PROCESS"
            })}
          </Text>
          <ProcessItem process={parentProcess} />
        </Stack>
      )}
      <ChildProcessTypeList
        processTypes={selectedProcessFamily?.childProcessTypes}
        selectedProcessId={selectedProcessId}
        handleCreateNewProcess={handleCreateNewProcess}
        isWorkflowBeingMigrated={!!isSelectedProcessMigrating}
      />
    </Stack>
  );
};

export { FamilyContainer };
