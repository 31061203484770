/** @jsx jsx */
/** @jsxRuntime classic */
import type { StackProps } from "@certa/blocks/thanos";
import { Stack, Text } from "@certa/blocks/thanos";
import type { TagSizes } from "@certa/catalyst";
import { TagVariants, Tag } from "@certa/catalyst";
import { jsx, css } from "@emotion/core";
import type { FC } from "react";

// TODO: Use this same component in the ReportsTable component
// TODO: Use this in Search page too @Umerbhat

/**
 * This component is deprecated and should not be used.
 * @example
 * // Preferred Usage
 * CustomLabelNew
 */
export const CustomLabel: FC<
  {
    label: string | number;
    width?: string;
    ellipsis?: string | number;
    color?: string;
    backgroundColor?: string;
  } & StackProps
> = ({
  label,
  width,
  ellipsis,
  color,
  backgroundColor = "var(--neutral-50)",
  ...stackProps
}) => {
  return (
    <Stack
      inline
      gutter="s1 s2"
      css={css`
        border-radius: 3px;
        cursor: pointer;
        background-color: ${backgroundColor};
      `}
      justify="center"
      align="center"
      {...stackProps}
    >
      <Text
        variant="p2-bold-upper"
        ellipsis={ellipsis}
        useMaxWidth={true}
        color={color}
      >
        {label}
      </Text>
    </Stack>
  );
};

export const CustomLabelNew: FC<{
  label: string | number;
  "aria-label"?: string;
  width?: string;
  ellipsis?: string | number;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  size?: TagSizes;
}> = ({
  label,
  ellipsis,
  color,
  backgroundColor = "var(--neutral-50)",
  onClick,
  "aria-label": ariaLabel,
  size
}) => {
  return (
    <Tag
      label={ariaLabel || String(label)}
      backgroundColor={backgroundColor}
      onClick={onClick}
      variant={TagVariants.FILLED}
      size={size}
      hasBorder={false}
    >
      <Text
        variant="p2-bold-upper"
        ellipsis={ellipsis}
        useMaxWidth={true}
        color={color}
      >
        {label}
      </Text>
    </Tag>
  );
};
