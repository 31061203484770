import {
  useQuery,
  useMutation,
  type UseQueryOptions,
  type QueryKey
} from "react-query";
import {
  getProcessFamilyHierarchy,
  linkProcess,
  replaceProcess,
  unlinkProcess
} from "../services/processFamily.services";
import type {
  UseMutationExtraConfigType,
  UseQueryReturnType
} from "@certa/common";

export const useGetProcessFamily = (
  processId?: number,
  config?: UseQueryOptions<UseQueryReturnType<typeof getProcessFamilyHierarchy>>
) =>
  useQuery({
    ...config,
    queryKey: ["process-family", processId] as QueryKey,
    queryFn: () => {
      if (!processId) return Promise.reject("Process ID must be a number!");
      return getProcessFamilyHierarchy(processId);
    }
  });

export const useLinkProcess = (
  sideEffectsConfig?: UseMutationExtraConfigType
) => useMutation(linkProcess, sideEffectsConfig);

export const useUnlinkProcess = (
  sideEffectsConfig?: UseMutationExtraConfigType
) => useMutation(unlinkProcess, sideEffectsConfig);

export const useReplaceLinkProcess = (
  sideEffectsConfig?: UseMutationExtraConfigType
) => useMutation(replaceProcess, sideEffectsConfig);
