import React from "react";
import { Stack } from "@certa/blocks/thanos";
import { Button, ButtonVariants } from "@certa/catalyst";
import { Close } from "@certa/icons";
import { iframePostMessage } from "../utils/postMessage";
import { useIntl } from "react-intl";

export const CloseIframe = () => {
  const intl = useIntl();
  const onCloseIframe = () => {
    // The wildcard "*" is used because document.location.ancestorOrigins
    // is not supported in firefox. Since there is no sensitive data in
    // the message, it is safe to use the wildcard.
    iframePostMessage({ text: "Iframe closed", id: "IFRAME_CLOSED" }, {}, "*");
  };
  return (
    <Stack
      justify="flex-end"
      style={{
        padding: "var(--s2) var(--s4)",
        background: "var(--neutral-10)"
      }}
    >
      <Button
        variant={ButtonVariants.FILLED}
        leftIcon={<Close />}
        onClick={onCloseIframe}
      >
        {intl.formatMessage({
          id: "iframe.closeButton",
          defaultMessage: "Exit"
        })}
      </Button>
    </Stack>
  );
};
