import React from "react";
import { ClassNames } from "@emotion/core";
import styled from "@emotion/styled";
import { DatePicker as AntdDatePicker } from "antd";
import type { DatePickerProps as AntDatePickerProps } from "antd/lib/date-picker";
import { getVariantStyles } from "../Typography/utils";
import { Close, Date } from "@certa/icons";
import { getLayoutContainer } from "../../utils";

// TODO: Support design Arrow icon

export type DatePickerProps = {
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement | null;
  allowClear: AntDatePickerProps["allowClear"];
  autoFocus: AntDatePickerProps["autoFocus"];
  className: AntDatePickerProps["className"];
  dateRender: AntDatePickerProps["dateRender"];
  disabled: AntDatePickerProps["disabled"];
  disabledDate: AntDatePickerProps["disabledDate"];
  inputReadOnly: AntDatePickerProps["inputReadOnly"];
  mode: AntDatePickerProps["mode"];
  open: AntDatePickerProps["open"];
  picker: "date";
  placeholder: AntDatePickerProps["placeholder"];
  onOpenChange: AntDatePickerProps["onOpenChange"];
  error: boolean;
  onChange: AntDatePickerProps["onChange"];
  value: AntDatePickerProps["value"];
};

function CustomDatePicker(props: DatePickerProps) {
  const { getPopupContainer, className, ...rest } = props;
  return (
    <ClassNames>
      {({ css }) => (
        <AntdDatePicker
          format={"YYYY-MM-DD"}
          getPopupContainer={triggerNode =>
            getPopupContainer?.(triggerNode) ||
            triggerNode ||
            getLayoutContainer()
          }
          suffixIcon={<Date />}
          clearIcon={<Close />}
          className={className}
          dropdownClassName={css`
            & * {
              font-family: "Inter", sans-serif, "system-ui";
            }

            .ant-picker-panel-container {
              /* Popup styling */
              background-color: var(--neutral-0);
              box-shadow: var(--popover-shadow);
              padding: var(--s00);
              border-radius: var(--s2);
              .ant-picker-date-panel {
                width: auto;
              }
            }
            .ant-picker-header {
              font-weight: bold;
              color: var(--neutral-100);
              & > button {
                // TODO: Latest antd wont need it, we can pass icon directly
                color: var(--brand-15);
                &:hover {
                  color: var(--brand);
                }
              }
              .ant-picker-header-view button:hover {
                color: var(--brand);
              }
            }

            .ant-picker-content {
              width: 265px;
              th {
                min-width: 40px;
                min-height: 40px;
                line-height: 40px;
                font-weight: bold;
              }
              .ant-picker-cell {
                min-width: auto;
                .ant-picker-cell-inner {
                  min-width: 40px;
                  min-height: 40px;
                  line-height: 40px;
                  border-radius: var(--s2);
                }
              }
            }

            .ant-picker-cell {
              color: var(--neutral-70);
              padding: 0;
              &:not(.ant-picker-cell-selected):hover {
                .ant-picker-cell-inner {
                  background-color: var(--brand-35) !important;
                }
              }
              &.ant-picker-cell-in-view {
                color: var(--neutral-100);
                &.ant-picker-cell-today {
                  .ant-picker-cell-inner:before {
                    border: 2px solid var(--brand-35);
                    border-radius: var(--s2);
                  }
                }
              }
              &.ant-picker-cell-selected {
                .ant-picker-cell-inner {
                  background-color: var(--brand);
                }
              }
            }
          `}
          {...rest}
        />
      )}
    </ClassNames>
  );
}

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { DatePicker } from '@certa/catalyst';
 */
export const DatePicker = styled(CustomDatePicker)`
  & * {
    font-family: "Inter", sans-serif, "system-ui";
  }

  &.ant-picker {
    border: none !important;
    outline: none;
    padding: 0 var(--s4);
    min-height: calc(var(--s10) + 2px) !important;
    border-radius: var(--s2);
    background-color: ${props =>
      props.error ? "var(--red-60)" : "var(--neutral-5)"};
    border: 1px solid
      ${props => (props.error ? "var(--red)" : "var(--neutral-5)")} !important;
    outline: none;
    transition: none;

    .ant-picker-input input,
    .ant-picker-suffix {
      ${getVariantStyles("h3-regular")}
      color: var(--neutral-100);
    }
    .ant-picker-suffix,
    .ant-picker-clear {
      display: flex;
      align-items: center;
    }
    .ant-picker-clear {
      background-color: ${props =>
        props.error ? "var(--red-60)" : "var(--neutral-5)"};
    }
  }
  &.ant-picker-focused {
    box-shadow: 0 0 0 2px
      ${props => (props.error ? "var(--red-40)" : "var(--brand-15)")};
  }
  /* Disabled state */
  &.ant-picker-disabled {
    color: var(--neutral-70);
    -webkit-text-fill-color: var(--neutral-70);
    background-color: var(--neutral-10);
    border: 1px solid var(--neutral-20) !important;
  }
`;
