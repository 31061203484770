import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
  CatalystColors,
  Drawer,
  DrawerHeader,
  DrawerPlacements,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemSizes,
  DropdownMenuItemTypes,
  DropdownMenuTrigger,
  Stack,
  Switch,
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst";
import {
  ErrorBoundary,
  getDrawerPositionInDesktop,
  getDrawerPositionInMobile,
  useScreenResolution,
  useSubscribeThreadToggle
} from "@certa/common";
import { ChevronDownLight, ChevronUpLight, Reduce } from "@certa/icons";
import { css } from "emotion";
import { useState } from "react";
import { useIntl } from "react-intl";
import {
  MixPanelActions,
  MixPanelEvents
} from "../../../../main/src/js/_helpers/mixpanel";
import { useMentionsContext } from "../MentionsContext";
import { notificationsToggleStyles } from "./NotificationsDrawer.styles";
import { MentionsContainer } from "./notificationsContainer/MentionsContainer";
import { SubscriptionsContainer } from "./notificationsContainer/SubscriptionsContainer";

type NotificationsDrawerProps = {
  handleOnClose: () => void;
};

type NotificationsDrawerMode = {
  id: string;
  label: string;
};

const NotificationDrawer = ({ handleOnClose }: NotificationsDrawerProps) => {
  const { show: shouldShow, mode, updateMode } = useMentionsContext();
  const canRenderSubscriptionTab = useSubscribeThreadToggle();
  const intl = useIntl();
  const [isNotificationsDropdownOpen, setIsNotificationsDropdownOpen] =
    useState(false);
  const [shouldShowUnreadOnly, setShouldShowUnreadOnly] = useState(false);

  const isNavbarExpanded = window.location.pathname === "/home";
  const { isMobileResolution } = useScreenResolution();
  const allNotifications = intl.formatMessage({
    id: "mentions.allNotificationsTab",
    defaultMessage: "All Notifications"
  });

  const subscriptions = intl.formatMessage({
    id: "mentions.mySubscriptionsTab",
    defaultMessage: "My Subscriptions"
  });

  const unreadText = intl.formatMessage({
    id: "mentions.unread",
    defaultMessage: "UNREAD"
  });

  const modes: NotificationsDrawerMode[] = [
    {
      id: "allNotifications",
      label: allNotifications
    },
    {
      id: "subscriptions",
      label: subscriptions
    }
  ];

  const handleModeChange = (mode: NotificationsDrawerMode) => {
    switch (mode.id) {
      case "allNotifications":
        MixPanelActions.track(
          MixPanelEvents.notificationEvents
            .NOTIFICATIONS_HEADER_CLICK_ALL_NOTIFICATIONS
        );
        updateMode("mentions");
        break;
      case "subscriptions":
        MixPanelActions.track(
          MixPanelEvents.notificationEvents
            .NOTIFICATIONS_HEADER_CLICK_MY_SUBSCRIPTIONS
        );
        updateMode("subscriptions");
        break;
      default:
        updateMode("mentions");
    }
  };

  return (
    <>
      <Drawer
        title=""
        aria-label={"Notifications drawer"}
        show={shouldShow}
        width={isMobileResolution ? "100%" : "28rem"}
        height={isMobileResolution ? "unset" : "100%"}
        onClose={handleOnClose}
        showCloseIcon={false}
        getPositioning={
          isMobileResolution
            ? getDrawerPositionInMobile
            : getDrawerPositionInDesktop
        }
        placement={DrawerPlacements.LEFT}
        canShowDimmer={false}
      >
        <DrawerHeader>
          <Stack
            direction="horizontal"
            justify="space-between"
            align="center"
            padding="20 24"
          >
            {canRenderSubscriptionTab ? (
              <DropdownMenu onOpenChange={setIsNotificationsDropdownOpen}>
                <DropdownMenuTrigger>
                  <Typography
                    as="button"
                    variant={TypographyVariants.BODY_BOLD}
                    color={TypographyColors.NEUTRAL_800}
                    className={notificationsToggleStyles}
                  >
                    {mode === "mentions" ? allNotifications : subscriptions}
                    {isNotificationsDropdownOpen ? (
                      <ChevronUpLight
                        size={16}
                        color={CatalystColors.BRAND_400}
                      />
                    ) : (
                      <ChevronDownLight
                        size={16}
                        color={CatalystColors.BRAND_400}
                      />
                    )}
                  </Typography>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" width="12rem">
                  {modes.map(mode => (
                    <DropdownMenuItem
                      key={mode.id}
                      value={mode.id}
                      onSelect={() => handleModeChange(mode)}
                      size={DropdownMenuItemSizes.BIG}
                      type={DropdownMenuItemTypes.DEFAULT}
                    >
                      <Typography variant={TypographyVariants.BODY}>
                        {mode.label}
                      </Typography>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <Typography
                variant={TypographyVariants.BODY_BOLD}
                color={TypographyColors.NEUTRAL_800}
              >
                {allNotifications}
              </Typography>
            )}
            <Stack direction="horizontal" gap="16" align="center">
              {mode === "mentions" && (
                <Stack direction="horizontal" gap="8" align="center">
                  <Switch
                    label="UNREAD"
                    checked={shouldShowUnreadOnly}
                    onChange={checked => {
                      if (checked) {
                        MixPanelActions.track(
                          MixPanelEvents.notificationEvents
                            .NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_TOGGLE_UNREAD_ON
                        );
                      } else {
                        MixPanelActions.track(
                          MixPanelEvents.notificationEvents
                            .NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_TOGGLE_UNREAD_OFF
                        );
                      }
                      setShouldShowUnreadOnly(checked);
                    }}
                  />
                  <Typography
                    variant={TypographyVariants.LABEL_SM}
                    color={TypographyColors.NEUTRAL_700}
                  >
                    {unreadText}
                  </Typography>
                </Stack>
              )}

              {!isMobileResolution && (
                <Button
                  size={ButtonSizes.SMALL}
                  type={ButtonTypes.ICON}
                  color={ButtonColors.BRAND}
                  leftIcon={<Reduce />}
                  aria-label={"collapse notifications drawer"}
                  onClick={handleOnClose}
                />
              )}
            </Stack>
          </Stack>
          <div
            className={css({
              borderBottom: "1px solid var(--neutral-20)"
            })}
          />
        </DrawerHeader>
        <ErrorBoundary>
          {mode === "mentions" ? (
            <MentionsContainer showUnreadOnly={shouldShowUnreadOnly} />
          ) : (
            <SubscriptionsContainer />
          )}
        </ErrorBoundary>
      </Drawer>
      {/* TODO: https://thevetted.atlassian.net/browse/PLAT-20709  */}
      {shouldShow && (
        <div
          onClick={handleOnClose}
          style={{
            position: "fixed",
            top: 0,
            left: isNavbarExpanded ? "230px" : "65px",
            width: isMobileResolution ? 0 : "100%",
            height: "100%",
            background: "rgba(0, 14, 51, 0.15)",
            zIndex: 100
          }}
        />
      )}
    </>
  );
};

export { NotificationDrawer };
