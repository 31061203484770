export const STUDIO_SETTINGS_KEY_V2 = "studio_settings_v2";

export enum StudioTheme {
  LIGHT = "light",
  DARK = "dark"
}

// This is not being used but keeping it here for future reference
export enum StudioVersion {
  CURRENT = "current",
  NEXT = "next"
}
