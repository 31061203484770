import { css } from "emotion";
import React, { useMemo } from "react";
import type { LCData as LCDataPropTypes } from "@certa/types";
import { LCDataFormat } from "@certa/types";
import { isValid } from "date-fns";
import { LCIcon, gmtDate, supportedFieldFormats } from "@certa/common";
import { FormattedTextInput } from "main/src/modules/common/components/FormattedTextInput";
import { Star } from "@certa/icons";
import {
  Typography,
  TypographyColors,
  TypographyVariants,
  Stack
} from "@certa/catalyst";

export const LCData = ({
  value,
  label,
  format = LCDataFormat.DEFAULT
}: LCDataPropTypes) => {
  const lcComponent = useMemo(() => {
    if (value === undefined || value === null) return null;

    let lcFormat = format;

    if (label === "D-U-N-S" && !format) lcFormat = LCDataFormat.DUNS;

    if (typeof value === "string" && value.includes("~") && !format)
      lcFormat = LCDataFormat.TAGS;

    switch (lcFormat.toLowerCase()) {
      case LCDataFormat.DATE:
        const timestamp = new Date(value as string);
        return (
          <LCSimpleText
            value={isValid(timestamp) ? gmtDate(value) : "Invalid Date"}
          />
        );

      case LCDataFormat.PID:
        return <LCSimpleText value={(value as string).toUpperCase()} />;

      case LCDataFormat.ICON:
        return <LCIcon value={value as string} type="tooltip" />;
      case LCDataFormat.DUNS:
        return (
          <Stack dangerouslySetClassName={dunsStyleOverride}>
            <FormattedTextInput
              format={supportedFieldFormats.duns}
              displayType="text"
              value={value}
            />
          </Stack>
        );
      case LCDataFormat.RATING:
        return <LCRating value={value as string} />;
      case LCDataFormat.TAGS:
        return <LCTags value={value as string} />;
      default:
        return <LCSimpleText value={value as string} />;
    }
  }, [format, value, label]);

  return lcComponent;
};

export const LCSimpleText = ({ value }: { value: string }) => {
  return (
    <Typography
      variant={TypographyVariants.BODY}
      color={TypographyColors.NEUTRAL_700}
      className={workBreakStyle}
    >
      {value}
    </Typography>
  );
};

const LCTags = React.memo(({ value }: { value: string }) => (
  <LCSimpleText value={value.split("~").join(", ")} />
));

const LCRating = ({ value }: { value: string }) => {
  return value && value !== "0" ? (
    <Stack
      padding="0 4 0 0"
      justify="flex-start"
      align="center"
      direction="horizontal"
    >
      <LCSimpleText value={value} />
      <Star color="orange" />
    </Stack>
  ) : null;
};

const workBreakStyle = css({
  wordBreak: "break-word"
});

const dunsStyleOverride = css({
  fontSize: "14px",
  color: "var(--neutral-700)"
});
