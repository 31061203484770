import { getValueFromCookie } from "../utils/request";
import { tenant } from "../../config";

export const authHeader = {
  get,
  post,
  requestOptions,
  tenant,
  prasaran
};

function prasaran() {
  return {
    "X-CSRFToken": getValueFromCookie("csrftoken"),
    "X-DTS-SCHEMA": tenant
  };
}

/**
 * @deprecated in favor of RequestHeaders.GET
 * @returns {{"X-DTS-SCHEMA": string}}
 */
function get() {
  return {
    "X-DTS-SCHEMA": tenant
  };
}

/**
 * @deprecated in favor of RequestHeaders.POST
 * @returns {{"X-CSRFToken": string, "X-DTS-SCHEMA": string, "Content-Type": string}}
 */
function post() {
  return {
    "Content-Type": "application/json",
    "X-CSRFToken": getValueFromCookie("csrftoken"),
    "X-DTS-SCHEMA": tenant
  };
}

function requestOptions(method) {
  return {
    method: method === "get" ? "GET" : "POST",
    headers: method === "get" ? get() : post(),
    credentials: "include",
    mode: "cors"
  };
}
