import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";

// Instance level config ENABLE_USER_IMPERSONATION
export const useEnableUserImpersonation = (): boolean => {
  return useSelector(
    (state: ReduxState) =>
      lodashGet(
        state,
        "config.feature_toggles.ENABLE_USER_IMPERSONATION",
        false
      ) && !sessionStorage.getItem("impersonateeId")
  );
};
