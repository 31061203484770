/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import { useMemo, Fragment } from "react";
import { get as lodashGet } from "lodash-es";
import { Stack as OldStack, Text } from "@certa/blocks/thanos";
import { useChannelContext, useCommentsContext } from "../../comments.context";
import { DEFAULT_INTEGRATION_TITLE_PATH } from "../../comments.constants";
import {
  removeHTMLTagsAndTruncate,
  useNotificationsNewUI,
  useScreenResolution
} from "@certa/common";
import {
  Ellipsis,
  Stack,
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst";
import {
  hierarchyItemStyles,
  separatorStyles
} from "./CommentsRedesigned.styles";
import { FieldGroup } from "@certa/icons";

const HierarchyBreadcrumbs: FC<{
  containerWidth: string;
  isReadOnly?: boolean;
}> = ({ containerWidth, isReadOnly = false }) => {
  const {
    onStepClick,
    extra: commentsExtra,
    header: processName,
    workflowFamily
  } = useCommentsContext();
  const channel = useChannelContext();
  const isNewNotificationUIEnabled = useNotificationsNewUI();
  const { isMobileResolution } = useScreenResolution();

  /**
   * Try to get path from the config first. If value can be derived from
   * path provided in config, mapped to the row data that we have, then we
   * use it. Otherwise we check if we the type matches one of the default paths
   * that we have defined in a constant.
   *
   * @returns {string} title of the integration row
   */
  const integrationTitle = useMemo(() => {
    const { fieldType, target } = channel;

    const defaultPath = DEFAULT_INTEGRATION_TITLE_PATH[String(fieldType)];

    // TODO: Do it without capturing extras, so it works independently
    // (like, this won't work if you open the channel from a deep-link)
    const configPath = lodashGet(
      commentsExtra,
      "integration_row_title_path",
      null
    );

    const titleFromConfigPath =
      configPath && lodashGet(target, configPath, null);
    const titleFromDefaultPath =
      defaultPath && lodashGet(target, defaultPath, null);

    return titleFromConfigPath || titleFromDefaultPath;
  }, [channel, commentsExtra]);

  const {
    stepGroupId: taskLaneId,
    stepGroupName: taskLaneName,
    stepId: taskId,
    stepName: taskName,
    fieldName,
    fieldId
  } = channel;

  const handleTaskClick = () =>
    isReadOnly === false &&
    taskLaneId &&
    taskId &&
    onStepClick(channel.workflowId, {
      groupId: taskLaneId,
      stepId: taskId,
      fieldId
    });

  const renderFamily =
    workflowFamily &&
    workflowFamily.map((item, index) => {
      const shouldRenderDivider = index !== workflowFamily.length - 1;
      return (
        <Fragment key={index}>
          <Text
            variant={shouldRenderDivider ? "p1-regular" : "p1-bold"}
            color={shouldRenderDivider ? "neutral-70" : "neutral-100"}
            className="hierarchy-item"
          >
            {item.name}
          </Text>
          {shouldRenderDivider && (
            <Fragment>
              <Text variant="p1-regular" color="brand-15" className="separator">
                /
              </Text>
            </Fragment>
          )}
        </Fragment>
      );
    });

  return isNewNotificationUIEnabled ? (
    <Stack direction="horizontal" align="center" gap="8" width="100%">
      {!(workflowFamily && workflowFamily.length) ? (
        <Fragment>
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_800}
            className={hierarchyItemStyles(isMobileResolution)}
          >
            <Ellipsis>{taskLaneName || ""}</Ellipsis>
          </Typography>
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_800}
            className={separatorStyles}
          >
            /
          </Typography>
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_800}
            className={hierarchyItemStyles(isMobileResolution)}
          >
            <Ellipsis>{taskName || ""}</Ellipsis>
          </Typography>
          {fieldName && (
            <Fragment>
              <Typography
                variant={TypographyVariants.LABEL_SM_BOLD}
                color={TypographyColors.NEUTRAL_800}
                className={separatorStyles}
              >
                /
              </Typography>
              <Stack
                direction="horizontal"
                align="center"
                justify="center"
                gap="4"
              >
                <FieldGroup size={12} />
                <Typography
                  variant={TypographyVariants.LABEL_SM_BOLD}
                  color={TypographyColors.NEUTRAL_800}
                  className={hierarchyItemStyles(isMobileResolution)}
                >
                  <Ellipsis>
                    {fieldName
                      ? removeHTMLTagsAndTruncate(fieldName)
                      : fieldName}
                  </Ellipsis>
                </Typography>
              </Stack>
            </Fragment>
          )}
          {integrationTitle && (
            <Fragment>
              <Typography
                variant={TypographyVariants.LABEL_SM_BOLD}
                color={TypographyColors.NEUTRAL_800}
                className={separatorStyles}
              >
                /
              </Typography>
              <Typography
                variant={TypographyVariants.LABEL_SM_BOLD}
                color={TypographyColors.NEUTRAL_800}
                className={hierarchyItemStyles(isMobileResolution)}
              >
                <Ellipsis>{integrationTitle}</Ellipsis>
              </Typography>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>{renderFamily}</Fragment>
      )}
    </Stack>
  ) : (
    <OldStack
      align="center"
      gap="s2"
      css={css`
        width: ${containerWidth};
        .hierarchy-item {
          max-width: min-content;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .separator {
          max-width: min-content;
        }
      `}
    >
      {!(workflowFamily && workflowFamily.length) ? (
        <Fragment>
          {processName && (
            <Fragment>
              <Text
                variant="p1-regular"
                color="neutral-70"
                className="hierarchy-item"
              >
                {processName}
              </Text>
              <Text variant="p1-regular" color="brand-15" className="separator">
                /
              </Text>
            </Fragment>
          )}
          <Text
            variant="p1-regular"
            color="neutral-70"
            className="hierarchy-item"
          >
            {taskLaneName}
          </Text>
          <Text variant="p1-regular" color="brand-15" className="separator">
            /
          </Text>
          <Text
            variant={`p1-${fieldName || integrationTitle ? "regular" : "bold"}`}
            onClick={handleTaskClick}
            css={{ cursor: "pointer" }}
            color={fieldName || integrationTitle ? "neutral-70" : "neutral-100"}
            className="hierarchy-item"
          >
            {taskName}
          </Text>
          {fieldName && (
            <Fragment>
              <Text variant="p1-regular" color="brand-15" className="separator">
                /
              </Text>
              <Text
                variant="p1-bold"
                color="neutral-100"
                className="hierarchy-item"
              >
                {fieldName ? fieldName.replace(/(<([^>]+)>)/gi, "") : fieldName}
              </Text>
            </Fragment>
          )}
          {integrationTitle && (
            <Fragment>
              <Text variant="p1-regular" color="brand-15" className="separator">
                /
              </Text>
              <Text
                variant="p1-bold"
                color="neutral-100"
                className="hierarchy-item"
              >
                {integrationTitle}
              </Text>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>{renderFamily}</Fragment>
      )}
    </OldStack>
  );
};

export { HierarchyBreadcrumbs };
