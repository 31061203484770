import { Popover } from "@certa/catalyst";
import { breadcrumbsContainer } from "./Breadcrumbs.styles";
import { Fragment } from "react";
import type { BreadcrumbsProps } from "./Breadcrumbs.types";
import { BreadcrumbItem } from "./BreadcrumbItem";
import { BreadcrumbsTooltip } from "./BreadcrumbsTooltip";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { parentToChildHierarchy: hierarchy, highlights } = props;
  const shouldShowTooltip = hierarchy.length > 1;

  // We don't show hierarchy at root level, only in tooltip
  const lastItem = hierarchy.findLast(() => true);

  const content = lastItem ? (
    <div className={breadcrumbsContainer}>
      <BreadcrumbItem
        text={lastItem?.name}
        highlights={highlights}
        style={{ flexGrow: 1 }}
      />
    </div>
  ) : (
    <></>
  );

  const handlePopoverClose = () => {
    MixPanelActions.track(
      MixPanelEvents.homepageEvents.HOME_SEARCH_TOOLTIP_SHOWN,
      {
        hierarchy: hierarchy.map(item => ({
          id: item.id,
          name: item.name
        }))
      }
    );
  };

  if (!shouldShowTooltip) {
    return content;
  } else {
    return (
      <Popover
        openInPortal={true}
        ariaDescription=""
        content={<BreadcrumbsTooltip parentToChildHierarchy={hierarchy} />}
        trigger="hover"
        onClose={handlePopoverClose}
      >
        {content}
      </Popover>
    );
  }
};
