import styled from "@emotion/styled";
import { css } from "emotion";

// Assuming 1rem = 16px;
export const Theme = styled.div`
  & {
    /* Spaces */
    --ratio: 1.5;
    --multiple: 4px;
    --s0: 1px;
    --s1: 4px;
    --s2: 8px;
    --s3: 12px;
    --s4: 16px;
    --s5: 20px;
    --s6: 24px;
    --s7: 28px;
    --s8: 32px;
    --s9: 36px;
    --s10: 40px;
    --s11: 44px;
    --s12: 48px;
    // TODO: Add more
    --s18: 64px;

    /* DEPRECATED */
    --h1: 1.75rem; // 28px
    --h2: 1.5rem; // 24px
    --h3: 1.25rem; // 20px
    --h4: 1.125rem; // 18px
    --p: 1rem; // 16px
    --subtitle: 0.875rem; // 14px
    /* DEPRECATED */

    /* Breakpoints */
    --min-width: 900px;

    // Border radius
    --normal-border-radius: 6px;

    /* DEPRECATED */
    --primary-color: #148cd6;
    --secondary-color: #f6f8f9;
    --white: white;
    /* DEPRECATED */

    /***************** Claudiu Design System ****************/
    /** Font Sizes and line height */
    --h1: 48px;
    --h1-line-height: 56px;

    --h2: 32px;
    --h2-line-height: 40px;

    --h3: 24px;
    --h3-line-height: 32px;

    --h4: 20px;
    --h4-line-height: 28px;

    --p1: 16px;
    --p1-line-height: 24px;

    --p2: 14px;
    --p2-line-height: 20px;

    --p3: 12px;
    --p3-line-height: 16px;

    /** Font Weights */
    --regular: 400;
    --medium: 500;
    --semi-bold: 600;
    --bold: 700;

    /** Colors */
    /** Neutral */
    --n-1000: #00192b;
    --n-800: #4e545f;
    --n-400: #747b87;
    --n-100: #a1a7b4;
    --n-80: #babec7;
    --n-50: #d2d6dd;
    --n-40: #dfe2e7;
    --n-30: #e6e8ec;
    --n-20: #f1f3f5;
    --n-10: #f7f8fa;
    --n-0: #ffffff;

    /** Blue */
    --b-1000: #0083e1;
    --b-800: #7ac8f7;
    --b-40: #d6edfb;
    --b-10: #e5f3fc;

    /** Light Blue */
    --lb-1000: #69d0f5;
    --lb-800: #96ddf6;
    --lb-40: #c9f1ff;
    --lb-10: #e3f8ff;

    /** Teal */
    --t-1000: #37c5ab;
    --t-800: #6cd9c5;
    --t-40: #c3efe5;
    --t-10: #e0faf5;

    /** Green */
    --g-1000: #37c93d;
    --g-800: #71d775;
    --g-40: #bdf1bf;
    --g-10: #e8fbea;

    /** Red */
    --r-1000: #e8384f;
    --r-800: #f88897;
    --r-40: #ffd7d9;
    --r-10: #ffecee;

    /** Orange */
    --o-1000: #fd612c;
    --o-800: #fb9775;
    --o-40: #ffdaca;
    --o-10: #fff1eb;

    /** Yellow */
    --y-1000: #fd9a00;
    --y-800: #fcba53;
    --y-40: #ffe4b9;
    --y-10: #fff3e0;

    /** Purple */
    --p-1000: #aa62e3;
    --p-800: #cd92fa;
    --p-40: #ebd1ff;
    --p-10: #f8f1ff;
    /***************** Claudiu Design System ****************/
  }

  /* Responsive Font Size */
  font-size: var(--p1) !important;
  line-height: var(--p1-line-height);
  color: var(--n-1000);
  --label: theme;
`;

export const NormalizerClass = css`
  /* TODO: This is more specific than blocks CSS */
  & blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  figure,
  p,
  pre,
  ul,
  ol {
    margin: 0;
  }

  a {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  * {
    font-family: "Source Sans Pro", sans-serif, "system-ui";
  }

  --label: normalizer;
`;
