/** @jsx jsx */
/** @jsxRuntime classic */
import { useGetTaskCount } from "@certa/queries";
import { jsx } from "@emotion/core";
import type { FC } from "react";
import { Badge, BadgeColors } from "@certa/catalyst";

// This will be count of number of steps assigned to a user across all workflow counts
export const AssignedTaskCount: FC<{ isActiveRoute: boolean }> = () => {
  const { data: myTaskCount } = useGetTaskCount();

  if (!myTaskCount)
    // null || undefined || 0
    return null;

  return (
    <Badge
      aria-label={`${myTaskCount} Tasks`}
      label={myTaskCount}
      color={BadgeColors.RED}
      role="status"
    />
  );
};
