import { get as lodashGet } from "lodash-es";

/**
 * Generator function to generate action creators
 *
 * Action Creators: https://redux.js.org/basics/actions#action-creators
 *
 * Approach: This approach make sure that
 * all the action creators have the same signature
 *
 * @param {string} type
 * @returns {function} ActionCreators
 */
export const makeActionCreator = type => payload => ({ type, payload });

/**
 * Converts a string(sentence) in any casing to `Title Case`
 * @param {string} str string in any casing
 * @returns {string} string in `Title Case`
 */
export const convertToTitleCase = str => {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

/**
 * Extracts my tasks from all step groups
 *
 */
export const getMyStepGroups = (stepGroups, myTasks, workflowId) => {
  if (myTasks[workflowId] && myTasks[workflowId].mySteps.length) {
    const { mySteps } = myTasks[workflowId];
    const myStepGroups = getMyGroup(stepGroups, mySteps);
    return myStepGroups;
  } else {
    return [];
  }
};

//Extract groups that has step assigned to me
/**
 * @deprecated
 * Moved to workflow.model.ts
 */
const getMyGroup = (allStepGroups, myStepTags) => {
  const myGroups = [];

  allStepGroups.forEach(group => {
    // Spreading this to avoid reference and state mutation errors
    const mySteps = getMySteps(group, myStepTags);
    if (mySteps.length > 0) {
      const newGroup = {
        ...group,
        steps: mySteps
      };
      myGroups.push(newGroup);
    }
  });
  return myGroups;
};

//Fill my groups with only the steps that are assigned to me
/**
 * @deprecated
 * Moved to workflow.model.ts
 */
const getMySteps = (group, myStepTags) => {
  const mysteps = group.steps.filter(step => {
    return myStepTags.includes(step.definitionTag);
  });
  return mysteps;
};

/**
 * Returns an array of stepgroups that includes only the steps that
 * are assigned to current user.
 * @param {object} stepGroups The step group object recieved from getStepGroups
 * @param {object} state Redux state
 */
export function getAssignedStepGroups(stepGroups, myTasks) {
  // Getting workflow id from url
  // TODO: pure functions should not access the window.location,
  // fix it in the later stage of refactoring
  const urlPathArray = window.location.pathname.split("/");

  // checking last two values of / splitted pathname array as pathname
  // can end with a "/" i.e /workflows/instances/43882/
  const workflowId = Number(
    urlPathArray[urlPathArray.length - 1] ||
      urlPathArray[urlPathArray.length - 2]
  );
  const results = getMyStepGroups(stepGroups.results, myTasks, workflowId);
  return { results };
}

export const checkAssignedGroupContainsCurrentStep = (
  assignedGroups,
  searchParams
) => {
  if (
    !searchParams ||
    !searchParams.group ||
    !searchParams.step ||
    !assignedGroups
  ) {
    return false;
  }

  const hasCurrentGroup = assignedGroups.find(group => {
    return group.id === Number(searchParams.group);
  });

  if (!hasCurrentGroup) {
    return false;
  }

  const hasSelectedStep = hasCurrentGroup.steps.find(step => {
    return step.id === Number(searchParams.step);
  });

  return !!hasSelectedStep;
};

export const bannerDetail = config => {
  const bannerMessage = lodashGet(
    config,
    "custom_ui_labels.banner.message",
    ""
  );
  const bannerTrigger = lodashGet(
    config,
    "custom_ui_labels.banner.trigger",
    "off"
  );

  const bannerColor = lodashGet(config, "custom_ui_labels.banner.color", null);

  return {
    bannerMessage,
    bannerTrigger,
    bannerColor
  };
};

export const checkBannerVisiblity = config => {
  const { bannerMessage, bannerTrigger } = bannerDetail(config);
  const isBannerVisible = bannerMessage && bannerTrigger === "on";

  return isBannerVisible;
};

// source: https://stackoverflow.com/a/68274426
export const getColorByBgColor = (
  bgColor,
  lightColor = "neutral-0",
  darkColor = "neutral-100"
) => {
  if (!bgColor) {
    return lightColor;
  }
  const getLuminance = function (hexColor) {
    const color =
      hexColor.charAt(0) === "#" ? hexColor.substring(1, 7) : hexColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map(col =>
      col <= 0.03928 ? col / 12.92 : ((col + 0.055) / 1.055) ** 2.4
    );

    return 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  };

  const L = getLuminance(bgColor);
  const L1 = getLuminance("#ffffff"); // neutral-0
  const L2 = getLuminance("#00164e"); // neutral-100

  return L > Math.sqrt((L1 + 0.05) * (L2 + 0.05)) - 0.05
    ? darkColor
    : lightColor;
};

const hexRegex = /[0-9a-f]{6}|[0-9a-f]{3}/gi;

export const getHEXCodeFromString = (code, defaultString = "") =>
  code && code?.match(hexRegex) ? `#${code}` : defaultString;
