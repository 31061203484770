import { css } from "emotion";
import type { FC } from "react";
import React, { useMemo } from "react";
import { Text, CountBadge, Button } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";
import { Table } from "antd";
import { ChildProcessList } from "./ChildProcessList";
import { ChevronDown, Plus } from "@certa/icons";
import { getVariantStyles } from "@certa/blocks/src/componentsTh/Typography/utils";
import type { ProcessTypes } from "../../constants";
import { useCreatableWorkflowKind, useKindFromId } from "@certa/queries";
import {
  Button as CatalystButton,
  ButtonSizes,
  ButtonTypes
} from "@certa/catalyst";
import { WrapWithMigrationTooltip } from "@certa/common/src/components/WrapWithMigrationTooltip";

const tableStyles = css`
  //--For border and manging fonts of the table.--//
  border: 1px solid var(--neutral-20);
  border-radius: var(--normal-border-radius);
  background-color: var(--neutral-5);

  thead .ant-table-cell {
    padding: var(--s0) var(--s00) !important;
    ${getVariantStyles("p2-medium")}
    color: var(--neutral-70);
  }
  //-----------------------------------------------//

  //--For managing the border radius of the table and tablecells--//
  .ant-table-tbody > tr:last-child.ant-table-row:hover > td:first-child {
    border-bottom-left-radius: var(--normal-border-radius);
  }
  .ant-table-tbody > tr:last-child.ant-table-row:hover > td:last-child {
    border-bottom-right-radius: var(--normal-border-radius);
  }
  .ant-table-expanded-row:hover > .ant-table-cell,
  .ant-table {
    border-radius: var(--normal-border-radius);
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: var(--normal-border-radius);
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: var(--normal-border-radius);
  }
  //----------------------------------------------------------------//

  //--For managing the background color of different components of table.--//
  .ant-table-row-level-0 {
    & .create-new-button {
      visibility: hidden !important;
    }
    :hover {
      & > td {
        background-color: var(--teal-60) !important;
      }
      & .process-name {
        color: var(--brand) !important;
      }
      & .create-new-button {
        visibility: visible !important;
      }
    }
  }
  tr.ant-table-expanded-row > td {
    background-color: transparent;
  }
  //-------------------------------------------------------------------------//

  //--For managing the border and padding of the tablecells--//
  .ant-table-tbody > tr:last-child > td {
    border-bottom: var(--s00);
  }

  .new-ui-table-null-state {
    margin-top: var(--n-s6);
    margin-right: var(--s6);
  }
  .ant-table-row-expand-icon-cell {
    padding-right: var(--s00) !important;
    padding-left: var(--s6) !important;
  }
  th,
  td,
  .ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
    padding-left: 0 !important;
  }
  //----------------------------------------------------------//
`;

const CreateNewButton: FC<{
  onClick: () => void;
  id: number;
  disabled?: boolean;
}> = ({ onClick, id, disabled }) => {
  const intl = useIntl();
  const kind = useKindFromId(id);
  const hasCreatePermission = useCreatableWorkflowKind(kind?.tag || "", true);

  return hasCreatePermission ? (
    <Button
      onClick={onClick}
      size="small"
      type="ghost"
      icon={<Plus />}
      className="create-new-button"
      disabled={!!disabled}
    >
      {intl.formatMessage({
        id: "processFamily.createNew",
        defaultMessage: "Create New ..."
      })}
    </Button>
  ) : null;
};

export function CreateNewButtonComponent({
  isWorkflowBeingMigrated,
  handleCreateNewProcess,
  id
}: {
  isWorkflowBeingMigrated: boolean;
  handleCreateNewProcess: (wfKindId: number) => void;
  id: number;
}): JSX.Element {
  return (
    <WrapWithMigrationTooltip show={isWorkflowBeingMigrated}>
      <CreateNewButton
        onClick={() => {
          handleCreateNewProcess(id);
        }}
        id={id}
        disabled={isWorkflowBeingMigrated}
      />
    </WrapWithMigrationTooltip>
  );
}

const ChildProcessTable: FC<{
  processTypes: ProcessTypes | undefined;
  selectedProcessId: number;
  handleCreateNewProcess: (wfKindId: number) => void;
  isWorkflowBeingMigrated: boolean;
}> = ({
  processTypes,
  selectedProcessId,
  handleCreateNewProcess,
  isWorkflowBeingMigrated
}) => {
  const intl = useIntl();
  const columns = useMemo(() => {
    return [
      {
        title: intl.formatMessage({
          id: "familyContainer.childProcessTable.processType",
          defaultMessage: `PROCESS TYPE`
        }),
        path: "name",
        width: "50%",
        render: ({ name }: ProcessTypes[0]) => {
          return (
            <Text
              variant="p1-bold"
              color="neutral-100"
              className="process-name"
              useMaxWidth
              ellipsis={"40ch"}
            >
              {name}
            </Text>
          );
        }
      },
      {
        title: intl.formatMessage({
          id: "familyContainer.childProcessTable.#ofChildren",
          defaultMessage: `# OF CHILDREN`
        }),
        path: "processCount",
        sorter: (a: ProcessTypes[0], b: ProcessTypes[0]) =>
          a.processCount - b.processCount,
        render: ({ processCount }: ProcessTypes[0]) => {
          return <CountBadge count={processCount} />;
        }
      },
      {
        title: ``,
        path: "id",
        render: ({ id }: ProcessTypes[0]) => (
          <CreateNewButtonComponent
            isWorkflowBeingMigrated={isWorkflowBeingMigrated}
            handleCreateNewProcess={handleCreateNewProcess}
            id={id}
          />
        )
      }
    ];
  }, [handleCreateNewProcess, isWorkflowBeingMigrated, intl]);
  return (
    <Table
      className={tableStyles}
      dataSource={processTypes}
      size="small"
      pagination={false}
      rowKey={row => row.id}
      columns={columns}
      expandable={{
        expandIcon: ({ expanded, onExpand, record }) => (
          <div style={{ marginRight: "var(--s2)" }}>
            <CatalystButton
              type={ButtonTypes.ICON}
              size={ButtonSizes.SMALL}
              rightIcon={
                <ChevronDown
                  style={{
                    transform: `rotate(${expanded ? 180 : 0}deg)`
                  }}
                />
              }
              onClick={e => onExpand(record, e)}
              data-testid="chevronIcon"
            />
          </div>
        ),
        expandedRowRender: record => {
          return (
            <ChildProcessList
              processTypeId={record.id}
              selectedProcessId={selectedProcessId}
            />
          );
        }
      }}
    />
  );
};

export default ChildProcessTable;
