import type {
  UseMutationExtraConfigType,
  UseInfiniteQueryExtraConfigType,
  UseQueryReturnType
} from "@certa/common";
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  type UseQueryOptions,
  type QueryKey
} from "react-query";
import {
  getUnReadMentionCount,
  getUserMentions,
  markMentionAsRead
} from "../services/mentions.services";

export const useGetMentionsList = (config?: UseInfiniteQueryExtraConfigType) =>
  useInfiniteQuery(
    "user-mention-list",
    ({ pageParam = 0 }) => getUserMentions(pageParam),
    {
      getNextPageParam: lastPage => {
        if (lastPage.next) {
          const url = new URL(lastPage.next);
          const urlParams = new URLSearchParams(url.search);
          return urlParams.get("page");
        }
        return false;
      },
      ...config
    }
  );

export const useGetUnReadMentionCount = (
  config?: UseQueryOptions<UseQueryReturnType<typeof getUnReadMentionCount>>
) =>
  useQuery({
    refetchOnWindowFocus: false,
    ...config,
    queryKey: "unRead-mention-count" as QueryKey,
    queryFn: getUnReadMentionCount
  });

export const useMarkMentionRead = (config?: UseMutationExtraConfigType) =>
  useMutation(markMentionAsRead, config);
