import { workflowDetailsConstants } from "../constants";

export function myTasks(state = {}, action) {
  switch (action.type) {
    //my tasks
    case workflowDetailsConstants.GET_MY_TASK_REQUEST:
      return state;
    case workflowDetailsConstants.GET_MY_TASK_SUCCESS:
      return {
        ...state,
        ...action.myTasks,
        loading: false,
        error: null
      };
    case workflowDetailsConstants.GET_MY_TASK_FAILURE:
      return {
        loading: false,
        error: action.error,
        isActive: false
      };

    //Update my task with my stepgroup data
    case workflowDetailsConstants.UPDATE_MY_STEPGROUP_DATA:
      const mySteps = (state[action.payload.workflowId] || {}).mySteps;
      const myTaskData = {
        mySteps,
        myStepGroups: action.payload.stepGroups
      };

      return { ...state, [action.payload.workflowId]: myTaskData };

    //Toggle my task
    case workflowDetailsConstants.TOGGLE_MY_TASK:
      return { ...state, isActive: action.payload };

    default:
      return state;
  }
}
