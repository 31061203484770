import type { VFC } from "react";
import type { AlertProps } from "./types";
import type { IconProps } from "@certa/icons";
import { Exclamation, Info, Warning, Check } from "@certa/icons";
import type { Colors } from "../../types";

export const getConfig = (
  type: AlertProps["type"]
): { icon: VFC<IconProps>; color: Colors } => {
  switch (type) {
    case "info":
      return {
        icon: Info,
        color: "teal"
      };

    case "success":
      return {
        icon: Check,
        color: "green"
      };

    case "warning":
      return {
        icon: Warning,
        color: "orange"
      };

    case "error":
      return {
        icon: Exclamation,
        color: "red"
      };

    default:
      return {
        icon: Info,
        color: "teal"
      };
  }
};
