import { Stack } from "@certa/blocks";
import { Text } from "@certa/blocks/thanos";
import { ButtonVariants, Button } from "@certa/catalyst";
import {
  EPHEMERAL_TOKEN_QUERY_PARAM,
  USER_INTERACTION_REQUIRED_PATH
} from "@certa/common";
import { useLocation } from "react-router-dom";

const StoragePermissionRequiredInstructions = ({
  askForPermission
}: {
  askForPermission: () => void;
}) => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const ephemeralToken = urlParams.get(EPHEMERAL_TOKEN_QUERY_PARAM);

  return (
    <Stack
      style={{ width: "100%", height: "100vh" }}
      align="center"
      justify="center"
    >
      <Stack>
        <Text variant="h2-regular">
          Please follow below mentioned instructions:{" "}
        </Text>
        <li>
          <a
            href={`${window.origin}${USER_INTERACTION_REQUIRED_PATH}?${EPHEMERAL_TOKEN_QUERY_PARAM}=${ephemeralToken}`}
            target="_blank"
            rel="noreferrer"
          >
            Click here
          </a>{" "}
          which will redirect you to new page
        </li>
        <li>Close that page by clicking on 'Close this page'</li>
        <li>
          After you've closed that page, please follow the instructions on the
          button below
        </li>
      </Stack>
      <Button onClick={askForPermission} variant={ButtonVariants.FILLED}>
        <Stack dir="column">
          <span>Please CLICK HERE and then</span>
          <span>
            Click 'ALLOW' in your browser if a pop-up appears to load the screen
          </span>
        </Stack>
      </Button>
    </Stack>
  );
};

export { StoragePermissionRequiredInstructions };
