type CustomDotProps = {
  cx?: number;
  cy?: number;
  r: number;
  points?: string;
  stroke?: string;
  strokeWidth: number;
  className?: string;
  fill?: string;
  style:
    | {
        cursor: string;
      }
    | {};
  onClick?: () => void;
  showValueOn?: string;
};

const DOT_OPACITY = 1;

export const CustomDot = (props: CustomDotProps) => {
  const {
    cx,
    cy,
    r,
    points,
    stroke,
    strokeWidth,
    className,
    fill,
    style,
    onClick
  } = props;

  if (!cx || !cy) {
    return null;
  }

  return (
    <circle
      cx={cx}
      cy={cy}
      points={points}
      stroke={stroke}
      className={className}
      r={r}
      strokeWidth={strokeWidth}
      fill={fill}
      fillOpacity={DOT_OPACITY}
      style={style}
      onClick={onClick}
    />
  );
};
