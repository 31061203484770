import React from "react";
import type { EdgeProps } from "react-flow-renderer";
import { getSmoothStepPath } from "react-flow-renderer";
import { MarkerDefinition } from "./MarkersDef";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data
}: EdgeProps) {
  const edgePath = getSmoothStepPath({
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    borderRadius: 10
  });

  return (
    <g>
      <MarkerDefinition
        color={style.stroke as string}
        id={"custom_arrow" + id}
      />
      {/* This is dummy path to cause more target area on hover */}
      <path
        style={{ cursor: "pointer" }}
        strokeWidth={10}
        fill="none"
        d={edgePath}
        onMouseLeave={() => {
          data?.resetSelectedElements?.();
        }}
        onMouseOut={() => {
          data?.resetSelectedElements?.();
        }}
      />
      <path
        id={id}
        style={{ ...style, pointerEvents: "none" }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={`url(#custom_arrow${id})`}
      />
    </g>
  );
}
