import React from "react";
import { cx, css } from "emotion";
import type { StackProps } from "./Stack";
import { Stack } from "./Stack";

export type PaperProps = StackProps & {};

export const Paper = React.forwardRef<HTMLDivElement, PaperProps>(
  ({ children, className, ...otherProps }, ref) => {
    return (
      <Stack
        ref={ref}
        className={cx(
          css`
            background-color: #fff;
            border: 1px solid var(--neutral-20);
            border-radius: var(--s2);
          `,
          className
        )}
        {...otherProps}
      >
        {children}
      </Stack>
    );
  }
);
