export const stepBody = {
  GET_STEP_USERS_LOADING: "GET_STEP_USERS_LOADING",
  GET_STEP_USERS_SUCCESS: "GET_STEP_USERS_SUCCESS",
  GET_STEP_USERS_FAILURE: "GET_STEP_USERS_FAILURE",
  POST_STEP_USER_FAILURE: "POST_STEP_USER_FAILURE",
  POST_STEP_USER_LOADING: "POST_STEP_USER_LOADING",
  POST_STEP_USER_SUCCESS: "POST_STEPUSER_SUCCESS",
  POST_ALL_STEPS_USER_SUCCESS: "POST_ALL_STEPS_USER_SUCCESS",
  DELETE_STEP_USER_LOADING: "DELETE_STEP_USER_LOADING",
  DELETE_STEP_USER_SUCCESS: "DELETE_STEP_USER_SUCCESS",
  DELETE_STEP_USER_FAILURE: "DELETE_STEP_USER_FAILURE",
  GET_ASSIGNED_USER_LOADING: "GET_ASSIGNED_USER_LOADING",
  GET_ASSIGNED_USER_SUCCESS: "GET_ASSIGNED_USER_SUCCESS",
  GET_ASSIGNED_USER_FAILURE: "GET_ASSIGNED_USER_FAILURE"
};
