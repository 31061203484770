import { MODULE_NAME } from "./constants";

export const GET_STATUSES_LIST = `${MODULE_NAME}_GET_STATUSES_LIST`;
export const GET_STATUSES_LIST_SUCCESS = `${MODULE_NAME}_GET_STATUSES_LIST_SUCCESS`;
export const GET_STATUSES_LIST_FAILURE = `${MODULE_NAME}_GET_STATUSES_LIST_FAILURE`;

export const GET_ADVANCED_FILTER_DATA = `${MODULE_NAME}_GET_ADVANCED_FILTER_DATA`;
export const GET_ADVANCED_FILTER_DATA_SUCCESS = `${MODULE_NAME}_GET_ADVANCED_FILTER_DATA_SUCCESS`;
export const GET_ADVANCED_FILTER_DATA_FAILURE = `${MODULE_NAME}_GET_ADVANCED_FILTER_DATA_FAILURE`;

export const GET_ALL_KINDS = `${MODULE_NAME}_GET_ALL_KINDS`;
export const GET_ALL_KINDS_SUCCESS = `${MODULE_NAME}_GET_ALL_KINDS_SUCCESS`;
export const GET_ALL_KINDS_FAILURE = `${MODULE_NAME}_GET_ALL_KINDS_FAILURE`;

export const GET_ALL_ALERTS = `${MODULE_NAME}_GET_ALL_ALERTS`;
export const GET_ALL_ALERTS_SUCCESS = `${MODULE_NAME}_GET_ALL_ALERTS_SUCCESS`;
export const GET_ALL_ALERTS_FAILURE = `${MODULE_NAME}_GET_ALL_ALERTS_FAILURE`;

export const GET_ALL_TASK_QUEUES = `${MODULE_NAME}_GET_ALL_TASK_QUEUES`;
export const GET_ALL_TASK_QUEUES_SUCCESS = `${MODULE_NAME}_GET_ALL_TASK_QUEUES_SUCCESS`;
export const GET_ALL_TASK_QUEUES_FAILURE = `${MODULE_NAME}_GET_ALL_TASK_QUEUES_FAILURE`;

export const SET_WORKFLOW_FILTER = `${MODULE_NAME}_SET_WORKFLOW_FILTER`;
export const SET_MULTIPLE_WORKFLOW_FILTERS = `${MODULE_NAME}_SET_MULTIPLE_WORKFLOW_FILTERS`;
export const REMOVE_WORKFLOW_FILTERS = `${MODULE_NAME}_REMOVE_WORKFLOW_FILTERS`;
export const RESET_WORKFLOW_FILTERS = `${MODULE_NAME}_RESET_WORKFLOW_FILTERS`;

export const GET_WORKFLOW_LIST = `${MODULE_NAME}_GET_WORKFLOW_LIST`;
export const GET_WORKFLOW_LIST_SUCCESS = `${MODULE_NAME}_GET_WORKFLOW_LIST_SUCCESS`;
export const GET_WORKFLOW_LIST_FAILURE = `${MODULE_NAME}_GET_WORKFLOW_LIST_FAILURE`;

export const CREATE_WORKFLOW = `${MODULE_NAME}_CREATE_WORKFLOW`;
export const CREATE_WORKFLOW_SUCCESS = `${MODULE_NAME}_CREATE_WORKFLOW_SUCCESS`;
export const CREATE_WORKFLOW_FAILURE = `${MODULE_NAME}_CREATE_WORKFLOW_FAILURE`;

export const SET_CURRENT_DASHBOARD_VIEW = `${MODULE_NAME}_SET_CURRENT_DASHBOARD_VIEW`;
