import type { CommentMention } from "@certa/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import {
  getUserMentionTag,
  userMentionRegEx,
  sanitizeHTML,
  convertURLToAnchor
} from "@certa/common";

/**
 * TODO: Move this to @certa/common
 */
export const useCommentMessageFormat = (
  message: string,
  mentions: CommentMention[],
  useNewMention?: boolean
) => {
  const userId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );

  const finalMessage = useMemo(() => {
    let transformedMessage = message;

    // Go through each of the mentions, and find them in message,
    // while wrapping them in a tag to make it look highlighted.
    for (const mention of mentions) {
      const { display, id } = mention;
      if (transformedMessage.indexOf("(" + id + ")") === -1) continue;
      // We only look for the ID and ignore the name that was originally
      // in the message because we map it with the current name that we
      // get in mentions from the API
      const lookForRegEx = new RegExp(`~\\[[^\\]]*\\]\\(${id}\\)`, "gm");

      const tag = useNewMention
        ? getUserMentionTag(`${userId}` === id.slice(1), display)
        : `<span class="mentions">@${display}</span>`;

      // Now we replace all occurrences of that in the message string.
      transformedMessage = transformedMessage.replace(lookForRegEx, tag);

      // To make it look from the very beginning, every single time.
      userMentionRegEx.lastIndex = 0;

      // If we don't have any more @ mentions that are not already
      // taken care of then we can quit the loop.
      // The reason it is not at the top is because it's a regex check
      // which is a slightly expensive operation to run multiple times
      // so this only gets executed when we already have a match and we're checking
      // if there are any more tags left, only then the iterator will continue.
      if (!userMentionRegEx.test(transformedMessage)) break;
    }

    // Replace left out mentions in cases like user was removed later after
    // the comment was posted.
    // The reason we don't do this up there is because username or group-name
    // may have changed, so we display the one that we get in mentions within
    // API. But in this case, since the user/group doesn't exist in current
    // context, we display the one that was added initially in the comment.

    transformedMessage = transformedMessage
      .replace(userMentionRegEx, "<strong><em>@$1</em></strong>")
      .replace(/\n/g, "<br />"); // new lines should be treated as breaks

    // Convert links in message to anchor so that user can click and open in new tab easily
    const messageWithAnchor = convertURLToAnchor(transformedMessage);

    return messageWithAnchor;
  }, [message, mentions, useNewMention, userId]);
  return sanitizeHTML(finalMessage);
};
