import type { FC } from "react";

export const EventLogIllustration: FC = () => {
  return (
    <svg
      width="101"
      height="110"
      viewBox="0 0 101 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.0154 5.30101C52.41 3.79674 49.1999 3.79673 46.5944 5.30101L9.86939 26.5042C7.26391 28.0085 5.65887 30.7885 5.65887 33.797V76.2034C5.65887 79.2119 7.26391 81.992 9.86939 83.4962L46.5944 104.699C49.1999 106.204 52.4099 106.204 55.0154 104.699L91.7404 83.4962C94.3459 81.992 95.9509 79.212 95.9509 76.2034V33.797C95.9509 30.7885 94.3459 28.0085 91.7404 26.5042L55.0154 5.30101Z"
        fill="#EEEFEF"
        stroke="#F8F9F9"
        stroke-width="5.61404"
      />
      <path
        d="M47.1757 72.6175C48.1268 75.289 52.3511 75.1667 53.109 72.4472L59.9741 47.8845L63.986 58.1646C64.4253 59.2821 65.5936 60.0286 66.9019 60.0286H78.0251C79.7292 60.0286 81.1148 58.7758 81.1148 57.2348C81.1148 55.6939 79.7292 54.4411 78.0251 54.4411H69.0889L62.4024 37.3075C61.3837 34.7015 57.2415 34.8717 56.4933 37.552L49.7538 61.6476L43.8881 45.2035C42.9998 42.7109 39.1521 42.5931 38.0804 45.0202L33.914 54.4404H23.6454C21.9412 54.4404 20.5557 55.6932 20.5557 57.2342C20.5557 58.7751 21.9412 60.0279 23.6454 60.0279H36.0044C37.2692 60.0279 38.4038 59.3339 38.8721 58.2731L40.6342 54.2876L47.1757 72.6175Z"
        fill="#8892AC"
      />
    </svg>
  );
};
