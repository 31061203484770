import { APIFetch, APIFetchV2, RequestHeaders } from "@certa/network";
import type { SelectOption } from "@certa/types";
import { getUserLanguage } from "main/src/js/components/common/intlLanguages";
import {
  searchFileResultsModelCreator,
  searchResultsModelCreator,
  searchStatusListModelCreator
} from "../models/search.model";

export const getBusinessUnits = (region?: string) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  const url = region
    ? `business-unit/extra-data/?regions__code=${region}`
    : "fields/export-business-json/";

  return APIFetch(url, requestOptions).then(
    (response: any): SelectOption[] =>
      response?.results?.sort((a: SelectOption, b: SelectOption) =>
        a.label
          .toUpperCase()
          .localeCompare(b.label.toUpperCase(), getUserLanguage())
      ) || []
  );
};

export const getRegionsList = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  const url = "fields/export-region-json/";

  return APIFetch(url, requestOptions).then(
    (response: any): SelectOption[] =>
      response?.results?.sort((a: SelectOption, b: SelectOption) =>
        a.label > b.label ? 1 : -1
      ) || []
  );
};

export const getStatusListForSearch = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `available-workflow-statuses/`;

  return APIFetchV2(url, requestOptions).then(searchStatusListModelCreator);
};

export const getSearchResults = (
  urlParams: string,
  page: number,
  config: Omit<RequestInit, "method" | "headers" | "body">
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include",
    ...config
  };
  const url = `workflows/${urlParams ? `${urlParams}&` : "?"}page=${page}`;
  return APIFetchV2(url, requestOptions).then(searchResultsModelCreator);
};

export const getSearchFileResults = (
  searchText: string,
  page: number,
  config: Omit<RequestInit, "method" | "headers" | "body">
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include",
    ...config
  };

  const url = `response/attachment-search/?q=${searchText}&page=${page}`;

  return APIFetchV2(url, requestOptions).then(searchFileResultsModelCreator);
};
