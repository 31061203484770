import { useEffect, useState } from "react";
import { WorkflowLevelPermissionType } from "@certa/queries/src/types/permissions.types";
import { workflowServices } from "../services/workflow.services";
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  type UseQueryOptions,
  type QueryKey
} from "react-query";
import { notification } from "@certa/blocks/thanos";
import { useHistory } from "react-router-dom";
import type {
  ReportOrdering,
  WorkflowFiltersQuery,
  RelatedKindType,
  WorkflowAccessQuery,
  GetAdvancedFilterDataORMType,
  StepGroupsData,
  StepUserTags
} from "../types/workflow.types";
import type {
  UseInfiniteQueryExtraConfigType,
  UseMutationExtraConfigType,
  UseQueryExtraConfigType,
  UseQueryReturnType
} from "@certa/common";
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGINATION_SIZE,
  EMAIL_DELIVERY_REPORT,
  INTEGRATION_CALL_REPORT,
  useCheckLevelPermission,
  WORKFLOW_REPORT
} from "@certa/common";
import type {
  ColumnED,
  ColumnIC,
  FilterED,
  FilterIC,
  WorkflowDynamicAPIResultType
} from "@certa/types";
import { upsertWorkflow } from "../services/external.services";
import { payloadCreatorED } from "../models/emailDeliveryReportPayload.model";
import { columnsPayloadCreatorIC } from "../models/integrationCallReportPayload.model";
import { getWorkflowUniqKey } from "../utils/workflow.utils";
import { queryClient } from "../utils/utils";
import { type FieldHierarchyReturnType } from "../..";
import type { ReportWorkflowsCustomSort } from "../../../dasboards";

export const useCreateWorkflowMutation = () =>
  useMutation(workflowServices.createWorkflow);

export const useCreateReportWorkflowWithLabelMappingsMutation = () =>
  useMutation(workflowServices.createReportWorkFlowWithLabelMappings);

export const useGetUserWorkflowByEmail = () =>
  useMutation(workflowServices.getUserWorkflowByEmail);

export const useUpdateChildWorkflow = () =>
  useMutation(workflowServices.updateChildWorkflow);

export const useArchiveWorkflowMutation = () =>
  useMutation(workflowServices.archiveWorkflow);

export const useExportReportMutation = () =>
  useMutation(workflowServices.exportReport);

export const useDiscardWorkflow = () =>
  useMutation(workflowServices.discardWorkflow);

export const useGetTaskLaneList = (
  taskId: number | undefined,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getStepGroupList>
  >,
  redirectToHomeOnError?: boolean // On Process detail page, when process id is invalid, redirection to home is required
) => {
  const history = useHistory();
  return useQuery({
    enabled: taskId !== undefined && taskId !== null,
    retry: 0,
    ...config,
    refetchOnWindowFocus: false,
    onError: error => {
      if (!redirectToHomeOnError) return;
      // @ts-expect-error error.status not availalbe Check this
      if (error && error.status === 404) {
        // In process details page, when process not found adding redirction to home
        notification.error({
          message: "Process not found!"
        });
        // Adding set timout so like logout for user friendly notification, first error message is shown, then user is redircted to home page
        setTimeout(() => {
          history.replace("/");
        }, 1000);
      }
    },

    queryKey: ["stepGroups", taskId] as QueryKey,
    queryFn: () => workflowServices.getStepGroupList(taskId)
  });
};

export const getStepGroupDataFromCache = (taskId: number) =>
  queryClient.getQueryData<StepGroupsData>(["stepGroups", taskId]);

/**
 * TODO: Make proper types according to all filters supported
 * CAUTION:
 * TODO: This needs testing, since this touches the production code!
 */
export const useGetWorkflowList = (
  params: Record<string, string | number>,
  config?: UseInfiniteQueryExtraConfigType
) =>
  useInfiniteQuery(
    ["workflowList", params],
    () => workflowServices.getWorkflowList(params),
    {
      refetchInterval: 60 * 1000,
      retry: 0,
      getNextPageParam: ({ next }) => {
        if (next) {
          const urlParams = new URLSearchParams(next);
          const nextPage = urlParams.get("page");
          return nextPage;
        }
        return false;
      },
      ...config
    }
  );

export const useGetProcessDetails = (
  processId: number | null | undefined,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getTaskDetails>
  >
) =>
  useQuery<UseQueryReturnType<typeof workflowServices.getTaskDetails>>({
    enabled: processId !== null,
    retry: 0,
    ...config,
    queryKey: getWorkflowUniqKey(processId) as QueryKey,
    queryFn: () => workflowServices.getTaskDetails(processId as number)
  });

export const useGetProcessMigrationStats = (
  processId: number | null,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getTaskMigrationStats>
  >
) =>
  useQuery({
    ...config,
    queryKey: [processId, "procesMigrationStats"] as QueryKey,
    queryFn: () =>
      workflowServices.getTaskMigrationStats({
        taskId: processId,
        includeTotalEstimatedCompletionTime: true
      }),
    enabled: processId !== null
  });

export const useGetProgressMap = (
  taskId: number,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getStepGroupList>
  >
) => {
  const { status: canViewProgressMapStatus } = useCheckLevelPermission({
    processId: taskId,
    checkFor: WorkflowLevelPermissionType.CAN_VIEW_PROGRESS_MAP
  });
  return useQuery({
    enabled:
      canViewProgressMapStatus === "granted" &&
      (taskId !== undefined || taskId !== null),
    retry: 0,
    ...config,
    queryKey: ["progressMap", taskId, { "show-all": true }] as QueryKey,
    queryFn: () =>
      workflowServices.getStepGroupList(taskId, { "show-all": "true" })
  });
};

export const useGetStepUserTags = (taskId: number) => {
  return useQuery({
    enabled: taskId !== null,
    retry: 0,
    refetchOnWindowFocus: false,
    queryKey: ["stepUserTags", taskId],
    queryFn: () => workflowServices.getStepUserTags(taskId)
  });
};

export const getStepUserTagsFromCache = (taskId: number) => {
  return queryClient.getQueryData<StepUserTags>(["stepUserTags", taskId]);
};

// TODO: Remove kind tag from this hook
export const useGetFieldsHierarchy = (
  {
    kindTag,
    processTypeId
  }: Parameters<typeof workflowServices.getAdvancedFilterData>[0],

  config?: UseQueryOptions<FieldHierarchyReturnType>
) => {
  return useQuery({
    ...config,
    enabled: !!kindTag || !!processTypeId,
    cacheTime: Infinity,
    staleTime: Infinity,

    // Cache it forever, it will anyways be invalidated once the page is reloaded,
    queryKey: ["fields", kindTag || processTypeId] as QueryKey,
    queryFn: () => {
      // Create a new AbortController instance for this request
      const controller = new AbortController();

      // Get the abortController's signal
      const signal = controller.signal;
      const promise = workflowServices.getAdvancedFilterData(
        {
          kindTag: kindTag,
          processTypeId: processTypeId
        },
        {
          signal
        }
      );

      // @ts-expect-error: Cancel the request if React Query calls the `promise.cancel` method
      // React query expects a .cancel method on the returned promise
      // and it will automatically cancel the old promise when a new call is fired
      // but naturally the promise object does not have a cancel property.
      promise.cancel = () => {
        console.log("Aborting Advanced filters API call");
        controller.abort();
      };
      return promise;
    }
  });
};

export const useGetFieldsHierarchyORM = (
  {
    processTypeId,
    childrenKindIds = [],
    grandChildrenKindIds = [],
    useORMReport
  }: GetAdvancedFilterDataORMType,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getAdvancedFilterDataORM>
  >
) => {
  return useQuery({
    ...config,
    enabled: !!processTypeId,
    // Cache it forever, it will anyways be invalidated once the page is reloaded,
    cacheTime: Infinity,
    // Cache it forever, it will anyways be invalidated once the page is reloaded,
    staleTime: Infinity,
    queryKey: [
      "fields",
      processTypeId,
      childrenKindIds?.join(","),
      grandChildrenKindIds?.join(",")
    ] as QueryKey,
    queryFn: () => {
      // Create a new AbortController instance for this request
      const controller = new AbortController();

      // Get the abortController's signal
      const signal = controller.signal;
      const promise = workflowServices.getAdvancedFilterDataORM(
        {
          processTypeId,
          childrenKindIds,
          grandChildrenKindIds,
          useORMReport
        },
        {
          signal
        }
      );

      // @ts-expect-error: Cancel the request if React Query calls the `promise.cancel` method
      // React query expects a .cancel method on the returned promise
      // and it will automatically cancel the old promise when a new call is fired
      // but naturally the promise object does not have a cancel property.
      promise.cancel = () => {
        console.log("Aborting Advanced filters API call");
        controller.abort();
      };
      return promise;
    }
  });
};

type UseGetReportWorflowsTypes = {
  queryKey?: string;
  query: WorkflowFiltersQuery | null;
  offset: number;
  fieldTags: string[];
  ordering: ReportOrdering[] | null;
  config?: UseQueryExtraConfigType;
  reportType?: string;
  customSort?: ReportWorkflowsCustomSort;
  customPageSize?: number;
};
export const useReportWorkflows = ({
  queryKey,
  query,
  offset,
  fieldTags,
  ordering,
  config,
  reportType,
  customSort,
  customPageSize
}: UseGetReportWorflowsTypes) => {
  const [count, setCount] = useState();
  const workflows = useGetReportWorkflows({
    query,
    ordering,
    offset,
    fieldTags,
    config,
    reportType,
    customPageSize
  });
  // Mutates the original array
  if (customSort) workflows?.data?.results?.sort(customSort);
  const { refetch } = useGetReportWorkflowsCount({
    queryKey,
    query,
    config: {
      ...config,
      staleTime: 60 * 60 * 1000,
      enabled: false
    }
  });

  useEffect(() => {
    const _count = workflows.data?.results?.length;

    if (!workflows.isFetching) {
      if (_count < DEFAULT_PAGINATION_SIZE && offset === DEFAULT_OFFSET) {
        setCount(_count);
      } else if (_count !== undefined && offset === DEFAULT_OFFSET) {
        refetch().then(res => {
          setCount(res?.data?.count);
        });
      }
    }
  }, [workflows.isFetching, workflows.data?.results?.length, offset, refetch]);

  return {
    ...workflows,
    data: {
      count,
      results: workflows?.data?.results
    }
  };
};

export const useGetReportWorkflowsCount = ({
  queryKey,
  query,
  config
}: {
  queryKey?: string;
  query: WorkflowFiltersQuery | null;
  config?: UseQueryOptions<// Replace Any Type after getReportWorkflowsCount returns a type
  any>;
  // UseQueryReturnType<typeof workflowServices.getReportWorkflowsCount>
}) => {
  const reportWorkflowsCount = useQuery({
    refetchOnWindowFocus: false,
    retry: 0,
    ...config,
    queryKey: [queryKey ?? "report-data-count", query] as QueryKey,

    queryFn: () => {
      // Create a new AbortController instance for this request
      const controller = new AbortController();

      // Get the abortController's signal
      const signal = controller.signal;
      const promise = workflowServices.getReportWorkflowsCount(
        {
          ...query! // Typecasting since this query will be disabled when there is no kind_id
        },
        { signal }
      );

      // @ts-expect-error: Cancel the request if React Query calls the `promise.cancel` method
      // React query expects a .cancel method on the returned promise
      // and it will automatically cancel the old promise when a new call is fired
      // but naturally the promise object does not have a cancel property.
      promise.cancel = () => {
        console.log("useGetReportWorkflowsCount: Aborting API call");
        controller.abort();
      };
      return promise;
    }
  });
  return reportWorkflowsCount;
};

export const useGetReportWorkflows = ({
  queryKey,
  query,
  offset,
  fieldTags,
  ordering,
  config,
  reportType,
  customPageSize
}: UseGetReportWorflowsTypes) => {
  const reportWorkflows = useQuery({
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: Boolean(
      (query as WorkflowFiltersQuery)?.kind_id ||
        query?.self_created ||
        query?.incomplete_step_tags ||
        !!query?.id__in ||
        reportType === WORKFLOW_REPORT
    ),
    ...config,
    queryKey: [
      queryKey ?? "report-data",
      query,
      // Sorting this will make sure that the API
      // is not called once the fields ordering is changed
      [...fieldTags].sort(),
      offset,
      ordering
    ],

    queryFn: () => {
      // Create a new AbortController instance for this request
      const controller = new AbortController();

      // Get the abortController's signal
      const signal = controller.signal;
      const promise = workflowServices.getReportWorkflows(
        {
          ...query!, // Typecasting since this query will be disabled when there is no kind_id
          offset
        },
        fieldTags,
        ordering || undefined,
        { signal },
        customPageSize
      );
      // @ts-expect-error: Cancel the request if React Query calls the `promise.cancel` method
      // React query expects a .cancel method on the returned promise
      // and it will automatically cancel the old promise when a new call is fired
      // but naturally the promise object does not have a cancel property.
      promise.cancel = () => {
        console.log("useGetReportWorkflows: Aborting API call");
        controller.abort();
      };
      return promise;
    }
  });
  return {
    ...reportWorkflows,
    data: {
      count: reportWorkflows?.data?.count,
      results: reportWorkflows?.data?.results
    }
  };
};

export const useGetWorkflowDetails = (
  id: number | undefined,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getWorkflowDetailsById>
  >
) =>
  useQuery({
    enabled: id !== null,
    retry: 0,
    ...config!,
    queryKey: ["workflowDetails", id] as QueryKey,
    queryFn: () => workflowServices.getWorkflowDetailsById(id)
  });

export const useGetApiCallLogs = (
  reportType: string,
  queryUrlParams: string,
  offset: number,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getApiCallLogs>
  >
) =>
  useQuery({
    enabled: reportType === INTEGRATION_CALL_REPORT,
    staleTime: Infinity,
    ...config!,
    queryKey: ["api-call-logs", queryUrlParams, offset] as QueryKey,
    queryFn: () => workflowServices.getApiCallLogs(queryUrlParams, offset)
  });

export const useExportEmailDeliveryReport = () =>
  useMutation(
    ({
      attributes,
      filters,
      reportId
    }: {
      attributes: ColumnED[];
      filters?: FilterED;
      reportId: number;
    }) => {
      const { attributes: attributesPayload, filters: filtersPayload } =
        payloadCreatorED({
          attributes,
          filters
        });
      return workflowServices.exportEmailDeliveryReport({
        attributes: attributesPayload,
        filters: filtersPayload,
        reportId
      });
    }
  );

export const useExportIntegrationReport = () =>
  useMutation(
    ({
      attributes,
      filters,
      reportId
    }: {
      attributes: ColumnIC[];
      filters?: FilterIC;
      reportId: number;
    }) => {
      const { attributes: attributesPayload, filters: filtersPayload } =
        columnsPayloadCreatorIC({
          attributes,
          filters
        });
      return workflowServices.exportIntegrationReport({
        attributes: attributesPayload,
        filters: filtersPayload,
        reportId
      });
    }
  );

export const useGetEmailDeliveryReports = (
  reportType: string,
  queryUrlParams: string,
  offset: number,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getEmailDeliveryReports>
  >
) =>
  useQuery({
    enabled: reportType === EMAIL_DELIVERY_REPORT,
    staleTime: Infinity,
    ...config!,
    queryKey: ["email-delivery-reports", queryUrlParams, offset] as QueryKey,
    queryFn: () =>
      workflowServices.getEmailDeliveryReports(queryUrlParams, offset)
  });

export const useGetmailDeliveryReportsStatuses = (
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getEmailDeliveryReportsStatuses>
  >
) =>
  useQuery({
    staleTime: Infinity,
    ...config!,
    queryKey: ["email-delivery-reports-statuses"] as QueryKey,
    queryFn: () => workflowServices.getEmailDeliveryReportsStatuses()
  });

export const useGetEmailDeliveryReportsSubjects = (
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getEmailDeliveryReportsSubjects>
  >
) =>
  useQuery({
    staleTime: Infinity,
    ...config!,
    queryKey: ["email-delivery-reports-subjects"] as QueryKey,
    queryFn: () => workflowServices.getEmailDeliveryReportsSubjects()
  });

export const useGetEmailDeliveryReportsSmtpCodes = (
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getEmailDeliveryReportsSmtpCodes>
  >
) =>
  useQuery({
    staleTime: Infinity,
    ...config!,
    queryKey: ["email-delivery-reports-smtp-code"] as QueryKey,
    queryFn: () => workflowServices.getEmailDeliveryReportsSmtpCodes()
  });

export const useGetEmailDeliveryReportsKindIds = (
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getEmailDeliveryReportsKindIds>
  >
) =>
  useQuery({
    staleTime: Infinity,
    ...config!,
    queryKey: ["email-delivery-reports-kind-ids"] as QueryKey,
    queryFn: () => workflowServices.getEmailDeliveryReportsKindIds()
  });

export const useGetIntegrationReportKindIds = (
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getGetIntegrationReportKindIds>
  >
) =>
  useQuery({
    staleTime: Infinity,
    ...config!,
    queryKey: ["api-call-logs-kind-ids"] as QueryKey,
    queryFn: () => workflowServices.getGetIntegrationReportKindIds()
  });

export const useGetIntegrationReportApiTypes = (
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getGetIntegrationReportApiTypes>
  >
) =>
  useQuery({
    staleTime: Infinity,
    ...config!,
    queryKey: ["api-call-logs-api-types"] as QueryKey,
    queryFn: () => workflowServices.getGetIntegrationReportApiTypes()
  });

export const useGetIntegrationReporStatuses = (
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getGetIntegrationReportStatues>
  >
) =>
  useQuery({
    staleTime: Infinity,
    ...config!,
    queryKey: ["api-call-logs-statuses"] as QueryKey,
    queryFn: () => workflowServices.getGetIntegrationReportStatues()
  });

export const useGetWorkflowPermissionsType = (
  id: number,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.geWorkflowPermissionsTypeById>
  >
) =>
  useQuery({
    enabled: config?.enabled !== undefined ? config.enabled : !!id,
    retry: 0,
    staleTime: Infinity,
    ...config!,
    queryKey: ["workflowPermissions", id] as QueryKey,
    queryFn: () => workflowServices.geWorkflowPermissionsTypeById(id)
  });

export function useGetDynamicWorkflow<T, ResponseDataT = any>(
  payload: {
    data: { workflow: T };
    filters: WorkflowFiltersQuery;
    extra?: WorkflowAccessQuery;
  },
  config?: UseQueryOptions<
    unknown,
    unknown,
    WorkflowDynamicAPIResultType<ResponseDataT>
  >
) {
  return useQuery({
    enabled: !!payload?.data?.workflow,
    retry: 0,
    staleTime: Infinity,
    ...config!,
    queryKey: ["workflowDynamicAPI", payload] as QueryKey,
    queryFn: () => workflowServices.dynamicWorkflowAPI(payload)
  });
}

export const useGetRelatedWorkflows = (kindId: number) =>
  useQuery<RelatedKindType[]>({
    retry: 0,
    cacheTime: 0,
    queryKey: ["related-kinds"],
    queryFn: () => workflowServices.getRelatedWorkflows(kindId)
  });

export const useUpsertWorkflow = (mutateConfig?: UseMutationExtraConfigType) =>
  useMutation(upsertWorkflow, mutateConfig);
