import { MAX_PROGRESS_PERCENTAGE } from "@certa/blocks/thanos";
import type { Step, StepDependencies, StepGroup } from "@certa/types";
import {
  uniq,
  countBy,
  entries,
  flow,
  head,
  last,
  maxBy,
  partialRight
} from "lodash-es";

type SwimlaneOneToManyDependenciesWithOrder = Record<string, [number]>;
type SourceTargetType = {
  source: string;
  target: string;
};
type ParentAncestorMapType = Record<string, number>;

export const getTaskCompletion = (taskLanes: StepGroup[]) =>
  // NOTE: For empty array .every always returns true so using the length check
  {
    return taskLanes.length
      ? taskLanes.every(tasklane =>
          tasklane.steps.every(
            task =>
              task.isCompleted ||
              task.stepType === "FYI" ||
              task.stepType === "readonly"
          )
        )
      : false;
  };

export const getTaskLaneFromTaskId = (
  taskLanes: StepGroup[],
  selectedTask: number | null | undefined
) =>
  taskLanes.find(taskLane =>
    taskLane.steps.find(task => task.id === selectedTask)
  );

export const getSwimlaneBgByProgressStatus = (
  group: StepGroup,
  isInProgress: boolean,
  isOverdue: boolean
) => {
  switch (true) {
    case group.isCompleted:
      return {
        background: "var(--green-60)",
        border: "1px solid var(--neutral-20)"
      };
    case isOverdue:
      return {
        background: "var(--red-60)",
        border: "1px solid var(--red)"
      };
    case isInProgress:
      return {
        background: "var(--teal-60)",
        border: "1px solid"
      };

    default:
      return {
        background: "var(--neutral-0)",
        border: "1px solid var(--neutral-20)"
      };
  }
};

export const getTaskListColor = (
  group: StepGroup,
  outlined?: boolean,
  isProcessFlow?: boolean,
  percent?: number
) => {
  const submittableIncompletedSteps = group.steps.filter(
    step => !step.isCompleted && step.stepType !== "FYI"
  );
  if (group.overdue && submittableIncompletedSteps.length)
    return {
      background: "var(--red-60)",
      border: "var(--red-40)",
      textColor: "var(--red)",
      progressColor: "var(--red)"
    };

  if (
    group?.isCompleted ||
    (percent === MAX_PROGRESS_PERCENTAGE && isProcessFlow)
  )
    return {
      background: "var(--green-60)",
      border: "var(--green-40)",
      textColor: "var(--green)",
      progressColor: "var(--green)"
    };

  return {
    background: "var(--neutral-0)",
    border: outlined ? "var(--neutral-20)" : "var(--neutral-0)",
    textColor: isProcessFlow ? "var(--neutral-70)" : "var(--neutral-100)",
    progressColor: "var(--teal)"
  };
};

export const getMyTaskCountFromSteps = (steps: Step[]) => {
  return steps.reduce((count, item) => {
    if (item.isCurrentUser) {
      count = +1;
      return count;
    }
    return count;
  }, 0);
};

const getResolvedParents = (
  parents: number[],
  swimlaneOneToManyDependenciesWRTOrder: SwimlaneOneToManyDependenciesWithOrder
) => {
  // <parent: mostNearestAncestor>
  const parentsAndAncestorMap: ParentAncestorMapType = parents.reduce(
    (acc, parent) => ({
      ...acc,
      ...(swimlaneOneToManyDependenciesWRTOrder[parent]
        ? {
            // Pick the nearest ancestor for each parent
            [parent]: Math.max(...swimlaneOneToManyDependenciesWRTOrder[parent])
          }
        : {})
    }),
    {}
  );

  /**
   *  In the parents to ancestor map, we try to group the most occurring, common
   *  ancestor among the given parents and just pick that one ancestor's order
   */
  const mostCommonAncestor = flow(
    countBy,
    entries,
    partialRight(maxBy, last),
    head
  )(Object.values(parentsAndAncestorMap));

  // All those parents which match will appear on the same level.
  const commonAncestor: string[] = Object.keys(parentsAndAncestorMap).reduce(
    (acc: string[], cur: string) =>
      `${parentsAndAncestorMap[cur]}` === `${mostCommonAncestor}`
        ? [...acc, cur]
        : acc,
    []
  );

  // If we found only one ancestor then its better to fallback to the nearest parent
  return commonAncestor.length > 1 ? commonAncestor : [];
};

const getNearestParent = (parents: number[], current: number) => {
  const _parents = [...parents];
  const backwardEdges: number[] = [];

  parents.forEach((parent, index) => {
    // honor backward dependencies
    if (current - parent < 0) {
      // Mark their order as -1 so that it doesn't get picked on Math.max
      _parents[index] = -1;
      backwardEdges.push(parent);
    }
  });

  if (backwardEdges.length) {
    return backwardEdges.concat(Math.max(..._parents));
  }

  // if no backward deps then simply return the closest parent
  return [Math.max(..._parents)];
};

// Used to see if any deps exists from isolated nodes and add them
const getIsolatedNodesEdgeMapping = (
  nodes: number[],
  dependencies: SwimlaneOneToManyDependenciesWithOrder
) => {
  const edges = Object.entries(dependencies).reduce(
    (acc: SourceTargetType[], cur: [string, number[]]) => {
      // [currentNode, [...itsIncomingDependencies]]
      const [key, value] = cur;
      const edges: SourceTargetType[] = [];
      nodes.forEach(nodeOrder => {
        // if the isolated node's order exists in any deps list
        if (value.includes(nodeOrder)) {
          // Push and edge from isolated node to currentNode
          edges.push({
            source: `${nodeOrder}`,
            target: key
          });
        }
      });
      return [...acc, ...edges];
    },
    []
  );
  return edges;
};

/**
 *
 * @param groupsList
 * @param stepDependencies
 * @returns SourceTargetType[]
 *
 *  What this function does?
 *  - The function takes in the swimlanes and the swimlane's steps dependency map
 *    and transforms the input into an array of objects with source and target as keys.
 */
export const getFlowEdges = (
  groupsList?: StepGroup[],
  stepDependencies?: StepDependencies
) => {
  if (!groupsList || !stepDependencies) return [];

  // Swimlane order by its array index as va
  const swimlaneOrder: Record<string, number> = groupsList.reduce(
    (a, c, i) => ({ ...a, [c.definitionTag]: i }),
    {}
  );

  // Map all the steps def tag to its corresponding swimlane tags
  const stepsToSwimlaneMap: Record<string, string> = groupsList.reduce(
    (acc, swimlane) => {
      return {
        ...acc,
        ...swimlane.steps.reduce(
          (_acc, step) => ({
            ..._acc,
            [step.definitionTag]: swimlane.definitionTag
          }),
          {}
        )
      };
    },
    {}
  );

  /**
   *  Note: for now in ProcessMap we are not concerned about the type of dependency
   *  - Lock dep or Visibility dep. Hence we are merging both and considering the
   *  unique tag values as a general dependencies.
   */
  const mergedStepDependencies: Record<string, [string]> = Object.keys(
    stepDependencies
  ).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: uniq([
        ...stepDependencies[cur].lockDependentOn,
        ...stepDependencies[cur].visibilityAffectedBy
      ])
    }),
    {}
  );

  // one swimlaneTag to many swimlaneTag deps - { [swimlaneTag]: [...parentSwimlaneTags] }
  const swimlaneOneToManyDependencies: Record<string, [string]> = Object.keys(
    mergedStepDependencies
  ).reduce(
    (acc, cur) => ({
      ...acc,
      [stepsToSwimlaneMap[cur]]: uniq(
        mergedStepDependencies[cur].map(
          (step: string) => stepsToSwimlaneMap[step]
        )
      )
    }),
    {}
  );

  /**
   *  Added this step for readability
   *  - Easy to debug with number mappings in contrast to lengthy tags
   *  - Also for future cases, simply plug the order dependency map here and see the
   *    graph in action.
   *  For instance: Use the following data as the reference point 
        {
          "1": [0],
          "2": [6, 1],
          "3": [2],
          "4": [0, 2, 1],
          "5": [0, 2],
          "6": [5, 3, 4, 1]
        }
   */
  const swimlaneOneToManyDependenciesWRTOrder: SwimlaneOneToManyDependenciesWithOrder =
    Object.keys(swimlaneOneToManyDependencies).reduce(
      (acc, cur) => ({
        ...acc,
        [swimlaneOrder[cur] || 0]: swimlaneOneToManyDependencies[cur].map(
          // for the first swimlane fallback to 0
          (step: string) => swimlaneOrder[step] || 0
        )
      }),
      {}
    );

  // The edges list in ordered format
  let resolvedEdgesWithSwimlaneOrder = Object.keys(
    swimlaneOneToManyDependenciesWRTOrder
  ).reduce((acc: SourceTargetType[], cur: string) => {
    const parents = swimlaneOneToManyDependenciesWRTOrder[cur];
    // for the first node
    if (!parents) return acc;
    // direct dependencies can't be eliminated
    if (parents.length === 1) {
      return [...acc, { source: `${parents[0]}`, target: cur }];
    }
    // Among the available find the parent having common ancestors
    const parentsWithCommonAncestor: string[] | [] = getResolvedParents(
      parents,
      swimlaneOneToManyDependenciesWRTOrder
    );
    // if we found the nodes that appear on same level then add edges
    if (parentsWithCommonAncestor?.length) {
      return [
        ...acc,
        ...parentsWithCommonAncestor.map((parent: string) => ({
          source: `${parent}`,
          target: cur
        }))
      ];
    }

    // If we didn't find the common parent then hook onto a closest node if dep exists
    const nearestParents = getNearestParent(parents, +cur).map(parent => ({
      source: `${parent}`,
      target: cur
    }));

    return [...acc, ...nearestParents];
  }, []);

  const nodesWithEdgesMap = Object.keys(
    resolvedEdgesWithSwimlaneOrder.reduce(
      (acc, cur) => ({ ...acc, [cur.source]: 1, [cur.target]: 1 }),
      {}
    )
  );

  // See if we have any isolated (no incoming or outgoing edges) nodes
  const isolatedNodes = Object.values(swimlaneOrder).filter(
    x => !nodesWithEdgesMap.includes(`${x}`)
  );

  // if so show their dependencies too
  if (isolatedNodes.length) {
    resolvedEdgesWithSwimlaneOrder = [
      ...resolvedEdgesWithSwimlaneOrder,
      ...getIsolatedNodesEdgeMapping(
        isolatedNodes,
        swimlaneOneToManyDependenciesWRTOrder
      )
    ];
  }

  const swimlaneTagKeyedByOrder: Record<string, string> = Object.keys(
    swimlaneOrder
  ).reduce((a, c) => ({ ...a, [swimlaneOrder[c]]: c }), {});

  // Transform the o/p in ordered to swimlaneTag format
  const resolvedEdgesWithSwimlaneTag = resolvedEdgesWithSwimlaneOrder
    .filter(item => item.source !== item.target) // remove self loops
    .map(item => ({
      id: `edge-${swimlaneTagKeyedByOrder[item.source]}-${
        swimlaneTagKeyedByOrder[item.target]
      }`,
      source: swimlaneTagKeyedByOrder[item.source],
      target: swimlaneTagKeyedByOrder[item.target]
    }));

  return resolvedEdgesWithSwimlaneTag;
};

export const isReadOnlyOrFYIStep = (step: Step) =>
  step.stepType === "FYI" || step.stepType === "readonly";
