import { css } from "emotion";

export const searchDropdownStyles = css({
  ".catalyst-select__indicator": {
    display: "none"
  },
  ".catalyst-select__control": {
    border: "none",
    cursor: "text",
    "&:focus-within": {
      border: "none",
      cursor: "text"
    },
    "&:hover": {
      border: "none",
      cursor: "text"
    }
  },
  ".catalyst-select__control:focus-within": {
    border: "none",
    backgroundColor: "var(--colors-neutral-100)",
    boxShadow: "none"
  },
  ".catalyst-select__menu": {
    boxShadow: "none"
  },
  ".catalyst-select__menu-list": {
    height: "576px",
    maxHeight: "576px"
  }
});
