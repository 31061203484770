import { createSelector } from "reselect";
import { get as lodashGet, groupBy } from "lodash-es";
import { FILTERS_ENUM } from "./constants";
import { getOccurrenceDay } from "./utils";
import { getIntlBody } from "../../js/_helpers/intl-helpers";

export const statusesSelector = state => state.workflowList.staticData.statuses;

export const selectedKindSelector = state => {
  return lodashGet(
    state,
    `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.KIND_FILTER.name}].meta`
  );
};

// TODO: filter using selected kind id
export const statusesForFilterDropdownSelector = createSelector(
  statusesSelector,
  selectedKindSelector,
  (statuses, kind) => {
    const statusesData = Array.isArray(statuses.data)
      ? kind
        ? kind.availableStatuses
            .map(statusId =>
              statuses.data.find(status => status.id === statusId)
            )
            .map(status => ({
              label: status.displayName,
              value: status.id
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : statuses.data.map(status => ({
            label: status.displayName,
            value: status.id
          }))
      : statuses.data;
    return {
      ...statuses,
      data: statusesData
    };
  }
);

export const selectedBasicFiltersSelector = state => {
  return [
    {
      label: "status",
      name: FILTERS_ENUM.STATUS_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.STATUS_FILTER.name}].value`
      )
    },
    {
      label: "region",
      name: FILTERS_ENUM.REGION_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.REGION_FILTER.name}].value`
      )
    },
    {
      label: "businessUnit",
      name: FILTERS_ENUM.BUSINESS_UNIT_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.BUSINESS_UNIT_FILTER.name}].value`
      )
    },
    {
      label: "startDateFilter",
      name: FILTERS_ENUM.START_DATE_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.START_DATE_FILTER.name}].value`
      )
    },
    {
      label: "endDateFilter",
      name: FILTERS_ENUM.END_DATE_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.END_DATE_FILTER.name}].value`
      )
    }
  ];
};

export const selectedAdvancedFiltersSelector = state => {
  return {
    label: "advancedFitlers",
    name: FILTERS_ENUM.ADVANCED_FILTER.name,
    value: lodashGet(
      state,
      `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.ADVANCED_FILTER.name}].value`,
      ""
    )
  };
};

export const kindsSelector = state => state.workflowList.kinds;

/**
 * Returns the selector that retrives the kind definition of the specified
 * kind tag
 * @param {string} kindTag
 */
export const kindSelector = kindTag =>
  createSelector(kindsSelector, allKinds => {
    if (!kindTag) return null;
    if (!Array.isArray(allKinds.data)) return null;

    return allKinds.data.find(kind => kind.tag === kindTag);
  });

export const kindsForNewWorkflowSelector = createSelector(
  kindsSelector,
  kinds =>
    Array.isArray(kinds.data)
      ? kinds.data.filter(
          kind =>
            !kind.isRelatedKind &&
            kind.features.includes("add_workflow") &&
            !["users", "entity-id"].includes(kind.tag)
        )
      : null
);

export const hiddenGroupsMainNavSelector = state => {
  return lodashGet(
    state,
    `config.custom_ui_labels["workflows.hiddenGroupsMainNav"]`,
    []
  );
};
export const taskQueuesSelector = state => state.workflowList.taskQueues;
export const visibleTaskQueuesSelector = createSelector(
  taskQueuesSelector,
  hiddenGroupsMainNavSelector,
  (taskQueues, hiddentTags) => {
    if (!taskQueues.data) {
      return taskQueues;
    }
    return {
      ...taskQueues,
      data: taskQueues.data.filter(
        taskQueue => !hiddentTags.includes(taskQueue.tag)
      )
    };
  }
);
export const alertsSelector = state => state.workflowList.alerts;

export const regionPlaceholderSelector = state => {
  const custom_ui_labels = lodashGet(state, "config.custom_ui_labels", null);
  return custom_ui_labels
    ? getIntlBody(custom_ui_labels, "filterPlaceholders.Region")
    : null;
};
export const businessUnitPlaceholderSelector = state => {
  const custom_ui_labels = lodashGet(state, "config.custom_ui_labels", null);
  return custom_ui_labels
    ? getIntlBody(custom_ui_labels, "filterPlaceholders.Business")
    : null;
};

export const workflowCountSelector = state =>
  lodashGet(state, "workflowList.workflowList.data.count", null);

export const isWorkflowSortingEnabledSelector = createSelector(
  selectedKindSelector,
  kind => lodashGet(kind, "isSortingFieldEnabled", false)
);

export const workflowListCountSelector = state =>
  lodashGet(state, "workflowList.workflowList.data.count", 0);
export const workflowListSelector = state =>
  lodashGet(state, "workflowList.workflowList.data.results", []);

export const isSortingFilterAppliedSelector = state => {
  return lodashGet(
    state,
    `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.ORDERING_FILTER.name}].meta`,
    false
  );
};

export const groupedWorkflowsSelector = createSelector(
  workflowListSelector,
  isSortingFilterAppliedSelector,
  (workflows, isSortingFilterApplied) =>
    isSortingFilterApplied
      ? { workflows }
      : groupBy(workflows, getOccurrenceDay)
);
