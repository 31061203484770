import type { OptionGroup, OptionItem } from "@certa/types";
import type { FieldResponse, ResultsResponse } from "../types/generic.types";
import type { AppVersion, DeployedAppVersion } from "../types/appBuilder.types";

/**
 * Returns a modeled list of options (ready to use with Select component)
 * where options contains item, identifying the every related workflow
 * for given designer workflow.
 * @param response
 */
export const relatedProcessesModelCreator = (response: ResultsResponse) => {
  const options: OptionItem[] = [];
  response.results.forEach((item: any, index: number) => {
    options.push({
      label: item.workflow_kind_name,
      value: item.workflow_kind_tag,
      kindId: item.workflow_id
    });
  });
  return options;
};

export const arrayAnswerAsDropdownModelCreator = (
  response: FieldResponse
): OptionItem[] | null =>
  response.answer !== undefined
    ? response.answer.split("~").map(
        item =>
          ({
            label: item,
            value: item
          }) as OptionItem
      )
    : null;

export const bulkActionFieldModelCreator = (
  response: FieldResponse
): OptionItem[] | null => {
  if (response.answer !== undefined) {
    const answers = JSON.parse(response.answer);
    return Array.isArray(answers)
      ? answers.map(answer => ({
          label: answer.name,
          value: answer.action_tag
        }))
      : null;
  }
  return null;
};

export const designerKindsDropdownModelCreator = (
  response: ResultsResponse
) => {
  const options = [] as Array<{
    label: string;
    value: string;
    workflowId: number;
  }>;
  response.results?.forEach((item: any) => {
    options.push({
      label: item.label,
      value: item.value,
      workflowId: item.workflow_id
    });
  });

  return options;
};

export const editableTableColumnAsDropdownModelCreator = (
  response: ResultsResponse
) => response.results as unknown as OptionItem[];
/**
 * Returns a modeled list of options (ready to use with Select component)
 * where option groups are formed of Step Group and Step name, and within each
 * option, exist the fields that belong to that particular pair of
 * Step Group and step.
 * @param response
 */
export const fieldSelectModelCreator = (response: ResultsResponse) => {
  const options: OptionGroup[] = [];
  response.results.forEach((item: any, index: number) => {
    const stepLabel = `${item.step_group || "Unnamed"} →  ${item.step}`;
    let stepGroup = options.find(sg => sg.label === stepLabel);
    if (!stepGroup) {
      stepGroup = {
        label: stepLabel,
        options: []
      };
      options.push(stepGroup);
    }

    stepGroup.options?.push({
      label: item.label,
      value: item.value,
      title: item.label,
      id: item.id
    });
  });
  return options;
};

/**
 * Return the fields for the Select component which include the
 * stepgroup and stepname as the group label.
 * @param response
 * @returns {OptionGroup}
 */
export const fieldLiteSelectModelCreator = (response: any) => {
  const options: OptionGroup[] = [];

  if (response) {
    Object.values(response).forEach((stepGroup: any) => {
      Object.values(stepGroup?.steps ?? {}).forEach((step: any) => {
        const stepLabel = `${stepGroup?.name || "Unnamed"} → ${step?.name}`;
        const fieldOptions = Object.values(step?.fields ?? {}).map(
          (field: any) => ({
            label: field.body,
            value: field.tag,
            title: field.body,
            type: field.field_type,
            id: field.id
          })
        );
        options.push({
          label: stepLabel,
          options: fieldOptions
        });
      });
    });
  }

  return options;
};

/**
 * Returns a modeled list of options (ready to use with Select component)
 * where option groups are formed of Step Group and Step name, and within each
 * option, exist the fields that belong to that particular pair of
 * Step Group and step. This field data also contains the "type" of that field
 * such so that it can be used to filter out on that basis, if needed.
 * @param response
 */
export const fieldSelectModelCreatorWithType = (response: ResultsResponse) => {
  const options: OptionGroup[] = [];
  response.results.forEach((item: any, index: number) => {
    const stepLabel = `${item.step_group || "Unnamed"} →  ${item.step}`;
    let stepGroup = options.find(sg => sg.label === stepLabel);
    if (!stepGroup) {
      stepGroup = {
        label: stepLabel,
        options: []
      };
      options.push(stepGroup);
    }

    stepGroup.options?.push({
      label: item.label,
      value: item.value,
      title: item.label,
      type: item.field_type
    });
  });
  return options;
};

export const workflowAsDropdownModelCreator = (response: ResultsResponse) => {
  const options: OptionItem[] = [];
  response.results.forEach((item: any) => {
    options.push({
      ...item
    });
  });
  return options;
};

/**
 * Returns modeled list of app versions.
 * @param response
 * @returns {OptionData[]}
 */
export const appVersionsModelCreator = (response: ResultsResponse) => {
  const versions = response.results
    .map((item: any) => {
      const version: AppVersion = {
        createdAt: item.created_at,
        description: item.description,
        environments: item.environments,
        id: item.id,
        isEditable: item.is_editable,
        processCount: item.process_count,
        version: item.version,
        versionLabel: item.version_label,
        lastActivityAt: item.last_activity_at,
        dependencyConfig: item.admin_settings_config
      };
      return version;
    })
    .sort((a, b) => (a.version > b.version ? -1 : 1));
  return versions;
};

/**
 * Returns modeled list of app versions.
 * @param response
 * @returns {OptionData[]}
 */
export const deployedAppsModelCreator = (response: ResultsResponse) => {
  const versions = response.results
    .map((item: any) => {
      const version: DeployedAppVersion = {
        description: item.description,
        environments: item.environments,
        id: item.id,
        processCount: item.process_count,
        version: item.version,
        versionLabel: item.version_label,
        appId: item.app_id,
        appName: item.app_name,
        appUid: item.app_uid,
        appLCData: item.app_lc_data,
        lastActivityAt: item.last_activity_at
      };
      return version;
    })
    .sort((a, b) => (a.version > b.version ? -1 : 1));
  return versions;
};
