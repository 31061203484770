import {
  Navbar,
  NavbarLogo,
  NavbarFooter,
  NavbarItem,
  NavbarUserProfile,
  UserProfileMenuItem,
  AvatarSizes,
  UserProfileSubMenuItemTrigger,
  DropdownSubMenu,
  DropdownSubMenuTrigger,
  Typography,
  TypographyVariants,
  TypographyColors,
  AvatarColors
} from "@certa/catalyst";
import {
  Book,
  Envelope,
  Eye,
  Gear,
  Help,
  Language,
  OpenInWindow,
  User
} from "@certa/icons";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  useHomePage,
  useCanViewTasks,
  useCheckPermissionFn,
  useHeaderReportLinkText,
  useCheckPermission,
  getUsersByKind,
  toCapital,
  useShowHeaderDropdownOptions,
  useCanSeeCreateSupportButton,
  useCreateNewWorkflow,
  CERTA_SUPPORT,
  type DropdownOption,
  useEnableUserImpersonation,
  TABLEAU_PATH
} from "../../..";
import { SearchDropdown, getSearchRoute } from "@certa/common";
import { useAppSelector, useAppDispatch } from "../../hooks";
import {
  checkLinkVisibility,
  handleMixPanelEventsForNav,
  mixpanelHandler
} from "./utils";
import { headerNavLinks, studioNavLink } from "./NavigationSidebar.constants";
import { useMentionsContext } from "@certa/mentions";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { useIntl } from "react-intl";
import { useGetTaskCount, useGetUnReadMentionCount } from "@certa/queries";
import { MySubscriptions } from "./components/MySubscriptions";
import { UserAvatar } from "../Header/components/UserProfile/components";
import useLanguages from "./hooks/useLanguages";
import { userLogout } from "main/src/modules/LoginPage/loginSlice";
import { InstanceSettings } from "./components/InstanceSettings";
import { PermissionTypes } from "@certa/types";
import { ImpersonationUsersList } from "../Header/components/UserProfile/components/ImpersonationUsersList";
import { css } from "emotion";
import { useNavigationDrawer } from "./DrawerContext";
import { getIntlBody } from "main/src/js/_helpers/intl-helpers";
import {
  NavbarDropdownMenuTrigger,
  NavbarUserProfileTrigger
} from "@certa/catalyst/src/layouts";

type Link = {
  label: string;
  url: string;
}[];

const NavigationSidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const authentication = useAppSelector(state => state?.authentication);

  const currentRoute = window.location.pathname;
  useEffect(() => {
    if (currentRoute === "/home") {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [currentRoute]);
  const history = useHistory();

  const isSupplierUser = authentication?.user?.kind === 2;
  const isHomePageEnabled = useHomePage();
  const canViewTasks = useCanViewTasks();
  const canViewStudio2 = useCheckPermission(PermissionTypes.CAN_ACCESS_STUDIO);
  const checkPermissions = useCheckPermissionFn();

  const { setIsDashboardDrawerOpen, setIsSearchModalOpen } =
    useNavigationDrawer();
  const filterNavLinks = checkLinkVisibility(checkPermissions, {
    isSupplierUser,
    isHomePageEnabled,
    canViewTasks,
    canViewStudio2
  });

  const navLinks = [...headerNavLinks, studioNavLink];

  const activeRouteLabel = navLinks.find(
    nav =>
      currentRoute.includes(nav.route) ||
      nav?.alias?.some(aliasRoute => aliasRoute.test(currentRoute))
  )?.label;

  const { toggleShow: toggleNotificationDrawer } = useMentionsContext();
  const reportText = useHeaderReportLinkText();
  const intl = useIntl();
  const config = useAppSelector(state => state.config);
  // Adding `!!` so that TS understands it as boolean automatically without typecasting since the authentication and user in the reducer are of type `any`
  const shouldShowTableauReports =
    !!authentication?.user?.features?.includes("view_reports") &&
    !!config?.report_embed_url;
  const { data: userMentionsCount } = useGetUnReadMentionCount();
  const { data: myTaskCount } = useGetTaskCount();
  const [shouldShowMySubscriptions, setShouldShowMySubscriptions] =
    useState(false);
  const [shouldShowInstanceSettings, setShouldShowInstanceSettings] =
    useState(false);
  const tableauLinkLabel =
    reportText ||
    intl.formatMessage({
      id: "navigation.tableau",
      defaultMessage: "Open Tableau"
    });

  const handleNavItemsClick = (args: { label: string; route: string }) => {
    const { label, route } = args;
    handleMixPanelEventsForNav(label);
    switch (label) {
      case "Notifications":
        // Close dashboard drawer if open and then open the notifications drawer
        setIsDashboardDrawerOpen(false);
        toggleNotificationDrawer();
        break;
      case "Search":
        if (!isSearchPage) {
          toggleNotificationDrawer(false);
          setIsDashboardDrawerOpen(false);
          setIsSearchModalOpen(true);
        }
        break;
      case "Dashboards":
        // Close notifications drawer if open and then open the dashboard drawer
        toggleNotificationDrawer(false);
        setIsDashboardDrawerOpen(prevState => !prevState);
        if (activeRouteLabel !== "Dashboards") {
          history.push(route);
        }
        break;
      default:
        toggleNotificationDrawer(false);
        setIsDashboardDrawerOpen(false);
        history.push(route);
    }
  };

  const handleStudioClick = () => {
    mixpanelHandler(studioNavLink.label);
    window.open(studioNavLink.route, "_blank");
  };

  const handleTableauClick = () => {
    mixpanelHandler("Tableau");
    window.open(`${window.location.origin}${TABLEAU_PATH}`, "_blank");
  };

  const showCountBadges = (key: string) => {
    switch (key) {
      case "Notifications":
        return userMentionsCount;
      case "Tasks":
        return myTaskCount;
      default:
        return undefined;
    }
  };
  const userName =
    authentication?.user?.first_name && authentication?.user?.last_name
      ? `${authentication.user.first_name} ${authentication.user.last_name}`
      : "";

  const userEmail = authentication?.user?.email || "";
  const dispatch = useAppDispatch();
  const handleLogout = useCallback(() => dispatch(userLogout()), [dispatch]);

  const canAccessInstanceSettings = useCheckPermission(
    PermissionTypes.CAN_ACCESS_ADMIN_SETTINGS
  );

  const {
    supportedLanguages,
    preferredLanguage,
    handleLanguageChange,
    renderLanguageMenuItem
  } = useLanguages();

  const canUserViewAs = useEnableUserImpersonation();
  const hasPermissionToImpersonate = useCheckPermission(
    PermissionTypes.CAN_IMPERSONATE_OTHER_USERS
  );

  const tenantName = toCapital(
    (useAppSelector(state => state.config.name) as string) || "certa"
  ) as Capitalize<string>;
  const usersByKind = getUsersByKind(tenantName);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleSubmenuOpenChange = (isOpen: boolean) => {
    setIsSubmenuOpen(isOpen);
  };
  const isSearchPage = useRouteMatch(getSearchRoute());

  const userProfileId = authentication?.user?.user_profile_workflow || null;
  const showHeaderDropdownOptions = useShowHeaderDropdownOptions();
  const { supportTicketData } = useCanSeeCreateSupportButton();
  const { onSelect: createWorkflowOnSelect } = useCreateNewWorkflow();

  const headerLinks: Link =
    useAppSelector(state => state.config.custom_ui_labels.header_links) || [];

  const footerLinks: Link =
    useAppSelector(state => state.config.custom_ui_labels.footer_links) || [];

  const goToUserProfile = useCallback(() => {
    handleMixPanelEventsForNav("My Profile");
    history.push(`/process/${userProfileId}`);
  }, [history, userProfileId]);

  const goToScheduledReports = () => {
    handleMixPanelEventsForNav("My Scheduled Reports");
    setShouldShowMySubscriptions(prev => !prev);
  };

  const goToInstanceSettings = () => {
    handleMixPanelEventsForNav("Instance Settings");
    setShouldShowInstanceSettings(prev => !prev);
  };

  const myProfileText = intl.formatMessage({
    id: "userProfileMenu.myProfileText",
    defaultMessage: "My Profile"
  });

  const logOutMessage = intl.formatMessage({
    id: "userProfileMenu.logOutMessage",
    defaultMessage: "Log out"
  });

  const languageText = intl.formatMessage({
    id: "userProfileMenu.languageText",
    defaultMessage: "Language"
  });

  const instanceSettingsText = intl.formatMessage({
    id: "loginPageInstances.InstanceSettingsText",
    defaultMessage: "Instance Settings"
  });

  const helpText = intl.formatMessage({
    id: "userProfileMenu.help",
    defaultMessage: "Help"
  });

  const onSelectLink = (value: string, index: number) => {
    MixPanelActions.track(
      MixPanelEvents.navbar.NAV_PROFILE_HELP_CLICK_OPEN_USER_HELP_LINK,
      // TODO: https://thevetted.atlassian.net/browse/PLAT-21478
      // Passing index to track which link is clicked does not
      // give any useful information to the event
      // To check with the product team if we need this data or not
      { helpLinkIndex: index }
    );
    window.open(value);
  };

  const handleUserProfileMenuClick = () => {
    toggleNotificationDrawer(false);
    setIsDashboardDrawerOpen(false);
  };

  /* We don't show header links to supplier users */
  const shouldShowHeaderLinks = headerLinks?.length > 0 && !isSupplierUser;
  const shouldShowFooterLinks = footerLinks?.length > 0;

  const shouldShouldHelpNavMenu =
    showHeaderDropdownOptions.length ||
    supportTicketData ||
    shouldShowHeaderLinks ||
    shouldShowFooterLinks;

  // If mini_logo is available, use it when the navbar is collapsed
  // Otherwise, use the logo
  const logoImageSrc = isExpanded
    ? config.logo
    : config?.mini_logo || config.logo;

  return (
    <>
      <Navbar expanded={isExpanded} activeNavItem={activeRouteLabel}>
        <NavbarLogo path="/" imageAlt={config.name} imageSrc={logoImageSrc} />
        {filterNavLinks(headerNavLinks).map((nav, index) => {
          const { label, route, icon: Icon } = nav;
          return (
            <NavbarItem
              id={label}
              onClick={() => handleNavItemsClick({ label, route })}
              icon={<Icon size={16} />}
              key={index}
              label={intl.formatMessage({
                id: `navigation.${label}`,
                defaultMessage: label
              })}
              badgeCount={showCountBadges(label)}
            />
          );
        })}
        <NavbarFooter>
          {canViewStudio2 && (
            <NavbarItem
              id={studioNavLink.label}
              onClick={handleStudioClick}
              icon={<studioNavLink.icon size={16} />}
              label={intl.formatMessage({
                id: `navigation.Studio`,
                defaultMessage: studioNavLink.label
              })}
            />
          )}
          {shouldShowTableauReports && (
            <NavbarItem
              id="Tableau"
              onClick={handleTableauClick}
              icon={<OpenInWindow size={16} />}
              label={tableauLinkLabel}
            />
          )}
          {/* Help Dropdown Menu */}
          {shouldShouldHelpNavMenu ? (
            <NavbarUserProfile>
              <NavbarDropdownMenuTrigger>
                <NavbarItem
                  id="Help"
                  icon={<Help size={16} className={helpIconStyles} />}
                  label={helpText}
                  onClick={() => {}}
                />
              </NavbarDropdownMenuTrigger>
              {supportTicketData && (
                <>
                  <UserProfileMenuItem
                    onSelect={value => createWorkflowOnSelect(value)}
                    icon={<Book size={12} />}
                    text={supportTicketData.label}
                    value={CERTA_SUPPORT}
                  />
                </>
              )}
              {showHeaderDropdownOptions.length > 0
                ? showHeaderDropdownOptions.map(
                    (option: DropdownOption, index: number) => (
                      <UserProfileMenuItem
                        key={index}
                        text={option.label}
                        value={option?.url || ""}
                        onSelect={value => window.open(value)}
                      />
                    )
                  )
                : null}
              {shouldShowHeaderLinks
                ? headerLinks.map((link, index) => (
                    <UserProfileMenuItem
                      key={link.url}
                      text={
                        getIntlBody(link, "label") ||
                        getIntlBody(link, "label_en")
                      }
                      value={link.url}
                      onSelect={value => onSelectLink(value, index)}
                    />
                  ))
                : null}
              {shouldShowFooterLinks
                ? footerLinks.map((link, index) => (
                    <UserProfileMenuItem
                      key={link.url}
                      text={
                        getIntlBody(link, "label") ||
                        getIntlBody(link, "label_en")
                      }
                      value={link.url}
                      onSelect={value => onSelectLink(value, index)}
                    />
                  ))
                : null}
            </NavbarUserProfile>
          ) : null}
          {/* User profile Dropdown Menu */}
          <NavbarUserProfile onClick={handleUserProfileMenuClick}>
            <NavbarDropdownMenuTrigger>
              <NavbarUserProfileTrigger
                email={userEmail}
                name={userName}
                onClick={() => handleMixPanelEventsForNav("Profile")}
                key={"user-profile-menu"}
                avatar={
                  <UserAvatar
                    userName={userName || userEmail}
                    size={AvatarSizes.SMALL}
                    color={AvatarColors.NEUTRAL_600}
                  />
                }
              />
            </NavbarDropdownMenuTrigger>
            {userProfileId ? (
              <UserProfileMenuItem
                icon={<User size={12} />}
                onSelect={goToUserProfile}
                text={myProfileText}
                value={"profile"}
              />
            ) : null}
            <UserProfileMenuItem
              icon={<Envelope size={12} />}
              onSelect={goToScheduledReports}
              text={intl.formatMessage({
                id: "report.mySubscriptions",
                defaultMessage: "My Scheduled Reports"
              })}
              value="my-scheduled-reports"
            />
            {canAccessInstanceSettings && (
              <UserProfileMenuItem
                icon={<Gear size={12} />}
                onSelect={goToInstanceSettings}
                text={instanceSettingsText}
                value="instance-settings"
              />
            )}
            {canUserViewAs && hasPermissionToImpersonate && (
              <UserProfileMenuItem
                icon={<Eye size={12} />}
                onSelect={() => {}}
                text={intl.formatMessage({
                  id: "loginPageInstances.viewAs",
                  defaultMessage: "View As"
                })}
                value="view-as"
              >
                <UserProfileSubMenuItemTrigger
                  icon={<Eye size={12} />}
                  text={intl.formatMessage({
                    id: "loginPageInstances.viewAs",
                    defaultMessage: "View As"
                  })}
                />
                {usersByKind.map(user => (
                  <UserProfileMenuItem
                    onSelect={() => {
                      if (user.kind === "Client") {
                        handleMixPanelEventsForNav("Impersonate Internal User");
                      } else {
                        handleMixPanelEventsForNav("Impersonate External User");
                      }
                    }}
                    text={toCapital(user.name)}
                    value={user.name}
                    key={user.name}
                  >
                    <DropdownSubMenu onOpenChange={handleSubmenuOpenChange}>
                      <DropdownSubMenuTrigger>
                        {user.name}
                      </DropdownSubMenuTrigger>
                      <ImpersonationUsersList
                        kind={user.kind}
                        isOpen={isSubmenuOpen}
                      />
                    </DropdownSubMenu>
                  </UserProfileMenuItem>
                ))}
              </UserProfileMenuItem>
            )}
            <UserProfileMenuItem
              icon={<Language size={12} />}
              onSelect={() => {}}
              text={languageText}
              value="language"
            >
              <UserProfileSubMenuItemTrigger
                icon={<Language size={12} />}
                text={`${languageText}: ${preferredLanguage.toUpperCase()}`}
              />
              {supportedLanguages.map(language => (
                <UserProfileMenuItem
                  checked={preferredLanguage === language.value}
                  onSelect={() => {
                    MixPanelActions.track(
                      MixPanelEvents.navbar
                        .NAV_PROFILE_LANGUAGE_CLICK_SWITCH_LANGUAGE,
                      { language: language.value }
                    );
                    handleLanguageChange(language.value);
                  }}
                  text={renderLanguageMenuItem(language)}
                  value={language.value}
                  key={language.value}
                />
              ))}
            </UserProfileMenuItem>
            <UserProfileMenuItem
              onSelect={() => {}}
              text={logOutMessage}
              value="logout"
              preventDropdownClose // Req on logout action, if dropdown closes then it cause double redirection
            >
              <>
                <div className={dividerStyles} />
                <div onClick={handleLogout} className={logOutStyles}>
                  <Typography
                    variant={TypographyVariants.LABEL_SM}
                    color={TypographyColors.NEUTRAL_600}
                  >
                    {logOutMessage}
                  </Typography>
                </div>
              </>
            </UserProfileMenuItem>
          </NavbarUserProfile>
        </NavbarFooter>
      </Navbar>
      <MySubscriptions
        showMySubscriptionsDialog={shouldShowMySubscriptions}
        setShowMySubscriptionsDialog={setShouldShowMySubscriptions}
      />
      <InstanceSettings
        showInstanceSettingsDialog={shouldShowInstanceSettings}
        setShowInstanceSettingsDialog={setShouldShowInstanceSettings}
      />
      {!isSearchPage ? <SearchDropdown /> : null}
    </>
  );
};

// Explicitly defining the width and height of the icons
// to avoid the icon size changing when the text is long
const helpIconStyles = css({
  "> svg": {
    width: "16px",
    height: "16px"
  }
});

export const dividerStyles = css({
  height: "1px",
  width: "100%",
  margin: "var(--space-4) 0",
  backgroundColor: "var(--colors-neutral-400)"
});

export const logOutStyles = css({
  cursor: "pointer",
  marginBottom: "var(--space-4)",
  padding: "var(--space-12) var(--space-12)",
  "&:hover": {
    backgroundColor: "var(--colors-neutral-300)"
  }
});

export { NavigationSidebar };
