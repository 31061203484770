import React from "react";
import { cx, css } from "emotion";
import { Divider as AntDivider } from "antd";
import type { DividerProps } from "antd/lib/divider";

type Props = DividerProps & {};

export const Divider = ({ className, ...otherProps }: Props) => {
  return (
    <AntDivider
      className={cx(
        css`
          &.ant-divider {
            background-color: var(--neutral-20);
          }
          &.ant-divider-horizontal {
            margin: var(--s3) 0;
          }
        `,
        className
      )}
      {...otherProps}
    />
  );
};
