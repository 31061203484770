export const getAvatarLetters = (username: string) => {
  const nameList = username?.split(" ") || [];
  let letterList: string[] = [];

  if (nameList.length > 1) {
    letterList = [nameList[0], nameList[nameList.length - 1]].map(n => n[0]);
  } else if (nameList.length === 1) {
    letterList = [nameList[0].slice(0, 2)];
  }

  return letterList.join("").toUpperCase();
};
