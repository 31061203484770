import { get as lodashGet } from "lodash-es";
import { createSelector } from "reselect";
import { languageConstants } from "../../js/constants";
import { IntlLoader } from "../../translations";
import memoizeOne from "memoize-one";
import { kindsSelector } from "../dashboard/selectors";
import { FEATURE_FLAGS } from "./constants";

const specialFeatures = state =>
  lodashGet(state, "config.custom_ui_labels.specialFeatures", []);

/**
 * To check whether new step groups API is enabled or not
 * @param {object} state Redux State
 * @returns {boolean} returns true if feature is enabled
 */

export const isNewStepGroupsAPIEnabled = createSelector(
  specialFeatures,
  features => features.includes("NEW_STEP_GROUPS_API")
);

/**
 * To check whether Actvity Logs are grouped by request_id or not
 * @param {object} state Redux State
 * @returns {boolean} returns true if feature is enabled
 * @deprecated This feature is no longer in use
 */
export const isCERTA2Enabled = createSelector(specialFeatures, features =>
  features.includes(FEATURE_FLAGS.NEW_UI_ENABLED)
);

/**
 * To check whether kind is selected or not
 * @param {object} state Redux State
 * @returns {number} returns the selected kind value
 */
export const getSelectedKind = state => {
  return lodashGet(
    state,
    "workflowList.selectedWorkflowFilters.KIND_FILTER.value",
    null
  );
};

export const getCurrentLangauge = state => {
  return lodashGet(state, "languageSelector.language", "en");
};

const getCurrentUserGroups = state =>
  lodashGet(state, "authentication.user.groups", []);

export const isSupplierUser = state => {
  return lodashGet(state, "authentication.user.kind", 0) === 2;
};

/**
 * Provides a Set<Numner> of current user's user group IDs.
 * @returns {Set<Number>} Set of current user's user-group IDs
 */
export const getCurrentUserGroupIDs = createSelector(
  getCurrentUserGroups,
  userGroups => new Set(userGroups.map(group => group.id))
);

// Helper selectors for getPreferredLocale
const getUserPreferredLanguage = state =>
  lodashGet(state, "authentication.user.prefered_language", null);
const getSelectedLanguage = state =>
  lodashGet(state, "languageSelector.language", null);

/**
 * Provides best locale to use based on current params. Not to be used as
 * users current language, but more of what language the UI is using.
 * @returns {string} Locale
 */
export const getPreferredLocale = createSelector(
  getUserPreferredLanguage,
  getSelectedLanguage,
  (userLanguage, selectedLanguage) => {
    // First we pick the best possible locale based on priority
    let locale = (
      selectedLanguage ||
      userLanguage ||
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage ||
      languageConstants.DEFAULT_LOCALE
    ).toLowerCase();

    // Checking locale compatibility with our platform
    if (!IntlLoader[locale]) {
      // If the user picked a regional language, that is not supported by platform,
      // let's pick the non-regional variant of that language, if available.
      // This means that if user picked en-gb but we don't support en-gb, it'll look
      // for the support of en.
      const secondaryLocale = locale.split("-")[0];

      // And even the if that's not supported, we'll fallback to the ultimate
      // default locale, which we know the platform supports for sure.
      if (locale === secondaryLocale || !IntlLoader[secondaryLocale]) {
        // Load language for default locale if any unsupported language is being loaded
        locale = languageConstants.DEFAULT_LOCALE;
      } else {
        locale = secondaryLocale;
      }
    }

    return locale;
  }
);

/**
 * To retrive the currently loaded workflow's ID
 * @param {object} state
 */
export const getCurrentWorkflowId = state =>
  lodashGet(state, "workflowDetailsHeader.workflowDetailsHeader.id", null);

export const currentWorkflowSelector = state =>
  lodashGet(state, "workflowDetailsHeader.workflowDetailsHeader", {});

export const currentWorkflowRelatedKindsSelector = state =>
  lodashGet(
    state.workflowDetailsHeader,
    `workflowDetailsHeader.definition.related_types`,
    []
  );

export const permissionSelector = state =>
  lodashGet(state, "permissions.permissions", []);

export const availableStatuesForKindIdSelector = memoizeOne(kindId =>
  createSelector(kindsSelector, allKinds => {
    if (!allKinds.data) return [];
    const requiredKind = allKinds.data.find(kind => kind.id === kindId);
    if (!requiredKind) return [];
    return requiredKind.availableStatuses;
  })
);

/**
 * Gets the list of workflow kinds that are specific to self-service.
 * @param {any} state
 */
const ssWorkflowKinds = state =>
  lodashGet(state, "config.custom_ui_labels.ss_workflow_kinds", []);

/**
 * Checks if a workflow kind is self-service specific, which can later be used to
 * enable/disable elements as per requirement.
 * @returns {boolean} true, if the kind of a found in the ss_workflow_kinds list
 */
export const isSSWorkflowKind = createSelector(
  ssWorkflowKinds,
  kindsSelector,
  currentWorkflowSelector,
  (ssWorkflowKinds, allKinds, currentWorkflow) => {
    if (!allKinds.data) return false;
    // find kindTag from all kinds
    const workflowKindId = lodashGet(currentWorkflow, "definition.kind");
    const kindTag = allKinds.data.find(kind => kind.id === workflowKindId);
    return !!kindTag && ssWorkflowKinds.includes(kindTag.tag);
  }
);
