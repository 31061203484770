import styled from "@emotion/styled";
import type { FC, ReactNode, ReactElement } from "react";
import React from "react";
import { Modal as AntModal } from "antd";
import type { ModalProps as AntModalProps } from "antd/lib/modal/index";
import { Stack } from "../Stack";
import { Text } from "../Typography";
import { HoverAbleSVG } from "../HoverableSVG";
import { Close } from "@certa/icons";
import type { ButtonProps } from "../Button/";
import { Button } from "../Button/";
import { LAYOUT_ID, Z_INDEX } from "../../constants";
import { canSeeCERTA2 } from "@certa/common/src/utils/routes";
import { getLayoutContainer } from "../../utils";

export type ModalProps = {
  /** @deprecated This prop is no longer in use. */
  isCERTA2Enabled?: boolean;
  okType?: ButtonProps["type"];
  cancelButtonProps?: ButtonProps;
  okButtonProps?: ButtonProps;
  hideHeader?: boolean;
  hideFooter?: boolean;
  children?: ReactNode;
  description?: ReactNode;
  /**
   * Reverses the placement order of action buttons. When 'false', the button order is
   * [Cancel button, Ok button] and when 'true', it will become [Ok button, Cancel button].
   */
  reverseButtonOrder?: boolean;
} & Omit<AntModalProps, "okType" | "cancelButtonProps" | "okButtonProps">;

const CustomModal: FC<ModalProps> = props => {
  const designSystemContainer = document.getElementById(LAYOUT_ID);
  const { isCERTA2Enabled, className, ...modalProps } = props;

  const isNewUI =
    (isCERTA2Enabled ?? canSeeCERTA2()) && !!designSystemContainer;

  if (!isNewUI) {
    return <AntModal {...modalProps} />;
  }

  const {
    title,
    description,
    children,
    footer,
    closable = true,
    width,
    hideHeader,
    hideFooter,
    onCancel,
    cancelText = "Cancel",
    cancelButtonProps,
    onOk,
    okText = "OK",
    okType = "primary",
    okButtonProps,
    confirmLoading,
    centered = false,
    destroyOnClose = true,
    zIndex,
    getContainer,
    reverseButtonOrder = false,
    ...restProps
  } = modalProps;

  return (
    <AntModal
      className={className}
      zIndex={zIndex ?? Z_INDEX["zindex-modal"]}
      maskStyle={{
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        zIndex: zIndex ? zIndex - 20 : Z_INDEX["zindex-modal-backdrop"]
      }}
      width={width}
      title={
        hideHeader
          ? null
          : title && (
              <Stack direction="vertical">
                <Text
                  variant="h1-bold"
                  color="neutral-100"
                  className="certa-modal-title"
                >
                  {title}
                </Text>
                {description && (
                  <Text
                    variant="p1-regular"
                    color="neutral-70"
                    className="certa-modal-description"
                  >
                    {description}
                  </Text>
                )}
              </Stack>
            )
      }
      footer={
        hideFooter ? null : footer === undefined && (onOk || onCancel) ? (
          <Stack
            gap="s2"
            justify={reverseButtonOrder ? "flex-start" : "flex-end"}
            reverse={reverseButtonOrder}
          >
            <Button onClick={onCancel} {...cancelButtonProps}>
              {cancelText}
            </Button>
            <Button
              type={okType}
              onClick={onOk}
              loading={confirmLoading}
              {...okButtonProps}
            >
              {okText}
            </Button>
          </Stack>
        ) : (
          footer
        )
      }
      closeIcon={
        <HoverAbleSVG
          data-testid="modalCloseIcon"
          onClick={onCancel}
          icon={Close}
          defaultSVGColor="brand-15"
          hoverBackground="brand-35"
        />
      }
      closable={closable}
      centered={centered}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={confirmLoading}
      getContainer={getContainer || getLayoutContainer}
      destroyOnClose={destroyOnClose}
      transitionName="fade"
      maskTransitionName="fade"
      {...restProps}
    >
      <Stack direction="vertical" gap="s4" data-testid="modalOverlayContent">
        <Stack direction="vertical">
          {/*This is done to pass down the prop onCancel to the child component */}
          {React.Children.map(
            children,
            child =>
              // For handling the case the child component is null.
              child &&
              React.cloneElement(child as ReactElement, { onCancel: onCancel })
          )}
        </Stack>
      </Stack>
    </AntModal>
  );
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Dialog } from '@certa/catalyst';
 */
export const Modal = styled(CustomModal)`
  .ant-modal {
    padding-top: var(--s6);
    padding-bottom: var(--s6);
    color: var(--neutral-100);
    top: 116px;
  }

  .ant-modal-content {
    box-shadow: var(--popover-shadow);
    border-radius: var(--big-border-radius);
    padding: var(--s6);

    .ant-modal-close {
      top: var(--s6);
      right: var(--s6);

      .ant-modal-close-x {
        width: var(--s6);
        height: var(--s6);
        line-height: var(--s6);
      }
    }

    .ant-modal-header {
      padding: var(--s00);
      margin-bottom: var(--s6);
      border: none;
      display: ${props => (props.hideHeader ? "none" : "block")};
      padding-right: ${props => (props.closable ? "var(--s8)" : "var(--s00)")};

      .certa-modal-description {
        margin-top: var(--s2);
      }
    }
    .ant-modal-body {
      padding: var(--s00);
    }
    .ant-modal-footer {
      padding: var(--s00);
      margin-top: var(--s6);
      border: none;
      display: ${props => (props.hideFooter ? "none" : "block")};
    }
  }
`;
