import React, { useMemo, memo } from "react";
import type {
  OptionProps,
  ClearIndicatorProps,
  ValueContainerProps,
  GroupProps,
  MenuListProps,
  ControlProps,
  GroupBase,
  IndicatorsContainerProps,
  NoticeProps
} from "react-select";
import { components } from "react-select";
import { Check, Clear } from "@certa/icons";
import type { SelectOption, ExtendedMenuProps } from "./GlobalSelect.types";
import {
  globalSelectMinSpaceContainer,
  globalSelectClearButton
} from "./GlobalSelect.styles";
import { Loader } from "@certa/blocks";
import { TypographyColors } from "@certa/catalyst";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

export const IndicatorsContainerComponent = memo(
  <T extends SelectOption>(
    props: IndicatorsContainerProps<T> & {
      inputValue: string;
      onClear: () => void;
    }
  ) => {
    const { inputValue, onClear, ...restProps } = props;
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      props.onClear();
    };
    return (
      <components.IndicatorsContainer {...restProps}>
        {props.inputValue.length > 0 ? (
          <div onClick={handleClick} className={globalSelectClearButton}>
            <Clear size={12} color={TypographyColors.NEUTRAL_500} />
          </div>
        ) : (
          <></>
        )}
      </components.IndicatorsContainer>
    );
  }
);

type OptionsComponentProps<T> = {
  handleScrolledToBottom?: () => void;
} & OptionProps<T>;

export const OptionComponent = memo(
  <T extends SelectOption>(props: OptionsComponentProps<T>) => {
    const { innerRef, options, data, handleScrolledToBottom, ...rest } = props;

    /*
     * Adding menuPosition = "fixed", is prohibiting from menu option to be focused
     * (which normally works by default while using react-select)
     * So for bringing focused option to view, we do two additonal actions
     * 1. For non virtualized select, we add scrollIntoView, in ref of option
     * 2. Adding scrollToIndex, for virtualized select
     *
     * You may think, wasn't action 1 enough ?
     * No, because, when we use virtualized select, the options are not rendered
     * hence we have to use scrollToIndex
     *
     * Note:
     * Please make a note here that if new options are generated
     * (i.e., if a new object reference is created on each render),
     * this will cause scrolling to the top on change.
     * Please use memoized options to avoid this.
     */
    const customInnerRef = (ref: HTMLDivElement | null) => {
      if (ref && props.isFocused) {
        ref.scrollIntoView({ block: "nearest" });
      }
    };

    return (
      <components.Option
        {...rest}
        options={options}
        data={data}
        innerRef={customInnerRef}
      >
        <div>
          <div>{props.children}</div>
          {props.isSelected && <Check size={12} />}
        </div>
      </components.Option>
    );
  }
) as typeof components.Option;

export const ControlComponent = memo(
  <T extends SelectOption>(
    props: ControlProps<T> & {
      leftIcon?: () => React.ReactElement;
    }
  ) => {
    const { leftIcon, innerProps, ...rest } = props;

    let title = "";
    const [labelAndValue] = props.getValue();

    if (
      labelAndValue &&
      labelAndValue.label &&
      typeof labelAndValue.label === "string"
    ) {
      title = labelAndValue.label;
    }

    const handleOnClick = () => {
      MixPanelActions.track(
        MixPanelEvents.homepageEvents.HOME_CLICK_GLOBAL_SEARCH
      );
    };

    return (
      <components.Control
        {...rest}
        innerProps={{ ...innerProps, title, onClick: handleOnClick }}
      >
        {props.children}
      </components.Control>
    );
  }
) as typeof components.Control;

export const ClearIndicatorComponent = memo(
  <T extends SelectOption>(props: ClearIndicatorProps<T>) => {
    return (
      <components.ClearIndicator {...props}>
        <Clear size={12} />
      </components.ClearIndicator>
    );
  }
) as typeof components.ClearIndicator;

export const ValueContainerComponent = memo(
  <T extends SelectOption>(props: ValueContainerProps<T>) => {
    return (
      <components.ValueContainer {...props}>
        {props.children}
      </components.ValueContainer>
    );
  }
) as typeof components.ValueContainer;

export const GroupComponent = memo(
  <T extends SelectOption>(props: GroupProps<T>) => {
    return <components.Group {...props} />;
  }
) as typeof components.Group;

type MenuListComponentProps<T> = {
  menuHeight?: number;
  menuWidth: number;
  children: React.ReactElement | React.ReactElement[];
  filteredOptions: SelectOption[];
  onScroll?: () => void;
} & MenuListProps<T>;

export const MenuListComponent = memo(
  <T extends SelectOption>(props: MenuListComponentProps<T>) => {
    const {
      menuHeight,
      menuWidth,
      children,
      filteredOptions,
      innerProps,
      onScroll,
      ...restProps
    } = props;

    const hasGroupedOptions = useMemo(
      () => !!props.options.find(option => (option as GroupBase<T>)?.options),
      [props.options]
    );

    return (
      <components.MenuList
        {...restProps}
        innerProps={{ ...innerProps, onScroll }}
      >
        {/*
          https://github.com/JedWatson/react-select/issues/3128
          - The onMouseMove and onMouseOver events are removed from the
            menu options to improve the performance of the react-select menu.
          - This fixes the lagging issue when hovering over the options.
         */}
        {hasGroupedOptions
          ? React.Children.map(children, (groupChild: React.ReactElement) => {
              if (Array.isArray(groupChild?.props?.children)) {
                React.Children.toArray(groupChild.props.children).forEach(
                  child => {
                    delete (child as React.ReactElement)?.props?.innerProps
                      ?.onMouseMove;
                    delete (child as React.ReactElement)?.props?.innerProps
                      ?.onMouseOver;
                  }
                );
                return groupChild;
              } else {
                delete groupChild?.props?.innerProps?.onMouseMove;
                delete groupChild?.props?.innerProps?.onMouseOver;
                return <div>{groupChild}</div>;
              }
            })
          : !!(children as React.ReactElement)?.key &&
              React.Children.toArray(children).length > 0
            ? React.Children.map(children, (child: React.ReactElement) => {
                delete child?.props.innerProps.onMouseMove;
                delete child?.props.innerProps.onMouseOver;
                return child;
              })
            : children}
      </components.MenuList>
    );
  }
) as React.FC<MenuListComponentProps<any>>;

export const MenuComponent = memo(
  <T extends SelectOption>(props: ExtendedMenuProps<T>) => {
    const hasGroupedOptions = useMemo(
      () => !!props.options.find(option => (option as GroupBase<T>)?.options),
      [props.options]
    );
    const { menuFooter, menuHeader, ...restProps } = props;

    return hasGroupedOptions ? (
      <components.Menu {...restProps}>
        {menuHeader}
        <div>{props.children}</div>
        {menuFooter}
      </components.Menu>
    ) : (
      <components.Menu {...restProps}>
        {menuHeader}
        <div>{props.children}</div>
        {menuFooter}
      </components.Menu>
    );
  }
) as typeof components.Menu;

export const LoadingMessageComponent = () => {
  return (
    <div className={globalSelectMinSpaceContainer}>
      <Loader />
    </div>
  );
};

export const NoOptionsMessageComponent = (
  props: NoticeProps<SelectOption, boolean, GroupBase<SelectOption>>
) => {
  return (
    <div className={globalSelectMinSpaceContainer}>
      <components.NoOptionsMessage {...props} />
    </div>
  );
};
