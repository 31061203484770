import { FieldTypes } from "@certa/types";

type SystemFieldsDetails = {
  sourceMapping: SourceMapping;
  label: string;
  fieldType: FieldTypes;
};
const systemFieldsSystemFieldsDetails: SystemFieldsDetails[] = [
  {
    sourceMapping:
      "workflow.created_by.profile.business_unit_full_access.all.name",
    label:
      "Business unit(s) assigned to the user who created the workflow (Full Access)",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping:
      "workflow.created_by.profile.business_read_full_access.all.name",
    label:
      "Business unit(s) assigned to the user who created the workflow (Read Access)",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "step.completed_by.email",
    label: "Email of User who completed the step",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "step.completed_by.username",
    label: "Username of User who completed the step",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "step.completed_by.first_name",
    label: "First Name of User who completed the step",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "step.completed_by.last_name",
    label: "Last Name of User who completed the step",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "step.completed_by",
    label: "User who completed the step",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping:
      "workflow.created_by.profile.region_full_access.all.name_std",
    label:
      "Region(s) assigned to the user who created the workflow (Full Access)",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping:
      "workflow.created_by.profile.region_read_access.all.name_std",
    label:
      "Region(s) assigned to the user who created the workflow (Read Access)",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "step.completed_at.isoformat_naive_date",
    label: "Step Completion Date",
    fieldType: FieldTypes.NAIVE_DATE
  },
  {
    sourceMapping: "step.completed_at.isoformat",
    label: "Step Completion Date and Time",
    fieldType: FieldTypes.DATE
  },
  {
    sourceMapping: "step.definition_id",
    label: "Step Definition ID and Step Name",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "step.is_enabled",
    label: "Step Enabled/Visible",
    fieldType: FieldTypes.BOOL
  },
  {
    sourceMapping: "step.id",
    label: "Step ID",
    fieldType: FieldTypes.INTEGER
  },
  {
    sourceMapping: "step.initiated_at.isoformat_naive_date",
    label: "Step Initiation Date",
    fieldType: FieldTypes.NAIVE_DATE
  },
  {
    sourceMapping: "step.initiated_at.isoformat",
    label: "Step Initiation Date and Time",
    fieldType: FieldTypes.DATE
  },
  {
    sourceMapping: "step.updated_at.isoformat_naive_date",
    label: "Step Last Updated Date",
    fieldType: FieldTypes.NAIVE_DATE
  },
  {
    sourceMapping: "step.updated_at.isoformat",
    label: "Step Last Updated Date and Time",
    fieldType: FieldTypes.DATE
  },
  {
    sourceMapping: "step.is_locked",
    label: "Step Locked",
    fieldType: FieldTypes.BOOL
  },
  {
    sourceMapping: "step.overdue",
    label: "Step Overdue",
    fieldType: FieldTypes.BOOL
  },
  {
    sourceMapping: "step.submit_count",
    label: "Step Submission Count",
    fieldType: FieldTypes.INTEGER
  },
  {
    sourceMapping: "step.step_group_id",
    label: "Step Group ID and Stepgroup Name",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "step.step_group.id",
    label: "Step Group ID",
    fieldType: FieldTypes.INTEGER
  },
  {
    sourceMapping: "workflow.name",
    label: "Workflow Name",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.created_by.groups.all.name",
    label: "User role(s) assigned to the user who created the workflow",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.business_unit.name",
    label: "Workflow Business Unit",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.created_by.email",
    label: "Workflow Created By - User Email",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.created_by.get_full_name",
    label: "Workflow Created By - User Name",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.created_at.isoformat_naive_date",
    label: "Workflow Creation Date",
    fieldType: FieldTypes.NAIVE_DATE
  },
  {
    sourceMapping: "workflow.created_at.isoformat",
    label: "Workflow Creation Date and Time",
    fieldType: FieldTypes.DATE
  },
  {
    sourceMapping: "workflow.definition",
    label: "Workflow Definition / Version ID",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.uid",
    label: "Workflow Ext UID",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.id",
    label: "Workflow ID",
    fieldType: FieldTypes.INTEGER
  },
  {
    sourceMapping: "step.workflow",
    label: "Workflow ID and Workflow Name",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.region.name_std",
    label: "Workflow Region",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.status.label",
    label: "Workflow Status",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.updated_at.isoformat",
    label: "Workflow Updated Timestamp",
    fieldType: FieldTypes.DATE
  },
  {
    sourceMapping: "workflow.content_object.date_joined",
    label: "Date Joined",
    fieldType: FieldTypes.DATE
  },
  {
    sourceMapping: "workflow.content_object.last_login",
    label: "Last Login",
    fieldType: FieldTypes.DATE
  },
  {
    sourceMapping:
      "workflow.content_object.profile.business_unit_read_access.all.name",
    label: "User Business Unit - Read Access",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping:
      "workflow.content_object.profile.business_unit_full_access.all.name",
    label: "User Business Unit - Full Access",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping:
      "workflow.content_object.profile.region_read_access.all.name_std",
    label: "User Regions - Read Access",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping:
      "workflow.content_object.profile.region_full_access.all.name_std",
    label: "User Regions - Full Access",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.content_object.groups.all.permissions__codename",
    label: "User Permissions - Groups",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.content_object.groups.all.permissions__name",
    label: "User Group Permissions",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.content_object.user_permissions.all.name",
    label: "User Permissions",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "field.workflow.emails.latest.subject",
    label: "The Subject Of The Latest Email Sent",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "field.workflow.emails.latest.status",
    label: "The Status Of The Latest Email Sent",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "field.workflow.emails.latest.email_id",
    label: "The Email-id To Which The Latest Email Is Sent Out",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "field.workflow.emails.latest.smtp_status_code",
    label:
      "The Status Code Of An Email In Case Of Bounced, Failed (Visible Only After 72 Hours)",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "field.workflow.emails.latest.reject_reason",
    label:
      "The Reject Reason Of An Email In Case Of Bounced, Failed (Visible Only After 72 Hours)",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "lc_message",
    label: "LC Message Showing Status Of Latest Email ",
    fieldType: FieldTypes.TEXT
  },
  {
    sourceMapping: "workflow.created_by.user_permissions.all.name",
    label: "Permissions Of The User Who Created The Workflow",
    fieldType: FieldTypes.TEXT
  }
];

export const systemFieldsDetailsMapping =
  systemFieldsSystemFieldsDetails.reduce(
    (acc, curr) => {
      acc[curr.sourceMapping] = curr;
      return acc;
    },
    {} as Record<SourceMapping, SystemFieldsDetails>
  );

export type SourceMapping =
  | "workflow.created_by.profile.business_unit_full_access.all.name"
  | "workflow.created_by.profile.business_read_full_access.all.name"
  | "step.completed_by.email"
  | "step.completed_by.username"
  | "step.completed_by.first_name"
  | "step.completed_by.last_name"
  | "step.completed_by"
  | "workflow.created_by.profile.region_full_access.all.name_std"
  | "workflow.created_by.profile.region_read_access.all.name_std"
  | "step.completed_at.isoformat_naive_date"
  | "step.completed_at.isoformat"
  | "step.definition_id"
  | "step.is_enabled"
  | "step.id"
  | "step.initiated_at.isoformat_naive_date"
  | "step.initiated_at.isoformat"
  | "step.updated_at.isoformat_naive_date"
  | "step.updated_at.isoformat"
  | "step.is_locked"
  | "step.overdue"
  | "step.submit_count"
  | "step.step_group_id"
  | "step.step_group.id"
  | "workflow.name"
  | "workflow.created_by.groups.all.name"
  | "workflow.business_unit.name"
  | "workflow.created_by.email"
  | "workflow.created_by.get_full_name"
  | "workflow.created_at.isoformat_naive_date"
  | "workflow.created_at.isoformat"
  | "workflow.definition"
  | "workflow.uid"
  | "workflow.id"
  | "step.workflow"
  | "workflow.region.name_std"
  | "workflow.status.label"
  | "workflow.updated_at.isoformat"
  | "workflow.content_object.date_joined"
  | "workflow.content_object.last_login"
  | "workflow.content_object.profile.business_unit_read_access.all.name"
  | "workflow.content_object.profile.business_unit_full_access.all.name"
  | "workflow.content_object.profile.region_read_access.all.name_std"
  | "workflow.content_object.profile.region_full_access.all.name_std"
  | "workflow.content_object.groups.all.permissions__codename"
  | "workflow.content_object.groups.all.permissions__name"
  | "workflow.content_object.user_permissions.all.name"
  | "field.workflow.emails.latest.subject"
  | "field.workflow.emails.latest.status"
  | "field.workflow.emails.latest.email_id"
  | "field.workflow.emails.latest.smtp_status_code"
  | "field.workflow.emails.latest.reject_reason"
  | "lc_message"
  | "workflow.created_by.user_permissions.all.name";
