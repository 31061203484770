import { configConstants } from "../constants";
import { configService } from "../services";
import { setCurrentDashboardView } from "../../modules/dashboard/actionCreators";
import { DASHBOARD_VIEWS } from "../../modules/dashboard/constants";
import { get as lodashGet } from "lodash-es";

export const configActions = {
  getConfig
};

function getConfig() {
  return dispatch => {
    dispatch(loading());
    configService.getConfig().then(
      config => {
        const isPersonalisedHomepageVisible = lodashGet(
          config,
          "custom_ui_labels.specialFeatures",
          []
        ).includes("PERSONALISED_HOMEPAGE");
        dispatch(success(config));
        dispatch(
          setCurrentDashboardView(
            isPersonalisedHomepageVisible
              ? DASHBOARD_VIEWS.MY_STUFF
              : DASHBOARD_VIEWS.KIND_VIEW
          )
        );
      },
      error => {
        if (error && error?.status === 503) {
          error.res.json().then(res =>
            dispatch(
              maintananceError({
                showMaintanancePage: true,
                maintananceData: res
              })
            )
          );
        } else {
          dispatch(failure(error));
        }
      }
    );
  };

  function loading() {
    return { type: configConstants.CONFIG_REQUEST };
  }
  function success(config) {
    return { type: configConstants.CONFIG_SUCCESS, config };
  }
  function failure(error) {
    return { type: configConstants.CONFIG_FAILURE, error };
  }
  function maintananceError(maintananceErrorData) {
    return {
      type: configConstants.CONFIG_MAINTANANCE_FAILURE,
      maintananceErrorData
    };
  }
}
