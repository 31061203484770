// TODO: Tree shake
import React from "react";
import moment from "moment";
import { FILTERS_ENUM } from "./constants";
import { CountWidget } from "./sidebar/components/CountWidget";
import { StyledColoredCountWidget } from "./sidebar/components/styledComponents";
import { useIntl } from "react-intl";

const supportedFiltersForSeachParams = [
  FILTERS_ENUM.SEARCH_FILTER.name,
  FILTERS_ENUM.STATUS_FILTER.name,
  FILTERS_ENUM.BUSINESS_UNIT_FILTER.name,
  FILTERS_ENUM.REGION_FILTER.name,
  FILTERS_ENUM.ADVANCED_FILTER.name,
  FILTERS_ENUM.ADVANCED_FILTER.name,
  FILTERS_ENUM.KIND_FILTER.name,
  FILTERS_ENUM.START_DATE_FILTER.name,
  FILTERS_ENUM.END_DATE_FILTER.name
];

export const getOccurrenceDay = occurrence => {
  const today = moment().startOf("day");
  const thisWeek = moment().startOf("week");
  const thisMonth = moment().startOf("month");

  if (moment(occurrence.createdAt).isAfter(today)) {
    return "commonTextInstances.today";
  }
  if (moment(occurrence.createdAt).isAfter(thisWeek)) {
    return "commonTextInstances.thisWeek";
  }
  if (moment(occurrence.createdAt).isAfter(thisMonth)) {
    return "commonTextInstances.thisMonth";
  }
  return moment(occurrence.createdAt).format("MMM");
};

// Private function to get Rank
const getRank = ({ page, index, count, isAscending }) => {
  if (isAscending) {
    return (page - 1) * 20 + index + 1;
  } else {
    return count - (page - 1) * 20 - index;
  }
};

export const appendRankInWorkflowItem = (
  { results: workflows = [], next, count, ...rest },
  filterParams
) => {
  if (!Array.isArray(workflows)) {
    console.warn(
      `appendRankInWorkflowItem: Expected an array recieved ${typeof workflows}`
    );
    return workflows;
  }

  const page = filterParams[FILTERS_ENUM.PAGE_FILTER.key] || 1;
  const sortingOrder = filterParams[FILTERS_ENUM.ORDERING_FILTER.key];
  const workflowWithRank = workflows.map((workflow, index) => ({
    ...workflow,
    rank: sortingOrder
      ? getRank({
          page,
          index,
          count,
          isAscending: !sortingOrder.startsWith("-")
        })
      : null
  }));
  return {
    next,
    count,
    results: workflowWithRank,
    ...rest
  };
};

export function createFilterParams(selectedFilters) {
  const params = {};

  for (const filterName in selectedFilters) {
    const { key, value, meta } = selectedFilters[filterName];
    params[key] = value;

    /**
     * Since the filters are storing kind tag instead of kind id every where so
     * applying this check to make export worflow api work which is a v1 api
     * and still used kind id instead of kind tag as payload
     */
    if (key === FILTERS_ENUM.KIND_FILTER.key) {
      params["kind"] = meta.id;
    }
  }

  // Removing kind filter param for search and my task
  // It needs excluded only at the time of filtering, not from the UI state
  if (
    params[FILTERS_ENUM.MY_TASK_FILTER.key] ||
    // Only remove the kind_tag filter when
    // using text search and REMOVE_KIND_FILTER is applied
    (params[FILTERS_ENUM.SEARCH_FILTER.key] &&
      params[FILTERS_ENUM.REMOVE_KIND_FILTER.key])
  ) {
    delete params[FILTERS_ENUM.KIND_FILTER.key];
  }

  // Not required in the API
  delete params[FILTERS_ENUM.REMOVE_KIND_FILTER.key];

  return params;
}

export function createFiltersFromSearchParams(params) {
  const searchParams = new URLSearchParams(params);
  const selectedFilters = [];

  for (const filter of supportedFiltersForSeachParams) {
    const filterObj = FILTERS_ENUM[filter];
    const value = searchParams.get(filterObj.key);
    if (value) {
      selectedFilters.push({
        key: decodeURIComponent(filterObj.key),
        value: decodeURIComponent(value),
        name: filterObj.name,
        meta: {}
      });
    }
  }

  return selectedFilters;
}

export function createSearchParams(selectedFilters) {
  const params = {};

  for (const filterName in selectedFilters) {
    const { key, value, name } = selectedFilters[filterName];
    if (supportedFiltersForSeachParams.includes(name) && key && value) {
      params[encodeURIComponent(key)] = encodeURIComponent(value);
    }
  }

  return new URLSearchParams(params).toString();
}

// TODO: Remove the usage of `translated_label` from this function
// when PR #2525 is merged
// since, it will remove the `WorkflowItem` components
// and `MyStuffWorkflowItem` component's lcData is already translated from modelCreator
export function lcDataToAlerts(lcData) {
  // extract alerts from LC Data
  const alertsLCData = lcData.filter(
    lcData =>
      (lcData.display_type === "alert" ||
        lcData.display_type === "alert_status") &&
      !!lcData.value &&
      lcData.value !== "0" &&
      !lcData.hide_from_workflow
  );
  // change the data structure according to the usual alerts and return
  return alertsLCData.map((lcAlert, index) => ({
    alert: {
      id: "lc_" + index,
      tag: lcAlert.value,
      label: lcAlert.translated_label || lcAlert.label,
      category: {
        color_label: lcAlert.color || "#999999" // default color will be grey
      }
    }
  }));
}

export function getRatingFromLCData(lcData) {
  return (
    lcData
      .map(item => ({
        ...item,
        label: item.translated_label || item.label
      }))
      .find(item => item.format === "rating") || null
  );
}

/**
 * Parent Alert color code logic:
 * Check visibility priority of all items in sub-category of alert is 0 or not, if yes
 * then use the parent color label. If no then sort the sub category array in
 * ascending order according to visibility priority and then get the color
 * of the child whose count is greater than 0 and has the highest
 * vibility priority(1 > 2 > 3 > ————— ∞)
 */
export const ParentAlertCount = React.memo(({ item }) => {
  const intl = useIntl();
  const children = item.sub_categories;
  const childrenWithout0Priority = children.filter(
    child => child.visibility_priority !== 0
  );

  if (!childrenWithout0Priority.length) {
    // Return parent color
    return <CountWidget innerColour={item.color_label} value={item.count} />;
  }

  const sortedChildren = [...childrenWithout0Priority].sort(
    (a, b) => a.visibility_priority - b.visibility_priority
  );

  const firstChildWithCount = sortedChildren.find(child => child.count > 0);

  // Show the color from first child with non-zero count and non-zero highest visibility priority
  if (firstChildWithCount) {
    return (
      <StyledColoredCountWidget background={firstChildWithCount.color_label}>
        {intl.formatNumber(item.count)}
      </StyledColoredCountWidget>
    );
  }

  return null;
});

export function convertAlertToNewResponseModel(alerts) {
  const modifiedAlert = alerts.map(alert => {
    return {
      id: alert.id,
      fieldId: alert.field,
      stepId: alert.step,
      stepGroupId: alert.step_group,
      workflowId: alert.workflow,
      catColorLabel: alert.alert.category.color_label,
      alertTag: alert.alert.label
        ? `${alert.alert.label}: ${alert.alert.tag}`
        : alert.alert.tag
    };
  });

  return modifiedAlert;
}
