import { FieldTypes } from "@certa/types";
import type { ColumnORM } from "@certa/types";
import type {
  ORMReportColumnIsAddedBy,
  ORMReportFilterConditionJoinRelation,
  ORMReportProcessAttr,
  ORMReportStepAttr,
  ORMReportStepGroupAttr
} from "../constants";
import {
  FETCH_STEP_ATTR,
  FETCH_STEPGROUP_ATTR,
  processAttrDataORM,
  FETCH_PROCESS_ATTR,
  INDEX_ZERO,
  INDEX_ONE,
  LAST_INDEX,
  ormStepGroupAttributes,
  ormStepAttributes,
  ormReportProcessAttr,
  ormStepLevelAttributeDetails,
  ormStepGroupLevelAttributeDetails
} from "../constants";

const STEP_ATTR_VALUE_TAG_MIN_LANGTH = 3;
export const createStepValueTag = (stepTeg: string, value: string) =>
  `${FETCH_STEP_ATTR}__${stepTeg}__${value}`;

const STEP_GROUP_ATTR_VALUE_TAG_MIN_LANGTH = 3;
export const createStepGroupValueTag = (stepGroupTeg: string, value: string) =>
  `${FETCH_STEPGROUP_ATTR}__${stepGroupTeg}__${value}`;

export const createProcessValueTag = (value: string) =>
  `${FETCH_PROCESS_ATTR}__${value}`;

/**
 * A type guard that checks if the value is a valid value of the ORMReportStepGroupAttr type
 */
export const isStepGroupAttrORM = (value: string) => {
  return ormStepGroupAttributes.includes(value as ORMReportStepGroupAttr);
};

/**
 * A type guard that checks if the value is a valid value of the ORMReportStepAttr type
 */
export const isStepAttrORM = (value: string) => {
  return ormStepAttributes.includes(value as ORMReportStepAttr);
};

/**
 * A type guard that checks if the fieldTag is a valid value of the ORMReportProcessAttr type
 */
export const isProcessAttrORM = (value: string) => {
  return ormReportProcessAttr.includes(value as ORMReportProcessAttr);
};

export const destructureStepValueTag = (tag: string) => {
  const splitTag = tag.split("__");
  if (
    !tag.includes(FETCH_STEP_ATTR) ||
    splitTag.length < STEP_ATTR_VALUE_TAG_MIN_LANGTH
  ) {
    return { stepTag: undefined, stepAttribute: undefined };
  }
  const stepAttribute = splitTag.slice(LAST_INDEX)[INDEX_ZERO];
  const stepTag = splitTag.slice(INDEX_ONE, LAST_INDEX).join("__");
  if (isStepAttrORM(stepAttribute)) {
    return { stepTag, stepAttribute: stepAttribute as ORMReportStepAttr };
  }
  return { stepTag, stepAttribute: undefined };
};

export const destructureStepGroupValueTag = (tag: string) => {
  const splitTag = tag.split("__");

  if (
    !tag.includes(FETCH_STEPGROUP_ATTR) ||
    splitTag.length < STEP_GROUP_ATTR_VALUE_TAG_MIN_LANGTH
  ) {
    return { stepGroupTag: undefined, stepGroupAttribute: undefined };
  }

  const stepGroupAttribute = splitTag.slice(LAST_INDEX)[INDEX_ZERO];
  const stepGroupTag = splitTag.slice(INDEX_ONE, LAST_INDEX).join("__");
  if (isStepGroupAttrORM(stepGroupAttribute)) {
    return {
      stepGroupTag,
      stepGroupAttribute: stepGroupAttribute as ORMReportStepGroupAttr
    };
  }
  return { stepGroupTag: undefined, stepGroupAttribute: undefined };
};

/**
 * This function destructure the process value tag and returns the fieldTag
 * if the fieldTag is a valid value of the ORMReportProcessAttr type
 * else it returns undefined. e.g. if the tag is "##fetch_process_attr##__name" then it will return "name"
 */
export const destructureProcessValueTag = (tag: string) => {
  const fieldTag = tag.replace(FETCH_PROCESS_ATTR + "__", "");
  if (isProcessAttrORM(fieldTag)) {
    return fieldTag as ORMReportProcessAttr;
  } else {
    return undefined;
  }
};

export const getCustomAttributeTag = (
  kindId: number | string | undefined,
  fieldTag: string
) => {
  if (!kindId) return fieldTag;
  return `##${kindId}##__${fieldTag}`;
};

const FIRST_PATTERN_INDEX = 1;
const SECOND_PATTERN_INDEX = 2;
export const destructureCustomAttributeTag = (tag: string | undefined) => {
  const pattern = /##([^#]+)##__(.+)$/;
  const matches = tag?.match(pattern);

  if (matches && !isNaN(Number(matches[FIRST_PATTERN_INDEX]))) {
    const kindId = matches[FIRST_PATTERN_INDEX];
    const fieldTag = matches[SECOND_PATTERN_INDEX];
    return { kindId, fieldTag };
  } else {
    return { kindId: undefined, fieldTag: tag ?? "" };
  }
};

/**
 * This function creates the hidden columns that supports the main column.
 * Like for parent's name column, we need id, lc_data and logo data.
 */

export const createHiddenColumnsORM = (
  kindId: number | undefined,
  ormColumns: ColumnORM[]
) => {
  if (!kindId) {
    return [];
  }
  const hiddenColumnsCustomTags = new Set<string>();
  const hiddenColumns: ColumnORM[] = [];
  const customTags = ormColumns.map(column => column.extraJSON.customTag);

  ormColumns.forEach(column => {
    if (
      kindId &&
      ((column.value === "name" && !column.joinRelation) ||
        column.value === "status_tag")
    ) {
      const {
        kindId,
        extraJSON: { addedBy }
      } = column;

      // add status_tag and created_at columns only for parent `name` columns
      if (column.value === "name" && !column.joinRelation) {
        const statusCustomTag = getCustomAttributeTag(
          kindId,
          createProcessValueTag("status_tag")
        );
        const hasStatusColumn = customTags.includes(statusCustomTag);
        if (
          kindId &&
          !hiddenColumnsCustomTags.has(statusCustomTag) &&
          !hasStatusColumn
        ) {
          hiddenColumnsCustomTags.add(statusCustomTag);
          const { joinRelation } = column;
          hiddenColumns.push(
            createProcessAttrColumn("status_tag", kindId, addedBy, joinRelation)
          );
        }

        const createdAtCustomTag = getCustomAttributeTag(
          kindId,
          createProcessValueTag("created_at")
        );
        const hasCreatedAtColumn = customTags.includes(createdAtCustomTag);
        if (
          kindId &&
          !hiddenColumnsCustomTags.has(createdAtCustomTag) &&
          !hasCreatedAtColumn
        ) {
          hiddenColumnsCustomTags.add(createdAtCustomTag);
          const { joinRelation } = column;
          hiddenColumns.push(
            createProcessAttrColumn("created_at", kindId, addedBy, joinRelation)
          );
        }
      }

      const idCustomTag = getCustomAttributeTag(
        kindId,
        createProcessValueTag("id")
      );
      const hasIdColumn = customTags.includes(idCustomTag);
      if (kindId && !hiddenColumnsCustomTags.has(idCustomTag) && !hasIdColumn) {
        hiddenColumnsCustomTags.add(idCustomTag);
        const { joinRelation } = column;
        hiddenColumns.push(
          createProcessAttrColumn("id", kindId, addedBy, joinRelation)
        );
      }
    } else if (column.value === "cycle_time") {
      const cycleTimeCustomTag = column.extraJSON.customTag;
      const isCycleRunningCustomTag = cycleTimeCustomTag.replace(
        "cycle_time",
        "is_cycle_running"
      );
      const isNotHavingCycleRunningColumn = !ormColumns.some(
        column => column.extraJSON.customTag === isCycleRunningCustomTag
      );
      if (isNotHavingCycleRunningColumn) {
        hiddenColumnsCustomTags.add(isCycleRunningCustomTag);
        hiddenColumns.push(
          createIsCycleRunningColumn(column, isCycleRunningCustomTag)
        );
      }
    }
  });
  return hiddenColumns;
};

/**
 * This function creates a column for a process attribute based on given tag and kindId.
 * The column is hidden by default. The column is assigned the specified is_added_by value.
 * The column is assigned the specified joinRelation if joinRelation is specified.
 */

export const createProcessAttrColumn = (
  tag: ORMReportProcessAttr,
  kindId: number,
  addedBy: ORMReportColumnIsAddedBy,
  joinRelation?: ORMReportFilterConditionJoinRelation
) => {
  const customTag = getCustomAttributeTag(kindId, createProcessValueTag(tag));
  const { label, value, fieldType } = processAttrDataORM[tag];
  const processColumn: ColumnORM = {
    kindId,
    label,
    value,
    type: "attr",
    extraJSON: {
      fieldType,
      isHidden: true,
      customTag,
      addedBy
    }
  };
  if (joinRelation) {
    processColumn.joinRelation = joinRelation;
  }
  return processColumn;
};

const createIsCycleRunningColumn = (
  cycleTimeColumn: ColumnORM,
  customTag: string
): ColumnORM => {
  const value = "is_cycle_running";
  let label = "Is Cycle Running";
  let fieldType = FieldTypes.BOOL;

  if (cycleTimeColumn.type === "attr") {
    const attributeDetails = processAttrDataORM[value];
    label = attributeDetails.label;
    fieldType = attributeDetails.fieldType;
  } else if (cycleTimeColumn.type === "step") {
    const attributeDetails = ormStepLevelAttributeDetails[value];
    label = createStepTagLabel(cycleTimeColumn.tag, attributeDetails.labelText);
    fieldType = attributeDetails.systemFieldType;
  } else if (cycleTimeColumn.type === "step_group") {
    const attributeDetails = ormStepGroupLevelAttributeDetails[value];
    label = createStepGroupTagLabel(
      cycleTimeColumn.tag,
      attributeDetails.labelText
    );
    fieldType = attributeDetails.systemFieldType;
  }

  return {
    ...cycleTimeColumn,
    value,
    label,
    extraJSON: {
      fieldType,
      isHidden: true,
      customTag,
      addedBy: "column"
    }
  };
};

export const createStepGroupTagLabel = (
  stepGroupLabel: string | undefined,
  stepGroupAttributeLabel: string
) => {
  if (stepGroupLabel === undefined) {
    return stepGroupAttributeLabel;
  }
  return stepGroupLabel + " - " + stepGroupAttributeLabel + " (step group)";
};

export const createStepTagLabel = (
  stepLabel: string | undefined,
  stepAttributeLabel: string
) => {
  if (stepLabel === undefined) {
    return stepAttributeLabel;
  }
  return stepLabel + " - " + stepAttributeLabel;
};

export const WF_ID_DATA_PATH = "wfIDDataPath";
