import type { ReactNode, FC, Ref, PropsWithChildren } from "react";
import { forwardRef } from "react";

import { Stack } from "@certa/blocks/thanos";

type WrapperWithIconProps = PropsWithChildren<{
  icon?: ReactNode;
  tabIndex?: number;
}>;

export const WrapperWithIcon: FC<WrapperWithIconProps> = forwardRef(
  (props, ref: Ref<HTMLDivElement>) => {
    const { children, icon, ...rest } = props;

    return (
      <Stack align="center" direction="horizontal" ref={ref} {...rest}>
        <Stack
          style={{
            marginRight: 8
          }}
        >
          {icon}
        </Stack>
        <Stack
          style={{
            marginTop: 0
          }}
        >
          {children}
        </Stack>
      </Stack>
    );
  }
);
