import { INDEX_ZERO, aggregateModelCreatorORM } from "@certa/common";
import type { ChartViewICProps } from "../types";

type Props = Pick<ChartViewICProps, "apiData" | "chartConfigIC">;

export const useConvertToChartDataIC = (props: Props) => {
  const { apiData, chartConfigIC } = props;
  const { isStackedBarChart, isGroupedBarChart } =
    chartConfigIC.otherConfigurations;

  const yAxis = chartConfigIC.operations.filter(
    operation => operation.extraJSON.axisName === "yAxis"
  );

  const outputDataTypes = yAxis.map(
    operation => operation.extraJSON.outputDataType
  );

  const xAxisLength = chartConfigIC.groupBy.length;
  const xAxisKey = chartConfigIC.groupBy[INDEX_ZERO].extraJSON.label;

  const reportChartData = aggregateModelCreatorORM(
    isStackedBarChart && !isGroupedBarChart,
    outputDataTypes,
    xAxisLength,
    xAxisKey
  )(apiData);

  return reportChartData;
};
