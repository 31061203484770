import React, { useContext } from "react";
import type { Workflow } from "@certa/types";
import type { TaskDetails } from "@certa/queries";

/**
 * Workflow contexts holds the current workflow that's loaded
 * and the refetch callback reference that will update the
 * workflow with latest data.
 */
type WorkflowContextProps = {
  workflow: Workflow | TaskDetails | null;
  fetchWorkflow: () => Promise<Workflow | TaskDetails | undefined>;
  readOnly: boolean;
};

export const WorkflowContext = React.createContext<WorkflowContextProps>({
  workflow: null,
  fetchWorkflow: () => Promise.resolve(undefined),
  readOnly: false
});

export const useWorkflowContext = () => {
  const contextValue = useContext(WorkflowContext);

  if (typeof contextValue === "undefined")
    throw Error(
      "useWorkflowContext is being used outside of WorkflowContext's range"
    );

  return contextValue;
};
