import { cx } from "emotion";
import { MehOutlined } from "@ant-design/icons";
import {
  StyledContainer,
  StyledHeader,
  StyledPadding,
  StyledBody,
  StyledCard,
  StyledHeading,
  StyledDescription,
  StyledFooter,
  StyledButton
} from "./ErrorBoundary";
import { history } from "../../_helpers";
import { FormattedMessage } from "react-intl";
import { COPY_TEXT } from "../../utils/constants";
import {
  DesignTheme,
  LAYOUT_ID,
  globalCSSClass,
  normalizeCSSClass
} from "@certa/blocks";
import { Maintenance } from "./Maintenance";
import { AppLoader } from "../../../modules/router/AppLoader";
import { useAppSelector, useGetMaintenanceMode } from "@certa/common";

export const ServiceUnavailableError = () => {
  const isConfigLoading = useAppSelector(state => state.config?.loading);
  const maintenanceModeConfig = useGetMaintenanceMode();

  const isMaintenanceModeTurnedOn =
    maintenanceModeConfig.isMaintenanceModeEnabled;
  const maintenanceModeMessage = maintenanceModeConfig.messageOnMaintenancePage;

  if (!!isConfigLoading) {
    return <AppLoader />;
  }

  if (!!isMaintenanceModeTurnedOn) {
    if (maintenanceModeMessage) {
      return (
        <DesignTheme
          id={LAYOUT_ID}
          className={cx(normalizeCSSClass, globalCSSClass)}
        >
          <Maintenance eta={""} msg={{ en: maintenanceModeMessage }} />
        </DesignTheme>
      );
    }
  }
  return (
    <StyledContainer>
      <StyledPadding />
      <StyledBody>
        <StyledCard>
          <StyledHeader>
            <MehOutlined style={{ color: "#ffffff", fontSize: 64 }} />
            <StyledHeading>{COPY_TEXT.OPPS}</StyledHeading>
          </StyledHeader>
          <StyledDescription>
            <FormattedMessage id="errorMessageInstances.serverNotAvailable" />
          </StyledDescription>
          <StyledFooter>
            <StyledButton onClick={() => history.goBack()}>
              <FormattedMessage id="commonTextInstances.goBack" />
            </StyledButton>
          </StyledFooter>
        </StyledCard>
      </StyledBody>
      <StyledPadding />
    </StyledContainer>
  );
};
