import type { WorkflowStatus } from "@certa/types";

export const createWorkflowStatusModel = (response: any) =>
  response
    .map(
      (item: any) =>
        ({
          id: item.id,
          label: item.label
        }) as WorkflowStatus
    )
    .sort((a: WorkflowStatus, b: WorkflowStatus) => {
      const aLabel = a.label;
      const bLabel = b.label;
      return aLabel < bLabel ? -1 : aLabel > bLabel ? 1 : 0;
    });
