import { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMentionsContext } from "./MentionsContext";
import { getProcessDetailRoute } from "@certa/common";
import { NotificationDrawer } from "./NotificationDrawer";
import { DrawerViewStates, useCommentsDrawerContext } from "@certa/comments";
import type { CommentObjectTypes } from "@certa/types";

export const MentionsCommentsDrawer = () => {
  const history = useHistory();

  const {
    show: shouldShow,
    onClose: mentionsDrawerOnClose,
    mode,
    updateMode,
    commentData,
    updateCommentData
  } = useMentionsContext();
  const { objectId, objectType, uid, processName } = commentData;
  const { showComments, commentsDrawerStates } = useCommentsDrawerContext();

  const handleOnClose = useCallback(() => {
    updateCommentData({ processId: null });
    updateMode("mentions");
    mentionsDrawerOnClose();
  }, [mentionsDrawerOnClose, updateCommentData, updateMode]);

  useEffect(() => {
    handleOnClose();
  }, [history.location.pathname, handleOnClose]);

  useEffect(() => {
    /**
     * We will show the new Comments drawer
     * when a specific notification is clicked from the notification drawer
     * or when comments are opened for a field/step/swimlane/dashboard/report
     */
    if (
      mode === "comments" &&
      shouldShow &&
      commentsDrawerStates === DrawerViewStates.NOT_OPENED
    ) {
      showComments({
        objectId: Number(objectId),
        objectType: objectType as CommentObjectTypes,
        uid: uid,
        header: processName || "",
        onStepClick: (groupId: number, stepId: number, workflowId: number) => {
          history.push(
            `${getProcessDetailRoute(workflowId)}?group=${groupId}&step=${stepId}`
          );
        }
      });
    }
    if (
      mode === "comments" &&
      commentsDrawerStates === DrawerViewStates.CLOSED
    ) {
      handleOnClose();
    }
  }, [
    shouldShow,
    showComments,
    commentsDrawerStates,
    updateMode,
    mode,
    objectId,
    objectType,
    uid,
    processName,
    history,
    handleOnClose
  ]);

  if (mode === "mentions" || mode === "subscriptions") {
    return <NotificationDrawer handleOnClose={handleOnClose} />;
  } else {
    return <></>;
  }
};
