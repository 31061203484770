import type { ProcessFamily } from "../types/processFamily.types";

export const processFamilyHierarchyModelCreator = (
  response: any
): ProcessFamily => {
  const processTypeWiseChildCounts = response?.child_count_kind_wise || {};
  const data = {
    parentProcessTypeIdList:
      Object.keys(response?.parents_count_kind_wise || {}) || [],
    childProcessTypes:
      response?.wf_kind_data
        ?.filter(
          (processType: any) => processTypeWiseChildCounts[processType.id]
        )
        ?.map((processType: any) => ({
          ...processType,
          processCount: processTypeWiseChildCounts[processType.id]
        })) || []
  };

  return data;
};
