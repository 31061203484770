import type { ButtonProps } from "@certa/catalyst";
import {
  Button,
  ButtonVariants,
  Typography,
  TypographyVariants
} from "@certa/catalyst";
import { useCreateNewWorkflow } from "../hooks/useCreateNewWorkflow";
import { VirtualizedSearchDropdown, useCheckPermission } from "../..";
import { PermissionTypes } from "@certa/types";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { css, cx } from "emotion";
import { transformAndCaptializeFirstLetter } from "../constants/styles";

type CreateNewProcessProps = {
  buttonProps?: ButtonProps & {
    isDropdownButton?: boolean;
    label?: string;
  };
};

export const CreateNewProcess = ({
  buttonProps = {}
}: CreateNewProcessProps) => {
  const { label, isDropdownButton = true, ...restProps } = buttonProps;

  const {
    kinds,
    status: kindsStatus,
    onSelect,
    buttonLabel: staticButtonLabel,
    creatingNewProcess: isCreatingNewProcess,
    isGlobalProcessPending
  } = useCreateNewWorkflow({ allowOrphanCreation: true });

  const dropdownItems = kinds.map(kind => ({
    label: kind.name,
    value: kind.tag
  }));

  const canCreateWorkflows = useCheckPermission(
    PermissionTypes.CAN_ADD_WORKFLOW
  );

  if (
    !canCreateWorkflows ||
    (kindsStatus !== "loading" && kinds.length === 0)
  ) {
    return null;
  }

  return (
    <VirtualizedSearchDropdown
      dropdownCmp={
        <Button
          variant={ButtonVariants.FILLED}
          {...restProps}
          minWidth="115px"
          showDropdownArrow={isDropdownButton}
          loading={
            isCreatingNewProcess ||
            isGlobalProcessPending ||
            kindsStatus === "loading"
          }
          disabled={kindsStatus === "loading"}
          aria-label={label || staticButtonLabel}
        >
          {/* Typography is required to capitalize */}
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            className={cx(
              transformAndCaptializeFirstLetter,
              css({ color: "inherit" })
            )}
          >
            {label || staticButtonLabel}
          </Typography>
        </Button>
      }
      items={dropdownItems}
      onSelect={onSelect}
      dropdownMenuContentProps={{
        align: "end",
        width: "340px",
        height: "400px"
      }}
      onDropdownMenuOpenChange={open => {
        open &&
          MixPanelActions.track(
            MixPanelEvents.headerEvents.HEADER_CLICK_CREATE_NEW_DROPDOWN
          );
      }}
      inlineSearchVisibilityThreshold={15}
    />
  );
};
