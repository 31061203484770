import { LOGO_SIZE } from "./constants";
import { css } from "emotion";
import { NewPage, Input } from "@certa/icons";
import { Stack, TypographyColors } from "@certa/catalyst";

type ResultIconProps = {
  type: "field" | "lc" | "file";
};

const resultIconContainerStyles = css({
  width: LOGO_SIZE,
  flexShrink: 0,
  flexGrow: 0,
  height: LOGO_SIZE,
  marginRight: "var(--space-8)",
  backgroundColor: "var(--colors-neutral-400)",
  borderRadius: LOGO_SIZE / 2
});

export const ResultIcon = ({ type }: ResultIconProps) => {
  let icon = null;

  switch (type) {
    case "field":
    case "lc":
      icon = <Input color={TypographyColors.NEUTRAL_600} />;
      break;
    case "file":
      icon = <NewPage color={TypographyColors.NEUTRAL_600} />;
      break;
    default:
      return null;
  }

  return (
    <Stack
      direction="horizontal"
      align="center"
      justify="center"
      dangerouslySetClassName={resultIconContainerStyles}
    >
      {icon}
    </Stack>
  );
};
