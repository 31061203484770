import { APIFetch, RequestHeaders } from "@certa/network";
import { createWorkflowStatusModel } from "../models/filters.models";

function getStatusData(kindId: number) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const searchParams = new URLSearchParams({
    kinds: `${kindId}`
  });
  const url = `workflow-status/?${searchParams}`;

  return APIFetch(url, requestOptions).then(createWorkflowStatusModel);
}

export const filterServices = {
  getStatusData
};
