/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import React from "react";
import { getCSSToken } from "../utils";

type SwitcherProps = {
  space: string;
  max: number;
  threshold: string;
  align?: string;
} & { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>;

const Switcher = React.forwardRef<HTMLDivElement, SwitcherProps>(
  (props, ref) => {
    const { space, max, threshold, align, ...htmlProps } = props;
    return (
      <div
        {...htmlProps}
        ref={ref}
        css={[
          css`
            --label: switcher;
            & > * {
              display: flex;
              flex-wrap: wrap;
              margin: calc((${getCSSToken(space)} / 2) * -1);
              align-items: ${align};
            }
            & > * > * {
              flex-grow: 1;
              flex-basis: calc(
                (${getCSSToken(threshold)} - (100% - ${getCSSToken(space)})) *
                  999
              );
              margin: calc(${getCSSToken(space)} / 2);
            }
            & > * > :nth-last-child(n + ${max + 1}),
            > * > :nth-last-child(n + ${max + 1}) ~ * {
              flex-basis: 100%;
            }
          `
        ]}
      >
        <div>{props.children}</div>
      </div>
    );
  }
);

export { Switcher };
