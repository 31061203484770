import React, { useMemo } from "react";
import Handlebars from "handlebars";
import { useIntl } from "react-intl";
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS, sanitizeHTML } from "@certa/common";
import { Typography, TypographyVariants } from "@certa/catalyst";

type HandleBarProps<T = any> = {
  template: string;
  data: T;
};

/**
 * This helper is used when we need to get n number of characters
 * from the string
 */
Handlebars.registerHelper("subString", (passedString, start, end) => {
  const value = passedString.substring(start, end ? end : passedString.length);
  return new Handlebars.SafeString(value);
});

/**
 * This helper is used to replace a specific character/ regex inside a string
 */
Handlebars.registerHelper("replace", (passedString, find, replace) => {
  const regEx = new RegExp(find);
  const value = passedString.replace(regEx, replace);
  return new Handlebars.SafeString(value);
});

/**
 * This helper is used when we need to wrap a particular string inside a
 * particular html tag if it satisfies a specific regex condition
 */
Handlebars.registerHelper("replaceWrap", (passedString, find, tag, style) => {
  const regEx = new RegExp(find);
  const value = passedString.replace(
    regEx,
    (foundValue: string) =>
      `<${tag} style=${style || ""}>${foundValue}</${tag}>`
  );

  return new Handlebars.SafeString(value);
});

const HandleBar = ({ template, data }: HandleBarProps) => {
  const intl = useIntl();

  const html = useMemo(() => {
    if (!template || !data) return null;

    try {
      const compliedTemplate = Handlebars.compile(template);
      const htmlWithData = compliedTemplate(data);
      return htmlWithData;
    } catch {
      return null;
    }
  }, [data, template]);

  if (!html)
    return (
      <Typography variant={TypographyVariants.BODY}>
        {intl.formatMessage({
          id: "handlebar.errorMessage",
          defaultMessage: "Invalid template or data provided"
        })}
      </Typography>
    );

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizeHTML(html, {
          ALLOWED_TAGS: [...ALLOWED_TAGS],
          ALLOWED_ATTR: [...ALLOWED_ATTRIBUTES],
          // retain entire document including <html> tags
          WHOLE_DOCUMENT: true,
          // glue elements like style, script and others to document.body
          FORCE_BODY: true
        })
      }}
    />
  );
};

export { HandleBar };
