import type { StepGroupsData, TaskDetails } from "@certa/queries";
import type { Dispatch, SetStateAction } from "react";
import type { TaskCategoryTypes } from "@certa/tasks/src/types";

export enum ActiveTaskPanel {
  MY_TASKS = "MY_TASKS",
  ALL_TASKS = "ALL_TASKS",
  MY_GROUPS = "MY_GROUP",
  BOTH_ACTIVE = "BOTH_ACTIVE",
  NONE = "NONE"
}

export enum TaskLaneTaskType {
  FYI = "FYI",
  DOABLE = "doable",
  INTERNAL = "internal",
  READONLY = "readonly"
}

export const submittableSteps: TaskLaneTaskType[] = [
  TaskLaneTaskType.DOABLE,
  TaskLaneTaskType.INTERNAL
];

export type TaskChangeArgs = {
  taskLaneId: number;
  taskId: number;
  activeTab?: string | null;
};

export type SectionChangeArgs = {
  fieldId: number;
};

export type SidebarProps = {
  processDetails: TaskDetails;
  taskLanesData: StepGroupsData;
};

export type SidebarContextTypes = {
  processId: number;
  selectedTask: number | null;
  selectedTaskLane: number | null;
  isProgressMapVisible: boolean;
  toggleProgressMap: () => void;
  handleTaskClick: (taskArgs: TaskChangeArgs) => void;
  isSidebarPinned: boolean;
  setIsSidebarPinned: (value: boolean) => void;
};

export type TaskBodyProps = {
  selectedTask: number;
  processId: number;
  selectedField: number | null;
  handleSectionChange: (payload: SectionChangeArgs) => void;
};

export type TaskCompletionStates = "all" | "mine";

export enum TFilterTab {
  ALL = "all",
  MY_GROUPS = "todoStepGroups",
  ASSIGNED_TO_ME = "taggedSteps"
}

export enum TOverviewMode {
  BASIC = "basic",
  ADVANCED = "advanced"
}

export type TaskCountConfig = {
  [TaskCategoryTypes.ME]: number;
  [TaskCategoryTypes.MY_GROUPS]: number;
};

export type UseTaskCategoryFiltersProps = {
  initialFilter: TaskCategoryTypes;
  tasklane: StepGroupsData | undefined;
};

export type UseTaskCategoryFiltersConfig = {
  taskCategoryFilter: TaskCategoryTypes;
  setTaskCategoryFilter: Dispatch<SetStateAction<TaskCategoryTypes>>;
  tasklaneFilteredByCategory?: StepGroupsData;
  taskCounts: TaskCountConfig;
  taggedStepGroups: number[];
};

export type NodeState = "expanded" | "collapsed" | "mixed";
