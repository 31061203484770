import type { HashMap } from "@certa/types/src/common";

/**
 * Transforms an array into HashMap with given prop as the key,
 * and the rest of the object as the value.
 * input:
 *   arr: [
 *          { name: "A", permissions: ["p1", "p2"], enabled: true  },
 *          { name: "B", permissions: ["p1", "p3"], enabled: false }
 *        ]
 *   keyProp: "name"
 *
 * output:
 *   {
 *      "A": { name: "A", permissions: ["p1", "p2"], enabled: true  },
 *      "B": { name: "B", permissions: ["p1", "p3"], enabled: false }
 *   }
 * @param arr
 * @param keyProp
 * @returns
 */
export const arrayToHashmap = <T extends HashMap>(
  arr: Array<T>,
  keyProp: keyof T
): HashMap<T> => (arr || []).reduce((a, c) => ({ ...a, [c[keyProp]]: c }), {});

export function dedupeArray<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function convertArrayToMap<T, K extends keyof T>(
  array: T[],
  key: K
): Map<T[K], T> {
  const map = new Map<T[K], T>();

  for (const item of array) {
    const itemKey = item[key];
    map.set(itemKey, item);
  }

  return map;
}

type Key = number | string;
/**
 * Convert an array to a string using a specified delimiter.
 *
 * @param {Array} array - The array to be converted to a string.
 * @param {string} delimiter - The delimiter to use between array elements.
 * @returns {string} The string representation of the array.
 */
export function arrayToString(
  array: Array<Key> | null = [],
  delimiter = "~"
): string {
  if (!array) return "";
  return array.join(delimiter);
}

/**
 * Convert a string to an array using a specified delimiter.
 *
 * @param {string} str - The string to be converted to an array.
 * @param {string} delimiter - The delimiter used to separate elements in the string.
 * @returns {Array} The array representation of the string.
 */
export function stringToArray(
  str: string = "",
  delimiter = "~"
): Array<string> {
  if (str === "") return [];
  return str.split(delimiter);
}
