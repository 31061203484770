import type { FC } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ActivityLogItem } from "./ActivityLogItem";
import { Timeline, Stack } from "@certa/blocks/src/componentsTh";
import { Loader } from "@certa/blocks";
import type { GroupedActivityLogs } from "../types";
import { getGroupActivityForBotOnly } from "../utils";
import type { ActivityLogModelType } from "@certa/queries";

type ActivityLogListProps = {
  loadData: (page: number) => void;
  hasMore: boolean;
  isLoading: boolean;
  groupedActivityLogs: GroupedActivityLogs;
};

export const ActivityLogList: FC<ActivityLogListProps> = props => {
  const { loadData, hasMore, groupedActivityLogs } = props;

  return (
    <div
      style={{
        overflow: "auto",
        padding: "var(--space-12) var(--space-24)",
        width: "100%"
      }}
    >
      <InfiniteScroll
        pageStart={0}
        loadMore={loadData}
        hasMore={hasMore}
        useWindow={false}
        initialLoad
        loader={
          <Stack direction="vertical" align="center" justify="center" key={0}>
            <Loader />
          </Stack>
        }
        // getScrollParent={() => document.querySelector(".ant-drawer-content")}
      >
        <Timeline edges="node">
          {Object.values(groupedActivityLogs).map(
            ({
              activityLog: activityLogInit = [],
              activityLogsByBot: activityLogsByBotInit = []
            }: GroupedActivityLogs[""]) => {
              const { activityLog, activityLogsByBot, hasAutoActivity } =
                getGroupActivityForBotOnly(
                  activityLogInit,
                  activityLogsByBotInit
                );
              /*
              - activityLogInit multiple 
              (hasAutoActivity === true) will be nested according to timestamp. 
              Bot icon collapsible will change to normal collapsible in this case
              - activityLogsByBot (with all bot), 
              will be handled by oldest as parent and all nested under it 
              into bot collapsible
              */

              return activityLog.map(
                (currentActivityLog: ActivityLogModelType) => (
                  <ActivityLogItem
                    activityLog={currentActivityLog}
                    activityLogsByBot={activityLogsByBot}
                    hasAutoActivity={hasAutoActivity}
                    key={currentActivityLog.id}
                  />
                )
              );
            }
          )}
        </Timeline>
      </InfiniteScroll>
    </div>
  );
};
