import { css } from "@emotion/core";

// NOTE: WN if we make a component here which renders CustomTable then its gives some error
// TODO: Solve the bug anf make a component here and move CustomTable here

export const TableNew = css`
  .ant-table {
    border-radius: 8px;
  }
  th {
    font-weight: bold !important;
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    overflow-wrap: normal !important;
    padding: 3px 12px !important;
    color: #8f8f91 !important;
    background: #f9f9f9 !important;
  }
  tbody tr:not(:first-child) td {
    padding: 8px 16px !important;
  }
  .ant-table-body {
    overflow-y: hidden !important;
  }
  td {
    border-bottom: 1px solid #eaeaea !important;
  }
  td:first-child {
    border-left: 1px solid #eaeaea !important;
  }
  td:last-child {
    border-right: 1px solid #eaeaea !important;
  }
  tr:last-child > td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr:last-child > td:last-child {
    border-bottom-right-radius: 8px;
  }
  tr:first-child > td {
    border-bottom: 0 !important;
  }
  .ant-table-thead > tr > th {
    border-bottom: 0 !important;
  }
  .ant-table-header {
    // The below css is to make the column header fixed when list is scrolled.
    // Antd latest version supports sticky prop so replace this css with that prop when antd is updated
    position: sticky;
    top: 0;
    z-index: 3;
    border: 1px solid #eaeaea;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom: 0;
  }
`;
