import { useState, useCallback, useRef, useEffect } from "react";
import { css } from "emotion";
import { Tooltip } from "../Tooltip";
import styles from "./Ellipsis.module.css";

const ellipsisStyles = css({
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
  minWidth: "0"
});

type EllipsisProps = {
  children: string;
};

/**
 * This component is copy of catalyst Ellipsis component
 * only difference is that it is using new Popover API Tooltip
 */
export const Ellipsis = (props: EllipsisProps) => {
  const { children } = props;

  const [isOverflowing, setIsOverflowing] = useState(false);
  const ellipsisContainerRef = useRef<HTMLDivElement | null>(null);

  const checkChildrenOverflow = useCallback(() => {
    if (ellipsisContainerRef.current) {
      if (
        ellipsisContainerRef.current.scrollWidth >
        ellipsisContainerRef.current.clientWidth
      ) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  }, []);

  useEffect(() => {
    checkChildrenOverflow();
  }, [children, checkChildrenOverflow]);

  return (
    <Tooltip
      content={children}
      show={isOverflowing}
      targetClassName={styles.catalystTypographyTooltipTarget}
    >
      <div className={ellipsisStyles} ref={ellipsisContainerRef}>
        {children}
      </div>
    </Tooltip>
  );
};
