import { css } from "emotion";
import type { FC } from "react";
import React from "react";
import { Clear } from "@certa/icons";
import { Stack, Paper, Text, Tag } from "@certa/blocks/thanos";
import { ButtonSizes, ButtonVariants, Button } from "@certa/catalyst";
import { useIntl } from "react-intl";
import type { AppliedFilter } from "@certa/tasks/src/types";
import { useTasksNewUX } from "../toggles";

// TODO: Add support to make filter type generic
// TODO: Add the colors used here in design theme
// TODO: Add the save button to blocks higher level component

type AppliedFiltersProps = {
  filters: AppliedFilter[];
  onClear: (type: string, label: string, value: string) => void;
  onSave?: () => void;
  onReset: () => void;
  disableOnSave?: boolean;
};

const tagWrapperClassname = css({
  "& > *": {
    whiteSpace: "break-spaces",
    wordBreak: "break-all"
  }
});
const tagKeyClassname = css({
  wordBreak: "normal"
});
const AppliedFilters: FC<AppliedFiltersProps> = ({
  filters,
  onClear,
  onSave,
  onReset,
  disableOnSave
}) => {
  const intl = useIntl();
  const isTasksNewUXEnabled = useTasksNewUX();

  return (
    <Paper
      gutter="s2 s2 s2 s4"
      gap="s4"
      justify="space-between"
      className={css`
        min-height: 45px;
      `}
    >
      <Stack
        gap="s4"
        align="center"
        className={css`
          flex: 1;
        `}
      >
        <Text variant="p2-bold-upper" color="neutral-70">
          {intl.formatMessage({
            id: "filters.filters",
            defaultMessage: "FILTERS"
          })}
        </Text>
        <Stack
          style={{ flex: 1, flexWrap: "wrap" }}
          className={isTasksNewUXEnabled ? tagWrapperClassname : ""}
        >
          {filters.map((filter, index) => {
            const Renderer = filter.renderer;
            return (
              <Tag
                closable
                onClose={() => onClear(filter.type, filter.label, filter.value)}
                key={`${index}`}
              >
                <Text
                  variant="p1-bold"
                  className={isTasksNewUXEnabled ? tagKeyClassname : ""}
                >
                  {`${filter.placeholder}:`}&nbsp;
                </Text>
                {Renderer ? (
                  Renderer()
                ) : (
                  <Text variant="p1-regular">{filter.label}</Text>
                )}
              </Tag>
            );
          })}
        </Stack>
        {onSave && (
          <Button
            variant={ButtonVariants.LINK}
            size={ButtonSizes.SMALL}
            onClick={onSave}
            disabled={disableOnSave}
          >
            {intl.formatMessage({
              id: "filters.saveAs",
              defaultMessage: "Save as..."
            })}
          </Button>
        )}
        <Button
          variant={ButtonVariants.LINK}
          size={ButtonSizes.SMALL}
          leftIcon={<Clear />}
          onClick={onReset}
        >
          {intl.formatMessage({
            id: "filters.clearFilters",
            defaultMessage: "Clear all filters"
          })}
        </Button>
      </Stack>
    </Paper>
  );
};

export { AppliedFilters };
