import { isDev } from "./isDev";

type ConsoleMethod = keyof Console;

// Utility type to extract the parameters of a function type if it is a function, otherwise never
type ConsoleMethodParameters<T extends ConsoleMethod> = Console[T] extends (
  ...args: infer ArgsType
) => any
  ? ArgsType
  : never;

export const devLogger = new Proxy(console, {
  get(target, prop: ConsoleMethod, receiver) {
    const origMethod = Reflect.get(target, prop, receiver);
    if (typeof origMethod === "function") {
      // Dynamically infer and apply the correct parameter types for the accessed console method
      return (...args: ConsoleMethodParameters<typeof prop>) => {
        if (isDev) {
          // Execute the original method with the correct 'this' context and supplied arguments
          return Reflect.apply(origMethod, console, args);
        }
      };
    }
    return origMethod;
  }
});
