export type ImageMetaData = {
  width?: number;
  height?: number;
  name?: string;
  fileName?: string;
};

const pageWidth = 287; // it is in mm. A4 have 297mm width
const pageHeight = 210; // it is in mm. A4 have 210mm height
const pieChartDeviation = 5; // to fix pie chart's slightly oval shape.

export const pdfTitleConfig = {
  padding: 5,
  color: "#00164e",
  fontSize: 14,
  font: `"Inter",sans-serif,"system-ui"`,
  fontStyle: "normal",
  fonrtWeight: "700",
  bold: true
};

/**
 *
 * @param pngData array of Data URL for a png image.
 * @param metaData array of metadata for image - width, height and name
 * @param fileName string file name
 * downloads the generated pdf doc
 */
export const convertAndDownloadPdfFromPng = async (
  pngDataUrl: string[],
  metaDataArray: ImageMetaData[] | undefined = [{}],
  fileName?: string
) => {
  const { default: jsPDF } = await import("jspdf");
  const doc = new jsPDF({ orientation: "landscape" });

  pngDataUrl.forEach((pngDataUrl, index) => {
    const metaData = metaDataArray?.[index] ?? {};
    const name = metaData?.name ?? "";
    const { w, h, y, x } = getScaledWidthAndHeight(
      metaData,
      pageWidth,
      pageHeight,
      pdfTitleConfig.padding,
      pdfTitleConfig.fontSize
    );

    if (index) doc.addPage();
    if (name)
      doc
        .setFontSize(pdfTitleConfig.fontSize)
        .setTextColor(pdfTitleConfig.color)
        .setFont(
          pdfTitleConfig.font,
          pdfTitleConfig.fontStyle,
          pdfTitleConfig.fonrtWeight
        )
        .text(name, x, y - pdfTitleConfig.fontSize);

    doc.addImage(pngDataUrl, "JPEG", x, y, w, h + pieChartDeviation);
    // jsPdf only support jpeg, height + 5 is: to fix pie chart's slightly oval shape.
  });

  doc.save(fileName);
};

const defaultWidthToHeightRatio = 1;

export const getScaledWidthAndHeight = (
  metaData: ImageMetaData,
  maxWidth: number,
  maxHeight: number,
  imageX = 0,
  imageY = 0
) => {
  const { height: originalHeight, width: originalWidth } = metaData;

  let widthToHeightRatio = defaultWidthToHeightRatio;

  if (originalHeight && originalWidth) {
    widthToHeightRatio = originalWidth / originalHeight;
  }
  let width = maxWidth;
  let h = maxWidth / widthToHeightRatio;
  let y = (maxHeight - h) / 2;
  let x = imageX;

  if (h > maxHeight) {
    width = maxHeight * widthToHeightRatio;
    h = maxHeight;
    y = imageY;
    x = (maxWidth - width) / 2;
  }

  return {
    w: width,
    h,
    x,
    y
  };
};
