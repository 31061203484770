import { getCharComponent } from "../../../utils";
import type { ChartViewEDProps } from "../types";
import { NoDataInReports } from "../../noDataInReports/NoDataInReports";
import { useChartViewED } from "../hooks/useChartViewED";

export const ChartViewED = (props: ChartViewEDProps) => {
  const {
    onChartClick,
    chartType,
    xActiveIdentifier,
    error,
    isAnimationActive = true
  } = props;
  const { chartData, otherConfig, chartHeight, hasData, xAxisDataLabels } =
    useChartViewED(props);
  const ChartComponent = getCharComponent(chartType);

  if (!hasData) {
    return <NoDataInReports error={error} />;
  }

  return (
    <ChartComponent
      chartData={chartData}
      onClick={onChartClick}
      height={chartHeight}
      xActiveIdentifier={xActiveIdentifier}
      otherConfig={otherConfig}
      isCycleTimeReport={false}
      xAxisDataLabels={xAxisDataLabels}
      isAnimationActive={isAnimationActive}
    />
  );
};
