import { useState, useEffect, useRef, useCallback } from "react";
import type { TabProps } from "../Tabs.types";

const useTabs = (
  activeTab: string | undefined,
  tabs: React.ReactElement<TabProps>[],
  onChange?: (activeTab: string) => void
) => {
  const [selectedTab, setSelectedTab] = useState<string>(
    activeTab || tabs?.[0]?.props.value
  );
  const tabListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeTab) {
      setSelectedTab(activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    const tabListElement = tabListRef.current;
    let isTabListFocused = false;

    const onKeyPressChangeTab = (evt: KeyboardEvent) => {
      const totalTabs = tabListElement?.childElementCount || 1;
      let activeTabIndex =
        tabListElement?.children && document.activeElement
          ? Array.from(tabListElement?.children).indexOf(document.activeElement)
          : 0;

      if (evt.key === "ArrowLeft") {
        evt.preventDefault();
        if (activeTabIndex === 0) {
          activeTabIndex = totalTabs - 1;
        } else {
          activeTabIndex -= 1;
        }
      }

      if (evt.key === "ArrowRight") {
        evt.preventDefault();
        if (activeTabIndex === totalTabs - 1) {
          activeTabIndex = 0;
        } else {
          activeTabIndex += 1;
        }
      }

      const currentTab = tabListElement?.children.item(
        activeTabIndex
      ) as HTMLButtonElement;

      currentTab.focus({ preventScroll: true });
    };

    const handleTabFocusChange = (evt: FocusEvent) => {
      if (!isTabListFocused) {
        if (
          document?.activeElement &&
          tabListElement?.contains(document?.activeElement)
        ) {
          tabListElement?.addEventListener("keydown", onKeyPressChangeTab);
          isTabListFocused = true;
        }
      }
    };

    tabListElement?.addEventListener("focusin", handleTabFocusChange);

    return () => {
      tabListElement?.removeEventListener("focusin", handleTabFocusChange);
      tabListElement?.removeEventListener("keydown", onKeyPressChangeTab);
    };
  }, []);

  const handleTabChange = useCallback(
    (tab: string) => {
      setSelectedTab(tab);
      onChange?.(tab);
    },
    [onChange]
  );

  return {
    selectedTab,
    tabListRef,
    handleTabChange
  };
};

export default useTabs;
