import { css } from "emotion";

export const accordionContainer = css({
  display: "flex",
  flexDirection: "column",
  rowGap: "var(--space-8)",
  boxShadow: "0px var(--space-4) var(--space-12) 0px rgba(0, 0, 0, 0.06)",
  padding: "var(--space-8)",
  borderRadius: "var(--space-4)",
  border: "1px solid var(--colors-neutral-400)"
});

export const accordionSummaryStyles = css({
  "--chevron-color": "var(--colors-neutral-500)",
  display: "flex",
  // Total min-height of collapsed accordion is 48px
  minHeight: "1.875rem",
  alignItems: "center",
  backgroundColor: "var(--colors-neutral-100)",
  ":hover, :focus-visible": {
    cursor: "pointer",
    "--chevron-color": "var(--colors-neutral-700)"
  },
  borderRadius: "1px",
  ":focus-visible": {
    outline: "2px solid var(--colors-brand-400)",
    outlineOffset: "4px"
  },
  padding: "var(--space-8) 0"
});

export const accordionSummaryDetailsDivider = css({
  width: "100%",
  height: "1px",
  margin: 0,
  border: "none",
  backgroundColor: "var(--colors-neutral-400)"
});

export const accordionIconContainer = css({
  display: "flex",
  width: "fit-content",
  color: "var(--chevron-color)",
  marginLeft: "auto",
  transform: "rotate(0deg)",
  marginRight: "var(--space-16)",
  transition: "color 0.15s ease-in",
  "&:hover": {
    transition: "color 0.15s ease-out"
  }
});

export const animateAccordionIcon = css({
  transform: "rotate(180deg)"
});

export const accordionDetailsContainer = css({
  display: "flex",
  flexDirection: "column",
  rowGap: "var(--space-4)",
  overflow: "hidden",
  backgroundColor: "var(--colors-neutral-100)"
});
