/**
 * SYNC: Importing from @certa/blocks/thanos is leading an error, most probably
 * due to circular dependency
 */
import { notification } from "@certa/blocks/src/componentsTh/Notification/notification";
import { intl } from "./components/IntlCapture";
import type { IconType, NotificationPlacement } from "antd/lib/notification";
import type { HashMap } from "./types/generalTypes";

type IExtraParams = {
  key?: string;
  description?: string;
};

type IShowNotification = {
  type?: IconType;
  message?: string;
  messageData?: HashMap;
  description?: string;
  descriptionData?: any;
  key?: string;
  placement?: NotificationPlacement;
  duration?: number;
};

/**
 *
 * @param {string} type Defines the type of notification that you want to show
 * @param {string} message Translation path of title text
 * @param {object} messageData Additional data to substiture variables from the message
 * @param {string} description Translation path of body text
 * @param {object} descriptionData Additional data to substitute variables from the message
 * @param {any} key Any unique string or symbol
 * @param {"topLeft" | "topRight" | "bottomLeft" | "bottomRight"} placement placement of the notification on screen
 * @param {number} duration Duration for which the notification should stay
 * @deprecated
 */
export const showNotification = (params: IShowNotification) => {
  const {
    type = "info",
    message = "",
    messageData = {},
    description,
    descriptionData = {},
    key,
    placement = "bottomLeft",
    duration = 4.5
  } = params;
  if (!!intl === false) return;
  if (!!message === false) return;
  const extraProps: IExtraParams = {};
  if (!!key) extraProps.key = key;
  if (!!description)
    extraProps.description = intl.formatMessage(
      {
        id: description,
        defaultMessage: description
      },
      descriptionData
    );

  notification[type]({
    message: intl.formatMessage(
      { id: message, defaultMessage: message },
      messageData
    ),
    placement,
    duration,
    ...extraProps
  });
};
