// import { PrasaranEventType } from "./prasaran.constants";

import type { SubscriptionMap, Subscription } from "./types";

/**
 * To manage all the active subscriptions, with these in mind
 * - Each channel will manage it's own Subscription & Subscribers
 * - Replacing existing channel is prevented
 * - Only new channel subscription can be added
 * - Older channels can be directly deleted off the map
 */
export const subscriptions: SubscriptionMap = new Proxy(
  {},
  {
    set: (
      subMap: SubscriptionMap,
      channelName: string,
      subscription: Subscription
    ) => {
      if (!subMap[channelName]) {
        // if we dont have a subcription against the given channel name
        // we create and store an instance within that
        subMap[channelName] = subscription;
      } else if (subscription === null) {
        // if we already have subscription within the channelName
        // and we're trying to set it to null, we delete it
        delete subMap[channelName];
      }
      return true;
    },
    get: (subMap: SubscriptionMap, channelName: string) => {
      return subMap[channelName];
    }
  }
);
