import { APIFetchV2, RequestHeaders } from "@certa/network";
import { processFamilyHierarchyModelCreator } from "../models/processFamily.model";

type LinkUnlinkProcessPayload = {
  child_workflow_id: number;
  parent_workflow_id: number;
};

// https://www.notion.so/certa/Workflow-Family-Bird-s-eye-view-265f5671d9f640e7a9340ac3e4ce8ebc

export const getProcessFamilyHierarchy = (processId: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `workflows/${processId}/family-birds-eye-view/`;

  return APIFetchV2(url, requestOptions).then(
    processFamilyHierarchyModelCreator
  );
};

export const linkProcess = (payload: LinkUnlinkProcessPayload) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(payload)
  };

  return APIFetchV2("workflows/parent-child-relation/", requestOptions);
};

export const unlinkProcess = (payload: LinkUnlinkProcessPayload) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(payload)
  };

  return APIFetchV2("workflows/parent-child-relation/remove/", requestOptions);
};
type LinkUnlinkReplaceProcessPayload = {
  child_workflow_id: number;
  remove_parent_workflow_id: number;
  add_parent_workflow_id: number;
};
export const replaceProcess = (payload: LinkUnlinkReplaceProcessPayload) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(payload)
  };

  return APIFetchV2("workflows/parent-child-relation/replace/", requestOptions);
};
