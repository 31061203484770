import type { SetStateAction } from "react";
import { useState, useEffect } from "react";

import { Godaam } from "../utils/Godaam";

const onStoreValueInGodaam = <T>(key: string, value: T) => {
  Godaam[key] = JSON.stringify(value);
};

const getValueFromGodaam = <T>(key: string): T => {
  return JSON.parse(Godaam[key]);
};

/**
 * A hook to sync / update the data to the local storage based on the key
 *
 * @param key - A key in local storage to store the data
 * @param data - The data that needs to be stored in the local storage
 * @returns Array of updated value and a function to update the data
 */
export const useGodaamSyncState = <T>(
  key: string,
  data?: T
): [T, (data: T | SetStateAction<T>) => void] => {
  const [valueFromGodaam, setValueFromGodaam] = useState<T>(
    getValueFromGodaam(key)
  );

  useEffect(() => {
    onStoreValueInGodaam(key, valueFromGodaam);
  }, [key, valueFromGodaam]);

  useEffect(() => {
    if (data) {
      setValueFromGodaam(data);
    }
  }, [data]);

  return [valueFromGodaam, setValueFromGodaam];
};
