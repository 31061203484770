import {
  Ellipsis,
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst";
import type { BreadcrumbCurrentProps } from "../Breadcrumb.types";
import styles from "./BreadcrumbCurrent.module.css";
import { forwardRef } from "react";

const BreadcrumbCurrent = forwardRef<HTMLLIElement, BreadcrumbCurrentProps>(
  ({ link }, ref) => {
    const { label } = link;

    return (
      <li className={styles.container} ref={ref}>
        <Typography
          color={TypographyColors.NEUTRAL_700}
          variant={TypographyVariants.LABEL_SM}
          as="span"
          className="overflow-hidden"
        >
          <Ellipsis>{label}</Ellipsis>
        </Typography>
      </li>
    );
  }
);

export { BreadcrumbCurrent };
