import { Stack } from "@certa/blocks/thanos";
import type { ModelledAPIResponse } from "@certa/queries";
import type { BreadcrumbsProps } from "../Breadcrumbs/Breadcrumbs.types";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { WorkflowLogo } from "../WorkflowLogo";
import {
  logoAndBreadcrumbsContainer,
  nonWorkflowGutter,
  rightIconContainer,
  innerStackStyles
} from "./ResultLayout.styles";
import { cx } from "emotion";
import { ResultIcon } from "../ResultIcon";

type ResultLayoutProps = {
  workflow: ModelledAPIResponse.Workflow;
  breadcrumbProps: BreadcrumbsProps;
  footer: React.ReactNode;
  rightIcon?: React.ReactNode;
  type: "workflow" | "lc" | "field" | "file";
};

export const ResultLayout = (props: ResultLayoutProps) => {
  const { workflow, breadcrumbProps, footer, type, rightIcon = null } = props;
  const isWorkflowResult = type === "workflow";

  let icon = null;

  switch (type) {
    case "workflow":
      icon = <WorkflowLogo logoUrl={workflow.logo} name={workflow.name} />;
      break;
    case "field":
    case "lc":
    case "file":
      icon = <ResultIcon type={type} />;
      break;
    default:
      break;
  }

  return (
    <Stack direction="horizontal">
      {!isWorkflowResult ? <Stack className={nonWorkflowGutter} /> : null}
      <Stack
        direction="vertical"
        gap="s3"
        className={cx(
          "global-select__option-container",
          isWorkflowResult && "global-select__workflow-option-container"
        )}
      >
        <Stack direction="horizontal" gap="s3" className={innerStackStyles}>
          <Stack
            direction="horizontal"
            align="center"
            className={logoAndBreadcrumbsContainer}
          >
            {icon}
            <Stack direction="vertical" itemWidth="100%">
              <Breadcrumbs {...breadcrumbProps} />
              {footer}
            </Stack>
          </Stack>
          <Stack className={rightIconContainer}>{rightIcon}</Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
