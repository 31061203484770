import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";
import type { AlertProps } from "@certa/catalyst";
import { AlertTypes } from "@certa/catalyst";

/**
 * bannerConfig: {message: string, visible: true / false, type: "warning" / "success" / "error" / "info", icon: true / false}
 * requiredConfig: message
 * defaultValues: visible = true, type: "info", icon: false,
 */

export const useHeaderBanner = (): Pick<
  AlertProps,
  "type" | "message" | "hasIcon"
> | null => {
  const bannerConfig = useSelector((state: ReduxState) =>
    lodashGet(state, "config.custom_ui_labels.banner", null)
  );
  if (bannerConfig && bannerConfig.visible && bannerConfig.message) {
    return {
      message: bannerConfig.message,
      type: bannerConfig.type
        ? bannerConfig.type[0].toUpperCase() + bannerConfig.type.slice(1)
        : AlertTypes.INFO,
      hasIcon: bannerConfig.icon || false
    };
  } else {
    return null;
  }
};
