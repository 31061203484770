/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import type { FC } from "react";

const Close: FC<React.SVGProps<SVGSVGElement> & { color?: string }> = props => {
  const { color = "#138CD6" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M6 4.586L1.707.293.293 1.707 4.586 6 .293 10.293l1.414 1.414L6 7.414l4.293 4.293 1.414-1.414L7.414 6l4.293-4.293L10.293.293 6 4.586z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export { Close };
