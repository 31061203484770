import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { languages } from "../components/common/intlLanguages";

export const useGetPreferredLanguage = () => {
  const preferredLanguage = useSelector(
    (state: ReduxState) =>
      state.languageSelector.language || state.languageConstants.DEFAULT_LOCALE
  );
  return preferredLanguage;
};

export const renderLanguageName = (languageSymbol: string) => {
  const languageEndonym = (languages as { endonyms: Record<string, string> })
    .endonyms[languageSymbol];
  return languageEndonym
    ? `${languageSymbol}(${languageEndonym})`
    : languageSymbol;
};

export const renderLanguageNativeName = (languageSymbol: string) => {
  const languageEndonym = (languages as { endonyms: Record<string, string> })
    .endonyms[languageSymbol];
  const languageName = (languages as { names: Record<string, string> }).names[
    languageSymbol
  ];
  return languageEndonym ? languageEndonym : languageName;
};
