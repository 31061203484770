import { RequestHeaders, APIFetchV2 } from "@certa/network";
import { kindsModelCreator } from "../models/kinds.models";

export type GetAllKindsArgs = { hideInternal?: boolean };

export function getAllKinds(params: GetAllKindsArgs = {}) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const searchParams = new URLSearchParams({
    limit: "200",
    ...(params.hideInternal ? { hide_internal: "True" } : undefined)
  });

  // Essentially this API shouldn't have limit param because we always need
  // all the workflow kinds that are available within the system.
  return APIFetchV2<any[]>(
    `workflow-kinds/?${searchParams}`,
    requestOptions
  ).then(kindsModelCreator);
}
