import { SearchDialog } from "../GlobalSearch";
import { useNavigationDrawer } from "../NavigationSidebar";
import { searchDropdownStyles } from "./SearchDropdown.styles";
import { useCreateNewWorkflow } from "../../hooks/useCreateNewWorkflow";
import { ErrorBoundary } from "../ErrorBoundary";
import { SearchDropdownBase } from "./SearchDropdownBase";

export const SearchDropdownWrapper = () => {
  const { isSearchModalOpen, setIsSearchModalOpen } = useNavigationDrawer();

  const {
    kinds,
    status: kindsStatus,
    onSelect: onCreateNewWorkflow
  } = useCreateNewWorkflow();

  const handleOnClose = () => {
    setIsSearchModalOpen(false);
  };

  return (
    <SearchDialog
      show={isSearchModalOpen}
      width="640px"
      height="619px"
      onClose={handleOnClose}
    >
      <ErrorBoundary>
        <div className={searchDropdownStyles}>
          <SearchDropdownBase
            hideSearchModal={handleOnClose}
            createWorkflowParams={{
              kinds,
              kindsStatus,
              onCreateNewWorkflow
            }}
          />
        </div>
      </ErrorBoundary>
    </SearchDialog>
  );
};
