import {
  Eye,
  Edit,
  Checkmark,
  Backslash,
  Envelope,
  Bot,
  Trashcan
} from "@certa/icons";

export const TIMELINE_ACTION_MAPPER = {
  viewed: {
    color: "teal",
    bgColor: "#F1FAFF",
    icon: Eye
  },
  edited: {
    color: "orange",
    bgColor: "#FFF1DA",
    icon: Edit
  },
  updated: {
    color: "orange",
    bgColor: "#FFF1DA",
    icon: Edit
  },
  submitted: {
    color: "green",
    bgColor: "#DEFFEF",
    icon: Checkmark
  },
  undo: {
    color: "orange",
    bgColor: "#FFF1DA",
    icon: Backslash
  },
  queued: {
    color: "neutral-70",
    bgColor: "#F6F6F6",
    icon: Envelope
  },
  delivered: {
    color: "green",
    bgColor: "#DEFFEF",
    icon: Envelope
  },
  bounced: {
    color: "red",
    bgColor: "#feeaea",
    icon: Envelope
  },
  deferred: {
    color: "orange",
    bgColor: "##FFF1DA",
    icon: Envelope
  },
  performed: {
    color: "purple",
    bgColor: "#FBDFFF",
    icon: Bot
  },
  created: {
    color: "orange",
    bgColor: "#FFF1DA",
    icon: Edit
  },
  deleted: {
    color: "red",
    bgColor: "#feeaea",
    icon: Trashcan
  }
};
