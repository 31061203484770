import React from "react";
import { message } from "antd";
import type { ArgsProps } from "antd/es/message";
import { LAYOUT_ID, Loader } from "@certa/blocks";
import { css } from "emotion";
/**
 * This is a wrapper around `message` of ANTD.
 * Reason this is in common is because in future if you want to change the way
 * messages show up across application, it can be changed here.
 *
 * @param {"warning"| "warn"| "success"| "loading"| "info"| "error"} type Defines the type of message that you want to show.
 * @param {React.ReactNode | string} content Defines the content that would show in the message
 * @param {ArgsProps} config Defines additional configuration
 */

//Setting the container for messages so as to make it render inside the ;new-min-layout-body' div so that it could access the CSS variabes that are set.
message.config({
  getContainer: () => document.getElementById(LAYOUT_ID) || document.body
});

export const showMessage = (
  type: "warning" | "warn" | "success" | "loading" | "info" | "error",
  content: string | React.ReactChild | null,
  config: Partial<Omit<ArgsProps, "content" | "icon">> = {}
) => {
  if (!message[type] || ["config", "destroy", "open"].includes(type))
    return console.warn(`Unsupported type: ${type}`);

  const messageConfig = {
    //Message Content
    content: (
      <span
        className={css`
          font-family: "Inter", sans-serif, "system-ui";
        `}
      >
        {content}
      </span>
    ),
    //custom loading icon
    icon: type === "loading" && (
      <Loader
        size={"small"}
        className={css`
          margin-right: 10px;
          margin-bottom: 2px;
        `}
      />
    ),
    ...config
  } as ArgsProps;

  message[type](messageConfig);
};
